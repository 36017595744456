import { AccountApi } from '@/api/login/account';
import { UserInfoByUserNameApi } from '@/api/organization/UserInfoByUserNameApi';
import UserApi from '@/api/security/UserApi';
import User from '@/models/task/User';
import Setting from '@/rest/Setting';
import CookieHelper from '@/utils/CookieHelper';
import { State } from '@/utils/state';
import SystemUtils from '@/utils/SystemUtils';
import Utils from '@/utils/Utils';
/**
 * 首页模块
 */
export class AccountService {

    private api = new AccountApi();

    public login(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.login(email).then(async (res: any) => {
                const success = res.data["success"];
                if (success) {
                    CookieHelper.save({
                        key: "Authorization",
                        value: res.data["data"],
                    });
                    this.getUserInfo().then((value) => {
                        resolve(true);
                    });
                } else {
                    CookieHelper.deleteCookie("Authorization");
                    resolve(false);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getLoginUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getLoginUser().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    private userApi = new UserInfoByUserNameApi();
    getUserInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userApi.getLoginUser().then((res: any) => {
                let user: User = res.data;
                //user.account=user.id;
                SystemUtils.loginUser = user;
                CookieHelper.save({
                    key: "task_system_loginuser",
                    value: JSON.stringify(user)
                });
                resolve(user);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    loginWithPass(login: string, pass: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.loginWithPass(login, pass).then(async (res: any) => {
                const success = !res.data["error"];
                if (success) {
                    this.sucessOauth(res);
                    this.getUserInfo().then((value) => {
                        resolve(true);
                    }).catch((err: any) => {
                        Utils.presentToastWarning("获取用户信息失败");
                        reject(false);
                    });
                } else {
                    CookieHelper.deleteCookie("Authorization");
                    resolve(false);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    loginWithSMSCode(mobile: string, code: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.loginWithSMS(mobile, code).then(async (res: any) => {
                const success = !res.data["error"];
                if (success) {
                    this.sucessOauth(res);
                    this.getUserInfo().then((value) => {
                        resolve(true);
                    }).catch((err: any) => {
                        Utils.presentToastWarning("获取用户信息失败");
                        reject(false);
                    });
                } else {
                    CookieHelper.deleteCookie("Authorization");
                    resolve(false);
                }
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    private sucessOauth(res: any) {
        let state: State = new State();
        state.accessToken_expires = state.getExpiresTimestamp(res.data["expires_in"]);;
        state.access_token = "Bearer " + res.data["access_token"];
        state.refresh_token = res.data["refresh_token"];
        state.refreshToken_expires = state.getExpiresTimestamp(30 * 24 * 60 * 60); //有效期三十天
        CookieHelper.save({
            key: Setting.authCookieName,
            value: JSON.stringify(state)
        });
        CookieHelper.save({
            key: Setting.refreshingTokenName,
            value: "0"
        });
    }

    //发送验证码
    private userSmsApi = new UserApi();
    getMsgCode(loginId: string): Promise<any> {
        const params = {
            phone: loginId,
            loginId: loginId,
            type: "login",
            roleType: 'client_credentials',
            terminal: '0'
        };

        return new Promise((resolve, reject) => {
            this.userSmsApi.getMsgCode(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 校验验证码的有效性
     * @param phone 
     * @param code 
     */
    validateSMSCodeIsCorrect(phone: string, code: string): Promise<any> {
        const params = {
            phone: phone,
            code: code,
            type: "login",
            terminal: '0'
        };

        return new Promise((resolve, reject) => {
            this.userSmsApi.validateSMSCodeIs(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    /** 通过组织Id获取其下所有用户*/
    public getUserByDept(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userSmsApi.getUserByDept(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public updatePassWord(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.userSmsApi.updatePassword(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}