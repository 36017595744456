import { OrganizationApi } from '@/api/organization/OrganizationApi'

/**
 *机构service
 */
export class OrganizationService {

    private organizationApi = new OrganizationApi();
    //选择机构--  新建项目 选择所属机构
    public getOrgList({
        orgId = "",
        size = 10,
        pageNum = 1,
        pageSize = 10,
        isAllChildOrg = false,
        likeConditions = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            this.organizationApi.getChildOrgsInfo({
                orgId,
                size,
                pageNum,
                pageSize,
                isAllChildOrg,
                likeConditions
            }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //获取用户信息
    public getUserInfo(params:any): any {
        return new Promise((resolve, reject) => {
            this.organizationApi.getUserInfo(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}