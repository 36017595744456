import { ProjectStatusApi } from '@/api/project/ProjectStatusApi';
import BaseService from '../base/BaseService';
import ProjectStatus from '@/models/project/ProjectStatus';
/**
 * 项目列表模块
 */
export class ProjectStatusService extends BaseService {
    private api = new ProjectStatusApi();
    //获取项目状态
    public getByProjectStatusId(projectId: string): any {
        return new Promise((resolve, reject) => {
            this.api.getByProjectStatusId({ projectId: projectId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //新增项目状态
    public addProjectStatus(projectStatus: ProjectStatus): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.addProjectStage(projectStatus).then(async (res: any) => {
                if (res && res.data)
                    resolve(projectStatus);
                else resolve({ errors: { base: res.data.message } })
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //删除项目状态
    public deleteProjectStatusById(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.deleteProjectStatusById(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //编辑
    public updateFieldsById(parms: any): any {
        return new Promise((resolve, reject) => {
            this.api.updateFieldsById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
}
