import { KeyResultApi } from '@/api/okr/KeyResultApi';
import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';
import UserInfo from '@/services/selectexecutor/UserInfo';
import KeyResult, { KeyResultRsp, KeyResultsRsp } from '@/models/okr/KeyResult';
import BaseRsp from '@/models/base/BaseRsp';
import moment from "moment";
import { Task } from '../task/model/Task';
import User from '@/models/task/User';
import OkrTaskListInfo from '@/views/OKR/details/OkrTaskListInfo';
import BaseModel from '../base/model/BaseModel';
import Utils from '@/utils/Utils';
/**
 * okr-关键结果
 */
export class KeyResultService extends BaseService {
    private readonly api = new KeyResultApi();

    //更新进度
    public progressInsert(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.progressInsert(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 计算状态
     * 
     * 偏差值：（计划进度-实际进度）。计划进度=（实际日期-结果开始）/（结果结束-结果开始）
     * 实际日期=当天，最大不能超过结果结束日期。
     * 
     * 状态有以下值：
     * 失控：偏差值>10% 
     * 有风险：偏差值>5%
     * 正常：偏差值<=5%
     * --：关键结果尚未开始，且进度为0
     * 
     * @param kr 关键结果实体
     */
    calStatus(kr: KeyResult, log?: any): number {
        if ((kr && kr.progress) != null || (kr && log) != null) {
            let today: any = "";
            if (log) {
                today = moment(log.modifyTime).format("YYYY-MM-DD");
            } else {
                today = moment(new Date()).format("YYYY-MM-DD");
            }
            // let todayTime = new Date(today + " 23:59:59.999");
            // let pStartDate = new Date(moment(kr.startDate).format("YYYY-MM-DD") + " 0:0:0.001");
            // let pEndDate = new Date(moment(kr.endDate).format("YYYY-MM-DD") + " 23:59:59.999");
            let todayTime = new Date(moment(today).endOf('day').format("yyyy-MM-DD HH:mm:ss"));
            let pStartDate =new Date(moment(kr.startDate).startOf('day').format("yyyy-MM-DD HH:mm:ss"));
            let pEndDate =new Date(moment(kr.endDate).endOf('day').format("yyyy-MM-DD HH:mm:ss"));
            
            let progrssAct =
                (todayTime.getTime() - pStartDate.getTime()) /
                (pEndDate.getTime() - pStartDate.getTime());
            //偏差值 （计划进度-实际进度）
            let pianCha: number = 0;
            if (log)
                pianCha = progrssAct - log.progress / 100;
            else if (kr.progress != null)
                pianCha = progrssAct - kr.progress / 100;
            let status = 0;
            if (pianCha <= 0.05) {
                status = 1;
            } else if (pianCha > 0.05 && pianCha <= 0.1) {
                status = 2;
            } else if (pianCha > 0.1) {
                status = 3;
            }
            return status;
        } else {
            return 0;
        }
    }

    getStatus(kr: KeyResult, log: any) {
        let status = this.calStatus(kr, log);
        let statusName = "--";
        let color = "#000000";
        switch (status) {
            case 1:
                statusName = "正常";
                color = "#64cb50";
                break;
            case 2:
                statusName = "有风险";
                color = "#ff9a6d";
                break;
            case 3:
                statusName = "失控";
                color = "#d55a5a";
                break;
        }
        return { statusName: statusName, color: color };
    }

    getStatusByStatusName(statusName: string): number {
        let status = 0;
        switch (statusName) {
            case "正常":
                status = 1;
                break;
            case "有风险":
                status = 2;
                break;
            case "失控":
                status = 3;
                break;
        }
        return status;
    }

    /**
     * 获取目标下的关键结果列表
     * @param oid 目标id
     */
    public getListByOid(oid: string, isEdit?: boolean): Promise<KeyResultsRsp> {
        return new Promise((resolve, reject) => {
            let params = { oid: oid, isEdit: false };
            if (isEdit) {
                params = { oid: oid, isEdit: true }
            }
            this.api.getListByOid(params).then((res: KeyResultsRsp) => {
                for (let kr of res.data) {
                    kr.startDate = moment(kr.startDate).format("YYYY-MM-DD");
                    kr.endDate = moment(kr.endDate).format("YYYY-MM-DD");
                    kr.status = this.calStatus(kr);
                }
                resolve(res.data);
            }).catch((err: KeyResultsRsp) => {
                reject(err);
            });
        })
    }

    /**
     * 获取关键结果详情
     * @param id 关键结果Id
     */
    public getDetailById(id: string): Promise<KeyResultRsp> {
        return new Promise((resolve, reject) => {
            this.api.detailById({ id: id }).then(async (res: KeyResultRsp) => {
                res.data.startDate = moment(res.data.startDate).format("YYYY-MM-DD");
                res.data.endDate = moment(res.data.endDate).format("YYYY-MM-DD");
                res.data.status = this.calStatus(res.data);
                resolve(res.data);
            }).catch((err: KeyResultRsp) => {
                reject(err);
            })
        })
    }

    /**
     * 添加关键结果
     * @param kr 关键结果
     */
    private addKeyResult(kr: KeyResult): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.api.addKeyResult(kr).then(async (res: BaseRsp) => {
                resolve(res.data);
            }).catch((err: BaseRsp) => {
                reject(err);
            })
        })
    }

    /**
     * 更新关键结果
     * @param kr 关键结果
     */
    private updateKeyResult(kr: KeyResult): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.api.updateKeyResult(kr).then(async (res: BaseRsp) => {
                resolve(res.data);
            }).catch((err: BaseRsp) => {
                reject(err);
            })
        })
    }

    /**
     * 逻辑删除关键结果 根据id
     * @param id 关键结果id
     */
    public deleteKeuResultById(id: string) {
        return new Promise((resolve, reject) => {
            this.api.deleteKeuResultById(id).then(async (res: BaseRsp) => {
                resolve(res.data);
            }).catch((err: BaseRsp) => {
                reject(err);
            })
        })
    }

    /**
     * 逻辑删除关键结果 根据id数组
     * @param id 关键结果id数组
     */
    public deleteKeuResultByIds(ids: []) {
        return new Promise((resolve, reject) => {
            this.api.deleteKeuResultByIds(ids).then(async (res: BaseRsp) => {
                resolve(res);
            }).catch((err: BaseRsp) => {
                reject(err);
            })
        })
    }

    /**
     * 获取目标关键结果的剩余可用权重
     * @param oid 目标id
     * @param krId 关键结果id（新建不传）
     */
    public getSurplusWeight(oid: string, krId: string = "") {
        return new Promise((resolve, reject) => {
            this.api.getSurplusWeight({ oid: oid, krId: krId }).then(async (res: BaseRsp) => {
                resolve(res.data);
            }).catch((err: BaseRsp) => {
                reject(err);
            })
        })
    }

    /**
     * 新建关键结果
     * @param oid 目标id
     * @param name 关键结果名称
     * @param user 负责人
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param priority 重要性
     * @param measurements 衡量标准
     * @param remark 描述
     * @param weight 权重
     * @param isCustomUnit 是否自定义单位
     * @param unit 单位
     * @param unitStartValue 起始
     * @param unitEndValue 目标
     * @param linkTasks 关联任务
     */
    public addKeyResultExpose(
        target: any,
        oid: string,
        name: string,
        user: any,
        startDate: string,
        endDate: string,
        priority: number,
        measurements: string,
        remark: string,
        weight: number,
        isCustomUnit: boolean,
        unit: string,
        unitStartValue: string,
        unitEndValue: string,
        linkTasks: Array<any>
    ): Promise<BaseRsp> {
        name = this.trim(name);
        if (!name || name.length <= 0)
            return this.errRsp("请输入关键结果标题");
        if (Utils.isInCludeEmoji(name) || Utils.isInCludeEmoji(measurements) || Utils.isInCludeEmoji(remark) || Utils.isInCludeEmoji(unit))
            return this.errRsp("请勿输入emoji表情");
        if (!user.userId)
            return this.errRsp("请选择负责人");
        if (!startDate)
            return this.errRsp("请选择开始时间");
        if (!endDate)
            return this.errRsp("请选择结束时间");
        startDate = moment(startDate).format("YYYY-MM-DD");
        endDate = moment(endDate).format("YYYY-MM-DD");
        let isDateSatisfy: boolean = false;
        for (let cycle of target.cycleList) {
            if ((startDate >= cycle.cycleStartDate && startDate <= cycle.cycleEndDate) && (endDate >= cycle.cycleStartDate && endDate <= cycle.cycleEndDate)) {
                isDateSatisfy = true;
                break;
            }
        }
        if (!isDateSatisfy)
            return this.errRsp("起止时间不在目标周期范围内，请重新选择！");
        if (startDate > endDate)
            return this.errRsp("开始时间不得大于结束时间");
        if (weight < 0)
            return this.errRsp("权重不能小于0");
        if (isCustomUnit) {
            if (!unit) {
                return this.errRsp("请输入关键结果单位");
            }
            if (!unitStartValue) {
                return this.errRsp("请输入关键结果起始值");
            }
            if (!unitEndValue) {
                return this.errRsp("请输入关键结果目标值");
            }
        } else {
            unit = "完成/未完成"
            unitStartValue = "未完成"
            unitEndValue = "完成"
        }
        let kr = new KeyResult();
        kr.oid = oid;
        kr.rootOid = oid;
        kr.pathOid = oid;
        kr.name = name;
        kr.userId = user.userId + "";
        kr.userName = user.name;
        kr.startDate = startDate;
        kr.endDate = endDate;
        kr.priority = priority;
        kr.measurements = measurements;
        kr.remark = remark;
        kr.weight = weight;
        kr.isCustomUnit = isCustomUnit ? 1 : 0;
        kr.unit = unit;
        kr.unitStartValue = unitStartValue;
        kr.unitEndValue = unitEndValue;
        kr.score = 0;
        kr.progress = 0;
        kr.remark = remark;
        kr.linkTasks = this.setTaskData(linkTasks, kr);
        return this.addKeyResult(kr);
    }

    /**
     * 修改关键结果
     * @param kr 关键结果实体
     * @param name 关键结果名称
     * @param user 负责人
     * @param startDate 开始日期
     * @param endDate 结束日期
     * @param priority 重要性
     * @param measurements 衡量标准
     * @param remark 描述
     * @param weight 权重
     * @param isCustomUnit 是否自定义单位
     * @param unit 单位
     * @param unitStartValue 起始
     * @param unitEndValue 目标
     * @param linkTasks 关联任务
     */
    public updateKeyResultExpose(
        target: any,
        kr: KeyResult,
        name: string,
        user: any,
        startDate: string,
        endDate: string,
        priority: number,
        measurements: string,
        remark: string,
        weight: number,
        isCustomUnit: boolean,
        unit: string = "完成/未完成",
        unitStartValue: string = "未完成",
        unitEndValue: string = "完成",
        linkTasks: Array<any>
    ): Promise<BaseRsp> {
        name = this.trim(name);
        if (!name || name.length <= 0)
            return this.errRsp("请输入关键结果标题");
        if (Utils.isInCludeEmoji(name) || Utils.isInCludeEmoji(measurements) || Utils.isInCludeEmoji(remark) || Utils.isInCludeEmoji(unit))
            return this.errRsp("请勿输入emoji表情");
        if (!user.userId)
            return this.errRsp("请选择负责人");
        if (!startDate)
            return this.errRsp("请选择开始时间");
        if (!endDate)
            return this.errRsp("请选择结束时间");
        startDate = moment(startDate).format("YYYY-MM-DD");
        endDate = moment(endDate).format("YYYY-MM-DD");
        let isDateSatisfy: boolean = false;
        for (let cycle of target.cycleList) {
            if ((startDate >= cycle.cycleStartDate && startDate <= cycle.cycleEndDate) && (endDate >= cycle.cycleStartDate && endDate <= cycle.cycleEndDate)) {
                isDateSatisfy = true;
                break;
            }
        }
        if (!isDateSatisfy)
            return this.errRsp("起止时间不在目标周期范围内，请重新选择！");
        if (startDate > endDate)
            return this.errRsp("开始时间不得大于结束时间");
        if (weight < 0)
            return this.errRsp("权重不能小于0");
        if (isCustomUnit) {
            if (!unit) {
                return this.errRsp("请输入关键结果单位");
            }
            if (!unitStartValue) {
                return this.errRsp("请输入关键结果起始值");
            }
            if (!unitEndValue) {
                return this.errRsp("请输入关键结果目标值");
            }
        } else {
            unit = "完成/未完成"
            unitStartValue = "未完成"
            unitEndValue = "完成"
        }
        kr.modifierId = SystemUtils.loginUser.id;
        kr.modifier = SystemUtils.loginUser.name;
        kr.name = name;
        kr.userId = user.userId;
        kr.userName = user.name;
        kr.startDate = startDate;
        kr.endDate = endDate;
        kr.priority = priority;
        kr.measurements = measurements;
        kr.remark = remark;
        kr.weight = weight;
        kr.isCustomUnit = isCustomUnit ? 1 : 0;
        kr.unit = unit;
        kr.unitStartValue = unitStartValue;
        kr.unitEndValue = unitEndValue;
        kr.linkTasks = this.setTaskData(linkTasks, kr);
        return this.updateKeyResult(kr);
    }


    private setTaskData(linkTasks: Array<any>, kr: KeyResult) {
        let newTasks = new Array<any>(0);
        // if (kr.linkTasks && kr.linkTasks.length > 0) {
        //     for(let task of kr.linkTasks){
        //         if(linkTasks.filter(item => item.id === task.taskId)){
        //             newTasks.push(task);
        //         }else{
        //             newTasks.push(this.buildRelatedTask(task, kr.id as string));
        //         }
        //     }
        // } else {
        for (let task of linkTasks) {
            newTasks.push(this.buildRelatedTask(task, kr.id as string));
        }
        // }
        return newTasks;
    }

    private buildRelatedTask(task: any, krId: string): any {
        task = Object.assign(task, {
            sourceType: 2,
            sourceId: krId,
            taskId: task.id,
        });
        task.id = new BaseModel().generateIdNoSys();
        return task;
    }

    //获取关键结果详情
    public getDetail(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getDetail(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //我的关键结果列表
    public myResultsList(): any {
        return new Promise((resolve, reject) => {
            this.api.myResultsList().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}
