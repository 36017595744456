
    import {
        IonTabBar,
        IonTabButton,
        IonTabs,
        IonLabel,
        IonPage,
    } from "@ionic/vue";
    import {defineComponent} from "vue";
    import mainInfo from '../project/mainInfo.vue';
    import Document from '../project/Document.vue';
    import Plan from '../project/Plan.vue';
    export default defineComponent({
        name: "Tabs",
        components: {IonLabel, IonTabs, IonTabBar, IonTabButton, IonPage,mainInfo,Document,Plan},
        data() {
            return {
                queryParams:{planId:123},
                currentTab: "mainInfo",
                mainInfoUrl:'',
                documentUrl:'',
                planUrl:'',
                planId: 0,
                projectId: 0,
            };
        },
        methods: {
            bindTab(tabBtn: any) {
                this.currentTab = tabBtn;
            },
        },
        created() {
            if(this.$route.query.currentTab){
                this.currentTab = this.$route.query.currentTab as any;
            }
        }
    });
