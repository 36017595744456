
import { isPlatform,getPlatforms } from '@ionic/vue';

export default class NativeService {

  constructor() {
  }

  /**
 * 是否是真机环境
 * @returns {boolean}
 * @memberof NativeService
 */
  static isMobile(): boolean {
    return this.isPlatform().indexOf("capacitor") > -1;
  }

  /**
   * 是否android真机环境
   * @returns {boolean}
   * @memberof NativeService
   */
  static isAndroid(): boolean {
    return isPlatform('android');
  }

  isIos(): boolean {
    return isPlatform('ios');
  }

  static isPlatform(): Array<string> {
    return getPlatforms();
  }

}