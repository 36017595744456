import { isPlatform } from "@ionic/core";
import { Geolocation,PositionOptions } from '@capacitor/geolocation';

declare const BMap: any;
declare const navigator: any;

export default class LocationHelper {

    public static longitude: number = 0;//经度
    public static latitude: number = 0;//纬度
    public static locationAddress: string = '';//位置
    public static locationIntervalStatus: number = 0;//0:关闭，1:启动

    private static EARTH_RADIUS: number = 6378137;// 赤道半径(单位m)
    private static rad(d: number): number {
        return d * Math.PI / 180.0;
    }// 圆周率

    //启动获取位置坐标任务
    public static startLocationInterval() {
        if (LocationHelper.locationIntervalStatus == 1) {
            console.log("启动定位成功");
            if (isPlatform('ios')) {
                LocationHelper.GPSWatchPosition();
            }
            if (isPlatform('android') && isPlatform('capacitor')) {
                LocationHelper.BaiDuWatchPosition();
            }
        }
    }

    //清除获取位置坐标任务
    public static clearLocationInterval() {
        if (LocationHelper.locationIntervalStatus == 1) {
            if (isPlatform('ios')) {
                LocationHelper.GPSClearWatch();
            }
            if (isPlatform('android') && isPlatform('capacitor')) {
                LocationHelper.BaiDuClearWatch();
            }
        }
    }

    /** 百度定位 start */
    private static BaiDuWatchPosition(): void {
        if (!navigator.baidulocation) {
            return;
        }
        navigator.baidulocation.config('gcj02', 0);
        //可选，设置返回经纬度坐标类型，默认GCJ02
        //GCJ02：国测局坐标；
        //BD09ll：百度经纬度坐标；
        //BD09：百度墨卡托坐标；
        //WGS84: GPS
        //海外地区定位，无需设置坐标类型，统一返回WGS84类型坐标
        navigator.baidulocation.get((result: any) => {
            if (result.longitude == '5e-324' ||
                result.longitude == 0 ||
                result.latitude == 0) {
            } else {
                if (result.coorType == 'gcj02') {
                    const loc = LocationHelper.gcj02ToBd0911(result.longitude, result.latitude);
                    LocationHelper.longitude = loc.lon;
                    LocationHelper.latitude = loc.lat;
                } else {
                    LocationHelper.longitude = result.longitude;
                    LocationHelper.latitude = result.latitude;
                }
            }
        }, (err: any) => {
            alert("定位错误：" + JSON.stringify(err));
            console.log("定位错误：" + JSON.stringify(err));
        }, false);
    }
    private static BaiDuClearWatch(): void {
        navigator.baidulocation.stop();
    }
    /** 百度获取经纬度坐标 end */


    /** GPS获取经纬度坐标 start */
    private static gpsWatchId: string = "";
    //定时任务获取位置经纬度
    private static async GPSWatchPosition() {
        const options: PositionOptions = {
            enableHighAccuracy: true,
            timeout: 3000,
            maximumAge: 3000
        }
        LocationHelper.gpsWatchId = await Geolocation.watchPosition(options, (position: any) => {
            if (position) {
                LocationHelper.longitude = position.coords.longitude;
                LocationHelper.latitude = position.coords.latitude;
            }
        });
    }
    //停止定位
    private static GPSClearWatch() {
        if (LocationHelper.gpsWatchId != "") {
            const params = {
                id: LocationHelper.gpsWatchId
            }
            Geolocation.clearWatch(params).then((r: any) => { });
        }
    }
    /** GPS获取经纬度坐标 end */


    /**
    * 基于googleMap中的算法得到两经纬度之间的距离,计算精度与谷歌地图的距离精度差不多，
    * 相差范围在0.2米以下（单位m）
    * @param lon1 第一点的精度
    * @param lat1 第一点的纬度
    * @param lon2 第二点的精度
    * @param lat2 第二点的纬度
    * @return 返回的距离，单位m
    */
    public static getDistance(lon1: number, lat1: number, lon2: number, lat2: number): number {
        let radLat1: number = LocationHelper.rad(lat1);
        let radLat2: number = LocationHelper.rad(lat2);
        let a: number = radLat1 - radLat2;
        let b: number = LocationHelper.rad(lon1) - LocationHelper.rad(lon2);
        let s: number = 2 * Math.asin(Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
        s = s * LocationHelper.EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000;
        return s;
    }

    //获取详细的地址，精确到街道的名称
    public static makeLocationAddress(longitude: number, latitude: number) {
        let gc = new BMap.Geocoder();
        gc.getLocation(new BMap.Point(longitude, latitude), (rs: any, e: any) => {
            let addComp = rs.addressComponents;
            LocationHelper.locationAddress = addComp.city + addComp.district + addComp.street + addComp.streetNumber;
        });
    }

    private static pi: number = 3.141592653589793 * 3000.0 / 180.0;

    /**
     * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-0911) 的转换算法 将 GCJ-02 坐标转换成 BD-0911 坐标
     * 
     * @param lon
     * @param lat
     * @return
     */
    public static gcj02ToBd0911(lon: number, lat: number): any {
        let x = lon, y = lat;//火星坐标的经度和纬度
        let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * LocationHelper.pi);
        let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * LocationHelper.pi);
        let bd_lon = z * Math.cos(theta) + 0.0128;//百度坐标的经度
        let bd_lat = z * Math.sin(theta) + 0.0075;//百度坐标的纬度
        return { lon: bd_lon, lat: bd_lat };
    }

    /**
     * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-0911) 的转换算法   将 BD-0911 坐标转换成GCJ-02 坐标 
     * 
     * @param lon
     * @param lat
     * @return
     */
    public static bd0911ToGcj02(lon: number, lat: number): any {
        let x = lon - 0.0126, y = lat - 0.0080;
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * LocationHelper.pi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * LocationHelper.pi);
        let gcj02_lat = z * Math.sin(theta);//火星坐标纬度
        let gcj02_lon = z * Math.cos(theta);//火星坐标经度
        return { lon: gcj02_lon, lat: gcj02_lat };
    }

}