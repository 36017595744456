/**
 * projectUserstars 项目收藏 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目收藏/取消收藏
 */
export class ProjectMembersApi extends TaskBaseService {

    constructor() {
        super("projectMembers");
    } 
    //根据项目Id获取项目成员-App（用户不去重复）
    public getMemberByProjectIdApp(params: any): Promise<any> {
        const path = "getMemberByProjectIdApp";
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //根据项目Id获取项目成员-App（用户不去重复）
    public getPermissionUserByProjectIdApp(params: any): Promise<any> {
        const path = "getPermissionUserByProjectIdApp";
        return this.request(path, this.rest.METHOD_GET, params);
    }
    
    //是否存在成员
    public existsMember(params: any): Promise<any> {
        const path = "existsMember";
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //更新实体
    public updateModel(params: any): Promise<any> {
        const path = "";
        return this.request(path, this.rest.METHOD_PUT, params);
    }
    //删除团队成员
    public deleteMembers(params: any,projectId: any): Promise<any> {
        const path  = "deleteMembers?projectId="+projectId;
        return this.request(path, this.rest.METHOD_POST, params);
    }
    //添加成员
    public saveList(params: any): Promise<any> {
        const path  = "saveList";
        return this.request(path, this.rest.METHOD_POST, params);
    }
    //获取登陆人所在所有团队的成员列表
    public getUsersByProjectTeamApp(params: any): Promise<any> {
        const path = "getUsersByProjectTeamsApp";
        return this.request(path, this.rest.METHOD_GET, params);
    }
     //根据项目Id成员数量-App（用户去重复）
     public getMemberCountByProjectApp(params: any): Promise<any> {
        const path = "getMemberCountByProjectApp";
        return this.request(path, this.rest.METHOD_GET, params);
    }

    //根据项目Id成员数量-App（用户去重复、项目角色、团队角色 聚合）
    public getTeamMemberByProjectIdApp(params: any): Promise<any> {
        return this.request("getTeamMemberByProjectIdApp", this.rest.METHOD_GET, params);
    }

    public existProjectByUserId(params: any): Promise<any>{
        return this.request("existsProjectMember",this.rest.METHOD_GET,params);
    }
    //是否存在项目成员（批量检查）
    public existsProjectMembers(params: any): Promise<any>{
        return this.request("existsProjectMembers?projectId="+params.projectId,this.rest.METHOD_POST,params.userIds);
    }
    //获取项目成员
    public getDistinctMember(params: any): Promise<any>{
        return this.request("getDistinctMember",this.rest.METHOD_GET,params);
    }

    public updateProjectRole(id: string,projectRoleId:string): Promise<any>{
        return this.request("updateProjectRole?id="+id+"&projectRoleId="+projectRoleId,this.rest.METHOD_POST,{});
    }
}