
import { modalController, IonPage } from "@ionic/vue";
import { TaskLinkContentService } from "@/services/task/TaskLinkContentService";
import { ScheduleRelationService } from "@/services/schedule/ScheduleRelationService.ts";
import { TaskLinkContentType } from "@/models/task/Enum";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import TaskLinkProjectList from "./link/ProjectList.vue";
import TaskLinkTaskList from "./link/TaskList.vue";
import ProjectFileList from "./link/ProjectFileList.vue";
import TaskFileList from "./link/TaskFileList.vue";
import SearchBar from "@/components/SearchBar.vue";
import TaskLink from "@/services/task/model/TaskLink";
import { defineComponent } from "vue";
export default defineComponent({
  name: "TaskLinkList",
  props: {
    taskId: String,
    projectId: String,
    isIndependentTask: Boolean,
    linkContentList: Object,
    linkScheduleList: Object,
  },
  components: {
    HeaderModal,
    SearchBar,
    IonPage,
    TaskLinkProjectList,
    TaskLinkTaskList,
    ProjectFileList,
    TaskFileList,
  },
  data() {
    return {
      linkService: new TaskLinkContentService(),
      linkScheduleService: new ScheduleRelationService(),
      selSeg: 0,
      tabs: [
        {
          name: "项目",
          path: "TaskLinkProjectList",
          type: TaskLinkContentType.Project,
        },
        {
          name: "任务",
          path: "TaskLinkTaskList",
          type: TaskLinkContentType.Task,
        },
        {
          name: "项目文档",
          path: "ProjectFileList",
          type: TaskLinkContentType.Document,
        },
        {
          name: "日程",
          path: "TaskFileList",
          type: TaskLinkContentType.Schedule,
        },
      ],
      $refs: {
        component: TaskLinkProjectList,
      },
      searchKey: "",
    };
  },
  mounted() {
    // if(this.isIndependentTask){
    //   this.tabs.push({
    //     });
    // }
  },
  methods: {
    segmentChanged(e: any) {
      this.selSeg = e.detail.value;
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchKey = data.searchKey;
      // this.$refs.component.init(data.searchKey);
    },
    closeModel(type: number) {
      if (type == 4) {
        //文件不在此处保存
        if (typeof this.$refs.component.getSelectedItems != "function") {
          if (
            typeof this.$refs.component.hasAddFiles == "boolean" &&
            this.$refs.component.hasAddFiles
          ) {
            modalController.dismiss({ refresh: true });
            return;
          }
          modalController.dismiss();
          return;
        }
        //完成选择，保存数据并关闭模态
        const items = this.$refs.component.getSelectedItems();
        if (!items || items.length == 0) {
          Utils.presentToastTopWarning("请选择至少一项关联内容");
          return;
        }
        const type = this.tabs[this.selSeg].type;
        const newItemList = [];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (type == TaskLinkContentType.Schedule) {
            //不能包含已选
            if (this.linkScheduleList) {
              const itemIndex = this.linkScheduleList.findIndex((link: any) => {
                return item.scheduleTimeId == link.scheduleTimeId;
              });
              if (itemIndex > -1) {
                Utils.presentToastTopWarning("关联内容已经存在");
                return;
              }
            }
          } else {
            //不能包含已选
            if (this.linkContentList) {
              const itemIndex = this.linkContentList.findIndex((link: any) => {
                return (
                  item.targetId == link.targetId &&
                  item.type == "linked" &&
                  item.targetType == type
                );
              });
              if (itemIndex > -1) {
                Utils.presentToastTopWarning("关联内容已经存在");
                return;
              }
            }
            //不能包含自己
            if (item.targetId == this.taskId) {
              Utils.presentToastTopWarning("不能关联自己");
              return;
            }
            const newitem = new TaskLink(
              item.targetId,
              item.name,
              item.targetProjectId,
              type,
              this.taskId ? this.taskId : "",
              this.projectId ? this.projectId : "",
              TaskLinkContentType.Task
            );
            newItemList.push(newitem);
          }
        }

        //save 方法
        if (type == TaskLinkContentType.Schedule) {
          this.linkScheduleService.taskLinkSchedule(items).then((res: any) => {
            if (res) {
              // 提醒，添加成功
              Utils.presentToastSuccess("添加成功");
              //关闭页面
              modalController.dismiss({ refresh: true });
            } else {
              Utils.presentToastWarning("添加失败");
            }
          });
        } else {
          this.linkService.addLink(newItemList).then((res) => {
            if (res) {
              // 提醒，添加成功
              Utils.presentToastSuccess("添加成功");
              //关闭页面
              modalController.dismiss({ refresh: true });
            } else {
              Utils.presentToastWarning("添加失败");
            }
          });
        }
      } else {
        if (
          typeof this.$refs.component.hasAddFiles == "boolean" &&
          this.$refs.component.hasAddFiles
        ) {
          modalController.dismiss({ refresh: true });
          return;
        }
        modalController.dismiss();
      }
    },
  },
});
