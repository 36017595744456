
import { defineComponent } from "vue";
import GanntPage from "./GanntPage.vue";
import ProjectTreeView from "./ProjectTreeView.vue";

export default defineComponent({
  name: "ProjectView",
  components: { GanntPage, ProjectTreeView },
  data() {
    const data = [
      {
        id: 1,
        name: "一级 1",
        dd: "dd",
        cc: "cc",
        ee: "ee",
        children: [
          {
            id: 4,
            name: "二级 1-1",
            dd: "dd",
            cc: "cc",
            ee: "ee",
            children: [
              {
                id: 9,
                name: "三级 1-1-1",
                dd: "dd",
                cc: "cc",
                ee: "ee",
              },
              {
                id: 10,
                name: "三级 1-1-2",
                dd: "dd",
                cc: "cc",
                ee: "ee",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        name: "一级 2",
        dd: "dd",
        cc: "cc",
        ee: "ee",
        children: [
          {
            id: 5,
            name: "二级 2-1",
            dd: "dd",
            cc: "cc",
            ee: "ee",
          },
          {
            id: 6,
            name: "二级 2-2",
            dd: "dd",
            cc: "cc",
            ee: "ee",
          },
        ],
      },
      {
        id: 3,
        name: "一级 3",
        dd: "dd",
        cc: "cc",
        ee: "ee",
        children: [
          {
            id: 7,
            name: "二级 3-1",
            dd: "dd",
            cc: "cc",
            ee: "ee",
          },
          {
            id: 8,
            name: "二级 3-2",
            dd: "dd",
            cc: "cc",
            ee: "ee",
          },
        ],
      },
    ];
    return {
      data: JSON.parse(JSON.stringify(data)),
      areaData: [
        { label: "脑图", value: "left" },
        { label: "甘特图", value: "right" },
      ],
      currentView: "",
      curIndex: 0,
      isShowSelSeg: true,
    };
  },
  mounted() {},
  methods: {
    clickItem(data: any) {
      console.log(data);
    },
    changeArea(item: any, index: number) {
      this.currentView = item.value;
      this.curIndex = index;
    },
    showSegment(obj: any) {
      this.$emit("showSegment", obj); //显示导航
      if (obj == "show") this.isShowSelSeg = true;
      else this.isShowSelSeg = false;
    },
  },
});
