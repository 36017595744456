
import { defineComponent, reactive, ref, toRefs, watch } from "vue";
import SearchBar from "@/components/SearchBar.vue";
import { useRouter, useRoute } from "vue-router";
import TaskList from "@/views/task/TaskList.vue";
import ProjectList from "@/views/project/ProjectList.vue";
import TeamPage from "@/views/team/TeamPage.vue";
import Message from "@/views/message/Message.vue";
import MyCalendar from "@/views/calendar/my/MyCalendar.vue";
import MyOKRList from "@/views/home/search/modals/MyOKRList.vue";
import Setting from "@/rest/Setting";
import Utils from "../../../utils/Utils";

export default defineComponent({
  name: "HomeSearchList",
  components: {
    SearchBar,
    TaskList,
    ProjectList,
    TeamPage,
    Message,
    MyCalendar,
    MyOKRList
  },
  props: {
    searchValue: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      refresh: false,
    };
  },
  ionViewDidEnter() {
    setTimeout(() => {
      this.refresh = false;
    }, 100);
    this.refresh = true;
  },
  setup() {
    const route = useRoute();
    let rotate = false;
    let searchKey = ref("");
    let p = route.params.searchValue;
    if (typeof p == "string") {
      searchKey.value = p;
    }
    let selSeg = ref("0");
    let searchParam = "";
    const placeholderContent = ref(1);
    if (route.params.segmentType) {
      selSeg = ref(route.params.segmentType as string);
      searchParam = route.params.searchParam as string;
    }
    const currentSegmentComponents = reactive({
      data: [
        { name: "日程", module: "MyCalendar" },
        { name: "任务", module: "TaskList" },
        { name: "OKR", module: "MyOKRList" },
        // { name: "消息", module: "Message" },
        // { name: "团队", module: "TeamPage" },
      ],
    });
    if (!Setting.isSVW) {
      currentSegmentComponents.data.push({
        name: "项目",
        module: "ProjectList",
      });
    }

    const source = ref(route.params.source);
    //是否未首页概览按钮进入，是判断条件赋值，不是全部显示
    if (
      route.params.source == "homeTask" ||
      route.params.source == "homeSchedule"
    ) {
      if (route.params.source == "homeTask") {
        currentSegmentComponents.data = [{ name: "任务", module: "TaskList" }];
        placeholderContent.value = 2;
      } else {
        currentSegmentComponents.data = [
          { name: "日程", module: "MyCalendar" },
        ];
      }
    }

    const router = useRouter();

    const searchHandler = (v: any) => {
      if (v.searchKey != null && v.searchKey.trim() != "") {
        if (Utils.isInCludeEmoji(v.searchKey)) {
          Utils.presentToastWarning("搜索内容不可以包含表情");
          return;
        }
        const historyStorage = localStorage.getItem("searchHistory");
        let history = new Array(0);
        if (
          historyStorage != undefined &&
          historyStorage != "" &&
          historyStorage != null
        ) {
          history = JSON.parse(historyStorage);
        }
        if (history.length > 0) {
          history.some((item: any, index: number) => {
            if (v.searchKey == item.name) {
              history.splice(index, 1);
              return true;
            }
          });
        }
        if (history.length >= 10) {
          history.pop();
        }
        history.unshift({
          id: new Date().getTime().toString(),
          name: v.searchKey.trim(),
        });
        localStorage.setItem("searchHistory", JSON.stringify(history));
      }
      searchKey.value = v.searchKey;
    };
    const hasPage = ref(true);

    //返回
    const goBack = () => {
      router.go(-1);
    };
    
    watch([selSeg], (newValue, oldValue) => {
      if (newValue[0] != oldValue[0]) {
        let s:any=newValue[0];
        if (currentSegmentComponents.data[s].module == "TaskList") {
          placeholderContent.value = 2;
        } else {
          placeholderContent.value = 1;
        }
      }
    });

    return {
      currentSegmentComponents,
      selSeg,
      searchKey,
      searchParam, //首页传参过来的名称
      goBack,
      searchHandler,
      source,
      hasPage,
      placeholderContent,
    };
  },
});
