
import {
  modalController,
  IonPage,
  // IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
} from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "../../components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { UserRelationService } from "@/services/mine/UserRelationService";
import UserInfo from "../../services/selectexecutor/UserInfo";
import Utils from "@/utils/Utils";
import AvatarList from "@/components/AvatarList.vue";
export default defineComponent({
  name: "SelectSuperior",
  components: {
    IonPage,
    HeaderModal,
    // IonList,
    IonItem,
    SearchBar,
    IonRadioGroup,
    IonRadio,
    AvatarList,
  },
  data() {
    return {
      relationService: new UserRelationService(),
      executorAllList: new Array(0),
      leaderList: new Array(0),
      subordinate: new Array(0),

      executorSelectList: new Array(0),
      departmentName: "",

      relationParams: {
        limit: 10,
        offset: 1,
        keyName: "",
      },
    };
  },
  props: {
    intentExecutorSelectList: Object,
    isCheckBox: Boolean,
  },
  mounted() {
    this.getRelationData();
  },
  // created() {},
  methods: {
    avatarDataAllList(item: any) {
      let obj = { name: item.name, img: "", id: item.userId };
      return obj;
    },
    clickleaderRow(userInfo: any) {
      const indexSelected = this.executorSelectList.findIndex((item) => {
        return item.userId == userInfo.userId;
      });

      const allIndex = this.leaderList.findIndex((a) => {
        return a.userId == userInfo.userId;
      });
      if (indexSelected > -1) {
        this.executorSelectList.splice(indexSelected, 1);
        const temObje = this.leaderList[allIndex];
        temObje.isChecked = false;
        this.leaderList.splice(allIndex, 1, temObje);
      } else {
        const temObje = this.leaderList[allIndex];
        temObje.isChecked = true;
        this.leaderList.splice(allIndex, 1, temObje);

        if (this.isCheckBox == false) {
          this.executorSelectList.splice(0, this.executorSelectList.length);
          for (let index1 = 0; index1 < this.leaderList.length; index1++) {
            const element = this.leaderList[index1];
            console.log("leaderindex:"+index1+"allIndex:"+allIndex);
            
            if (index1 != allIndex) {
              element.isChecked = false;
              this.leaderList.splice(index1, 1, element);
            }
          }
          for (let index2 = 0; index2 < this.subordinate.length; index2++) {
            const element = this.subordinate[index2];
            element.isChecked = false;
            this.subordinate.splice(index2, 1, element);
          }
        }

        // let obj = Object.assign({}, userInfo);
        this.executorSelectList.push(
          Object.assign(userInfo, { isChecked: true })
        );
        // this.isSelected = userInfo.userId;
        // console.log("clickCheckRow");
      }
    },
    clickSubordinateRow(userInfo: any) {
      const indexSelected = this.executorSelectList.findIndex((item) => {
        return item.userId == userInfo.userId;
      });

      const allIndex = this.subordinate.findIndex((a) => {
        return a.userId == userInfo.userId;
      });
      if (indexSelected > -1) {
        this.executorSelectList.splice(indexSelected, 1);
        const temObje = this.subordinate[allIndex];
        temObje.isChecked = false;
        this.subordinate.splice(allIndex, 1, temObje);
      } else {
        const temObje = this.subordinate[allIndex];
        temObje.isChecked = true;
        this.subordinate.splice(allIndex, 1, temObje);

        if (this.isCheckBox == false) {
          this.executorSelectList.splice(0, this.executorSelectList.length);
          for (let index1 = 0; index1 < this.subordinate.length; index1++) {
            const element = this.subordinate[index1];
            console.log("index:"+index1+"allIndex:"+allIndex);
            if (index1 != allIndex) {
              element.isChecked = false;
              this.subordinate.splice(index1, 1, element);
            }
          }
          for (let index2 = 0; index2 < this.leaderList.length; index2++) {
            const element = this.leaderList[index2];
            element.isChecked = false;
            this.leaderList.splice(index2, 1, element);
          }
        }
        // let obj = Object.assign({}, userInfo);
        this.executorSelectList.push(
          Object.assign(userInfo, { isChecked: true })
        );
        // this.isSelected = userInfo.userId;
        // console.log("clickCheckRow");
      }
    },

    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      if (searchParam.searchKey == "") {
        this.relationParams.keyName = "";
        this.getRelationData();
      } else {
        this.relationParams.keyName = searchParam.searchKey;
        this.getRelationData();
      }
    },

    getRelationData() {
      this.relationService.getRelationList(this.relationParams).then(
        (r: any) => {
          if (r) {
            this.executorAllList = r;
            if (this.executorAllList.length == 0) {
              this.leaderList.splice(0, this.leaderList.length);
              this.subordinate.splice(0, this.subordinate.length);
            }

            if (this.intentExecutorSelectList != null) {
              if (this.intentExecutorSelectList.length != 0) {
                for (
                  let position = 0;
                  position < this.intentExecutorSelectList.length;
                  position++
                ) {
                  const element = this.intentExecutorSelectList[position];
                  this.executorSelectList.push(
                    Object.assign(element, { isChecked: true })
                  );
                  const allIndex = this.executorAllList.findIndex((a) => {
                    if (a.userType == 1) {
                      return a.leaderUserId == element.userId;
                    } else if (a.userType == 2) {
                      return a.subordinateUserId == element.userId;
                    }
                  });

                  if (allIndex > -1) {
                    const temObje = this.executorAllList[allIndex];
                    temObje.isChecked = true;
                    this.executorAllList.splice(allIndex, 1, temObje);
                  }
                }
              }
            }
            if (this.relationParams.offset == 1) {
              this.leaderList = [];
              this.subordinate = [];
            }

            for (let index = 0; index < this.executorAllList.length; index++) {
              const element = this.executorAllList[index];

              if (element.userType == 1) {
                const user = new UserInfo();
                user.id = element.id;
                user.name = element.userName;
                user.userId = element.leaderUserId;
                user.isChecked = element.isChecked;
                this.leaderList.push(user);
              } else if (element.userType == 2) {
                const user = new UserInfo();
                user.id = element.id;
                user.name = element.userName;
                user.userId = element.subordinateUserId;
                user.isChecked = element.isChecked;
                this.subordinate.push(user);
              }
            }
          }
        },
        (e: any) => {}
      );
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type) {
        if (type == 3) {
          modalController.dismiss();
        } else if (type == 4) {
          modalController.dismiss({
            executorSuperiorSelectList: this.executorSelectList,
          });
        }
      }
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
  },
});
