
import { IonSegment, IonSegmentButton, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { TaskService } from "@/services/task/TaskService";
import Utils from "@/utils/Utils";
import Header from "@/components/Header.vue";
import MicroIssuesList from "../microIssues/list/MicroIssuesList.vue";
import TaskInfo from "./info/TaskInfo.vue";
import ExecuteCaseList from "./info/executeCase/ExecuteList.vue";
import Chat from "@/views/message/Chat.vue";
import TaskMenu from "@/views/task/menu/TaskMenu.vue";
import TaskLinkList from "./menu/LinkList.vue";
import LinkSchedule from "./menu/LinkSchedule.vue";
import ConvertToChildTask from "./menu/ConvertToChildTask.vue";
import SetVisible from "./menu/SetVisible.vue";
import OperationLog from "./menu/OperationLog.vue";
import TaskSetting from "./menu/TaskSetting.vue";
import ProjectGroupingModal from "@/views/project/modal/ProjectGroupingModal.vue";
import Permission from "@/models/permission/Permission";
import SystemUtils from "@/utils/SystemUtils";
import { PermissionService } from "@/services/permission/PermissionService";
import { TaskModel } from "@/services/task/model/TaskModel";
import { TaskPermission } from "@/services/task/model/TaskPermission";
import ConvertToProjectTask from "./menu/moveToProject/ConvertToProjectTask.vue";
import Setting from "@/rest/Setting";
import TaskTransfer from "./menu/TaskTransfer.vue";
import EditTaskInfo from "@/views/task/info/model/EditTaskInfo.vue";
import { TaskUserService } from "@/services/task/TaskUserService";
import { nextTick } from "process";
export default defineComponent({
  name: "TaskPage",
  components: {
    IonSegment,
    IonSegmentButton,
    MicroIssuesList,
    TaskInfo,
    Header,
    Chat,
    LinkSchedule,
    ConvertToProjectTask,
    ExecuteCaseList,
  },
  watch: {
    $route(to, from) {
      if (from.path == to.path && to.path == "/task-page") {
        if (to.query.id != from.query.id) {
          this.taskId = to.query.id;
          this.getTaskInfoRequest();
        }
      }
    },
  },
  data() {
    const router = useRouter();
    const params = this.$route.query;
    const _taskPermissions: Array<Permission> = [];
    const _task: any = {};
    const _info: TaskModel = new TaskModel();
    const btnIconRight: any = ["icongengduo"];
    return {
      taskApi: new TaskService(),
      permissionService: new PermissionService(),
      taskUserApi: new TaskUserService(),
      router: router,
      info: _info,
      task: _task,
      taskPermissions: _taskPermissions,
      isIndependentTask: false, //是否独立任务
      useRoute: useRoute(),
      indicators: ["任务详情", "任务消息", "微议题", "执行情况"], //, "视图"
      selSeg: 0,
      taskName: params.taskName,
      btnIconRight: btnIconRight,
      btnIconRightEmpty: [],
      btnSizeRight: ["34px"],
      taskId: "", //任务Id
      showAdd: false,
      chatGroupId: "",
      folderId: "",
      isDeleted: false,
      isMember: false,
      projectName: "",
      projectId: "",
      $refs: {
        issuesList: MicroIssuesList,
        task: TaskInfo,
        chat: Chat,
      },
      isSVW: Setting.isSVW,
      showError: false, //展示错误页面
      errorMessage: "", //错误信息
      executorList: new Array(),

      isClick: true,
      isSelectVisible: false,
      showMenu: false, //右上角更多是否显示
      isExecutor: false, //当前登录人是否执行人
      isRecycle: "false",
    };
  },
  ionViewWillEnter() {
    if (this.useRoute.query.id != null) {
      this.taskId = this.useRoute.query.id as string;
    }
    this.getTaskInfoRequest();

    if (this.selSeg == 2) {
      let micList: any = this.$refs.issuesList;
      if (micList) micList.getListData(true);
    }
  },
  mounted() {
    if (this.useRoute.query["isRecycle"] != null) {
      this.isRecycle = this.useRoute.query["isRecycle"] as string;
    }
    this.updateRead();
  },

  methods: {
    //滚动到顶部
    scrollToTop() {
      nextTick(() => {
        let ionContent: any = document.querySelector("#taskMain");
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    },
    backPageHandler() {
      //Bus.$emit("receive_taskinfo_message", "从详情页返回信息"); // IssuesChat组件消息通知
    },
    segmentChanged(ev: any) {
      this.scrollToTop();
      this.selSeg = ev.detail.value;
      if (this.selSeg == 2) {
        this.showAdd = true;
        setTimeout(() => {
          let micList: any = this.$refs.issuesList;
          if (micList) micList.getListData(true);
        }, 300);
      } else {
        this.showAdd = false;
        if (this.selSeg == 2) {
          setTimeout(() => {
            this.$refs.chat.scrollToBottom();
          }, 100);
        }
      }
    },
    concatTaskHandler(param: any) {
      Object.assign(this.task, param);
    },
    //初始化任务权限
    initTaskPermissions() {
      this.taskApi
        .getTaskPermissionByUser(
          this.isIndependentTask,
          this.isIndependentTask ? this.taskId : this.info.project.id
        )
        .then((res) => {
          this.info.permission = new TaskPermission(
            res,
            this.info.taskRoles,
            this.info.isTaskDeleted,
            this.isIndependentTask
          );
          let task: any = this.$refs.task;
          if (task) task.initTaskPermission(this.info.permission);
          this.initTaskMenuShow();
        });
    },
    initTaskMenuShow() {
      // taskId: this.taskId,
      //     taskInfo: this.task,
      //     pers: this.info.permission,
      //     isTaskDeleted: this.task.isDeleted,
      let isIndependentTask = this.task && this.task.projectId ? false : true;
      let isShow = Setting.isSVW && isIndependentTask ? false : true;
      let pers = this.info.permission;
      let taskInfo = this.task;
      let isTaskDeleted = this.task.isDeleted;
      //添加子任务
      this.showMenu = false;
      if (!taskInfo.isFinished && pers.post) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //转为子任务
      if (!isIndependentTask && pers.transform && !taskInfo.parentId) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //转为独立任务
      if (!isIndependentTask && pers.transform && taskInfo.parentId) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //移动任务
      if (!isIndependentTask && pers.move && !taskInfo.parentId) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //转为项目任务
      if (isIndependentTask && !isTaskDeleted && isShow && pers.setting) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //关联内容
      if (this.hasLinkPermission(pers, isIndependentTask) && isShow) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //关联日程
      if (this.hasLinkPermission(pers, isIndependentTask)) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //编辑任务
      if (pers.name) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //可见性设置
      if (pers.taskvisible) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //任务设置
      if (isIndependentTask && !isTaskDeleted && pers.setting) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //复制
      if (pers.post) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //还原
      if (pers.revert) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //彻底删除
      if (pers.clear) {
        this.showMenu = true;
      }
      if (this.showMenu) return;
      //移至回收站
      if (pers.delete) {
        this.showMenu = true;
      }
    },
    hasLinkPermission(pers: any, isIndependentTask: any) {
      if (pers) {
        return (
          (!isIndependentTask && pers.linkpost) ||
          (isIndependentTask && pers.name)
        );
      }
      return false;
    },
    getTaskInfoRequest() {
      if (this.taskId) {
        this.taskApi.getTaskInfo(this.taskId).then((res) => {
          if (!res || !res.task || !res.task.id) {
            this.btnIconRight = null;
            this.errorMessage = "抱歉，该任务找不到";
            this.showError = true;
            return;
          }
          if (!res.task.isCheckVisible) {
            this.btnIconRight = null;
            this.errorMessage = "抱歉，你无权访问该页面";
            this.showError = true;
            return;
          }
          this.showError = false;
          this.btnIconRight = ["icongengduo"];
          this.task = res.task;
          this.task.taskVisibility = res.task.taskVisibility + "";
          this.isIndependentTask = res.project == null || res.project == "";
          this.info = new TaskModel(res);
          this.initTaskPermissions();
          let task: any = this.$refs.task;
          if (task) {
            this.$refs.task.initTaskInfo(this.info);
          }
          this.taskName = this.task.name;
          this.chatGroupId = this.task.chatGroupId;
          this.isDeleted = this.task.isDeleted;
          this.folderId = this.task.folderId;
          this.isMember = false;
          for (let user of res.taskUserList) {
            if (user.userId == SystemUtils.loginUser.id) {
              this.isMember = true;
              break;
            }
          }
          for (let user of res.taskUserList) {
            if (
              user.workType == 1 &&
              (user.userId == SystemUtils.loginUser.id ||
                user.creatorId == SystemUtils.loginUser.id)
            ) {
              this.isExecutor = true;
              break;
            }
          }
          if (this.$refs.issuesList) {
            this.$refs.issuesList.updateGetDataId(
              this.task.id,
              !this.isDeleted && this.isMember
            );
            this.$refs.issuesList.getListData(true);
          }
          let project = res.project;
          if (project) {
            this.projectName = project.name;
            this.projectId = project.id;
            this.folderId = project.folderId;
          } else {
            this.projectName = "";
            this.projectId = "";
          }
          if (this.$refs.chat) {
            this.$refs.chat.updateParam(
              this.chatGroupId,
              this.folderId,
              this.isDeleted,
              this.isMember,
              this.task.id,
              this.task.name,
              project ? "" : project.id,
              project ? "" : project.name,
              this.task.id
            );
          }
        });
      } else {
        Utils.presentToastWarning("该任务不存在");
        this.router.go(-1);
      }
    },
    getExecutor(users: any) {
      this.executorList = users.executor;
    },
    /********************----------任务按钮操作 Begin -----------************************* */
    //打开任务按钮
    async openTaskMenuModal() {
      if (this.isClick) {
        this.isClick = false;
        const modal = await modalController.create({
          component: TaskMenu,
          // cssClass: "task-bottom-modal-class",
          componentProps: {
            taskId: this.taskId,
            taskInfo: this.task,
            pers: this.info.permission,
            isTaskDeleted: this.task.isDeleted,
          },
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data && data.action) {
          this.isClick = true;
          //添加子任务
          switch (data.action) {
            case "addTask":
              // console.log(this.info.subTaskList);
              let sumPlanWorkingHour = 0;
              this.info.subTaskList.forEach((item) => {
                sumPlanWorkingHour += item.planWorkingHour;
              });
              console.log("子任务工时和 " + sumPlanWorkingHour);
              let maxWorkingHours = 0;
              maxWorkingHours = this.task.planWorkingHour - sumPlanWorkingHour;
              if (maxWorkingHours > 0) {
                this.gotoAddChildTaskPage(maxWorkingHours);
              } else {
                Utils.presentToastWarning("任务工时已用完，暂不可添加子任务");
              }

              break;
            case "linkContent":
              this.LinkContentHandler();
              break;
            case "LinkSchedule":
              this.LinkScheduleHandler();
              break;
            case "convertToChild":
              this.convertToChildTaskHandler();
              break;
            case "moveToRecycleBin":
              this.removeToRecycleRequest();
              break;
            case "setVisible":
              this.setVisibleHandle();
              break;
            case "operationLog":
              this.operationLogHandler();
              break;
            case "setTask":
              this.setTaskHandler();
              break;
            case "copyTask":
              this.copyTaskAsNewHandler();
              break;
            case "moveToStage":
              this.moveToStageHandler();
              break;
            case "convertToParentTask":
              this.moveToStageHandler(true);
              break;
            case "convertToProjectTask":
              this.convertToProjectTask();
              break;
            case "revert":
              this.revertTask();
              break;
            case "deleteComplete":
              this.deleteCompleteTaskHandler();
              break;
            case "taskTransfer":
              this.taskTransferHandler();
              break;
            case "editTask":
              this.editTaskHandler();
              break;
            default:
              break;
          }
        } else {
          this.isClick = true;
        }
      }
    },
    //还原任务
    revertTask() {
      this.taskApi.restoreById({ id: this.taskId }).then(
        (r: any) => {
          if (r) {
            //还原成功，返回上一页
            this.router.go(-1);
            Utils.presentToastSuccess("还原成功");
          }
        },
        (e: any) => {
          Utils.presentToastWarning("还原失败");
        }
      );
    },
    //彻底删除任务
    deleteCompleteTaskHandler() {
      this.taskApi.clearById({ id: this.taskId }).then((res: boolean) => {
        if (res) {
          //删除成功，返回上一页
          Utils.presentToastSuccess("删除成功");
          this.router.go(-1);
        }
      });
    },
    //添加子任务
    gotoAddChildTaskPage(maxWorkingHours: Number) {
      const childTask = {
        rootId: this.task.rootId,
        parentId: this.taskId,
        parentName: this.task.name,
        stageId: this.task.stageId,
        pos: 0,
        projectId: this.task.projectId,
        orgId: this.task.orgId,
        projectName: this.task.projectName,
        taskGroupName: this.task.taskGroupName,
        stageName: this.task.stageName,
        taskGroupId: this.task.taskGroupId,
        planItemId: this.task.planItemId,
        maxWorkingHours: maxWorkingHours,
      };
      this.router.push({
        name: "edit-task",
        params: {
          task: JSON.stringify(childTask),
          tagGroupId: this.info.project.tagGroupId,
          isDepenceTask: this.task.projectId == "" ? "false" : "true",
        },
      });
    },
    //复制任务
    copyTaskAsNewHandler() {
      let newTask = JSON.parse(JSON.stringify(this.$refs.task.info.task));
      //newTask=Object.assign(newTask,this.$refs.task.info.task);
      delete newTask.id;
      delete newTask.rootId;
      delete newTask.chatGroupId;
      delete newTask.parentId;
      delete newTask.parentName;
      newTask.childCount = 0;
      this.router.push({
        name: "edit-task",
        params: {
          task: JSON.stringify(newTask),
          tagGroupId: this.info.project.tagGroupId,
        },
      });
    },
    //移动任务
    async moveToStageHandler(isReFreshPage?: boolean) {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.task.taskGroupId, //分组Id
          isSelPhases: true, //是否选择阶段
          selPhaseId: this.task.stageId, //阶段Id
          projectId: this.task.projectId, //项目Id
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.staged) {
        //this.stage=data.staged;
        this.task.stageId = data.staged.taskStage.id;
        this.task.stageName = data.staged.taskStage.name;
        this.task.taskGroupId = data.staged.taskStage.taskGroupId;
        this.task.taskGroupName = data.group.name;
        //保存save
        this.taskApi
          .moveTask(
            this.task.id,
            this.task.projectId,
            this.task.taskGroupId,
            this.task.stageId
          )
          .then((res) => {
            if (res) {
              if (isReFreshPage) {
                //返回上一页
                Utils.presentToastSuccess("转为独立任务成功");
                this.getTaskInfoRequest(); //刷新详情页
                //this.router.go(-1);
              } else {
                this.info.task.stageId = data.staged.taskStage.id;
                this.info.task.stageName = data.staged.taskStage.name;
                this.info.task.taskGroupId = data.staged.taskStage.taskGroupId;
                this.info.task.taskGroupName = data.group.name;
                this.$refs.task.initTaskInfo(this.info);
              }
            }
          });
      }
    },
    //关联内容
    async LinkContentHandler() {
      const modal = await modalController.create({
        component: TaskLinkList,
        cssClass: "notice-modal-class",
        componentProps: {
          taskId: this.taskId,
          isIndependentTask: this.isIndependentTask,
          projectId: this.isIndependentTask ? "" : this.task.projectId,
          linkContentList: this.info.taskLinkList,
          linkScheduleList: this.info.taskLinkScheduleList,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        //刷新详情页
        this.getTaskInfoRequest();
      }
    },
    //关联日程
    async LinkScheduleHandler() {
      const modal = await modalController.create({
        component: LinkSchedule,
        cssClass: "notice-modal-class",
        componentProps: {
          taskId: this.taskId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        //刷新详情页
        this.getTaskInfoRequest();
      }
    },
    //转换为子任务事件
    async convertToChildTaskHandler() {
      const modal = await modalController.create({
        component: ConvertToChildTask,
        cssClass: "notice-modal-class",
        componentProps: {
          source: 0,
          taskId: this.taskId,
          isIndependentTask: this.isIndependentTask,
          projectId: this.isIndependentTask ? "" : this.task.projectId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        //刷新详情页
        this.getTaskInfoRequest();
      }
    },
    //独立任务转为项目任务
    async convertToProjectTask() {
      const modal = await modalController.create({
        component: ConvertToProjectTask,
        cssClass: "notice-modal-class",
        componentProps: {
          taskId: this.taskId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        //刷新详情页
        this.getTaskInfoRequest();
      }
    },
    //移到回收站
    removeToRecycleRequest() {
      this.taskApi.removeToRecycle(this.taskId).then((res) => {
        if (res) {
          this.router.go(-1);
          Utils.presentToast("移至回收站成功");
        }
      });
    },
    //设置可见性
    // async setVisibleHandler() {
    //   const param = {
    //     taskId: this.taskId,
    //     taskVisibility: this.task.taskVisibility,
    //   };
    //   if (this.task.projectId) {
    //     Object.assign(param, { projectId: this.task.projectId });
    //   }
    //   const modal = await modalController.create({
    //     component: SetVisible,
    //     cssClass: "bottom-openness-class",
    //     componentProps: param,
    //   });
    //   await modal.present();
    //   const { data } = await modal.onDidDismiss();
    //   if (data && data.taskVisibility) {
    //     this.task.taskVisibility = data.taskVisibility;
    //     this.info.task.taskVisibility = data.taskVisibility;
    //     this.$refs.task.initTaskInfo(this.info);
    //   }
    // },
    setVisibleHandle() {
      this.isSelectVisible = true;
      if (this.projectId == "") {
        let selectOperate: any = document.getElementById(
          "select-visible-single"
        );
        let el: HTMLElement = selectOperate;

        if (el != null && el != undefined) {
          el.click();
        }
      } else {
        let selectOperate: any = document.getElementById(
          "select-visible-project"
        );
        let el: HTMLSelectElement = selectOperate;
        if (el != null && el != undefined) {
          el.click();
        }
      }
    },
    bindSelectVisible(ev: any) {
      const updataData = {
        id: this.taskId,
        taskVisibility: ev.detail.value,
        setFields: ["taskVisibility"],
      };
      this.taskApi.updateFieldsById(updataData).then((response) => {
        if (response) {
          this.task.taskVisibility = ev.detail.value;
          this.info.task.taskVisibility = ev.detail.value;
          // Utils.presentToast("保存成功");
        } else {
          Utils.presentToast("保存失败");
        }
      });
      this.$refs.task.initTaskInfo(this.info);
    },
    //操作日志
    async operationLogHandler() {
      const modal = await modalController.create({
        component: OperationLog,
        cssClass: "notice-modal-class",
        componentProps: {
          busId: this.taskId,
        },
      });
      await modal.present();
    },
    //任务设置
    async setTaskHandler() {
      const modal = await modalController.create({
        component: TaskSetting,
        cssClass: "notice-modal-class",
        componentProps: {
          id: this.isIndependentTask ? this.taskId : this.info.project.id,
          isProject: !this.isIndependentTask,
          isHasPermission:
            this.isIndependentTask && this.info.permission.setting,
        },
      });
      await modal.present();
    },
    //任务转派
    async taskTransferHandler() {
      const modal = await modalController.create({
        component: TaskTransfer,
        cssClass: "notice-modal-class",
        componentProps: {
          taskId: this.taskId,
          projectId: this.info.project.id,
          executorList: this.executorList,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        //刷新详情页
        this.getTaskInfoRequest();
      }
    },
    //编辑任务
    async editTaskHandler() {
      const modal = await modalController.create({
        component: EditTaskInfo,
        cssClass: "notice-modal-class",
        componentProps: {
          taskId: this.taskId,
          folderId: this.folderId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        //刷新详情页
        this.getTaskInfoRequest();
      }
    },
    /********************----------任务按钮操作 End -----------************************* */
    // 新建微议题 任务
    addMicroIssues() {
      this.router.push({
        name: "MicroIssuesAdd",
        params: {
          type: "task",
          taskId: this.task.id,
          taskName: this.task.name,
          projectId: this.task.projectId,
          projectName: this.task.projectName,
        },
      });
    },
    //更新已读状态
    updateRead() {
      let params = { taskId: this.taskId, userId: SystemUtils.loginUser.id };
      this.taskUserApi.updateReadStatusByTaskIdAndUser(params).then((res) => {
        if (res) {
        }
      });
    },
  },
});
