
import { modalController, popoverController } from "@ionic/vue";
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  onUnmounted,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute } from "vue-router";
//组件
import ModuleMenu from "@/views/home/ModuleMenu.vue";
import SettingVue from "@/views/home/components/Setting.vue";
//import TaskTrackList from "./task/TaskTrackList.vue";
import SearchBar from "@/components/SearchBar.vue";
import moment from "moment";

import Overview from "@/views/home/Overview.vue";
//import CalendarList from "@/views/home/calendar/CalendarList.vue";
import MyTodayTask from "./task/MyTodayTask.vue";
import MyHomeSchedule from "./calendar/MySchedule.vue";
import HmModuleService from "@/services/home/customModule/HmModuleService";
import ProjectConditionList from "./project/ProjectConditionList.vue";
import TaskConditionList from "./task/TaskConditionList.vue";
import LeaderTaskList from "./task/LeaderTask.vue";
import SubordinateTask from "./task/SubordinateTask.vue";
import NoRelationTask from "./task/NoRelationTask.vue";
import MyHomeObjectivesList from "@/views/OKR/OKRManage/TargetList.vue";
import MyHomeRemind from "./remind/RemindList.vue";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import segmentPullDown from "@/views/home/components/segmentPullDown.vue";
import Utils from "@/utils/Utils";
import Setting from "@/rest/Setting";
import { WarningService } from "@/services/warning/WarningService";
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import "@/views/aiui/WavAudioEncoder.min";

export default defineComponent({
  name: "Home",
  components: {
    SearchBar,
    ModuleMenu,
    //TaskTrackList,
    MyTodayTask,
    //CalendarList,
    MyHomeSchedule,
    ProjectConditionList,
    TaskConditionList,
    LeaderTaskList,
    NoRelationTask,
    SubordinateTask,
    MyHomeObjectivesList,
    MyHomeRemind,
    TaskCards,
  },
  watch: {
    $route(to, from) {
      if (to.path != "/home") {
        return;
      } else {
        //获取未读数量
        this.getWarningCount();
      }
      if (from.path == "/tabs/mine") {
        this.initTabModules();
      } else {
        this.getAppQuery();
        let selectModule: any = this.tabModules[this.selectIndex];
        let that = this;
        if (selectModule) {
          that.refreshShedule = true;
          setTimeout(function () {
            that.refreshShedule = false;
          }, 1000);
        }
      }
    },
  },
  data() {
    const router = useRouter();
    let tabModules: any = new Array();
    const modules = JSON.parse(localStorage.getItem("myHomeModules") as any);
    if (modules && modules instanceof Array) {
      tabModules = modules;
    }
    const permissionService = new PermissionService();
    return {
      searchPanelService: new SearchPanelService(),
      permissionService: permissionService,
      moduleApi: new HmModuleService(),
      tabModules: tabModules,
      selectIndex: 0,
      router: router,
      noticeIcon: "icontixing",
      noticeSize: "34px",
      searchNameArr: new Array<any>(),
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "1",
        queryType: "",
      },
      searchSelectType: "",
      searchSelectId: "",
      refreshShedule: false,
      selectDate: "",
      isActivated: false,
      warningService: new WarningService(),
      warningUnreadCount: 0, //未读提醒数量
      isAddProject: false, //新增项目权限
    };
  },
  created() {
    this.getAppQuery();
    this.initTabModules();
    this.getWarningCount();
    this.getUserPermission();
  },
  methods: {
    initTabModules() {
      //todo是否有网？有网获取接口，否则从缓存读取
      this.moduleApi
        .getMyModules()
        .then((res) => {
          this.tabModules = res;
        })
        .catch((e) => {
          // Utils.presentToastWarning(e.data.msg);
          //请求失败，从缓存获取
          this.tabModules = JSON.parse(localStorage.getItem("myHomeModules") as any);
        });
    },
    getAppQuery() {
      // 是否需要隐藏 隐藏---上级指派 、下级请示 、同级协作
      if (Setting.isSVW) {
        this.searchListParams.queryType = "task";
      }
      this.searchPanelService.getAppQueryByUserId(this.searchListParams).then(
        (res: any) => {
          if (res) {
            this.searchNameArr = res;
          }
        },
        (e: any) => {}
      );
    },
    getModule() {
      let companentName = "TaskTrackList";
      if (this.selectIndex != -2) {
        if (this.tabModules && this.tabModules.length > 0) {
          const _select: any = this.tabModules[this.selectIndex];
          if (_select.pagePath) {
            companentName = _select.pagePath;
          }
        }
        return companentName;
      } else {
        if (this.searchSelectType == "project") {
          companentName = "ProjectConditionList";
        } else if (this.searchSelectType == "task") {
          companentName = "TaskConditionList";
        }
        return companentName;
      }
    },
    segmentChanged(ev: any) {
      this.selectIndex = Number.parseInt(ev);
      if (this.selectIndex != -2) {
        this.searchSelectId = "";
      }
      this.isActivated = false;
    },
    async openSettingModel(ev: any) {
      if (this.searchNameArr.length > 0) {
        const popover = await popoverController.create({
          component: segmentPullDown,
          cssClass: "myPullDown",
          event: ev,
          mode: "md",
          componentProps: {
            SelectId: this.searchSelectId,
            fromType: "taskList",
            searchNameArr: this.searchNameArr,
          },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data) {
          this.selectIndex = -2;
          this.searchSelectType = data.searchSelectType;
          this.searchSelectId = data.searchSelectId;
        }
      } else {
        Utils.presentToastWarning("您尚未创建自定义查询条件");
      }
    },
    async openModel() {
      const modal = await modalController.create({
        component: SettingVue,
        cssClass: "notice-modal-class",
        componentProps: {
          data: {
            content: "content",
          },
        },
      });
      await modal.present();
    },
    goNext(path: string) {
      this.router.push(path);
    },
    bindSearchHandler() {
      this.router.push({ name: "HomeSearchCondition" });
    },
    bindLeftOperate() {
      this.openRemindPage();
    },
    //弹出消息模态框
    async bindNoticeModal() {
      const modal = await modalController.create({
        component: Overview,
        cssClass: "notice-modal-class",
        componentProps: {
          data: {
            content: "content",
          },
        },
      });
      await modal.present();
    },
    //打开提醒页面
    openRemindPage() {
      this.router.push({
        path: "/MineRemindList",
      });
    },
    addModuleHandler(moduleId: string) {
      moduleId = moduleId.substring(0, moduleId.indexOf("."));
      switch (moduleId) {
        case "schedule":
          this.addScheduleHandler();
          break;
        case "task":
          this.addTaskHandler();
          break;
        case "okr":
          this.addOkrHandler();
          break;
        default:
          break;
      }
    },
    //添加日程
    addScheduleHandler() {
      const schedule = {
        pos: 0,
      };
      this.router.push({
        name: "add-schedule",
        params: {
          schedule: JSON.stringify(schedule),
          selectDate: this.selectDate,
        },
      });
    },
    //添加任务
    addTaskHandler() {
      const task = {
        pos: 0,
      };
      this.router.push({
        name: "edit-task",
        params: {
          task: JSON.stringify(task),
        },
      });
    },
    //添加okr
    addOkrHandler() {
      this.router.push({
        name: "addOKR",
      });
    },
    //添加项目
    addProjectHandler() {
      this.router.push("/project/add");
    },
    selectDateHandler(param: any) {
      this.selectDate = param;
    },
    getWarningCount() {
      this.warningService
        .getUnreadCount()
        .then((res: any) => {
          this.warningUnreadCount = res.data;
        })
        .catch(() => {});
    },
    //根据企业Id获取用户的添加项目权限
    getUserPermission() {
      this.permissionService.getByEnterpriseIdAndUser().then(
        (resp: AllResponse<Permission>) => {
          if (resp) {
            if (resp) {
              let permissionData = resp.data;
              //判断是否拥有新增项目权限
              let putInfo = permissionData.filter(
                (item) => item.id == "enterpriseproject.post.project"
              );
              if (putInfo.length < 1) {
                this.isAddProject = false;
              } else {
                this.isAddProject = true;
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    openTaskInfo(task: any) {
      this.router.push({
        path: "/task-page",
        query: {
          id: task.id,
          taskName: task.name,
        },
      });
    },
  },
});
