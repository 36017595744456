
import { defineComponent } from "vue";
import { getInstance } from "@/services/project/projectService";
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  IonIcon,
  modalController,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";

import Utils from "@/utils/Utils";
import Settings from "@/rest/Setting";
import ChooseOrganization from "@/views/project/modal/ChooseOrganization.vue";
import ProjectOpennessModal from "@/views/project/modal/ProjectOpennessModal.vue";
import ProjectTypeModal from "@/views/project/modal/ProjectTypeModal.vue";
import { useRouter } from "vue-router";
import BaseService from "@/services/base/BaseService";
import { ProjectTeamRolesService } from "@/services/project/ProjectTeamRolesService";

export default defineComponent({
  name: "NewTeamRole",
  components: {
    HeaderModal,
    IonContent,
    IonText,
    IonInput,
    IonIcon,
  },

  props: {
    datas: Object,
  },
  data() {
    const projectService = getInstance();
    const router = useRouter();
    return {
      staged: { id: "" },
      projectService: projectService,
      router: router,
      title: "",

      //团队名称
      teamName: "",
      //团队角色名称
      roleName: "",
      //角色备注
      roleRemark: "",

      //接收projectId
      childId: "",
      //团队ID
      projectTeamId: "",

      baseService: new BaseService(),

      projectTeamRolesService: new ProjectTeamRolesService(),

      //角色ID （当type为add时，接收为空，当type为edit时，接收值）
      projectTeamRoleId: "",
    };
  },

  created() {
    if (this.datas) {
      if (this.datas.type == "add") {
        this.title = "新建团队角色";
      } else {
        this.title = "编辑团队角色";
      }
      this.roleName = this.datas.name;
      this.roleRemark = this.datas.roleObj.remark;
      this.childId = this.datas.projectChildId;
      this.projectTeamId = this.datas.projectTeamId;
      this.teamName = this.datas.projectNameTitle;
    }
  },
  methods: {
    //获取备注输入框中的值
    bindContentChange(ev: any) {
      if (ev) {
        this.roleRemark = ev.detail.value;
      }
    },

    // 新建（标题栏 “完成” 按钮）

    onRightClick(type: any) {
      //  this.router.push("/project");

      if (type == 3) {
        modalController.dismiss();
      } else if (type == 4) {
        if (this.datas) {
          if (this.datas.type == "add") {
            this.projectTeamRoleId = BaseService.newId(
              "task",
              "ProjectTeamRole"
            );
          } else {
            this.projectTeamRoleId = this.datas.roleObj.id;
          }
        }

        const params = {
          projectTeamRoleId: this.projectTeamRoleId,
          projectTeamRoleName: this.roleName,
          projectId: this.childId,
        };
        this.roleName = this.baseService.trim(this.roleName);
        if (!this.roleName || this.roleName.length <= 0) {
          Utils.presentToastWarning("请输入团队角色名称");
          return;
        }
        this.projectTeamRolesService.getProjectExistsTeamRole(params).then(
          (r: any) => {
            if (r) {
              //新建角色时判断是否存在此角色，存在则提示，不存在则进行添加接口请求
              if (!r.data) {
                //不存在
                this.addProjectTeamRole(this.projectTeamRoleId);
              } else {
                Utils.presentToastWarning("同一团队中团队角色名称不允许重复");
              }
            } else {
              console.log("未选择信息");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },
    addProjectTeamRole(projectTeamRoleId: any) {
      const modelJson: any = this.baseService.newModel(
        "task",
        "ProjectTeamRole"
      );
      if (this.datas) {
        modelJson.id = projectTeamRoleId;
        modelJson.childCount = "0";
        modelJson.systemId = "task";
        modelJson.isOwner = false;
        modelJson.name = this.roleName;
        modelJson.nodeType = "teamRole";
        modelJson.parentId = this.projectTeamId;
        modelJson.projectId = this.childId;
        modelJson.projectTeamId = this.projectTeamId;
        modelJson.remark = this.roleRemark;
      }
      if (this.datas) {
        if (this.datas.type == "add") {
          this.projectTeamRolesService.addProjectTeamRole(modelJson).then(
            (r: any) => {
              if (r) {
                if (this.datas) {
                  modalController.dismiss({
                    type: this.datas.type,
                    roleObj: r.data,
                  });
                }
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        } else {
          this.projectTeamRolesService.editProjectTeamRole(modelJson).then(
            (r: any) => {
              if (r.data) {
                if (this.datas) {
                  this.datas.roleObj.name = this.roleName;
                  this.datas.roleObj.remark = this.roleRemark;
                  modalController.dismiss({
                    type: this.datas.type,
                    roleObj: this.datas.roleObj,
                  });
                }
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        }
      }
    },
  },
});
