
import { defineComponent, onMounted, onUnmounted, toRefs } from "vue";
import { FileHelper } from "@/utils/FileHelper";

export default defineComponent({
  name: "PreviewModal",
  props: {
    photos: {
      type: Array,
      required: true,
      default: [],
    },
    index: Number,
  },
  setup(props) {
    const { photos, index } = toRefs(props);
    let urls = new Array<any>();
    let page = 0;
    if (photos != undefined && photos.value != undefined) {
      urls = photos.value;
    }
    if (index != undefined && index.value != undefined) {
      page = index.value;
    }
    const fileHelper = new FileHelper();
    onMounted(() => {
      if (urls.length > 0) {
        fileHelper.previewImage(urls, page);
      }
    });
    onUnmounted(() => {
      if (FileHelper.imagePreviewFlag != 1) {
        FileHelper.imagePreviewFlag = 2;
        FileHelper.imagePreviewInstance.close();
      }
    });
    return {};
  },
});
