
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { TaskLinkContentService } from "@/services/task/TaskLinkContentService";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import SearchBar from "@/components/SearchBar.vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
export default defineComponent({
  name: "ScheduleProjectList",
  props: {
    paramList: Object,
  },
  components: {
    modalController,
    HeaderModal,
    SearchBar,
  },
  data() {
    return {
      linkApi: new TaskLinkContentService(),
      parameters: {
        userId: "",
        isDeleted: false,
        keyword: "",
        offset: 1,
        limit: 15,
        projectName: "",
        createTimeCB: "",
        createTimeCE: "",
        endTimeCB: "",
        endTimeEE: "",
        projectLight: "",
        createTimeOrder: "DESC",
        statusLightOrder: "DESC?offset=0&limit=0",
      },
      list: new Array(),
      isRollback: true,
      isModal: true,
      selectedItem: {},
      selectedId: "",
      selectList: new Array(),
    };
  },
  mounted() {
    const currentUser: any = SystemUtils.loginUser;
    this.parameters.userId = currentUser.id;
    if (this.paramList) {
      this.selectList = this.paramList.list;
    }
    this.init("");
  },
  methods: {
    init(keyword: any) {
      this.parameters.projectName = keyword;
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.init(data.searchKey);
    },
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        this.selectList.push(item);
      } else {
        this.selectList = this.selectList.filter((e) => {
          return e.id != item.id;
        });
      }
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            // event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      let that = this;
      this.linkApi.getToLinkProjectList(this.parameters).then((res) => {
        res.forEach((ele: any) => {
          let tempList = that.selectList.filter((sel: any) => {
            return sel.id == ele.id;
          });
          if (tempList.length == 0) {
            ele.checked = false;
          } else {
            ele.checked = true;
          }
        });
        callback(res);
      });
    },
    closeModel(type: any) {
      if (type == 4) {
        if (this.selectList.length > 0) {
          //关闭页面
          modalController.dismiss({
            selectedItem: this.selectList,
          });
        } else {
          modalController.dismiss({});
        }
      }
      modalController.dismiss({});
    },
  },
});
