
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";

import { CalendarService } from "@/services/calendar/CalendarService.ts";
import { Network } from "@ionic-native/network";

export default defineComponent({
  name: "AddCalendar",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    const calendarInfo: any = {
      id: "",
      name: "",
      color: "6c88d7", //最下面一排颜色值
      visible: "0",
      remark: "",
      relationProjectId: "",
      partnerIds: "",
    };
    const visibleList = [
      {
        id: "0",
        text: "私有（只有创建人和参与人可见）",
      },
      {
        id: "1",
        text: "公开（其他用户可申请订阅）",
      },
    ];
    return {
      calendarService: new CalendarService(),
      router: router,
      useRoute: useRoute(),
      calendarInfo: calendarInfo,
      isNewTask: true, //是否是新建任务
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["34px"],
      visibleList: visibleList,
      colorList: new Array(),
      infoParam: {
        id: "",
      },
      title: "",
      userList: new Array(),
      isClick: true,
    };
  },
  created() {
    //无网络连接
    if (Network.type === "none" || Network.type === "NONE") {
      Utils.presentToastWarning("网络连接失败，请检查网络");
    }
    if (this.useRoute.params && this.useRoute.params.info) {
      const infoParam = this.useRoute.params.info as string;
      this.infoParam = JSON.parse(infoParam);
      this.isNewTask = this.infoParam.id == null || this.infoParam.id == "";
      this.initColor();
      if (this.isNewTask) {
        this.initPageValue();
        this.title = "添加日历";
      } else {
        this.calendarInfo.id = this.infoParam.id;
        this.title = "编辑日历";
        //加载页面数据
        this.loadData();
      }
    }
  },
  methods: {
    initPageValue() {
      const currentUser: any = SystemUtils.loginUser;
      if (this.isNewTask) {
        //获取当前人信息
        const user = {
          id: currentUser.id,
          userId: currentUser.id,
          name: currentUser.name,
        };
        this.userList.push(user);
      }
    },
    initColor() {
      this.colorList = [
        { value: "c24341", index: 1 },
        { value: "fa8585", index: 2 },
        { value: "fcb777", index: 3 },
        { value: "94dba0", index: 4 },
        { value: "6bc9ab", index: 5 },
        { value: "85b8fa", index: 6 },
        { value: "6c88d7", index: 7 },
        { value: "796ad9", index: 8 },
      ];
    },
    loadData() {
      let params = { id: this.calendarInfo.id };
      this.calendarService.getCalDetail(params).then((res: any) => {
        if (res) {
          this.calendarInfo.visible = res.data.visible;
          this.calendarInfo.remark = res.data.remark;
          this.calendarInfo.color = res.data.color;
          this.calendarInfo.name = res.data.name;
          //解析参与人
          let parterList = res.data.calCalendarUserList;
          this.userList = new Array();
          for (let i = 0; i < parterList.length; i++) {
            this.userList.push({
              id: parterList[i].userId,
              name: parterList[i].userName,
            });
          }
          this.builderPartnerIds();
        }
      });
    },
    //可见性
    SelectVisibleInfo(ev: any) {
      // this.calendarInfo.visible = ev.detail.value;
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    bindStatusRadioCheck(ev: any) {
      if (ev) {
        //  this.calendarInfo.color = ev.detail.value;
      }
    },
    goBack() {
      this.router.go(-1);
    },
    //选择人员
    async selectJoiner() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.userList,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        this.userList = data.data;
        this.checkContaintsCurrentUser();
        this.builderPartnerIds();
      }
    },
    /**
     * 检查选择的参与人是否包含当前登录人，如果没有，则加上。
     * 参与人必须包含创建人
     */
    checkContaintsCurrentUser() {
      const currentUser: any = SystemUtils.loginUser;
      let isContainsCurrentUser = false;
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id == currentUser.id) {
          isContainsCurrentUser = true;
          break;
        }
      }
      if (!isContainsCurrentUser) {
        //获取当前人信息
        const user = {
          id: currentUser.id,
          name: currentUser.name,
        };
        this.userList.push(user);
      }
    },
    //拼接参与人id,以,分割
    builderPartnerIds() {
      let ids = "";
      for (let i = 0; i < this.userList.length; i++) {
        ids += ids == "" ? this.userList[i].id : "," + this.userList[i].id;
      }
      this.calendarInfo.partnerIds = ids;
    },
    validateForm() {
      this.calendarInfo.name = this.calendarInfo.name.replace(/\s*/g, "");
      if (
        !this.calendarInfo.name ||
        !this.calendarInfo.name.replace(/\s+/g, "")
      ) {
        Utils.presentToastWarning("请输入日历名称");
        return false;
      }
      if (Utils.isInCludeEmoji(this.calendarInfo.name)) {
        Utils.presentToastWarning("日历名称不可以包含表情");
        return false;
      }
      if (Utils.isInCludeEmoji(this.calendarInfo.remark)) {
        Utils.presentToastWarning("日历备注不可以包含表情");
        return false;
      }
      if (this.userList.length == 0) {
        Utils.presentToastWarning("请选择参与人");
        return false;
      }
      return true;
    },
    saveHandler() {
      if (this.isClick) {
        this.isClick = false;
        if (!this.validateForm()) {
          this.isClick = true;
          return;
        }
        this.calendarService
          .saveCalendarAndMem(this.calendarInfo)
          .then((res: any) => {
            if (res) {
              Utils.presentToastSuccess("保存成功");
              this.router.go(-1);
            }
          });
      }
    },
  },
});
