import TaskBaseService from '../base/TaskBaseService';

export class UserInfoByUserNameApi extends TaskBaseService {

    constructor() {
        super("users");
    }
    //查询部门下所有员工的信息
    public getAllTeamStaffList(params: any): Promise<any> {
        const path = 'getUserInfoByUserName';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**获取用户信息 */
    public getLoginUser() {
        return this.request("getLoginUser", this.rest.METHOD_GET);
    }

    /**通过拼音获取用户信息 */
    public getUserInfoByPinyin(params: any): Promise<any> {
        return this.request("getUserInfoByPinyin", this.rest.METHOD_GET, params);
    }
}