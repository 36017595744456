import { CalendarApi } from '@/api/calendar/CalendarApi';

import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';

/**
 * 日历模块
 */
export class CalendarService extends BaseService {
    private static calendarApi = new CalendarApi();

    // 我的日历列表（创建人或者参与人是我）
    public getPageMyCal({ offset = 1, limit = 10,calName='' }): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.getPageMyCal({
                offset,
                limit, 
                calName,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 我的回收站日历列表
    public getRecycleList({ offset = 1, limit = 10,calName='' }): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.getRecycleList({
                offset,
                limit,
                calName,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //日历还原  
    public recycleCal({ id = ""} = {}): any {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.recycleCal({id}).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //日历删除 
    public clearById(id:any): any {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.clearById(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 检查数据状态 DataState 插入,更新,删除实体
    public saveCalendarAndMem(parms: any): any {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.saveCalendarAndMem(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //删除
    public deleteById({ id = ""} = {}): any {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.deleteById({id}).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    // 获取日历详情，含参与人信息
    public getCalDetail({ id = "" }): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.getCalDetail({
                id
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 获取他人日历列表--根据人员选择订阅日历列表
    public getCalListByUserAndWorkUser({ offset = 1,limit=10,calName="",workUserId=[""] }): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarService.calendarApi.getCalListByUserAndWorkUser({
                offset,limit,calName,workUserId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}