/**
 * projects 控制器 api
 */
import BaseRsp from '@/models/base/BaseRsp';
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectApi extends TaskBaseService {

    constructor() {
        super("projects");
    }
    // 查询新建微议题时可选的项目列表
    public getProjectListByMember(params: any): Promise<BaseRsp> {
        const path = 'getAppProjectListByProjectMember';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    //首页点击项目模块，加载项目列表
    public getProjectList(params: any): Promise<any> {
        //  params.systemId="";
        //  params.partakeType="";
        const path = 'getAppProjectListByUser';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //创建项目
    public createProjectForData(params: any): Promise<any> {
        const path = 'createProjectForData';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    //选择任务的可关联项目列表
    public getProjectListByUser(params: any): Promise<any> {
        const path = 'getProjectListByUser';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    //选择项目信息
    public getProjectInfoById(params: any): Promise<any> {
        const path = '';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    //项目统计
    public getByIdForOverview(params: any): Promise<any> {
        const path = 'getByIdForOverview';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    //项目完成统计
    public getIndexProject(params: any): Promise<any> {
        const path = 'getIndexProject';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //项目归档
    public setFinishById(params: any): Promise<any> {
        const path = "setFinishById?projectId=" + params.projectId + "&&isFinsh=" + params.isFinsh;
        return this.request(path, this.rest.METHOD_POST, null);
    }
    //移到回收站
    public deleteProjectDataById(id: any): Promise<any> {
        const path = "app/deleteProjectDataById?id=" + id;
        return this.request(path, this.rest.METHOD_POST, null);
    }
    //回收站还原
    public recycleProject(id:any):Promise<any>{
        const path='app/recycleProject?id='+id;
        return this.request(path,this.rest.METHOD_POST, null)
    }

    //彻底删除
    public clearProject(id:any):Promise<any>{
        const path='app/clearProject?id='+id;
        return this.request(path,this.rest.METHOD_POST, null)
    }
    

    // //更新项目信息-转移项目
    // public updateFieldsById(params: any): Promise<any> {
    //     const path = "updateFieldsById";
    //     return this.request(path,this.rest.METHOD_PUT, params);       
    // }
    //获取项目信息
    public getInfoById(id: string): Promise<any> {
        const path = id;
        return this.request(path, this.rest.METHOD_GET, null);
    }
    //项目设置时获取项目信息
    public getForInforSet(params: any): Promise<any> {
        const path = "getForInforSetApp";
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //根据项目表示获取对象
    public getByIdForFinish(id: string): Promise<any> {
        const path = "getByIdForFinish";
        return this.request(path, this.rest.METHOD_GET, { id });
    }
    //更新项目状态
     public updateProjectStatus(params:any): Promise<any> {
        const path = "updateProjectStatus?projectId="+params.projectId+"&statusId="+params.statusId+"&&statusLight=";
        return this.request(path, this.rest.METHOD_PUT, {});
    }

    //获取有创建权限的组织，必须有参数userId
    public getProjectListWithCreatePermission(params: any){
        return this.request("getAppProjectListByUserPermission",this.rest.METHOD_GET,params);
    }
    //更新项目任务数量
    public refreshStatistics(params:any){
        return this.request("refreshStatistics?id="+params.id+"&isRefresh="+params.isRefresh,this.rest.METHOD_POST,params);
    }
    //获取我的已归档项目
    public getByFinishProjectList(params: any){
        return this.request("getByFinishProjectList",this.rest.METHOD_GET,params);
    }
    //根据用户id，获取项目信息
    public getProjectListByUserByID(params: any){
        return this.request("getProjectListByUserByID",this.rest.METHOD_GET,params);
    }
    //任务-项目列表视图
    public getProjectTask(params: any){
        return this.request("getMyTaskForAppProjectView",this.rest.METHOD_GET,params);
    }
}