import AttendanceBaseService from '../base/AttendanceBaseService';

export class RecordApi extends AttendanceBaseService {
    constructor() {
        super("attRecord");
    }

    /**
   * 获取用户考勤城市信息
   */
    public getAttenCity(params: any): Promise<any> {
        return this.request('attenCity', this.rest.METHOD_GET, params);
    }
    /**
   * 获取我的考勤
   */
    public getList(params: any): Promise<any> {
        return this.request('myAttenRecordList', this.rest.METHOD_GET, params);
    }

    /**
     * 考勤记录
     */
    public getAllList(params: any): Promise<any> {
        return this.request('list', this.rest.METHOD_GET, params);
    }

    /**
    获取详细
       */
    public getDetail(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }

    /**
     * 获取考勤地点
     * @param params 
     * @returns 
     */
    public getSignPlace(params: any): Promise<any> {
        return this.request('getSignPlace', this.rest.METHOD_GET, params);
    }

    /**
     * 获取用户打卡信息
     * @param params 
     * @returns 
     */
    public getUserInfo(params: any): Promise<any> {
        return this.request('attenCity', this.rest.METHOD_GET, params);
    }

    /**
     * 获取考勤记录
     * @param params 
     * @returns 
     */
    public getAttList(params: any): Promise<any> {
        return this.request('current/detail', this.rest.METHOD_GET, params);
    }
    /**
     * 签到提交
     * @param params 
     * @returns 
     */
    public saveAttendance(params: any): Promise<any> {
        let path="?address="+params.address+"&lat="+params.lat+"&lon="+params.lon+"&signType="+params.signType+"&userId="+params.userId;
        return this.request('singn/update'+path, this.rest.METHOD_POST, {});
    }

    /**
     * 获取考勤日志
     * @param params 
     * @returns 
     */
    public getDetaillog(params: any): Promise<any> {
        return this.request('detail/log', this.rest.METHOD_GET, params);
    }

}