import LogBaseService from '../base/LogBaseService';

export class LogApi extends LogBaseService {
    constructor() {
        super("datalogs/datalog");
    }

    public getLogList(param: any): Promise <any> {
        return this.request("", this.rest.METHOD_GET,param);
    }
}