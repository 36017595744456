
import { defineComponent, toRefs, ref, reactive } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import { FeedbackService } from "@/services/feedback/FeedbackService";
import Utils from "@/utils/Utils";
import { nextTick } from "process";
import { useRouter, useRoute } from "vue-router";
import { onBeforeMount } from "vue";
export default defineComponent({
  name: "HelperDetails",
  components: {
    Header,
  },
  props: {
    userId: String,
    folderId: String,
  },
  setup(props) {
    const content = "";
    //表单对象
    const feedbackObj = reactive({
      userId: "",
      folderId: "",
      name: "",
      type: 1,
      replyContent: "",
      remark: "",
      id: "",
    });
    // let type = ref(1);

    onBeforeMount(() => {
      const { id, name, replyContent } = useRoute().query;
      if (id) {
        getFeedback(id);
      } else {
        feedbackObj.name = name ? name.toString() : "";
        feedbackObj.type = -1;
        feedbackObj.replyContent = replyContent ? replyContent.toString() : "";
      }
    });

    const feedbackService = new FeedbackService();
    //保存提交
    const getFeedback = (id: any) => {
      feedbackService.getFeedback(id).then((r: any) => {
        console.log(r.data);
        if (r && r.data) {
          feedbackObj.userId = r.data.userId;
          feedbackObj.name = r.data.name;
          feedbackObj.type = r.data.type;
          feedbackObj.replyContent = r.data.replyContent;
          feedbackObj.remark = r.data.remark;
          feedbackObj.id = r.data.id;
        }
      });
    };

    return {
      feedbackObj,
      content,
    };
  },
});
