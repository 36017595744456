import { TagLinkApi } from '@/api/tag/TagLinkApi'

/**
 * 选择执行人及选择参与人
 */
export class TagLinkService {

    private static tagLinkApi = new TagLinkApi();

    //编辑任务获取标签列表
    public getTagListAndLinkList({
        groupId = "",
        busId = "",
        busTable = "",
    } = {}): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.getTagListAndLinkList({
                groupId,
                busId,
                busTable
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //创建任务获取标签列表
    public getTagList({
        groupId = "",
    } = {}): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.getTagList({
                groupId,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //添加标签
    public addTag(params: any): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.addTag(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新标签
    public updateTag(params: any): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.updateTag(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //删除标签
    public deleteTag({ id = "", } = {}): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.deleteTag(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //新建标签关联关系控制器实体
    public addTagLinks(params: any): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.addTagLinks(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    //删除标签关联关系控制器实体
    public clearTagLinks({
        groupId = "",
        busId = "",
        busTable = "",
        tagId = "",
    } = {}): any {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.clearTagLinks({
                groupId,
                busId,
                busTable,
                tagId,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
   * 项目-回收站-标签列表
   * */
    public getDeletedByGroupId(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.getDeletedByGroupId(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
    * 项目-回收站-标签列表--删除
    * */
    public clearById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.clearById(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
        * 项目-回收站-标签列表--还原
        * */
    public restoreById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            TagLinkService.tagLinkApi.restoreById(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}

export function getInstance() {
    return new TagLinkService();
}