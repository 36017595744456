 
import TaskBaseService from '../base/TaskBaseService';

 
export class SearchPanelApi extends TaskBaseService{
    constructor() {
        super("appQuerySave");
    }
   /**
     * 新建/编辑查询条件
     * @param data
     */
    public saveSearch(data: any,newId :string){ 
        if(newId){
            //新增
            data.id=newId;
            return this.request('', this.rest.METHOD_POST, data);
        }
        return this.request('', this.rest.METHOD_PUT, data);
    }
    //查询条件列表
    public getAppQueryByUserId(data: any){ 
        return this.request('getAppQueryByUserId', this.rest.METHOD_GET, data);
    }

    //根据Id查询条件
    public getById(id: string){ 
        return this.request(id, this.rest.METHOD_GET);
    }
    
}