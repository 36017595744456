
import {
  modalController,
  IonToggle,
  alertController,
  isPlatform,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils, { transformRequest } from "@/utils/Utils";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectCalendar from "../component/SelectCalendar.vue";
import SelectRepeat from "../component/SelectRepeat.vue";
import SelectRemind from "../component/SelectRemind.vue";
import SelectVisibleProject from "../../project/selectVisibleProject/selectVisibleProjectModel.vue";
import SelectTaskList from "../component/SelectTask.vue";
import ScheduleProjectList from "../component/SelectProject.vue";
import AddRepeat from "../add/AddRepeat.vue";

import { ScheduleService } from "@/services/schedule/ScheduleService";
import { MessageService } from "@/services/message/MessageService";
import { CalendarNative, EventOption } from "@/utils/CalendarNative";
import BaseRsp from "@/models/base/BaseRsp";
import { Network } from "@ionic-native/network";

export default defineComponent({
  name: "AddSchedule",
  components: {
    Header,
    IonToggle,
  },
  data() {
    const router = useRouter();
    const calendarNative = new CalendarNative();
    const scheduleInfo: any = {
      id: "",
      name: "",
      address: "",
      startTime: "",
      endTime: "",
      isAllday: false,
      remark: "",
      needFeedback: false,
      priority: "0",
      attachment: [],
      folderId: "",
      isRepeat: false,
      isFinish: false,
      isDeleted: false,
      updateType: "-1",
      chatGroupId: "",
      relationId: "",
      relationFirstId: "",
    };
    const weekday = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
    const weekday2 = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
      "星期日",
    ];
    return {
      scheduleService: new ScheduleService(),
      router: router,
      useRoute: useRoute(),
      calendarNative: calendarNative,
      more: false,
      isBack: true,
      btnIconRight: ["iconduihao"],
      schedule: scheduleInfo,
      isNewTask: true, //是否是新建任务
      btnSizeRight: ["34px"],
      startTime: {
        date: "",
        day: "",
        time: "",
      },
      endTime: {
        date: "",
        day: "",
        time: "",
      },
      repeat: {
        id: "never",
        text: "永不",
        repeat: "",
        span: 0,
        dateList: [],
        endTime: "",
      },
      oldRepeat: {
        id: "",
      },
      remind: {
        id: "-1",
        text: "无",
      },
      userList: new Array(),
      calendarSelect: {
        id: "",
        name: "",
      },
      calendarSelectList: new Array(),
      projectRelation: new Array(),
      projectShow: true,
      taskRelation: new Array(),
      taskShow: true,
      weekday: weekday,
      weekday2: weekday2,
      title: "",
      scheduleId: "",
      scheduleTimeId: "",
      showTime: true,
      selectDeleteType: -1,
      isClick: true,
      messageService: new MessageService(),
      displayFormat1: "YYYY-MM-DD HH:mm",
      displayFormat2: "YYYY-MM-DD",
      oldEventOption: {
        title: "",
        eventLocation: "",
        notes: "",
        startDate: new Date(),
        endDate: new Date(),
      },
      selectDate: "",
    };
  },
  created() {
    if (this.useRoute.params && this.useRoute.params.schedule) {
      const strschedule = this.useRoute.params.schedule as string;
      const scheduleParam: any = JSON.parse(strschedule);
      this.isNewTask =
        scheduleParam.scheduleId == null || scheduleParam.scheduleId == "";
      if (this.isNewTask) {
        this.title = "添加日程";
        this.schedule.id = Utils.getUuid;
      } else {
        this.title = "编辑日程";
        this.scheduleId = scheduleParam.scheduleId;
        this.scheduleTimeId = scheduleParam.scheduleTimeId;
      }
    }
    if (this.useRoute.params && this.useRoute.params.selectDate) {
      const selectDateParam: any = this.useRoute.params.selectDate as string;
      this.selectDate = selectDateParam;
    }
    this.initPageValue();
    this.calendarNative.checkReadWritePermission().then(
      (bo: boolean) => {},
      (e: boolean) => {}
    );
  },
  methods: {
    initPageValue() {
      const currentUser: any = SystemUtils.loginUser;
      if (this.isNewTask) {
        //无网络连接
        if (Network.type === "none" || Network.type === "NONE") {
          Utils.presentToastWarning("网络连接失败，请检查网络");
        }
        //获取当前人信息
        const user = {
          id: currentUser.id,
          userId: currentUser.id,
          name: currentUser.name,
        };
        this.userList.push(user);
        //初始化时间
        let today: Date = new Date();
        let todayEnd: Date = new Date();
        if (today.getMinutes() <= 30) {
          today.setMinutes(30);
          todayEnd.setMinutes(30);
        } else {
          today.setHours(today.getHours() + 1);
          today.setMinutes(0);
          todayEnd.setHours(todayEnd.getHours() + 1);
          todayEnd.setMinutes(0);
        }
        if (this.selectDate != "") {
          let time = moment(today)
            .format("yyyy-MM-DDTHH:mmZ")
            .substring(11, 16);
          today = new Date(this.selectDate.replace(/\-/g, "/") + " " + time);
          todayEnd = new Date(this.selectDate.replace(/\-/g, "/") + " " + time);
        }
        todayEnd.setHours(todayEnd.getHours() + 1);
        this.schedule.startTime = moment(today).format("yyyy-MM-DDTHH:mmZ");
        this.schedule.endTime = moment(todayEnd).format("yyyy-MM-DDTHH:mmZ");

        let info = this.formatDate(today);
        this.startTime.date = info.date;
        this.startTime.day = info.day;
        this.startTime.time = info.time;

        let infoEnd = this.formatDate(todayEnd);
        this.endTime.date = infoEnd.date;
        this.endTime.day = infoEnd.day;
        this.endTime.time = infoEnd.time;
      } else {
        this.getDetail();
      }
    },
    selectStartTime() {
      let startTimes: any = document.getElementsByClassName("start-picker");
      if (startTimes && startTimes[0]) {
        let el: HTMLElement = startTimes[0];
        el.click();
      }
    },
    selectEndTime() {
      let startTimes: any = document.getElementsByClassName("end-picker");
      if (startTimes && startTimes[0]) {
        let el: HTMLElement = startTimes[0];
        el.click();
      }
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    //开始时间
    startTimeChangeHandler(e: any) {
      this.schedule.startTime =
        e.detail.value.substring(0, 10) +
        " " +
        e.detail.value.substring(11, 16);
      if (e.detail.value) {
        let info = this.formatDate(e.detail.value);
        this.startTime.date = info.date;
        this.startTime.day = info.day;
        this.startTime.time = info.time;
      }
    },
    //结束时间
    endTimeChangeHandler(e: any) {
      this.schedule.endTime =
        e.detail.value.substring(0, 10) +
        " " +
        e.detail.value.substring(11, 16);
      if (e.detail.value) {
        let info = this.formatDate(e.detail.value);
        this.endTime.date = info.date;
        this.endTime.day = info.day;
        this.endTime.time = info.time;
      }
    },
    formatDate(value: any) {
      let info = {
        date: "",
        day: "",
        time: "",
      };
      let date: any = moment(value).format("yyyy-MM-DD");
      let todayDate = new Date();
      let dateToday: any = moment(todayDate).format("yyyy-MM-DD");
      todayDate.setDate(todayDate.getDate() - 1);
      let dateYestoday: any = moment(todayDate).format("yyyy-MM-DD");
      todayDate.setDate(todayDate.getDate() + 2);
      let dateTomorrow: any = moment(todayDate).format("yyyy-MM-DD");

      if (date == dateToday) {
        info.date = "今天";
      } else if (date == dateYestoday) {
        info.date = "昨天";
      } else if (date == dateTomorrow) {
        info.date = "明天";
      } else {
        info.date = date;
      }
      let day: number = moment(value).day();
      if (day == 0) {
        day = 7;
      }
      info.day = this.weekday[day - 1];
      let dateHour: any = moment(value).format("yyyy-MM-DD HH:mm");
      info.time = dateHour.substr(10, 6);
      return info;
    },
    filterUserItem(list: any, len: number) {
      if (list && list.length > 0) {
        return list.slice(0, len > list.length ? list.length : length);
      }
      return [];
    },
    goBack() {
      this.router.go(-1);
    },
    ismore() {
      this.more = !this.more;
    },
    //选择人员
    async selectJoiner() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.userList,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        this.userList = data.data;
        this.checkContaintsCurrentUser();
      }
    },
    /**
     * 检查选择的参与人是否包含当前登录人，如果没有，则加上。
     * 参与人必须包含创建人
     */
    checkContaintsCurrentUser() {
      const currentUser: any = SystemUtils.loginUser;
      let isContainsCurrentUser = false;
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].userId == currentUser.id) {
          isContainsCurrentUser = true;
          break;
        }
      }
      if (!isContainsCurrentUser) {
        //获取当前人信息
        const user = {
          userId: currentUser.id,
          name: currentUser.name,
        };
        this.userList.push(user);
      }
    },
    //选择日历
    async chooseCalendarHandler() {
      let list = new Array();
      this.calendarSelectList.forEach((ele: any) => {
        list.push({ id: ele.calId, name: ele.name, canCheck: true });
      });
      const modal = await modalController.create({
        component: SelectCalendar,
        cssClass: "notice-modal-class",
        componentProps: {
          paramList: { list: list },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();

      if (data && data.selectedCalendar) {
        if (data.selectedCalendar.length > 0) {
          let list = new Array();
          data.selectedCalendar.forEach((ele: any) => {
            list.push({ id: "", calId: ele.id, name: ele.name });
          });
          this.calendarSelectList = list;
        } else {
          this.calendarSelectList = new Array();
        }
      }
    },
    //选择重复
    async chooseRepeat() {
      let that = this;
      const modal = await modalController.create({
        component: SelectRepeat,
        cssClass: "notice-modal-class",
        componentProps: {
          paramItem: this.repeat,
          paramItemType: "repeat-calendar",
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedItem) {
        if (
          data.selectedItem &&
          data.selectedItem.id &&
          data.selectedItem.id != ""
        ) {
          if (data.selectedItem.id == -1) {
            that.selfCheckHandler();
          } else {
            that.repeat.id = data.selectedItem.id;
            that.repeat.endTime = moment(
              data.selectedItem.endTime == ""
                ? new Date()
                : data.selectedItem.endTime
            ).format("yyyy-MM-DD");
            that.getRepeatInfo();
            // that.repeat.text =
            //   data.selectedItem.text + "，截止：" + that.repeat.endTime;
            that.repeatDataInit();
          }
        }
        if (data.selectedItem == null || data.selectedItem.id == "") {
          that.repeat.id = "";
          that.repeat.text = "";
          that.repeat.endTime = "";
        }
      }
    },
    //自定义重复
    async selfCheckHandler() {
      const modal = await modalController.create({
        component: AddRepeat,
        cssClass: "notice-modal-class",
        componentProps: {
          paramItem: this.repeat,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        this.repeat.id = "diy";
        this.repeat.repeat = data.data.type;
        this.repeat.span = data.data.span;
        this.repeat.dateList = data.data.list;
        this.repeat.text = data.data.detail;
        this.repeat.endTime = moment(data.endTime).format("yyyy-MM-DD");
        this.repeat.text = data.data.detail + "，截止：" + this.repeat.endTime;
        this.repeatDataInit();
      }
    },
    repeatDataInit() {
      let that = this;
      if (that.repeat.id != "never") {
        that.projectRelation = [];
        that.projectShow = false;
        that.taskRelation = [];
        that.taskShow = false;
      } else {
        that.projectRelation = [];
        that.projectShow = true;
        that.taskRelation = [];
        that.taskShow = true;
      }
    },
    //选择提醒
    async chooseRemind() {
      const modal = await modalController.create({
        component: SelectRemind,
        cssClass: "notice-modal-class",
        componentProps: {
          paramItem: this.remind,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedItem) {
        if (
          data.selectedItem &&
          data.selectedItem.id &&
          data.selectedItem.id != ""
        ) {
          this.remind.id = data.selectedItem.id;
          this.remind.text = data.selectedItem.text;
        }
        if (data.selectedItem == null || data.selectedItem.id == "") {
          this.remind.id = "";
          this.remind.text = "";
        }
      }
    },
    //选择优先级
    choosePriority() {
      let selectOperate: any = document.getElementById(
        "select-priority-schedule"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
    //优先级
    bindPriority(e: any) {
      this.schedule.priority = e.detail.value;
    },
    //选择关联项目
    async chooseProjectRelation() {
      let list = new Array();
      this.projectRelation.forEach((ele: any) => {
        list.push({ id: ele.id, name: ele.text });
      });
      const modal = await modalController.create({
        component: ScheduleProjectList,
        cssClass: "notice-modal-class",
        componentProps: {
          paramList: { list: list },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedItem) {
        this.projectRelation = [];
        data.selectedItem.forEach((ele: any) => {
          this.projectRelation.push({
            id: ele.id,
            text: ele.name,
          });
        });
        if (data.selectedItem.length == 0) {
          this.projectRelation = [];
        }
      }
    },
    delProject(item: any, index: number) {
      this.projectRelation.splice(index, 1);
    },
    delTask(item: any, index: number) {
      this.taskRelation.splice(index, 1);
    },
    delCalendar(item: any, index: number) {
      this.calendarSelectList.splice(index, 1);
    },
    //选择关联任务
    async chooseTaskRelation() {
      let list = new Array();
      this.taskRelation.forEach((ele: any) => {
        list.push({ id: ele.id, name: ele.text });
      });
      const modal = await modalController.create({
        component: SelectTaskList,
        cssClass: "notice-modal-class",
        componentProps: {
          paramList: { list: list },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedItem) {
        this.taskRelation = [];
        data.selectedItem.forEach((ele: any) => {
          this.taskRelation.push({
            id: ele.id,
            text: ele.name,
          });
        });
        if (data.selectedItem.length == 0) {
          this.taskRelation = [];
        }
      }
    },
    isAllDayHandler(e: any) {
      this.showTime = !e.detail.checked;
      this.schedule.isAllday = e.detail.checked;
    },
    needFeedbackHandler(e: any) {
      this.schedule.needFeedback = e.detail.checked;
    },
    getDetail() {
      let params = {
        scheduleId: this.scheduleId,
        scheduleTimeId: this.scheduleTimeId,
      };
      this.scheduleService.getScheduleDetail(params).then((result: any) => {
        let res = result.data;
        this.schedule.id = res.id;
        this.schedule.name = res.name;
        this.schedule.address = res.address;
        this.schedule.startTime = res.startTime;
        this.startTime = this.formatDate(res.startTime);
        this.schedule.endTime = res.endTime;
        this.endTime = this.formatDate(res.endTime);
        this.schedule.address = res.address;
        this.schedule.priority = res.priority + "";
        this.schedule.isAllday = res.isAllday;
        this.showTime = !this.schedule.isAllday;
        this.schedule.remark = res.remark;
        this.schedule.scheduleEndTime = res.scheduleEndTime;
        this.schedule.scheduleStartTime = res.scheduleStartTime;
        this.schedule.scheduleTimeId = res.scheduleTimeId;
        this.schedule.chatGroupId = res.chatGroupId;
        this.schedule.folderId = res.folderId;
        this.schedule.needFeedback = res.needFeedback;
        this.schedule.relationId = res.relationId;
        this.schedule.relationFirstId = res.relationFirstId;
        this.schedule.mobileScheduleId = res.mobileScheduleId;

        this.initPermission();

        //日历
        if (res.calendarDetailList) {
          this.calendarSelectList = [];
          res.calendarDetailList.forEach((ele: any) => {
            this.calendarSelectList.push({
              id: ele.id,
              calId: ele.calId,
              name: ele.calendarName,
            });
          });
        }
        //参与人反馈
        this.userList = [];
        if (
          res.scheduleParticipantList &&
          res.scheduleParticipantList.length > 0
        ) {
          res.scheduleParticipantList.forEach((ele: any) => {
            this.userList.push({
              id: ele.userId,
              userId: ele.userId,
              name: ele.userName,
            });
          });
        }
        //重复
        let repeatParams: any = JSON.parse(res.scheduleRepeatSetting.parameter);
        this.repeat.id = repeatParams.type;
        this.repeat.repeat = repeatParams.repeat;
        this.repeat.span = repeatParams.span;
        this.repeat.dateList = repeatParams.dateList;
        this.repeat.endTime = res.scheduleRepeatSetting.deadTime;
        this.getRepeatInfo();
        this.oldRepeat.id = this.repeat.id;
        if (this.repeat.id == "never") {
          this.projectShow = true;
          this.taskShow = true;
        } else {
          this.projectShow = false;
          this.taskShow = false;
        }

        //提醒
        //remind: { id: "id1",text: "每十分钟提醒一次",},
        //repeat.id形式：1-2-hour
        if (
          res.sheduleWarningRuleModel &&
          res.sheduleWarningRuleModel.id != null
        ) {
          if (
            res.sheduleWarningRuleModel.typeValue == -1 ||
            res.sheduleWarningRuleModel.typeValue == 0
          ) {
            this.remind.id = res.sheduleWarningRuleModel.typeValue + "";
          } else {
            this.remind.id =
              res.sheduleWarningRuleModel.typeValue +
              "-" +
              res.sheduleWarningRuleModel.intervals +
              "-" +
              res.sheduleWarningRuleModel.unit;
          }
        }
        this.remind.text = this.getRemindInfo(res.sheduleWarningRuleModel);
        //项目关联
        if (
          res.projectRelation &&
          res.projectRelation != "" &&
          res.projectRelation != [] &&
          res.projectRelation.length > 0
        ) {
          res.projectRelation.forEach((ele: any) => {
            this.projectRelation.push({
              id: ele.formId,
              text: ele.relationName,
            });
          });
        }
        //任务关联
        if (
          res.taskRelation &&
          res.taskRelation != "" &&
          res.taskRelation != [] &&
          res.taskRelation.length > 0
        ) {
          res.taskRelation.forEach((ele: any) => {
            this.taskRelation.push({ id: ele.formId, text: ele.relationName });
          });
        }
      });
    },
    initPermission() {
      this.calendarNative.checkReadWritePermission().then(
        (bo: boolean) => {},
        (e: boolean) => {}
      );
    },
    //拼接重复内容
    getRepeatInfo() {
      //重复参数格式 type: never:永不 everyday:每天 everyweek:每周  everytwoweek:每两周 everymonth:每月  everyyear:每年 everyworkday:每个工作日.diy:自定义
      switch (this.repeat.id) {
        case "never":
          this.repeat.text = "永不";
          break;
        case "everyday":
          this.repeat.text = "每天";
          break;
        case "everyweek":
          this.repeat.text = "每周";
          break;
        case "everytwoweek":
          this.repeat.text = "每两周";
          break;
        case "everymonth":
          this.repeat.text = "每月";
          break;
        case "everyyear":
          this.repeat.text = "每年";
          break;
        case "diy":
          //{type:'diy',repeat:'day',span:1,dateList:[1,2]}
          switch (this.repeat.repeat) {
            case "day":
              if (this.repeat.span != -1) {
                this.repeat.text = "事件将每" + this.repeat.span + "天重复一次";
              }
              break;
            case "week":
              if (this.repeat.span != -1 && this.repeat.dateList.length > 0) {
                let str = "";
                for (let i = 0; i < this.repeat.dateList.length; i++) {
                  str +=
                    str == ""
                      ? this.weekday2[this.repeat.dateList[i]]
                      : "," + this.weekday2[this.repeat.dateList[i]];
                }
                this.repeat.text =
                  "事件将每" + this.repeat.span + "周于" + str + "重复一次";
              }
              break;
            case "month":
              if (this.repeat.span != -1) {
                let str = "";
                for (
                  let index = 0;
                  index < this.repeat.dateList.length;
                  index++
                ) {
                  str +=
                    str == ""
                      ? this.repeat.dateList[index] + "日"
                      : "," + this.repeat.dateList[index] + "日";
                }
                0;
                this.repeat.text =
                  "事件将每" + this.repeat.span + "个月于" + str + "重复一次";
              }
              break;
          }
          break;
      }
      if (this.repeat.id != "never") {
        this.repeat.text =
          this.repeat.text +
          "，截止：" +
          moment(this.repeat.endTime).format("yyyy-MM-DD");
      } else {
        this.repeat.text = "永不";
      }
    },
    getRemindInfo(model: any) {
      let typeValue = "";
      switch (model.typeValue) {
        case 0:
          typeValue = "时";
          break;
        case 1:
          typeValue = "前";
          break;
      }
      let unit = "";
      switch (model.unit) {
        case "day":
          unit = "天";
          break;
        case "min":
          unit = "分钟";
          break;
        case "hour":
          unit = "小时";
          break;
      }
      if (model.typeValue + "" == "") {
        return "";
      } else if (model.typeValue == -1) {
        return "无";
      } else if (model.typeValue == 0) {
        return "任务开始时提醒一次";
      } else {
        return "任务开始" + typeValue + model.intervals + unit + "提醒一次";
      }
    },
    validateForm() {
      this.schedule.name = this.schedule.name.replace(/\s*/g, "");
      if (!this.schedule.name || !this.schedule.name.replace(/\s+/g, "")) {
        Utils.presentToastWarning("请输入日程名称");
        return false;
      }
      if (Utils.isInCludeEmoji(this.schedule.name)) {
        Utils.presentToastWarning("日程名称不可以包含表情");
        return false;
      }
      // if (this.calendarSelect.id == "") {
      //   Utils.presentToastWarning("请选择关联日历");
      //   return false;
      // }
      return true;
    },
    bindOperateEdit(e: any) {
      this.schedule.updateType = e.detail.value;
      if (this.schedule.updateType == 1 || this.schedule.updateType == 3) {
        this.saveHandler();
      }
    },
    finishHandler() {
      if (this.isNewTask) {
        this.saveHandler();
      } else {
        //this.oldRepeat.id
        //非重复数据直接保存
        if (this.repeat.id == "never") {
          if (this.oldRepeat.id != "never") {
            this.confirmDialog();
          } else {
            this.schedule.updateType = 1;
            this.saveHandler();
          }
        } else {
          //重复数据提示保存本条数据还是本条及以后数据
          let selectOperate: any = document.getElementById(
            "select-operate-edit-schedule"
          );
          let el: HTMLElement = selectOperate;
          el.click();
        }
      }
    },
    async confirmDialog() {
      const alert = await alertController.create({
        header: "确认",
        message:
          "您更新的日程为重复日程，将此日程更新为永不重复后，将取消此日程以后的重复数据。请确认是否操作吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.schedule.updateType = 4;
              this.saveHandler();
            },
          },
        ],
      });
      await alert.present();
    },
    saveHandler() {
      if (this.isClick) {
        this.isClick = false;
        if (!this.validateForm()) {
          this.isClick = true;
          return;
        }

        if (Utils.isInCludeEmoji(this.schedule.address)) {
          Utils.presentToastWarning("地点名称不可以包含表情");
          this.isClick = true;
          return;
        }

        if (Utils.isInCludeEmoji(this.schedule.remark)) {
          Utils.presentToastWarning("日程描述不可以包含表情");
          this.isClick = true;
          return;
        }

        let that = this;
        let today = moment(new Date()).format("yyyy-MM-DD");

        that.schedule.startTime = moment(that.schedule.startTime).format(
          "yyyy-MM-DD HH:mm:ss"
        );
        that.schedule.endTime = moment(that.schedule.endTime).format(
          "yyyy-MM-DD HH:mm:ss"
        );
        if (that.schedule.isAllday) {
          that.schedule.startTime =
            moment(that.schedule.startTime)
              .startOf("day")
              .format("yyyy-MM-DD HH:mm:ss") + "";
          that.schedule.endTime =
            moment(that.schedule.endTime)
              .endOf("day")
              .format("yyyy-MM-DD HH:mm:ss") + "";
        }
        let startTime = that.schedule.startTime;
        let endTime = that.schedule.endTime;

        let startDate = new Date(startTime.replace(/\-/g, "/"));
        let endDate = new Date(endTime.replace(/\-/g, "/"));
        if (startDate.getTime() >= endDate.getTime()) {
          Utils.presentToastWarning("开始时间不能晚于或等于截止时间");
          this.isClick = true;
          return;
        }
        //关联日历
        let calendarDetailList = new Array();
        if (this.calendarSelectList.length > 0) {
          this.calendarSelectList.forEach((ele: any) => {
            calendarDetailList.push({
              id: Utils.getUuid,
              calId: ele.calId,
              sourceId: that.schedule.id,
              sourceType: 1,
              isDeleted: 0,
            });
          });
          that.schedule.calendarDetailList = calendarDetailList;
        }
        //参与人
        let scheduleParticipantList = new Array();
        that.userList.forEach((ele: any) => {
          scheduleParticipantList.push({
            id: Utils.getUuid,
            scheduleId: that.schedule.id,
            userId: ele.userId,
            userName: ele.name,
          });
        });
        that.schedule.scheduleParticipantList = scheduleParticipantList;
        //重复
        //重复参数格式 type: never:永不 everyday:每天 everyweek:每周  everytwoweek:每两周 everymonth:每月  everyyear:每年 everyworkday:每个工作日.diy:自定义
        //{type:'never'}
        //自定义，repeat： day：按天（dateList:数字为间隔天数） week:按周（dateList:1~7对应周一到周日） month:按月（dateList:数字为每个月的几号）
        //{type:'diy',repeat:'day',span:1,dateList:[1,2]}
        let repeatParams = {
          type: that.repeat.id,
          repeat: that.repeat.repeat,
          span: that.repeat.span,
          dateList: that.repeat.dateList,
        };
        that.repeat.endTime =
          that.repeat.endTime == null || that.repeat.endTime == ""
            ? today
            : that.repeat.endTime;
        let deadTimeStr = "";
        if (that.repeat.endTime.length == 10) {
          deadTimeStr =
            moment(that.repeat.endTime)
              .endOf("day")
              .format("yyyy-MM-DD HH:mm:ss") + "";
        } else {
          deadTimeStr =
            moment(that.repeat.endTime.substring(0, 10))
              .endOf("day")
              .format("yyyy-MM-DD HH:mm:ss") + "";
        }
        let scheduleRepeatSetting = {
          id: Utils.getUuid,
          scheduleId: that.schedule.id,
          parameter: JSON.stringify(repeatParams),
          deadTime: deadTimeStr,
        };
        //重复截止日期不能早于开始日期
        let deadTime = scheduleRepeatSetting.deadTime;
        if (repeatParams.type != "never") {
          let deadDate = new Date(deadTime.replace(/\-/g, "/"));
          if (startDate.getTime() > deadDate.getTime()) {
            Utils.presentToastWarning("开始时间不能晚于重复周期截止时间");
            this.isClick = true;
            return;
          }
        }

        that.schedule.scheduleRepeatSetting = scheduleRepeatSetting;
        if (repeatParams.type == "never") {
          that.schedule.isRepeat = false;
        } else {
          that.schedule.isRepeat = true;
        }

        //提醒
        let warningId = Utils.getUuid;
        let sheduleWarningRuleModel = {
          id: warningId,
          scheduleId: that.schedule.id,
          typeValue: -1,
          description: "",
          unit: "",
          intervals: 0,
        };
        let remindInfo = that.remind.id;
        //-1:不提醒
        if (remindInfo != "-1") {
          if (remindInfo != "0") {
            //repeat.id形式：1-2-hour
            let s = remindInfo.split("-");
            if (s.length == 3) {
              sheduleWarningRuleModel.typeValue = parseInt(s[0]);
              sheduleWarningRuleModel.intervals = parseInt(s[1]);
              sheduleWarningRuleModel.unit = s[2];
            }
          } else {
            sheduleWarningRuleModel.typeValue = 0;
          }
        }
        that.schedule.sheduleWarningRuleModel = sheduleWarningRuleModel;
        //提醒人
        let scheduleWarnList = new Array();
        that.userList.forEach((ele: any) => {
          scheduleWarnList.push({
            id: Utils.getUuid,
            wariningId: warningId,
            userId: ele.userId,
            userName: ele.name,
          });
        });
        that.schedule.scheduleWarnList = scheduleWarnList;

        //关联项目
        let projectRelations = new Array();
        that.projectRelation.forEach((ele: any) => {
          projectRelations.push({ formId: ele.id, type: 2 });
        });
        if (that.projectRelation.length > 0 && that.repeat.id == "never") {
          that.schedule.projectRelation = projectRelations;
        }
        //关联任务
        let taskRelations = new Array();
        that.taskRelation.forEach((ele: any) => {
          taskRelations.push({ formId: ele.id, type: 1 });
        });

        if (that.taskRelation.length > 0 && that.repeat.id == "never") {
          that.schedule.taskRelation = taskRelations;
        }
        if (this.isNewTask) {
          that.scheduleService
            .insertSchedule(that.schedule)
            .then((res: any) => {
              if (res.data.isSuccess) {
                Utils.presentToastSuccess("保存成功");
                //发送消息
                that.send(res.data, "add");
                that.schedule.id = res.data.id;
                that.saveCalendarEvent(
                  that.schedule.name,
                  that.schedule.address,
                  that.schedule.remark,
                  that.schedule.scheduleParticipantList,
                  startDate,
                  endDate,
                  ""
                );
                //  that.router.go(-1);
              }
            });
        } else {
          that.scheduleService
            .updateSchedule(that.schedule)
            .then((res: any) => {
              if (res.data.isSuccess) {
                Utils.presentToastSuccess("保存成功");
                //发送消息
                that.send(res.data, "update");
                that.schedule.id = res.data.id;
                that.saveCalendarEvent(
                  that.schedule.name,
                  that.schedule.address,
                  that.schedule.remark,
                  that.schedule.scheduleParticipantList,
                  startDate,
                  endDate,
                  that.schedule.mobileScheduleId
                );
                // that.router.go(-1);
              }
            });
        }
      }
    },
    generateNotes(partUsers: Array<any>): string {
      let noteUsers = "";
      if (partUsers != undefined && partUsers.length > 0) {
        noteUsers = "参与人：";
        partUsers.forEach((user: any) => {
          noteUsers += user.userName + "、";
        });
        noteUsers = noteUsers.substring(0, noteUsers.length - 1);
      }
      return noteUsers;
    },
    compareMakeDate(startDate: Date, endDate: Date): Date {
      if (startDate.getTime() == endDate.getTime()) {
        endDate.setTime(endDate.getTime() + 1000 * 60);
      }
      return endDate;
    },
    saveCalendarEvent(
      title: string,
      location: string,
      notes: string,
      partUsers: Array<any>,
      startDate: Date,
      endDate: Date,
      eventId: string
    ) {
      if (this.repeat.id != "never") {
        return;
      }
      this.calendarNative.findAllCalendar().then(
        (r: any) => {
          if (r && r.length > 0) {
            let noteUsers = this.generateNotes(partUsers);
            let option: EventOption = {
              calendarName: "",
              calendarId: "",
              title: title,
              eventLocation: location,
              notes: noteUsers + (notes != "" ? "\n说明：" + notes : ""),
              startDate: startDate,
              endDate: this.compareMakeDate(startDate, endDate),
            };
            let delId = "";
            if (eventId == "" || eventId == null) {
              this.isNewTask = true;
            } else {
              let eventJson = JSON.parse(eventId);
              if (isPlatform("ios")) {
                delId = eventJson.ios;
              } else {
                delId = eventJson.android;
              }
            }
            if (isPlatform("ios")) {
              option.calendarName = r[0].name;
            } else {
              option.calendarId = r[0].id;
            }

            if (this.isNewTask || delId == "") {
              //创建
              this.calendarNative.createEventSilently(option).then(
                (r: any) => {
                  this.updateMobileScheduleId(r);
                  console.log("日程标识：" + JSON.stringify(r));
                },
                (e: boolean) => {
                  console.log("异常：" + JSON.stringify(e));
                }
              );
            } else {
              //修改
              let eventId = "";
              this.calendarNative.modifyEventSilently(delId, option).then(
                (r: any) => {
                  eventId = r;
                  this.updateMobileScheduleId(r);
                  console.log("修改成功");
                },
                (e: boolean) => {
                  console.log("修改失败");
                }
              );
              let modifySetTimeout = setTimeout(() => {
                if (
                  eventId == "" ||
                  eventId + "" == "true" ||
                  eventId + "" == "false"
                ) {
                  this.calendarNative.deleteEventByOption(option).then(
                    (r: any) => {
                      this.updateMobileScheduleId(r);
                      console.log("修改成功");
                    },
                    (e: boolean) => {
                      console.log("修改失败");
                    }
                  );
                }
                clearTimeout(modifySetTimeout);
              }, 2000);
            }
          } else {
            console.log("没有日历");
          }
        },
        (e: any) => {
          console.log("获取日历失败");
        }
      );
    },

    //更新手机事件标识
    updateMobileScheduleId(eventId: string) {
      let eventJson =
        this.schedule.mobileScheduleId == undefined ||
        this.schedule.mobileScheduleId == ""
          ? { ios: "", android: "" }
          : JSON.parse(this.schedule.mobileScheduleId);
      if (isPlatform("ios")) {
        eventJson.ios = eventId;
      } else {
        eventJson.android = eventId;
      }
      const params = {
        id: this.schedule.id,
        setFields: ["mobileScheduleId"],
        mobileScheduleId: JSON.stringify(eventJson),
      };
      this.scheduleService.updateFieldsById(params).then((res: any) => {
        console.log("修改日程事件成功");
      });
    },
    //发送消息
    send(result: any, type: string) {
      let that = this;
      let chatGroupId = result.chatGroupId;
      let scheduleTimeId = result.scheduleTimeId;
      let scheduleId = result.id;
      let users = "";
      that.userList.forEach((ele: any) => {
        users += users == "" ? ele.name : "，" + ele.name;
      });
      let feedback = that.schedule.needFeedback ? "   【需要反馈】" : "";
      let time = that.formatTime(
        that.schedule.startTime,
        that.schedule.endTime,
        that.schedule.isAllday
      );
      let saveType = type == "add" ? "创建" : "修改";
      let address =
        that.schedule.address.trim().length > 0
          ? "   【地点】：" + that.schedule.address
          : "   【地点】：暂无";
      let content =
        result.creator +
        saveType +
        "了日程（" +
        that.schedule.name +
        "）：【日程时间】：" +
        time +
        address +
        "   【参与人】：" +
        users +
        feedback;
      that.messageService
        .sendMessageExpose(
          chatGroupId,
          content,
          3,
          "cal_schedule",
          scheduleId + "",
          that.schedule.name + ""
        )
        .then(
          (rsp: BaseRsp) => {
            that.router.go(-1);
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
          }
        );
    },
    formatTime(startTimeT: any, endTimeT: any, isAllDay: any) {
      let str = "";
      const startTime = moment(startTimeT).format("yyyy-MM-DD HH:mm");
      const endTime = moment(endTimeT).format("yyyy-MM-DD HH:mm");

      const startY = moment(startTimeT).format("yyyy");
      const endY = moment(endTimeT).format("yyyy");

      const startM = moment(startTimeT).format("MM");
      const endM = moment(endTimeT).format("MM");

      const startD = moment(startTimeT).format("DD");
      const endD = moment(endTimeT).format("DD");

      const startH = moment(startTimeT).format("HH:mm");
      const endH = moment(endTimeT).format("HH:mm");
      if (isAllDay) {
        if (startY == endY && startM == endM && startD == endD) {
          str = startY + "年" + startM + "月" + startD + "日";
        } else {
          str =
            startY +
            "年" +
            startM +
            "月" +
            startD +
            "日-" +
            endY +
            "年" +
            endM +
            "月" +
            endD +
            "日";
        }
      } else {
        if (startY == endY && startM == endM && startD == endD) {
          str =
            startY + "年" + startM + "月" + startD + "日" + startH + "-" + endH;
        } else {
          str =
            startY +
            "年" +
            startM +
            "月" +
            startD +
            "日" +
            startH +
            "-" +
            endY +
            "年" +
            endM +
            "月" +
            endD +
            "日" +
            endH;
        }
      }
      return str;
    },
    projectDetailHandler(item: any) {
      this.router.push({
        path: "/ProjectPage",
        query: {
          projectId: item.id,
          projectName: item.text,
        },
      });
    },
    taskDetailHandler(item: any) {
      this.router.push({
        path: "/task-page",
        query: {
          id: item.id,
        },
      });
    },
  },
});
