
import { defineComponent } from "vue";
import { IonPage, IonContent } from "@ionic/vue";
import MicroIssuesList from "./list/MicroIssuesList.vue";
import Header from "@/components/Header.vue";
import { useRouter, useRoute } from "vue-router";
import SqlHelper from "@/utils/sql/SqlHelper";
import { nextTick } from "process";
export default defineComponent({
  name: "MicroIssues",
  components: {
    IonPage,
    MicroIssuesList,
    Header,
    IonContent,
  },
  data() {
    const router = useRouter();
    const sqlHelper = new SqlHelper();
    return {
      router: router,
      // btnIconRight: ["icontianjiaweiyiti"],
      // btnSizeRight: ["45px"],
      sqlHelper: sqlHelper,
    };
  },
  ionViewWillEnter() {
    let micList: any = this.$refs.issuesList;
    if (micList) micList.getListData(true);
  },
  methods: {
    //滚动到顶部
    scrollToTop() {
      nextTick(() => {
        let ionContent: any = document.querySelector("#microIssuesMain");
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    },
    addMicroIssues() {
      this.router.push({
        name: "MicroIssuesAdd",
        params: {
          isEdit: "false",
        },
      });
    },
  },
});
