import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';


// 管理类 获取统计信息
export function getStatisticByManage(data){
    return request('/problem/getStatisticByManage.do',"POST",data)
}

// 制件类 获取统计信息
export function getStatisticByPart(data){
    return request('/problem/getStatisticByPart.do',"POST",data)
}
