
import TaskCards from "@/components/ListCard/TaskCards.vue";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import TaskQueryCondition from "@/services/task/model/TaskQueryCondition";
import { TaskService } from "@/services/task/TaskService";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TaskConditionList",
  components: {
    TaskCards,
  },
  props: {
    conditionId: String,
    refresh: Boolean,
  },
  watch: {
    conditionId(newValue: any, oldValue: any) {
      this.init();
    },
    refresh() {
      if (this.refresh) {
        this.init();
      }
    },
  },
  data() {
    const router = useRouter();
    const _taskService = new TaskService();
    const _condtionService = new SearchPanelService();
    return {
      router: router,
      list: [],
      conditionService: _condtionService,
      api: _taskService,
      showNoDate: false,
      parameters: {
        offset: 1,
        limit: 10,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //根据conditionId，获取条件参数
      if (this.conditionId) {
        this.conditionService.getById(this.conditionId).then((res: any) => {
          //解析条件
          if (res) {
            const queryC: TaskQueryCondition = new TaskQueryCondition(res);
            this.parameters = Object.assign(this.parameters, queryC);
            this.getListRequest((res: any) => {
              this.list = res;
              this.showNoDate = this.parameters.offset == 1 && res.length == 0;
            });
          } else {
            this.showNoDate = true;
          }
        });
      }
    },
    openTask(id: any) {
      this.router.push({ path: "/task-page", query: { id: id } });
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        this.showNoDate = this.parameters.offset == 1 && this.list.length == 0;
        event.target.complete();
        if (result) {
          if (result == null || result.length <= this.parameters.limit) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
        this.showNoDate = this.parameters.offset == 1 && this.list.length == 0;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.api.getTaskList(this.parameters).then((res: any) => {
        callback(res);
      });
    },
  },
});
