import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目组织机构
 */
export default class ProjectOrganizationsApi extends TaskBaseService {

    constructor() {
        super("projectOrganizations");
    }

    public getList(param: any){
        return this.request("getAllByProjectId", this.rest.METHOD_GET, param);
    }

    public delete(id: any){
        return this.request("clearById?id="+id, this.rest.METHOD_DELETE);
    }

    public add(data: any){
        return this.request("updateOrganization", this.rest.METHOD_POST, data);
    }
}