import BaseModel from '@/models/base/BaseModel';

export enum EPermissionType {
  Enterprise = 1, //企业权限
  Project = 2,    //项目权限
  Task = 3  //任务权限
}

export default interface Permission extends BaseModel {

  /**
  *权限分类
  */
  permissionType?: EPermissionType;
  /**
  *名称
  */
  name?: string;
  /**
  *权限分组id
  */
  permissionGroupId?: string;
  /**
  *标题(中)
  */
  titleCn?: string;
  /**
  *标题(英)
  */
  titleEn?: string;
  /**
  *标题(德)
  */
  titleDe?: string;

  /**
*标题(德)
*/
  taskRoleId?: string;

  /**
*项目Id
*/
  projectId?: string;


  /**
  *权限分组名称
  */
 permissionGroupName?: string;
}