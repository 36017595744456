/**
 * schedule 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class ScheduleApi extends TaskBaseService {
   
    constructor() {
        super("schedule");
    }

    // 获取我的日历列表:我是创建人或参与人
    public getMyScheduleList(params: any): Promise<any> {
        const path = 'getMyScheduleList';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // 获取他人日程列表：我订阅的他人可显示的日程
    public getOtherScheduleList(params: any): Promise<any> {
        const path = 'getOtherScheduleList';
        return this.request(path, this.rest.METHOD_GET, params);
    }
      
    //获取日程回收站列表 
    public getRecycleSchedule(param: any): Promise<any> {
        const path = 'getDeleteScheduleTimeList';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    //日程回收站还原
    public restoreSchedule(param: any): Promise<any> {
        const path = 'restoreScheduleTimeById?scheduleTimeId='+param.scheduleTimeId;
        return this.request(path, this.rest.METHOD_PUT);
    }


    // 新建日程
    public insertSchedule(params: any): Promise<any> {
        const path = 'insertSchedule';
        return this.request(path, this.rest.METHOD_POST, params);
    }

     // 编辑日程
     public updateSchedule(params: any): Promise<any> {
        const path = 'updateSchedule';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    // 根据日程id删除全部日程
    public deleteScheduleByScheduleId(params: any): Promise<any> {
        const path ="deleteScheduleByScheduleId";
        return this.request(path, this.rest.METHOD_DELETE, params);
    }

    // 删除当前及以后日程
    public deleteScheduleThisAndAfter(params: any): Promise<any> {
        const path ="deleteScheduleThisAndAfter?scheduleId="+params.scheduleId+"&scheduleTimeId="+params.scheduleTimeId;
        return this.request(path, this.rest.METHOD_DELETE, {});
    }

    // 根据当前日程明细id删除当前日程
    public deleteScheduleTimeById(params: any): Promise<any> {
        const path ="deleteScheduleTimeById?scheduleTimeId="+params.scheduleTimeId;
        return this.request(path, this.rest.METHOD_DELETE, {});
    }

     // 获取日程详情，含关联项目、任务、日历、参与人
     public getScheduleDetail(params: any): Promise<any> {
        const path = 'getScheduleDetail';
        return this.request(path, this.rest.METHOD_GET, params);
    }

     //更新日程信息--完成，反馈
    public updateFieldsById(params: any): Promise<any> {
        const path = "updateFieldsById";
        return this.request(path,this.rest.METHOD_PUT, params);       
    }

    getScheduleOverView() {
        return this.request("getScheduleOverview",this.rest.METHOD_GET);       
    }

    /**
     * 日程每日待办数量：按月
     */
    public getCountByMonth(params: any): Promise<any> {
        const path = "getCountByMonth";
        return this.request(path,this.rest.METHOD_GET, params);       
    }

    /**
     * 是否有查看权限
     */
    public getLimitDetail(params: any): Promise<any> {
        const path = "getLimitDetail";
        return this.request(path,this.rest.METHOD_GET, params);       
    }
}