
import { defineComponent } from "vue";
export default defineComponent({
  name: "CardLoading",
  props: {
    lodingType: {
      type: String,
      default: "1",
    }, //1任务卡片或日程卡片  //2项目卡片  3微议题卡片  4团队卡片或日历
    isHome: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      list: [1, 1, 1, 1, 1, 1, 1, 1],
    };
  },
});
