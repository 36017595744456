
import { defineComponent } from "vue";
import { modalController, IonPage, IonContent } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { TaskService } from "@/services/task/TaskService";
import TaskFileList from "../modal/TaskFileList.vue";
import Utils from "@/utils/Utils";
import Setting from "@/rest/Setting";
import SystemUtils from "@/utils/SystemUtils";
import User from "@/models/task/User";
import DocService from "@/services/doc/docService";
import { FileHelper } from "@/utils/FileHelper";
export default defineComponent({
  name: "AttendToModel",
  components: {
    IonPage,
    HeaderModal,
    IonContent,
  },
  props: {
    taskInfo: Object,
  },
  data() {
    const task = this.taskInfo;
    const fileHelper = new FileHelper();
    return {
      mainTask: { context: "" },
      task: { finishId: "", finishTime: "", finisher: "", isFinished: true },
      api: new TaskService(),
      docApi: new DocService(),
      taskId: task ? task.id : "",
      busId: task ? task.busId : "",
      isRequested: false,
      queryDocment: {
        folderId: "",
        systemId: "plancenter",
        offset: 1,
        limit: 100,
      },
      docmentList: new Array(),
      fileHelper: fileHelper,
    };
  },
  mounted() {
    this.initData(this.busId);
  },
  methods: {
    initData(busId: any) {
      this.api
        .getMainPlanTask(busId)
        .then((result: any) => {
          const res = result.data;
          if (res) {
            Object.assign(this.mainTask, res.mainTask);
            // this.mainTask.context=res.mainTask.context?res.mainTask.context:"";
            this.task.finishId = res.taskCenter.finishId;
            this.task.finishTime = res.taskCenter.finishTime;
            this.task.finisher = res.taskCenter.finisher;
            this.task.isFinished = res.taskCenter.isFinished;
            this.queryDocment.folderId = res.folders.result.id;
            this.queryDocment.systemId = res.folders.result.systemId;
            this.queryDocment.offset = 1;
          }
          this.docApi.getFileByFolderId(this.queryDocment).then((rsp: any) => {
            if (rsp && rsp.data) {
              this.docmentList = rsp.data.documents;
            }
          });
          this.isRequested = true;
        })
        .catch((e: any) => {
          console.log(e);
          this.isRequested = true;
        });
    },
    subStringUserName(name: string) {
      if (!name) {
        const user: User = SystemUtils.loginUser;
        if (user && user.name) {
          name = user.name;
        } else {
          name = "暂无";
        }
      }

      return Utils.subStringUserName(name, 2);
    },
    closeModel(type: Number) {
      if (type == 4) {
        this.onSave(() => {
          modalController.dismiss({ isSave: true });
        });
      } else {
        modalController.dismiss();
      }
    },

    //保存-- 更新任务完成时间
    onSave(callback: any) {
      //保存主任务
      this.api.updataMainPlanInfo(this.mainTask).then((res: any) => {
        if (res) {
          //更新任务中心 完成时间
          this.api.updateIsFinishedByUser(this.taskId).then((res: any) => {
            if (res) {
              callback();
            }
          });
        }
      });
    },
    async openTaskFileModelHandler() {
      Utils.presentToastWarning("待确认，如何实现");
      const modal = await modalController.create({
        component: TaskFileList,
        cssClass: "notice-modal-class",
        componentProps: {
          busId: this.busId,
        },
      });
      await modal.present();
    },

    //删除关联
    clearLinkContentHandler(id: string, index: number) {
      this.docApi.delFileById(id).then((res) => {
        if (res) {
          this.docmentList.splice(index, 1);
        } else {
          Utils.presentToast("操作失败");
        }
      });
    },
    fileItemClick(item: any) {
      console.log(item);
    },
    checkChangeHandler(info: any) {
      this.docApi.delFileById(info.id).then((res) => {
        if (res) {
          const index = this.docmentList.findIndex((e) => {
            return e.id == info.id;
          });
          if (index > -1) {
            this.docmentList.splice(index, 1);
          }
        } else {
          Utils.presentToast("操作失败");
        }
      });
    },
    //下载
    downloadFile(r: any) {
      if (r) {
      }
    },
    //浏览
    previewFile(r: any) {
      // if (r) {
      //   if(r.previewImage){
      //     this.fileHelper.phonePreview(r.previewImage, r.mainFileId, r.name);
      //   }else{
      //     Utils.presentToast("您选择的文件类型暂时不支持预览！");
      //   }
      // }
    },
  },
});
