
import { IonPage, modalController, alertController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
export default defineComponent({
  name: "EditSearchCondition",
  components: { IonPage, HeaderModal, AvatarList },
  props: {
    queryObj: Object,
    systemIdArr: Array,
  },
  data() {
    return {
      searchPanelService: new SearchPanelService(),
      queryType: "project",
      creatArr: new Array(0), //创建人
      searchNameArr: new Array(0),
      lamps: [
        { name: "灯1", value: 1 },
        { name: "灯2", value: 2 },
        { name: "灯3", value: 3 },
        { name: "灯4", value: 4 },
      ],
      wayArr: [
        { name: "全部", value: "" },
        { name: "我参与的", value: "myPart" },
        { name: "我创建的", value: "myCreate" },
        { name: "我收藏的", value: "myStar" },
      ],
      //----筛选条件字段--------------
      searchPanelForm: {
        queryName: "", //搜索名称
        qisDefault: false, //是否默认
        qisIndexCustomize: false, //是否推送到首页
        qname: "", //项目名称
        qcreateTimeb: "", //创建时间
        qcreateTimee: "",
        qendTimeb: "", //结束时间
        qendTimee: "",
        qpartType: "", //参与方式（多选）不传默认全部
        qpartTypeName: "",
        qsource: "", //来源（多选）不传默认全部
        qsourceName: "",
        qstatusLight: "", // 状态灯
        queryType: "project",
        id: "",
        qcreatorIds: "",
        qcreatorNames: "",
      },
    };
  },
  created() {
    this.initTaskData();
  },
  methods: {
    //重置时间
    resetTime(type: any) {
      if (type == "createTime") {
        this.searchPanelForm.qcreateTimeb = "";
        this.searchPanelForm.qcreateTimee = "";
      }
      if (type == "endTime") {
        this.searchPanelForm.qendTimeb = "";
        this.searchPanelForm.qendTimee = "";
      }
    },
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    initTaskData() {
      //请求项目来源
      // this.getSourceList(); 
      this.initSeachFormData(this.queryObj);
    },
    onClose(type: any) {
      modalController.dismiss();
    },
    // 状态灯改变
    lampChange(event: any) {
      this.searchPanelForm.qstatusLight = event.detail.value;
    },
    //参与方式
    getWay(obj: any) {
      this.searchPanelForm.qpartType = obj.value;
      this.searchPanelForm.qpartTypeName = obj.name;
    },
    //来源
    getsystemId(obj: any) {
      this.searchPanelForm.qsource = obj.id;
      this.searchPanelForm.qsourceName = obj.name;
    },

    //设为默认
    changeDefault(e: any) {
      this.searchPanelForm.qisDefault = e.detail.checked;
    },
    //推送到首页
    changePushHome(e: any) {
      this.searchPanelForm.qisIndexCustomize = e.detail.checked;
    },
    //确定检索
    confirm() {
      if (
        !this.searchPanelForm.queryName ||
        this.searchPanelForm.queryName.replace(/\s+/g, "") == ""
      ) {
        Utils.presentToastTopWarning("请填写查询条件名称");
        return false;
      }
      this.timeFromat();
      this.updataQueryCondition();
    },
    //请求来源
    updataQueryCondition() {
      this.searchPanelService.saveSearch(this.searchPanelForm).then(
        (res: any) => {
          if (res) {
            modalController.dismiss({
              isSussess: true,
              isDeleted: false,
              newModle: this.searchPanelForm,
            });
          } else {
            Utils.presentToastWarning("查询条件名称不允许重复");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //删除
    async deleteQueryCondition() {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要删除此条件吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",

            handler: () => {
              if (this.searchPanelForm.qisIndexCustomize) {
                Utils.presentToastTopWarning("请先取消推送到首页定制");
                return;
              }
              //删除
              this.searchPanelService.clearById(this.searchPanelForm.id).then(
                (res: any) => {
                  if (res) {
                    modalController.dismiss({
                      isSussess: true,
                      isDeleted: true,
                      newModle: null,
                    });
                  }
                },
                (e: any) => {
                  console.log(e);
                }
              );
            },
          },
        ],
      });

      await alert.present();
      // modalController.dismiss({isSussess:true,isDeleted: true, newModle: null});
    },
    //时间处理-创建人处理
    timeFromat() {
      if (this.searchPanelForm.qcreateTimeb) {
        this.searchPanelForm.qcreateTimeb = moment(
          this.searchPanelForm.qcreateTimeb
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qcreateTimee != "") {
        this.searchPanelForm.qcreateTimee = moment(
          this.searchPanelForm.qcreateTimee
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qendTimeb != "") {
        this.searchPanelForm.qendTimeb = moment(
          this.searchPanelForm.qendTimeb
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qendTimee != "") {
        this.searchPanelForm.qendTimee = moment(
          this.searchPanelForm.qendTimee
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.creatArr && this.creatArr.length > 0) {
        const cUserArr = this.creatArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i].id;
          const uName = cUserArr[i].name;
          const uPernr = cUserArr[i].pernr;
          if (i == 0) {
            userIds += cUserArr[i].id;
          } else {
            userIds += "," + cUserArr[i].id;
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.qcreatorIds = userIds;
          this.searchPanelForm.qcreatorNames = JSON.stringify(userObjectArr);
        }
      }
    },
    //渲染查询条件数据
    initSeachFormData(params: any) {
      this.searchPanelForm.queryName = params.queryName;
      this.searchPanelForm.qname = params.qname;
      this.searchPanelForm.qpartType = params.qpartType;
      this.searchPanelForm.qsource = params.qsource;
      this.searchPanelForm.qcreateTimeb = params.qcreateTimeb;
      this.searchPanelForm.qcreateTimee = params.qcreateTimee;
      this.searchPanelForm.qendTimeb = params.qendTimeb;
      this.searchPanelForm.qendTimee = params.qendTimee;
      this.searchPanelForm.qisDefault = params.qisDefault;
      this.searchPanelForm.qisIndexCustomize = params.qisIndexCustomize;
      this.searchPanelForm.id = params.id;
      this.searchPanelForm.qstatusLight = params.qstatusLight;
      if (params.qcreatorIds && params.qcreatorNames) {
        this.searchPanelForm.qcreatorIds = params.qcreatorIds;
        this.creatArr = JSON.parse(params.qcreatorNames);
      }
    },
    //删除所选的人
    deleteUser(item: any, index: number) {
      this.creatArr.splice(index, 1);
    },
    //创建人
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.creatArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.creatArr = data.data;
        //初始化
      }
    },
    //重置检索条件
    reset() {
      this.searchPanelForm.queryName = "";
      this.searchPanelForm.qname = ""; //名称
      this.searchPanelForm.qcreateTimeb = ""; //创建时间
      this.searchPanelForm.qcreateTimee = "";
      this.searchPanelForm.qendTimeb = ""; //结束时间
      this.searchPanelForm.qendTimee = "";
      this.searchPanelForm.qpartType = ""; //参与方式（多选）不传默认全部
      this.searchPanelForm.qsource = ""; //来源（多选）不传默认全部
      this.searchPanelForm.qstatusLight = "";
      this.searchPanelForm.qcreatorIds = "";
      this.creatArr = [];
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
