
import {
  modalController,
  popoverController,
  loadingController,
} from "@ionic/vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
//组件
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import { getInstance } from "@/services/project/projectService";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "../../../utils/Utils";

export default defineComponent({
  name: "MineArchive",
  components: {
    Header,
    SearchBar,
    ProjectCss,
  },
  ionViewDidEnter() {
    this.projectParams.offset = 1;
    this.islist = true;
    this.requsetProjectData(this.projectParams, null);
  },
  setup() {
    const headerTitle = ref("已归档项目");
    const projectService = getInstance();
    const router = useRouter();

    const list = reactive({ data: new Array(0) });
    const projectParams = reactive({
      limit: 10,
      offset: 1,
      projectName: "",
      userId: "",
      createTimeCB: "",
      createTimeCE: "",
      endTimeCB: "",
      endTimeEE: "",
      projectLight: "",
      createTimeOrder: "DESC",
      statusLightOrder: "DESC",
      orderType: "createTime",
    });
    let isMore = true;
    let islist = ref(true);

    //初始加载
    onMounted(() => {
      const currentUser: any = SystemUtils.loginUser;
      isMore = true;
      islist.value = true;
      projectParams.offset = 1;
      projectParams.projectName = "";
      projectParams.userId = currentUser.userId;

      requsetProjectData(projectParams, null);
    });

    //下拉刷新
    const doRefresh = (event: any) => {
      if (isMore) {
        isMore = false;
        projectParams.offset = 1;
        requsetProjectData(projectParams, event);
      }
    };

    //上滑分页
    const nextPage = (event: any) => {
      if (isMore) {
        isMore = false;
        projectParams.offset++;
        requsetProjectData(projectParams, event);
      }
    };

    //执行搜索
    const searchHandler = (val: any) => {
      if (Utils.isInCludeEmoji(val.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      projectParams.offset = 1;
      projectParams.projectName = val.searchKey;
      islist.value = true;
      requsetProjectData(projectParams, null);
    };

    //我的项目接口
    const requsetProjectData = (pamas: any, event: any) => {
      projectService.getByFinishProjectList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            isMore = true;
            if (result == null || result.length == 0) {
              if (event != null) {
                event.target.complete();
                event.target.disabled = true;
              } else {
                islist.value = false;
              }
            } else {
              if (event != null) {
                list.data = list.data.concat(result);
                setTimeout(() => {
                  event.target.complete();
                }, 500);
              } else {
                list.data = result;
                islist.value = false;
              }
            }
          } else {
            isMore = true;
            if (event != null) {
              list.data = [];
              event.target.complete();
            } else {
              islist.value = false;
            }
          }
        },
        (e: any) => {
          isMore = true;
          if (event != null) {
            list.data = [];
            event.target.complete();
          } else {
            islist.value = false;
          }
        }
      );
    };

    //跳转详情
    const itemClick = (item: any) => {
      router.push({
        path: "/ProjectPage",
        query: {
          projectId: item.id,
          projectName: item.name,
          isStart: item.isStart,
        },
      });
    };

    return {
      list,
      headerTitle,
      searchHandler,
      doRefresh,
      itemClick,
      islist,
      nextPage,
      projectParams,
      requsetProjectData,
    };
  },
});
