export default class BaseRsp {
    data?: any;
    status?: number;
    statusText?: string;
    method?: string;
    message?: string;
};

// export class Response {
//     code?: number;         //($int32) 状态码
//     data?: any;         //
//     msg?: string;          // 返回消息
//     success?: boolean;     // 成功标志
//     timestamp?: number;    //($int64) 时间戳
// }