import { ProjectTeamApi } from '@/api/project/ProjectTeamApi';
import BaseService from '../base/BaseService';
/**
 * 项目列表模块
 */
export class ProjectTeamService extends BaseService {
    private api = new ProjectTeamApi();
    //查询项目团队任务统计情况
    public getProjectTeamMemberCount({ projectId = '', limit = 10, offset = 1 } = {}): any {
        return new Promise((resolve, reject) => {
            this.api.getProjectTeamMemberCount({ projectId: projectId, limit, offset }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    public getAllTeamsByUserId(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getAllTeamsByUserId(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    private arr = new Array();
    //查询项目中所有团队角色
    public getAllNodeByTreeId(projectId: string): any {
        this.arr = [];
        return new Promise((resolve, reject) => {
            this.api.getAllNodeByTreeId({ treeId: projectId }).then((res: any) => {
                this.teamRoles(res.data);
                resolve(this.arr);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //查询项目所有团队及团队角色管理
    public getAllProjectTeam(projectId: string): any {
        return new Promise((resolve, reject) => {
            this.api.getAllNodeByTreeId({ treeId: projectId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    private teamRoles(datas: any) {
        for (let i = 0; i <= datas.length - 1; i++) {
            let data = datas[i];
            if (data.childNodes && data.childNodes.length > 0) {
                this.teamRolesData(data.childNodes);
            } else {
                if (data.nodeType == "teamRole") {
                    this.arr.push(data);
                }
            }
        }
    }
    private teamRolesData(datas: any) {
        for (let i = 0; i <= datas.length - 1; i++) {
            let data = datas[i];
            if (data.nodeType == "teamRole") {
                this.arr.push(data);
            } else {
                if (data.childNodes && data.childNodes.length > 0) {
                    this.teamRolesData(data.childNodes);
                }
            }
        }
    }

    //查询项目团队是否存在
    public getExistsTeam({ projectTeamId = "",
        projectTeamName = "",
        projectId = "", } = {}): any {
        return new Promise((resolve, reject) => {
            this.api.getExistsTeam({ projectTeamId, projectTeamName, projectId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //添加团队
    public getInsertProjectTeam(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getInsertProjectTeam(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新团队
    public getUpdateProjectTeam(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getUpdateProjectTeam(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //删除团队
    public deleteProjectTeam(params: any,projectId: any): any {
        return new Promise((resolve, reject) => {
            this.api.deleteProjectTeam(params,projectId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}
