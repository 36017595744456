
import { modalController, IonPage, IonItem, IonCheckbox } from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "../../components/modal/components/HeaderModal.vue";
import { AnimationSelf } from "@/libs/animation";
import SearchBar from "@/components/SearchBar.vue";
import SelectSuperior from "@/components/selectexecutor/SelectSuperior.vue";
import SelectMyTeam from "@/components/selectexecutor/SelectMyTeam.vue";
import SelectForTeamList from "@/components/selectexecutor/SelectForTeamList.vue";
import { getInstance1 } from "@/services/selectexecutor/selectExecutorTeamService";
import { getInstance } from "@/services/selectexecutor/UserInfoByUserNameService";
import { FileHelper } from "@/utils/FileHelper";
import { ProjectMemberService } from "@/services/project/ProjectMemberService";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "../../utils/Utils";
import Setting from "@/rest/Setting";

export default defineComponent({
  name: "SelectExecutor",
  components: {
    IonPage,
    HeaderModal,
    IonItem,
    SearchBar,
    IonCheckbox,
    AvatarList,
  },
  data() {
    const allExecutorService = getInstance1();
    const userInfoService = getInstance();
    return {
      //当前搜索出的界面所有的人员
      executorAllList: new Array(0),
      //记录旧的所有人员
      executorAllOldList: new Array(0),
      //选中人员集合
      executorSelectList: new Array(0),
      //所有团队请求service
      allExecutorService: allExecutorService,
      //所有人员接口service
      userInfoService: userInfoService,
      //搜索框中的值
      searchStr: "",
      //组织架构集合
      organizeList: new Array(0),
      //传过来的值，用于判断当前界面是执行者还是参与者
      executor: "",
      //页码
      pageNo: 1,

      fileServe: new FileHelper(),

      //获取推荐人员的service
      projectMemberServices: new ProjectMemberService(),

      //人员头像管理
      users: new Array<any>(),
      //是否上汽环境
      isSVW: Setting.isSVW,
    };
  },
  props: {
    //传递需要的参数为(userId:用户唯一ID // name:名称//photo:头像//pernr:用户的员工编号//department:用户所属部门)
    data: Object,
  },
  created() {
    //加载组织架构列表
    this.loadTeamList(0);
    if (this.data) {
      //   if(this.data.type == "executor"){

      //     this.executor = "执行者";

      //   }else if(this.data.type == "joiner"){

      //   }

      if (this.data.executorList) {
        for (let index = 0; index < this.data.executorList.length; index++) {
          const element = this.data.executorList[index];
          this.executorSelectList.push(
            Object.assign(element, { isChecked: true })
          );
        }
      }
    }
    this.recommend();
  },
  methods: {
    avatarData(memberObj: any) {
      let that = this;
      that.users = [];
      if (!memberObj) {
        return;
      }
      memberObj.forEach(function (item: any, index: number) {
        let obj = { name: item.name, img: item.photo, id: item.userId };
        that.users.push(obj);
      });
      /*let obj = {
        name: memberObj.name,
        //img: memberObj.photo,
        img: "",
        id: memberObj.userId,
      };
      this.users.push(obj);
      console.log(this.users)*/
      return this.users;
    },
    avatarDataAllList(item: any) {
      /*let avatarList = new Array();
      let that = this;
      that.users = [];
      if (!avatarList) {
        return;
      }*/
      let obj = { name: item.name, img: item.photo, id: item.userId };
      //that.users.push(obj);
      return obj;
    },
    clickCheckRow(userInfo: any) {
      const indexSelected = this.executorSelectList.findIndex((item) => {
        return item.userId == userInfo.userId;
      });
      const allIndex = this.executorAllList.findIndex((a) => {
        return a.userId == userInfo.userId;
      });
      if (indexSelected > -1) {
        this.executorAllList[allIndex].isChecked = false;
      } else {
        this.executorAllList[allIndex].isChecked = true;
      }
    },

    //推荐人员列表
    recommend() {
      this.pageNo = 1;
      const params = {
        limit: 10,
        offset: 1,
        searchKey: this.searchStr,
        userId: "",
      };
      this.projectMemberServices.getUsersByProjectTeamApp(params).then(
        (r: any) => {
          if (r) {
            this.executorAllList = r;

            for (
              let index = 0;
              index < this.executorSelectList.length;
              index++
            ) {
              const element = this.executorSelectList[index];

              const allIndex = this.executorAllList.findIndex((a) => {
                return a.userId == element.userId;
              });

              if (allIndex > -1) {
                const temObje = this.executorAllList[allIndex];
                temObje.isChecked = true;
                this.executorAllList.splice(allIndex, 1, temObje);
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      if (searchParam.searchKey == "") {
        this.searchStr = "";
        this.recommend();
      } else {
        this.searchStr = searchParam.searchKey;
        this.refreshList();
      }
    },

    doRefresh(event: any) {
      if (this.data) {
        if (this.searchStr == "") {
          this.recommend();
        } else {
          this.refreshList();
        }
      }
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },

    refreshList() {
      this.pageNo = 1;
      const params = {
        limit: 10,
        offset: 1,
        searchKey: this.searchStr,
      };

      this.userInfoService.getOrganizationAllTeamStaffList(params).then(
        (r: any) => {
          if (r) {
            this.executorAllList = r;

            for (
              let index = 0;
              index < this.executorSelectList.length;
              index++
            ) {
              const element = this.executorSelectList[index];
              const allIndex = this.executorAllList.findIndex((a) => {
                return a.userId == element.userId;
              });

              if (allIndex > -1) {
                const temObje = this.executorAllList[allIndex];
                temObje.isChecked = true;
                this.executorAllList.splice(allIndex, 1, temObje);
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //上拉加载
    nextPage(event: any) {
      this.pageNo++;
      if (this.data) {
        if (this.searchStr == "") {
          const params = {
            limit: 10,
            offset: this.pageNo,
            searchKey: this.searchStr,
            userId: "",
          };
          this.projectMemberServices.getUsersByProjectTeamApp(params).then(
            (r: any) => {
              if (r) {
                this.executorAllList = this.executorAllList.concat(r);

                for (
                  let index = 0;
                  index < this.executorSelectList.length;
                  index++
                ) {
                  const element = this.executorSelectList[index];
                  const allIndex = this.executorAllList.findIndex((a) => {
                    return a.userId == element.userId;
                  });

                  if (allIndex > -1) {
                    const temObje = this.executorAllList[allIndex];
                    temObje.isChecked = true;
                    this.executorAllList.splice(allIndex, 1, temObje);
                  }
                }

                event.target.complete();
                if (r) {
                  if (r == null || r.length <= 0) {
                    event.target.disabled = true;
                  }
                }
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        } else {
          const params = {
            offset: this.pageNo,
            limit: 10,
            searchKey: this.searchStr,
          };

          this.userInfoService.getOrganizationAllTeamStaffList(params).then(
            (r: any) => {
              if (r) {
                this.executorAllList = this.executorAllList.concat(r);

                for (
                  let index = 0;
                  index < this.executorSelectList.length;
                  index++
                ) {
                  const element = this.executorSelectList[index];

                  const allIndex = this.executorAllList.findIndex((a) => {
                    return a.userId == element.userId;
                  });

                  if (allIndex > -1) {
                    const temObje = this.executorAllList[allIndex];
                    temObje.isChecked = true;
                    this.executorAllList.splice(allIndex, 1, temObje);
                  }
                }

                event.target.complete();
                if (r) {
                  if (r == null || r.length <= 0) {
                    event.target.disabled = true;
                  }
                }
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        }
      }
    },

    //获取组织架构根目录信息
    loadTeamList(pId: number) {
      let params = {};

      if (pId != 0) {
        params = {
          parentId: pId + "",
        };
      }
      this.allExecutorService.getOrganizationTeamList(params).then(
        (r: any) => {
          if (r) {
            this.organizeList = r.data.data;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    isChecked(index: any) {
      if (this.executorSelectList.length != 0) {
        for (
          let position = 0;
          position < this.executorSelectList.length;
          position++
        ) {
          const element1 = this.executorSelectList[position];
          if (this.executorAllList[index].userId == element1.userId) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type == 3) {
        modalController.dismiss();
      } else if (type == 4) {
        modalController.dismiss({
          //回调数据为(id//  userId:用户唯一ID // name:名称//photo:头像//pernr:用户的员工编号//department:用户所属部门)
          data: this.executorSelectList,
        });
      }
    },
    SelectStaffInfo(ev: any, index: number) {
      const checkObj = this.executorAllList[index];
      checkObj.isChecked = ev.detail.checked;
      // console.log(index);
      // console.log(ev.detail.checked);
      if (checkObj.isChecked) {
        const allIndex = this.executorSelectList.findIndex((a) => {
          return a.userId == checkObj.userId;
        });

        if (allIndex <= -1) {
          if (this.data) {
            if (this.data.isCkeckbox == false) {
              this.executorSelectList.splice(0, this.executorSelectList.length);
              for (
                let index1 = 0;
                index1 < this.executorAllList.length;
                index1++
              ) {
                const element = this.executorAllList[index1];
                if (index1 != index) {
                  element.isChecked = false;
                }
              }
            }
          }
          this.executorSelectList.push(this.executorAllList[index]);
        }
      } else {
        for (
          let index1 = 0;
          index1 < this.executorSelectList.length;
          index1++
        ) {
          const element = this.executorSelectList[index1];
          if (element.userId == this.executorAllList[index].userId) {
            this.executorSelectList.splice(index1, 1);
            break;
          }
        }
      }
    },

    removeSelect(position: any) {
      const allIndex = this.executorAllList.findIndex((a) => {
        return a.userId == this.executorSelectList[position].userId;
      });
      if (allIndex > -1) {
        this.executorAllList[allIndex].isChecked = false;
      }

      this.executorSelectList.splice(position, 1);
      // this.executorAllList.push
    },

    //跳转至上下级模态窗
    async intentsuperior() {
      let isCheckBox = true;
      if (this.data) {
        if (this.data.isCkeckbox == undefined) {
          isCheckBox = true;
        } else {
          isCheckBox = false;
        }
      }
      const result = AnimationSelf.prototype.modalRight;
      const modal = await modalController.create({
        component: SelectSuperior,
        cssClass: "notice-modal-class",
        mode: "ios",
        enterAnimation: result.enterAnimation,
        leaveAnimation: result.leaveAnimation,
        backdropDismiss: true,
        componentProps: {
          intentExecutorSelectList: this.executorSelectList,
          isCheckBox: isCheckBox,
        },
      });

      await modal.present();
      const { data } = await modal.onDidDismiss();

      if (data) {
        this.executorSelectList.splice(0, this.executorSelectList.length);
        for (
          let index = 0;
          index < data.executorSuperiorSelectList.length;
          index++
        ) {
          const element = data.executorSuperiorSelectList[index];

          this.executorSelectList.push(element);
        }
      }
    },

    //跳转至组织架构选择成员
    async intentOrganizeFramework() {
      let isCheckBox = true;
      if (this.data) {
        if (this.data.isCkeckbox == undefined) {
          isCheckBox = true;
        } else {
          isCheckBox = false;
        }
      }

      const result = AnimationSelf.prototype.modalRight;
      const modal = await modalController.create({
        component: SelectMyTeam,
        cssClass: "notice-modal-class",
        mode: "ios",
        enterAnimation: result.enterAnimation,
        leaveAnimation: result.leaveAnimation,
        backdropDismiss: true,
        componentProps: {
          intentExecutorSelectList: this.executorSelectList,
          organize: this.organizeList[0],
          isCheckBox: isCheckBox,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.isReturn) {
          this.executorSelectList.splice(0, this.executorSelectList.length);
        }
        if (data.executorSuperiorSelectList) {
          for (
            let index = 0;
            index < data.executorSuperiorSelectList.length;
            index++
          ) {
            const element = data.executorSuperiorSelectList[index];

            this.executorSelectList.push(element);
          }
        }
      }
    },

    //跳转根据团队选择人员
    async intentteam() {
      let isCheckBox = true;
      if (this.data) {
        if (this.data.isCkeckbox == undefined) {
          isCheckBox = true;
        } else {
          isCheckBox = false;
        }
      }

      const result = AnimationSelf.prototype.modalRight;
      const modal = await modalController.create({
        component: SelectForTeamList,
        cssClass: "notice-modal-class",
        mode: "ios",
        enterAnimation: result.enterAnimation,
        leaveAnimation: result.leaveAnimation,
        backdropDismiss: true,
        componentProps: {
          intentExecutorSelectList: this.executorSelectList,
          isCheckBox: isCheckBox,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.isReturn) {
          this.executorSelectList.splice(0, this.executorSelectList.length);
        }
        if (data.executorSuperiorSelectList) {
          for (
            let index = 0;
            index < data.executorSuperiorSelectList.length;
            index++
          ) {
            const element = data.executorSuperiorSelectList[index];

            this.executorSelectList.push(element);
          }
        }
      }
    },
  },
});
