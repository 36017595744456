
import { menuController, modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SearchConditionManageModal from "./modal/SearchConditionManageModal.vue";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import TaskSearchPanelPullDown from "../task/TaskSearchPanelPullDown.vue";
import Utils from "@/utils/Utils";
import { json } from "gantt";
import AvatarList from "@/components/AvatarList.vue";
import BaseService from "@/services/base/BaseService";

export default defineComponent({
  name: "ProjectSearchPanel",
  props: {
    selectConditionId: String, //列表中选中的Id
  },
  components: {
    AvatarList,
  },
  emits: ["on-close", "on-init"],
  data() {
    const searchPanelService = new SearchPanelService();
    return {
      newSearchModel: {
        searchName: "",
        qisDefault: false,
        qisIndexCustomize: false,
      },
      isCondition: false,
      creatArr: new Array(0), //创建人
      queryType: "project",
      searchNameArr: new Array(0),
      lamps: [
        { name: "灯1", value: 1 },
        { name: "灯2", value: 2 },
        { name: "灯3", value: 3 },
        { name: "灯4", value: 4 },
      ],
      wayArr: [
        { name: "全部", value: "" },
        { name: "我参与的", value: "myPart" },
        { name: "我创建的", value: "myCreate" },
        // { name: "我收藏的", value: "myStar" },
      ],
      systemIdArr: new Array(0),
      searchPanelService: searchPanelService,
      //----筛选条件字段--------------
      searchPanelForm: {
        queryName: "", //搜索名称
        qisDefault: false, //是否默认
        qisIndexCustomize: false, //是否推送到首页
        qname: "", //项目名称
        qcreateTimeb: "", //创建时间
        qcreateTimee: "",
        qendTimeb: "", //结束时间
        qendTimee: "",
        qpartType: "", //参与方式（多选）不传默认全部
        qpartTypeName: "",
        qsource: "", //来源（多选）不传默认全部
        qsourceName: "",
        qstatusLight: "", // 状态灯
        queryType: "project",
        qcreatorIds: "",
        qcreatorNames: "",
        isSelected:"false"
      },
      //----查询条件下拉--------------
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: "project",
      },
      //当前选择查询条件
      selectedConditionId: "",

      baseService: new BaseService(),
    };
  },
  created() {
    this.initTaskData();
  },
  methods: {
    initTaskData() {
      //请求查询条件
      this.getQueryList(this.searchListParams);
      //请求项目来源
      this.getSourceList();
    },
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    onClose() {
      menuController.close();
    },
    // 状态灯改变
    lampChange(event: any) {
      this.searchPanelForm.qstatusLight = event.detail.value;
    },
    //参与方式
    getWay(obj: any) {
      this.searchPanelForm.qpartType = obj.value;
      this.searchPanelForm.qpartTypeName = obj.name;
    },
    //来源
    getsystemId(obj: any) {
      this.searchPanelForm.qsource = obj.id;
      this.searchPanelForm.qsourceName = obj.name;
    },
    keyNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
       this.confirm();
      }
    },
    //确定检索
    confirm() {
      if (Utils.isInCludeEmoji(this.searchPanelForm.qname)) {
        Utils.presentToastWarning("项目名称不可以包含表情");
        return;
      }
      this.searchPanelForm.qname = this.baseService.trim(
        this.searchPanelForm.qname
      );
      this.timeFromat();
      console.log("this.selectedConditionId"+this.selectedConditionId);
      this.searchPanelForm.isSelected="true";
      this.$emit("on-close",Object.assign(this.searchPanelForm,{searchId:this.selectedConditionId}));
      this.onClose();
    },
    //下拉框
    async pullShow(ev: any) {
      const popover = await popoverController.create({
        component: TaskSearchPanelPullDown,
        cssClass: "myPullDown",
        event: ev,
        componentProps: {
          searchNameArr: this.searchNameArr,
          searchName: this.searchPanelForm.queryName,
          searchId: this.selectedConditionId,
        },
      });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && data.searchName != "") {
        //this.searchName = data.searchName;
        this.initSeachFormData(data.selectObj);
        this.selectedConditionId = data.selectObj.id;
      }
    },
    //打开条件名称框
    openCondition() {
      this.newSearchModel.searchName = "";
      this.newSearchModel.qisDefault = false;
      this.newSearchModel.qisIndexCustomize = false;
      this.isCondition = true;
    },
    //关闭条件名称框
    CloseDialog() {
      this.isCondition = false;
      this.isOpenCondition();
    },
    //点击保存按钮
    saveCondition(type: number) {
      if (type == 1) {
        if (
          !this.newSearchModel.searchName ||
          this.newSearchModel.searchName.replace(/\s+/g, "") == ""
        ) {
          Utils.presentToastWarning("请填写查询条件名称");
          return;
        }
        this.searchPanelForm.queryName = this.newSearchModel.searchName;
        this.isCondition = false;
        this.isOpenCondition();
      } else {
        this.isCondition = false;
      }
    },
    //保存查询条件
    isOpenCondition() {
      this.timeFromat();
      this.saveQueryInfo(this.searchPanelForm);
    },
    //条件管理
    async openSearchConditionManageModal() {
      const modal = await modalController.create({
        component: SearchConditionManageModal,
        cssClass: "notice-modal-class",
        backdropDismiss: true,
        componentProps: {
          systemIdArr: this.systemIdArr,
          queryType: "project",
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data.queryList) {
        const selectArr: Array<any> = data.queryList.filter((item: any) => {
          return item.id == this.selectedConditionId;
        });
        if (selectArr.length > 0) {
          //this.searchName = selectArr[0].queryName;
          this.initSeachFormData(selectArr[0]);
        } else {
          //已删除 清空查询条件
          //this.searchName = "";
          this.searchNameArr = data.queryList;
          this.$emit("on-init", this.searchNameArr);
          this.reset();
        }
      }
    },
    //设为默认
    changeDefault(e: any) {
      this.newSearchModel.qisDefault = e.detail.checked;
    },
    //推送到首页
    changePushHome(e: any) {
      this.newSearchModel.qisIndexCustomize = e.detail.checked;
    },
    //请求来源
    getSourceList() {
      this.searchPanelService.getSourceList().then(
        (res: any) => {
          if (res) {
            this.systemIdArr = res;
            this.systemIdArr.unshift({ id: "", name: "全部" });
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //请求查询条件列表
    getQueryList(params: any) {
      this.searchPanelService.getAppQueryByUserId(params).then(
        (res: any) => {
          if (res) {
            this.searchNameArr = res;
            this.$emit("on-init", res);
          }
        },
        (e: any) => {
          // Utils.presentToastWarning("请求异常");
          this.$emit("on-init", new Array(0));
        }
      );
    },
    //保存查询条件
    saveQueryInfo(params: any) {
      let newParam = JSON.parse(JSON.stringify(params));
      newParam.id = "";
      newParam.queryName = this.newSearchModel.searchName;
      newParam.qisDefault = this.newSearchModel.qisDefault;
      newParam.qisIndexCustomize = this.newSearchModel.qisIndexCustomize;
      this.searchPanelService.saveSearch(newParam).then(
        (res: any) => {
          if (res) {
            this.getQueryList(this.searchListParams);
            this.selectedConditionId = res.id;
            Utils.presentToastSuccess("保存成功");
          } else {
            Utils.presentToastWarning("查询条件名称不允许重复");
          }
        },
        (e: any) => {
          console.log(e.message);
          Utils.presentToastWarning("查询条件名称不允许重复");
        }
      );
    },
    //时间处理-创建人处理
    timeFromat() {
      if (this.searchPanelForm.qcreateTimeb) {
        this.searchPanelForm.qcreateTimeb = moment(
          this.searchPanelForm.qcreateTimeb
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qcreateTimee != "") {
        this.searchPanelForm.qcreateTimee = moment(
          this.searchPanelForm.qcreateTimee
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qendTimeb != "") {
        this.searchPanelForm.qendTimeb = moment(
          this.searchPanelForm.qendTimeb
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.qendTimee != "") {
        this.searchPanelForm.qendTimee = moment(
          this.searchPanelForm.qendTimee
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.creatArr && this.creatArr.length > 0) {
        const cUserArr = this.creatArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i].userId;
          const uName = cUserArr[i].name;
          const uPernr = cUserArr[i].pernr;
          if (i == 0) {
            userIds += cUserArr[i].userId;
          } else {
            userIds += "," + cUserArr[i].userId;
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.qcreatorIds = userIds;
          this.searchPanelForm.qcreatorNames = JSON.stringify(userObjectArr);
        }
      }else{
          this.searchPanelForm.qcreatorIds = "";
          this.searchPanelForm.qcreatorNames = "";
      }
    },
    //渲染查询条件数据
    initSeachFormData(params: any) {
      this.searchPanelForm.queryName = params.queryName;
      this.searchPanelForm.qname = params.qname;
      this.searchPanelForm.qpartType = params.qpartType;
      this.searchPanelForm.qsource = params.qsource;
      this.searchPanelForm.qcreateTimeb = params.qcreateTimeb;
      this.searchPanelForm.qcreateTimee = params.qcreateTimee;
      this.searchPanelForm.qendTimeb = params.qendTimeb;
      this.searchPanelForm.qendTimee = params.qendTimee;
      this.searchPanelForm.qisDefault = params.qisDefault;
      this.searchPanelForm.qisIndexCustomize = params.qisIndexCustomize;
      this.searchPanelForm.qstatusLight = params.qstatusLight;
      if (params.qcreatorIds && params.qcreatorNames) {
        this.searchPanelForm.qcreatorIds = params.qcreatorIds;
        this.creatArr = JSON.parse(params.qcreatorNames);
      }
    },
    //删除所选的人
    deleteUser(item: any, index: number) {
      this.creatArr.splice(index, 1);
    },
    //创建人
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.creatArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.creatArr = data.data;
        //初始化
      }
    },
    //重置检索条件
    reset() {
      this.searchPanelForm.queryName = "";
      this.searchPanelForm.qname = ""; //名称
      this.searchPanelForm.qcreateTimeb = ""; //创建时间
      this.searchPanelForm.qcreateTimee = "";
      this.searchPanelForm.qendTimeb = ""; //结束时间
      this.searchPanelForm.qendTimee = "";
      this.searchPanelForm.qpartType = ""; //参与方式（多选）不传默认全部
      this.searchPanelForm.qsource = ""; //来源（多选）不传默认全部
      this.searchPanelForm.qstatusLight = "";
      this.searchPanelForm.qcreatorIds = "";
      this.creatArr = [];
      //this.searchName = "";
      this.selectedConditionId = "";
      this.searchPanelForm.isSelected="false";
      this.$emit("on-close",Object.assign(this.searchPanelForm,{searchId:this.selectedConditionId}));
      this.onClose();
    },

    //重置时间
    resetTime(type: any) {
      if (type == "createTime") {
        this.searchPanelForm.qcreateTimeb = "";
        this.searchPanelForm.qcreateTimee = "";
      }
      if (type == "endTime") {
        this.searchPanelForm.qendTimeb = ""; //结束时间
        this.searchPanelForm.qendTimee = "";
      }
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
