import BaseService from '../base/BaseService';
import { TaskScheduleApi } from '@/api/task/TaskScheduleApi';

export class TaskScheduleService extends BaseService {

    private api = new TaskScheduleApi();

    //保存
    public saveTaskSchedule(params: any): Promise<any> {
        delete params.enterpriseId;
        delete params.systemId;
        return new Promise((resolve, reject) => {
            this.api.save(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //获取任务进度列表
    public getTaskScheduleList({ offset = 1, limit = 10, taskId = '' } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.list({ offset, limit, taskId, sort: '-modify_time' }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //创建一个新实体
    public newSchedule() {
        return super.newModel("task", "TaskProgress");
    }

    //根据用户和日期获取当天填写的任务记录
    public listByUserAndDate(params: any): Promise<any> { 
        return new Promise((resolve, reject) => {
            this.api.listByUserAndDate(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //工时批量保存 
    public saveBatchList(params: any): Promise<any> {  
        return new Promise((resolve, reject) => {
            this.api.saveBatchList(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //删除 
    public clearById(id: any): Promise<any> {  
        return new Promise((resolve, reject) => {
            this.api.clearById(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}