
import { defineComponent } from "vue";
import { isPlatform, modalController } from "@ionic/vue";
import { TaskService } from "@/services/task/TaskService";
import { TaskUserService } from "@/services/task/TaskUserService";
import { TaskLinkContentType, EUserWorkType } from "@/models/task/Enum";
import { useRouter } from "vue-router";
import { TaskModel } from "@/services/task/model/TaskModel";
import { Task } from "@/services/task/model/Task";
import moment from "moment";
import Utils from "@/utils/Utils";
import UpdateScheduleModal from "@/views/task/info/schedule/UpdateScheduleModal.vue";
import CheckScheduleModal from "@/views/task/info/schedule/CheckScheduleModal.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectExecutorByProject from "@/components/selectexecutor/SeleceExecutorByProject.vue";
import SelectLable from "@/views/task/info/selectlable/SelectLable.vue";
import { TaskPermission } from "@/services/task/model/TaskPermission";
import { TagLinkService } from "@/services/TagLinkService";
import { ScheduleRelationService } from "@/services/schedule/ScheduleRelationService";
import BaseService from "@/services/base/BaseService";
import { format } from "url";
import FileCard from "@/components/ListCard/FileCard.vue";
import { FileHelper } from "@/utils/FileHelper";
import TaskFileList from "@/views/task/modal/TaskFileList.vue";
import AttendToModel from "@/views/task/info/AttendToModel.vue";
import EditDeliveryRequireModel from "@/views/task/info/EditDeliveryRequire.vue";
import Setting from "@/rest/Setting";
import { date } from "gantt";
import { ScheduleService } from "@/services/schedule/ScheduleService";
import { nextTick } from "process";
import { log } from "console";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "EditTaskInfo",
  components: {
    FileCard,
    HeaderModal,
  },
  props: {
    taskId: String,
    folderId: String,
  },
  emits: ["on-executor", "update-task-info", "on-refresh"],
  data() {
    const router = useRouter();
    const _taskInfo: TaskModel = new TaskModel();
    const _task: any = {};
    const _fileList: any[] = [];
    const _tagLinkService = new TagLinkService();
    const _permission: TaskPermission = new TaskPermission();
    const fileHelper = new FileHelper();
    return {
      linkScheduleService: new ScheduleRelationService(),
      api: new TaskService(),
      taskUserService: new TaskUserService(),
      tagLinkService: _tagLinkService,
      scheduleService: new ScheduleService(),
      taskApi: new TaskService(),
      router: router,
      info: _taskInfo,
      task: _task,
      isIndependentTask: true, //是否是独立任务
      hasPutPermission: true, //有编辑权限
      hasNamePermission: true, //是否有修改name的权限
      fileList: _fileList, //关联文件列表
      permission: _permission,
      fileHelper: fileHelper,
      isSVW: Setting.isSVW,
      ssT: new Date().toISOString(),
      error: {
        name: false,
        startTime: false, //开始时间
        endTime: false, //结束时间
        planHours: false, //计划工时
      },
      startTime: "--",
      endTime: "--",
      isLookOver: false,
      actualStartTimeStr: "",
      permissionLoaded: false, //权限加载完成
      showAdd: false,
      chatGroupId: "",
      isDeleted: false,
      isMember: false,
      projectName: "",
      projectId: "",
      taskName: "",
      isExecutor: false, //当前登录人是否执行人
    };
  },
  mounted() {
    this.isLookOver = false;
    this.getTaskInfoRequest();
  },
  methods: {
    formatDate(time: any) {
      return moment(time).format("YYYY-MM-DD");
    },
    getTaskInfoRequest() {
      let that = this;
      if (that.taskId) {
        that.taskApi.getTaskInfo(that.taskId).then((res) => {
          that.task = res.task;
          that.task.taskVisibility = res.task.taskVisibility + "";
          that.isIndependentTask = res.project == null || res.project == "";
          that.info = new TaskModel(res);
          that.initTaskPermissions();
          that.initTaskInfo(that.info);
          that.taskName = that.task.name;
          that.chatGroupId = that.task.chatGroupId;
          that.isDeleted = that.task.isDeleted;
          //   that.folderId = that.task.folderId;
          that.isMember = false;
          for (let user of res.taskUserList) {
            if (user.userId == SystemUtils.loginUser.id) {
              that.isMember = true;
              break;
            }
          }
          for (let user of res.taskUserList) {
            if (
              user.workType == 1 &&
              (user.userId == SystemUtils.loginUser.id ||
                user.creatorId == SystemUtils.loginUser.id)
            ) {
              that.isExecutor = true;
              break;
            }
          }
          let project = res.project;
          if (project) {
            that.projectName = project.name;
            that.projectId = project.id;
            // that.folderId = project.folderId;
          } else {
            that.projectName = "";
            that.projectId = "";
          }
        });
      } else {
        Utils.presentToastWarning("该任务不存在");
        that.router.go(-1);
      }
    },
    //初始化任务权限
    initTaskPermissions() {
      this.taskApi
        .getTaskPermissionByUser(
          this.isIndependentTask,
          this.isIndependentTask ? this.taskId : this.info.project.id
        )
        .then((res) => {
          this.info.permission = new TaskPermission(
            res,
            this.info.taskRoles,
            this.info.isTaskDeleted,
            this.isIndependentTask
          );

          this.initTaskPermission(this.info.permission);
          //   this.initTaskMenuShow();
        });
    },
    resetError(item: Boolean) {
      item = false;
    },
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    hasChangeTaskStatus() {
      const unFinishedTask = this.info.subTaskList.filter((o) => {
        return (
          !o.isFinished &&
          (!o.isDeleted ||
            !o.isDeletedProject ||
            !o.isDeletedTaskGroup ||
            !o.isDeletedTaskStage)
        );
      }).length;
      return this.permission.status && unFinishedTask == 0;
    },
    showPartUser() {
      return (
        (this.isIndependentTask && this.permission.executor) ||
        (!this.isIndependentTask && this.permission.partypost)
      );
    },
    showEditRemark() {
      return (
        (this.isIndependentTask && this.permission.name) ||
        (!this.isIndependentTask && this.permission.put)
      );
    },
    hasShowLinkListPermission() {
      return (
        this.info.taskLinkProjectList.length > 0 ||
        this.info.taskLinkTaskList.length > 0 ||
        this.info.taskLinkFileList.length > 0 ||
        this.info.taskLinkScheduleList.length > 0
      );
    },
    hasDelLinkPermission() {
      return (
        (this.isIndependentTask && this.permission.name) ||
        (!this.isIndependentTask && this.permission.linkdel)
      );
    },
    //子任务状态不可用状态设置
    disabledChildTaskStatus(item: any) {
      //		满足以下任意一种情况，返回false
      //		1、任务的isUserFinished为false(业务系统不允许修改)
      //		2、存在未完成子任务
      //		3、子任务的父任务已完成，不允许修改子任务状态
      //		4、没有“任务修改状态”权限
      if (this.task.isFinished) {
        //子任务的父任务已完成，不允许修改子任务状态
        return true;
      }
      if (
        item.isUserFinished == false ||
        item.isChildsFinished == false ||
        item.stausPermission == false
      ) {
        item.isCheckFinished = false; //是否允许点击完成
        return true;
      }

      item.isCheckFinished = true; //是否允许点击完成
      return false;
    },
    initTaskPermission(per: any) {
      this.permission = per;
      this.permissionLoaded = true;
      //获取子任务的权限
      if (this.info && this.info.subTaskList) {
        if (this.isIndependentTask) {
          this.info.subTaskList.map((item: any) => {
            let _taskUsers = this.info.getTaskRoleIdList(
              item.creatorId,
              item.taskUser
            );
            //请求接口
            this.api.getTaskPermissionByUser(true, item.id).then((res) => {
              item.stausPermission = this.permission.getPermissionOne(
                res,
                "status",
                _taskUsers
              );
              item.deletePermission = this.permission.getPermissionOne(
                res,
                "delete",
                _taskUsers
              );
            });
          });
        } else {
          this.info.subTaskList.map((item: any) => {
            item.stausPermission = this.permission.status;
            item.deletePermission = this.permission.delete;
          });
        }
      }
    },
    initTaskInfo(taskInfo: TaskModel) {
      this.info = taskInfo;
      this.task = this.info.task;
      this.permissionLoaded = false;

      if (this.task.startTime) {
        this.startTime = moment(this.task.startTime).format("YYYY-MM-DD");
        this.task.startTime = moment(this.task.startTime).format(
          "YYYY-MM-DDTHH:mmZ"
        );
      }
      if (this.task.endTime) {
        this.endTime = moment(this.task.endTime).format("YYYY-MM-DD");
        this.task.endTime = moment(this.task.endTime).format(
          "YYYY-MM-DDTHH:mmZ"
        );
      }
      if (this.task.actualStartTime && this.task.actualStartTime != "") {
        this.task.actualStartTime = moment(this.task.actualStartTime).format(
          "YYYY-MM-DD HH:mm"
        );
        this.actualStartTimeStr = moment(this.task.actualStartTime).format(
          "YYYY-MM-DD"
        );
      }
      if (this.task.actualEndTime)
        this.task.actualEndTime = moment(this.task.actualEndTime).format(
          "YYYY-MM-DD"
        );
      this.bindTaskPriority(this.task.priority);
      // this.bindTaskDifficulty(this.task.difficulty);
      //是否是独立任务
      this.isIndependentTask =
        this.info.project == null || this.info.project == "";
      const files = this.info.taskLinkFileList;
      this.$emit("on-executor", { executor: this.task.executorUsers });
      if (files.length > 0) {
        const idList = files.map((e) => {
          return e.targetId;
        });
        const ids = idList.join(",");
        this.api.getFilesByDocmentIds(ids).then((res: any) => {
          this.fileList = [];
          res.map((e: any) => {
            const f = files.find((f) => {
              return f.targetId == e.fileId;
            });
            if (f) {
              let obj = {
                id: e.fileId,
                mainFileId: e.mainFileId,
                linkId: f.id,
                name: e.name,
                extName: e.extName,
                className: "document",
                previewImage: e.previewImage,
                isDownloadOver: true,
                downloadFlag: false,
                systemId: e.systemId,
                busId: e.busId,
                busTable: e.busTable,
                busTag: e.busTag,
                rootId: e.rootId,
                folderId: e.folderId,
                size: e.size,
                documentId: e.documentId,
                isDeleted: false,
              };
              this.fileHelper.checkExistsFile(e.mainFileId, e.name).then(
                (fr: boolean) => {
                  obj.downloadFlag = fr;
                  this.fileList.push(obj);
                },
                (fe: boolean) => {
                  obj.downloadFlag = fe;
                  this.fileList.push(obj);
                }
              );
            }
          });
        });
      }
    },
    getDetailRequest() {
      if (this.taskId) {
        this.api.getTaskInfo(this.taskId).then((res) => {
          this.initTaskInfo(new TaskModel(res));
        });
      }
    },
    convertToPriorityDesc(priority: number) {
      if (priority == 0) {
        return "较低"; //"较低";
      } else if (priority == 1) {
        return "普通"; //"普通";
      } else if (priority == 2) {
        return "紧急"; // "紧急";
      } else if (priority == 3) {
        return "非常紧急"; //"非常紧急";
      }
    },
    convertToDifficultyDesc(difficulty: number) {
      if (difficulty == 1) {
        return "简单"; //"简单";
      } else if (difficulty == 2) {
        return "一般"; // "一般";
      } else if (difficulty == 3) {
        return "复杂"; //"复杂";
      }
    },
    convertToData(date: string) {
      if (date) {
        return moment(date).format("yyyy-MM-DD");
      } else {
        return "暂无";
      }
    },
    async openTaskFileModel() {
      console.log("1111");
      
      const modal = await modalController.create({
        component: TaskFileList,
        cssClass: "notice-modal-class",
        componentProps: {
          busId: this.task.folderId,
        },
      });
      await modal.present();
    },
    filterTaskUserItem(taskUser: any, length: number) {
      if (taskUser && taskUser.length > 0) {
        return taskUser.slice(
          0,
          length > taskUser.length ? taskUser.length : length
        );
      }
      return [];
    },
    openTaskInfo(id: string) {
      this.isLookOver = false;
      this.router.push({
        path: "/task-page",
        query: {
          id: id,
        },
      });
    },
    openProjectInfo(id: string, name: string) {
      this.router.push({
        path: "/ProjectPage",
        query: {
          projectId: id,
          projectName: name,
          folderId: this.folderId,
        },
      });
    },
    //关联内容点击事件
    linkHandler(type: number, item: any) {
      if (type == 1) {
        this.openProjectInfo(item.targetProjectId, item.projectName); //跳转项目详情页
      } else if (type == 2) {
        this.openTaskInfo(item.targetId); //跳转任务详情页
      } else if (type == 3) {
        //预览或下载文件
        //this.downloadFile(item.fileId, item.name);
      } else if (type == 4) {
        //跳日程详情
        // this.router.push({
        //   name: "detail-schedule-feedback",
        //   params: {
        //     info: JSON.stringify(item),
        //   },
        // });
        this.openScheduleInfo(item);
      }
    },
    openScheduleInfo(item: any) {
      let params = {
        scheduleId: item.id,
        scheduleTimeId: item.scheduleTimeId,
      };
      this.scheduleService.getLimitDetail(params).then((result: any) => {
        if (result) {
          this.router.push({
            path: "/schedule/detail/feedback",
            query: {
              id: item.id,
              scheduleTimeId: item.scheduleTimeId,
              showCalendar: "false",
            },
          });
        } else {
          Utils.presentToastWarning("无权限查看此页面");
        }
      });
    },

    //删除子任务
    deleteChildTaskHandler(index: number, id: string) {
      Utils.confirmDialog(
        "移到回收站",
        "您确定要把该任务移到回收站吗？",
        () => {
          this.api.removeToRecycle(id).then((res) => {
            if (res) {
              this.info.subTaskList.splice(index, 1);
            } else {
              Utils.presentToastWarning("删除失败");
            }
          });
        }
      );
    },
    //删除关联
    clearLinkContentHandler(id: string, index: number, type: number) {
      Utils.confirmDialog("取消关联", "您确认要取消关联吗？", () => {
        if (type == 4) {
          let parms = {
            scheduleTimeId: id,
            formId: this.taskId,
            type: 1,
            id: BaseService.newId("", ""),
          };
          this.linkScheduleService
            .deleteLinkSchedule(parms)
            .then((res: any) => {
              if (res) {
                this.info.taskLinkScheduleList.splice(index, 1);
              } else {
                Utils.presentToast("操作失败");
              }
            });
        } else {
          this.api.removeLinkContent(id).then((res) => {
            if (res) {
              if (type == TaskLinkContentType.Project) {
                this.info.taskLinkProjectList.splice(index, 1);
              } else if (type == TaskLinkContentType.Task) {
                this.info.taskLinkTaskList.splice(index, 1);
              } else if (type == TaskLinkContentType.Document) {
                this.fileList.splice(index, 1);
                this.info.taskLinkFileList.splice(index, 1);
              }
            } else {
              Utils.presentToast("操作失败");
            }
          });
        }
      });
    },
    downloadHandler(obj: any) {
      if (obj.fileId) {
        this.downloadFile(obj);
      }
    },
    //下载
    downloadFile(obj: any) {
      let fileId = obj.fileId;
      let fileName = obj.name;
      if (fileId) {
        const index = this.fileList.findIndex((f) => {
          return f.mainFileId == fileId;
        });
        if (index < 0) {
          return;
        }
        let doc = this.fileList[index];
        doc.isDownloadOver = false;
        this.fileHelper.downloadNative(fileId, fileName).then(
          (result: any) => {
            let flag = false;
            if (result) {
              if ("nativeURL" in result && result.nativeURL != "") {
                doc.isDownloadOver = true;
                doc.downloadFlag = true;
                Utils.presentToastSuccess("下载完成");
                flag = true;
                if (obj.previewImage != "") {
                  this.previewFile(obj);
                }
              }
            }
            if (!flag) {
              doc.isDownloadOver = true;
              Utils.presentToastWarning("下载失败");
            }
          },
          (e: any) => {
            doc.isDownloadOver = true;
            Utils.presentToastWarning("下载失败");
          }
        );
      }
    },
    //浏览
    previewFile(r: any) {
      if (r) {
        if (r.isPic) {
          let extName = r.name.substring(r.name.lastIndexOf(".") + 1);
          this.fileHelper.downloadOriginal(r.fileId, extName, 2).then(
            (r: any) => {
              this.fileHelper.previewVant([r], 0);
            },
            (e: any) => {
              Utils.presentToastWarning("查看原图失败");
            }
          );
        } else {
          this.fileHelper.previewNativeFile(r.fileId, r.name);
        }
      }
    },
    //进展情况
    async checkSchedule() {
      const modal = await modalController.create({
        component: CheckScheduleModal,
        cssClass: "task-progress-update-class",
        backdropDismiss: false,
        componentProps: {
          taskId: this.taskId,
        },
      });
      await modal.present();
    },

    //选择标签
    async chooseTags() {
      const modal = await modalController.create({
        component: SelectLable,
        cssClass: "notice-modal-class",
        backdropDismiss: true,
        componentProps: {
          data: {
            busId: this.taskId,
            type: "edit",
            groupId: this.info.project.tagGroupId,
            busTable: "tt_task",
            checkedLabelList: this.task.tagLink,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.checkedLabelList) {
        this.task.tagLink = data.checkedLabelList;
      }
    },
    //选择执行人或参与人
    pickUserHandler(type: number) {
      //选择执行人
      if (type == 1) {
        if (this.isIndependentTask) {
          this.selectExecutor();
        } else {
          this.selectTeamUserExecutor(type);
        }
      } else {
        if (this.isIndependentTask) {
          this.selectJosinUser();
        } else {
          this.selectTeamUserExecutor(type);
        }
      }
    },
    //选择项目成员选择
    async selectTeamUserExecutor(type: number) {
      let postUser = [];
      if (type == 1) {
        postUser = this.convertToSelectFormat(this.task.executorUsers);
      } else {
        postUser = this.convertToSelectFormat(this.task.partUsers);
      }
      const modal = await modalController.create({
        component: SelectExecutorByProject,
        cssClass: "notice-modal-class",
        mode: "ios",
        backdropDismiss: false,
        componentProps: {
          data: {
            projectId: this.task.projectId,
            executorList: postUser,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        if (type == 1) {
          this.task.executorUsers = this.addOrDropUserHandler(
            data.data,
            this.task.executorUsers,
            EUserWorkType.Executor
          );
        } else {
          this.task.partUsers = this.addOrDropUserHandler(
            data.data,
            this.task.partUsers,
            EUserWorkType.Party
          );
        }
      }
    },
    //从全员选择-执行人
    async selectExecutor() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        backdropDismiss: false,
        componentProps: {
          data: {
            type: "executor",
            isCkeckbox: true,
            executorList: this.convertToSelectFormat(this.task.executorUsers),
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        this.task.executorUsers = this.addOrDropUserHandler(
          data.data,
          this.task.executorUsers,
          EUserWorkType.Executor
        );
      }
    },
    //从全员选择-参与人
    async selectJosinUser() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        backdropDismiss: false,
        componentProps: {
          data: {
            type: "joiner",
            isCkeckbox: true,
            executorList: this.convertToSelectFormat(this.task.partUsers),
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        this.task.partUsers = this.addOrDropUserHandler(
          data.data,
          this.task.partUsers,
          EUserWorkType.Party
        );
      }
    },
    //转换为模态框要求的接收参数
    convertToSelectFormat(dataList: any[]) {
      const newList = [];
      for (var i = 0; i < dataList.length; i++) {
        const data = dataList[i];
        newList.push({
          userId: data.userId,
          name: data.name,
        });
      }
      return newList;
    },
    //模态返回参数后，调用接口实时新增或删除，参与人执行人等
    addOrDropUserHandler(newDataList: any[], oldDataList: any[], type: number) {
      const newArray = [];
      if (newDataList.length > 0) {
        for (var i = 0; i < newDataList.length; i++) {
          const u = newDataList[i];
          const userId: string = u.userId ? u.userId : u.id;
          const index = oldDataList.findIndex((e) => {
            return e.userId == userId;
          });
          if (index > -1) {
            newArray.push(oldDataList[index]);
            continue;
          }
          //不存在的要新增
          const newdata = this.taskUserService.getNewUserModel({
            userId: userId,
            name: u.name,
            workType: type,
            taskId: this.task.id,
            projectId: this.task.projectId ? this.task.projectId : "",
          });

          newArray.push(newdata);
          this.taskUserService.addTaskUser(newdata).then((res: any) => {
            this.$emit("update-task-info");
          });
        }
      }
      //删除旧的
      for (var i = 0; i < oldDataList.length; i++) {
        const data = oldDataList[i];
        if (data.userId == this.task.creatorId && type == 2) {
          continue;
        }
        const index = newDataList.findIndex((e) => {
          return e.userId == data.userId;
        });
        if (index == -1) {
          //删除
          this.taskUserService
            .deleteTaskUser({
              taskId: data.taskId,
              userId: data.userId,
              workType: data.workType,
            })
            .then((res: any) => {
              this.$emit("update-task-info");
            });
        }
      }
      if (type == 2) {
        //判断没有删除创建人
        const index = newArray.findIndex((e) => {
          return e.userId == this.task.creatorId;
        });
        if (index == -1) {
          const creatdata = this.taskUserService.getNewUserModel({
            userId: this.task.creatorId,
            name: this.task.creator,
            workType: type,
            taskId: this.task.id,
            projectId: this.task.projectId ? this.task.projectId : "",
          });
          newArray.push(creatdata);
        }
      }

      return newArray;
    },
    /*************修改内容的任意字段，实现自动保存******************* */
    //任务名称修改
    onNameBlur() {
      this.task.name = this.task.name.trim();
      if (!this.task.name || this.task.name.replace(/\s+/g, "") == "") {
        Utils.presentToastWarning("请输入任务名称");
        this.error.name = true;
        return false;
      }
      if (Utils.isInCludeEmoji(this.task.name)) {
        this.error.name = true;
        Utils.presentToastWarning("任务名称不可以包含表情");
        return false;
      }
      this.error.name = false;
      const updataData = {
        id: this.task.id,
        name: this.task.name,
        setFields: ["name"],
      };
      this.updateFieldReques(updataData, () => {});
    },
    //计划时间
    onDateChangeHandler(e: any, name: string) {
      if (name == "startTime") {
        // if (e.detail.value == this.task.startTime) {
        //   return;
        // }
        this.error.startTime = false;
        let dateStart = new Date(e.detail.value).getTime();
        let dateEnd = 0;
        if (this.task.endTime) {
          dateEnd = new Date(this.task.endTime).getTime();
        } else {
          dateEnd = dateStart;
        }
        if (dateStart > dateEnd) {
          Utils.presentToastWarning("开始时间不能晚于结束时间");
          this.error.startTime = true;
          return;
        }
        const updataData = {
          id: this.task.id,
          setFields: [name],
          startTime: "",
        };
        updataData.startTime = moment(e.detail.value).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.updateFieldReques(updataData, () => {
          this.task.startTime = e.detail.value;
        });
      } else {
        // if (e.detail.value == this.task.endTime) {
        //   return;
        // }
        this.error.endTime = false;

        let dateEnd = new Date(e.detail.value).getTime();

        let dateStart = 0;
        if (this.task.startTime) {
          dateStart = new Date(this.task.startTime).getTime();
        }
        if (dateStart > dateEnd) {
          Utils.presentToastWarning("结束时间不能早于开始时间");
          this.error.endTime = true;
          return;
        }

        const updataData = {
          id: this.task.id,
          setFields: [name],
          endTime: "",
        };
        updataData.endTime = moment(e.detail.value).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.updateFieldReques(updataData, () => {
          this.task.endTime = e.detail.value;
        });
      }
    },
    //更新某个字段
    updateFieldReques(updataData: any, callback: any) {
      this.api.updateFieldsById(updataData).then((response) => {
        if (response) {
          callback();
        }
      });
    },
    onPlanWokingBlur() {
      nextTick(() => {
        if (this.task.planWorkingHour) {
          this.task.planWorkingHour = this.task.planWorkingHour.trim();
        }
        if (this.task.planWorkingHour != "") {
          var reg = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,1}$/;
          if (!reg.test(this.task.planWorkingHour)) {
            this.error.planHours = true;
            Utils.presentToastWarning("计划工时需输入正数，最多保留1位小数");
            return false;
          } else {
            if (parseFloat(this.task.planWorkingHour) > 999999) {
              Utils.presentToastWarning("计划工时最大值不允许超过999999");
              return false;
            }
          }
        }
        this.error.planHours = false;
        const updataData = {
          id: this.task.id,
          planWorkingHour: this.task.planWorkingHour,
          setFields: ["planWorkingHour"],
        };
        this.updateFieldReques(updataData, () => {});
      });
    },
    bindTaskPriority(e: any) {
      if (typeof e.detail == "undefined") {
        return;
      }
      if (e.detail.value == this.task.priority) {
        return;
      }
      this.task.priority = e.detail.value;
      const updataData = {
        id: this.task.id,
        priority: this.task.priority,
        setFields: ["priority"],
      };
      this.updateFieldReques(updataData, () => {});
    },
    bindTaskDifficulty(e: any) {
      if (typeof e.detail == "undefined") {
        return;
      }
      if (e.detail.value == this.task.difficulty) {
        return;
      }
      this.task.difficulty = e.detail.value;
      const updataData = {
        id: this.task.id,
        difficulty: this.task.difficulty,
        setFields: ["difficulty"],
      };
      this.updateFieldReques(updataData, () => {});
    },
    //工时填报
    async updateSchedule(finish: any) {
      let folederId = this.task.folderId;
      if (this.info.project != "") {
        folederId = this.info.project.folderId;
      }
      const modal = await modalController.create({
        component: UpdateScheduleModal,
        cssClass: "task-progress-update-class",
        componentProps: {
          startTime: this.task.actualStartTime,
          endTime: this.task.actualEndTime,
          taskProgress: this.task.progress,
          hour: this.task.actualWorkingHour,
          taskId: this.taskId,
          folderId: folederId,
          isIndependentTask: this.isIndependentTask,
          isfinish: finish,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      console.log(data);

      if (data) {
        if (data.isOk) {
          if (finish) {
            // this.changeFinish(true);
            // this.bindTaskFinshStatus("1", true);
            this.api.isfullworkingHour(this.task.id).then((res) => {
              if (res) {
                this.changeFinish(true);
              } else {
                this.changeFinish(false);
                Utils.presentToastWarning("请填写完整的工时");
              }
            });
          } else {
            this.changeFinish(false);
            // this.bindTaskFinshStatus("0", false);
          }
        }
      } else {
        this.changeFinish(false);
        // this.bindTaskFinshStatus("0", false);
        // this.bindTaskFinshStatus("0", false);
      }
    },
    //完成状态
    bindTaskFinshStatus(e: any, isflag: any) {
      //  if (e == this.task.isFinishedStr) {
      //   return;
      // }
      this.task.isFinishedStr = e;
      this.task.isFinished = e == "1";
      if (isflag) {
        if (e == "1") {
          this.api.isfullworkingHour(this.task.id).then((res) => {
            if (res) {
              this.changeFinish(true);
            } else {
              Utils.presentToastWarning("请填写完整的工时");
              this.updateSchedule(true); //去填写工时
            }
          });
        } else {
          this.changeFinish(false);
        }
      } else {
        // this.updateSchedule(true);
        this.changeFinish(false);
      }
    },
    changeFinish(finish: any) {
      console.log(finish);

      this.api.changeFinishStatus(this.task.id, finish).then((res) => {
        if (!res) {
          Utils.presentToast("操作失败");
        } else {
          this.getDetailRequest();
          this.$emit("on-refresh", { isFinished: finish });
        }
      });
    },
    onFinishedChange(ev: any, item: any) {
      if (ev.target.name != item.id) {
        return;
      }
      this.api.changeFinishStatus(item.id, ev.detail.checked).then((res) => {
        if (!res) {
          Utils.presentToast("操作失败");
        }
      });
    },
    onRemarkBlur() {
      const updataData = {
        id: this.task.id,
        remark: this.task.remark,
        setFields: ["remark"],
      };
      if (Utils.isInCludeEmoji(updataData.remark)) {
        Utils.presentToastWarning("任务备注不可以包含表情");
        return false;
      }
      this.updateFieldReques(updataData, () => {});
    },
    //修改交付要求
    async editDeliveryRequire() {
      let list = new Array();
      const modal = await modalController.create({
        component: EditDeliveryRequireModel,
        cssClass: "notice-modal-class",
        componentProps: {
          deliveryRequire: this.task.deliveryRequire,
          taskId: this.task.id,
          isCanEdit: this.isIndependentTask
            ? "true"
            : this.isSVW
            ? "false"
            : "true",
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();

      if (data && data.info) {
        if (data.info != "") {
          this.task.deliveryRequire = data.info;
        } else {
          this.task.deliveryRequire = "";
        }
      }
    },
    onDeliveryRequireBlur() {
      const updataData = {
        id: this.task.id,
        deliveryRequire: this.task.deliveryRequire,
        setFields: ["deliveryRequire"],
      };
      if (Utils.isInCludeEmoji(updataData.deliveryRequire)) {
        Utils.presentToastWarning("交付要求不可以包含表情");
        return false;
      }
      this.updateFieldReques(updataData, () => {});
    },
    hexToRgba(hex: string) {
      return Utils.hexToRgba(hex, 0.1);
    },
    delTagLink(id: string, index: number, tagGroupId: string) {
      this.tagLinkService
        .clearTagLinks({
          groupId: tagGroupId,
          busId: this.taskId,
          busTable: "tt_task",
          tagId: id,
        })
        .then((res: any) => {
          if (res) {
            this.task.tagLink.splice(index, 1);
          }
        });
    },
    fromatTime(beginTime: any, endTime: any) {
      let str = "";
      if (beginTime && endTime) {
        let beginTimeT = moment(beginTime).format("YYYY-MM-DD HH:mm:ss");
        let endTimeT = moment(endTime).format("YYYY-MM-DD HH:mm:ss");

        if (beginTimeT.substring(0, 10) == endTimeT.substring(0, 10)) {
          str =
            beginTimeT.substring(0, 10) +
            " " +
            beginTimeT.substring(11, 16) +
            "~" +
            endTimeT.substring(11, 16);
        } else {
          str = beginTimeT + "~" + endTimeT;
        }
      }
      return str;
    },
    //清空时间
    resetTime() {
      const updataData = {
        id: this.task.id,
        setFields: ["startTime", "endTime"],
        startTime: "",
        endTime: "",
      };
      this.updateFieldReques(updataData, () => {
        this.task.startTime = "";
        this.task.endTime = "";
      });
    },
    //打开办理模态
    async openAttendToModelHandler(task: any) {
      const modal = await modalController.create({
        component: AttendToModel,
        cssClass: "notice-modal-class",
        componentProps: {
          taskInfo: this.task,
        },
      });
      await modal.present();
    },
    chooseStatus() {
      let selectOperate: any = document.getElementById("select-Status-task");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    choosePriority() {
      let selectOperate: any = document.getElementById("select-Priority-task");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    chooseDiffcuty() {
      let selectOperate: any = document.getElementById(
        "select-Difficulty-task"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
    changeProgress(val: any) {
      const updataData = {
        id: this.task.id,
        setFields: ["progress"],
        progress: val,
      };
      this.updateFieldReques(updataData, () => {});
    },
    closeModel(): void {
      modalController.dismiss({ refresh: true });
    },
  },
});
