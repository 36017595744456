
  import {TaskLinkContentService} from '@/services/task/TaskLinkContentService';
  import {modalController} from '@ionic/vue'
  import SystemUtils from '@/utils/SystemUtils';
  import FileModal from "../../modal/FileModal.vue";
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: "ProjectFileList",
    props: {
      projectId: String, //要关联文件的任务的项目Id，可能为空
      taskId: String, //要关联文件的任务Id
      isIndependentTask: Boolean,
      searchKey:String,
    },
    data() {
      return {
        showNoDate: false,
        linkApi: new TaskLinkContentService(),
        parameters: {
          userId: "",
          isDeleted: false,
          offset: 1,
          limit: 15,
          projectName: "",
          createTimeCB: "",
          createTimeCE: "",
          endTimeCB: "",
          endTimeEE: "",
          projectLight: "",
          createTimeOrder: "DESC",
          statusLightOrder: "DESC?offset=0&limit=0"
        },
        list: [],
        hasAddFiles:false //是否添加了文件
      };
    },
    watch:{
      searchKey(){
        this.init(this.searchKey+"");
      },
    },
    mounted() {
      this.parameters.userId = SystemUtils.loginUser.id;
      this.init(this.searchKey+"");
    },
    methods: {
      init(projectName: string) {
        this.parameters.projectName = projectName;
        this.parameters.offset = 1;
        this.getListRequest((res: any) => {
          this.list = res;
          this.showNoDate = res.length == 0;
        });
      },
      async itemClick(item: any) {
        const modal = await modalController.create({
          component: FileModal,
          cssClass: "notice-modal-class",
          componentProps: {
            busId: item.id,
            beLinkId: this.taskId ? this.taskId : "",
            projectId: this.projectId ? this.projectId : "",
            type: "project"
          },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if(data && data.refresh){
          this.hasAddFiles=true;
        }
      },
      nextPage(event: any) {
        this.parameters.offset++;
        this.getListRequest((result: any) => {
          this.list = this.list.concat(result);
          event.target.complete();
          if (result) {
            if (result == null || result.length <= 0) {
              event.target.disabled = true;
            }
          }
        });
      },
      doRefresh(event: any) {
        this.parameters.offset = 1;
        this.getListRequest((res: any) => {
          this.list = res;
          this.showNoDate = res.length == 0;
        });
        setTimeout(() => {
          event.target.complete();
        }, 2000);
      },
      getListRequest(callback: any) {
        this.linkApi.getToLinkProjectList(this.parameters).then((res) => {
          callback(res);
        });
      },
    },
  });
