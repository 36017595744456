import BaseRsp from '../base/BaseRsp';
import MessageContent from './MessageContent';

export class MessageRsp extends BaseRsp {
    messages?: Array<Message> = this.data;
}

export default class Message {
    id?: string;
    chatGroupId?: string;
    content?: string;
    busId?: string;
    busTable: string = "";
    busTag?: any = "";
    title?: string = "";
    messageType?: number;
    sendTime: string = " ";
    formatSendTime?: string = "";
    sendUserId?: string;
    systemId?: string;
    toUserId?: string;
    url?: string;
    messageState?: number;  // 0已发送 1已接收 2已读取
    isAt?: boolean;
    isList?: boolean;
    sendUserName?: string;
    chatMessageMongoId?: string;
    file?: any = {fileId: '',fileName: '',fileType: '',fileSize: ''};
    fileName?: string = "";
    count?: number;
    imgs?: Array<any>;
}