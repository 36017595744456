
import { modalController } from "@ionic/vue";
import ChooseOrganizationList from "./ChooseOrganization.vue";
import ProjectOrganizationsService from "@/services/project/ProjectOrganizationsService";
import { AnyCnameRecord } from "dns";
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "SetOrganizationList",
  props: {
    id: String,
    isHasPermission: Boolean, //是否编辑
  },
  data() {
    return {
      api: new ProjectOrganizationsService(),
      parameters: {
        projectId: this.id,
        organizationName: "",
        offset: 1,
        limit: 15,
      },
      list: [],
      idList: [], //选中的id集合
    };
  },
  mounted() {
    this.init("");
  },
  methods: {
    init(organizationName: string) {
      this.parameters.organizationName = organizationName;
      this.loadDataFromPageOne();
    },
    loadDataFromPageOne() {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    deleteHandler(id: string, index: number) {
      let that = this;
      Utils.confirmDialog("确认", "确认要删除吗？", function () {
        that.api.delete(id).then((res) => {
          if (res) {
            that.loadDataFromPageOne();
          }else{
            Utils.presentToastWarning("删除失败");
          }
        }).catch(()=>{
          Utils.presentToastWarning("删除失败");
        });
      });
      //this.list.splice(index, 1);
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.loadDataFromPageOne();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.api.getList(this.parameters).then((result) => {
        callback(result);
      });
    },
    async openChooseOrganizationModal() {
      const modal = await modalController.create({
        component: ChooseOrganizationList,
        cssClass: "notice-modal-class",
        componentProps: {
          id: this.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        this.loadDataFromPageOne();
      }
    },
  },
});
