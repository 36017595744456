
/**
 * 文件权限
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 首页
 */
export class FilePermissionApi extends TaskBaseService {

    constructor() {
        super("filePermission");
    }

    /**
     * 检查文件权限
     */
    public checkPermission(params: any): Promise<any> {
        const path = 'permission';
        return this.request(path, this.rest.METHOD_POST, params);
    }

}