import Setting from '@/rest/Setting';
import CoreService from './CoreService';

export default class PlanCenterBaseService extends CoreService {
    constructor(resource: string, version?: string) {
        super({
            name: Setting.apiBaseServiceName.planCenter,
            resource: resource
        });
    }
    //企业id
    public enterpriseId=Setting.currentEnterprise.id;
}