/**
 * 任务标签
 */
import TagBaseService from '../base/TagBaseService';
/**
 * 首页
 */
export class TagLinkApi extends TagBaseService {

    constructor() {
        super("");
    }

    /**
     * 编辑任务获取标签
     * @param param groupId、busId busTable：tt_task
     */
    public getTagListAndLinkList(param: any): Promise <any> {
        const path = 'taglinks/getTagListAndLinkList';
        return this.request(path, this.rest.METHOD_GET,param);
    }

    /**
     * 创建任务获取标签
     * @param param groupId、busId busTable：tt_task
     */
    public getTagList(param: any): Promise <any> {
        const path = 'tags/getByGroupId';
        return this.request(path, this.rest.METHOD_GET,param);
    }
    
    /**
     * 获取用户权限（根据ProjcetId和userid）
     * @param param 
     */
    public getUserPermissionByProject(param: any) {
        const path = 'taglinks/getUserPermissionByProject';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 添加标签
     * @param param 
     */
    public addTag(param: any) {
        const path = 'tags';
        return this.request(path, this.rest.METHOD_POST, param);
    }

    /**
     * 更新标签
     * @param param 
     */
    public updateTag(param: any) {
        const path = 'tags';
        return this.request(path, this.rest.METHOD_PUT, param);
    }

    /**
     * 删除标签
     * @param param 
     */
    public deleteTag(param: any) {
        const path = 'tags/'+param;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }

    /**
     * 新建标签关联关系控制器实体
     * @param param 
     */
    public addTagLinks(param: any) {
        const path = 'taglinks';
        return this.request(path, this.rest.METHOD_POST, param);
    }

    /**
     * 删除标签关联关系控制器实体
     * @param param groupId、busId busTable：tt_task
     */
    public clearTagLinks(param: any): Promise <any> {
        const path = 'taglinks/clearByUserCondition?groupId='+param.groupId+"&&busId="+param.busId+"&&busTable="+param.busTable+"&&tagId="+param.tagId;
        return this.request(path, this.rest.METHOD_DELETE,null);
    }

     /**
     * 项目-回收站-标签列表
     * */
    public getDeletedByGroupId(param: any) {
        return this.request("tags/getDeletedByGroupId", this.rest.METHOD_GET, param);
    }

    /**
     * 项目-回收站-标签列表-删除
     * */
    public clearById(param: any) {
        return this.request("tags/clearById?id="+param.id, this.rest.METHOD_DELETE, null);
    }

    /**
     * 项目-回收站-标签列表-还原
     * */
    public restoreById(param: any) {
        return this.request("tags/restoreById?id="+param.id, this.rest.METHOD_PUT, null);
    }
}