import Permission, { EPermissionType } from '@/models/permission/Permission';
import Setting from "@/rest/Setting";

export class TaskPermission {
    name: boolean = false; //修改任务名称 
    status: boolean = false; //修改任务状态 
    executor: boolean = false; //更改任务执行者  
    startDate: boolean = false; //设置任务开始时间
    planWorkingHour: boolean=false;//设置计划工时
    endDate: boolean = false; //设置任务截止时间 
    rely: boolean = false; //添加/删除任务依赖
    transform: boolean = false; //子任务转为顶级任务
    move: boolean = false; //移动任务
    delete: boolean = false; //移到回收站/恢复任务
    put: boolean = false; //修改任务
    post: boolean = false; //添加任务
    clear: boolean = false; //删除任务
    linkpost: boolean = false; //添加关联
    linkdel: boolean = false; //删除关联
    taskvisible: boolean = false; //设置可见性
    partypost: boolean = false; //添加参与者
    partydel: boolean = false; //移除参与者

    updateSchedule: boolean=false;//设置工作进度
    setting: boolean=false; //任务设置 //修改项目基础信息权限
    tagPut:boolean=false;//标签权限

    deleteComplete:boolean=false;//彻底删除
    revert:boolean=false;//还原任务

    taskPermissionList:Array<Permission>=[];//权限代码列表，为了方便后面取用

    constructor(pers?: any, taskRoles?: Array<string>,isDelete?: boolean,isIndependentTask?: boolean) {
        if (!pers) {
            return;
        }
        this.taskPermissionList=pers;
        let canEdit: boolean=isDelete?!isDelete:true;
        
        if(canEdit&&!isIndependentTask&&Setting.isSVW){
            canEdit=false;
        }

        let gettaskAll=this.exsitTaskPer(pers, 'enterpriseproject.gettask.all', '', taskRoles);
        let gettaskDeptall=this.exsitTaskPer(pers, 'enterpriseproject.gettask.deptall', '', taskRoles);

        console.log("gettaskAll: "+gettaskAll);
        console.log("gettaskDeptall: "+gettaskDeptall);
        let enterprise=false;
        if(gettaskAll||gettaskDeptall){
            enterprise=true
        }
        console.log("enterprise: "+enterprise);
        
        
        this.name =enterprise||(canEdit&&this.exsitTaskPer(pers, '', 'taskpermission.put.name', taskRoles));   //修改任务名称 
        this.status = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.status', 'taskpermission.put.status', taskRoles));   //修改任务状态 
        this.executor = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.executor', 'taskpermission.put.executor', taskRoles));  //更改任务执行者  
        this.startDate = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.startDate', 'taskpermission.put.startDate', taskRoles)); //设置任务开始时间
        this.endDate = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.endDate', 'taskpermission.put.startDate', taskRoles));   //设置任务截止时间 
        this.rely = enterprise||(canEdit&&this.exsitTaskPer(pers, 'dependency.manage', '', taskRoles));     //添加/删除任务依赖
        this.transform = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.transform', 'taskpermission.put.transform', taskRoles));  //子任务转为顶级任务
        this.move = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.move', 'taskpermission.put.move', taskRoles));       //移动任务
        this.delete =  enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put.archive', 'taskpermission.put.archive', taskRoles));     //移到回收站/恢复任务
        this.put = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.put', '', taskRoles));         //修改任务
        if(isIndependentTask && taskRoles){
            this.post = enterprise||(canEdit&&(taskRoles.findIndex(o=>o=='task.creator')>-1 || taskRoles.findIndex(o=>o=='task.executor')>-1));         //添加任务
        }else{
            this.post = enterprise||(canEdit&&this.exsitTaskPer(pers, 'task.post', '', taskRoles));         //添加任务
        }
        if(isDelete==undefined)
        isDelete=false;
         console.log("name: "+this.name);
         
        this.clear =enterprise||(isDelete&&this.exsitTaskPer(pers, 'task.del', 'taskpermission.del', taskRoles));         //删除任务
        this.linkpost = enterprise||(canEdit&&this.exsitTaskPer(pers, 'objectlink.post', '', taskRoles));         //添加关联
        this.linkdel = enterprise||(canEdit&&this.exsitTaskPer(pers, 'objectlink.del', '', taskRoles));        //删除关联
        this.taskvisible = enterprise||(canEdit&&this.exsitTaskPer(pers, 'object.put.visible', 'taskpermission.put.visible', taskRoles));         //设置可见性
        this.partypost = enterprise||(canEdit&&this.exsitTaskPer(pers, 'object.put.involvers', '', taskRoles));         //添加参与者
        this.partydel = enterprise||(canEdit&&this.exsitTaskPer(pers, 'object.del.involvers', '', taskRoles));         //移除参与者
        // 修改 2021年1月15日14:17:10  修改计划工时的权限与 修改开始/截止时间一致
        // this.planWorkingHour=this.status;
        this.planWorkingHour= this.startDate || this.endDate;
        this.updateSchedule= this.startDate || this.endDate;
        this.tagPut=enterprise||(canEdit&&this.exsitTaskPer(pers, 'object.put.tag', '', taskRoles)); //编辑标签
        this.deleteComplete=!enterprise||(!canEdit&&this.exsitTaskPer(pers, 'task.put.archive', 'taskpermission.put.archive', taskRoles));
        this.revert=this.deleteComplete;
        if(taskRoles){
            this.setting= enterprise||(canEdit&&taskRoles.findIndex(o=>o=='task.creator' || o=='task.executor')>-1); //编辑项目权限
        }
        
    }

    public getPermissionOne(permissionList: Array<Permission>,code:string, taskRoles?: Array<string>){
        if(code=="status"){
            return this.exsitTaskPer(permissionList, 'task.put.status', 'taskpermission.put.status', taskRoles);   //修改任务状态 
        }else if(code=="delete"){
            return this.exsitTaskPer(permissionList, 'task.put.archive', 'taskpermission.put.archive', taskRoles);   //修改任务状态 
        }
        return false;
    }

    private exsitTaskPer(permissionList: Array<Permission>, projectPermissionName: string, taskPermissionName: string, taskRoles?: Array<string>): boolean {
        let roleIdList: Array<string> = taskRoles?taskRoles:[];   //当前用户、当前任务拥有的角色列表
        for (let permission of permissionList) {
            if (permission.permissionType == EPermissionType.Project && permission.id == projectPermissionName) {
                return true;
            }
            if (permission.permissionType == EPermissionType.Task && permission.id == taskPermissionName) {
                if (roleIdList.length == 0) {
                    continue;
                }
                const index = roleIdList.findIndex((roleId) => { return permission.taskRoleId == roleId; });
                if (index >= 0) { return true; }
            }            
            if (permission.permissionType == EPermissionType.Enterprise && permission.id == projectPermissionName) {
                return true;
            }
        }
        return false;
    }
}