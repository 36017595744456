/**
 * projectUserstars 项目收藏 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目收藏/取消收藏
 */
export class ProjectRolesApi extends TaskBaseService {

    constructor() {
        super("projectRoles");
    } 
    //获取项目角色
    public getByProjectId(): Promise<any> {
        const path = "getByProjectId";
        return this.request(path, this.rest.METHOD_GET, {projectId:"xxx"});
    }

}