
import { alertController, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";

import { CalendarService } from "@/services/calendar/CalendarService.ts";

export default defineComponent({
  name: "AddCalendar",
  components: {
    HeaderModal,
  },
  props: {
    id: String,
    isRecycle: Boolean,
  },
  data() {
    const router = useRouter();
    const calendarInfo: any = {
      id: "",
      name: "",
      color: "", //最下面一排颜色值
      visible: "",
      remark: "",
      relationProjectId: "",
      partnerIds: "",
      creator: "",
    };
    const visibleList = [
      {
        id: "0",
        text: "私有（只有创建人和参与人可见）",
      },
      {
        id: "1",
        text: "公开（其他用户可申请订阅）",
      },
    ];
    return {
      calendarService: new CalendarService(),
      router: router,
      useRoute: useRoute(),
      calendarInfo: calendarInfo,
      visibleList: visibleList,
      colorList: new Array(),
      infoParam: {
        id: "",
      },
      userList: new Array(),
      isRollback: true,
      btnIconRight: ["icongengduo"],
      btnTypeRight: "2",
      selectOperate: "-1",
    };
  },
  mounted() {
    this.initColor();
    this.calendarInfo.id = this.id;
    //加载页面数据
    this.loadData();
  },
  methods: {
    initColor() {
      this.colorList = [
        { value: "c24341", index: 1 },
        { value: "fa8585", index: 2 },
        { value: "fcb777", index: 3 },
        { value: "94dba0", index: 4 },
        { value: "6bc9ab", index: 5 },
        { value: "85b8fa", index: 6 },
        { value: "6c88d7", index: 7 },
        { value: "796ad9", index: 8 },
      ];
    },
    loadData() {
      let params = { id: this.calendarInfo.id };
      this.calendarService.getCalDetail(params).then((res: any) => {
        if (res) {
          this.calendarInfo.visible = res.data.visible;
          this.calendarInfo.remark = res.data.remark;
          this.calendarInfo.color = res.data.color;
          this.calendarInfo.name = res.data.name;
          this.calendarInfo.creator = res.data.creator;
          //解析参与人
          let parterList = res.data.calCalendarUserList;
          this.userList = new Array();
          for (let i = 0; i < parterList.length; i++) {
            this.userList.push({
              id: parterList[i].userId,
              name: parterList[i].userName,
            });
          }
          this.builderPartnerIds();
        }
      });
    },
    //拼接参与人id,以,分割
    builderPartnerIds() {
      let ids = "";
      for (let i = 0; i < this.userList.length; i++) {
        ids += ids == "" ? this.userList[i].id : "," + this.userList[i].id;
      }
      this.calendarInfo.partnerIds = ids;
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    closeModel(type: any) {
      if (type == 4) {
        //编辑 删除
        let selectOperate: any = document.getElementById(
          "select-operate-calendar-recyle"
        );
        let el: HTMLElement = selectOperate;
        el.click();
      } else {
        modalController.dismiss({});
      }
    },
    bindOperate(e: any) {
      this.selectOperate = e.detail.value;
      if (this.selectOperate == "recycle") {
        //选中select后需要重置，否则再点select不会触发change
        this.selectOperate = "-1";
        this.Recycle();
      } else if (this.selectOperate == "delete") {
        //选中select后需要重置，否则再点select不会触发change
        this.selectOperate = "-1";
        this.Delete();
      } else {
        this.selectOperate = "-1";
      }
    },
    //还原日历
    async Recycle() {
      const alert = await alertController.create({
        header: "还原日历",
        message: "您确定要还原此日历",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.calendarService.recycleCal({ id: this.id }).then(
                (r: any) => {
                  if (r) {
                    Utils.presentToastSuccess("日历还原成功");
                    modalController.dismiss({ isDelete: true });
                  }
                },
                (e: any) => {
                  Utils.presentToastWarning("日历还原失败");
                }
              );
            },
          },
        ],
      });
      await alert.present();
    },
    //删除日历
    async Delete() {
      const alert = await alertController.create({
        header: "删除日历",
        message: "您确定要删除此日历",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.calendarService.clearById({ id: this.id }).then(
                (r: any) => {
                  if (r) {
                    Utils.presentToastSuccess("日历删除成功");
                    modalController.dismiss({ isDelete: true });
                  }
                },
                (e: any) => {
                  Utils.presentToastWarning("日历删除失败");
                }
              );
            },
          },
        ],
      });
      await alert.present();
    },
  },
});
