import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';
// 创建任务
export function createTask(data){
    return request('/taskManagement/createTask.do',"POST",{userId:SystemUtils.loginUser.id,nameCN:SystemUtils.loginUser.name})
}

// 选择代办人
export function checkExecutor(data){
    return request('/taskManagement/checkExecutor.do',"POST",data)
}

// 发送任务
export function sendTask(data){
    return request('/taskManagement/sendTask.do',"POST",data)
}

// 查看任务
export function getTask(data){
    return request('/taskManagement/getTask.do',"POST",data)
}

