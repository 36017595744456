import { privacyPolicyApi } from '@/api/app/privacyPolicyApi';

export class privacyPolicyService {
    private api = new privacyPolicyApi();

     //同意个人隐私申明
    public signAgree(params:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.signAgree(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}