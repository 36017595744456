
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "CardProject",
  props: {
    ProjectObj: Object,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
    };
  },
  methods: {},
});
