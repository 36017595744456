
import { defineComponent } from "vue";
import { alertController, modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import DocService from "@/services/doc/docService";
import Utils from "@/utils/Utils";
import FolderTree from "./FolderTree.vue";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "FileList",
  components: {
    modalController,
  },
  props: {
    projectId: String,
    folderId: String,
    isHasPermission:  {
      type: Object,
      required: false,
      default: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
      },
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    return {
      fileList: new Array(0),
      docService: new DocService(),
      router: router,
      useRoute: useRoute(),
      searchParams: _param,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.resetSearchParam();
      this.resetPage();
    },
    resetSearchParam() {
      this.searchParams = {
        limit: 20,
        offset: 1,
        rootId: this.folderId,
      };
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        if (res == null || res.length <= 0) {
          this.fileList = [];
        } else {
          this.fileList = res;
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.fileList = this.fileList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
           // event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.docService
        .getPagedRootDeletedDocuments(this.searchParams)
        .then((res: any) => {
          callback(res.data);
        });
    },
    //删除
    async clearById(id: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要把该文件删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              this.docService.clearFileById(id).then((res: any) => {
                // Utils.presentToastCenter("归档失败", "warning");
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    //选择要还原的文件夹
    async projectRecycle(id: string) {
      const modal = await modalController.create({
        component: FolderTree,
        backdropDismiss: true,
        cssClass: "notice-modal-class",
        componentProps: {
          projectId: this.projectId
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.folderId) {
        //documentId
        this.restoreById(id, data.folderId);
      }
    },
    //还原
    restoreById(documentId: string, targetFolderId: string) {
      let params = {
        documentId: documentId,
        targetFolderId: targetFolderId,
      };
      this.docService.restoreDocumnetById(params).then((res: any) => {
        Utils.presentToast("还原成功");
        this.resetPage();
      });
    },
    formatTime(modifyTime: any) {
      return SystemUtils.fromatTime(modifyTime);
    },
  },
});
