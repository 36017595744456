
import { defineComponent, reactive, onBeforeMount, toRefs, watch } from "vue";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { useRouter } from "vue-router";
import { ProjectTeamService } from "@/services/project/ProjectTeamService";
import Utils from "@/utils/Utils";
import CardLoading from "@/components/ListCard/CardLoading.vue";
export default defineComponent({
  name: "TeamPage",
  components: {
    Header,
    SearchBar,
    CardLoading,
  },
  props: {
    isHome: String,
    searchKey: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const { searchKey } = toRefs(props);
    const projectTeamService = new ProjectTeamService();
    let isMore = true;
    let queryParams = {
      limit: 10,
      offset: 1,
      teamName: "",
      searchKey: searchKey.value,
    };
    let dataList = reactive({ list: new Array(0) });
     let islist= true; //是否显示加载中的标识
    const router = useRouter();

    //跳转添加页面
    const bindRouterAdd = () => {
      router.push({
        name: "teamAdd",
      });
    };
    //跳转项目详情
    const itemClick = (projectInfo: any) => {
      router.push({
        path: "/project/member-manage",
        query: {
          projectId: projectInfo.projectId,
          title: projectInfo.name,
        },
      });
    };

    const searchHandler = (s: any) => {
      if (Utils.isInCludeEmoji(s.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      queryParams.teamName = s.searchKey;
      queryParams.offset = 1;
      getAllTeams();
    };

    const getAllTeams = () => {
      projectTeamService.getAllTeamsByUserId(queryParams).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            dataList.list = result;
            islist = false;
            scrollToTop();
          }
        },
        (e: any) => { islist = false;}
      );
    };

    onBeforeMount(() => {
      if (searchKey != undefined && searchKey.value != undefined) {
        queryParams.searchKey = searchKey.value;
      }
      getAllTeams();
    });
    //下拉刷新
    const doRefresh = (event: any) => {
      if (event) {
        event.target.disabled = true;
      }
      queryParams.offset = 1;
      getAllTeams();
      setTimeout(() => {
        event.target.disabled = false;
        event.target.complete();
      }, 1000);
    };
    const nextPage = (event: any) => {
      event.target.disabled = true;
      queryParams.offset++;
      projectTeamService.getAllTeamsByUserId(queryParams).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              Utils.presentToast("已无更多数据");
            } else {
              if (event) {
                dataList.list = dataList.list.concat(result);
              }
            }
          }
        },
        (e: any) => {}
      );
      setTimeout(() => {
        event.target.disabled = false;
        event.target.complete();
      }, 1000);
    };

    watch(searchKey, (newVal: any) => {
      if (newVal == undefined) {
        newVal = "";
      }
      queryParams.searchKey = newVal;
      getAllTeams();
    });

    //滚动到顶部
    const scrollToTop = () => {
      let ionContent: any = document.querySelector("#ionContent");
      if (ionContent) {
        ionContent.scrollToTop();
      }
    };
    return {
      islist,
      dataList,
      bindRouterAdd,
      searchHandler,
      itemClick,
      nextPage,
      doRefresh,
    };
  },
});
