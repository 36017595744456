
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SelectDate from "../component/SelectDate.vue";

export default defineComponent({
  name: "AddRepeat",
  components: {
    HeaderModal,
  },
  props: {
    paramItem: Object,
  },
  data() {
    const router = useRouter();

    const weekday = [
      "",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
      "星期日",
    ];

    const info = {
      pinlv: "day",
      every: {
        week: [],
        month: [],
      },
      everyStr: "",
    };
    const options: any = {
      cssClass: "select-week-interface",
    };
    return {
      router: router,
      isRollback: true,
      useRoute: useRoute(),
      info: info,
      isNewTask: true, //是否是新建任务
      btnIconRight: ["iconshaixuan"],
      btnSizeRight: ["34px"],
      infoParam: {
        id: "",
      },
      weekday: weekday,
      selectDay: -1,
      selectWeek: -1,
      selectWeekDay: [],
      selectWeekDayStr: "",
      selectMonth: -1,
      selectMonthDate: [],
      selectMonthDateStr: "",
      endTime: "",
      options: options,
    };
  },
  watch: {
    selectDay() {
      this.selectChange();
    },
    selectWeek() {
      this.selectChange();
    },
    selectWeekDay() {
      this.selectChange();
    },
    selectMonth() {
      this.selectChange();
    },
    selectMonthDate() {
      this.selectChange();
    },
  },
  created() {
    //加载页面数据
    this.loadData();
    this.initEndTime();
    if (this.paramItem && this.paramItem.id == "diy") {
      this.info.pinlv = this.paramItem.repeat;
      this.endTime=moment(this.paramItem.endTime).format("yyyy-MM-DD");
      switch (this.paramItem.repeat) {
        case "day":
          this.selectDay = this.paramItem.span;
          this.selectWeek = -1;
          this.selectWeekDay = [];
          this.selectWeekDayStr = "";
          this.selectMonth = -1;
          this.selectMonthDate = [];
          this.selectMonthDateStr = "";
          break;
        case "week":
          this.selectDay = -1;
          this.selectWeek = this.paramItem.span;
          this.selectWeekDay = this.paramItem.dateList;
          this.selectWeekDayStr = this.paramItem.text;;
          this.selectMonth = -1;
          this.selectMonthDate = [];
          this.selectMonthDateStr = "";
          break;
        case "month":
          this.selectDay = -1;
          this.selectWeek = -1;
          this.selectWeekDay = [];
          this.selectWeekDayStr = "";
          this.selectMonth = this.paramItem.span;
          this.selectMonthDate = this.paramItem.dateList;
          this.selectMonthDateStr = this.paramItem.text;
          break;
      }
    }
  },
  methods: {
    //优先级
    bindPriority(e: any) {
      this.info.pinlv = e.detail.value;
      this.selectDetailSetting();
    },
    bindSelectDay(e: any) {
      this.selectDay = e.detail.value;
    },
    bindSelectWeek(e: any) {
      this.selectWeek = e.detail.value;
      this.selectWeekDayHandler();
    },
    bindSelectWeekday(e: any) {
      this.selectWeekDay = e.detail.value;
    },
    bindSelectMonth(e: any) {
      this.selectMonth = e.detail.value;
      this.selectMonthDateHandler();
    },
    initEndTime() {
      //默认重复截止时间：月
      let month = 3;
      switch (this.info.pinlv) {
        case "day":
          month = 3;
          break;
        case "week":
          month = 3;
          break;
        case "month":
          month = 6;
          break;
      }
      let date = new Date();
      date.setMonth(date.getMonth() + month);
      this.endTime = moment(date).format("YYYY-MM-DD");
    },
    //设置周几
    selectWeekDayHandler() {
      let selectDay: any = document.getElementById("select-weekday");
      let el: HTMLElement = selectDay;
      el.click();
    },
    //设置天周月
    selectDetailSetting() {
      let type = this.info.pinlv;
      if (type == "day") {
        let selectDay: any = document.getElementById("select-day");
        let el: HTMLElement = selectDay;
        el.click();
      } else if (type == "week") {
        let selectDay: any = document.getElementById("select-week");
        let el: HTMLElement = selectDay;
        el.click();
      } else if (type == "month") {
        let selectDay: any = document.getElementById("select-month");
        let el: HTMLElement = selectDay;
        el.click();
      }
    },
    //设置详细的周月
    selectDetailsSetting() {
      if (this.info.pinlv == "week") {
        this.selectWeekDayHandler();
      } else if (this.info.pinlv == "month") {
        this.selectMonthDateHandler();
      }
    },
    selectChange() {
      if (this.info.pinlv == "day") {
        if (this.selectDay != -1) {
          this.info.everyStr = "事件将每" + this.selectDay + "天重复一次";
        }
      } else if (this.info.pinlv == "week") {
        if (this.selectWeek != -1 && this.selectWeekDay.length > 0) {
          let str = "";
          for (let i = 0; i < this.selectWeekDay.length; i++) {
            str +=
              str == ""
                ? this.weekday[this.selectWeekDay[i]]
                : "," + this.weekday[this.selectWeekDay[i]];
          }
          this.selectWeekDayStr = str;
          this.info.everyStr =
            "事件将每" + this.selectWeek + "周于" + str + "重复一次";
        }
      } else if (this.info.pinlv == "month") {
        if (this.selectMonth != -1) {
          let str = "";
          for (let index = 0; index < this.selectMonthDate.length; index++) {
            str +=
              str == ""
                ? this.selectMonthDate[index] + "日"
                : "," + this.selectMonthDate[index] + "日";
          }
          this.selectMonthDateStr = str;
          this.info.everyStr =
            "事件将每" + this.selectMonth + "个月于" + str + "重复一次";
        }
      }
    },
    loadData() {
      this.selectDay = -1;
      this.selectWeek = -1;
      this.selectWeekDay = [];
      this.selectWeekDayStr = "";
      this.selectMonth = -1;
      this.selectMonthDate = [];
      this.selectMonthDateStr = "";
    },
    goBack() {
      this.router.go(-1);
    },
    //设置月的天
    async selectMonthDateHandler() {
      const modal = await modalController.create({
        component: SelectDate,
        cssClass: "notice-modal-class",
        componentProps: {
          selectList: this.selectMonthDate
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        if (data.data.length > 0) {
          this.selectMonthDate = data.data;
        }
      }
    },
    closeModel(type: number) {
      if (type == 4) {
        let item = { type: "", span: 0, list: [], detail: "" };
        item.type = this.info.pinlv;
        item.detail = this.info.everyStr;
        item.span = this.selectDay;
        if (this.info.pinlv == "week") {
          item.span = this.selectWeek;
          if (this.selectWeekDay.length == 0) {
            modalController.dismiss({});
          } else {
            item.list = this.selectWeekDay;
          }
        } else if (this.info.pinlv == "month") {
          item.span = this.selectMonth;
          if (this.selectMonthDate.length == 0) {
            modalController.dismiss({});
          } else {
            item.list = this.selectMonthDate;
          }
        }
        if (item.span == -1) {
          //  Utils.presentToast("请选择重复间隔时间");
          modalController.dismiss({});
        } else {
          if (this.endTime == "") {
            //  Utils.presentToast("请选择结束日期");
            modalController.dismiss({});
          } else {
            //关闭页面
            modalController.dismiss({
              data: item,
              endTime: this.endTime,
            });
          }
        }
      }
      modalController.dismiss({});
    },
  },
});
