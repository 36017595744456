
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  IonTextarea,
  IonIcon,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";

import Utils from "@/utils/Utils";
import Settings from "@/rest/Setting";
import { useRouter } from "vue-router";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectTeamStaff from "@/components/selectexecutor/SelectTeamStaff.vue";
import SystemUtils from "@/utils/SystemUtils";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import BaseModel from "@/services/base/model/BaseModel";
import { OkrUserInfo } from "./OkrUserInfo";
import UserInfo from "@/services/selectexecutor/UserInfo";
import selectCycle from "./selectCycle.vue";
import { log } from "console";
import SelectOrganization from "@/views/OKR/modals/SelectOrganization.vue";
import SelectGroupUser from "@/views/OKR/modals/SelectGroupUser.vue";
import moment from "moment";

export default defineComponent({
  name: "addOKR",
  components: {
    Header,
    IonContent,
    IonText,
    IonInput,
    IonTextarea,
    IonIcon,
    selectCycle,
    SelectOrganization,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    params: Object,
  },
  data() {
    const btnIconRight = ["iconduihao"];
    const btnSizeRight = ["50px"];
    const router = useRouter();
    const paramsInfo = this.$route.params;

    let info = null;
    if (paramsInfo.object != undefined && paramsInfo.object != null) {
      info = JSON.parse(paramsInfo.object.toString());
    }

    const _currentUser = SystemUtils.loginUser;
    const taskInfo: any = {
      executorUsers: [],
    };
    return {
      takeUsers: Array(0),
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      currentUser: {
        id: _currentUser.id,
        name: _currentUser.name,
        pernr: _currentUser.pernr,
        photo: _currentUser.photo,
        userId: _currentUser.id,
        department: _currentUser.department,
        departmentId: _currentUser.departmentId,
        // color: SystemUtils.loginUser.userColor,
        isChecked: true,
      },
      organization: { id: "", name: "点击选择", description: "" },
      staged: { id: "" },
      router: router,
      fromData: {
        id: "", //目标id
        name: "", //目标name
        levelId: "", //目标级别id
        levelName: "", //目标名称
        cycleId: "", //周期id
        cycleName: "", //周期名称
        visibleRange: "1", //可见范围 1.全员可见 2.目标成员可见 3.本级成员可见
        users: new Array(0), //负责人或分享人 根据不同的type区分负责人还是分享人
        sourceKrId: "",
        categoryId: "",
        categoryName: "",
        cycleList: new Array(0),
        belongto: "", //所属机构id
        belongtoName: "",
        belongtoType: "",
        endTime: "",
        status: "",
      },
      isSaveFinish: true, //是否保存成功,防止连续点击
      task: taskInfo,

      levelId: "",
      cycleId: "",
      visibleRange: "2",
      categoryId: "",

      objectiveService: new ObjectiveService(),
      //等级列表
      levelList: new Array(0),
      //可用周期列表
      cycleList: new Array(0),
      //可用维度列表
      cateGoryList: new Array(0),

      baseModel: new BaseModel(),

      //关键结果转目标
      paramsInfo: paramsInfo,
      paramsObj: info,

      //已选中周期
      idList: new Array(0),
      //是否展示蒙层
      requesting: false,
    };
  },
  created() {
    this.fromData.belongto = SystemUtils.loginUser.orgId as string;
    this.fromData.belongtoName = SystemUtils.loginUser.orgName as string;
    this.fromData.belongtoType = "org";
    this.task.executorUsers = [
      {
        id: SystemUtils.loginUser.id,
        name: SystemUtils.loginUser.name,
        pernr: SystemUtils.loginUser.pernr,
        photo: SystemUtils.loginUser.photo,
        userId: SystemUtils.loginUser.id,
        department: SystemUtils.loginUser.orgName,
        isChecked: true,
      },
    ];
    //如果为关键结果转目标
    if (this.paramsInfo.sourceKrId) {
      this.fromData.sourceKrId = this.paramsInfo.sourceKrId as string;
    }
    if (
      this.paramsInfo.sourceKrName != undefined &&
      this.paramsInfo.sourceKrName != null
    ) {
      this.fromData.name = this.paramsInfo.sourceKrName as string;
    }
    if (
      this.paramsObj == null &&
      this.paramsInfo.oid != undefined &&
      this.paramsInfo.oid != null
    ) {
      this.getDetails(this.paramsInfo.oid as string);
    } else {
      //获取级别列表
      this.getLevelList();
      //获取可用周期列表
      this.getCycleList();
      //获取可用维度列表
      this.getCateGoryList();
      this.initFrom();
    }
  },
  methods: {
    // 构建表单
    initFrom() {
      if (this.isEdit) {
        if (this.params) {
          this.fromData.id = this.params.id;
          this.fromData.name = this.params.name;
          this.fromData.belongto = this.params.belongto;
          this.fromData.belongtoName = this.params.belongtoName;
          this.fromData.belongtoType = this.params.belongtoType;
          this.fromData.status = this.params.status;
          this.visibleRange = this.params.visibleRange.toString();

          if (this.params.users) {
            this.task.executorUsers.splice(0, this.task.executorUsers.length);
            for (let index = 0; index < this.params.users.length; index++) {
              const element = this.params.users[index];
              const user = new UserInfo();
              user.id = element.id;
              user.name = element.userName;
              user.pernr = "";
              user.photo = "";
              user.userId = element.userId;
              user.department = element.partNameEn;
              user.departmentId = element.partId;
              if (element.type == 1) {
                this.task.executorUsers.push(user);
              } else if (element.type == 2) {
                this.takeUsers.push(user);
              }
            }
          }
        }
      } else {
        if (this.paramsObj) {
          this.task.executorUsers.splice(0, this.task.executorUsers.length);
          this.fromData.belongto = this.paramsObj.belongto;
          this.fromData.belongtoName = this.paramsObj.belongtoName;
          this.fromData.belongtoType = this.paramsObj.belongtoType;
          this.visibleRange = this.paramsObj.visibleRange.toString();
          const user = new UserInfo();
          user.id = this.currentUser.id;
          user.name = this.currentUser.name;
          user.pernr = "";
          user.photo = "";
          user.userId = this.currentUser.id;
          user.department = this.currentUser.department;
          user.departmentId = this.currentUser.departmentId;
          this.task.executorUsers.push(user);
        }
      }
    },
    //打开组织
    async openOrganizationSelece() {
      let objParam: any = [];
      // this.searchParams.orgIds.forEach((ele: any) => {
      //   objParam.push({ treeId: ele });
      // });
      let getQueryObj = {
        checkedId: this.fromData.belongto,
        checkedName: this.fromData.belongtoName,
        checkedType: this.fromData.belongtoType,
      };

      const modal = await modalController.create({
        component: SelectOrganization,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          type: "other",
          queryObj: getQueryObj,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.checkedObj) {
        if (this.fromData.belongto != data.checkedObj.checkedId) {
          //清除负责人信息
          this.task.executorUsers = [];
        }
        this.fromData.belongto = data.checkedObj.checkedId;
        this.fromData.belongtoName = data.checkedObj.checkedName;
        this.fromData.belongtoType = data.checkedObj.checkedType;
      }
    },

    // 获取目标信息
    getDetails(oid: string) {
      this.objectiveService.objectiveDetails({ id: oid }).then(
        (r: any) => {
          if (r) {
            if (r) {
              this.paramsObj = r;
              //获取级别列表
              this.getLevelList();
              //获取可用周期列表
              this.getCycleList();
              //获取可用维度列表
              this.getCateGoryList();
              this.initFrom();
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    //获取级别列表
    getLevelList() {
      const params = { parentId: "" };
      if (this.paramsInfo.sourceKrId) {
        if (this.params) {
          params.parentId = this.params.levelId;
        }
      }
      this.objectiveService.levelList(params).then(
        (r: any) => {
          if (r) {
            this.levelList = r;
            if (this.isEdit) {
              if (this.params) {
                this.levelId = this.params.levelId;
              }
            } else {
              if (this.paramsObj) {
                this.levelId = this.paramsObj.levelId;
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //获取可用周期列表
    getCycleList() {
      this.objectiveService.cycleList().then(
        (r: any) => {
          if (r) {
            this.cycleList = r;
            if (this.isEdit) {
              if (this.params) {
                this.idList = this.params.cycleList;
                console.log("aaaaa: " + JSON.stringify(this.idList));
              }
            } else {
              if (this.paramsObj) {
                this.idList = this.paramsObj.cycleList;
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //获取可用维度列表
    getCateGoryList() {
      this.objectiveService.categoryList().then(
        (r: any) => {
          if (r) {
            this.cateGoryList = r;
            if (this.isEdit) {
              if (this.params) {
                this.categoryId = this.params.categoryId;
              }
            } else {
              if (this.paramsObj) {
                this.categoryId = this.paramsObj.categoryId;
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //绑定级别id
    bindObjLevel(e: any) {
      this.levelId = e.detail.value;
    },
    //绑定维度id
    bindObjCategory(e: any) {
      this.categoryId = e.detail.value;
    },
    //选择周期
    async selectCycle() {
      console.log("传之前：" + JSON.stringify(this.idList));
      const modal = await modalController.create({
        component: selectCycle,
        cssClass: "calendar-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            querylist: this.cycleList,
            selectList: this.idList,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.idList) {
        this.idList = data.idList;
      }
    },
    //绑定可见范围id
    bindObjVisibility(e: any) {
      this.visibleRange = e.detail.value;
    },

    pickUserHandler() {
      if (!this.fromData.belongto || this.fromData.belongto == "") {
        Utils.presentToastWarning("请先选择组织");
        return;
      }
      //选择负责人
      if (this.fromData.belongtoType == "org") {
        this.selectChargeUserByOrgModel();
      } else {
        this.selectChargeUserByGroupModel();
      }
    },

    //从组中选择用户
    async selectChargeUserByGroupModel() {
      const modal = await modalController.create({
        component: SelectGroupUser,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          intentExecutorSelectList: this.task.executorUsers,
          groupId: this.fromData.belongto,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.isReturn) {
        this.task.executorUsers = data.executorSuperiorSelectList;
      }
    },

    //选择负责人
    async selectChargeUserByOrgModel() {
      const modal = await modalController.create({
        component: SelectTeamStaff,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          intentExecutorSelectList: this.task.executorUsers,
          tsOrgId: this.fromData.belongto,
          isCkeckbox: true, //多选
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.isReturn) {
        this.task.executorUsers = data.executorSuperiorSelectList;
        //modifyby hansl 负责人可以不包含本人了
        // let isExist = false;
        // for (let user of data.executorSuperiorSelectList) {
        //   if (user.userId == this.currentUser.userId) {
        //     isExist = true;
        //     break;
        //   }
        // }
        // if (!isExist) {
        //   this.task.executorUsers.unshift(this.currentUser);
        // }
      }
    },

    // 选择分享人
    async selectSharedPeople() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.takeUsers,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        this.takeUsers = data.data;
      }
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    getId() {
      const id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );

      return id;
    },
    onRightClick() {
      this.requesting = true;
      this.fromData.name = Utils.trim(this.fromData.name);
      if (!this.fromData.name || this.fromData.name.length <= 0) {
        Utils.presentToastWarning("请填写目标标题");
        this.requesting = false;
        return;
      }
      if (Utils.isInCludeEmoji(this.fromData.name)) {
        Utils.presentToastWarning("目标标题不可以包含表情");
        this.requesting = false;
        return;
      }
      if (this.fromData.belongto == "") {
        Utils.presentToastWarning("请选择组织机构");
        this.requesting = false;
        return;
      }
      if (!this.task.executorUsers || this.task.executorUsers.length == 0) {
        Utils.presentToastWarning("请选择负责人");
        this.requesting = false;
        return;
      }
      if (this.levelId == "") {
        Utils.presentToastWarning("请选择目标级别");
        this.requesting = false;
        return;
      }
      if (this.idList.length == 0) {
        Utils.presentToastWarning("请选择目标周期");
        this.requesting = false;
        return;
      }
      if (this.categoryId == "") {
        Utils.presentToastWarning("请选择维度");
        this.requesting = false;
        return;
      }
      this.fromData.levelId = this.levelId;
      this.fromData.cycleId = this.cycleId;
      this.fromData.categoryId = this.categoryId;
      this.fromData.visibleRange = this.visibleRange;
      //结束日期
      if (this.idList.length > 0) {
        let time = this.idList[0].cycleEndDate;
        let endTime = new Date(time)

        this.idList.forEach((ele: any) => {
          let thisDate = new Date(ele.cycleEndDate)
          if (endTime < thisDate) {
            endTime = thisDate;
          }
        });   
        this.fromData.endTime = moment(endTime).endOf('day').format("yyyy-MM-DD HH:mm:ss");
      }

      let userList = new Array<OkrUserInfo>();
      if (!this.isEdit) {
        this.fromData.id = this.getId();
      }

      for (let index = 0; index < this.levelList.length; index++) {
        const element = this.levelList[index];
        if (element.id == this.fromData.levelId) {
          this.fromData.levelName = element.name;
        }
      }
      for (let index = 0; index < this.cycleList.length; index++) {
        const element = this.cycleList[index];
        if (element.id == this.fromData.cycleId) {
          this.fromData.cycleName = element.name;
        }
      }
      for (let index = 0; index < this.cateGoryList.length; index++) {
        const element = this.cateGoryList[index];
        if (element.id == this.fromData.categoryId) {
          this.fromData.categoryName = element.name;
        }
      }

      for (let index = 0; index < this.task.executorUsers.length; index++) {
        const element = this.task.executorUsers[index];

        let user = new OkrUserInfo();
        user.id = this.getId();
        user.oid = this.fromData.id;
        user.userName = element.name;
        user.userId = element.userId;
        user.partNameEn = element.department;
        user.partId = element.departmentId;
        user.type = 1;
        userList.push(user);
      }

      for (let index = 0; index < this.takeUsers.length; index++) {
        const element = this.takeUsers[index];
        let user = new OkrUserInfo();
        user.id = this.getId();
        user.oid = this.fromData.id;
        user.userName = element.name;
        user.userId = element.userId;
        user.partNameEn = element.department;
        user.partId = element.departmentId;
        user.type = 2;
        userList.push(user);
      }

      this.fromData.users = userList;
      this.fromData.cycleList = this.idList;

      if (this.isEdit) {
        //编辑OKR
        this.updateOkr();
      } else {
        //新增OKR
        this.addOkr();
      }
    },

    //编辑OKR
    updateOkr() {
      this.objectiveService.editObjective(this.fromData).then(
        (r: any) => {
          if (r) {
            this.router.go(-1);
            this.requesting = false;
          }
          this.requesting = false;
        },
        (e: any) => {
          console.log(e);
          this.requesting = false;
        }
      );
    },
    //新增OKR
    addOkr() {
      this.objectiveService.addObjective(this.fromData).then(
        (r: any) => {
          if (r) {
            this.requesting = false;
            this.router.replace({
              path: "/OKR/details",
              query: {
                id: this.fromData.id,
              },
            });
          }
          this.requesting = false;
        },
        (e: any) => {
          console.log(e);
          this.requesting = false;
        }
      );
    },
  },
});
