import request from "@/utils/request"


// 登录
export function login(data){
    return request('/pda/login.do',"POST",data)
}
//获取权限菜单userId
export function pdaSiteMap(data){
    return request('/pda/pdaSiteMap.do',"POST",data)
}

//获取当前用户打印机
export async function getPrinterIP(data){
   
    return request('/pdaInfo/getPrinterIP.do',"POST",data)
}
//获取打印机列表
export function getPrinter(){
   
    return request('/pdaInfo/getPrinter.do',"POST",{})
}
// 绑定信息
export async function insertPrinter_pda(data){
   
    return request('/pdaInfo/insertPrinter_pda.do',"POST",data)
}
// 创建pda
export async function createPdaInfo(data){
    return request('/pdaInfo/createPdaInfo.do',"POST",data)
}
// 钉钉免登录
export function getUserId(data){
    console.log(data)
    return request('/pdaInfo/getUserId.do',"POST",data)
}