
import { defineComponent } from "vue";
import { IonMenu, menuController, modalController } from "@ionic/vue";
import { useRouter } from "vue-router";
import SearchBar from "@/components/SearchBar.vue";
import Message from "@/models/message/Message";
import SqlHelper from "@/utils/sql/SqlHelper";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "MessageSearch",
  components: {
    SearchBar,
    IonMenu,
  },
  data() {
    const sqlHelper = new SqlHelper();
    const router = useRouter();
    return {
      router: router,
      messageList1: Array<Message>(0),
      messageList2: Array<Message>(0),
      messageList3: Array<Message>(0),
      messageListMore: Array<Message>(0),
      mroeSubtitle: "",
      sqlHelper: sqlHelper,
      searchKey: "",
    };
  },
  methods: {
    closeSearch() {
      modalController.dismiss();
    },

    // goMessage() {
    //   modalController.dismiss({ message: "message" });
    // },

    searchMessage(event: any) {
      this.searchKey = event.searchKey;
      if (!this.searchKey) {
        this.messageList1 = Array<Message>(0);
        this.messageList2 = Array<Message>(0);
        this.messageList3 = Array<Message>(0);
        return;
      }
      this.sqlHelper
        .selectMessageListByTitle(this.searchKey, true)
        .then((res: any) => {
          this.messageList1 = res;
          this.analysisData(this.messageList1);
        });
      this.sqlHelper
        .selectMessageListByContent(this.searchKey, true)
        .then((res: any) => {
          this.messageList2 = res;
          this.analysisData(this.messageList2);
        });
      this.sqlHelper
        .selectMessageListByFile(this.searchKey, true)
        .then((res: any) => {
          this.messageList3 = res;
          this.analysisData(this.messageList3);
        });
    },

    // 查看更多搜索结果
    showMore(type: number) {
      switch (type) {
        case 1: // 群组
          this.mroeSubtitle = "群组";
          this.sqlHelper
            .selectMessageListByTitle(this.searchKey)
            .then((res: any) => {
              this.messageListMore = res;
              this.analysisData(this.messageListMore);
            });
          break;
        case 2: // 消息记录
          this.mroeSubtitle = "消息记录";
          this.sqlHelper
            .selectMessageListByContent(this.searchKey)
            .then((res: any) => {
              this.messageListMore = res;
              this.analysisData(this.messageListMore);
            });
          break;
        case 3: // 附件
          this.mroeSubtitle = "附件";
          this.sqlHelper
            .selectMessageListByFile(this.searchKey)
            .then((res: any) => {
              this.messageListMore = res;
              this.analysisData(this.messageListMore);
            });
          break;
      }
      menuController.enable(true, "custom");
      menuController.open("custom");
    },

    closeMenu() {
      menuController.close();
      this.messageListMore = new Array<Message>(0);
    },

    openMessage(message: Message, isLocation: boolean = true) {
      // 跳转消息详情页
      this.router.push({
        name: "ChatPage",
        params: {
          message: isLocation ? JSON.stringify(message) : "",
          chatGroupId: message.chatGroupId as string,
          busId: message.busId as string,
          busTable: message.busTable as string,
          busTag: JSON.stringify(message.busTag),
        },
      });
      this.closeSearch();
    },

    analysisData(messages: Array<Message>) {
      if (messages != null && messages.length > 0) {
        for (let message of messages) {
          let busTag = JSON.parse(message.busTag as string);
          message.busTag = busTag;
          if (message.file && message.file != "undefined") {
            let file = JSON.parse(message.file as string);
            message.file = file;
          }
          this.sqlHelper.selectHomeMessageList3(message);
        }
      }
    },
  },
});
