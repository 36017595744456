import { toastController, alertController,isPlatform} from '@ionic/vue';
import moment from "moment";
import emoji from "emoji-regex";
import { TextToSpeech } from "@ionic-native/text-to-speech";

export default class Utils {

    public static mqttConnected = false;
    static textTospeech(answer: any, isSpeech?: boolean) {
        if (isSpeech == undefined || isSpeech == true) {
            isSpeech = true;
        }
        if (answer && isSpeech) {
            TextToSpeech.speak({
                text: answer,
                locale: 'zh-CN',
                rate:isPlatform("ios")?1.62:1
            })
                .then(() => console.log("TextToSpeech---Success"))
                .catch((reason: any) => console.log("TextToSpeech--ERROR", reason));
        }

    }

    /**
     * 十六进制转换为RGB，带透明度
     * @param hex 16进制颜色，格式：#ffffff
     * @param opacity 透明度：最大是1
     */
    static hexToRgba(hex: string, opacity: number) {
        return "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt(
            "0x" + hex.slice(5, 7)) + "," + opacity + ")";
    }
    public static duration = 1000;
    public static get getUuid() {
        const s = new Array(0);
        const hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = "-";
        const uuid = s.join("");
        return uuid;
    }
    //底部消息提醒
    public static async presentToast(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            duration: Utils.duration,
            color: color ? color : "primary"
        });
        toast.present();
    }
    //顶部消息提醒
    public static async presentToastTop(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            position: "top",
            duration: Utils.duration,
            color: color ? color : "primary"
            // showCloseButton:true,
            // closeButtonText:"隐藏"
        });
        // toast.onclick = function () {
        //   toast.dismiss();
        // }
        toast.present();
    }
    //中间消息提醒
    public static async presentToastCenter(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            position: "middle",
            duration: Utils.duration,
            color: color ? color : "primary"
            // showCloseButton:true,
            // closeButtonText:"隐藏"
        });
        toast.present();
    }

    //底部消息提醒
    public static async presentToastWarning(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            duration: Utils.duration,
            color: color ? color : "warning"
        });
        toast.present();
    }
    //顶部消息提醒
    public static async presentToastTopWarning(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            position: "top",
            duration: Utils.duration,
            color: color ? color : "warning"
            // showCloseButton:true,
            // closeButtonText:"隐藏"
        });
        // toast.onclick = function () {
        //   toast.dismiss();
        // }
        toast.present();
    }
    //中间消息提醒
    public static async presentToastCenterWarning(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            position: "middle",
            duration: Utils.duration,
            color: color ? color : "warning"
            // showCloseButton:true,
            // closeButtonText:"隐藏"
        });
        toast.present();
    }


    //底部消息提醒
    public static async presentToastSuccess(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            duration: Utils.duration,
            color: color ? color : "success"
        });
        toast.present();
    }
    //顶部消息提醒
    public static async presentToastTopSuccess(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            position: "top",
            duration: Utils.duration,
            color: color ? color : "success"
            // showCloseButton:true,
            // closeButtonText:"隐藏"
        });
        // toast.onclick = function () {
        //   toast.dismiss();
        // }
        toast.present();
    }
    //中间消息提醒
    public static async presentToastCenterSuccess(msg: any, color?: any) {
        const toast = await toastController.create({
            message: msg,
            position: "middle",
            duration: Utils.duration,
            color: color ? color : "success"
            // showCloseButton:true,
            // closeButtonText:"隐藏"
        });
        toast.present();
    }
    //查找所有叶子节点
    public static getAllLeaf(data: any) {
        let result = new Array();
        function getLeaf(data: any) {
            data.forEach((item: any) => {
                if (!item.children) {
                    result.push(item)
                } else {
                    getLeaf(item.children)
                }
            })
        }
        getLeaf(data)
        return result;
    }

    public static async confirmDialog(header: string, message: string, okCallBack: any) {
        const alert = await alertController.create({
            header: header,
            message: message,
            mode: "ios",
            buttons: [{
                text: "取消",
                role: "cancel",
                cssClass: "secondary",
            },
            {
                text: "确定",
                handler: () => {
                    okCallBack();
                },
            },
            ],
        });
        await alert.present();
    }
    public static async confirmDialog1(header: string, message: string, okCallBack: any, cancelCallBack: any) {
        const alert = await alertController.create({
            header: header,
            message: message,
            mode: "ios",
            buttons: [{
                text: "取消",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                    cancelCallBack();
                },
            },
            {
                text: "确定",
                handler: () => {
                    okCallBack();
                },
            },
            ],
        });
        await alert.present();
    }
    public static async alertDialog(header: string, message: string, okCallBack: any) {
        const alert = await alertController.create({
            header: header,
            message: message,
            backdropDismiss: false,
            mode: "ios",
            buttons: [
                {
                    text: "确定",
                    handler: () => {
                        okCallBack();
                    },
                }
            ],
        });
        await alert.present();
    }

    //人员名字截取,包含英文取前两个并转化为大写，全是汉字取后2个
    public static subStringUserName(userName: string, len: number): string {
        let retName = "";
        if (userName != null && userName != undefined && userName.length > 0) {
            if (/^[a-zA-Z]/.test(userName)) {  //存在英文
                retName = userName.substring(0, len).toUpperCase();
            } else {  //全是中文
                retName = userName.substring(userName.length - len, userName.length);
            }
        }
        return retName;
    }
    //文本长度截取,英文取前4个并转化为大写，全是汉字取前4个
    public static subStringText(userName: string, len: number): string {
        let retName = "";
        if (userName != null && userName != undefined && userName.length > 0) {
            if (/^[a-zA-Z]/.test(userName)) {  //存在英文
                retName = userName.substring(0, len).toUpperCase() + "...";
            } else {  //全是中文
                retName = userName.substring(0, len) + "...";
            }
        }
        return retName;
    }

    public static formatDateTime(dateTime: string): string {
        let dt = dateTime.split(" ");
        if (dt[0] == moment(new Date()).format("YYYY-MM-DD")) {
            return dt[1].substr(0, 5);
        } else {
            return dt[0];
        }
    }

    /**
     * 去除字符串两侧空格
     * @param str 字符串
     */
    public static trim(str: string): string {
        const reg = /^\s+|\s+$/g;
        return str.replace(reg, "");
    }

    /**
    * 判断是否包含emoji表情包
    */
    public static isInCludeEmoji(str: any) {
        let match = emoji().exec(str);
        if (match) {
            return true;
        }
        return false;
    }

    /**
     * 根据菜单个数计算模态框高度，每排3个
     * @param menuCount 
     */
    public static resetModalHei(menuCount: number) {
        let modalMenu = document.querySelector("ion-modal") as HTMLIonModalElement;
        let i = parseInt(menuCount / 3 + "");
        let modalHei = ((menuCount % 3 == 0 ? i : i + 1) - 1) * 8.75;
        modalMenu.setAttribute(
            "style",
            "z-index: 20001;--height:calc(21% + " + modalHei + "rem) !important"
        );
    }
}
export const transformRequest = (data: any) => {
    let ret = '';
    for (const it in data) {
        //if(data[it]){   //20191115 modify for 值为空也可以传入参数
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
        //}
    }
    if (ret.length > 0) {
        ret = ret.substr(0, ret.length - 1);
    }
    return ret;
}

/**
 * 中划线转驼峰
 */
export const toCamel = (str: string) => {
    return str.replace(/([^-])(?:-+([^-]))/g, function ($0, $1, $2) {
        return $1 + $2.toUpperCase();
    });
}


/**
 * 得到uuID
 */
export const getUuid = () => {
    const s = new Array(0);
    const hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    const uuid = s.join("");
    return uuid;
}

/**
 * 驼峰转中划线
 */
function toMiddleLine(str: any) {
    let temp = str.replace(/[A-Z]/g, function (match: any) {
        return "-" + match.toLowerCase();
    });
    if (temp.slice(0, 1) === '-') {
        //如果首字母是大写，执行replace时会多一个-，这里需要去掉  		
        temp = temp.slice(1);
    }
    return temp;
}


