import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")
  const _component_MicroIssuesList = _resolveComponent("MicroIssuesList")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_Header, {
        type: "0",
        title: "微议题"
      }),
      _createVNode(_component_ion_content, { id: "microIssuesMain" }, {
        default: _withCtx(() => [
          _createVNode(_component_MicroIssuesList, {
            ref: "issuesList",
            type: 'none',
            allowAdd: true,
            onAddMicroIssues: _ctx.addMicroIssues,
            onOnSeach: _ctx.scrollToTop
          }, null, 8, ["onAddMicroIssues", "onOnSeach"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}