
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import Utils from "@/utils/Utils";
import Header from "@/components/Header.vue";
import { TeamUser } from "@/models/team/TeamUser";
import { ProjectMemberService } from "@/services/project/ProjectMemberService";
import BaseService from "@/services/base/BaseService";
import { json } from "gantt";
import { popoverController, alertController } from "@ionic/vue";
import PostPopover from "@/views/team/add/PostPopover.vue"; //项目角色选择
import RolePopover from "@/views/team/add/RolePopover.vue"; //团队角色选择

export default defineComponent({
  name: "UserRoleEdit",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      //mid:mid,
      useRoute: useRoute(),
      service: new ProjectMemberService(),
      hasEditMemberRole: false,
      hasDelMember: false,
      btnIconRight: ["iconxinjianbiaoqian"],
      info: new TeamUser(),
      showAddModel: false, //展示新建窗口
      addRoleModel: {
        projectRoleId: "",
        projectRoleName: "",
        projectTeamRoleId: "",
        projectTeamRoleName: "",
      },
      teamRoleList: [],
      projectRoleList: [],
      projectId: "",
    };
  },
  created() {
    if (this.useRoute.params && this.useRoute.params.receiveInfo) {
      const receiveObject = JSON.parse(
        this.useRoute.params.receiveInfo as string
      );
      this.info = new TeamUser(receiveObject);
      //teamRoleList 和 projectRoleList
      this.teamRoleList = JSON.parse(
        this.useRoute.params.teamRoleList as string
      );
      this.projectRoleList = JSON.parse(
        this.useRoute.params.projectRoleList as string
      );

      this.hasEditMemberRole =
        (this.useRoute.params.hasEditMemberRole as string) == "1";
      this.hasDelMember = (this.useRoute.params.hasDelMember as string) == "1";
      this.projectId = this.useRoute.params.projectId as string;
    }
  },
  methods: {
    addRole() {
      this.showAddModel = true;
      this.addRoleModel = {
        projectRoleId: "",
        projectRoleName: "",
        projectTeamRoleId: "",
        projectTeamRoleName: "",
      };
    },
    //更新成员
    updataMember(params: any) {
      const putData = {
        id: params.projectMemberId,
        projectId: this.projectId,
        projectRoleId: params.projectRoleId,
        projectRoleName: params.projectRoleName,
        projectTeamRoleId: params.projectTeamRoleId,
        projectTeamRoleName: params.projectTeamRoleName,
        teamRoleName: params.projectTeamRoleName,
      };
      this.service.updateModel(putData).then(
        (r: any) => {
          if (!r) {
            Utils.presentToastTopWarning("变更失败");
          }
        },
        (e: any) => {
          Utils.presentToastTopWarning("变更失败");
        }
      );
    },
    //设置 项目角色
    async openPostPopover(ev: Event, postId?: string, postObj?: any) {
      const list = JSON.parse(JSON.stringify(this.projectRoleList));
      const popover = await popoverController.create({
        component: PostPopover,
        cssClass: "my-popover-class",
        event: ev,
        mode: "md",
        translucent: false,
        componentProps: {
          postId: postId ? postId : "",
          list: list,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && "id" in data) {
        if (postObj) {
          postObj.projectRoleId = data.id;
          postObj.projectRoleName = data.name;
          this.updataMember(postObj);
        } else {
          this.addRoleModel.projectRoleId = data.id;
          this.addRoleModel.projectRoleName = data.name;
        }
      }
    },
    //设置团队角色
    async openRolePopover(ev: Event, roleId?: string, item?: any) {
      const list = JSON.parse(JSON.stringify(this.teamRoleList));
      const popover = await popoverController.create({
        component: RolePopover,
        cssClass: "my-popover-class",
        event: ev,
        mode: "md",
        translucent: false,
        componentProps: {
          roleId: roleId ? roleId : "",
          list: list,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && "id" in data) {
        if (item) {
          this.checkExistsMember(data.id, this.info.userId, () => {
            item.projectTeamRoleId = data.id;
            item.projectTeamRoleName = data.name;
            this.updataMember(item);
          });
        } else {
          this.addRoleModel.projectTeamRoleId = data.id;
          this.addRoleModel.projectTeamRoleName = data.name;
        }
      }
    },
    addNewRoleEvent() {
      this.checkExistsMember(
        this.addRoleModel.projectTeamRoleId,
        this.info.userId,
        () => {
          this.saveRoleHandlere();
        }
      );
    },
    saveRoleHandlere() {
      const saveData = {
        account: this.info.pernr,
        dataState: 0,
        enterpriseId: "1",
        systemId: "task",
        id: BaseService.newId("task", "projectmember"),
        projectId: this.projectId,
        partNameCn: this.info.partNameCn,
        partNameEn: this.info.partNameEn,
        userId: this.info.userId,
        userName: this.info.userName,
        projectRoleId: this.addRoleModel.projectRoleId,
        projectRoleName: this.addRoleModel.projectRoleName,
        projectTeamRoleId: this.addRoleModel.projectTeamRoleId,
        projectTeamRoleName: this.addRoleModel.projectTeamRoleName,
      };
      this.service.savaList([saveData]).then(
        (r: any) => {
          if (r) {
            //添加
            this.info.roleList.push({
              projectRoleId: saveData.projectRoleId,
              projectRoleName: saveData.projectRoleName,
              projectTeamRoleId: saveData.projectTeamRoleId,
              projectTeamRoleName: saveData.projectTeamRoleName,
              projectMemberId: saveData.id,
            });
            this.showAddModel = false;
          }
        },
        (e: any) => {
          Utils.presentToastWarning("添加成员角色失败");
        }
      );
    },
    async delRoleHandler(item: any, index: number) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.service
                .deleteMembers(
                  [
                    {
                      id: item.projectMemberId,
                      dataState: 4,
                    },
                  ],
                  this.projectId
                )
                .then(
                  (r: any) => {
                    if (r) {
                      if (this.info.roleList.length == 1) {
                        //返回上一页
                        this.router.go(-1);
                      }
                      //删除成员
                      this.info.roleList.splice(index, 1);
                    }
                  },
                  (e: any) => {
                    Utils.presentToastWarning("删除失败");
                  }
                );
            },
          },
        ],
      });
      await alert.present();
    },
    checkExistsMember(teamRoleId: string, userId: string, callback: any) {
      this.service
        .existsMember({ teamRoleId: teamRoleId, userId: userId })
        .then((r: any) => {
          if (r.data) {
            Utils.presentToastWarning("角色下已存在该用户，不能进行此操作！");
          } else {
            callback();
          }
        });
    },
  },
});
