
import { modalController, IonPage } from "@ionic/vue";
import { OrganizationService } from "@/services/organization/OrganizationService";
import ProjectOrganizationsService from "@/services/project/ProjectOrganizationsService";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChooseOrganizationList",
  components: {
    HeaderModal,
    IonPage,
    SearchBar,
  },
  props: {
    id: String,
  },
  data() {
    return {
      api: new OrganizationService(),
      projectOrgService: new ProjectOrganizationsService(),
      parameters: {
        likeConditions: "",
        pageNum: 1,
        pageSize: 15,
      },
      list: [],
      selectId: "",
      selectKey: -1, //选中index
    };
  },
  mounted() {
    this.init("");
  },
  methods: {
    init(likeConditions: any) {
      this.parameters.likeConditions = likeConditions;
      this.parameters.pageNum = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.parameters.likeConditions = data.searchKey;
      this.parameters.pageNum = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    closeModel(type: number) {
      if (type != 4 || this.selectKey == -1) {
        modalController.dismiss();
        return;
      }
      if (!this.id) {
        return;
      }

      this.projectOrgService
        .add(this.list[this.selectKey], this.id)
        .then((res) => {
          if (res) {
            Utils.presentToast("添加成功");
            modalController.dismiss({ refresh: true });
          } else {
            Utils.presentToast("所选机构已经存在");
          }
        });
    },
    checkHandler(e: any, index: number, id: string) {
      if (e.detail.checked) {
        this.selectKey = index;
        this.selectId = id;
      } else {
        //取消选中，设置
        if (id == this.selectId) {
          this.selectKey = -1;
          this.selectId = "";
        }
      }
    },
    nextPage(event: any) {
      this.parameters.pageNum++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.pageNum = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.api.getOrgList(this.parameters).then((result: any) => {
        callback(result.data.rows);
      });
    },
  },
});
