class OkrTaskListInfo {

    public id?: string;            //主键id
    public name?: string;
    public sourceId?: string;
    public sourceType?: number;
    public taskId?: string;
    public remark?: string;
    public isDeleted?: boolean;
    public createTime?: string;
    public modifierId?: string;
    public creatorId?: string;
    public modifier?: string;
    public creator?: string;
    public modifierTime?: string;
    public systemId?: string;
    public enterpriseId?: string;
    public systemName?: string;
    public priority?: string;
    public isFinished?: string;
    public taskUser?: any;

    constructor() {
        // this.id = item.id;
        // this.userId = item.userId;
        // this.name = item.name;
        // this.photo = item.photo;
        // this.pernr = item.pernr;
        // this.department = item.department;
    }

}

export {
    OkrTaskListInfo,
    OkrTaskListInfo as default
}