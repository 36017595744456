
import { defineComponent } from "vue";
import { DashboardService } from "@/services/dashboardService";
export default defineComponent({
  name: "baseInfo",
  data() {
    return {
      api: new DashboardService(),
      projectId: "",
      data: {},
    };
  },
  methods: {
      getProjectInfo() {
         this.api.getProjectInfo({ projectId: this.projectId }).then((value) => {
        this.data = value.data.data;
      });
    },
  },
  mounted() {
    this.projectId = this.$route.query.projectId as any;
    this.getProjectInfo();
  },
});
