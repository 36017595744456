/**
 * 登录页面，获取基础信息
 */
import DashboardBaseService from '../base/DashboardBaseService';
/**
 * 首页
 */
export class DashboardApi extends DashboardBaseService {

    constructor() {
        super("");
    }

    public getProjectInfo(params: any): Promise<any> {
        const path = 'project/main/project-info/'+params.projectId;
        return this.request(path, this.rest.METHOD_GET, params);
    }
    public getCarTypeProduct(params: any): Promise<any> {
        const path = 'project/cartype/car-type/car-type-product/'+params.projectId;;
        return this.request(path, this.rest.METHOD_GET, params);
    }

    public getTeamUser(params: any): Promise<any> {
        const path = 'project/team/user/page';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    public getCardProject(params: any): Promise < any > {
        const path='project/main/project-list/page?projectManagerId=&isSearchAll=0';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // dashboard列表页
    public getDashboardList(params: any): Promise<any> {
        const path = 'dashboard/project/page';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // dashboard列表页查询
    public getMasterData(params: any): Promise<any> {
        const path = 'masterdata/dict/dict-item/getDictItemByDictCode/' + params.dictCode;
        return this.request(path, this.rest.METHOD_GET);
    }

    // dashboard列表页查询
    public getMilestoneNames(params: any): Promise<any> {
        const path = 'plan-center/task-names/main-milestone-shortnames';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // dashboard详情页
    public getDashboardCheckpint(params: any): Promise<any> {
        const path = 'dashboard/project/checkpint';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // Budget权限验证
    public getCheckAuth(params: any): Promise<any> {
        const path = 'dashboard/project/isBudget/' + params.id;
        return this.request(path, this.rest.METHOD_GET);
    }

    // 状态列表
    public getCheckpointTimes(params: any): Promise<any> {
        const path = 'tpb/dashboard/project/checkpoint-times';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // 状态列表
    public getCheckpointsStates(params: any): Promise<any> {
        const path = 'tpb/dashboard/project/checkpoint-states';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // 收藏
    public getCollection(params: any): Promise<any> {
        const path = 'project/main/project-info-extend/collection?projectId=' + params.projectId + '&isCollection=' + params.isCollection;
        return this.request(path, this.rest.METHOD_PUT);
    }

    // 点击量
    public getHits(params: any): Promise<any> {
        const path = 'project/main/project-info-extend/hits?projectId=' + params.projectId;
        return this.request(path, this.rest.METHOD_PUT);
    }

    // 获取dashboard详情信息
    public getDashboardById(params: any): Promise<any> {
        const path = `dashboard/project/${params}`;
        return this.request(path, this.rest.METHOD_GET);
    }

    // 根据项目id获得最新版本的主里程碑计划(主里程碑tap页)
    public getMainMileStone(params: any): Promise<any> {
        const path = 'project/plan/project-plan/getMainMileStone/'+ params.id;
        return this.request(path, this.rest.METHOD_GET);
    }
    //主里程碑计划任务(主里程碑tap页)
    public selectTasks(params: any): Promise<any> {
        const path = 'plan-center/milestone-plans/' + params +'/tasks';
        return this.request(path, this.rest.METHOD_GET);
    }

    // 根据项目id获得最新版本的主里程碑计划(主计划tap页)
    public getMainPlanByProjectId(params: any): Promise<any> {
        const path = 'project/plan/project-plan/getMainPlanByProjectId/'+ params.id;
        return this.request(path, this.rest.METHOD_GET);
    }

    //根据 relationid 获取关联里程碑任务(主计划tap页)
    public showMileStonePlan (params: any): Promise<any> {
        const path = 'plan-center/milestone-plans/showMileStonePlan/' + params;
        return this.request(path, this.rest.METHOD_GET);
    }

    //根据planid 获取任务数据(主计划tap页)
    public getMainTask (params: any): Promise<any> {
        const path = 'project/plan/project-plan/getMainTask/' + params + '?isShowDelay=false';
        return this.request(path, this.rest.METHOD_GET);
    }

    // 关注任务
    public getAttentiontasks(params: any): Promise<any> {
        const path = 'plan-center/milestone-plans/tasks/list';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    public PlanCalendarList(params: any): Promise<any> {
        const path = 'plan-center/calendars/planCalendarList';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    public getRelationStone(params: any): Promise<any> {
        const path = 'plan-center/main-plans/relationStoneNode/' + params;
        return this.request(path, this.rest.METHOD_GET);
    }
}