<template>
  <ion-page>
    <HeaderModal
      btnTypeLeft
      :isRollback="isRollback"
      btnTypeRight
      themeType="1"
      btnTextRight
      :title="title"
      @on-close="closeModel"
    ></HeaderModal>
    <ion-content>
      <div class="list">
        <ion-item v-for="(item, index) in list" :key="index">
          <div class="check">
            <ion-checkbox
              :value="item.id"
              @ionChange="checkHandler($event,item)"
              :checked="item.id==selectedId"
            ></ion-checkbox>
          </div>
          <div class="label">{{item.text}}</div>
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script scoped>
import { modalController, IonPage } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import moment from "moment";

export default {
  name: "SelectRemind",
  props: {
    paramItem: Object,
    paramItemType: String,
  },
  components: {
    HeaderModal,
    SearchBar,
  },
  data() {
    const router = useRouter();
    const list = [];
    return {
      router: router,
      useRoute: useRoute(),
      selectedItem: {},
      selectedId: "",
      list: list,
      title: "",
      endTime: "",
      isRollback:true,
    };
  },
  mounted() {
    if (this.paramItem) {
      this.selectedItem = this.paramItem;
      this.selectedId = this.paramItem.id;
    }

    this.initList();
  },
  methods: {
    removeSelectItem() {
      this.selectedItem = {};
      this.selectedId = "";
    },
    initList() {
      this.list = [
        {
          id: "-1",
          text: "无",
        },
        {
          id: "0",
          text: "日程发生时",
        },
        {
          id: "1-5-min",
          text: "5分钟前",
        },
        {
          id: "1-15-min",
          text: "15分钟前",
        },
        {
          id: "1-30-min",
          text: "30分钟前",
        },
        {
          id: "1-1-hoour",
          text: "1小时前",
        },
        {
          id: "1-2-hour",
          text: "2小时前",
        },
        {
          id: "1-1-day",
          text: "1天前",
        },
      ];
      this.title = "选择";
    },
    checkHandler(e, item) {
      if (e.detail.checked) {
        this.selectedItem = item;
        this.selectedId = item.id;
      }
    },
    closeModel(type) {
      if (type == 4) {
        //关闭页面
        this.selectedItem.endTime = this.endTime;
        modalController.dismiss({
          selectedItem: this.selectedItem,
        });
      }
      modalController.dismiss({});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/details";

.list {
  list-style: none;
  margin: 0 $left-right-padding-width;

  ion-item {
    margin-top: px2rem(20);
    font-size: $little-font-size;
    --border-radius: #{px2rem(8)};
    --background: #{$page-background};
    line-height: px2rem(38);
    --border-style: none;

    .check {
      padding-top: px2rem(10);
    }
    ion-checkbox {
      --size: 1.3em;
      --background: #fff;
      display: inline-block !important;
      margin: 0;
    }
    .label {
      padding-top: px2rem(15px);
      padding-bottom: px2rem(15px);
      margin-left: px2rem(65px);
    }
    div {
      display: inline;
    }
  }

  ion-item:first-child {
    margin-top: 0px;
  }
}
</style>