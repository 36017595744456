<template>
    <div class="ganttWraper">
        <!-- 甘特区域 -->
        <div class="ganttArea" style="height:100%;">
            <div style="height: 100%;position:relative;">
                <!-- <div style="height: 100%;width:100%;position: absolute;top: 0;left:0;background: transparent;z-index:99999;"></div> -->
                <bingo-gantt ref="gantt" :type="ganttType" :planId="id" :tasks='milestoneData' @before-init="beforeInit"></bingo-gantt>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from "vue"; 
    import bingoGantt from './gantt/gantt.vue';
    import { DashboardService } from "@/services/dashboardService";
    import { loadingController } from "@ionic/vue";
    import Utils from "@/utils/Utils";
    export default defineComponent({
        name: "milestone",
        components: {
            bingoGantt
        },
        props:{},
        data() {
            return {
                api: new DashboardService(),
                gantt:{},
                ganttType: 'milestone',
                id: 7,
                milestoneData: null,
                loading: null
            };
        },
        watch:{
            $route(from,to){
                this.loading.dismiss();
            }
        },
        created(){
            this.projectId = Number(this.$route.query.projectId);
            this.getData();
        },
        methods: {
            async getData(){
                this.loading = await loadingController.create({
                    cssClass: "my-custom-class",
                    message: "数据加载中...",
                });
                await this.loading.present();
                this.api.getMainPlanByProjectId({id:this.projectId}).then(planData => {
                    // console.log(planData.data.data,'通过projectId获取主计划信息---relation-milestone组件')
                    if(planData.data.data){
                        this.id = planData.data.data.relationId;
                        this.api.showMileStonePlan(this.id).then((res) => {
                            // console.log(res.data.data ,'主计划里程碑数据---relation-milestone组件');
                            if(res.data.success){
                                this.gantt.sopDate = res.data.data.plan.sopDate.replace(/\-/g, "/");
                                let tempData = {
                                    data:[],
                                    link:[]
                                }
                                tempData.data = res.data.data.tasks.data.filter(item => item.shortName !='' );
                                tempData.data.push({
                                    id: '0',
                                    duration: 0,
                                    color: null,
                                    open: 0,
                                    parent: 'root',
                                    text: '',
                                    render: 'split',
                                    taskType: 'project',
                                    startDate: '',
                                    endDate: '',
                                    start_date: this.fommatCurrentTime(),
                                    end_date: this.fommatCurrentTime(),
                                    resources: []
                                });
                                this.milestoneData = tempData;
                                this.loading.dismiss();
                            } else {
                                this.loading.dismiss();
                                Utils.presentToast("没有相关联里程碑数据！");
                                this.milestoneData = {
                                    data:[],
                                    link:[]
                                }
                            }
                        }).catch(err => {
                            this.loading.dismiss();
                            Utils.presentToast("没有相关联里程碑数据！");
                            this.milestoneData = {
                                data:[],
                                link:[]
                            }
                        });
                    }else{
                        this.loading.dismiss();
                        Utils.presentToast("没有相关主计划数据！");
                    }
                }).catch(()=>{
                    this.loading.dismiss();
                    Utils.presentToast("没有相关联项目数据！");
                })
                
            },
            beforeInit(gantt){
                this.gantt = gantt;
                this.setConfigs(gantt);
                this.setTemplate(gantt);
            },
            setTemplate (gantt) {
                gantt.templates.task_class = (start, end, task) => {
                    const css = [];
                    if(task.duration >= 1&& !task.backColor) {
                        css.push('taskBlue2')
                    } else if(task.duration === 0&& !task.backColor) {
                        css.push('taskBlue')
                    }
                    return css.join(' ');
                };
            },
            setConfigs (gantt) {
                gantt.config.layout = {
                    css: 'gantt_container',
                    rows: [
                        {
                            cols: [
                                { view: 'grid', scrollX: 'scrollHor', scrollY: 'scrollVer', scrollable: true, min_width: 500 },
                                { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
                                { view: 'scrollbar', id: 'scrollVer' }
                            ]
                        },
                        { view: 'scrollbar', id: 'scrollHor', height: 20 }
                    ]
                };
                gantt.config.open_split_tasks = false;
                gantt.config.readonly = true;
                gantt.config.root_id = 'root';
                gantt.config.row_height = 69;
                gantt.config.show_grid = false;
                gantt.config.drag_move = false;
            },
            fommatCurrentTime(){
                let year = new Date().getFullYear();
                let month = new Date().getMonth() + 1;
                let day = new Date().getDate();
                let hour = new Date().getHours();
                if(hour < 10){
                    hour = '0' + hour;
                }
                let minutes = new Date().getMinutes();
                if(minutes < 10){
                    minutes = '0' + minutes;
                }
                let seconds = new Date().getSeconds();
                if(seconds < 10){
                    seconds = '0' + seconds;
                }
                return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
            }
        }
    });
</script>

<style lang="scss" scoped>
    .ganttWraper{
        width: 100%;
        height: 100%;
        overflow-x: auto
    }
    .ganttArea{
        width: 100%;
        overflow-x: scroll;
        border: 1px solid #cecece;
    }
</style>