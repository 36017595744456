/**
 * 任务用户角色
 */
export enum EUserWorkType {
  Executor = 1, //执行人
  Party = 2, //参与人
  Approver = 3 //批准人
}

/**
 * 关联内容类型
 */
export enum TaskLinkContentType {
  Project = 1, //关联项目
  Task = 2, //任务
  Document = 3, //文件
  Schedule = 4, //文件
}

export enum EPermissionType {
  Enterprise = 1, //企业权限
  Project = 2, //项目权限
  Task = 3 //任务权限
}

/**
 * 任务优先级
 */
export enum TaskPriority {
  low = 0, //较低
  common = 1, //普通
  urgent = 2, //紧急
  veryUrgent = 3 //非常紧急
}

/**
 * 项目任务可见性
 */
export enum TaskVisibility {
  all = 1, //所有人
  projectMember = 2, //项目成员
  taskUser = 3 //任务人员
}