
// import { IonCheckbox } from "@ionic/vue";
//时间格式化
import Message from "@/models/message/Message";
import moment from "moment";
import { defineComponent } from "vue";
import { FileHelper } from "@/utils/FileHelper";
import { File } from "@ionic-native/file";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "Massage",
  components: {},
  props: {
    message: Object,
    userId: String,
  },
  data() {
    const fileHelper = new FileHelper();
    return {
      fileHelper: fileHelper,
      base64Pic: "",
    };
  },
  created() {
    // this.timeFormat();
    // this.setPreviewPath();
    this.getBusTagData();
    this.loadImg();
  },
  watch: {
    sendTime() {
      this.timeFormat();
    },
  },
  methods: {
    timeFormat() {
      if (this.message) {
        let dateTime: Array<string> = this.message.sendTime.split(" ");
        let nowDate: string = moment(new Date()).format("YYYY-MM-DD");
        if (dateTime[0] == nowDate) {
          this.message.sendTime = moment(this.message.sendTime).format("HH:mm");
        } else {
          this.message.sendTime = dateTime[0];
        }
      }
    },

    // setPreviewPath() {
    //   if (this.message && this.message.messageType == 4) {
    //     this.message.url = this.fileHelper.getPreviewPathByFileId(
    //       this.message.file.fileId,
    //       ""
    //     );
    //   }
    // },

    loadImg() {
      let message = this.message as Message;
      if (message.messageType != 4) return;

      //TODO:
      // 1. 文件目录结构， 缩略图 /Download/Chat/$fileId_preview 原图 /Download/Chat/$fileId_raw
      // 2. 图片消息下载完成之前，显示一张默认的 GIF 图，沙漏之类。
      // 3. 下载缩略图 /preview/image/$fileId 接口找李宗科要
      // 4. 显示图片的顺序逻辑： 判断本地是否存在->不存在就下载->读出 dataurl
      // 5. 发送过程中，显示动图，发送完成后，下载并显示
      // 6. 把这文件里面所有用到的跟文件相关的方法，转移到 ChatFileUtils.ts 里。
      let expandedName = this.fileHelper.getExpandedName(message.file.fileName);
      this.fileHelper
        .downloadThumb(message.file.fileId, expandedName, 0)
        .then((res: any) => {
          this.base64Pic = res;
        })
        .catch(() => {
          // this.base64Pic = res;
        });
    },

    getBusTagData() {
      if (this.message && this.message.busTag) {
        this.message.busTag = JSON.parse(this.message.busTag);
      }
    },

    previewImg(message: Message) {
      if (message.messageType == 4) this.$emit("preViewImg", message);
    },

    previewFile(message: Message) {
      if (message.messageType == 6) this.$emit("previewFile", message);
    },
  },
});
