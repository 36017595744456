import { RecordApi } from '@/api/attendance/record/RecordApi';

export class RecordService {
    private api = new RecordApi();

    public getAttenCity(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAttenCity(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getAllList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAllList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getSignPlace(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getSignPlace(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getDetaillog(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDetaillog(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getUserAttenCity(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getUserInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}