
import {
  modalController,
  IonPage,
  // IonList,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { getInstance1 } from "@/services/selectexecutor/selectExecutorTeamService";
import Utils from "@/utils/Utils";
import { log } from "console";
import { GroupService } from "@/services/okr/GroupService";
export default defineComponent({
  name: "SelectOrganization",
  components: {
    IonPage,
    HeaderModal,
    // IonList,
    IonItem,
    SearchBar,
  },
  props: {
    type: String,
    queryObj: Object,
    data: Object,
    mulSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {},
  data() {
    const seleceExecutorTeamService = getInstance1();
    return {
      teamList: new Array(0),
      navLines: new Array(0),
      seleceExecutorTeamService: seleceExecutorTeamService,
      groupService: new GroupService(),
      navCheck: 0,
      navFolders: [0],
      getAllOrganization: new Array(),
      getAllTeam: new Array(),
      selectedItemOrg: new Array(),
      selectedItemTeam: new Array(),
      defaultExpandedKeys: "",
      selSeg: 0,
      checkedId: "",
      checkedName: "",
      defaultProps: {
        children: "children",
        label: "title",
      },
      teamDefaultProps: {
        children: "children",
        label: "name",
      },
      okrFilterText: "",
      checkedType: "",
      checkedIds: new Array(),
      orgArr: new Array<String>(),
      teamArr: new Array<String>(),
    };
  },

  mounted() {
    console.log(this.queryObj);
    this.initData();
  },
  methods: {
    initData() {
      this.getOrganization();
      this.getTeam();
      setTimeout(() => {
        if (this.queryObj) this.checkedId = this.queryObj.checkedId;
        if (this.queryObj) this.checkedName = this.queryObj.checkedName;
        if (this.queryObj) this.checkedType = this.queryObj.checkedType;
      });
      //  console.log(this.queryObj);

      //  console.log( this.checkedId );
    },
    //获取组织
    getOrganization() {
      this.seleceExecutorTeamService
        .getAllOrganizationTeamList()
        .then((r: any) => {
          if (r) {
            this.getAllOrganization = r.data.data;
            this.setCheckedKeys();
            this.setOpenIdArr(this.getAllOrganization, this.orgArr, true);
          }
        });
    },
    //获取团队
    getTeam() {
      this.groupService.getGroupList().then((res: any) => {
        this.getAllTeam = res;
        this.setCheckedKeys();
        this.setOpenIdArr(this.getAllTeam, this.teamArr, false);
      });
    },
    setOpenIdArr(orgs: Array<any>, arr: Array<String>, isOrg: boolean) {
      for (let org of orgs) {
        if (org.children) {
          for (let o of org.children) {
            if (isOrg) arr.push(o.treeId as string);
            else arr.push(o.id as string);
          }
        }
      }
    },
    setCheckedKeys() {
      let that = this;
      let objs = new Array();
      if (this.data && this.data.queryOrgIds) {
        this.data.queryOrgIds.forEach((ele: any) => {
          objs.push(ele.checkedId);
        });
      }
      let objOkr: any = that.$refs.okrTree;
      let objTeam: any = that.$refs.teamTree;
      if (objOkr) {
        objOkr.setCheckedKeys(objs);
      }
      if (objTeam) {
        objTeam.setCheckedKeys(objs);
      }
    },
    segmentChanged(ev: any) {
      this.selSeg = ev.detail.value;
    },
    //选中组织
    orgHandleNode(data: any) {
      //if (data.children == "" || data.children == null) {
      this.checkedId = data.treeId;
      this.checkedName = data.title;
      this.checkedType = "org";
      // }
    },
    //选中团队
    teamHandleNode(data: any) {
      //if (data.children == "" || data.children == null) {
      this.checkedId = data.id;
      this.checkedName = data.name;
      this.checkedType = data.type;
      //}
    },
    checkChange(item: any, checkedNodes: any) {
      if (this.selSeg == 0) {
        this.selectedItemOrg = checkedNodes.checkedNodes;
      } else {
        this.selectedItemTeam = checkedNodes.checkedNodes;
      }
    },
    confirm(type: any) {
      if (type == 3) {
        modalController.dismiss();
      } else if (type == 4) {
        let selectItemFinal = new Array();
        if (this.mulSelect) {
          var selectedIds = new Array<string>();
          //数据回显后，未重新选择组织/团队，直接点提交
          if (
            (this.selectedItemOrg.length == 0 ||
              this.selectedItemTeam.length == 0) &&
            this.data &&
            this.data.queryOrgIds &&
            this.data.queryOrgIds.length > 0
          ) {
            let that = this;
            let objOkr: any = this.$refs.okrTree;
            let objTeam: any = this.$refs.teamTree;
            if (this.$refs.okrTree) {
              let selects: any = objOkr.getCheckedNodes();
              selects.forEach((ele: any) => {
                console.log(JSON.stringify(ele));
                let e: any = {
                  checkedId: ele.treeId,
                  checkedName: ele.title,
                  checkedType: "org",
                };
                selectItemFinal.push(e);
              });
            }
            if (objTeam) {
              let selects: any = objTeam.getCheckedNodes();
              selects.forEach((ele: any) => {
                console.log(JSON.stringify(ele));
                let e: any = {
                  checkedId: ele.id,
                  checkedName: ele.name,
                  checkedType: type.type,
                };
                selectItemFinal.push(e);
              });
            }
          } else {
            //数据有修改
            let that = this;
            that.selectedItemOrg.forEach((ele: any) => {
              let e: any = {
                checkedId: ele.treeId,
                checkedName: ele.title,
                checkedType: "org",
              };
              selectItemFinal.push(e);
            });
            that.selectedItemTeam.forEach((ele: any) => {
              let e: any = {
                checkedId: ele.id,
                checkedName: ele.name,
                checkedType: type.type,
              };
              selectItemFinal.push(e);
            });
          }

          for (let index = 0; index < selectItemFinal.length; index++) {
            let item: any = selectItemFinal[index];
            selectedIds.push(item.checkedId + "");
          }
          modalController.dismiss({
            selectedIds: selectedIds,
            data: selectItemFinal,
          });
        } else {
          let obj = {
            checkedId: this.checkedId,
            checkedName: this.checkedName,
            checkedType: this.selSeg == 0 ? "org" : this.checkedType,
          };
          modalController.dismiss({ checkedObj: obj });
        }
      }
    },
  },
});
