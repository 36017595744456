import { TaskUserApi } from '@/api/task/TaskUserApi';
import BaseService from '../base/BaseService';

export class TaskUserService extends BaseService {
    
    protected api =new TaskUserApi();
    
    public getNewUserModel(data: any) {
        const taskData: any = super.newModel("task", "taskUser");
        data = Object.assign(data, taskData);
        return data;
    }

    public deleteTaskUser(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.deleteTaskUser(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public addTaskUser(data: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.insertTaskUser(data).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public updateReadStatusByTaskIdAndUser(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.updateReadStatusByTaskIdAndUser(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

}