import FileBaseService from '../base/FileBaseService';
import { Folder } from '@/models/file/Folder';

export class FolderApi extends FileBaseService {

    constructor() {
        super("folders");
    }

    //根据文件夹表示获取文件列表
    public getFileByFolderId(params: any): Promise<any> {
        const path = "getContent";
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 新增根文件夹
     * @param folder 
     */
    public createRootFolder(folder: any): Promise<any> {
        const path = "newRootFolder";
        return this.request(path, this.rest.METHOD_POST, folder);
    }

    /**
     * 
     * @param id
     */
    public getFilesById(id: string) {
        const path = 'getById';
        return this.request(path, this.rest.METHOD_GET, { id: id });
    }

    /**
     * 新增子文件夹
     * @param folder 
     */
    public createSubFolder(folder: any): Promise<any> {
        const path = "newSubFolder";
        return this.request(path, this.rest.METHOD_POST, folder);
    }

    /**
     * 项目-回收站-文件夹tree
     * */
    public getTreeByProjectId(params: any): Promise<any> {
        const path = "getTreeByProjectId";
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 项目-回收站-文件夹列表
     * */
    public getPagedRootDeletedFolders(params: any): Promise<any> {
        const path = "getPagedRootDeletedFolders";
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 项目-回收站-还原文件夹
     * */
    public restoreFolder(folderId: string, targetFolderId: string): Promise<any> {
        const path = "restoreFolder?folderId=" + folderId + "&targetFolderId=" + targetFolderId;
        return this.request(path, this.rest.METHOD_PUT, null);
    }

    /**
     * 项目-回收站-删除文件夹
     * */
    public clearFolder(folderId: string): Promise<any> {
        const path = "clearFolder?folderId=" + folderId;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }

}