
import { defineComponent } from "vue";
import { Px2rem } from "@/libs/px2rem";
import KeyResult from "@/models/okr/KeyResult";
import SystemUtils from "@/utils/SystemUtils";
// import moment from "moment";
export default defineComponent({
  name: "KeyResultItem",
  props: {
    kr:
    {
      type: Object,
      required: false,
      default: new KeyResult(),
    },
    isTargetPrincipal: Boolean,
    isShowTatget:Boolean,
    OStatus: Number,
  },
  data() {
    const circleSize = Px2rem.getRem("88px");
    return {
      circleSize: circleSize,
      circleWid: 80,
      isPrincipal: false,
    };
  },
  emits:["updataProgress","previewTarget","toKeyResultInfo","toTheTarget"],
  created() {  
    this.isPrincipal = this.kr?.userId == SystemUtils.loginUser.id;
  },
  methods: {
    updataProgress(kr: KeyResult) {
      this.$emit("updataProgress", kr);
    },
    previewTarget(kr: KeyResult) {
      this.$emit("previewTarget", kr);
    },
    toKeyResultInfo(kr: KeyResult) {
      this.$emit("toKeyResultInfo", kr);
    },
    toTheTarget(kr: KeyResult) {
      this.$emit("toTheTarget", kr);
    },
  },
});
