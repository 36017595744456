<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header>
        <ion-toolbar>
          <p slot="start" @click="goBack()">返回</p>
          <ion-title>list样式</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="projectList">
        <ProjectCss class="item" :ProjectObj="ProjectObj" />
      </div>
      <div class="indexList">
        <HomeSchedule class="item" :ScheduleObj="ScheduleObj" />
      </div>
      <div class="taskListTwo">
        <HomeTask class="item" :TaskObj="TaskObj" />
      </div>
      <div class="documentList">
        <TaskDocument class="item" :DocumentObj="DocumentObj" />
      </div>
      <div class="fileList">
        <FileCard class="item s" :FileObj="FileObj" />
      </div>
      <div class="teamList">
        <TaskTeam class="item" :TeamObj="TeamObj" />
      </div>
      <div class="messageList">
        <Massage class="item" :MassageObj="MassageObj" :userId="userId" />
      </div>


      <!-- 修改以后的日程和任务 -->

      <div class="indexList scheduleCardList">
        <ScheduleCard  :ScheduleCardObj="ScheduleCardObj"></ScheduleCard>
      </div>
       <div class="taskCardList"> 
        <TaskCards  :TaskCardObj="TaskCardObj" :operabilityCard="operabilityCard"></TaskCards>
      </div> 
      
    </ion-content>
  </ion-page>
</template>

<script >
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
//组件
import ProjectCss from "../../components/ListCard/ProjectCss.vue";
import HomeSchedule from "../../components/ListCard/HomeSchedule.vue";
import HomeTask from "../../components/ListCard/HomeTask.vue";
import TaskDocument from "../../components/ListCard/TaskDocument.vue";
import FileCard from "../../components/ListCard/FileCard.vue";
import Massage from "../../components/ListCard/Massage.vue";
import TaskTeam from "../../components/ListCard/TaskTeam.vue";
//新的卡片
import ScheduleCard from "../../components/ListCard/ScheduleCard.vue";
import TaskCards from "../../components/ListCard/TaskCards.vue";

export default defineComponent({
  name: "SelfList",
  components: {
    ProjectCss,
    HomeSchedule,
    HomeTask,
    TaskDocument,
    FileCard,
    Massage,
    TaskTeam,
    ScheduleCard,
    TaskCards
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      ProjectObj: {
        name: "任务项目App智能终端研发项目",
        startTime: "09:00",
        endTime: "18”00",
        source: "总经理",
        isfinish: 1,
        state: '进度延期',
        sum: "90",
        finishCount: "9",
        expire: "19",
        overdue: "8",
        recent: "6",
        schedule: "100",
        
      },
      ScheduleObj: {
        name: "产品研讨会",
        startTime: "09:00",
        endTime: "18”00",
        source: "总经理",
        isfinish: 1,
        state: 1,
      },
      TaskObj: {
        name: "产品研讨会",
        startTime: "09:00",
        endTime: "18”00",
        source: "总经理",
        isfinish: 0,
        state: 0,
        user: ["阳阳", "李果果"],
      },
      DocumentObj: {
        name: "项目需求说明书",
        size: "576k",
        userName: "王利军",
        time: "2020 - 09 - 01",
      },
      FileObj: {
        name: "项目需求规格说明书",
      },
      TeamObj: {
        finishNum: 12,
        nobeginNum: 9,
        beginNum: 19,
        finish: 12,
        userName: "王利军",
        schedule: "100",
      },
      MassageObj: {
        userId: "2",
        userName: "王利军",
        time: "2020-01-12",
        content: "请大家下班前检查并完成测试工作。",
      },
      userId:"1",

      //新的日程 修改了参数 
      ScheduleCardObj:{
        name: "产品研讨会",
        startTime: "09:00",
        endTime: "18:00",
        source: "总经理",
        isfinish:false, //false未完成  true完成
        priority:2, //优先级 0较低 1普通 2 紧急 3 非常紧急
        isSure:false,//是否参与确认
        isTimeBg:false,//是否显示时间的背景背景 
      },
      //新的任务列表 不可操作
      TaskCardObj:{
        name: "产品研讨会数量的发挥凯撒记得",
        startTime: "2020-11-27 08:00:00",
        endTime: "2020-11-27 21:00:00",
        source: "总经理",
        projectName:"任务中台App", //所属项目
        isfinish:false, //false未完成  true完成
        priority:2, //优先级 0较低 1普通 2 紧急 3 非常紧急 
        isTimeBg:true,//是否显示时间的背景背景 
        user: ["阳阳", "李果果", "李果果", "李果果"],
      }, 
      operabilityCard:true, //是否显示可操作的勾选按钮
    };
  },
  methods: {
    goBack() {
      this.router.go(-1);
    },
  },
});
</script>

<style scoped lang="scss">
</style>