
import { modalController } from "@ionic/vue";
import { useRouter } from "vue-router";
import { TaskService } from "@/services/task/TaskService";
import { defineComponent } from "vue";
import { TaskPermission } from "@/services/task/model/TaskPermission";
import Utils from "@/utils/Utils";
import Setting from "@/rest/Setting";

export default defineComponent({
  name: "TaskMenu",
  props: {
    taskId: String,
    taskInfo: Object,
    pers: TaskPermission,
    isTaskDeleted: Boolean,
  },
  data() {
    const router = useRouter();
    const isIndependentTask =
      this.taskInfo && this.taskInfo.projectId ? false : true;
    return {
      taskApi: new TaskService(),
      router: router,
      isIndependentTask: isIndependentTask,
      isShow: Setting.isSVW && isIndependentTask ? false : true,
    };
  },
  mounted() {
    Utils.resetModalHei(10);
  },
  methods: {
    hasLinkPermission(code: string) {
      if (this.pers) {
        return (
          (!this.isIndependentTask && this.pers.linkpost) ||
          (this.isIndependentTask && this.pers.name)
        );
      }
      return false;
    },
    //还原任务
    revertTaskHandler() {
      Utils.confirmDialog("还原任务", "您确定要把该任务还原吗？", () => {
        this.backToPageWithParam("revert");
      });
    },
    //彻底删除任务
    deleteCompleteHandler() {
      Utils.confirmDialog(
        "彻底删除",
        "彻底删除任务后，该任务及其子任务将会被永久删除？",
        () => {
          this.backToPageWithParam("deleteComplete");
        }
      );
    },
    //添加子任务事件
    addTaskHandler() {
      this.backToPageWithParam("addTask");
    },
    closeModel() {
      modalController.dismiss();
    },
    //转换为子任务事件
    convertToChildTaskHandler() {
      this.backToPageWithParam("convertToChild");
    },
    //关联内容
    LinkContentHandler() {
      this.backToPageWithParam("linkContent");
    },
    LinkScheduleHandler() {
      this.backToPageWithParam("LinkSchedule");
    },
    //任务设置
    setTaskHandler() {
      this.backToPageWithParam("setTask");
    },
    //设置可见性
    setVisibleHandler() {
      this.backToPageWithParam("setVisible");
    },
    //编辑任务
    editTaskHandler() {
      this.backToPageWithParam("editTask");
    },
    //复制任务
    copyTaskHandler() {
      this.backToPageWithParam("copyTask");
    },
    //操作日志
    operationLogHandler() {
      this.backToPageWithParam("operationLog");
    },
    //移到回收站
    async moveToRecycleBinHandler() {
      Utils.confirmDialog(
        "移到回收站",
        "您确定要把该任务移到回收站吗？",
        () => {
          this.backToPageWithParam("moveToRecycleBin");
        }
      );
    },
    //任务转派
    transferBinHandler() {
      this.backToPageWithParam("taskTransfer");
    },
    backToPageWithParam(action: string) {
      modalController.dismiss({
        action: action,
      });
    },
  },
});
