
import { alertController, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import { KeyResultService } from "@/services/okr/KeyResultService.ts";

export default defineComponent({
  name: "UpdateProgress",
  components: {
    HeaderModal,
  },
  props: {
    id: String,
  },
  data() {
    const router = useRouter();
    const progressInfo: any = {
      id: "",
      name: "",
      weight: "",
      unitStartValue: "",
      unitEndValue: "",
      isCustomUnit: false,
      unit: "",
      startDate: "", //结果开始日期
      endDate: "", //结果结束日期
    };
    const updateInfo = {
      id: "",
      // status: "--",
      progress: "",
      currentCount: "",
      endDate: "",
      remark: "",
      krId: "",
      krWeight: 0,
    };

    return {
      router: router,
      useRoute: useRoute(),
      keyResultService: new KeyResultService(),
      progressInfo: progressInfo,
      updateInfo: updateInfo,
      infoParam: {
        id: "",
      },
      userList: new Array(),
      isRollback: true,
      btnTextRight: "完成",
      btnTypeRight: "2",
      isClick: true,
    };
  },
  mounted() {
    this.progressInfo.id = this.id;
    //加载页面数据
    this.initProgressInfo();
  },
  methods: {
    initProgressInfo() {
      let params = { id: this.progressInfo.id };
      this.keyResultService.getDetail(params).then((res: any) => {
        if (res) {
          this.progressInfo.id = res.data.id;
          this.progressInfo.name = res.data.name;
          this.progressInfo.weight = res.data.weight;
          this.progressInfo.unitStartValue = res.data.unitStartValue;
          this.progressInfo.unitEndValue = res.data.unitEndValue;
          this.progressInfo.unit = res.data.unit;
          this.progressInfo.isCustomUnit = res.data.isCustomUnit;
          this.progressInfo.startDate = res.data.startDate;
          this.progressInfo.endDate = res.data.endDate;
          this.updateInfo.krId = res.data.id;
          this.updateInfo.krWeight = res.data.weight;
        }
      });
      /*
      this.progressInfo = {
        id: "1",
        name: "完成任务模块、项目模块API",
        weight: "20",
        unitStartValue: "0%",
        unitEndValue: "100%",
        unit: "未完成",
        isCustomUnit:false,
        startDate: "2020-12-29", //结果开始日期
        endDate: "2021-02-01", //结果结束日期
      };*/
    },
    closeModel(type: any) {
      if (type == 4) {
        this.saveHandler();
      } else {
        modalController.dismiss({});
      }
    },
    bindCheckValue() {
      let flagGress = true;
      if (this.updateInfo.currentCount == "") {
        flagGress = false;
        Utils.presentToastWarning("请输入当前值！");
        return;
      }
      let g = parseInt(this.updateInfo.currentCount);
      if (g < 0) {
        flagGress = false;
      }
      if (!flagGress) {
        this.updateInfo.currentCount = "";
        Utils.presentToastWarning("只能输入正整数！");
        return;
      } else {
        if (this.updateInfo.currentCount != g + "") {
          this.updateInfo.currentCount = g + "";
          Utils.presentToastWarning("只能输入正整数！");
          return;
        }
      }
      //计算进度
      this.calProgress();
      // this.calStatus();
    },
    //计算进度
    calProgress() {
      //若是关键结果单位为“完成/未完成”，那么直接填写当前进度值;
      //若是关键结果单位为“自定义”,那么直接填写当前值（正整数），进度不可填写，由系统自动计算（当前值/目标值），允许当前值大于目标值，但是进度最大不能超过100%
      if (this.progressInfo.isCustomUnit) {
        let g = parseInt(this.updateInfo.currentCount);
        // let p =
        //   ((parseInt(this.updateInfo.currentCount) -
        //     parseFloat(this.progressInfo.unitStartValue)) /
        //     (parseFloat(this.progressInfo.unitEndValue) -
        //       parseFloat(this.progressInfo.unitStartValue))) *
        //   100;
        let p = (g / parseFloat(this.progressInfo.unitEndValue)) * 100;
        p = parseInt(p * 10 + "") / 10;
        if (p > 100) {
          p = 100;
        }
        this.updateInfo.progress = p + "";
      }
    },
    //计算状态
    //   calStatus() {
    //     /**
    //        * 偏差值：（计划进度-实际进度）。计划进度=（实际日期-结果开始）/（结果结束-结果开始）。
    //        * 实际日期=当天，最大不能超过结果结束日期。
    // 失控：偏差值>10%
    // 有风险：偏差值>5%
    // 正常：偏差值<=5%
    // --：关键结果尚未开始，且进度为0
    //        */
    //     if (this.updateInfo.progress != "") {
    //       //计划进度
    //       let today = moment(new Date()).format("YYYY-MM-DD");
    //       let todayTime = new Date(today + " 23:59:59.999");
    //       let pStartDate = new Date( moment(this.progressInfo.startDate).format("YYYY-MM-DD") + " 0:0:0.001");
    //       let pEndDate = new Date( moment(this.progressInfo.endDate).format("YYYY-MM-DD") + " 23:59:59.999");
    //       let progrssAct =
    //         (todayTime.getTime() - pStartDate.getTime()) /
    //         (pEndDate.getTime() - pStartDate.getTime());
    //       //偏差值 （计划进度-实际进度）
    //       let pianCha = progrssAct - parseFloat(this.updateInfo.progress) / 100;
    //       let status = "--";
    //       if (pianCha <= 0.05) {
    //         status = "正常";
    //       } else if (pianCha > 0.05 && pianCha <= 0.1) {
    //         status = "有风险";
    //       } else if (pianCha > 0.1) {
    //         status = "失控";
    //       }
    //       this.updateInfo.status = status;
    //     }
    //   },
    bindCheckGress() {
      let flagGress = true;
      if (this.updateInfo.progress == "") {
        flagGress = false;
        Utils.presentToastWarning("请输入进度值！");
        return;
      }
      let g = parseInt(this.updateInfo.progress);
      if (g < 0) {
        flagGress = false;
      }
      if (g > 100) {
        flagGress = false;
      }
      if (!flagGress) {
        this.updateInfo.progress = "";
        Utils.presentToastWarning("只能输入0-100的整数！");
        return;
      } else {
        if (this.updateInfo.progress != g + "") {
          this.updateInfo.progress = g + "";
          Utils.presentToastWarning("只能输入0-100的整数！");
          return;
        }
      }
      // this.calStatus();
    },
    validateForm() {
      if (this.progressInfo.isCustomUnit) {
        if (this.updateInfo.currentCount == "") {
          Utils.presentToastWarning("请输入当前值");
          return false;
        }
      } else {
        if (this.updateInfo.progress == "") {
          Utils.presentToastWarning("请输入当前进度");
          return false;
        }
      }
      if (
        !this.updateInfo.endDate ||
        !this.updateInfo.endDate.replace(/\s+/g, "")
      ) {
        Utils.presentToastWarning("请选择进度结束时间");
        return false;
      }
      const v = moment(this.updateInfo.endDate).format("YYYY-MM-DD");
      const end: number = moment(v).toDate().getTime();
      const s = moment(new Date()).format("YYYY-MM-DD");
      const start: number = moment(s).toDate().getTime();
      if (start < end) {
        Utils.presentToastWarning("进度结束日期不能大于当前日期！");
        return false;
      }
      if (Utils.isInCludeEmoji(this.updateInfo.remark)) {
        Utils.presentToastWarning("说明不可以包含表情");
        return false;
      }
      return true;
    },
    saveHandler() {
      if (this.isClick) {
        this.isClick = false;
        if (!this.validateForm()) {
          this.isClick = true;
          return;
        }
        this.updateInfo.endDate =
          moment(this.updateInfo.endDate).format("yyyy-MM-DD HH:mm:ss") + "";
        this.updateInfo.id = Utils.getUuid;

        this.keyResultService
          .progressInsert(this.updateInfo)
          .then((res: any) => {
            if (res) {
              this.isClick = true;
              Utils.presentToastSuccess("保存成功");
              modalController.dismiss({
                isRefresh: res.data /* , status: this.updateInfo.status */,
                progress: this.updateInfo.progress,
              });
            }
          });
      }
    },
    chooseEndTime() {
      let selectOperate: any = document.getElementById(
        "select-EndTime-progress"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindEndTimeChange(ev: any) {
      let v = moment(ev.detail.value).format("YYYY-MM-DD");
      this.updateInfo.endDate = v;
      // let now = moment(new Date()).format("YYYY-MM-DD");
      // this.updateInfo.endDate = v;
      // if(v>now){
      //   this.updateInfo.endDate = now;
      //   ev.detail.value = now;
      //   // Utils.presentToastWarning("进度结束时间不能大于当前时间");
      // }
    },

    currentNumberInputChange(event: any) {
      const value: string = event.detail.value;
      if (value.length > 13) {
        setTimeout(() => {
          this.updateInfo.currentCount = value.substr(0, 13);
        }, 50);
      }
    },
  },
});
