import Message from "@/models/message/Message";
import SystemUtils from "../SystemUtils";
import Utils from "../Utils";
import SQLService from "./SQLService";
import Bus from "@/utils/bus";
import { resolve } from "path";
import { isPlatform } from '@ionic/vue';
import NativeService from "./NativeService";

export default class SqlHelper {

    private readonly sqlService: SQLService = SQLService.getInstance();

    /**
     * 初始化本地sqlite数据库
     */
    public initDB() {
        this.sqlService.initDB();
    }

    /**
     * 创建表（message）
     */
    public createTable(): Promise<any> {
        let sql = "CREATE TABLE IF NOT EXISTS message( "
            + "id CHAR(64) PRIMARY KEY NOT NULL, "
            + "chatGroupId CHAR(64) NOT NULL, "
            + "content CHAR(254), "
            + "file CHAR(254), "
            + "fileName CHAR(254), "
            + "busId CHAR(64), "
            + "busTable CHAR(254), "
            + "busTag CHAR(254), "
            + "title CHAR(254), "
            + "messageType INTERGER, "
            + "sendTime DATETIME, "
            + "sendUserId CHAR(64), "
            + "toUserId CHAR(64), "
            + "url CHAR(254), "
            + "messageState INTERGER, "
            + "sendUserName CHAR(64));";
        return this.sqlService.executeSql(sql);
    }

    /**
     * 插入一条消息数据
     * @param message 消息
     */
    public insertMessageData(message: Message) {
        this.sqlService.executeSql(this.getInsertSqlStr(message)).then((data: any) => {
            this.selectNotReadMessageCount();
        }).catch((err: any) => {
            // console.log(err)
        });
    }

    private getInsertSqlStr(message: Message): string {
        let sql = "INSERT INTO message (id, chatGroupId, content, file, fileName, busId, busTable, busTag, title, messageType, sendTime, sendUserId, toUserId, url, messageState, sendUserName) "
            + "VALUES ('"
            + message.id + "', '"
            + message.chatGroupId + "', '"
            + message.content + "', '";
        let file = "";
        let fileName = "";
        if (!message.file || message.file == 'undefined') {
            file = "";
            fileName = "";
        } else if (typeof message.file == 'string') {
            file = message.file;
            fileName = JSON.parse(message.file).fileName;
        } else {
            file = JSON.stringify(message.file);
            fileName = message.file.fileName;
        }
        sql = sql + file + "', '"
            + fileName + "', '"
            + message.busId + "', '"
            + message.busTable + "', '";
        if (typeof message.busTag == 'string') {
            sql = sql + message.busTag + "', '";
        } else {
            sql = sql + JSON.stringify(message.busTag) + "', '";
        }
        sql = sql + message.title + "', "
            + message.messageType + ", '"
            + message.sendTime + "', '"
            + message.sendUserId + "', '"
            + message.toUserId + "', '"
            + message.url + "', "
            + message.messageState + ", '"
            + message.sendUserName + "');";
        return sql;
    }

    /**
     * 插入多条消息数据
     * @param messages 消息集合
     */
    public insertMessageListData(messages: Array<Message>, isUpdate: boolean = true) {
        if (messages == null || messages.length <= 0) {
            return new Promise((resolve, reject) => {
            });
        }
        for (let message of messages) {
            this.sqlService.executeSql(this.getInsertSqlStr(message));
        }
        if (isUpdate)
            this.updateMessageState(messages[0].chatGroupId + "");
    }

    /**
     * 查询主页左侧消息列表
     * @param userId 当前用户id
     */
    public selectHomeMessageList(userId: string, offset: number): Promise<any> {
        console.log(" 查询主页左侧消息列表 selectHomeMessageList");
        let sql = "SELECT *, max(sendTime) from message WHERE toUserId='" + userId + "' group by chatGroupId order by sendTime desc LIMIT 15 OFFSET " + offset + ";";
        return this.getSqlMessageList1(sql, true);
    }

    /**
     * 主页左侧消息列表追加未读数量
     * @param message 消息
     */
    public selectHomeMessageList3(message: Message) {
        if (SystemUtils.loginUser && SystemUtils.loginUser.id) {
            let sql = "select count(id) as count from message where toUserId='" + SystemUtils.loginUser.id + "' and messageState!=2 and chatGroupId='" + message.chatGroupId + "';";
            // this.sqlService.executeSelectSql(sql).then((data: any) => {
            //     message.count = data.rows.item(0).count;
            // }, (err: any) => {
            //     message.count = 0;
            // }).catch((err: any) => {
            //     message.count = 0;
            // })

        } else {
            alert("查询主页左侧消息列表追加未读数量，未获取到用户信息");
            message.count = 0;
        }
    }

    /**
     * 查询所有未读消息数量
     * @param userId 当前用户id
     */
    public selectNotReadMessageCount() {
        if (SystemUtils.loginUser && SystemUtils.loginUser.id) {
            let sql = "SELECT COUNT(id) AS count from message WHERE toUserId='" + SystemUtils.loginUser.id + "' AND messageState!=2";
            return this.sqlService.executeSelectSql(sql)
                .then((rsp: any) => {
                    Bus.$emit("not_read_msg_count", rsp.rows.item(0).count);
                    Bus.$emit("receive_home_message");
                }, (err: any) => {
                    Bus.$emit("not_read_msg_count", 0);
                })
                .catch((err: any) => {
                    Bus.$emit("not_read_msg_count", 0);
                });
        } else {
            // alert("查询所有未读消息数量，未获取到用户信息");
            Bus.$emit("not_read_msg_count", 0);
        }
    }

    /**
     * 查询最新消息时间
     */
    public selectNewestMessageTime(): Promise<any> {
        let userId: string = SystemUtils.loginUser.id;
        let sql = "SELECT max(sendTime) as sendTime from message WHERE toUserId='" + userId + "'";
        return new Promise((resolve, reject) => {
            this.sqlService.executeSelectSql(sql).then((data: any) => {
                resolve(data.rows.item(0).sendTime);
            })
        });
    }

    /**
     * 根据聊天组id查询消息记录
     * @param chatGroupId 聊天组id
     * @param count 分页数量
     */
    public selectMessageListByChatGroupId(chatGroupId: string, count: number): Promise<any> {
        let userId: string = SystemUtils.loginUser.id;
        let sql = "SELECT * FROM message WHERE toUserId='" + userId + "' AND chatGroupId='" + chatGroupId + "' ORDER BY sendTime DESC LIMIT 50 OFFSET " + count + ";";
        return this.getSqlMessageList2(sql);
    }

    /**
     * 进入搜索详情的消息列表查询
     * @param message 搜索结果
     */
    public getSearchMessageList(message: Message) {
        let sql = "SELECT * FROM message WHERE toUserId='" + message.toUserId + "' AND chatGroupId='" + message.chatGroupId + "' AND sendTime >= '" + message.sendTime + "' ORDER BY sendTime DESC;";
        return this.getSqlMessageList2(sql);
    }

    public selectAll() {
        let sql = "SELECT * FROM message";
        return this.sqlService.executeSelectSql(sql);
    }

    public updateMessageState(chatGroupId: string) {
        let sql = "UPDATE message SET messageState=2 WHERE chatGroupId='" + chatGroupId + "' AND toUserId='" + SystemUtils.loginUser.id + "' and messageState!=2;"
        this.sqlService.executeSql(sql).then((rsp: any) => {
            this.selectNotReadMessageCount();
        });
    }

    public deleteMessageByChatGroupId(chatGroupId: string, noReadCount?: boolean) {
        let sql = "DELETE FROM message WHERE chatGroupId='" + chatGroupId + "' AND toUserId='" + SystemUtils.loginUser.id + "';"
        return this.sqlService.executeSql(sql).then((res: any) => {
            resolve(res);
            if (noReadCount)
                this.selectNotReadMessageCount();
        });
    }

    public selectMessageListByTitle(title: string, isTop3: boolean = false) {
        let userId: string = SystemUtils.loginUser.id;
        let sql = "SELECT *, MAX(sendTime) FROM message WHERE toUserId='" + userId + "' AND title LIKE '%" + title + "%' GROUP BY chatGroupId ORDER BY sendTime DESC";
        return this.searchMessage(sql, isTop3);
    }

    public selectMessageListByContent(content: string, isTop3: boolean = false) {
        let userId: string = SystemUtils.loginUser.id;
        let sql = "SELECT * FROM message WHERE toUserId='" + userId + "' AND content LIKE '%" + content + "%' ORDER BY sendTime DESC";
        return this.searchMessage(sql, isTop3);
    }

    public selectMessageListByFile(fileName: string, isTop3: boolean = false) {
        let userId: string = SystemUtils.loginUser.id;
        let sql = "SELECT * FROM message WHERE toUserId='" + userId + "' AND fileName LIKE '%" + fileName + "%' ORDER BY sendTime DESC";
        return this.searchMessage(sql, isTop3);
    }

    private searchMessage(sql: string, isTop3: boolean) {
        if (isTop3) {
            sql = sql + " LIMIT 3";
        }
        return this.getSqlMessageList1(sql, false);
    }

    private getSqlMessageList1(sql: string, isFormatDateTime: boolean) {
        console.log("getSqlMessageList1" + sql);
        return new Promise((resolve, reject) => {
            let messageList: Array<Message> = Array<Message>(0);
            this.sqlService.executeSelectSql(sql).then((data: any) => {

                console.log("executeSelectSql成功" + sql);
                for (let i = 0; i < data.rows.length; i++) {
                    let message: Message = data.rows.item(i);
                    if (isFormatDateTime) message.sendTime = Utils.formatDateTime(message.sendTime);
                    else message.formatSendTime = Utils.formatDateTime(message.sendTime);
                    messageList.push(message);
                }
                resolve(messageList);
            }, () => {
                console.log("executeSelectSql 失败");
                if (NativeService.isMobile()) {
                    setTimeout(() => {
                        this.getSqlMessageList1(sql, isFormatDateTime);
                    }, 200);
                }
                reject({ err: "this.sqlService.executeSelectSql 失败" });
            }).catch((err: any) => {
                console.log("getSqlMessageList1异常");
                console.log("err" + JSON.stringify(err));

                if (NativeService.isMobile()) {
                    setTimeout(() => {
                        this.getSqlMessageList1(sql, isFormatDateTime);
                    }, 200);
                }
                reject({ "erroy": err });
            }).finally(() => {
                console.log("getSqlMessageList1 finally");
            });
        });
    }

    private getSqlMessageList2(sql: string) {
        return new Promise((resolve, reject) => {
            let messageList: Array<Message> = Array<Message>(0);
            this.sqlService.executeSelectSql(sql).then((data: any) => {
                if (data.rows.length > 0) {
                    for (let i = data.rows.length - 1; i >= 0; i--) {
                        let message: Message = data.rows.item(i);
                        if (message.file && typeof message.file == 'string') {
                            message.file = JSON.parse(message.file);
                        }
                        messageList.push(message);
                    }
                }
                resolve(messageList);
            }).catch((err: any) => {
                // console.log(err)
                reject("error1");
            });
        });
    }
}