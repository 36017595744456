import { LogApi } from '@/api/log/LogApi';

export class LogService {
    private logApi = new LogApi();

    public getList(param: any): Promise<any> {
        param.nestedQuery= 'data.id:'+param.nestedQuery
        return new Promise((resolve, reject) => {
            this.logApi.getLogList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}