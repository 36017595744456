
import { defineComponent } from "vue"; //
import { useRouter, useRoute } from "vue-router";
import { FeedbackService } from "@/services/feedback/FeedbackService";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "Feedback",
  components: {},
  data() {
    const router = useRouter();
    return {
      dataList: new Array<any>(),
      feedbackService: new FeedbackService(),
      listParams: {
        searchKey: "",
        offset: 1,
        limit: 10,
        userId: this.userId,
      },
      router: router,
      isList: false,
    };
  },
  props: {
    refresh: Boolean, //是否刷新页面
    userId: String,
  },
  created() {},
  ionViewWillEnter() {},
  mounted() {},
  methods: {
    initData(addModel?: any) {
      if (addModel) {
        this.dataList.unshift(addModel);
      } else {
        //请求数据库，绑定数据
        // this.dataList=[{id:"1",name:"问题：小助手不能正常使用"},
        // {id:"2",name:"建议：任务模块加载太慢，建议优化"},
        // {id:"3",name:"问题：登录不成功"},
        // {id:"4",name:"问题：不能收消息"}];
        this.listParams.userId = this.userId;
        this.feedbackService
          .getFeedbackList(this.listParams)
          .then((r: any) => {
            this.isList = true;
            if (r) {
              this.dataList = r;
            }
          })
          .catch((error: any) => {
            Utils.presentToastWarning("获取反馈记录异常");
            this.isList = true;
          });
      }
      console.log("初始化数据");
    },
    openDetails(id: any) {
      this.router.push({
        path: "/HelperDetails",
        query: {
          id: id,
        },
      });
    },
  },
});
