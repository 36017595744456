import { isPlatform, loadingController } from "@ionic/vue";
import { BuildInfo } from '@ionic-native/build-info';
import { Rest } from '@/rest/rest';
import Setting from '@/rest/Setting';
import Utils from "@/utils/Utils";
import { VersionApi } from "@/api/version/VersionApi";
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { AndroidPermissions } from '@ionic-native/android-permissions';
import { Downloader, DownloadRequest, NotificationVisibility } from "@ionic-native/downloader";
import CookieHelper from "@/utils/CookieHelper";
import { HTTP } from '@ionic-native/http';
/**
 * byod版本  
 */
export class VersionService {
    public updateUrl: any = null;
    public path: any = null;
    public downPercent: number = 0;
    private api = new VersionApi();
    /**
     * 获取最新的版本信息
     */
    public getAppVersion(): Promise<any> {
        let headers = { Authorization: "", 'Content-Type': 'application/json', "X-APP-ID": Setting.defalutAPPID };
        // const token = CookieHelper.get(Setting.authCookieName);
        // if (token && token != "null" && token.length > 0) {
        //     headers.Authorization = JSON.parse(token).access_token;;
        // }

        return new Promise((resolve, reject) => {
            this.api.getLastVersion(null, headers)
                .then((res: any) => {
                    if (res) {
                        resolve(res.data);
                    } else {
                        Utils.presentToastWarning("未获取到最新版本");
                        resolve(null);
                    }
                }, (error: any) => {
                    reject(error);
                })
        })
    }
    /**
     * 检查是否有新版本
     * @return null 无新版本 not null 返回最新的版本对象 【区分ios 和 android】
     */
    public checkAppVersion(activeCheck?: any) {
        console.log('检测version2')
        HTTP.get(process.env.VUE_APP_BASE_API + "/files/jfvbmpda/version.txt", {}, {}).then(res => {
            console.log(res.data)
            let curV = BuildInfo.version
            let reqV = res.data;
            console.log(curV,reqV)
            //检测到更新
            if (curV != reqV) {

                //强制更新
                Utils.alertDialog(
                    "版本更新",
                    "当前应用版本过旧已经无法使用，请升级最新版本，否则无法使用",
                    () => {
                        this.download(reqV);
                        // (navigator as any).app.exitApp();
                    })
            }
        })

        // this.getAppVersion().then((res: any) => {
        //     // let curV="1.0.1" ,reqV="1.1.3";
        //     res=JSON.parse(res);
        //     if (res) {
        //         let curV =BuildInfo.version
        //         let reqV = res.versionNo;
        //         this.updateUrl = res.downUrl;
        //         this.path = res.path;
        //         //检测到更新
        //         if (curV != reqV) {
        //             let forceUpdate = false;
        //             if (!forceUpdate) {
        //                 Utils.confirmDialog(
        //                     "版本更新",
        //                     "发现新版本，是否升级",
        //                     () => {
        //                         this.download(reqV);
        //                     }
        //                 );
        //             } else {
        //                 //强制更新
        //                 Utils.alertDialog(
        //                     "版本更新",
        //                     "当前应用版本过旧已经无法使用，请升级最新版本，否则无法使用",
        //                     () => {
        //                         this.download(reqV);
        //                         // (navigator as any).app.exitApp();
        //                     })
        //             }
        //         } else {
        //             //未检查到更新
        //             if (activeCheck) {
        //                 this.loadingAlert();
        //             }
        //         }
        //     } else {
        //         if (activeCheck)
        //             Utils.presentToastWarning("未获取到最新版本");
        //     }
        // }, (err: any) => {
        //     Utils.presentToastWarning(JSON.stringify(err));
        //     console.log(JSON.stringify(err));
        // });
    }

    /**
     * 判断byod获得的版本号和当前版本号哪个大
     * 传入两个字符串，当前版本号：curV；比较版本号：reqV
     * 调用方法举例：compareVersion("1.1","1.2")，将返回false，提示更新
     * @param curV 当前版本号
     * @param reqV byod版本号
     * @returns {boolean} false 提示更新  true 不提示更新
     */
    compareVersion(curV: any, reqV: any): boolean {
        if (curV && reqV) {
            //将两个版本号拆成数字
            var arr1 = curV.split("-")[0].split('.'),
                arr2 = reqV.split("-")[0].split('.');
            var minLength = Math.min(arr1.length, arr2.length),
                position = 0,
                diff = 0;
            //依次比较版本号每一位大小，当对比得出结果后跳出循环（后文有简单介绍）
            while (position < minLength && ((diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0)) {
                position++;
            }
            diff = (diff != 0) ? diff : (arr1.length - arr2.length);
            //若curV大于reqV，则返回true
            return diff >= 0;
        } else {
            //输入为空
            Utils.presentToastWarning("版本号不能为空");
            return true;
        }
    }
    /**
     * 加载中
     */
    async loadingAlert() {
        const loading = await loadingController.create({
            cssClass: "my-custom-class",
            message: "正在获取最新版本",
            duration: 600,
        });
        await loading.present();
        setTimeout(() => {
            Utils.presentToastSuccess("当前已是最新版本");
            loading.dismiss();
        }, 600);
    }

    private download(ver: any) {
        ver = ver ? ver : "1.0.0";
        if (isPlatform('android')) {
            AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
                result => {
                    if (!result.hasPermission) {
                        AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(data => {
                            this.downloadFile(ver);
                        }).catch(err => {
                            Utils.presentToastWarning("取消安装");
                        });
                    } else {
                        this.downloadFile(ver);
                    }
                },
                err => {
                    AndroidPermissions.requestPermission(AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(data => {
                        this.downloadFile(ver);
                    });
                }
            );
        } else if (isPlatform('ios')) {
            //打开浏览器
            const browser = InAppBrowser.create(this.path, '_system');
            browser.show();
        }
    }

    /**
     * 手机文件下载（android）
     * @param name 文件名称
     */
    public downloadFile(name: string) {
        window.open(process.env.VUE_APP_BASE_API + "/files/jfvbmpda/JFPDA.apk")
        // if (name != null) {
        //     let downloaderRequest: DownloadRequest = {
        //         uri: encodeURI(process.env.VUE_APP_BASE_API + "/files/jfvbmpda/JFPDA.apk"),
        //         title: name,
        //         notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
        //         destinationInExternalFilesDir: {
        //             dirType: "Download",
        //             subPath: "okr.apk"
        //         }
        //     };

        //     Downloader.download(downloaderRequest)
        //         .then((location: string) => {
        //             console.log("location" + location);
        //             Utils.presentToastSuccess("下载完成");
        //         })
        //         .catch((error: any) => {
        //             console.error(error);
        //         });
        // }
    }

}