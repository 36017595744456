
import { modalController, IonPage } from "@ionic/vue";
import { ScheduleService } from "@/services/schedule/ScheduleService.ts";
import { ScheduleRelationService } from "@/services/schedule/ScheduleRelationService.ts";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { defineComponent } from "vue";
import moment from "moment";
import BaseService from "@/services/base/BaseService";
export default defineComponent({
  name: "LinkSchedule",
  props: {
    taskId: String,
    projectId: String,
    isIndependentTask: Boolean,
    linkContentList: Object,
  },
  components: {
    HeaderModal,
    SearchBar,
    IonPage,
  },
  data() {
    const pointDate = new Date();
    return {
      ScheduleService: new ScheduleService(),
      linkService: new ScheduleRelationService(),
      list: new Array(0),
      searchParams: {
        limit: 10,
        offset: 1,
        repeat:'0',
        schduleDate: "",
        scheduleName: "",
      },
      idList: new Array<any>(), //选中的id集合
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.searchParams.offset = 1;
      this.requsetData(this.searchParams, null);
    },
    fromatTime(beginTime: any, endTime: any) {
      let str = "";
      let beginTimeT = moment(beginTime).format("YYYY-MM-DD HH:mm");
      let endTimeT = moment(endTime).format("YYYY-MM-DD HH:mm");
      if (beginTimeT.substring(0, 10) == endTimeT.substring(0, 10)) {
        str =
          beginTimeT.substring(0, 10) +
          " " +
          beginTimeT.substring(11, 16) +
          "~" +
          endTimeT.substring(11, 16);
      } else {
        str = beginTimeT + "~" + endTimeT;
      }
      return str;
    },
    //输入文字搜索
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
      Utils.presentToastWarning("搜索内容不可以包含表情");
      return;
    }
      this.searchParams.scheduleName = searchParam.searchKey;
      this.initData();
    },
    //获取选择的Id集合
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        const newItem = {
          scheduleTimeId: item.scheduleTimeId,
          formId: this.taskId,
          type: 1,
          id: BaseService.newId("", ""),
        };
        this.idList.push(newItem);
      } else {
        this.idList = this.idList.filter((e) => {
          return e.targetId != item.id;
        });
      }
    },
    //下拉
    doRefresh(event: any) {
      this.searchParams.offset = 1;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页
    nextPage(event: any) {
      this.searchParams.offset++;
      this.requsetData(this.searchParams, event);
    },
    //请求数据
    requsetData(pamas: any, event: any) {
      this.ScheduleService.getMyScheduleList(this.searchParams).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              if (this.searchParams.offset == 1) {
                this.list = [];
              }
              event.target.disabled = true;
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
            console.log(this.list);
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //关闭modal
    closeModel(type: number) {
      if (type == 4) {
        //完成选择，保存数据并关闭模态
        const items = this.idList;
        if (!items || items.length == 0) {
          Utils.presentToastTopWarning("请选择至少一项关联日程");
          return;
        }
        // const newItemList = new Array();
        // for (let i = 0; i < items.length; i++) {
        //   const item = items[i];
        //   //不能包含已选
        //   if (this.linkContentList) {
        //     const itemIndex = this.linkContentList.findIndex((link: any) => {
        //       return (
        //         item.targetId == link.targetId
        //       );
        //     });
        //     if (itemIndex > -1) {
        //       Utils.presentToastTopWarning("关联内容已经存在");
        //       return;
        //     }
        //   }
        //不能包含自己
        //   if (item.targetId == this.taskId) {
        //     Utils.presentToastTopWarning("不能关联自己");
        //     return;
        //   }
        //   const newitem = new TaskLink(
        //     item.targetId,
        //     item.name,
        //     item.targetProjectId,
        //     type,
        //     this.taskId ? this.taskId : "",
        //     this.projectId ? this.projectId : "",
        //     TaskLinkContentType.Task
        //   );
        //   newItemList.push(newitem);
        // }
        //save 方法
        console.log(this.idList);

        this.linkService.taskLinkSchedule(this.idList).then((res: any) => {
          if (res) {
            // 提醒，添加成功
            Utils.presentToastSuccess("添加成功");
            //关闭页面
            modalController.dismiss({ refresh: true });
          } else {
            Utils.presentToastWarning("添加失败");
          }
        });
      } else {
        modalController.dismiss({});
      }
    },
  },
});
