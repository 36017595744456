
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { DashboardService } from "@/services/dashboardService";
import * as echarts from "echarts";
export default defineComponent({
  name: "GesamtStatus",
  components: {},
  props: {
    taskItem: Object,
    projectId: String,
    planId: Number,
  },
  data() {
    const router = useRouter();
    return {
      api: new DashboardService(),
      router: router,
      sum: 0,
    };
  },
  methods: {
    goFolder() {
      (this as any).router.push({
        path: "/dashboard/ProjectTabs",
        query: {
          folderId:
            "project@folder-" +
            (this as any).projectId +
            "-" +
            "Wochentlich Bericht",
          planId: (this as any).planId,
          projectId: (this as any).projectId,
          currentTab: "Document"
        },
      });
    },
    setMychart() {
      const elEchart = document.querySelector("#projectEchart");
      if (elEchart) {
        elEchart.removeAttribute("_echarts_instance_"); // 移除容器上的 _echarts_instance_ 属性,
        const ec = echarts as any;
        const myChart = ec.init(elEchart);
        const series = [
          {
            name: "",
            type: "pie",
            radius: ["40", "60"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              formatter: function (params: any) {
                //格式化鼠标移上去显示内容样式
                return params.value;
              },
            },
            color: ["#8e8f90", "#84bd00", "#ffc72c", "#ff8c00", "#cb333b"],
            data: [
              { value: 0, name: "1" },
              { value: 0, name: "2" },
              { value: 0, name: "3" },
              { value: 0, name: "4" },
              { value: 0, name: "5" },
            ],
            itemStyle: {
              show: false,
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ];
        this.api.getDashboardById(this.projectId).then((res) => {
          if (res.data.data) {
            const lightColor = res.data.data.color;
            this.sum =
              parseInt(lightColor.red) +
              parseInt(lightColor.redYellow) +
              parseInt(lightColor.yellow) +
              parseInt(lightColor.green) +
              parseInt(lightColor.gray);

            series[0].data[0].value = lightColor.gray || 0;
            series[0].data[1].value = lightColor.green || 0;
            series[0].data[2].value = lightColor.yellow || 0;
            series[0].data[3].value = lightColor.redYellow || 0;
            series[0].data[4].value = lightColor.red || 0;
            myChart.setOption({ series: series });
          }
        });
      }
    },
  },
  mounted() {
    this.setMychart();
  },
});
