
import { alertController } from "@ionic/vue";
import { defineComponent, onUnmounted } from "vue";
import Header from "@/components/Header.vue";
import Chat from "@/views/message/Chat.vue";
import IssuesChat from "@/views/message/IssuesChat.vue";
import { getInstance } from "@/services/MicroIssuesService";
import BaseRsp from "@/models/base/BaseRsp";
import SqlHelper from "@/utils/sql/SqlHelper";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
export default defineComponent({
  name: "ChatPage",
  components: {
    Header,
    Chat,
    IssuesChat,
  },

  created() {
    let busTag = JSON.parse(this.busTag as string);
    try {
      this.title = busTag.title;
      this.projectId = busTag.projectId;
      this.projectName = busTag.projectName;
      this.taskId = busTag.taskId;
    } catch (e) {
      console.log(e);
    }
    this.busTable = this.getBusTable();
    this.checkChatGroupIdIsExist();
    Bus.$on("the_business_is_deleted", (data: Message) => {
      if (data.chatGroupId == this.chatGroupId) {
        this.exitMessage();
      }
    });
  },
  setup() {
    onUnmounted(() => {
      Bus.$off("the_business_is_deleted");
    });
  },
  data() {
    const service = getInstance();
    const params = this.$route.params;
    const sqlHelper = new SqlHelper();
    return {
      message: params.message,
      chatGroupId: params.chatGroupId,
      busId: params.busId,
      busTable: params.busTable,
      busTag: params.busTag,
      title: "",
      projectId: "",
      projectName: "",
      taskId: "",
      pageTitle: "",
      service: service,
      isTemporary: true,
      sqlHelper: sqlHelper,
    };
  },
  methods: {
    settingChatFolderId(folderId: string) {
      let chat: any = this.$refs.chat;
      if (chat != null) chat.settingChatFolderId(folderId);
    },
    // 获取文件组id
    getFolderIdByBusInfo() {
      this.service
        .getFolderIdByBusInfo(this.busId as string, this.busTable as string)
        .then((rsp: BaseRsp) => {
          this.settingChatFolderId(rsp.data);
        });
    },
    // 检查业务是否存在
    checkChatGroupIdIsExist() {
      this.service
        .getBusIsDelete(this.busId as string, this.busTable as string)
        .then((res: any) => {
          if (res) {
            this.exitMessage();
          } else {
            this.getFolderIdByBusInfo();
          }
        });
    },
    //业务不存在提示
    async exitMessage() {
      this.sqlHelper.deleteMessageByChatGroupId(this.chatGroupId as string);
      const alert = await alertController.create({
        // header: "业务不存在",
        mode: "ios",
        message: this.pageTitle + " 已被删除",
        buttons: [
          {
            text: "确定",
            handler: () => {
              this.$router.go(-1);
            },
          },
        ],
      });
      await alert.present();
    },
    getBusTable(): string {
      let busTable = "";
      let type = "";
      // 项目：tt_project  任务：tt_task  微议题：tt_topic  日程：cal_schedule
      if (this.busTable.indexOf("project") != -1) {
        busTable = "tt_project";
        type = "（动态）";
      } else if (this.busTable.indexOf("task") != -1) {
        busTable = "tt_task";
        type = "（动态）";
      } else if (this.busTable.indexOf("topic") != -1) {
        busTable = "tt_topic";
        type = "（微议题）";
        this.isTemporary = false;
      } else if (this.busTable.indexOf("schedule") != -1) {
        busTable = "cal_schedule";
        type = "（日程）";
      }
      this.pageTitle = this.title + type;
      return busTable;
    },
  },
});
