import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
//导入页面对象
import Tabs from '@/views/tabs/Tabs.vue';
import HomeSearchCondition from '@/views/home/search/HomeSearchCondition.vue';
import HomeSearchList from '@/views/home/search/HomeSearchList.vue';
import TaskPage from '@/views/task/TaskPage.vue';
import TaskList from '@/views/task/TaskList.vue';
import MicroIssues from '@/views/microIssues/MicroIssues.vue';
import MicroIssuesInfo from '@/views/microIssues/info/MicroIssuesInfo.vue';
import MicroIssuesAdd from '@/views/microIssues/add/MicroIssuesAdd.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import CardProject from '@/views/cardProject/CardProject.vue';
import DashboardDetails from '@/views/dashboard/DashboardDetails.vue';
import ProjectTabs from '@/views/dashboard/project/projectTabs.vue';
import ProjectPage from '@/views/project/ProjectPage.vue'
import ProjectList from '@/views/project/ProjectList.vue';
import PTaskInfo from '@/views/project/ptask/task-info/PTaskInfo.vue';
import AddTask from '@/views/project/ptask/task-info/AddTask.vue'
import MemberManage from '@/views/project/menu/memberManage/MemberManage.vue'
import ProjectAdd from '@/views/project/add/ProjectAdd.vue'
import CalendarPage from '@/views/calendar/CalendarPage.vue';
import AddSchedule from '@/views/calendar/add/AddSchedule.vue';
import SettingCalendar from '@/views/calendar/setting/SettingCalendar.vue';
import AddCalendar from '@/views/calendar/add/AddCalendar.vue';
import AddCalendarOther from '@/views/calendar/add/AddCalendarOther.vue';
import AddRepeat from '@/views/calendar/add/AddRepeat.vue';
import DetailScheduleFeedback from '@/views/calendar/detail/DetailScheduleFeedback.vue';
import TeamPage from '@/views/team/TeamPage.vue';
import TeamPageAdd from '@/views/team/add/TeamPageAdd.vue';
import ChatPage from '@/views/message/ChatPage.vue'; 
import UserRoleEdit from '@/views/team/edit/UserRoleEdit.vue';
import ProjectRecycle from "@/views/project/menu/ProjectRecycle/ProjectRecycle.vue";
import MineCalendar from '@/views/mine/calendar/Calendar.vue';
import MineArchive from '@/views/mine/archive/Archive.vue';
import LoginDemo from '@/views/demo/Login.vue';
import AddOKR from '@/views/OKR/add/AddOKR.vue';
import ScoreList from '@/views/OKR/score/ScoreList.vue';
import ScoreDetail from '@/views/OKR/score/detail/ScoreDetail.vue';
import TestPreview from '@/views/OKR/TestPreview.vue';
import OKRDetails from '@/views/OKR/details/OKRDetails.vue';
import KeyResultInfo from '@/views/OKR/keyResult/KeyResultInfo.vue'
import MineRemindList from '@/views/remind/RemindPage.vue';
import OkrOrgTree from '@/views/OKR/OkrOrgTree.vue';
import LoginPage from '@/views/login/loginPage.vue';
import Helper from '@/views/helper/helper.vue';
import HelperDetails from '@/views/helper/HelperDetails.vue';
import homePage from '@/views/home/homePage.vue';
import mine from '@/views/mine/Mine.vue';
import message from  "@/views/message/Message.vue"
//路由懒加载 Language
const MineRelation = () => import('@/views/mine/relation/Relation.vue');
const MineLanguage = () => import('@/views/mine/language/Language.vue');
const MineEdit = () => import('@/views/mine/edit/Edit.vue');
const MineCollect = () => import('@/views/mine/collect/Collect.vue');
const MineTaskProject = () => import('@/views/mine/taskProject/TaskProject.vue');
const MineRecycle = () => import('@/views/mine/recycle/Recycle.vue');
const OKRPage = () => import('@/views/OKR/OKRPage.vue');
const TargrtScore = () => import('@/views/OKR/menu/TargrtScore.vue');
const EditTarget = () => import('@/views/OKR/menu/editTarget/EditTarget.vue');
const About = () => import('@/views/mine/About.vue');
const UpdatePwd = () => import('@/views/mine/UpdatePwd.vue');
const AiuiSearchCondition = () => import('@/views/aiui/AiuiSearchCondition.vue');
const AiuiSearchCondition_copy = () => import('@/views/aiui/AiuiSearchCondition_copy.vue');
const ScheculeSetting = () => import('@/views/mine/schedule/Setting.vue');
const MoreSetting = () => import('@/views/mine/more/Setting.vue');
const CachePage = () => import('@/views/mine/more/cache.vue');
const MessageSetting = () => import('@/views/mine/more/messageSetting.vue');
const AttendancePage = () => import('@/views/attendance/AttendancePage.vue');
const LeaveApplyPage = () => import('@/views/attendance/apply/leave/leaveApply.vue');
const FilterApply = () => import('@/views/attendance/apply/filter/FilterApply.vue');
const CancelLeavePage = () => import('@/views/attendance/apply/cancelLeave/cancelLeave.vue');
const TravelApply = () => import('@/views/attendance/apply/travel/travelApply.vue');
const MyAttendancePage = () => import('@/views/attendance/manage/attendance/MyAttendance.vue');
const AttendanceApply = () => import('@/views/attendance/manage/attendance/AbnormalApply.vue');
const AttendanceStatistics = () => import('@/views/attendance/statistics/staffattendance/AttendanceStatistics.vue');
const AttendanceStatisticsDetails = () => import('@/views/attendance/statistics/staffattendance/AttendanceStatisticsDetails.vue');
const OndutyApply = () => import('@/views/attendance/apply/onduty/ondutyApply.vue');
const StationingApply = () => import('@/views/attendance/apply/stationing/stationingApply.vue');
const LeaveInfo = () => import('@/views/attendance/approval/info/leaveInfo.vue');
const MyHolidayPage = () => import('@/views/attendance/manage/holiday/MyHolidayList.vue');
const MyHolidayDetailList = () => import('@/views/attendance/manage/holiday/MyHolidayDetailList.vue');
const PlaceList = () => import('@/views/attendance/station/list/PlaceList.vue');
const AttPlaceUserList = () => import('@/views/attendance/station/userlist/UserList.vue');
const Institution = () => import('@/views/attendance/manage/institution.vue')
const MyDonePage = () => import("@/views/attendance/myDone/MyDone.vue")
const TravelInfo = () => import("@/views/attendance/approval/info/travelInfo.vue")
const OndutyInfo = () => import("@/views/attendance/approval/info/ondutyInfo.vue")
const StationInfo = () => import("@/views/attendance/approval/info/stationingInfo.vue")
const CancelLeaveInfo = () => import("@/views/attendance/approval/info/cancelLeaveInfo.vue")
const AbnormalInfo = () => import("@/views/attendance/approval/info/abnormalInfo.vue")
const MeetingRoomList = () => import('@/views/meeting/MeetingRoomList.vue');
const MeetingRoomTimeBook = () => import('@/views/meeting/detail/MeetingRoomTimeBook.vue');
const VisitorList = () => import('@/views/visitor/VisitorList.vue');
const AddVisitor = () => import('@/views/visitor/AddVisitor.vue');
const VisitorDetails = () => import('@/views/visitor/VisitorDetails.vue');
const AlreadyReciverVisitor = () => import('@/views/visitor/AlreadyReciverVisitor.vue');
const MyBookListPage = () => import('@/views/meeting/MyBookListPage.vue');
const RoomBookPage = () => import('@/views/meeting/book/RoomBookPage.vue');
const RoomBookDetail = () => import('@/views/meeting/detail/RoomBookDetail.vue');
const FilterSignIn = () => import('@/views/attendance/signIn/filter/FilterSignIn.vue');
const SignInDetail = () => import('@/views/attendance/signIn/detail/SignInDetail.vue');
const OutSignInDetail = () => import('@/views/attendance/signIn/outDetail/OutSignInDetail.vue');
const ApplyStatsPage = () => import('@/views/attendance/statistics/applyStats/ApplyStatsPage.vue');
const PrivacyPolicy = () => import('@/views/mine/PrivacyPolicy.vue');
const OutSignInList = () => import('@/views/attendance/signIn/outList/OutSignInList.vue');
//仓库管理

// import putinStorageReceive from '@/views/storeManagement/putinStorageReceive.vue';
// import checkpointPutinStorage from '@/views/storeManagement/checkpointPutinStorage.vue';
// import checkPutinStorage from '@/views/storeManagement/checkPutinStorage.vue';

const checkpointPutinStorage = () => import('@/views/storeManagement/checkpointPutinStorage.vue');//检查站接收检查件
const checkPutinStorage = () => import('@/views/storeManagement/checkPutinStorage.vue');//检查件入库
const storeChoose = () => import('@/views/storeManagement/storeChoose.vue');//库位选择
const createStore = () => import('@/views/storeManagement/createStore.vue');//新建库位
const warehouseReceiving = () => import('@/views/storeManagement/warehouseReceiving.vue');//入库接收
const createOutWarehouse = () =>import('@/views/storeManagement/createOutWarehouse.vue');//创建出库单
const sweepCodeOn = () => import('@/views/storeManagement/sweepCodeOn.vue');//扫码上架
const workshopPartReception = () => import('@/views/storeManagement/workshopPartReception.vue');//车间零件接收
const partsComplement = () => import('@/views/storeManagement/partsComplement.vue');//零件补码
const partsofWarehouse = () => import('@/views/storeManagement/partsofWarehouse.vue');//零件出库
//模型 
const FilterApproval = () => import('@/views/attendance/approval/filter/FilterApproval.vue');
//模型 
import Demo from '@/views/demo/Demo.vue';
// import component from '@/views/tabs/Tabs.vue';
const scan = () => import('@/views/scan/index.vue');
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/homePage'
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/homePage'
      },
      {
        path: 'message',
        name: 'message',
        component: message
      },
      {
        path: 'homePage',
        name: 'homePage',
        component: homePage
      },
      {
        path: 'mine',
        name: 'mine',
        component: mine
      },
      
    ]
  },
  {
    path: "/login",
    component: LoginPage
  },
  {
    path: '/question',
    name: 'question',
    component: ()=>import("@/views/questionsManage/question.vue") 
  },
  {
    path: '/questionAdd',
    name: 'questionAdd',
    component: ()=>import("@/views/questionsManage/questionAdd.vue") 
  },
  {
    path: '/questionView',
    name: 'questionView',
    component: ()=>import("@/views/questionsManage/questionView.vue") 
  },
  {
    path: '/partsInfo',
    name: 'partsInfo',
    component: ()=>import("@/views/partsInfo/index.vue") 
  },
  {
    path:"/doEntrucking",
    name: 'doEntrucking',
    component: ()=>import("@/views/entrucking/doEntrucking.vue") 
  },
  {
    path:"/vehicleApplication",
    name:"vehicleApplication",
    component:()=>import("@/views/carManage/vehicleApplication.vue")
  },
  {
    path:"/createTask",
    name:"createTask",
    component:()=>import("@/views/task/createTask.vue")
  },
  {
    path:"/taskList",
    name:"taskList",
    component:TaskList
  },
  {
    path:"/vehicleList",
    name:"vehicleList",
    component:()=>import("@/views/carManage/vehicleList.vue")
  },
  {
    path:"/vehicleInfo",
    name:"vehicleInfo",
    component:()=>import("@/views/carManage/vehicleInfo.vue")
  },
  {
    path:"/carLoaded",
    name:"carLoaded",
    component:()=>import("@/views/entrucking/carLoaded.vue")
  },
  
  
  {
    path:"/templateInfo",
    name:"templateInfo",
    component:()=>import("@/views/templateInfo/index.vue")
  },
  {
    path:"/printPage",
    name:"printPage",
    component:()=>import("@/views/mine/printPage/index.vue")
  },
  
  {
    path:"/templateAdd",
    name:"templateAdd",
    component:()=>import("@/views/templateInfo/templateAdd.vue")
  },
  {
    path:"/templateCheck/:type",
    name:"templateCheck",
    component:()=>import("@/views/templateInfo/templateCheck.vue")
  },
  {
    path:"/templateCheckInfo/:id",
    name:"templateCheckInfo",
    component:()=>import("@/views/templateInfo/templateCheckInfo.vue")
  },
  {
    path:"/templateCheckInfoZz/:id",
    name:"templateCheckInfoZz",
    component:()=>import("@/views/templateInfo/templateCheckInfoZz.vue")
  },
  {
    path:"/sedExcel",
    name:"sedExcel",
    component:()=>import("@/views/templateInfo/sedExcel.vue")
  },
  {
    path:"/subPage/:name/:pathName",
    name:"subPage",
    component:()=>import("@/views/home/subPage.vue")
  },
  {
    path:"/selectStage",
    name:"selectStage",
    component:()=>import("@/views/questionsManage/selectStage.vue")
  },
  {
    path:"/selectStageManage/:type",
    name:"selectStageManage",
    component:()=>import("@/views/questionsManage/selectStageManage.vue")
  },
  {
    path:"/questionManage/:type",
    name:"questionManage",
    component:()=>import("@/views/questionsManage/questionManage.vue")
  },
  
  {
    path: '/dashboard/projectTabs',
    component: ProjectTabs
  },
  {
    path: '/home',
    component: Tabs
  },
  {
    path: '/demo',
    component: Demo
  },
  {
    path: '/home/search/condition',
    name: 'HomeSearchCondition',
    component: HomeSearchCondition
  },
  {
    path: '/aiui/AiuiSearchCondition',
    name: 'AiuiSearchCondition',
    component: AiuiSearchCondition
  },
  {
    path: '/aiui/AiuiSearchCondition_copy',
    name: 'AiuiSearchCondition_copy',
    component: AiuiSearchCondition_copy
  },
  {
    path: '/home/search/list',
    name: 'HomeSearchList',
    component: HomeSearchList
  },
  {
    path: '/task-page',
    component: TaskPage
  },

  {
    path: '/project',
    component: ProjectList
  },
  {
    path: '/microIssues',
    component: MicroIssues
  },
  {
    name: 'MicroIssuesInfo',
    path: '/microIssues/info',
    component: MicroIssuesInfo
  },
  {
    name: "MicroIssuesAdd",
    path: '/microIssues/add',
    component: MicroIssuesAdd
  },
  {
    path: '/dashboard',
    component: Dashboard
  },
  {
    path: '/cardProject',
    component: CardProject
  },
  {
    path: '/dashboardDetails',
    component: DashboardDetails,
  },
  {
    path: '/ProjectPage',
    component: ProjectPage
  },
  {
    path: '/ProjectPage/PTaskInfo',
    component: PTaskInfo
  },
  {
    path: '/ProjectPage/PTaskInfo/AddTask',
    name: 'edit-task',
    component: AddTask
  },
  {
    path: '/project/member-manage',
    component: MemberManage
  },
  {
    path: '/project/add',
    component: ProjectAdd
  },
  {
    path: '/calendar',
    component: CalendarPage
  },
  {
    path: '/schedule/add',
    name: 'add-schedule',
    component: AddSchedule
  },
  {
    path: '/calendar/setting',
    name: 'setting-calendar',
    component: SettingCalendar
  },
  {
    path: '/calendar/add',
    name: 'add-calendar',
    component: AddCalendar
  },
  {
    path: '/calendar/add/other',
    name: 'add-calendar-other',
    component: AddCalendarOther
  },
  {
    path: '/calendar/add/repeat',
    name: 'add-repeat',
    component: AddRepeat
  },
  {
    path: '/schedule/detail/feedback',
    name: 'detail-schedule-feedback',
    component: DetailScheduleFeedback
  },
  {
    path: '/team',
    name: 'team',
    component: TeamPage
  },
  {
    path: '/team/add',
    name: 'teamAdd',
    component: TeamPageAdd
  },
  {
    name: 'ChatPage',
    path: '/ChatPage',
    component: ChatPage
  },
  {
    path: '/team/member-edit',
    name: 'teamMemberEdit',
    component: UserRoleEdit
  },
  {
    path: '/MineRelation',
    name: 'MineRelation',
    component: MineRelation,
  },
  {
    path: '/MineLanguage',
    name: 'MineLanguage',
    component: MineLanguage,
  },
  {
    path: '/MineEdit',
    name: 'MineEdit',
    component: MineEdit,
  },
  {
    path: '/MineCollect',
    name: 'MineCollect',
    component: MineCollect,
  },
  {
    path: '/MineTaskProject',
    name: 'MineTaskProject',
    component: MineTaskProject,
  },
  {
    path: '/MineRecycle',
    name: 'MineRecycle',
    component: MineRecycle,
  },
  {
    path: '/MineCalendar',
    name: 'MineCalendar',
    component: MineCalendar,
  },
  {
    path: '/MineArchive',
    name: 'MineArchive',
    component: MineArchive
  },
  {
    path: '/project/recycle-manage',
    name: 'prject-recycle',
    component: ProjectRecycle,
  },
  {
    path: '/OKR',
    name: 'OKR',
    component: OKRPage
  },
  {
    path: '/okr/toscore',
    component: TargrtScore
  },
  {
    path: '/OKR/add',
    name: 'addOKR',
    component: AddOKR,
  },
  {
    path: '/score/list',
    name: 'score-list',
    component: ScoreList
  },
  {
    path: '/score/detail',
    name: 'score-detail',
    component: ScoreDetail
  },
  {
    path: '/test/preview',
    name: 'TestPreview',
    component: TestPreview
  },
  {
    path: '/OKR/details',
    name: 'OKRDetails',
    component: OKRDetails,
  },
  {
    path: '/OKR/KeyResultInfo',
    name: 'KeyResultInfo',
    component: KeyResultInfo,
  },
  {
    path: '/OKR/details/editTarget',
    name: 'EditTarget',
    component: EditTarget,
  },
  {
    path: "/About",
    name: 'About',
    component: About,
  },
  {
    path: "/UpdatePwd",
    name: 'UpdatePwd',
    component: UpdatePwd,
  },
  {
    path: "/ScheculeSetting",
    name: "ScheculeSetting",
    component: ScheculeSetting
  },
  {
    path: "/MoreSetting",
    name: "MoreSetting",
    component: MoreSetting
  },
  {
    path: "/MineRemindList",
    name: "MineRemindList",
    component: MineRemindList
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: AttendancePage
  }, {
    path: "/OkrOrgTree",
    name: "OkrOrgTree",
    component: OkrOrgTree
  }, {
    path: "/CachePage",
    name: "CachePage",
    component: CachePage
  }, {
    path: "/MessageSetting",
    name: "MessageSetting",
    component: MessageSetting
  }, {
    path: "/LeaveApplyPage",
    name: "LeaveApplyPage",
    component: LeaveApplyPage
  }, {
    path: "/FilterApply",
    name: "FilterApply",
    component: FilterApply
  }, {
    path: "/LeaveInfo",
    name: "LeaveInfo",
    component: LeaveInfo
  }, {
    path: "/TravelInfo",
    name: "TravelInfo",
    component: TravelInfo
  },
  {
    path: "/OndutyInfo",
    name: "OndutyInfo",
    component: OndutyInfo
  },
  {
    path: "/StationInfo",
    name: "StationInfo",
    component: StationInfo
  },
  {
    path: "/CancelLeaveInfo",
    name: "CancelLeaveInfo",
    component: CancelLeaveInfo
  }, {
    path: "/AbnormalInfo",
    name: "AbnormalInfo",
    component: AbnormalInfo
  },
  {
    path: "/CancelLeavePage",
    name: "CancelLeavePage",
    component: CancelLeavePage
  }, {
    path: "/TravelApply",
    name: "TravelApply",
    component: TravelApply
  }, {
    path: "/OndutyApply",
    name: "OndutyApply",
    component: OndutyApply
  }, {
    path: "/StationingApply",
    name: "StationingApply",
    component: StationingApply
  }, {
    path: "/FilterApproval",
    name: "FilterApproval",
    component: FilterApproval
  }, {
    path: "/att/place",
    component: PlaceList
  }, {
    path: "/att/place/user",
    component: AttPlaceUserList
  }, {
    path: "/MyAttendancePage",
    name: "MyAttendancePage",
    component: MyAttendancePage
  }, {
    path: "/AttendanceApply",
    name: "AttendanceApply",
    component: AttendanceApply
  },
  {
    path: "/attendancestatistics",
    name: "AttendanceStatistics",
    component: AttendanceStatistics
  },
  {
    path: "/AttendanceStatisticsDetails",
    name: "AttendanceStatisticsDetails",
    component: AttendanceStatisticsDetails
  },
  {
    path: "/MyHolidayPage",
    name: "MyHolidayPage",
    component: MyHolidayPage
  },
  {
    path: "/MyHolidayDetailList",
    name: "MyHolidayDetailList",
    component: MyHolidayDetailList
  }, {
    path: "/Institution",
    name: "Institution",
    component: Institution
  }, {
    path: "/MyDonePage",
    name: "MyDonePage",
    component: MyDonePage
  },
  {
    path: "/meeting",
    name: "MeetingRoomList",
    component: MeetingRoomList
  },
  {
    path: "/meeting-timebook",
    name: "MeetingRoomTimeBook",
    component: MeetingRoomTimeBook
  },
  {
    path: "/visitor",
    name: "VisitorList",
    component: VisitorList
  },
  {
    path: "/addvisitor",
    name: "AddVisitor",
    component: AddVisitor
  },
  {
    path: "/visitordetails",
    name: "VisitorDetails",
    component: VisitorDetails
  },
  {
    path: "/alreadyrecivervisitor",
    name: "AlreadyReciverVisitor",
    component: AlreadyReciverVisitor
  },
  {
    path: "/MyBookListPage",
    name: "MyBookListPage",
    component: MyBookListPage
  },
  {
    path: "/FilterSignIn",
    name: "FilterSignIn",
    component: FilterSignIn
  },
  {
    path: "/SignInDetail",
    name: "SignInDetail",
    component: SignInDetail
  },
  {
    path: "/OutSignInDetail",
    name: "OutSignInDetail",
    component: OutSignInDetail
  }, {
    path: "/RoomBookPage",
    name: "RoomBookPage",
    component: RoomBookPage
  }, {
    path: "/RoomBookDetail",
    name: "RoomBookDetail",
    component: RoomBookDetail
  },
  {
    path: "/ApplyStatsPage",
    name: "ApplyStatsPage",
    component: ApplyStatsPage
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  }, {
    path: "/OutSignInList",
    name: "OutSignInList",
    component: OutSignInList
  }, {
    path: "/Helper",
    name: "Helper",
    component: Helper
  }, {
    path: "/HelperDetails",
    name: "HelperDetails",
    component: HelperDetails
  },
  {
    path: '/checkpointPutinStorage',
    name: 'checkpointPutinStorage',
    component: checkpointPutinStorage
  },
  {
    path: '/checkPutinStorage',
    name: 'checkPutinStorage',
    component: checkPutinStorage
  },
  {
    path: '/storeChoose',
    name: 'storeChoose',
    component: storeChoose
  },
  {
    path: '/createStore',
    name: 'createStore',
    component: createStore
  },
  {
    path: '/warehouseReceiving',
    name: 'warehouseReceiving',
    component: warehouseReceiving
  },
  {
    path: '/createOutWarehouse',
    name: 'createOutWarehouse',
    component: createOutWarehouse
  },
  
  {
    path: '/sweepCodeOn',
    name: 'sweepCodeOn',
    component: sweepCodeOn
  },
  {
    path: '/workshopPartReception',
    name: 'workshopPartReception',
    component: workshopPartReception
  },
  {
    path: '/partsComplement',
    name: 'partsComplement',
    component: partsComplement
  },
  {
    path: '/partsofWarehouse',
    name: 'partsofWarehouse',
    component: partsofWarehouse
  },
  {
    path: '/scan',
    name: 'scan',
    component: scan
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
