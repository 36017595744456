import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "calendarList" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "no_data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")
  const _component_ion_refresher = _resolveComponent("ion-refresher")
  const _component_SubscribeCard = _resolveComponent("SubscribeCard")
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, { class: "ion-page" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          _createVNode("div", _hoisted_1, [
            (_ctx.canlendarList.length > 0)
              ? (_openBlock(), _createBlock("div", _hoisted_2, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.canlendarList, (item, index) => {
                    return (_openBlock(), _createBlock(_component_SubscribeCard, {
                      key: index,
                      showCheckBox: false,
                      showStatus: true,
                      SubscribeCardObj: item
                    }, null, 8, ["SubscribeCardObj"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("", true),
            (_ctx.canlendarList.length <= 0)
              ? (_openBlock(), _createBlock("div", _hoisted_3, "暂无数据"))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_ion_infinite_scroll, {
            threshold: "50px",
            onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextPage($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, { loadingSpinner: "bubbles" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}