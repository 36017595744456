
import { modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import TargetCards from "@/components/ListCard/TargetCards.vue";
import { useRouter } from "vue-router";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import SystemUtils from "@/utils/SystemUtils";
export default defineComponent({
  name: "TargetList",
  components: { TargetCards, CardLoading },
  watch: {
    $route(to, from) {
      if (to.path == "/MineRecycle") {
        this.onInit();
      }
    }
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      objectiveService: new ObjectiveService(),
      targetList: new Array<any>(),
      searchParams: {
        limit: 10,
        offset: 1,
        creatorIds: [SystemUtils.loginUser.id],
      },
      islist: true,
      showNoDate: false,
    };
  },
  props: {
    isHomeModule: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRecycle: Boolean,
  },
  mounted() {
    this.onInit();
  },
  methods: {
    //页面初始化
    onInit() {
      this.resetPage();
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.targetList = res;
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    //刷新
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        if (result) {
          this.targetList = this.targetList.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //请求数据
    getListRequest(callback: any) {
      let promise: Promise<any>;
      if (this.isRecycle) {
        promise = this.objectiveService.getRecycleObjectiveList(this.searchParams);
      } else {
        promise = this.objectiveService.objectiveList(this.searchParams);
      }
      promise
        .then((res: any) => {
          this.islist = false;
          callback(res);
        })
        .catch(() => {
          this.islist = false;
        });
    },
    openTargetInfo(item: any) {
      this.router.push({
        path: "/OKR/details",
        query: item,
      });
    },

    searchHandler(searchName: any) {
      this.searchParams = Object.assign(this.searchParams, {name: searchName.searchKey});
      this.resetPage();
    },
  },
});
