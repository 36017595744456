/**
 *组织中台 api
 */
import OrganizationBaseService from '../base/OrganizationBaseService';
/**
 * 组织
 */
export class OrganizationApi extends OrganizationBaseService {

    constructor() {
        super("department");
    }
    //根据ID查询部门信息
    public getChildOrgsList(params: any): Promise<any> {
       const path = 'org/children';
       return this.request(path,this.rest.METHOD_GET, params);
    }
    //全部的部门信息
    public getAllChildOrgsList(): Promise<any> {
        const path = 'org/getTreeModel';
        return this.request(path,this.rest.METHOD_GET);
     }

    //根据部门ID查询部门下员工的信息
    public getDepartmentStaffList(params: any): Promise<any> {
        const path = 'org-user/page';
        return this.request(path,this.rest.METHOD_GET, params);
     }
     
    //新建项目获取所属部门
    public getChildOrgsInfo(params: any): Promise<any> {
        const path = 'org/child-orgs-info';
        return this.request(path,this.rest.METHOD_GET, params);
     }
     //获取用户信息
    public getUserInfo(params: any): Promise<any> {
        const path = 'user/login-user/'+params.userId;
        return this.request(path,this.rest.METHOD_GET, null);
     }
}