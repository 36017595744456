
import { useRouter, useRoute } from "vue-router";
import { defineComponent } from "vue";
import { alertController, modalController } from "@ionic/vue";
import Utils from "@/utils/Utils";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import { log } from "console";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import SelectTaskList from "../../../../views/calendar/component/SelectTask.vue";
import { OkrTaskListInfo } from "../../details/OkrTaskListInfo";
import { nextTick } from "process";
export default defineComponent({
  name: "TaskList",
  components: { TaskCards, CardLoading, modalController },
  watch: {},
  props: {
    sourceId: String,
    isDetails: Boolean,
  },
  emits: ["on-click"],
  data() {
    const router = useRouter();
    return {
      router: router,
      objectiveService: new ObjectiveService(),
      taskList: new Array<any>(),
      searchParams: {
        limit: 10,
        offset: 1,
        sourceId: this.sourceId,
        sourceType: 1,
      },
      islist: true,
      showNoDate: false,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.resetPage();
    },
    //重新从第一页获取
    resetPage(e?: any) {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.taskList = res;
        this.showNoDate = res == null || res.length <= 0;
        if (e) e.target.complete();
      });
    },
    //刷新
    doRefresh(event: any) {
      this.resetPage(event);
      // nextTick(() => {
      //   event.target.complete();
      // });
    },
    //下一页
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        if (result) {
          this.taskList = this.taskList.concat(result);
        }
        event.target.complete();
      });
    },
    //请求数据
    getListRequest(callback: any) {
      this.objectiveService
        .objectivelinkTaskList(this.searchParams)
        .then((res: any) => {
          this.islist = false;
          callback(res);
        })
        .catch(() => {
          this.islist = false;
        });
    },
    openTask(item: any) {
      this.router.push({
        path: "/task-page",
        query: {
          id: item.id,
          taskName: item.name,
        },
      });
    },
    async cancelRelevancy(item: any) {
      let params = {
        id: item.linkId,
      };
      const alert = await alertController.create({
        header: "取消关联",
        message: "您确定要取消关联此任务吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
          },
          {
            text: "确定",
            handler: () => {
              this.objectiveService
                .cancelKeyTask(params)
                .then((res: any) => {
                  if (res) {
                    this.resetPage();
                  }
                })
                .catch((e: any) => {
                  console.log(e);
                });
            },
          },
        ],
      });
      await alert.present();
    },
    routeRefresh(sourceId: any) {
      if (sourceId) {
        this.searchParams.sourceId = sourceId;
      }
    },

    getId() {
      const id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );

      return id;
    },

    async edieKeyTask() {
      let list = new Array();
      this.taskList.forEach((ele: any) => {
        list.push({ id: ele.id, name: ele.text });
      });
      const modal = await modalController.create({
        component: SelectTaskList,
        cssClass: "notice-modal-class",
        componentProps: {
          paramList: { list: list },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedItem) {
        let taskList = new Array<OkrTaskListInfo>();
        data.selectedItem.forEach((ele: any) => {
          let okrTaskList = new OkrTaskListInfo();
          okrTaskList.id = this.getId();
          okrTaskList.sourceId = this.sourceId;
          okrTaskList.creator = ele.creator;
          okrTaskList.modifierTime = ele.modifyTime;
          okrTaskList.systemId = ele.systemId;
          okrTaskList.enterpriseId = ele.enterpriseId;
          okrTaskList.sourceType = 1;
          okrTaskList.taskId = ele.id;
          okrTaskList.remark = ele.remark;
          okrTaskList.isDeleted = ele.isDeleted;
          okrTaskList.createTime = ele.createTime;
          okrTaskList.modifierId = ele.modifierId;
          okrTaskList.creatorId = ele.creatorId;
          okrTaskList.modifier = ele.modifier;
          taskList.push(okrTaskList);
        });

        // this.okrLinkTaskParams.list = taskList;

        this.objectiveService
          .objectiveAddLinkTaskList(taskList, this.sourceId as string)
          .then(
            (r: any) => {
              if (r) {
                if (r) {
                  this.resetPage();
                }
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
      }
    },
  },
});
