import User from '@/models/task/User'
import CookieHelper from './CookieHelper';
import moment from "moment";

export enum ELanguage {
    cn = 'cn', en = 'en', de = 'de'
}

export default class SystemUtils {

    public static systemId: any = "task";

    /**
     * 获取当前用户
     */
    private static _loginUser: User | null = null;
    public static get loginUser() {
        if (SystemUtils._loginUser == null) {
            if (CookieHelper.get("task_system_loginuser")) {
                const _u = CookieHelper.get("task_system_loginuser");
                SystemUtils._loginUser = _u == null ? null : JSON.parse(_u);
            }
            if (SystemUtils._loginUser == null) {
                SystemUtils._loginUser = {
                    id: "",
                    pernr: '000',
                    gesch: '0',
                    department: '000',
                    name: '',
                    userColor: "#00a2ae",
                    groupName: "000000",
                    shortPy: "000000",
                    photo: "",
                    email: "",
                    autograph: "",
                    orgName: "",
                    orgId: "",
                    phone: "",
                    account:""
                };
            }


        }

        return SystemUtils._loginUser;
    }
    public static set loginUser(user: User) {
        SystemUtils._loginUser = user;
    }

    public static get ACCESS_TOKEN() {
        return CookieHelper.get("Authorization");
    }
    /**
     * 获取指定用户的颜色
     */
    public static getUserColor(userId: string) {
        return "bgOrangeC";
    }
    /**
     * 取当前语言
     */
    private static _language: string | null = '';
    public static get language(): ELanguage {
        if (!SystemUtils._language) {
            SystemUtils._language = CookieHelper.get("TaskCenterLanguage");
        }
        if (SystemUtils._language) {
            return SystemUtils._language as ELanguage;
        } else {
            return ELanguage.cn;
        }
    }
    /**
     * 设置前语言
     */
    public static set language(value: ELanguage) {
        SystemUtils._language = value;
    }

    //人员名字截取,包含英文取前两个并转化为大写，全是汉字取后2个
    public static subStringUserName(userName: string, len: number): string {
        let retName = "";
        if (userName != null && userName != undefined && userName.length > 0) {
            if (/^[a-zA-Z]/.test(userName)) { //存在英文
                retName = userName.substring(0, len).toUpperCase();
            } else { //全是中文
                retName = userName.substring(userName.length - len, userName.length);
            }
        }
        return retName;
    }

    //判断任务操作类型,如下类型之一，返回true;用于控制任务编辑页是否关闭
    public static IsTaskOperate(type: string): boolean {
        if (type && (type == "restore" || type == "clear" || type == "delete" || type == "toRoot" || type == "toSub" || type == "move")) {
            return true;
        }
        return false;
    }

    /**
     * 获取指示灯颜色的class
     */
    public static getProjctLighClass(light: any) {
        let returnClass = "";
        switch (light) {
            case 1:
                returnClass = "projectLightGreen";
                break;
            case 2:
                returnClass = "projectLightYellow";
                break;
            case 3:
                returnClass = "projectLightRedYellow";
                break;
            case 4:
                returnClass = "projectLightRed";
                break;
            case '2':
                returnClass = "projectLightYellow";
                break;
        }
        return returnClass;
    }

    /**
     * 获取指示灯颜色的class
     */
    public static getProjctLighColorClass(light: any) {
        let returnClass = "";
        switch (light) {
            case 1:
                returnClass = "projectLightColorGreen";
                break;
            case 2:
                returnClass = "projectLightColorYellow";
                break;
            case 3:
                returnClass = "projectLightColorRedYellow";
                break;
            case 4:
                returnClass = "projectLightColorRed";
                break;
        }
        return returnClass;
    }

    public static fromatTime(time: any) {
        let result = "";
        let fromatTime = moment(time).format("YYYY-MM-DD HH:mm");

        let today = new Date();
        let todayFormat = moment(today).format("YYYY-MM-DD HH:mm");
        let timeTemp = new Date(time);

        let dayToday = today.getDay() || 7;
        let mondayYestody = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
        let mondayYestodyFormat = moment(mondayYestody).format("YYYY-MM-DD HH:mm");
        let mondayThisWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1 - dayToday);
        let mondayLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1 - dayToday - 7);
        let weekList = ["周日", "周一", "周二", "周三", "周四", "周五", "周六", "周日"];
        let day = timeTemp.getDay() || 7;
        if (fromatTime.substring(0, 10) == todayFormat.substring(0, 10)) {
            result = "今天" + " " + fromatTime.substring(11, 16);
        }
        else if (fromatTime.substring(0, 10) == mondayYestodyFormat.substring(0, 10)) {
            result = "昨天" + " " + fromatTime.substring(11, 16);
        }
        else if (timeTemp.getTime() >= mondayThisWeek.getTime()) {
            result = weekList[day] + " " + fromatTime.substring(11, 16);
        }
        else if (timeTemp.getTime() >= mondayLastWeek.getTime() && timeTemp.getTime() <= mondayThisWeek.getTime()) {
            result = "上" + weekList[day] + " " + fromatTime.substring(11, 16);
        }
        else {
            result = moment(time).format("YYYY-MM-DD HH:mm");
        }

        return result;
    }
}