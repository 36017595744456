
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

//组件
import CalendarCard from "../component/CalendarCard.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import Utils from "@/utils/Utils";
import { CalendarService } from "@/services/calendar/CalendarService.ts";
import { CalendarSubscribeService } from "@/services/calendar/CalendarSubscribeService.ts";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "MyCalendarSetting",
  components: {
    CalendarCard,
  },
  data() {
    const router = useRouter();
    const createCalendar = [
      {
        name: "",
        info: "",
        type: "",
        id: "create",
      },
    ];
    return {
      router: router,
      canlendarList: new Array(0),
      searchParams: {
        limit: 10,
        offset: 1,
        calName: "",
      },
      createCalendar: createCalendar,
      shareUserList: [], //选择的分享人list
      calendarService: new CalendarService(),
      calendarSubscribeService: new CalendarSubscribeService(),
      userId: "",
      showShare: true,
    };
  },
  props: {
    refresh: Boolean, //是否刷新页面
    params: Object, //搜索条件
  },
  created() {},
  mounted() {
    if (this.params != undefined && this.params.searchKey != undefined) {
      this.searchParams.calName = this.params.searchKey;
    }
    this.initData();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        if (this.params != undefined && this.params.searchKey != undefined) {
          this.searchParams.calName = this.params.searchKey;
        }
        // this.searchParams.name=this.searchValue+"";
        this.resetPage();
      }
    },
  },
  methods: {
    initData() {
      const currentUser: any = SystemUtils.loginUser;
      this.userId = currentUser.id;
      this.resetPage();
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.canlendarList = res;
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.canlendarList = this.canlendarList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
          //  event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.calendarService.getPageMyCal(this.searchParams).then((res: any) => {
        callback(res.data);
      });
    },
    //选择分享人
    async share(item: any) {
      let that = this;
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data && data.data.length > 0) {
        let that = this;
        //不能分享给自己
        let list = data.data.filter((ele: any) => {
          return ele.userId != that.userId;
        });
        if (list.length == data.data.length) {
          that.shareUserList = data.data;
          that.calendarSubscribe(item.item.id);
        } else {
          Utils.presentToastWarning("请不要分享给自己。请重新选择分享人员。");
        }
      }
    },
    //日历分享
    calendarSubscribe(calId: String) {
      let that = this;
      let params = new Array();
      for (let i = 0; i < that.shareUserList.length; i++) {
        let user: any = that.shareUserList[i];
        let t = {
          id: Utils.getUuid,
          calId: calId,
          userId: user.id,
          userName: user.name,
          workUserId: this.userId,
          status: 1,
          sourceType: 2,
        };
        params.push(t);
      }
      that.calendarSubscribeService.insertList(params).then((res: any) => {
        if (res) {
          Utils.presentToastSuccess("分享成功");
          // that.resetPage();
        }
      });
    },
    //编辑
    editItem(item: any) {
      const info = {
        id: item.item.id,
      };
      this.router.push({
        name: "add-calendar",
        params: {
          info: JSON.stringify(info),
        },
      });
    },
    //删除
    deleteItem(item: any) {
      let that = this;
      Utils.confirmDialog(
        "确认",
        "如果该日历下有日程，将会解除日程与该日历的关联。确认要删除吗？",
        function () {
          let params = { id: item.item.id };
          that.calendarService.deleteById(params).then((res: any) => {
            if (res) {
              if (res.data) {
                Utils.presentToastSuccess("删除成功");
                that.resetPage();
              } else {
                Utils.presentToastSuccess("删除失败");
              }
            }
          });
        }
      );
    },
    //创建新的日历
    create() {
      const info = {
        // rootId: this.task.rootId,
        // orgId: this.task.orgId,
        action: "create",
      };
      this.router.push({
        name: "add-calendar",
        params: {
          info: JSON.stringify(info),
        },
      });
    },
  },
});
