import BaseRsp from "@/models/base/BaseRsp";
import MsgBaseService from "../base/MsgBaseService";

/**
 * 首页
 */
export class ChatMembersApi extends MsgBaseService{

    constructor() {
        super("chatmembers");
    }

    /**
     * 根据聊天组获取聊成员 
     * @param param 
     */
    public getChatMembersByGroupId(param: any): BaseRsp {
        const path = 'getChatMembersByGroupId';
        return this.request(path, this.rest.METHOD_GET,param);
    }
}