/**
 * 消息接口
 */
import BaseRsp from '@/models/base/BaseRsp';
import Message from '@/models/message/Message';
import Setting from '@/rest/Setting';
import { resolve } from 'path';
import MsgBaseService from '../base/MsgBaseService';
/**
 * 首页
 */
export class MessagesApi extends MsgBaseService {

    constructor() {
        super("messages");
    }

    /**
     * 获取通知数量 
     * @param param userId messageState systemId
     */
    public getNoticeCount(param: any): Promise<any> {
        const path = 'getNoticeCount';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 根据条件标记为已读消息
     * @param param 
     */
    public updateMessageStateByCondition(param: any) {
        const path = 'updateMessageStateByCondition';
        return this.request(path, this.rest.METHOD_PUT, param);
    }

    /**
    * 消息发送
    * @param param 
    */
    public send(params: any): Promise<any> {
        const path = 'send';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    /**
    * 获取列表
    * @param id 
    */
    public getMessageList(groupId: string, userId: string, params: any) {
        return this.request("app/" + groupId + "/" + userId, this.rest.METHOD_GET, params);
    }

    /**
     * 获取消息列表，根据开始、结束时间
     * @param params 参数
     */
    public getMessageListByTime(params: any) {
        const path = "getMessageByUserIdAndTime";
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 获取mq地址与端口
     */
    public getMqttAddress(): Promise<BaseRsp> {
        if (Setting.mqttIp && Setting.mqttIp.length > 0) {
            let ret = new BaseRsp();
            ret.data = [{ "host": Setting.mqttIp, "port": Setting.mqttPort }];
            return new Promise<BaseRsp>((resolve, reject) => {
                resolve(ret);
            })
        } else {
            const path = 'getMqttAddress';
            return this.request(path, this.rest.METHOD_GET);
        }
    }

    /**
     * 更新消息状态为已读
     * @param groupId 聊天组id
     */
    public updateMessageState(groupId: string) {
        return this.request("update/" + groupId, this.rest.METHOD_PUT);
    }
}