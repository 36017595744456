
//正则验证
export default class RegExps {

    // 手机号正则验证
    public static phoneReg = /^1(3|4|5|6|7|8|9)\d{9}$/;

    // 密码正则验证
    public static pwdReg = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,30}$/;

    //身份证号正则验证
    public static idNumberReg =/^[1-9]\d{18}[0-9Xx]$/

    //出生日期正则验证
    public static birthdayReg = /^(18|19|20|21)\d{2}-((0[1-9])|(1[0-2]))-(([0-2][1-9])|10|20|30|31)$/;

    //邮箱地址正则验证
    public static emailReg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

    //年龄正则验证
    public static ageReg =  /^(([0-9]|[1-9][1-9]|1[0-7][0-9])(\\.[0-9]+)?|180)$/;
   
    //只能输入汉字和英文字母
    public static SpecChar = /[`~!#$%^&*()_\+=<>?:"{}|~！#￥%……&*（）={}|《》？：“”【】、；‘’，。、\s+]/;
}
