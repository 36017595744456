<template>
  <ion-app style="background: none transparent">
    <router-view v-if="menuRole=='dd'&&flagLoaded||menuRole=='phone'&&flagLoaded" />
    <audio id="audio">
      <source src="./assets/source/signsuccess.mp3" type="audio/mpeg" />
    </audio>
  </ion-app>
  <!-- <AiuiBtn
    ref="aiuiBtn"
    @onFloatBtnClicked="onFloatBtnClicked"
    @clearMsg="clearMsg"
    :unreadCount="unreadCount"
    v-if="isShowBtn"
  ></AiuiBtn> -->
</template>

<script>
import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/vue";
import { Packet } from "mqtt";
import { defineComponent } from "vue";
import MessageClient, {
  DataChangeMessageEventHandler,
} from "./services/message/MessageClient";
import SystemUtils from "./utils/SystemUtils";
import Bus from "@/utils/bus";
import SqlHelper from "./utils/sql/SqlHelper";
import { VersionService } from "@/services/VersionService";
import Setting from "./rest/Setting";
import { StatusBar, Style } from "@capacitor/status-bar";
import { getInstance } from "@/services/message/MessageService";
import LocationHelper from "@/utils/LocationHelper";
import moment from "moment";
import Utils from "./utils/Utils";
import AiuiBtn from "@/components/AiuiBtn.vue";
import CookieHelper from "./utils/CookieHelper";
import { useRouter, useRoute } from "vue-router";
import { nextTick } from "process";
import JpushHelper from "@/utils/JpushHelper";
import * as dd from "dingtalk-jsapi";
import { getUserId, pdaSiteMap } from "@/api/login/login";
// declare function requireMp3(str:string): string;
// const mp3 = requireMp3('./assets/source/signsuccess.mp3');

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    AiuiBtn,
  },
  data() {
    const userId = SystemUtils.loginUser ? SystemUtils.loginUser.id : "";
    const client = MessageClient.mqttClient(userId, SystemUtils.systemId);
    const dataChangeMessageEventHandler = {};
    const sqlHelper = new SqlHelper();
    const messageService = getInstance();
    const mqttCheckInterval = null;
    return {
      client: client,
      chatMessageEventHandler: dataChangeMessageEventHandler,
      remindMessageEventHandler: dataChangeMessageEventHandler,
      sqlHelper: sqlHelper,
      messageService: messageService,
      versionService: new VersionService(),
      isStatusBarLight: false,
      statusBarBackgroundColor: "#ffffff",
      isFirst: true,
      mqttCheckInterval: mqttCheckInterval,
      $refs: {
        aiuiBtn: AiuiBtn,
      },
      isShowBtn: false,
      router: useRouter(),
      unreadCount: 0,
      msg: "",
      clickDate: new Date(),
      flagLoaded:false,
      menuRole:'dd'
    };
  },
  watch: {
    $route(to, from) {
      // to表示的是你要去的那个组件，from 表示的是你从哪个组件过来的
      if (to.fullPath == "/login") {
        this.isShowBtn = false;
      }
    },
  },
  async created() {
    document.addEventListener("deviceready", function () {}, false);
    const _this = this;
    if (this.$route.query.corpId) {
      localStorage.setItem("corpId", String(this.$route.query.corpId));
       localStorage.setItem("menuRole", "dd");
       _this.menuRole = 'dd'
      CookieHelper.save({
        key: Setting.authCookieName,
        value: `{"access_token":"1"}`,
      });
      dd.ready(function () {
        var list = window.location.search.indexOf('&')>-1?window.location.search.split("&"):[window.location.search];
        var corpId;
        var agentId;
          corpId = list[0].split('=')[1]||''
          agentId=list[1]?list[1].split('=')[1]:"";
          localStorage.setItem("agentId", agentId);
        dd.runtime.permission
          .requestAuthCode({
            corpId: corpId//window.location.search.split("=")[1], // 企业id
          })
          .then((res) => {
            getUserId({ code: res.code,agentId:agentId }).then(async(resp) => {
              if (!resp.data.isError && resp.data.data.isSuccess) {
                CookieHelper.save({
                  key: Setting.authCookieName,
                  value: `{"access_token":"1"}`,
                });
                SystemUtils.loginUser = resp.data.data.resultData;
               await _this.getMenu(resp.data.data.resultData.id);
               localStorage.setItem("menuList", "");
               _this.flagLoaded = true
                CookieHelper.save({
                  key: "task_system_loginuser",
                  value: JSON.stringify(resp.data.data.resultData),
                });
              } else {
                Utils.presentToastTopWarning(
                  resp.data.message || resp.data.data.message,
                  "danger"
                );
              }
            });
          });
      });
    }else{
       localStorage.setItem("menuRole", "phone");
       this.menuRole = 'phone'
       this.flagLoaded = true
    }
    const token = CookieHelper.get(Setting.authCookieName);
    if (!token || token.length == 0 || token == "null") {
      this.isShowBtn = false;
    } else {
      this.isShowBtn = true;
    }
    const userId = SystemUtils.loginUser ? SystemUtils.loginUser.id : "";
    this.changeStatusBar();
    this.initFrontBack();
    // this.initGoBack();
    // JpushHelper.buildJpush(userId);

    //手机 并且 byod版本检查更新
    if (isPlatform("capacitor")) this.checkAppVersion();
    
  },
  methods: {
    changeStatusBar() {
      if (isPlatform("capacitor")) {
        StatusBar.setStyle({
          style: this.isStatusBarLight ? Style.Dark : Style.Light,
        });
        StatusBar.setBackgroundColor({
          color: this.statusBarBackgroundColor,
        });
      }
    },
    getMenu(id) {
      return new Promise((resolve) => {
        pdaSiteMap({ userId: id }).then((res) => {
          console.log(res);
          localStorage.setItem(
            "loginRole",
            JSON.stringify(res.data.data.resultData)
          );
          resolve();
        });
      });
    },
    getUser(info) {
      let code = info.code; // 通过该免登授权码可以获取用户身份
    },

    createMQTTClient() {
      let userId_ = SystemUtils.loginUser ? SystemUtils.loginUser.id : "";
      if (this.client) {
        this.client.events.clear();
        MessageClient.mqttClose();
      }
      this.client = MessageClient.mqttClient(userId_, SystemUtils.systemId);

      this.chatMessageEventHandler = new DataChangeMessageEventHandler(
        this,
        this.chatMessageCallback,
        this.client.chatTopic
      );
      this.remindMessageEventHandler = new DataChangeMessageEventHandler(
        this,
        this.chatMessageCallback,
        this.client.remindTopic
      );
      this.client.events.add(this.chatMessageEventHandler);
      this.client.events.add(this.remindMessageEventHandler);

      if (this.mqttCheckInterval != null) {
        clearInterval(this.mqttCheckInterval);
      }

      // 每 5 秒检查 mqtt 连接状态，变化后发消息
      // TODO : 最好修改为会用 MqttClient 的事件，而
      this.mqttCheckInterval = setInterval(() => {
        if (Utils.mqttConnected != this.client.isConnected()) {
          Utils.mqttConnected = this.client.isConnected();
          console.info(
            "mqtt connection status changed to: " + Utils.mqttConnected
          );
          Bus.$emit("receive_mq_connected", Utils.mqttConnected);
        }
      }, 5000);
    },

    getNetMessageListByTime() {
      this.sqlHelper
        .selectNewestMessageTime()
        .then(
          (data) => {
            if (data) {
              let endTime = moment().format("YYYY-MM-DD HH:mm:ss");
              this.messageService.getNetMessageListByTime(1, data, endTime);
            }
          },
          (err) => {}
        )
        .catch((err) => {});
    },
    chatMessageCallback(topic, data, packet) {
      console.log("topic init: " + JSON.stringify(data));
      let userId = SystemUtils.loginUser.id;
      if (data.toUserId != userId) return;
      if (this.client.chatTopic == topic) {
        console.log("message init: " + JSON.stringify(data));
        let tmp = JSON.parse(data.content);
        data.content = tmp.msg;
        console.log("message content: " + JSON.stringify(data.content));
        if (data.content.indexOf("物理删除") != -1) {
          Bus.$emit("the_business_is_deleted", data);
        } else {
          data.messageType = tmp.msgType;
          if (tmp.file) data.file = tmp.file;
          if (data.sendUserId == SystemUtils.loginUser.id)
            data.messageState = 2;
          let busTag = JSON.parse(data.busTag);
          data.title = busTag.title;
          // console.log("message: " + JSON.stringify(data));
          // 保存本地数据库
          this.sqlHelper.insertMessageData(data);
          // 发送中心消息
          Bus.$emit("receive_message", data); // Chat组件消息通知
          Bus.$emit("receive_issues_message", data); // IssuesChat组件消息通知
          Bus.$emit("receive_home_message"); // 首页左侧消息通知
          Bus.$emit("receive_message_home", data); //首页我的事项消息通知
          Bus.$emit("receive_message_schedule", data); //首页我的日程消息通知，首页-日历：我的日历、他人日历
        }
      } else if (this.client.remindTopic == topic) {
        this.unreadCount++;
        let name = "";
        if (JSON.parse(data.busTag)["taskName"]) {
          name = "，任务名称是：" + JSON.parse(data.busTag)["taskName"];
        } else if (JSON.parse(data.busTag)["krName"]) {
          name = "，目标名称是：" + JSON.parse(data.busTag)["krName"];
        } else if (JSON.parse(data.busTag)["projectName"]) {
          name = "，项目名称是：" + JSON.parse(data.busTag)["projectName"];
        }
        this.msg =
          "您收到的最新一条消息是:" + JSON.parse(data.content).msg + name;
        Bus.$emit("receive_remind_message", data); //首页-左上角-提醒
        Bus.$emit("receive_remind_home_message", data); //首页-提醒
        let audio = document.getElementById("audio");
        if (audio) audio.play();
        setTimeout(() => {
          // TextToSpeech.speak(this.msg);
          // this.$refs.aiuiBtn.switchAction();
          Utils.textTospeech("您有一个新消息提醒");
        }, 500);
      }
    },
    checkAppVersion() {
      this.versionService.checkAppVersion();
    },
    initFrontBack() {
      LocationHelper.locationIntervalStatus = 0; //关闭
      //前台
      document.addEventListener(
        "resume",
        () => {
          LocationHelper.startLocationInterval();
        },
        false
      );
      //后台
      document.addEventListener(
        "pause",
        () => {
          LocationHelper.clearLocationInterval();
        },
        false
      );
    },
    onFloatBtnClicked() {
      let diffDate = this.getDifference(this.clickDate);
      this.clickDate = new Date();
      if (diffDate < 1000) {
        this.$refs.aiuiBtn.switchAction("操作太过频繁");
      } else {
        // this.$refs.aiuiBtn.switchAction(this.msg);
        Utils.textTospeech(this.msg);
        this.router.push({
          path: "/Helper",
        });
        nextTick(() => {
          this.clearMsg();
        });
      }
      // Utils.presentToastCenterWarning("点击了小助手");
    },
    clearMsg(unCount) {
      this.msg = "";
    },
    // 计算返回时间差（毫秒）
    getDifference(beforeDate) {
      let myDate = new Date();
      return (
        (myDate.getDay() - beforeDate.getDay()) * 24 * 60 * 60 * 1000 +
        (myDate.getHours() - beforeDate.getHours()) * 60 * 60 * 1000 +
        (myDate.getMinutes() - beforeDate.getMinutes()) * 60 * 1000 +
        (myDate.getSeconds() - beforeDate.getSeconds()) * 1000 +
        (myDate.getMilliseconds() - beforeDate.getMilliseconds())
      );
    },
  },
});
</script>
<style>
html {
  font-size: 7.68px !important;
}
</style>
