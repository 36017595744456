
import { modalController, IonPage, isPlatform } from "@ionic/vue";
import Header from "@/components/modal/components/HeaderModal.vue";
import { AccountService } from "@/services/login/accountService.ts";
import CookieHelper from "@/utils/CookieHelper";
import SystemUtils from "@/utils/SystemUtils";
import Bus from "@/utils/bus";
import { defineComponent } from "vue";

import { httpGet } from "@/rest/IonicHttp";
import User from "@/models/task/User";
import { useRouter } from "vue-router";
import Setting from "@/rest/Setting";

export default defineComponent({
  name: "LoginDemo",
  components: {
    IonPage,
    Header,
  },
  data() {
    const router = useRouter();
    return {
      loginid: "QianPeiqing@csvw.com",
      api: new AccountService(),
      router: router,
      userInfo: {},
      userList: new Array(),
      msg: "",
    };
  },
  created() {
    this.userList = [
      {
        name: "QianPeiqing@csvw.com",
      },
      {
        name: "ChengLixing@CSVW.COM",
      },
    ];
    if (!Setting.isSVW) {
      this.userList = this.userList.concat([
        {
          name: "wanglijunbj@bingosoft.net",
        },
        {
          name: "zhoumr@bingosoft.net",
        },
        {
          name: "lixs@bingosoft.net",
        },
      ]);
    }
  },
  mounted() {
    this.userInfo = SystemUtils.loginUser;
  },
  methods: {
    bindCheckUser(value: any) {
      this.loginid = value;
    },
    post() {
      this.api
        .login(this.loginid)
        .then((success:any) => {
          if (!success) {
            this.msg = "登录失败";
          } else {
            const user: User = SystemUtils.loginUser;
            this.userInfo = user;
            // ☆☆☆☆☆ 登录成功，SystemUtils.loginUser能取得当前用户信息后调用以下代码
            // ☆☆☆☆☆ Bus.$emit("login_successful");
            Bus.$emit("login_successful");
            //跳转的时候  关闭弹出框
            modalController.dismiss({ loginSuccess: true });
          }
        })
        .catch((error:any) => {
          alert(JSON.stringify(error));
        });
    },
    tunnel() {
      httpGet("http://10.122.27.84:85/api/")
        .then((e) => {
          alert(JSON.stringify(e));
        })
        .catch((e) => {
          alert(JSON.stringify(e));
        });
    },
    byod() {
      if (isPlatform("ios")) {
        let appConnect = (window as any).AppConnectCordova;
        appConnect.initialize();
        document.addEventListener("appconnect.isReady", (event) => {
          if (appConnect.isReady()) {
            switch (appConnect.authState()) {
              case 0:
                alert(JSON.stringify(appConnect.config().email));
                break;
              case 1:
                alert(appConnect.authMessage());
                break;
              case 2:
                alert(appConnect.authMessage());
                break;
              default:
                break;
            }
          } else {
            console.error("byod error: ");
            alert("BYOD 登录失败。");
          }
        });
      } else if (isPlatform("android")) {
        (window as any).ACConfigPlugin.setConfigHandler((result: any) => {
          alert(result);
          if (!result) {
            alert("BYOD 登录失败");
          } else {
            let temp = result.substring(1, result.length - 1);
            let t = temp.split(",");
            let email = "";
            let emailKey = "email";
            for (let i = 0; i < t.length; i++) {
              if (t[i].indexOf(emailKey) != -1) {
                email = t[i].substring(
                  t[i].indexOf(emailKey) + emailKey.length + 1,
                  t[i].length
                );
              }
            }
            alert(email);
          }
        });
        (window as any).ACConfigPlugin.requestConfig();
      } else {
        alert("请使用登录 POST");
      }
    },
    getLoginUser() {
      this.api
        .getUserInfo()
        .then((value) => {
          const user: User = SystemUtils.loginUser;
          this.userInfo = user;
          // ☆☆☆☆☆ 登录成功，SystemUtils.loginUser能取得当前用户信息后调用以下代码
          // ☆☆☆☆☆ Bus.$emit("login_successful");
          Bus.$emit("login_successful");
        })
        .catch((error) => {
          this.msg = "获取登录用户信息出错";
        });
    },
    closeModel(type: any) {
      modalController.dismiss({ userInfo: this.userInfo });
    },
  },
});
