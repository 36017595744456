
import {
  modalController,
  IonPage,
  IonList,
  IonItem,
  IonIcon,
  IonInput,
  IonRadioGroup,
  IonRadio,
  alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import BaseService from "@/services/base/BaseService";
import { getInstance } from "@/services/TagLinkService";
import { Toast } from "vant";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "SelectLableDetails",
  components: {
    IonPage,
    IonList,
    IonItem,
    IonIcon,
    IonInput,
    IonRadioGroup,
    IonRadio,
    HeaderModal,
  },
  data() {
    const tagLinkService = getInstance();
    return {
      labelName: "",
      labelColor: "#E62412",
      options: [
        { labelName: "红色", labelColor: "#E62412" },
        { labelName: "蓝色", labelColor: "#1B9AEE" },
        { labelName: "紫色", labelColor: "#6A70B8" },
        { labelName: "绿色", labelColor: "#15AD31" },
        { labelName: "黄色", labelColor: "#FA8C15" },
        { labelName: "青色", labelColor: "#009C95" },
      ],
      isDelete: false,
      labelList1: new Array(0),

      tagLinkService: tagLinkService,

      baseService: new BaseService(),
    };
  },
  props: {
    datas: Object,
  },
  mounted() {
    this.loadData();
  },
  // created() {},
  methods: {
    loadData() {
      if (this.datas != null) {
        if (this.datas.type == "edit") {
          this.labelName = this.datas.item.name;
          this.labelColor = this.datas.item.color;
        }
      }
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type == 3) {
        modalController.dismiss();
      } else if (type == 4) {
        if (this.labelName != null && this.labelColor != null) {
          if (this.datas != null) {
            if (this.datas.type == "add") {
              this.labelName = this.baseService.trim(this.labelName);
              if (!this.labelName || this.labelName.length <= 0) {
                Utils.presentToast("请填写标签名称");
                return;
              }
              if (Utils.isInCludeEmoji(this.labelName)) {
                Utils.presentToastWarning("标签名称不可以包含表情");
                return;
              }
              const modelJson: any = this.baseService.newModel("task", "tag");
              if (this.datas) {
                modelJson.groupId = this.datas.groupId;
                modelJson.systemId = "task";
                modelJson.isDeletedGroup = false;
                modelJson.color = this.labelColor;
                modelJson.name = this.labelName;
              }

              this.tagLinkService.addTag(modelJson).then(
                (r: any) => {
                  if (r) {
                    modalController.dismiss({
                      tag: r.data,
                      isDelete: this.isDelete,
                    });
                  }
                },
                (e: any) => {
                  console.log(e);
                }
              );
            } else {
              this.labelName = this.baseService.trim(this.labelName);
              if (!this.labelName || this.labelName.length <= 0) {
                Utils.presentToast("请填写标签名称");
                return;
              }
              if (Utils.isInCludeEmoji(this.labelName)) {
                Utils.presentToastWarning("标签名称不可以包含表情");
                return;
              }
              const modelJson: any = this.baseService.newModel("task", "tag");
              if (this.datas) {
                modelJson.id = this.datas.item.id;
                modelJson.groupId = this.datas.groupId;
                modelJson.systemId = "task";
                modelJson.isDeletedGroup = false;
                modelJson.color = this.labelColor;
                modelJson.name = this.labelName;
              }

              this.tagLinkService.updateTag(modelJson).then(
                (r: any) => {
                  if (r) {
                    if (!r.data) {
                      Toast.fail("更新标签失败，请检查");
                      return;
                    }

                    if (this.datas) {
                      this.datas.item.color = this.labelColor;
                      this.datas.item.name = this.labelName;

                      modalController.dismiss({
                        item: this.datas.item,
                        isDelete: this.isDelete,
                      });
                    }
                  }
                },
                (e: any) => {
                  console.log(e);
                }
              );
            }
          }
        }
      }
    },
    closeModal() {
      modalController.dismiss();
    },
    selectItem(item: any) {
      this.labelColor = item;
    },
    async delete1() {
      //  handleButtonClick() {

      // }
      const alert = await alertController.create({
        header: "删除标签",
        message: "确定要删除这个标签吗?",
        mode: "ios",
        buttons: [
          { text: "取消" },
          {
            text: "确定",
            handler: () => {
              // console.log(this.datas.labelList);

              if (this.datas) {
                const params = {
                  id: this.datas.item.id,
                };

                this.tagLinkService.deleteTag(params).then(
                  (r: any) => {
                    if (r) {
                      if (!r.data) {
                        Toast.fail("删除标签失败，请检查");
                        return;
                      }
                      console.log(r.data);

                      if (this.datas) {
                        this.datas.labelList.splice(this.datas.i, 1);

                        modalController.dismiss({
                          labelList: this.datas.labelList,
                          isDelete: true,
                        });
                      }
                    }
                  },
                  (e: any) => {
                    console.log(e);
                  }
                );
              }

              //未修改的
              // if (this.datas != null) {
              //   this.labelList1 = this.datas.labelList;
              //   this.labelList1.splice(this.datas.i, 1);
              // }

              // console.log(this.labelList1);

              // modalController.dismiss({
              //   isDelete: true,
              //   labelList: this.labelList1,
              // });
            },
          },
        ],
      });

      await alert.present();
    },
  },
});
