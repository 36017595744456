
import { alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import SubscribeApproveCard from "../component/SubscribeApproveCard.vue";
import { CalendarSubscribeApplyService } from "@/services/calendar/CalendarSubscribeApplyService";
import SubscribeApplyInfo from "@/models/calendar/SubscribeApplyInfo";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "MySubscribeSetting",
  components: {
    SubscribeApproveCard,
    alertController,
  },
  data() {
    const router = useRouter();

    const createCalendar = [
      {
        name: "",
        info: "",
        type: "",
        userid: "create",
      },
    ];
    return {
      router: router,
      canlendarList: new Array(0),
      applyService: new CalendarSubscribeApplyService(),
      searchParams: {
        limit: 10,
        offset: 1,
        searchKey: "",
      },
      createCalendar: createCalendar,
      rejectReason: "",
      submitModel: new SubscribeApplyInfo(),
      isClick: true,
    };
  },
  props: {
    refresh: Boolean, //是否刷新页面
    params: Object, //搜索条件
  },
  created() {},
  mounted() {
    if (this.params != undefined && this.params.searchKey != undefined) {
      this.searchParams.searchKey = this.params.searchKey;
    }
    this.initData();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        if (this.params != undefined && this.params.searchKey != undefined) {
          this.searchParams.searchKey = this.params.searchKey;
        }
        // this.searchParams.name=this.searchValue+"";
        this.resetPage();
      }
    },
  },
  methods: {
    initData() {
      this.resetPage();
    },
    bindResultEmit(r: any) {
      if (r) {
        //选择时间结果
        if (r.type == 0) {
          console.log(r.value);
        }
        //更多
        if (r.type == 1) {
          console.log(r.value);
        }
      }
    },
    //重新从第一页获取
    resetPage(event?: any) {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.isClick = true;
        this.canlendarList = res;
        if (event) {
          event.target.complete();
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        event.target.complete();
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            this.canlendarList = this.canlendarList.concat(result);
          }
        }
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    doRefresh(event: any) {
      this.resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.applyService
        .getCalApplyVoList(this.searchParams)
        .then((res: any) => {
          if (res && res.data) {
            callback(res.data);
          }
        });
    },
    //同意
    agreeHandler(item: SubscribeApplyInfo) {
      this.submitModel.applyId = item.applyId;
      this.submitModel.calId = item.calId;
      this.submitModel.calName = item.calName;
      this.submitModel.color = item.color;
      this.submitModel.userId = item.userId;
      this.submitModel.userName = item.userName;
      this.submitModel.status = 1;
      this.submitApprove();
    },
    //拒绝
    rejectHandler(item: SubscribeApplyInfo) {
      // console.log(JSON.stringify(item));
      this.submitModel.applyId = item.applyId;
      this.submitModel.calId = item.calId;
      this.submitModel.calName = item.calName;
      this.submitModel.color = item.color;
      this.submitModel.userId = item.userId;
      this.submitModel.userName = item.userName;
      this.submitModel.status = 2;
      this.presentAlertPrompt();
    },
    async presentAlertPrompt() {
      let that = this;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "请输入原因",
        mode: "ios",
        inputs: [
          {
            placeholder: "请输入原因",
            type: "textarea",
            value: that.rejectReason,
            cssClass: "specialClass",
            attributes: {
              rows: 10,
              maxlength: 200,
              inputmode: "textarea",
            },
          },
        ],
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: (e: any) => {
              that.rejectReason = e[0];
              let charFlag = false;
              if (that.rejectReason.replace(/\s+/g, "") == "") {
                Utils.presentToastWarning("请输入原因");
                charFlag = true;
              }
              if (charFlag) {
                that.presentAlertPrompt();
              } else {
                that.submitModel.rejectReason = that.rejectReason;
                that.submitApprove();
              }
            },
          },
        ],
      });
      return alert.present();
    },
    //审核提交
    submitApprove() {
      if (this.isClick) {
        this.isClick = false;
        this.applyService.approve(this.submitModel).then((res: any) => {
          if (res) {
            this.rejectReason = "";
            this.submitModel.rejectReason = this.rejectReason;
            this.resetPage();
            Utils.presentToastSuccess("操作成功");
          }
        });
      }
    },
  },
});
