/**
 * 提醒 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class WarningApi extends TaskBaseService {
    constructor() {
        super("warning");
    }
    // 获取模块
    public getModules(): Promise<any> {
        const path = 'modeules';
        return this.request(path, this.rest.METHOD_GET, null);
    }
    //根据模块获取未读消息列表
    public getListByModules(params:any): Promise<any> {
        const path = 'list/bymodeule';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //更新为已读状态
    public readstausChange(params:any): Promise<any> {
        const path = 'readstaus/change?id='+params.id;
        return this.request(path, this.rest.METHOD_PUT, {});
    }
     //获取未读消息数量
     public getUnreadCount(): Promise<any> {
        const path = 'unReadCount';
        return this.request(path, this.rest.METHOD_GET, {});
    }
}