
import { modalController, IonPage, IonItem, IonCheckbox } from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "../../components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { getInstance } from "@/services/project/projectService";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "../../utils/Utils";

export default defineComponent({
  name: "SelectExecutorByProject",
  components: {
    IonPage,
    HeaderModal,
    IonItem,
    SearchBar,
    IonCheckbox,
    AvatarList,
  },
  data() {
    const projectTeamUserService = getInstance();
    return {
      //项目所有人员
      executorAllList: new Array(0),
      //记录旧的成员
      executorAllOldList: new Array(0),
      //选中的人员集合
      executorSelectList: new Array(0),
      //根据项目ID获取成员的接口service
      projectTeamUserService: projectTeamUserService,

      //搜索框中的值
      searchStr: "",
      //页数
      pageNo: 1,
      //人员头像管理
      users: new Array<any>(),
    };
  },
  props: {
    //传递需要的参数为(userId:用户唯一ID // name:名称//photo:头像//pernr:用户的员工编号//department:用户所属部门)
    data: Object,
  },
  created() {
    if (this.data) {
      console.log(this.data.executorList);

      //加载组织架构列表
      this.refreshList(this.data.projectId);
      if (this.data.executorList) {
        for (let index = 0; index < this.data.executorList.length; index++) {
          const element = this.data.executorList[index];
          this.executorSelectList.push(
            Object.assign(element, { isChecked: true })
          );
        }
      }
    }
  },
  methods: {
    avatarData(memberObj: any) {
      let that = this;
      that.users = [];
      if (!memberObj) {
        return;
      }
      memberObj.forEach(function (item: any, index: number) {
        let obj = { name: item.name, img: item.photo, id: item.userId };
        that.users.push(obj);
      });
      return this.users;
    },
    avatarDataAllList(item: any) {
      let obj = { name: item.name, img: "", id: item.userId };
      return obj;
    },
    clickCheckRow(userInfo: any) {
      const indexSelected = this.executorSelectList.findIndex((item) => {
        return item.userId == userInfo.userId;
      });
      const allIndex = this.executorAllList.findIndex((a) => {
        return a.userId == userInfo.userId;
      });
      const ck = this.executorAllList[allIndex].isChecked;
      if (indexSelected > -1) {
        this.executorAllList[allIndex].isChecked = false;
      } else {
        this.executorAllList[allIndex].isChecked = true;
      }
    },
    //搜索框查询
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchStr = searchParam.searchKey;
      if (this.data) {
        this.refreshList(this.data.projectId);
      }
    },

    doRefresh(event: any) {
      if (this.data) {
        this.refreshList(this.data.projectId);
      }
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    refreshList(projectId: any) {
      this.pageNo = 1;
      const params = {
        offset: this.pageNo,
        limit: 10,
        menberName: this.searchStr,
        projectId: projectId,
      };

      this.projectTeamUserService.getMemberByProjectIdApp(params).then(
        (r: any) => {
          if (r) {
            this.executorAllList = r;
            for (
              let index = 0;
              index < this.executorSelectList.length;
              index++
            ) {
              const element = this.executorSelectList[index];

              const allIndex = this.executorAllList.findIndex((a) => {
                return a.userId == element.userId;
              });

              if (allIndex > -1) {
                const temObje = this.executorAllList[allIndex];
                temObje.isChecked = true;
                this.executorAllList.splice(allIndex, 1, temObje);
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //上拉加载
    nextPage(event: any) {
      this.pageNo++;
      if (this.data) {
        const params = {
          offset: this.pageNo,
          limit: 10,
          menberName: this.searchStr,
          projectId: this.data.projectId,
        };

        this.projectTeamUserService.getMemberByProjectIdApp(params).then(
          (r: any) => {
            if (r) {
              this.executorAllList = this.executorAllList.concat(r);

              for (
                let index = 0;
                index < this.executorSelectList.length;
                index++
              ) {
                const element = this.executorSelectList[index];

                const allIndex = this.executorAllList.findIndex((a) => {
                  return a.userId == element.userId;
                });

                if (allIndex > -1) {
                  const temObje = this.executorAllList[allIndex];
                  temObje.isChecked = true;
                  this.executorAllList.splice(allIndex, 1, temObje);
                }
              }

              event.target.complete();
              if (r) {
                if (r == null || r.length <= 0) {
                  event.target.disabled = true;
                }
              }
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },

    isChecked(index: any) {
      if (this.executorSelectList.length != 0) {
        for (
          let position = 0;
          position < this.executorSelectList.length;
          position++
        ) {
          const element1 = this.executorSelectList[position];
          if (this.executorAllList[index].userId == element1.userId) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type == 3) {
        modalController.dismiss({});
      } else if (type == 4) {
        modalController.dismiss({
          //回调数据为(id//  userId:用户唯一ID // name:名称//photo:头像//pernr:用户的员工编号//department:用户所属部门)
          data: this.executorSelectList,
        });
      }
    },
    SelectStaffInfo(ev: any, index: number) {
      const checkObj = this.executorAllList[index];
      checkObj.isChecked = ev.detail.checked;
      if (checkObj.isChecked) {
        const allIndex = this.executorSelectList.findIndex((a) => {
          return a.userId == checkObj.userId;
        });
        if (allIndex <= -1) {
          this.executorSelectList.push(checkObj);
        }
      } else {
        for (
          let index1 = 0;
          index1 < this.executorSelectList.length;
          index1++
        ) {
          const element = this.executorSelectList[index1];
          if (element.userId == this.executorAllList[index].userId) {
            this.executorSelectList.splice(index1, 1);
            break;
          }
        }
      }
    },

    removeSelect(position: any) {
      const allIndex = this.executorAllList.findIndex((a) => {
        return a.userId == this.executorSelectList[position].userId;
      });
      if (allIndex > -1) {
        this.executorAllList[allIndex].isChecked = false;
      }
      this.executorSelectList.splice(position, 1);
    },
  },
});
