/**
 * 文件
 */
import FileBaseService from '../base/FileBaseService';
import { SingleFile } from '../../models/file/Storage';
/**
 * 首页
 */
export class StorageApi extends FileBaseService {

    constructor() {
        super("storages");
    }

    /**
     * 单文件上传
     * @param params SingleFile
     */
    public singleUploadFile(params: SingleFile, localPath: string): Promise<any> {
        const path = 'singleUploadFile';
        return this.request(path, this.rest.METHOD_UPLOAD, params, localPath, "file");
    }

    /**
     * 获取单文件上传地址
     */
    public getSingleUploadFile(): string {
        const path = 'singleUploadFile';
        return this.getUrl(path);
    }

    /**
     * 获取分片上传地址
     */
    public getChunkUploadFile(): string {
        const path = 'upload';
        return this.getUrl(path);
    }

    /**
     * 聊天文件上传
     * @param file 文件流对象
     */
    public chatUploadFile(localPath: string): Promise<any> {
        const path = 'chatUploadFile';
        return this.request(path, this.rest.METHOD_UPLOAD, {}, localPath, "file");
    }

    /**
     * 分片检查
     * @param parameterMap 
     */
    public checkchunk(parameterMap: any): Promise<any> {
        const path = 'checkchunk';
        return this.request(path, this.rest.METHOD_POST, parameterMap);
    }

    /**
     * 文件检查
     * @param documentFile 
     */
    public checkfile(documentFile: any): Promise<any> {
        const path = 'checkfile';
        return this.request(path, this.rest.METHOD_POST, documentFile);
    }

    /**
     * 分片合并
     * @param uploadFile 
     */
    public merge(uploadFile: any): Promise<any> {
        const path = 'merge';
        return this.request(path, this.rest.METHOD_POST, uploadFile);
    }

    /**
     * 聊天文件上传地址
     */
    public getChatUploadFile(): string {
        const path = 'chatUploadFile';
        return this.getUrl(path);
    }

    /**
     * 文件下载
     * @param fileId 文件唯一标识
     * @param storagePath 下载文件存放地址
     */
    public downloadFile(fileId: string, storagePath: string): Promise<any> {
        const path = 'download/file/' + fileId;
        return this.request(path, this.rest.METHOD_DOWNLOAD, {}, storagePath);
    }

    /**
     * 获取下载文件地址
     */
    public getDownloadFile(fileId: string): string {
        const path = 'download/file/' + fileId;
        return this.getUrl(path);
    }

    /**
     * 文件预览
     * @param fileId 文件唯一标识
     */
    public getPreviewFile(fileId: string): string {
        const path = 'preview/file/' + fileId;
        return this.getUrl(path);
    }

    /**
     * 图片预览(缩略图)
     */
    public getPreviewImage(): string {
        const path = 'preview/image';
        return this.getUrl(path);
    }

    /**
     * 下载缩略图
     * @param fileId 
     * @param storagePath 
     */
    public getPreviewImageByFileId(fileId: string, storagePath: string): Promise<any> {
        const path = 'preview/image/' + fileId;
        return this.request(path, this.rest.METHOD_DOWNLOAD, {}, storagePath);
    }

    /**
     * 文件预览-原图
     * @param fileId 文件唯一标识
     */
    public getPreviewFileByFileId(fileId: string, storagePath: string): Promise<any> {
        const path = 'preview/file/' + fileId;
        return this.request(path, this.rest.METHOD_DOWNLOAD, {}, storagePath);
    }
}