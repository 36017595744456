import { ScheduleApi } from '@/api/calendar/ScheduleApi';

import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';

/**
 * 日程模块
 */
export default class extends BaseService {
    private  api = new ScheduleApi();

    // 他人日历-人员列表
    public getSubscribeUserList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getSubscribeUserList().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 他人日历 --日程列表
    
    public getOtherScheduleList(params:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getOtherScheduleList(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

     /**
     * 日程每日待办数量：按月
     */
    public getOtherCountByMonth(parms: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getOtherCountByMonth(parms).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

}