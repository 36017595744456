
import { defineComponent } from "vue";
import milestone from './milestone.vue'
export default defineComponent({
  name: "milestoneNode",
  data() {
    return {
    };
  },
  components: {
    milestone,
  },
  methods: {
  },
});
