
import { defineComponent } from "vue";
import { popoverController, modalController } from "@ionic/vue";
import AvatarList from "@/components/AvatarList.vue";
import moment from "moment";

export default defineComponent({
  name: "CalendarCard",
  components: { AvatarList },
  props: {
    itemList: Object,
    ItemType: String,
    index: Number,
  },
  emits: ["on-show-item", "on-detail"],
  data() {
    return {};
  },
  methods: {
    avatarDataAllList(item: any) {
      let obj = { name: item.senderName, img: "", id: item.senderId };
      return obj;
    },
    formatCreateTime(time: any) {
      return moment(time).format("yyyy/MM/DD HH:mm");
    },
    detailHandler(item: any) {
      this.$emit("on-detail", { item: item, index: this.index });
    },
  },
});
