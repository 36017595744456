
import { IonProgressBar } from "@ionic/vue";
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "ProjectCss",
  components: {
    IonProgressBar,
  },
  props: {
    ProjectObj: Object,
  },
  data() {
    return {
      sourceText: "",
    };
  },
  created() {
    var width = document.body.scrollWidth;
    if (width >= 360) {
      //this.sourceText ="来源："
      this.sourceText = "";
    }
  },
  methods: {
    subStringText(name: string) {
      if (!name) {
        return "";
      }
      if (name.length > 4) {
        return Utils.subStringText(name, 4);
      } else {
        return name;
      }
    },
    numFilter(value: any) {
      // 截取当前数据到小数点后三位
      let tempVal = parseFloat(value).toFixed(3);
      let realVal = tempVal.substring(0, tempVal.length - 1);
      return realVal;
    },
  },
});
