
import { popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import OrgTree from "@/components/OrgTree.vue";
import TargetNamePopover from "@/views/OKR/selectPullDown/TargetNamePopover.vue";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import { nextTick } from "process";
import moment from "moment";
export default defineComponent({
  name: "OkrOrgTree",
  components: {
    TargetNamePopover,
    OrgTree,
    Header,
  },
  props: {
    targetObj: {
      type: Object,
      default: {
        id: "",
        name: "",
      },
    },
  },
  data() {
    const router = useRouter();
    const second = { color: "#888", align: "center", lineHeight: 30 };
    return {
      router: router,
      objectiveService: new ObjectiveService(),
      errorData: true,
      mTargetObj: this.targetObj,
      rootData: {
        name: this.targetObj.name,
        id: "root",
        label: {
          backgroundColor: "#F4F4F4",
          borderRadius: 5,
          formatter: "{first|{b}}",
          rich: {
            first: {
              backgroundColor: "#078E34",
              color: "#fff",
              align: "center",
              width: 155,
              height: 35,
              borderRadius: 5,
              padding: 1,
              ellipsis: "...",
              overflow: "truncate",
            },
            second: second,
          },
        },
        children: new Array(),
        clickCallback: function (e: any) {
          console.log(e.data);
          let id = e.data.id;
          if (id != "root") {
            router.push({
              path: "/OKR/details",
              query: {
                id: id,
              },
            });
          }
          console.log("clickCallback");
        },
      },
      treeData: [],
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    //页面初始化
    onInit() {
      this.errorData = true;
      this.getListRequest((res: any) => {
        if (res) {
          nextTick(() => {
            this.errorData = false;
            let r = JSON.stringify(res);
            // this.rootData.children=this.treeData;
            this.rootData.children = JSON.parse(
              r.replace(new RegExp("left", "gm"), "center")
            );
            this.scrollToLeft();
          }, 500);
        } else {
          this.errorData = true;
        }
      });
    },
    // 滚动条向左滚动到居中
    scrollToLeft() {
      nextTick(() => {
        let clientWidth = document.body.clientWidth;
        const view: any = document.querySelector("#orgTreeContent");
        if (view != null) {
          const ele: Element = view;
          ele.scrollTo({
            left: (ele.scrollWidth - clientWidth) / 2,
          });
        }
      }, 200);
    },
    //请求数据
    getListRequest(callback: any) {
      this.objectiveService
        .getObjectiveTree(this.mTargetObj.id)
        .then((res: any) => {
          callback(res);
        })
        .catch(() => {
          callback(null);
        });
    },
    //路由返回
    bindLeftOperate() {
      // this.router.replace("OKR");
      this.router.go(-1);
    },
    // 设置周期等数据
    setDatachange(targetObj: any) {
      this.mTargetObj = targetObj;
      this.rootData.name = this.mTargetObj.name;
      this.onInit();
    },
  },
});
