import TaskBaseService from '../base/TaskBaseService';
/**
 * 任务进度
 */
export class TaskScheduleApi extends TaskBaseService {

    constructor() {
        super("taskProgress");
    }

    //保存
    public save(params: any): Promise<any> {
        const path = 'insertTaskProgress';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    //获取列表
    public list(params: any): Promise<any> {
        const path = 'getTaskProgressList';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //根据用户和日期获取当天填写的任务记录
    public listByUserAndDate(params: any): Promise<any> {
        const path = 'listByUserAndDate';
        return this.request(path, this.rest.METHOD_GET, params);
    }
     //工时填报批量保存
     public saveBatchList(params: any): Promise<any> {
        const path = 'saveBatchList?taskId='+params.taskId;
        return this.request(path, this.rest.METHOD_POST, params.taskProgress );
    }
    //删除
    public clearById(id: string): Promise<any> {
        const path = id;
        return this.request(path, this.rest.METHOD_DELETE);
    }

}