import User from '@/models/task/User';
import {Rest} from '@/rest/rest';
import Setting from '@/rest/Setting';
import CookieHelper from '@/utils/CookieHelper';
import {State} from '@/utils/state';
import SystemUtils from '@/utils/SystemUtils';
import Utils, {transformRequest} from '@/utils/Utils';

export interface Service {
    //前缀，默认使用setting中的apiBaseURL
    apiBaseURL?: string;
    /**
     * 服务名称
     */
    name?: string;
    /**
     * 版本号
     */
    version?: string;
    /**
     * 资源
     */
    resource?: string;
}

export default class CoreService {
    protected rest = new Rest();
    public service: Service = {
        version: "v1"
    };

    constructor(service?: Service) {
        if (service) {
            Object.assign(this.service, service);
        }
        if (this.service.version == null || this.service.version == undefined) {
            this.service.version = "v1";
        }
    }

    //用于短信发送类型区分，0:管理端应用，1：员工端应用，2：管理端网站
    public terminal: string = '0';
    //登录方式
    public grantTypePwd: string = 'password';
    public grantTypeCode: string = 'sms_code';
    //默认登录方式
    public grantType_def: string = 'client_credentials';
    public clientId_def: string = 'app_client';
    //刷新token
    public grantTypeRefresh: string = 'refresh_token';

    public refreshTokening: boolean = false;

    public get config() {
        return this.service;
    }

    /**
     * del
     * @param url 地址
     * @return Promise<any>
     */
    protected request(url: string, method: string, params?: any,
        files?: any, //用于文件上传:本地文件地址（支持数组['file://file.txt']）或者下载文件的本地地址（全路径）
        names?: any //用于文件上传:文件地址对应的文件标识（支持数组['file']）
    ): any {
        let state: State = new State();
        if (CookieHelper.get(Setting.authCookieName) != null) {
            state = JSON.parse(CookieHelper.get(Setting.authCookieName) as string);
        }
        
        //无token，或有token且未过期，直接请求
        if (url.indexOf("token") >= 0 || state.access_token == null|| state.refresh_token == "" || this.checkAccessTokenExpire(state.accessToken_expires)) {

            const path = this.getUrl(url);
            return this.rest.request(path, method, params, files, names)
                .catch((error: any) => {
                    if (error.status == 401 && url.indexOf("getMqttAddress") < 0) {
                        
                        return this.refreshAuthHandler(state).then(
                            result => {
                                return this.rest.request(path, method, params, files, names);
                            },
                            err => {
                                this.logOut();
                            });
                    }
                    if (error && error.data && typeof(error.data)=="string") {
                        error.data=JSON.parse(error.data);
                    }
                    return Promise.reject(error);
                });
        } else {
            //刷新token过期，重新登录
            if (state.refreshToken_expires > 0 && !this.checkRefreshTokenExpire(state.refreshToken_expires)) {
                this.logOut();
            } else {
                //token过期，刷新token
                //第一次验证授权token过期，进入刷新token请求
                return this.refreshAuthHandler(state).then(
                    result => {
                        const path = this.getUrl(url);
                        return this.rest.request(path, method, params, files, names);
                    },
                    err => {
                        this.logOut();
                    });
            }
        }
    }

    protected getUrl(url?: string) {
        //api 版本
        let res = this.service.apiBaseURL ? Setting.baseURL + "/" + this.service.apiBaseURL : Setting.apiBaseURL;
        if (this.service.version) {
            res += `/${this.service.version}`;
        }
        //微服务名称  可为空
        if (this.service.name)
            res += `/${this.service.name}`;
        //业务模块controller名称 可为空
        if (this.service.resource)
            res += `/${this.service.resource}`;
        if (url) {
            res += "/" + url;
        }
        return res;
    }

    logOut() {
        window.location.href = '/login';
    }
    /**
     * true:未过期，false：过期
     */
    public checkAccessTokenExpire(accessToken_expires: any): boolean {
        return new Date().getTime() < accessToken_expires;
    }

    /**
     * true:未过期，false：过期
     */
    public checkRefreshTokenExpire(refreshToken_expires: any): boolean {
        return new Date().getTime() < refreshToken_expires;
    }

    public getExpiresTimestamp(expires: number): number {
        let timestamp = new Date().getTime() + (expires - 60) * 1000;
        return timestamp;
    }

    public refreshAuthHandler(state: State): Promise<any> {
        let refreshTokening = CookieHelper.get(Setting.refreshingTokenName) == "1"; //是否在刷新token
        if (refreshTokening) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve('success');
                }, 500);
            });
        } else {
            CookieHelper.save({
                key: Setting.refreshingTokenName,
                value: "1"
            });
            const user: User = SystemUtils.loginUser;
            if (user == null) {
                return new Promise((resolve, reject) => {
                    reject();
                });
            }
            let params = {
                'grant_type': this.grantTypeRefresh, //refresh token
                'client_id': this.clientId_def,
                'client_secret': Setting.clientSecret,
                'refresh_token': state.refresh_token //refresh token 时必须有值
            };
            const stringpart = transformRequest(params);
            const url = Setting.baseURL + '/attapi/oauth/token?' + stringpart;

            return this.rest.request(url, this.rest.METHOD_POST, {})
                .then((result: any) => {
                    const success = !result.data["error"];
                    if (success) {
                        let data = result.data;
                        //非公共授权
                        if (data.refresh_token) {
                            state.refresh_token = data.refresh_token;
                            if (state.refreshToken_expires == 0) {
                                state.refreshToken_expires = this.getExpiresTimestamp(30 * 24 * 60 * 60); //有效期三十天
                            }
                            state.accessToken_expires = this.getExpiresTimestamp(data.expires_in);
                        }
                        state.access_token = "Bearer " + data["access_token"];
                        state.refresh_token = data.refresh_token;

                        CookieHelper.deleteCookie(Setting.refreshingTokenName);
                        CookieHelper.deleteCookie(Setting.authCookieName);

                        CookieHelper.save({
                            key: Setting.refreshingTokenName,
                            value: "0"
                        });
                        CookieHelper.save({
                            key: Setting.authCookieName,
                            value: JSON.stringify(state)
                        });
                    } else {
                        this.logOut();
                    }
                })
        }
    }
}