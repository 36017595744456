
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
//组件
import SelfList from "@/views/demo/SelfList.vue";
import Form from "@/views/demo/Form.vue";

import { useRouter } from "vue-router";

export default defineComponent({
  name: "Demo",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    SelfList,
    Form,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      jumpPage: "",
    };
  },
  methods: {
    addPage(type: string) {
      this.jumpPage = type;
    },
   
    goBack() {
      this.router.go(-1);
    },
  },
});
