import { MessagesApi } from '@/api/message/MessagesApi';
import BaseRsp from '@/models/base/BaseRsp';
import Message, { MessageRsp } from '@/models/message/Message';
import SystemUtils from '@/utils/SystemUtils';
import BaseService from '@/services/base/BaseService';
import moment from 'moment';
import SqlHelper from '@/utils/sql/SqlHelper';
import { ChatMembersApi } from '@/api/message/ChatMembersApi';

/**
 * 消息
 */
export class MessageService extends BaseService {

    private api: any;
    private sqlHelper = new SqlHelper();

    /**
     * 发送消息
     * @param message 消息
     */
    private sendMessage(message: Message): Promise<BaseRsp> {
        this.api = new MessagesApi();
        return new Promise((resolve, reject) => {
            this.api.send(message)
                .then(async (res: BaseRsp) => {
                    resolve(res);
                }, (err: any) => {
                    console.log("send message error:" + JSON.stringify(err));
                })
                .catch((err: BaseRsp) => {
                    console.log("send message error:" + JSON.stringify(err));
                    reject(err);
                });
        });
    }

    /**
     * 发送消息（对外）
     * @param chatGroupId 消息组id
     * @param msgType 消息所属类型  1、普通文本消息 2、暂时不用  3、日志消息  4、图片  5、语音  6、文档（文件）
     * @param msg 消息内容
     * @param busTable 项目：tt_project  任务：tt_task  微议题：tt_topic  日程：cal_schedule
     * @param busId  业务（项目、任务、微议题、日程等）id
     * @param name  业务名称
     */
    public sendMessageExpose(chatGroupId: string, msg: string = "", msgType: number, busTable: string, busId: string, name: string, busTag: any = {
        projectId: "",
        projectName: "",
        taskId: "",
    }, file?: any): Promise<BaseRsp> {
        msg = this.trim(msg);
        if ((msgType == 1 || msgType == 3) && (!msg || msg.length <= 0)) {
            return this.errRsp("请输入发送内容");
        }
        let user = SystemUtils.loginUser;
        let content = {
            msg: msg,
            msgType: msgType,
        }
        if (file && file != undefined) {
            content = Object.assign(content, { file: file })
        }
        let contentStr = JSON.stringify(content);
        let sendTime = moment().format('YYYY-MM-DD HH:mm:ss');
        let message: Message = {
            id: this.newId(),
            chatGroupId: chatGroupId,
            content: contentStr,
            sendTime: sendTime,
            messageType: 1,
            sendUserId: user.id,
            sendUserName: user.name,
            systemId: SystemUtils.systemId,
            busId: busId,
            busTag: JSON.stringify(Object.assign(busTag,
                {
                    title: name,
                    userName: user.name,
                    userColor: user.userColor,
                })),
            busTable: busTable,
            isList: true,
            isAt: false,
        };
        // return new Promise((resolve, reject) => {});
        return this.sendMessage(message);
    }

    /**
     * 获取消息列表
     * @param chatGroupId 消息组id
     * @param offset 页码
     */
    public getNetMessageList(
        chatGroupId: string,
        offset: number,
        firstTime?: string,
    ): Promise<any> {
        this.api = new MessagesApi();
        let userId = SystemUtils.loginUser.id;
        if (!firstTime)
            firstTime = moment().format('YYYY-MM-DD HH:mm:ss');
        let limit = 20;
        let msgType = 0;
        if (!offset) offset = 1;
        return new Promise((resolve, reject) => {
            this.api.getMessageList(chatGroupId, userId, {
                chatGroupId,
                offset,
                limit,
                msgType,
                firstTime,
                userId
            }).then(async (res: any) => {
                // 循环消息集合，更新消息内容
                for (let msg of res.data) {
                    let m = JSON.parse(msg.content);
                    msg.content = m.msg;
                    msg.messageType = m.msgType;
                    msg.file = m.file;
                    let busTag = JSON.parse(msg.busTag as string);
                    msg.title = busTag.title;
                }
                resolve(res.data);
                this.sqlHelper.insertMessageListData(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getNetMessageListByTime(
        offset: number,
        startTime: string,
        endTime: string,
    ): Promise<MessageRsp> {
        this.api = new MessagesApi();
        let userId = SystemUtils.loginUser.id;
        let limit = 200;
        let isPage = true;
        if (offset <= 0) {
            offset = 1;
        }
        return new Promise((resolve, reject) => {
            this.api.getMessageListByTime({
                offset,
                limit,
                userId,
                startTime,
                endTime,
                isPage,
            }).then(async (res: MessageRsp) => {
                if (res.data.length > 0) {
                    setTimeout(() => {
                        this.getNetMessageListByTime(++offset, startTime, endTime);
                    }, 500);
                }
                // 循环消息集合，更新消息内容
                for (let msg of res.data) {
                    let m = JSON.parse(msg.content);
                    msg.content = m.msg;
                    msg.messageType = m.msgType;
                    msg.file = m.file;
                    let busTag = JSON.parse(msg.busTag as string);
                    msg.title = busTag.title;
                }
                this.sqlHelper.insertMessageListData(res.data, false);
                // resolve(res.data.length);
            }).catch((err: any) => {
                reject(err);
            })
        })
    }

    public getMqttAddress(): Promise<BaseRsp> {
        this.api = new MessagesApi();
        return this.api.getMqttAddress();
    }

    public getChatMembersByGroupId(groupId: string): Promise<boolean> {
        this.api = new ChatMembersApi();
        return new Promise((reslove, reject) => {
            this.api.getChatMembersByGroupId({ groupId: groupId })
                .then((res: BaseRsp) => {
                    let isMember: boolean = false;
                    for (let user of res.data) {
                        if (user.userId == SystemUtils.loginUser.id) {
                            isMember = true;
                            break;
                        }
                    }
                    reslove(isMember);
                }, (err: BaseRsp) => {
                    console.log(err);
                    reject(err);
                }).catch((err: BaseRsp) => {
                    console.log(err);
                })
        });
    }

    /**
     * 更新消息状态为已读
     * @param groupId 聊天组id
     * @returns 
     */
    public updateMessageState(groupId: string) {
        this.api = new MessagesApi();
        return new Promise((reslove, reject) => {
            this.api.updateMessageState(groupId)
                .then((res: BaseRsp) => {
                    reslove(res);
                }, (err: BaseRsp) => {
                    reject(err);
                }).catch((err: BaseRsp) => {
                    console.log(err);
                })
        });
    }
}

export function getInstance() {
    return new MessageService();
}