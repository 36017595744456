class SubscribeApplyInfo {

   public applyId?: string;            //  申请ID
   public calId?: string ;      // calId
   public calName?: string;         // calName
   public  color?: string;         // 颜色
   public userId?: string;   // 用户id
   public userName?: string;   // 用户名称
   public status?: number;   // 审核状态
   public rejectReason?:string;// 拒绝原因
    constructor () {
    }
  
  }
  
  export {
    SubscribeApplyInfo,
    SubscribeApplyInfo as default
  }
  
