
import { JPush, TagOptions, AliasOptions } from "@jiguang-ionic/jpush";
import { isPlatform } from "@ionic/vue";
/**
 * Jpush帮助类
 */
export class JpushHelper {

    private rId: String = "";
    private static readonly JPUSH_PRE_KEY: String = "Bingo";

    constructor() {
        this.rId = "";
    }

    public initJpush(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (userId != "") {
                if (isPlatform("capacitor")) {
                    JPush.init();
                    JPush.setDebugMode(true);
                    this.getEquipId(userId).then((t: boolean) => {
                        resolve(t);
                    }, (f: any) => {
                        reject(f);
                    });
                } else {
                    reject("非移动设备");
                }
            } else {
                reject("用户不存在");
            }
        });
    }

    private getEquipId(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            JPush.getRegistrationID()
                .then((rId: any) => {
                    setTimeout(() => {
                        JPush.setApplicationIconBadgeNumber(0);
                        console.log("jpush获取设备标识成功：", rId);
                        this.rId = rId;
                        if (rId) {
                            this.buildTag();
                            this.buildAlias(userId);
                        }
                        resolve(true);
                    }, 2000);
                })
                .catch((err: any) => {
                    console.log("jpush获取设备标识失败！", err);
                    reject(err);
                });
        });
    }

    //标签（分组）
    private buildTag() {
        let options: TagOptions = {
            sequence: 0,
            tags: [this.generateDefaultTag()],
        };
        JPush.setTags(options)
            .then((res: any) => {
                console.log("jpush设置设备标签成功：", JSON.stringify(res));
            })
            .catch((err: any) => {
                console.log("jpush设置设备标签失败！", err);
            });
    }

    public addTags(tags: Array<string>) {
        if (this.rId) {
            let options: TagOptions = {
                sequence: 1,
                tags: this.generateTags(tags),
            };
            JPush.setTags(options)
                .then((res: any) => {
                    console.log("jpush设置设备标签成功：", JSON.stringify(res));
                })
                .catch((err: any) => {
                    console.log("jpush设置设备标签失败！", err);
                });
        }
    }

    public deleteTags(tags: Array<string>) {
        if (this.rId) {
            let options: TagOptions = {
                sequence: 1,
                tags: this.generateTags(tags),
            }
            JPush.deleteTags(options).then((res: any) => {
                console.log("jpush删除设备标签成功：", JSON.stringify(res));
            }).catch((err: any) => {
                console.log("jpush删除设备标签失败！", err);
            });
        }
    }

    //别名
    private buildAlias(userId: string) {
        let alias = this.generateAlias(userId);
        if (alias) {
            let options: AliasOptions = {
                sequence: 0,
                alias: alias,
            };
            JPush.setAlias(options)
                .then((res: any) => {
                    console.log("jpush设置设备别名成功：", JSON.stringify(res));
                })
                .catch((err: any) => {
                    console.log("jpush设置设备别名失败！", err);
                });
        } else {
            console.log("jpush别名生成失败");
        }
    }

    private generateDefaultTag(): string {
        return JpushHelper.JPUSH_PRE_KEY.valueOf() + "group";
    }

    private generateTags(tags: Array<string>): Array<string> {
        let result: Array<string> = new Array<string>();
        if (tags != null && tags.length > 0) {
            tags.forEach((tag: string) => {
                result.push(tag.startsWith(JpushHelper.JPUSH_PRE_KEY.valueOf()) ? tag : JpushHelper.JPUSH_PRE_KEY.valueOf() + tag);
            });
        }
        return result;
    }

    private generateAlias(userId: string) {
        if (userId) {
            return JpushHelper.JPUSH_PRE_KEY.valueOf() + userId.split("-").join("");
        }
        return "";
    }

    //接收消息触发
    public buildReceiveListener(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.rId) {
                document.addEventListener("jpush.receiveNotification", (event: any) => {
                    const content = isPlatform('ios') ? event['aps'].alert : event['alert'];
                    console.log("jpush接收消息触发：" + JSON.stringify(event));
                    resolve(content);
                });
            } else {
                reject("极光服务初始化失败");
            }
        });
    }

    //打开消息触发
    public buildOpenListener(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.rId) {
                document.addEventListener("jpush.openNotification", (event: any) => {
                    const content = isPlatform('ios') ? event['aps'].alert : event['alert'];
                    console.log("jpush打开消息触发：" + JSON.stringify(event));
                    resolve(content);
                });
            } else {
                reject("极光服务初始化失败");
            }
        });
    }

    //接收本地自定义消息
    public buildreceiveLocalListener(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.rId) {
                document.addEventListener("jpush.receiveLocalNotification", (event: any) => {
                    const message = isPlatform('ios') ? event['content'] : event['message'];
                    console.log("jpush接收本地消息：" + JSON.stringify(event));
                    resolve(message);
                });
            } else {
                reject("极光服务初始化失败");
            }
        });
    }

}

const Jpush = new JpushHelper();

const buildJpush = function (userId: string) {
    Jpush.initJpush(userId).then(
        (t: boolean) => {
            if (t) {
                Jpush.buildReceiveListener().then(
                    (r: any) => {
                        console.log(r);
                    },
                    (e: any) => {
                        console.log(e);
                    }
                );
                Jpush.buildOpenListener().then(
                    (r: any) => {
                        console.log(r);
                    },
                    (e: any) => {
                        console.log(e);
                    }
                );
                Jpush.buildreceiveLocalListener().then(
                    (r: any) => {
                        console.log(r);
                    },
                    (e: any) => {
                        console.log(e);
                    }
                );
            }
        },
        (f: any) => {
            console.log(f);
        }
    );
}

export default {
    Jpush: Jpush,
    buildJpush: buildJpush
}