import TaskBaseService from '../base/TaskBaseService';

export class FeedbackApi extends TaskBaseService {
    constructor() {
        super("okrFeedback");
    }
     /**
     * 用户反馈列表
     * @param param
     */
    public getFeedbackList(param: any): Promise<any> {
        const path = 'getListByParams';
        return this.request(path, this.rest.METHOD_GET, param);
    }  
      /**
     * 新增意见反馈
     * @param param
     */
    public addFeedback(param: any): Promise<any> {
        const path = '';
        return this.request(path,this.rest.METHOD_POST, param);
    }
    /**
   * 新增意见反馈
   * @param param
   */
  public getFeedback(param: any): Promise<any> {
      const path =param;
      return this.request(path,this.rest.METHOD_GET, {});
  }
}