import { KrScoreLogApi } from '@/api/okr/KrScoreLogApi';
import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';
import UserInfo from '@/services/selectexecutor/UserInfo';
/**
 * 评分记录
 */
export class KrScoreLogService extends BaseService {
    private api = new KrScoreLogApi();

    //目标评分记录
    public getObjectiveList(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getObjectiveList(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    //目标评分记录：关键结果评分详情
    public getKrListByOscoreId(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getKrListByOscoreId(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //目标评分记录：关键结果评分详情
    public getKrListByOidAndCycleId(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getKrListByOidAndCycleId(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            })
        })
    }
    //保存评分  
    public getSaveScores(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.getSaveScores(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
}
