
  import { defineComponent, onMounted, reactive, toRefs } from "vue";
  import { modalController, IonPage, IonContent, IonText } from "@ionic/vue";
  import HeaderModal from "@/components/modal/components/HeaderModal.vue";
  import { TaskScheduleService } from "@/services/task/TaskScheduleService";
  import DetailScheduleModal from "@/views/task/info/schedule/DetailScheduleModal.vue";
  import moment from "moment";

  export default defineComponent({
    name: "CheckScheduleModal",
    components: {
      IonPage,
      IonContent,
      HeaderModal,
      IonText,
    },
    props: {
      taskId: String,
    },
    setup(props) {
      const {
        taskId
      } = toRefs(props);
      const scheduleServices = new TaskScheduleService();
      const dataList = reactive({
        data: new Array < any > ()
      });
      let page: number = 1;

      //加载列表数据
      let loadDataList = (tId: string) => {
        scheduleServices.getTaskScheduleList({
          offset: page,
          taskId: tId
        }).then(
          (r: any) => {
            if (r) {
              dataList.data = r;
              dataList.data.forEach((val, index, arr) => {
                val.createTime = moment(arr[index].createTime).format(
                  "YYYY-MM-DD"
                );
              });
            }
          },
          (e: any) => {
            console.log("加载列表异常：" + e);
            page = 1;
          }
        );
      };

      //关闭模态框
      let closeModel = () => {
        modalController.dismiss();
      };

      //加载页面后绑定事件
      onMounted(() => {
        if (taskId != undefined && taskId.value != undefined) {
          loadDataList(taskId.value);
        }
      });

      const loadData = (event: any) => {
        setTimeout(() => {
          if (taskId != undefined && taskId.value != undefined) {
            page += 1;
            scheduleServices
              .getTaskScheduleList({
                offset: page,
                taskId: taskId.value
              })
              .then(
                (r: any) => {
                  if (r) {
                    dataList.data = dataList.data.concat(r);
                  }
                  event.target.complete();
                  if (dataList.data.length == 1000) {
                    event.target.disabled = true;
                  }
                },
                (e: any) => {
                  event.target.disabled = false;
                  page -= 1;
                }
              );
          }
        }, 500);
      };

      // const bindDetail = async (data: string) => {
      //   const modal = await modalController.create({
      //     component: DetailScheduleModal,
      //     cssClass: "task-progress-update-class",
      //     componentProps: {
      //       data: data,
      //     },
      //   });
      //   await modal.present();
      // };

      return {
        dataList,
        closeModel,
        loadData,
        // bindDetail,
      };
    },
  });
