
    import { defineComponent } from "vue";
    import Header from '@/components/Header.vue';
    import baseInfo from '../project/component/baseInfo.vue';
    import milestoneNode from '../project/component/milestoneNode.vue';
    import modelProductInfo from '../project/component/modelProductInfo.vue';
    import projectTeam from '../project/component/projectTeam.vue';
    export default defineComponent({
        name: "mainInfo",
        components: {
            baseInfo,
            modelProductInfo,
            projectTeam,
            Header,
            milestoneNode,
        },
        data() {
            return {
                indicators: ["基本信息", "里程碑节点", "车型及产品信息", "项目团队"],
                selSeg: 0,
            };
        },
        props: {},
        methods: {
            segmentChanged(ev: CustomEvent) {
                this.selSeg = ev.detail.value;
            },
        },
        mounted() {
            if(this.$route.query.selSeg){
                this.selSeg = this.$route.query.selSeg as any;
            }
        }
    });
