
  import { defineComponent } from "vue";
  import { modalController } from "@ionic/vue";
  import ProjectDoc from "@/views/project/document/ProjectDoc.vue";
  import HeaderModal from "@/components/modal/components/HeaderModal.vue";
  import { TaskLinkContentService } from "@/services/task/TaskLinkContentService";
  import TaskLink from "@/services/task/model/TaskLink";
  import Utils from "@/utils/Utils";
  import { TaskLinkContentType } from "@/models/task/Enum";
  export default defineComponent({
    name: "FileModal",
    components: {
      HeaderModal,
      ProjectDoc,
    },
    props: {
      busId: String,
      beLinkId: String,
      projectId: String,
      type: String //类型 连接项目文件或任务文件：project 或 task
    },
    data() {
      return {
        linkService: new TaskLinkContentService(),
        linkedFileList: [], //已经关联的文件集合
        $refs: {
          docList: ProjectDoc
        },
      }
    },
    mounted() {
      this.linkService.getByTaskId(this.beLinkId ? this.beLinkId : "").then((res) => {
        this.linkedFileList = res.filter((f: any)=>{return f.targetType==TaskLinkContentType.Document});
      })
    },

    methods: {
      closeModel(type: any) {
        if (type == 4) {
          const checkList = this.$refs.docList.getSelectId();
          //完成选择，保存数据并关闭模态
          if (checkList.length == 0) {
            Utils.presentToastWarning("请选择至少一项关联内容");
            return;
          }

          const items: Array < TaskLink >= new Array < TaskLink > ();
          const sourceId = this.beLinkId ? this.beLinkId : "";
          const projectId = this.projectId ? this.projectId : "";
          const tartetProjectId=this.type && this.type=="project" && this.busId?this.busId:"";
          for(var i=0;i<checkList.length;i++){
              const e: any=checkList[i];
              //不能包含已选
              const itemIndex = this.linkedFileList.findIndex((link: any) => {
                return e.id == link.targetId && link.type == 'linked' && link.targetType == TaskLinkContentType.Document;
              });
              if (itemIndex > -1) {
                Utils.presentToastWarning("关联内容已经存在");
                return false;
              }

              const item: TaskLink = new TaskLink(e.id, e.name,tartetProjectId, TaskLinkContentType.Document,
              sourceId, projectId, TaskLinkContentType.Task);
              items.push(item);
          }
          //save 方法
          this.linkService.addLink(items).then((res) => {
            if (res) {
              // 提醒，添加成功
              Utils.presentToastSuccess("添加成功");
               //关闭页面
              modalController.dismiss({refresh: true});
            } else {
              Utils.presentToastWarning("添加失败");
            }
          });
        } else {
          modalController.dismiss();
        }
      },
    },
  });
