import { ProjectMembersApi } from '@/api/project/ProjectMembers';
import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';
import UserInfo from '@/services/selectexecutor/UserInfo';
/**
 * 项目列表模块
 */
export class ProjectMemberService extends BaseService {
    private api = new ProjectMembersApi();
    //更新实体
    public updateModel(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.updateModel(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //检查是否存在成员
    public existsMember({
        teamRoleId="",
        userId=SystemUtils.loginUser.id
    }={}): any {
        return new Promise((resolve, reject) => {
            this.api.existsMember({teamRoleId,userId}).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //删除成员
    public deleteMembers(params:any,projectId :any): any {
        return new Promise((resolve, reject) => {
            this.api.deleteMembers(params,projectId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //添加成员    
    public savaList(params:any): any {
        // if(params&&params[0].id==""){
        //     params[0].id=BaseService.newId("task","projectmember")
        // }
        return new Promise((resolve, reject) => {
            this.api.saveList(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //获取登陆人所在所有团队的成员列表
    public getUsersByProjectTeamApp(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.getUsersByProjectTeamApp(params).then(async (res: any) => {
                resolve(this.getUserList(res.data));
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //根据项目Id成员数量-App（用户去重复）
    public getMemberCountByProjectApp(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.getMemberCountByProjectApp(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //是否存在项目成员（批量检查）
    public existsProjectMembers(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.existsProjectMembers(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //获取项目成员
    public getDistinctMember(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.getDistinctMember(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    updateProjectRole(id: string,roleId: string) {
        return new Promise((resolve, reject) => {
            this.api.updateProjectRole(id,roleId).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    getUserList(data: any) {
        
        const userList = new Array();
        
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const user = new UserInfo();
            user.id = element.id;
            user.name = element.userName;
            user.pernr = element.pernr;
            user.photo = element.photo;
            user.userId = element.userId;
            user.department = element.department;
            user.departmentId = "";
            userList.push(user);
        }
        return userList;
    }
}
