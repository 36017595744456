import TaskBaseService from '../base/TaskBaseService';

export class HelpGuideApi extends TaskBaseService {
    constructor() {
        super("okrInstructions");
    }
     /**
     * 使用说明列表
     * @param param
     */
    public getHelpGuideList(param: any): Promise<any> {
        const path = 'getListByParams';
        return this.request(path, this.rest.METHOD_GET, param);
    }
}