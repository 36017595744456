
import BaseService from "@/services/base/BaseService"
import Setting from '@/rest/Setting';

class ProjectStatus {

   public enterpriseId=Setting.currentEnterprise.id;         // 企业id
   public id?: string;            //  主键
   public isDeleted?: boolean ;      // 是否删除
   public nameCn?: string;         // 名称
   public  projectId?: string;         // 项目id
   public systemId="task";        // 系统id 默认task
  
    constructor (item: any) {
      this.id=item.id?item.id:BaseService.newId(this.systemId,"projectStatus");
      this.nameCn=item.nameCn;
      this.projectId=item.projectId;
      this.isDeleted=item.isDeleted?item.isDeleted:false ;      // 是否删除
      this.systemId= item.systemId;        // 系统id 默认task
    }
  
  }
  
  export {
    ProjectStatus,
    ProjectStatus as default
  }
  
