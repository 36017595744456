
import { defineComponent } from "vue";
import echarts from "echarts";
export default defineComponent({
  name: "Freigabe",
  components: {},
  props: {
    taskItem: Object,
    index: Number,
  },
  data() {
    return {
      echarts: echarts,
    };
  },
  methods: {
    init() {
      if (
        (this as any).taskItem.color.red == 0 &&
        (this as any).taskItem.color.redYellow == 0 &&
        (this as any).taskItem.color.yellow == 0 &&
        (this as any).taskItem.color.green == 0 &&
        (this as any).taskItem.color.gray == 0
      ) {
        (this as any).taskItem.color.empty = 9999;
      } else {
        (this as any).taskItem.color.empty = 0;
      }
      const series = [
        {
          name: "打灯情况",
          type: "pie",
          radius: ["50%", "80%"],
          color: [
            "#cb333b",
            "#ff8c00",
            "#ffc72c",
            "#84bd00",
            "#8e8f90",
            "#e8eaec",
          ],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          offsetY: 450,
          emphasis: {
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: (this as any).taskItem.color.red, name: "" },
            { value: (this as any).taskItem.color.redYellow, name: "" },
            { value: (this as any).taskItem.color.yellow, name: "" },
            { value: (this as any).taskItem.color.green, name: "" },
            { value: (this as any).taskItem.color.gray, name: "" },
            { value: (this as any).taskItem.color.empty, name: "" },
          ],
        },
      ];
      const Chart = this.echarts.init(
        document.getElementById(
          "charts-" + this.index
        ) as HTMLCanvasElement
      );
      Chart.setOption({ series: series });
    },
  },
  watch: {
    taskItem() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
});
