import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Calendar, Circle, Uploader, Rate, Slider, Overlay, Loading ,Stepper,Picker,Popup,ImagePreview,Tag  } from 'vant';
import ElementPlus from 'element-plus';
// 使用中文语言
import locale from 'element-plus/lib/locale/lang/zh-cn';

import {
  IonicVue, IonSlides, IonSlide, IonIcon, IonLabel, IonRadio, IonItem, IonRadioGroup, IonList, IonHeader, IonContent, IonFooter, IonTitle, IonInfiniteScroll, IonNote,
  IonInfiniteScrollContent, IonRefresher, IonRefresherContent, IonFabButton, IonFab, IonInput, IonImg, IonSearchbar, IonButton, IonText, IonTextarea, IonSelect, IonSelectOption, IonProgressBar,
  IonThumbnail, IonCol, IonRow, IonGrid, IonPage, IonCheckbox, IonButtons, IonToolbar, IonDatetime, IonMenu, IonSegment, IonSegmentButton, IonAvatar, IonChip,
  IonSkeletonText, isPlatform, IonToggle, IonListHeader, IonFabList
} from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import 'vant/lib/calendar/style';
import 'element-plus/lib/theme-chalk/index.css';
// 测试代码 删除
/* 主题样式变量 */
import '@/theme/variables.css';
/* 图标字体 */
import '@/assets/font/iconfont.css';
import '@/assets/font/iconfont.js';

import '@/assets/css/all.scss';
/**bingo gantt */
import "dhtmlx-gantt"; // 引入模块
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import "dhtmlx-gantt/codebase/locale/locale_cn"; // 本地化
import { AccountService } from './services/login/accountService';
import Setting from './rest/Setting';
import SystemUtils from './utils/SystemUtils';
import User from './models/task/User';
import Utils from './utils/Utils';
// import { SplashScreen } from '@capacitor/splash-screen';
import CookieHelper from './utils/CookieHelper';
//Capacitor plugins
defineCustomElements(window);
//
// import VConsole from 'vconsole'
// const vConsole = new VConsole()

// 获取本地语言环境
import langHelper from "./utils/LangHelper";
import { createI18n } from 'vue-i18n';
const messages = {
  en: Object.assign(require('@/locales/task/en.json')),
  cn: Object.assign(require('@/locales/task/cn.json')),
  de: Object.assign(require('@/locales/task/de.json'))
};
const i18n = createI18n({
  locale: langHelper.lang(),   // 设置地区语言 默认语言
  messages: messages  //设置地区语言信息
});

const getEmail = (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    //若是打包环境，从bdoy读取
    if (isPlatform("ios")) {
      let appConnect = (window as any).AppConnectCordova;
      if (appConnect) {
        appConnect.initialize();
      }
      document.addEventListener('appconnect.isReady', (event) => {
        if (appConnect.isReady()) {
          switch (appConnect.authState()) {
            case 0:
              reject(appConnect.authMessage());
              break;
            case 1:
              const _info = appConnect.config();
              if (_info && _info.email) {
                let email = _info.email;
                resolve(email);
              } else {
                reject("通过 BYOD 获取 email 失败");
              }
              break;
            case 2:
              //未安装 BYOD 平台

              reject(appConnect.authMessage());
              break;
            default:
              reject(appConnect.authMessage());
              break;
          }
        } else {
          reject('appConnect is not ready');
        }
      });

    } else if (isPlatform("android")) {
      (window as any).ACConfigPlugin.setConfigHandler((result: any) => {
        if (!result) {
          reject("通过 BYOD 获取 email 失败");
        } else {
          let temp = result.substring(1, result.length - 1);
          let t = temp.split(",");
          let email = "";
          let emailKey = "email";
          for (let i = 0; i < t.length; i++) {
            if (t[i].indexOf(emailKey) != -1) {
              console.log("email:" + t[i] + ":email");
              email = t[i].substring(
                t[i].indexOf(emailKey) + emailKey.length + 1,
                t[i].length
              );
            }
          }
          resolve(email);
        }
      });
      (window as any).ACConfigPlugin.requestConfig();
    }

  });
}




const app = createApp(App)
  .use(IonicVue,  {
                  rippleEffect: false,
                  mode: 'ios',
                  statusTap: false,
                  swipeBackEnabled: false
                })
  .use(router)
  .use(Calendar)
  .use(Circle)
  .use(Uploader)
  .use(Rate)
  .use(Stepper)
  .use(Picker)
  .use(Popup)
  .use(Slider)
  .use(Overlay)
  .use(Tag)
  .use(Loading) 
  .use(ImagePreview)
  .use(ElementPlus, { locale })
  .use(i18n);//通过 `i18n` 选项创建 Vue 实例
app.component('ion-slides', IonSlides);
app.component('ion-slide', IonSlide);
app.component('ion-icon', IonIcon);
app.component('ion-label', IonLabel);
app.component('ion-radio', IonRadio);
app.component('ion-item', IonItem);
app.component('ion-radio-group', IonRadioGroup);
app.component('ion-list', IonList);
app.component('ion-header', IonHeader);
app.component('ion-title', IonTitle);
app.component('ion-content', IonContent);
app.component('ion-footer', IonFooter);
app.component('ion-infinite-scroll', IonInfiniteScroll);
app.component('ion-infinite-scroll-content', IonInfiniteScrollContent);
app.component('ion-note', IonNote);
app.component('ion-refresher', IonRefresher);
app.component('ion-refresher-content', IonRefresherContent);
app.component('ion-fab-button', IonFabButton);
app.component('ion-fab', IonFab);
app.component('ion-fab-list', IonFabList);
app.component('ion-input', IonInput);
app.component('ion-img', IonImg);
app.component('ion-searchbar', IonSearchbar);
app.component('ion-button', IonButton);
app.component('ion-text', IonText);
app.component('ion-textarea', IonTextarea);
app.component('ion-select', IonSelect);
app.component('ion-select-option', IonSelectOption);
app.component('ion-progress-bar', IonProgressBar);
app.component('ion-thumbnail', IonThumbnail);
app.component('ion-col', IonCol);
app.component('ion-row', IonRow);
app.component('ion-grid', IonGrid);
app.component('ion-page', IonPage);
app.component('ion-checkbox', IonCheckbox);
app.component('ion-buttons', IonButtons);
app.component('ion-toolbar', IonToolbar);
app.component('ion-datetime', IonDatetime);
app.component('ion-menu', IonMenu);
app.component('ion-segment', IonSegment);
app.component('ion-segment-button', IonSegmentButton);
app.component('ion-avatar', IonAvatar);
app.component('ion-chip', IonChip);
app.component('ion-skeleton-text', IonSkeletonText);
app.component('ion-toggle', IonToggle);
app.component('ion-list-header', IonListHeader);
router.isReady().then(() => {
  console.log(window.location.search.indexOf('?')>=0,222)
//   CookieHelper.save({
//     key: Setting.authCookieName,
//     value: `{"access_token":"1"}`
// });
  const token = CookieHelper.get(Setting.authCookieName);
  if ((!token || token.length == 0 || token == "null")&&window.location.search.indexOf('?')==-1) {

    router.push({ path: '/login' });
  }
  if(window.location.search.indexOf('?')==-1){
    localStorage.setItem('menuRole','phone')
  }
  app.mount('#app');
  // modify by hansl 20210305，启用登录页，不再需要自动登录
  // if (Setting.isSVW
  //   && isPlatform('capacitor')
  //   && Setting.enableByod
  //   //&& (Setting.appHostName+"").indexOf("ci-") >0
  //   //&& isPlatform("ios")
  // ) {
  //   //get email from byod
  //   getEmail().then((email) => {
  //     // BYOD 测试账号
  //     if (email == 'CIP1@csvw.com') {
  //       email = 'QianPeiqing@csvw.com';
  //     }

  //     login(email).then(() => {
  //       app.mount('#app');
  //     }).catch(loginFailed);
  //   }).catch(e => {
  //     alert(e);
  //     // BYODD 登录失败， 退出 APP
  //     (navigator as any).app.exitApp();
  //   })
  // }
  // else {
  //   login(SystemUtils.loginUser.email + "").finally(() => {
  //      app.mount('#app');
  //   })
  // }

});


const login = (email: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    if (email) {
      const loginService = new AccountService();
      loginService.login(email).then((result: boolean) => {
        result ? resolve(true) : reject();
      }).catch((e: any) => {
        if (e.status == 4000) {
          //无网络连接
          reject(e.message);
        }
        else {
          //认证失败
          reject();
        }
      });
    } else {
      reject();
    }
  });
}

//登录失败后的处理
const loginFailed = (msg?: string) => {
  CookieHelper.deleteCookie("Authorization");
  const message = msg ? msg : "认证身份信息失败";
  Utils.presentToastWarning(message);
  router.push({ path: '/login' });
}
