
import { menuController, modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import Utils from "@/utils/Utils";
import AvatarList from "@/components/AvatarList.vue";
import BaseService from "@/services/base/BaseService";

export default defineComponent({
  name: "ProjectPageSearchPanel",
  components: {
    AvatarList,
  },
  emits: ["on-close"],
  data() {
    return {
      workUsersArr: new Array(0), //执行人
      //----筛选条件字段--------------
      searchPanelForm: {
        searchKey: "", //搜索名称
        workUsers: "", //执行者ids
        dueToday: "", //今日截止
        completedToday: "", //今日完成
        myExecute: "", //我执行的
        myCreate: "", //我创建的
        undone: "", //未完成的任务
        unassigned: "", //未分配的任务
        finished: "", //已完成的任务
        postponed: "", //已延期的任务
        isSelected: "false",
        createTimeStart: "", //创建时间
        createTimeEnd: "",
      },
      baseService: new BaseService(),
    };
  },
  methods: {
    ionToggleChange(event: any) {
      let viewType = event.target.value;
      let isSelected = event.target.checked == true ? event.target.checked : "";
      switch (viewType) {
        case "dueToday":
          this.searchPanelForm.dueToday = isSelected;
          break;
        case "completedToday":
          this.searchPanelForm.completedToday = isSelected;
          break;
        case "myExecute":
          this.searchPanelForm.myExecute = isSelected;
          break;
        case "myCreate":
          this.searchPanelForm.myCreate = isSelected;
          break;
        case "undone":
          this.searchPanelForm.undone = isSelected;
          break;
        case "unassigned":
          this.searchPanelForm.unassigned = isSelected;
          break;
        case "finished":
          this.searchPanelForm.finished = isSelected;
          break;
        case "postponed":
          this.searchPanelForm.postponed = isSelected;
          break;
        default:
          break;
      }
    },
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    onClose() {
      menuController.close();
    },
    keyNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        this.confirm();
      }
    },

    //重置时间
    resetTime(type: any) {
      if (type == "createTime") {
        this.searchPanelForm.createTimeStart = "";
        this.searchPanelForm.createTimeEnd = "";
      }
      // if (type == "startTime") {
      //   this.searchPanelForm.startTimeStart = "";
      //   this.searchPanelForm.startTimeEnd = "";
      // }
      // if (type == "endTime") {
      //   this.searchPanelForm.endTimeStart = "";
      //   this.searchPanelForm.endTimeEnd = "";
      // }
      // if (type == "finishTime") {
      //   this.searchPanelForm.finishTimeStart = "";
      //   this.searchPanelForm.finishTimeEnd = "";
      // }
    },
    //确定检索
    confirm() {
      if (Utils.isInCludeEmoji(this.searchPanelForm.searchKey)) {
        Utils.presentToastWarning("任务名称不可以包含表情");
        return;
      }
      this.searchPanelForm.searchKey = this.baseService.trim(
        this.searchPanelForm.searchKey
      );

      if (this.workUsersArr && this.workUsersArr.length > 0) {
        const cUserArr = this.workUsersArr;
        let userIds = "";
        let userObjectArr = new Array(0);
        for (let i = 0; i < cUserArr.length; i++) {
          const uId = cUserArr[i].id;
          const uName = cUserArr[i].name;
          const uPernr = cUserArr[i].pernr;
          if (i == 0) {
            userIds += cUserArr[i].id;
          } else {
            userIds += "," + cUserArr[i].id;
          }
          userObjectArr.push({ id: uId, name: uName, pernr: uPernr });
        }
        if (userIds) {
          this.searchPanelForm.workUsers = userIds;
        }
      } else {
        this.searchPanelForm.workUsers = "";
      }
      this.searchPanelForm.isSelected = "true";
      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },
    //删除所选的人
    deleteUser(item: any, index: number) {
      this.workUsersArr.splice(index, 1);
    },
    //执行人
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.workUsersArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.workUsersArr = data.data;
        //初始化
      }
    },
    //重置检索条件
    reset() {
      let ele: any = document.querySelectorAll(".iontoggle");
      if (ele) {
        ele.forEach((item: any, i: number, obj: any) => {
          item.className = item.className.replace("toggle-checked", "");
        });
      }
      this.searchPanelForm.searchKey = "";
      this.searchPanelForm.workUsers = "";
      this.searchPanelForm.dueToday = "";
      this.searchPanelForm.createTimeStart = ""; //创建时间
      this.searchPanelForm.createTimeEnd = "";
      this.searchPanelForm.completedToday = "";
      this.searchPanelForm.myExecute = "";
      this.searchPanelForm.myCreate = "";
      this.searchPanelForm.undone = "";
      this.searchPanelForm.unassigned = "";
      this.searchPanelForm.finished = "";
      this.searchPanelForm.postponed = "";
      this.workUsersArr = [];
      this.searchPanelForm.isSelected = "false";
      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
