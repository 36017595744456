<template>
  <div
    class="ys-float-btn"
    :style="{
      width: itemWidth + 'px',
      height: itemHeight + 'px',
      left: left + 'px',
      top: top + 'px',
    }"
    ref="div"
    @click="onBtnClicked"
    :class="warningUnreadCount > 0 ? 'circle' : ''"
  >
    <img :src="btnSrc" class="circle" />
    <!-- <p>{{text}}</p> -->
    
    <Audio ref="audio"></Audio>
  </div>
</template>


<script>
import { WarningService } from "@/services/warning/WarningService";
import Bus from "@/utils/bus";
import { onUnmounted } from "vue";
import Utils from "@/utils/Utils";
import Audio from "@/components/Audio.vue";
export default {
  name: "AiuiBtn",

  components: {
    Audio,
  },
  props: {
    text: {
      type: String,
      default: "小助手",
    },
    itemWidth: {
      type: Number,
      default: 60,
    },
    itemHeight: {
      type: Number,
      default: 60,
    },
    gapWidth: {
      type: Number,
      default: 10,
    },
    coefficientHeight: {
      type: Number,
      default: 0.8,
    },
    unreadCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ["onFloatBtnClicked", "clearMsg"],
  data() {
    return {
      $refs: {
        audio: Audio,
      },
      timer: null,
      currentTop: 0,
      clientWidth: 0,
      clientHeight: 0,
      left: 0,
      top: 0,
      btnSrc: "/assets/icon/50zhayan.png",
      warningService: new WarningService(),
      warningUnreadCount: 0, //未读提醒数量
      longPressTimer: null,
      longPressFlage: false,
      startY: 0,
      moveXStart: 0,
      moveYStart: 0,
    };
  },
  watch: {
    unreadCount(newVal, oldVal) {
      if (newVal != oldVal) {
        this.warningUnreadCount = newVal;
      }
    },
  },
  created() {
    Bus.$off("updateWarningCount");
    Bus.$on("updateWarningCount", () => {
      this.getWarningCount();
    });
    this.clientWidth = document.documentElement.clientWidth;
    this.clientHeight = document.documentElement.clientHeight;
    // this.left = this.clientWidth- this.itemWidth - this.gapWidth;
    this.left = this.gapWidth + 0;
    this.top = this.clientHeight * this.coefficientHeight;
  },
  mounted() {
    this.getWarningCount();
    window.addEventListener("scroll", this.handleScrollStart);
    this.$nextTick(() => {
      const div = this.$refs.div;
      div.addEventListener("touchstart", (e) => {
        console.log("touchstart");
        div.style.transition = "none";
        if (e.targetTouches.length === 1) {
        console.log("有坐标");
          let touch = e.targetTouches[0];
          this.startY = touch.clientY;
          console.log(this.moveYStart);
          console.log(this.moveXStart);
          this.longPressTimer && clearTimeout(this.longPressTimer);
          this.longPressTimer = setTimeout(() => {
            if (
              this.moveXStart !=0 ||
              this.moveYStart !=0
            ) {
              this.longPressFlage = false;
            } else {
              console.log("开始录音");
              this.longPressFlage = true;
              // 语音按钮按下
              let audio = this.$refs.audio;
              if (audio) {
                audio.touchstart(e);
              }
            }
          }, 600);
        }
      });
      div.addEventListener("touchmove", (e) => {
        if (e.targetTouches.length === 1) {
          let touch = e.targetTouches[0];
          if (!this.longPressFlage) {
            this.left = touch.clientX - this.itemWidth / 2;
            this.top = touch.clientY - this.itemHeight / 2;
            this.moveXStart = touch.clientX;
            this.moveYStart = touch.clientY;
          } else {
            this.moveYStart = touch.clientY - this.startY;
            console.log("长按录音");
            // 语音按钮抬起
            let audio = this.$refs.audio;
            if (audio) {
              audio.touchmove(e);
            }
          }
        }
      });
      div.addEventListener("touchend", (e) => {
        this.longPressTimer && clearTimeout(this.longPressTimer);
          this.startY = 0;
          this.moveXStart = 0;
          this.moveYStart = 0;
        if (this.longPressFlage) {
          this.longPressFlage = false;
          // 语音按钮抬起
          let audio = this.$refs.audio;
          if (audio) {
            audio.touchend(e);
          }
          //长按录音
          if (this.moveYStart < -30) {
            console.log("取消录音");
          } else {
            console.log("正在录音");
          }
        } else {
          div.style.transition = "all 0.3s";
          if (this.left > this.clientWidth / 2) {
            this.left = this.clientWidth - this.itemWidth - this.gapWidth;
          } else {
            this.left = this.gapWidth;
          }
          if (this.top < 0) {
            this.top = this.gapWidth;
          } else {
            if (this.top >= this.clientHeight - this.itemHeight)
              this.top = this.clientHeight - this.itemHeight - 5;
          }
        }
      });
    });
  },
  setup() {
    onUnmounted(() => {
      console.log("aiuiBtn onUnmounted");
      // window.removeEventListener("scroll", this.handleScrollStart);
      // Bus.$off("updateWarningCount");
    });
  },
  methods: {
    onBtnClicked() {
      this.$emit("onFloatBtnClicked");
    },
    handleScrollStart() {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleScrollEnd();
      }, 300);
      this.currentTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.left > this.clientWidth / 2) {
        this.left = this.clientWidth - this.itemWidth / 2;
      } else {
        this.left = -this.itemWidth / 2;
      }
    },
    handleScrollEnd() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop === this.currentTop) {
        if (this.left > this.clientWidth / 2) {
          this.left = this.clientWidth - this.itemWidth - this.gapWidth;
        } else {
          this.left = this.gapWidth + 0;
        }
        clearTimeout(this.timer);
      }
    },
    switchAction(msg) {
      this.btnSrc = "/assets/icon/50huishou.png";
      //播报声音
      Utils.textTospeech(msg);
      setTimeout(() => {
        this.btnSrc = "/assets/icon/50zhayan.png";
      }, 1500);
      // window.removeEventListener('scroll', this.handleScrollStart);
    },
    getWarningCount() {
      this.warningService
        .getUnreadCount()
        .then((res) => {
          this.warningUnreadCount = res.data;
          this.$emit("clearMsg");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.ys-float-btn {
  // background: #1f56c121;
  background:rgba(38, 86, 220, 0.16);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: #fff;
  z-index: 90000;
  transition: all 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 20vw;
  
-webkit-touch-callout:none;
-webkit-user-select:none;
-khtml-user-select:none;
-moz-user-select:none; 
-ms-user-select:none;
user-select:none;
  &.circle {
    &:after {
      content: "";
      position: absolute;
      top: px2rem(10);
      right: px2rem(20);
      width: 6px;
      height: 6px;
      background-color: #f38467;
      z-index: 100;
      border-radius: 50%;
    }
  }
  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
    // margin-bottom: 3px;
  }
  p {
    font-size: 7px;
  }
}
</style>