<template>
  <ion-page>
    <Header type="0" title="一汽解放试制管理系统" :isBack="false">
      <!-- :btnImgRight="[require('@/assets/images/sys.png')]"
      @transfer="transfer" -->
    </Header>
    <ion-content :scroll-y="false">
      <!-- 主页面内容容器 -->
      <div class="mui-content">
        <div class="mui-row">
          <img :src="require('@/assets/images/home.jpg')" style="width: 100%" />
        </div>
        <div class="logins-warp">
          <div class="login-title f-w"><h1>欢迎登录</h1></div>
          <input
            id="userName"
            type="text"
            placeholder="请输入账号"
            v-model="loginId"
          />
          <input
            id="password"
            type="password"
            placeholder="请输入密码"
            v-model="password"
          />
          <ion-button expand="block" color="primary" @click="login"
            >登录</ion-button
          >
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import Header from "@/components/Header.vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { modalController, loadingController } from "@ionic/vue";
import Utils from "@/utils/Utils";
import regExps from "@/utils/regExps";
import { AccountService } from "@/services/login/accountService";
import SystemUtils from "@/utils/SystemUtils";
import User from "@/models/task/User";
import Bus from "@/utils/bus";
import CookieHelper from "@/utils/CookieHelper";
import { login, pdaSiteMap } from "@/api/login/login";
import Setting from "@/rest/Setting";
import { privacyPolicyService } from "@/services/app/privacyPolicyService";
import PrivacyPolicy from "@/views/mine/PrivacyPolicy.vue";
import JpushHelper from "@/utils/JpushHelper";

export default defineComponent({
  name: "loginPage",
  components: { PrivacyPolicy, Header },
  data() {
    return {
      msg: "",
      router: useRouter(),
      loginId: "",
      password: "",
      passwordType: "password",
      loginClock: true,
      api: new AccountService(),
      privacyPolicyApi: new privacyPolicyService(),
      loginType: 1, //0 手机号验证码 1密码
      resisterCode: "获取验证码",
      codeTimmerValue: 0, //是否在获取验证码，0是可以获取验证码，其他是正在获取
      btnCodeClikck: true, //是否可以获取验证码
      maxTimmerValue: 120,

      code: "", //短信验证码
      isCheck: true,

      userPrivacy: false,
    };
  },
  created() {
    CookieHelper.deleteCookie(Setting.authCookieName);
    CookieHelper.deleteCookie(Setting.refreshingTokenName);
  },
  watch: {
    $route() {
      this.userPrivacy = false;
    },
  },
  methods: {
    login() {
      this.loginClock = false;
      if (this.loginId == null || this.loginId == "") {
        Utils.presentToastWarning("请输入用户名");
        return;
      }

      if (this.password == null || this.password == "") {
        Utils.presentToastWarning("请输入密码");
        return;
      }
      //login
      this.handlerLoginWithPassWord();
    },

    async handlerLoginWithPassWord() {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });

      await loading.present();
      login({ account: this.loginId, password: this.password })
        .then(async (res) => {
          loading.dismiss();
          if (!res.data.isError && res.data.data) {
            Utils.presentToastWarning("登录成功", "success");
            CookieHelper.save({
              key: Setting.authCookieName,
              value: `{"access_token":"1"}`,
            });
            SystemUtils.loginUser = res.data.data.resultData;
            await this.getMenu(res.data.data.resultData.id);
            CookieHelper.save({
              key: "task_system_loginuser",
              value: JSON.stringify(res.data.data.resultData),
            });
            this.$router.replace({
              path: "/tabs/homePage",
            });
            localStorage.setItem("menuList", "");
          } else {
            Utils.presentToastWarning("登录失败");
          }
        })
        .catch((error) => {
          Utils.presentToastWarning("登录失败");
        });
    },
    afterLogin() {
      // ☆☆☆☆☆ 登录成功，SystemUtils.loginUser能取得当前用户信息后调用以下代码
      // ☆☆☆☆☆ Bus.$emit("login_successful");
      //保存 用户同意隐私协议
      this.privacyPolicyApi
        .signAgree(SystemUtils.loginUser.id)
        .then(() => {})
        .catch(() => {});
      //重新建立连接
      // JpushHelper.buildJpush(SystemUtils.loginUser.id);
      this.router.push({ path: "/" });
      setTimeout(() => {
        this.loginId = "";
        this.password = "";
        this.code = "";
        this.resisterCode = "获取验证码";
      }, 300);
      Bus.$emit("login_successful");
      //取消已阅读并同意
      this.userPrivacy = false;
    },
    getMenu(id) {
      return new Promise((resolve) => {
        pdaSiteMap({ userId: id }).then((res) => {
          console.log(res);
          localStorage.setItem(
            "loginRole",
            JSON.stringify(res.data.data.resultData)
          );
          resolve();
        });
      });
    },
    //查看隐私协议

    async openPrivacyModel() {
      const modal = await modalController.create({
        component: PrivacyPolicy,
        cssClass: "task-progress-update-class",
        componentProps: {
          isLogin: true,
        },
      });
      await modal.present();
    },
  },
});
</script>

<style lang="scss" scoped>
.logins-warp {
  padding: 2rem 20px;
}
.login-title {
  font-size: 26px;
  letter-spacing: 3px;
  color: #111;
  margin-bottom: 30px;
}
input {
  margin-bottom: 20px !important;
  line-height: 21px;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 10px 15px;
  -webkit-user-select: text;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  outline: 0;
  background-color: #fff;
  -webkit-appearance: none;
  font-size: 14px;
}
button {
  width: 100%;
  padding: 10px 0 !important;
}
</style>
