import { ProjectOverViewApi } from '@/api/project/ProjectOverViewApi';
import { ProjectStatusApi } from '@/api/project/ProjectStatusApi';
import { ProjectApi } from '@/api/project/ProjectApi';
import { MessagesApi } from '@/api/message/MessagesApi';
import { ProjectGroupsApi } from '@/api/project/ProjectGroupsApi';
import { ProjectTypesApi } from '@/api/project/ProjectTypesApi';


import BaseService from './base/BaseService';
import Utils from '@/utils/Utils';

/**
 * 项目列表模块
 */
export class ProjectPageService extends BaseService {

    // constructor(
    //     private projectApi: ProjectApi,
    //     private projectDocApi: ProjectDocApi
    // ) { }

    private static projectOverViewApi = new ProjectOverViewApi();
    private static projectStatusApi = new ProjectStatusApi();
    private static projectApi = new ProjectApi();
    private static messagesApi = new MessagesApi();
    private static projectGroupsApi = new ProjectGroupsApi();
    private static projectTypesApi = new ProjectTypesApi();



    //获取项目状态列表
    public getByProjectStatusId({
        projectId = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectStatusApi.getByProjectStatusId({
                projectId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }



    //更改项目状态
    public projectStatusChanges(parms: any): any {
        if(Utils.isInCludeEmoji(parms.changeRemark)){
            return this.errRsp("请勿输入emoji表情");
        }
        return new Promise((resolve, reject) => {
            ProjectPageService.projectOverViewApi.projectStatusChanges(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    public getProjectInfoById({
        id = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectApi.getProjectInfoById({
                id
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    //更改项目状态
    public send(parms: any): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.messagesApi.send(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    /*
    获取项目分组
    **/
    public getProjectGroup({
        projectId = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectGroupsApi.getByProjectId({
                projectId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    /*
       获取项目分组
       **/
    public getProjectType({
        id = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectTypesApi.getById({
                id
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    /**
     *  获取消息列表
     * @param id 
     */
    public getMessageList(groupId: string, userId: string, params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            ProjectPageService.messagesApi.getMessageList(groupId, userId, params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     *  获取项目统计
     */
    public getByIdForOverview(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectApi.getByIdForOverview(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
    *  获取项目完成统计
    */
    public getIndexProject(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectApi.getIndexProject(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //更新项目信息
    public updateFieldsById(parms: any): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectApi.updateFieldsById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新项目状态
    public updateProjectStatus(parms: any): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectApi.updateProjectStatus(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新项目任务数量
    public refreshStatistics(parms: any): any {
        return new Promise((resolve, reject) => {
            ProjectPageService.projectApi.refreshStatistics(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}

export function getInstance() {
    return new ProjectPageService();
}