
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import Chat from "@/views/message/Chat.vue";
import TeamList from "@/views/project/team/TeamList.vue";
import AllMember from "./child/AllMember.vue";
import MemberMenu from "./MemberMenu.vue";
import SearchBar from "@/components/SearchBar.vue";
import ProjectMemberCard from "@/components/ListCard/ProjectMemberCard.vue";
import { ProjectService } from "@/services/project/projectService";
import { ProjectTeamService } from "@/services/project/ProjectTeamService";
import { ProjectMemberService } from "@/services/project/ProjectMemberService";
import BaseService from "@/services/base/BaseService";
import RoleManager from "../memberManage/rolemanager/RoleManager.vue";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
// 权限 类
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "MemberManage",
  components: {
    Header,
    Chat,
    TeamList,
    AllMember,
    ProjectMemberCard,
    SearchBar,
  },
  data() {
    const btnIconRight = [""]; //["icongengduo"];
    const btnSizeRight = [""]; //["45px"];
    return {
      indicators: ["成员任务情况", "所有成员", "团队消息"],
      selSeg: 0,
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      isShowSelSeg: true,
      title: "",
      projectId: "",
      // 项目成员
      projectService: new ProjectService(),
      projectMemberService: new ProjectMemberService(),
      permissionService: new PermissionService(),
      userProjectPermissionArr: new Array<Permission>(0),
      //成员操作
      hasAddMember: false,
      hasDelMember: false,
      hasEditMemberRole: false,
      projectRoleList: new Array(0),
      projectRoleId: "", //默认权限：成员
      projectRoleName: "", //默认权限：成员
      projectTeamRoleId: "", //默认角色：项目角色
      projectTeamRoleName: "", //默认角色：项目角色
      teamRoleList: new Array(), //团队
      project: { id: "", name: "", folderId: "", isDeleted: false },
      searchValue: "",
    };
  },
  created() {
    let tabIndex = this.$route.query.tabIndex;
    let projectId = this.$route.query.projectId;
    if (tabIndex) {
      this.selSeg = parseInt(tabIndex.toString());
      this.isShowSelSeg = false;
    }
    if (projectId) {
      this.projectId = projectId.toString();
    }
    //获取项目权限
    this.getUserPermission();
    //获取项目角色
    this.getProjectRoles();
    //获取团队
    this.getAllTeamRoles(this.projectId);
    this.title = this.$route.query.title + "";
  },
  watch: {
    $route(to, from) {
      // to表示的是你要去的那个组件，from 表示的是你从哪个组件过来的
      if (from.path == "/team/member-edit") {
        let obj: any = this.$refs.allMember;
        obj.refreshPage();
      }
    },
  },
  mounted() {},
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.selSeg = ev.detail.value;
    },

    getprojectInfo(event: any) {
      this.project = event;
    },

    async addMemberHandler() {
      //创建人
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: [],
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data) {
        let projectId = this.$route.query.projectId;
        this.operateAddedMember(data.data, projectId);
      }
    },

    operateAddedMember(retUserList: any, projectId: any) {
      let params = new Array();
      let userIds = new Array();
      for (let i = 0; i <= retUserList.length - 1; i++) {
        let retUser = retUserList[i];
        userIds.push(retUser.userId);
      }
      let p = { projectId: projectId, userIds: userIds };
      this.projectMemberService.existsProjectMembers(p).then((result: any) => {
        let listExists = new Array();
        if (result && result.data) {
          if (result.data.length > 0) {
            listExists = result.data;
          }
        }
        for (let i = 0; i <= retUserList.length - 1; i++) {
          let retUser = retUserList[i];
          let tempList: any = listExists.filter((ele: any) => {
            return ele == retUser.userId;
          });
          if (retUser.userId) {
            if (tempList.length == 0) {
              params.push({
                account: retUser.pernr,
                dataState: 0,
                enterpriseId: "1",
                systemId: "task",
                id: BaseService.newId("task", "projectmember"),
                projectId: projectId,
                partNameCn: retUser.department,
                partNameEn: retUser.department,
                userId: retUser.userId,
                userName: retUser.name,
                projectRoleId: this.projectRoleId,
                projectRoleName: this.projectRoleName,
                projectTeamRoleId: this.projectTeamRoleId,
                projectTeamRoleName: this.projectTeamRoleName,
              });
            }
          } else {
            // console.log("userId不存在");
          }
        }
        if (params.length) this.addMember(params);
      });
    },

    //打开成员菜单
    async openMemberMenuModal() {

      const arr = this.userProjectPermissionArr;
      let projectId = this.$route.query.projectId;
      const modal = await modalController.create({
        component: MemberMenu,
        // cssClass: "project-bottom-modal-class",
        componentProps: {
          projectId: projectId,
          hasAddMember: this.hasAddMember,
          hasEditMemberRole: this.hasEditMemberRole,
          // hasDelMember: this.hasDelMember,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data) {
        const retUserList = data.data;
        if (data.action == "addMember") {
          this.operateAddedMember(retUserList, projectId);
        }
        if (data.action == "settingRoles") {
          const modal = await modalController.create({
            component: RoleManager,
            cssClass: "notice-modal-class",
            componentProps: {
              receiveTreeId: this.projectId,
              hasAddMember: this.hasAddMember,
              hasEditMemberRole: this.hasEditMemberRole,
            },
          });

          await modal.present();
          const { data } = await modal.onWillDismiss();
          // if (data) {
          let obj: any = this.$refs.allMember;
          obj.refreshPage();
          // }
          let allMembe: any = this.$refs.allMember;
          if (allMembe) allMembe.getAllTeamRoles(projectId);
        }
      }
    },
    //添加成员
    addMember(params: any) {
      this.projectMemberService.savaList(params).then(
        (r: any) => {
          if (r) {
            // console.log("添加成功");
            // this.initTaskData();
            let obj: any = this.$refs.allMember;
            if (obj != null) {
              obj.initTaskData();
            }
          }
        },
        (e: any) => {
          // console.log("添加成员-addMember-error");
        }
      );
    },

    //根据用户ID获取项目权限
    getUserPermission() {
      this.permissionService
        .getUserPermissionByProject(this.projectId + "")
        .then(
          (resp: AllResponse<Permission>) => {
            if (resp) {
              this.userProjectPermissionArr = resp.data;
              //新增权限
              this.hasAddMember = this.permissionService.isHasPermissionByCode(
                this.userProjectPermissionArr,
                "project.del.member"
              );
              //删除权限
              this.hasDelMember = this.permissionService.isHasPermissionByCode(
                this.userProjectPermissionArr,
                "project.del.member"
              );
              //编辑成员 角色 、团队信息、团队角色信息权限
              this.hasEditMemberRole = this.permissionService.isHasPermissionByCode(
                this.userProjectPermissionArr,
                "project.put.member"
              );
            }
          },
          (e: any) => {
            // console.log("MemberManage-获取权限-异常");
            // console.log(e);
          }
        );
    },
    //获取项目角色
    getProjectRoles() {
      this.projectService.getRolesByProjectId().then(
        (r: any) => {
          if (r) {
            this.projectRoleList = r.data;
            this.projectRoleList.forEach((ele: any) => {
              if (ele.isDefault) {
                this.projectRoleId = ele.id;
                this.projectRoleName = ele.name;
              }
            });
          }
        },
        (e: any) => {}
      );
    },
    //获取团队角色
    getAllTeamRoles(projectId: string) {
      // console.log(projectId);
      const projectTeamService = new ProjectTeamService();
      projectTeamService.getAllNodeByTreeId(projectId).then(
        (r: any) => {
          if (r) {
            this.teamRoleList = r;
            this.teamRoleList.forEach((ele: any) => {
              if (ele.isDefault) {
                this.projectTeamRoleId = ele.id;
                this.projectTeamRoleName = ele.name;
              }
            });
          }
        },
        (e: any) => {}
      );
    },
    //搜索 给子组件传值
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchValue = value.searchKey;
      let obj: any = this.$refs.allMember;
      obj.searchHandler(value);
    },
  },
});
