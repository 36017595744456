
import { defineComponent } from "vue";
import {
  modalController,
  IonPage,
  IonContent,
  alertController,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { ProjectService } from "@/services/project/projectService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "ProjectTransfer",
  components: {
    IonPage,
    HeaderModal,
    IonContent,
    SearchBar,
  },
  props: {
    projectId: String,
  },
  data() {
    const projectService = new ProjectService();
    return {
      persons: [],
      projectService: projectService,
      seachParams: {
        projectId: this.projectId,
        menberName: "",
        offset: 1,
        limit: 10,
      },
      selectedId: "",
      selectedItem: {},
    };
  },
  created() {
    this.initTaskData();
  },
  watch: {
    selectedId() {
      if (this.selectedId != "") {
        this.itemClick(this.selectedItem);
      }
    },
  },
  methods: {
    initTaskData() {
      this.requestDate((result: any) => {
        this.persons = result;
      });
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.seachParams.menberName = data.searchKey;
      this.seachParams.offset = 1;
      this.requestDate((result: any) => {
        this.persons = result;
      });
    },
    nextPage(event: any) {
      this.seachParams.offset++;
      this.requestDate((result: any) => {
        this.persons = this.persons.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.seachParams.offset = 1;
      event.target.disabled = true;
      setTimeout(() => {
        event.target.disabled = false;
      }, 2000);
      this.requestDate((result: any) => {
        event.target.complete();
        this.persons = result;
      });
    },
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        this.selectedId = item.id;
        this.selectedItem = item;
      } else {
        if (this.selectedId != "" && this.selectedId != item.id) {
        } else {
          this.selectedId = "";
          this.selectedItem = {};
        }
      }
    },
    rowClick(item: any) {
      if (this.selectedId == item.id) {
        if (this.selectedId != "") {
          this.selectedId = "";
          this.selectedItem = {};
        }
      } else {
        this.selectedId = item.id;
        this.selectedItem = item;
      }
    },
    async itemClick(person: any) {
      const alert = await alertController.create({
        header: "项目转移",
        message: "您确定要把此项目转移给" + person.name + "吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              this.selectedId = "";
              this.selectedItem = {};
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: () => {
              console.log("Confirm Ok");
              modalController.dismiss({
                isReflush: true,
                belonger: person.userName,
                belongerId: person.userId,
              });
            },
          },
        ],
      });

      await alert.present();
    },
    closeModel() {
      modalController.dismiss();
    },
    requestDate(calblack: any) {
      this.projectService
        .getPermissionUserByProjectIdApp(this.seachParams)
        .then(
          (r: any) => {
            if (r) {
              calblack(r.data);
            } else {
              console.log("无组织");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
    },
  },
});
