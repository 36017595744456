
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { Px2rem } from "../libs/px2rem";

export default defineComponent({
  name: "Header",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonButton,
  },
  emits: ["transfer", "on-back"],
  data() {
    const router = useRouter();
    // const len = this.btnIconRight != null ? this.btnIconRight.length : 0;
    // const lenR = this.btnSizeRight != null ? this.btnSizeRight.length : 0;
    return {
      router: router,
      iconfont: "iconfont",
      // //标题左侧按钮
      btnIconLeft_: "",
      btnSizeLeft_: "",
      // //标题右侧按钮
      btnIconRight_: new Array(),
      btnSizeRight_: new Array(),
      right_: "",
      isRight: true,
      getBgColor: this.bgColor,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
      default: "0", //0:默认头部，1：首页头部，2：较大头部
    },
    isBack: {
      type: Boolean,
      required: false,
      default: true, //默认显示左侧返回按钮
    },
    bgColor: {
      //toolbar背景色
      type: String,
      default: "", //默认显示左侧返回按钮
    },
    //按钮字体标识
    btnIconLeft: {
      type: String,
      required: false,
      default: "",
    },
    //字体大小
    btnSizeLeft: {
      type: String,
      required: false,
      default: "",
    },
    //中间标题
    title: {
      type: String,
      required: false,
      default: "",
    },
    //文本内容
    btnTextRight: {
      type: Array,
      required: false,
      default: null,
    },
    //按钮图片路径
    btnImgRight: {
      type: Array,
      required: false,
      default: null,
    },
    //按钮字体标识
    btnIconRight: {
      type: Array,
      required: false,
      default: null,
    },
    //字体大小
    btnSizeRight: {
      type: Array,
      required: false,
      default: null,
    },
  },
  watch: {
    btnIconRight(newValue,oldValue) {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.type == "0") {
        //普通
      } else if (this.type == "1") {
        //首页
      } else if (this.type == "2") {
        //较高头部
      } else {
        this.bindMoreOperate(-1, "");
      }
      if (this.btnIconLeft == "" && this.isBack) {
        this.btnIconLeft_ = this.iconfont + " icon-fanhui";
      } else {
        this.btnIconLeft_ = this.iconfont + " " + this.btnIconLeft;
      }
      if (this.btnSizeLeft == "") {
        this.btnSizeLeft_ = Px2rem.getRem("28px");
      } else {
        this.btnSizeLeft_ = Px2rem.getRem(this.btnSizeLeft);
      }
      if (
        this.btnTextRight == null &&
        this.btnIconRight == null &&
        this.btnImgRight == null
      ) {
        this.isRight = false;
      } else {
        let len = 0;
        if (this.btnIconRight != null) {
          this.isRight = true;
          len = this.btnIconRight.length;
          this.btnIconRight_=new Array(0);
          for (let index = 0; index < len; index++) {
            let btnIconRightItem=this.btnIconRight[index]+"";
            if(btnIconRightItem.split('_').length>1){
              this.btnIconRight_[index] =
                this.iconfont + " " + btnIconRightItem.split('_')[0]+ " "+btnIconRightItem.split('_')[1];
            }else{
              this.btnIconRight_[index] =
                this.iconfont + " " + btnIconRightItem;
            }
          }
        }
        if (this.btnTextRight != null) {
          len = this.btnTextRight.length;
        }
        if (len > 0) {
          for (let index = 0; index < len; index++) {
            if (
              this.btnSizeRight != null &&
              this.btnSizeRight.length >= index
            ) {
              this.btnSizeRight_[index] = Px2rem.getRem(
                this.btnSizeRight[index] + ""
              );
            } else {
              this.btnSizeRight_.push(Px2rem.getRem("28px"));
            }
          }
        }
      }
    },
    goBack() {
      this.$emit("on-back");
      this.router.go(-1);
    },
    bindLeftOperate() {
      if (this.isBack) {
        this.goBack();
      } else {
        this.right_ = "-1";
        this.setResult();
      }
    },
    bindMoreOperate(type: number, key: string) {
      this.right_ = type + "_" + key;
      this.setResult();
    },
    setResult() {
      //左侧按钮回调参数:-1，右侧按钮回调参数:按钮数组索引_(img/icon/text)
      this.$emit("transfer", this.right_);
    },
    clearRightBtn(isClear: boolean = true) {
      if (isClear) this.isRight = false;
    },
  },
});
