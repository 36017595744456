import { UserRelationApi } from '@/api/mine/UserRelationApi';
import BaseService from '../base/BaseService';
/**
 * 我的上下级  
 */
export class UserRelationService extends BaseService {
    private api = new UserRelationApi();
    /**
     * 我的上下级列表
     * @param param 
     */
    public getRelationList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getRelationList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            })
        })
    }
    /**
     * 添加上级
     */
    public insertSuperior(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.insertSuperior(data).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err)
            })
        })
    }
    /**
     * 添加下级
     */
    public insertSubordinate(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.insertSubordinate(data).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err)
            })
        })
    }
    /**
     * 删除关系人员
     */
    public updateFieldsById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.updateFieldsById(data).then(async (res: any) => {
                resolve(res.data)
            }).catch((err: any) => {
                reject(err)
            })
        })
    }
     /**
    * 删除关系人员
    */
   public getLeaderIds(data: any): Promise<any> {
       return new Promise((resolve, reject) => {
           this.api.getLeaderIds(data).then(async (res: any) => {
               resolve(res.data)
           }).catch((err: any) => {
               reject(err)
           })
       })
   }
}