import TaskBaseService from '../base/TaskBaseService';
/**
 * 首页
 */
export class PermissionApi extends TaskBaseService {

  constructor() {
    super("permissions");
  }

  /**
   * 获取任务权限（根据ProjcetId）
   * @param param projectId
   */
  public getTaskPermissionByProject(param: any): Promise<any> {
    const path = 'getTaskPermissionByProject';
    return this.request(path, this.rest.METHOD_GET, param);
  }

  /**
   * 获取用户权限（根据ProjcetId和userid）
   * @param param 
   */
  public getUserPermissionByProject(param: any) {
    const path = 'getUserPermissionByProject';
    return this.request(path, this.rest.METHOD_GET, param);
  }
  /**
   * 根据企业Id,获取用户的企业权限
   * @param enterpriseId 企业Id
   */
  public getByEnterpriseIdAndUser(enterpriseId: string, userId: string): Promise<any> {
    let path = "getByEnterpriseIdAndUser";
    return this.request(path, this.rest.METHOD_GET, {
      enterpriseId: enterpriseId,
      userId: userId
    });
  }

  /**
   * 获取独立任务-用户操作权限
   * @param param （userId和taskId【必填】）
   */
  public getTaskPermissionByTaskIdApp(param: any) {
    return this.request("getTaskPermissionByTaskIdApp", this.rest.METHOD_GET, param);
  }
}