
import { defineComponent } from "vue";
export default defineComponent({
  name: "NoData",
  props: {
    message: String,
  },
  data () {
    return {
      msg:this.message?this.message:"未查询到目标"
    }
  }
});
