/**
 * schedule 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class ScheduleTimeApi extends TaskBaseService {  
    constructor() {
        super("scheduleTime");
    }
       //删除日程
       public clearById(param: any): Promise<any> {
        const path = 'clearById?id='+param.scheduleTimeId;
        return this.request(path, this.rest.METHOD_DELETE);
    }

    
}