/**
 * 微议题 api
 */
import BaseRsp from '@/models/base/BaseRsp';
import Issues from '@/models/microIssues/Issues';
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class MicroissuesApi extends TaskBaseService {

    constructor() {
        super("appTopic");
    }

    // 新增微议题
    public addMicroIssues(issues: Issues) {
        const path = "";
        return this.request(path, this.rest.METHOD_POST, issues);
    }

    // 修改微议题
    public updateMicroIssues(issues: Issues) {
        const path = "app/updateTopic";
        return this.request(path, this.rest.METHOD_PUT, issues);
    }

    /**
     * 获取微议题列表
     * @param condition 查询条件
     */
    public getMicroIssuesList(condition: any) {
        const path = "getAppTopicListByParams";
        return this.request(path, this.rest.METHOD_GET, condition);
    }

    /**
     * 获取微议题回收站列表
     * @param condition 查询条件
     */
    public getMicroIssuesRecycleList(condition: any) {
        const path = "deleteList";
        return this.request(path, this.rest.METHOD_GET, condition);
    }

    /**
    * 获取微议题详情
    * @param id 微议题id
    */
    public getIssuesInfo(id: string) {
        const path = "";
        return this.request(path + id, this.rest.METHOD_GET);
    }

    /**
    * 微议题移至/移出回收站
    * @param id 微议题id
    * @param isCollect 当前是否在回收站
    */
    public recycleIssues(id: string, isCollect: boolean) {
        const path = "app/topic/"+ id;
        if (isCollect) {
            return this.request(path, this.rest.METHOD_POST);
        } else {
            return this.request(path, this.rest.METHOD_DELETE);
        }
    }

    //删除微议题
    public deleteById(id: string):Promise<any> {
        const path = "clearById?id="+ id; 
       return this.request(path, this.rest.METHOD_DELETE); 
    }

    public getFolderIdByBusInfo(param: any){
        const path = "getFolderIdByBusInfo";
        return this.request(path, this.rest.METHOD_GET, param);
    }

    public getBusIsDelete(param: any){
        const path = "getBusIsDelete";
        return this.request(path, this.rest.METHOD_GET, param);
    }


}