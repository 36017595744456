
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import ProjectDoc from "../../project/document/ProjectDoc.vue";
export default defineComponent({
  name: "document",
  components: {
    ProjectDoc,
    Header,
  },
  data() {
    return {
      projectFilePermission: {
        isHasFileUpload: false, //上传文件权限
        isHasFileDownload: true, //下载文件权限
        isHasFileDel: false, //删除文件权限
        isHasFilePutArchive: false, //移到回收站权限/恢复回收站权限
      },
      folderId: "",
    };
  },
  props: {},
  created() {
    this.folderId = this.$route.query.folderId as any;
  },
  methods: {
  },
});
