
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  IonTextarea,
  modalController,
} from "@ionic/vue";
import Utils from "@/utils/Utils";
import Settings from "@/rest/Setting";
import ChooseOrganization from "@/views/project/modal/ChooseOrganization.vue";
import ProjectOpennessModal from "@/views/project/modal/ProjectOpennessModal.vue";
import ProjectTypeModal from "@/views/project/modal/ProjectTypeModal.vue";
import ProjectGroupingModal from "@/views/project/modal/ProjectGroupingModal.vue";
import ProjectStatusModal from "@/views/project/modal/ProjectStatusModal.vue";
import ProjectTransfer from "./../../projectTransfer/ProjectTransfer.vue";
import { ProjectService } from "@/services/project/projectService";
import { getInstance } from "@/services/projectPageService";
export default defineComponent({
  name: "ProjectInfo",
  components: {
    IonContent,
    IonText,
    IonInput,
    IonTextarea,
  },
  props: {
    projectId: String,
    isHasPermission: Boolean,
  },
  emits: ["onSave"],
  data() {
    const projectService = new ProjectService();
    const projectPageService = getInstance();
    return {
      groupingOption: { id: "", name: "点击选择" },
      opennessOption: { key: "0", value: "点击选择" },
      typeOption: { id: "", name: "点击选择" },
      organization: { id: "", name: "点击选择", description: "" },
      statusOption: {
        id: "",
        name: "点击选择",
        projectStatusNameDe: "",
        projectStatusNameEn: "",
      },
      staged: { id: "" },
      projectService: projectService,
      projectPageService: projectPageService,
      fromData: {
        pubickCheck: 0,
        belonger: "",
        no: "",
        name: "", //项目名称
        beginTime: "",
        endTime: "",
        awakeDate: "",
        organizationId: "",
        organizationName: "",
        organizationNameCn: "",
        organizationNameDe: "",
        organizationNameEn: "",
        projectGroupId: "", //项目分组Id
        projectTypeId: "", //项目类型id
        isPublic: false,
        synopsis: "", //简介
        taskNoPrefix: "", //任务前缀
        projectStatus: "",
      },
      selValue: "",
      options: [{
        id:"",
        nameCn:"",
        nameDe:"",
        nameEn:""
      }],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getProjectInfoById();
    },

    //项目转移
    //  enterAnimation: this.animationRight.enterAnimation,
    // leaveAnimation: this.animationRight.leaveAnimation,
    async projectTransfer() {
      const modal = await modalController.create({
        component: ProjectTransfer,
        cssClass: "notice-modal-class",
        mode: "ios",

        componentProps: {
          projectId: this.projectId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.isReflush) {
        const newModel = {
          belonger: data.belonger,
          belongerId: data.belongerId,
          id: this.projectId,
          setFields: ["belongerId", "belonger"],
        };
        
        this.projectService.updateFieldsById(newModel).then(
          (r: any) => {
            if (r) {
              console.log("保存成功");
              this.fromData.belonger = newModel.belonger;
            } else {
              console.log("暂无数据");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },

    getProjectStatusById(statusId:string) {
      this.options = [];
      //获取项目状态
      const parmsStatus = {
        projectId: this.projectId,
      };
      this.projectPageService.getByProjectStatusId(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            this.options = r.data;
            this.statusOption.id = statusId;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
      if (this.statusOption.id) this.selValue = this.statusOption.id;
    },
    //保存
    bindSave() {
      //  this.router.push("/project");
      const parms = this.fromData;
      this.fromData.projectStatus = this.statusOption.id;
      //选择公开性现在key为 0 1
      if (this.opennessOption.key == "0") {
        parms.isPublic = true;
      } else {
        parms.isPublic = false;
      }
      if (!this.fromData.name) {
        Utils.presentToastWarning("请输入项目名称");
        return;
      } else {
        if (this.fromData.name.length > 200) {
          Utils.presentToastWarning("项目名称不能超过200个字符");
          return;
        }
      }
      if (
        this.fromData.beginTime &&
        this.fromData.beginTime != "" &&
        this.fromData.endTime &&
        this.fromData.endTime != ""
      ) {
        let startDate = new Date(this.fromData.beginTime.replace(/\-/g, "/"));
        if (this.fromData.beginTime.indexOf("T") > -1) {
          startDate = new Date(
            this.fromData.beginTime.substring(0, 10) +
              " " +
              this.fromData.beginTime.substring(11, 16)
          );
        }
        let endDate = new Date(this.fromData.endTime.replace(/\-/g, "/"));
        if (this.fromData.endTime.indexOf("T") > -1) {
          endDate = new Date(
            this.fromData.endTime.substring(0, 10) +
              " " +
              this.fromData.endTime.substring(11, 16)
          );
        }

        if (startDate.getTime() > endDate.getTime()) {
          Utils.presentToastWarning("开始日期不能晚于结束日期");
          return;
        }
      }

      if (this.fromData.no.length > 50) {
        Utils.presentToastWarning("业务编号不能超过50个字符");
        return;
      }
      if (this.fromData.taskNoPrefix.length > 50) {
        Utils.presentToastWarning("任务前缀不能超过50个字符");
        return;
      }

      if (this.fromData.synopsis.length > 200) {
        Utils.presentToastWarning("项目简介不能超过200个字符");
        return;
      }

      const newModel = Object.assign(parms, {
        setFields: [
          "isPublic",
          "name",
          "organizationId",
          "organizationName",
          "organizationNameCn",
          "organizationNameEn",
          "no",
          "taskNoPrefix",
          "synopsis",
          "projectTypeId",
          "beginTime",
          "endTime",
          "awakeDate",
          "projectStatus",
        ],
      });

      //更新
      this.projectService.updateFieldsById(newModel).then(
        (r: any) => {
          if (r) {
            //更新项目分组
            this.updateProjectGroupToProjectByUser(parms.projectGroupId);
            this.$emit("onSave", this.fromData);
          } else {
            console.log("未选择信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    // 项目状态
    async openProjectStatusModal() {
      const modal = await modalController.create({
        component: ProjectStatusModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.statusOption.id,
          projectId: this.projectId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectObj) {
        this.statusOption.id = data.selectObj.id;
        this.statusOption.name = data.selectObj.nameCn;
        this.statusOption.projectStatusNameDe = data.selectObj.nameDe;
        this.statusOption.projectStatusNameEn = data.selectObj.nameEn;
        this.fromData.projectStatus = data.selectObj.id;
      }
    },
    // 分组Modal
    async openProjectGroupingModal() {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.groupingOption.id,
          isSelPhases: false,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.group) {
        this.groupingOption.id = data.group.id;
        this.groupingOption.name = data.group.name;
        this.fromData.projectGroupId = data.group.id;
      }
    },
    // 所属机构Modal
    async openChooseOrganizationModal() {
      const modal = await modalController.create({
        component: ChooseOrganization,
        cssClass: "notice-modal-class",
        componentProps: {
          finalSelId: this.organization.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        this.organization.id = data.selectedObj.orgId;
        this.organization.name = data.selectedObj.shortName;
        this.organization.description = data.selectedObj.description;
        this.fromData.organizationId = data.selectedObj.orgId;
        this.fromData.organizationName = data.selectedObj.shortName;
        this.fromData.organizationNameCn = data.selectedObj.description;
        this.fromData.organizationNameDe = data.selectedObj.shortName;
        this.fromData.organizationNameEn = data.selectedObj.shortName;
      }
    },

    bindProjectStatus(e: any) {
      this.statusOption.id = e.detail.value;
      for (let index = 0; index < this.options.length; index++) {
        const element = this.options[index];
        if (element.id == this.statusOption.id) {
          this.statusOption.name = element.nameCn;
          this.statusOption.projectStatusNameDe = element.nameDe;
          this.statusOption.projectStatusNameEn = element.nameEn;
        }
      }
    },

    // 公开性
    bindProjectPublishStatus(e: any) {
      this.opennessOption.key = e.detail.value;
    },

    // 类型Modal
    async openProjectTypeModal() {
      const modal = await modalController.create({
        component: ProjectTypeModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.typeOption.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.typeOption.id = data.typeObj.id;
        this.typeOption.name = data.typeObj.name;
        this.fromData.projectTypeId = data.typeObj.id;
      }
    },
    /**
     * 绑定项目信息
     */
    getProjectInfoById() {
      if (this.projectId) {
        this.projectService.getForInforSet({ projectId: this.projectId }).then(
          (r: any) => {
            if (r && r.data) {
              //渲染数据
              const retData = r.data;
              this.fromData = retData;
              this.groupingOption.id = retData.projectGroupId;
              this.groupingOption.name = retData.projectGroupName;
              if (!retData.projectGroupId && this.isHasPermission) {
                this.groupingOption.name = "点击选择";
              }
              this.typeOption.id = retData.projectTypeId;
              this.typeOption.name = retData.projectTypeName;
              this.organization.id = retData.organizationId;
              this.organization.name = retData.organizationNameEn;
              this.organization.description = retData.organizationNameCn;

              if (!retData.organizationId && this.isHasPermission) {
                this.organization.name = "点击选择";
                this.organization.description = "点击选择";
              }
              
              this.statusOption.name = retData.projectStatusName;
              this.statusOption.projectStatusNameDe =
                retData.projectStatusNameDe;
              this.statusOption.projectStatusNameEn =
                retData.projectStatusNameEn;
              if (retData.isPublic) {
                this.opennessOption.key = "0";
                this.opennessOption.value = "公开";
              } else {
                this.opennessOption.key = "1";
                this.opennessOption.value = "非公开";
              }

              this.getProjectStatusById(retData.projectStatusId);
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },

    //更新项目分组
    updateProjectGroupToProjectByUser(groupId: string) {
      if (this.projectId) {
        this.projectService
          .updateProjectGroupToProjectByUser({
            projectId: this.projectId,
            projectGroupId: groupId,
          })
          .then(
            (r: any) => {
              if (r && r.data) {
                Utils.presentToastSuccess("保存成功", "success");
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
      }
    },
  },
});
