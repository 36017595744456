
import { defineComponent } from "vue";
import {
  modalController,
  IonPage,
  IonContent,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import ProjectInfo from "./child/ProjectInfo.vue";
import StatusSetting from "./child/StatusSetting.vue";
import PermissionSetting from "@/views/task/menu/setting/Permission.vue";
import SetOrganizationList from "@/views/task/menu/setting/OrganizationList.vue";

export default defineComponent({
  name: "ProjectSetting",
  components: {
    IonPage,
    HeaderModal,
    IonContent,
    IonSegment,
    IonSegmentButton,
    SetOrganizationList,
    ProjectInfo,
    StatusSetting,
    PermissionSetting,
  },
  props: {
    projectId: String,
    isHasPermission: Boolean,
  },
  data() {
    return {
      indicators: ["项目信息", "状态设置", "任务设置", "参与机构"],
      selSeg: 0,
      projectInfo: null,
      $refs:{
         ProjectInfoDom:ProjectInfo
      }
    };
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.selSeg = ev.detail.value;
    },
    closeModel() {
      if (this.projectInfo) {
        modalController.dismiss({ projectInfo: this.projectInfo });
      } else {
        modalController.dismiss();
      }
    },
    onSave(projectModel: any) {
      this.projectInfo = projectModel;
    },
    //项目信息保存调用子组件方法
    ProjectInfoSave(){
        this.$refs.ProjectInfoDom.bindSave()
    }
  },
});
