import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';


// 建立用户与菜单关系
export function createUserSiteMap(data){
    return request('/problem/createUserSiteMap.do',"POST",data)
}

// 查询用户与菜单关系
export function selectUserSiteMap(data){
    return request('/problem/selectUserSiteMap.do',"POST",data)
}