
import { defineComponent, toRefs, ref, reactive } from "vue";
import { popoverController } from "@ionic/vue";

export default defineComponent({
  name: "CalendarMorePopover",
  props: {
    CalendarCardObj: Object,
    ShowMenu: Boolean,
    ShowShare: Boolean,
  },
  setup(props) {
    const { CalendarCardObj, ShowMenu, ShowShare } = toRefs(props);
    const paramsMenu = ref(false);
    const paramsShare = ref(false);
    if (ShowMenu != undefined && ShowMenu.value != undefined) {
      paramsMenu.value = ShowMenu.value;
    }
    if (ShowShare != undefined && ShowShare.value != undefined) {
      paramsShare.value = ShowShare.value;
    }
    const editItem = () => {
      closePopover("edit");
    };
    const deleteItem = () => {
      closePopover("delete");
    };
    const shareItem = () => {
      closePopover("share");
    };
    const closePopover = (type: string) => {
      if (CalendarCardObj != undefined && CalendarCardObj.value != undefined) {
        popoverController.dismiss({
          data: {
            type: type,
            obj: CalendarCardObj.value,
          },
        });
      }
    };
    return { paramsMenu, paramsShare, editItem, deleteItem, shareItem };
  },
});
