/**
 * ProjectStatusChangesApi 项目状态变更 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目状态变更
 */
export class ProjectStatusChangesApi extends TaskBaseService {

    constructor() {
        super("projectStatusChanges");
    }
    
    public getByProjectIdForLsit(params: any): Promise<any> {
        const path = 'getByProjectIdForLsit';
        
        return this.request(path,this.rest.METHOD_GET, params);       
     }
     
}