/**
 * 任务参与人
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class TaskUserApi extends TaskBaseService {

    constructor() {
        super("taskUsers");
    }

    //删除
    public deleteTaskUser(params: any): Promise < any > {
        const path = 'clearByTaskIdUserIdWorkType?taskId='+params.taskId+"&userId="+params.userId+"&workType="+params.workType;
        return this.request(path, this.rest.METHOD_DELETE);
    }

    public insertTaskUser(data: any): Promise < any > {
        return this.request("", this.rest.METHOD_POST, data);
    }
    
    //更新任务阅读状态
    public updateReadStatusByTaskIdAndUser(params: any): Promise < any > {
        const path="updateReadStatusByTaskIdAndUser?taskId="+params.taskId+"&userId="+params.userId;
        return this.request(path, this.rest.METHOD_PUT, {});
    }

}