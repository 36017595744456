import BaseRsp from '../base/BaseRsp';
import Member from './Member';

export class IssuesListRsp extends BaseRsp {
    issues?: Array<Issues> = this.data;
}

export class IssuesRsp extends BaseRsp{
    issues?: Issues = this.data;
}

export default class Issues {
    id?: string;            // "002",
    creatorId?: string;         // "67751",
    creator?: string;           // "钱培庆",
    createTime?: string;            // "2020-12-16 14:32:46",
    modifierId?: string;            // "67751",
    modifier?: string;          // "钱培庆",
    modifyTime?: string;            // "2020-12-16 17:07:59",
    dataState?: string;         // -1,
    remark?: string;            // "",
    systemId?: string;          // "",
    enterpriseId?: string;          // "",
    title?: string;         // "lizk-topic修改2",
    groupId?: string;           // "a65cf9a6-20b0-41d4-81a1-fa36bb06ee4a",
    projectId?: string;         // "p2",
    projectName?: string;           // "p2项目",
    taskId?: string;            // "t2",
    taskName?: string;          // "t2任务",
    status?: string;            // "0",
    visible?: string;           // "0",
    topicParticipantList?: Array<Member>;          // "",
    newTopicParticipantList?: Array<Member>;           // "",
    isDeleted: boolean = false;         // false
    users?: Array<any>;
}

