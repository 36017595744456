
import Utils from "@/utils/Utils";
import { defineComponent } from "vue";
import { ProjectViewService } from "@/services/project/ProjectViewService";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "ProjectTreeView",
  components: {},
  data() {
    const params = useRoute().query;
    const router = useRouter();
    return {
      projectViewService: new ProjectViewService(),
      projectId: params.projectId,
      data:[],
      router: router,
      useRoute: useRoute(),
    };
  },
  mounted() {
    //请求数据
    this.projectViewService.getTreeTasksByProject(this.projectId + "").then(
      (r: any) => {
        if (r.data) {
           this.data = r.data;
        }
      },
      (e: any) => {
        console.log(e);
      }
    );
  },
  methods: {
    clickItem(data: any) {
      //// Utils.presentToastCenter("跳转任务详情:【" + data.name + "】");
      // this.router.push({
      //   path: "/task-page",
      //   query: {
      //     id: data.id,
      //     taskName: data.name,
      //   },
      // });
      console.log(data);
    },
  },
});
