/**
 * ProjectTypes 项目类型 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectTypesApi extends TaskBaseService {

    constructor() {
        super("projectTypes");
    }
    //获取项目类型
    public getBySystemId(params: any): Promise<any> {
       const path = 'getBySystemId';
       return this.request(path,this.rest.METHOD_GET, params);       
    }

    //获取类型信息
    public getById(params: any): Promise<any> {
        const path = '';
        return this.request(path,this.rest.METHOD_GET, params);       
    }

}