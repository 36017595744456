<template>
  <div >
    <ion-header class="theme2">
      <ion-toolbar>
        <ion-title slot="start">查询条件编辑</ion-title>
        <ion-buttons slot="end" class="close">
          <ion-button @click="onClose()">
            <span class="iconfont iconguanbi"></span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="PanelBox">
        <div class="item">
          <ion-text class="label">任务名称</ion-text>
          <div class="content one">
            <ion-input class="search" placeholder="" v-model="name"></ion-input>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">执行人</ion-text>
          <div class="content two">
            <!-- <div>
              <img src="@/assets/mine/user_photo_def.png" alt="" />
              <p>王利军</p>
            </div>  -->
            <div>
              <div class="img" @click="selectExecutor()">
                <span class="iconfont iconjia"></span>
              </div>
              <p>更多</p>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">参与人</ion-text>
          <div class="content two">
            <div>
              <div class="img" @click="selectJoiner()">
                <span class="iconfont iconjia"></span>
              </div>
              <p>更多</p>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">创建人</ion-text>
          <div class="content two">
            <div>
              <img src="@/assets/mine/user_photo_def.png" alt="" />
            </div>
          </div>
        </div>
        <div class="item">
          <div class="content three">
            <ion-text class="label">创建时间</ion-text>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="createTimeStart"
              ref="createTimeStart"
              mode="ios"
            >
            </ion-datetime>
            <span>一</span>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="createTimeEnd"
              ref="createTimeEnd"
              mode="ios"
            >
            </ion-datetime>
          </div>
        </div>
        <div class="item">
          <div class="content three">
            <ion-text class="label">开始时间</ion-text>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="startTimeStart"
              ref="startTimeStart"
              mode="ios"
            >
            </ion-datetime>
            <span>一</span>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="startTimeEnd"
              ref="startTimeEnd"
              mode="ios"
            >
            </ion-datetime>
          </div>
        </div>
        <div class="item">
          <div class="content three">
            <ion-text class="label">截止时间</ion-text>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="endTimeStart"
              ref="endTimeStart"
              mode="ios"
            >
            </ion-datetime>
            <span>一</span>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="endTimeEnd"
              ref="endTimeEnd"
              mode="ios"
            >
            </ion-datetime>
          </div>
        </div>
        <div class="item">
          <div class="content three">
            <ion-text class="label">完成时间</ion-text>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="finishTimeStart"
              ref="finishTimeStart"
              mode="ios"
            >
            </ion-datetime>
            <span>一</span>
            <ion-datetime
              class="search"
              displayFormat="YYYY-MM-DD"
              min="2020"
              cancelText="取消"
              doneText="确认"
              :value="finishTimeEnd"
              ref="finishTimeEnd"
              mode="ios"
            >
            </ion-datetime>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">优先级</ion-text>
          <div class="content one">
            <div v-for="(item, index) in priorityArr" :key="index">
              <div
                @click="getPriority(item.value)"
                :class="
                  priority.includes(item.value) ? 'labelBtn active' : 'labelBtn'
                "
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">状态</ion-text>
          <div class="content one">
            <div v-for="(item, index) in statusArr" :key="index">
              <div
                v-if="index < 5"
                :class="
                  overdueStatus.includes(item.value)
                    ? 'labelBtn active'
                    : 'labelBtn'
                "
                @click="getStatus(item.value)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- <div class="content one">
            <div v-for="(item, index) in statusArr" :key="index">
              <div
                v-if="index >= 5"
                :class="
                  overdueStatus.includes(item.value) ? 'labelBtn active' : 'labelBtn'
                "
                @click="getStatus(item.value)"
              >
                {{ item.name }}
              </div>
            </div>
          </div> -->
        </div>
        <div class="item">
          <ion-text class="label">结束时间</ion-text>
          <div class="content one">
            <div v-for="(item, index) in endDateArr" :key="index">
              <div
                v-if="index < 5"
                @click="getEndDate(item.value)"
                :class="endDate == item.value ? 'labelBtn active' : 'labelBtn'"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="content one">
            <div v-for="(item, index) in endDateArr" :key="index">
              <div
                v-if="index >= 5"
                @click="getEndDate(item.value)"
                :class="endDate == item ? 'labelBtn active' : 'labelBtn'"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">是否完成</ion-text>
          <div class="content one">
            <div v-for="(item, index) in isFinishArr" :key="index">
              <div
                @click="getFinish(item.value)"
                :class="isFinish == item.value ? 'labelBtn active' : 'labelBtn'"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">来源</ion-text>
          <div class="content one">
            <div v-for="(item, index) in systemIdArr" :key="index">
              <div
                @click="getsystemId(item)"
                :class="
                  systemId.includes(item) ? 'labelBtn active' : 'labelBtn'
                "
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">查询条件名称</ion-text>
          <div class="content one">
            <ion-input
              placeholder="查询条件名称"
              v-model="newSName"
            ></ion-input>
          </div>
          <div class="content four">
            <p><ion-checkbox></ion-checkbox>&nbsp;设为默认</p>
            <p><ion-checkbox></ion-checkbox>&nbsp;推送到首页定制</p>
          </div>
        </div>

        <div class="item bottom">
          <div class="content Btn1">
            <ion-button color="success" @click="confirm()"
              >保存</ion-button
            >
            <ion-button color="tertiary" @click="reset()">重置</ion-button>
          </div>
          <div class="content Btn2">
            <ion-button color="warning" @click="cancelDel()">删除</ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </div>
</template>

 
 <style lang="scss" scoped>
@import "@/assets/css/searchPanel";
ion-content{
  height: 100vh;
}
.PanelBox .bottom{
    height: 25rem;
}
</style>

<script>
import { menuController, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
export default defineComponent({
  name: "EditSearchConditionCalendar",
  data() {
    return {
      newSName: "",
      isCondition: false,
      isPull: false,
      searchNameArr: [
        { id: "1", name: "查询条件1" },
        { id: "2", name: "查询条件2" },
        { id: "3", name: "查询条件3" },
        { id: "4", name: "查询条件4" },
      ],
      
      priorityArr: [
        { name: "全部", value: "0" },
        { name: "较低", value: "1" },
        { name: "普通", value: "2" },
        { name: "紧急", value: "3" },
        { name: "非常紧急", value: "4" },
      ],
      statusArr: [
        { name: "全部", value: "0" },
        { name: "未逾期", value: "1" },
        { name: "已逾期", value: "2" },
        { name: "逾期完成", value: "3" },
      ],
      endDateArr: [
        { name: "全部", value: "0" },
        { name: "当天", value: "1" },
        { name: "次日", value: "2" },
        { name: "三日内", value: "3" },
        { name: "五日内", value: "5" },
        { name: "近期", value: "7" },
      ],
      isFinishArr: [
        { name: "全部", value: "9" },
        { name: "已完成", value: "1" },
        { name: "未完成", value: "0" },
      ],
      systemIdArr: ["全部", "主数据", "任务中心", "计划中心"],
      //----筛选条件字段--------------
      searchName: "哈哈哈",
      name: "aaaa", //名称
      createTimeStart: "", //创建时间
      createTimeEnd: "",
      startTimeStart: "", //开始时间
      startTimeEnd: "",
      endTimeStart: "", //截止时间
      endTimeEnd: "",
      finishTimeStart: "", //完成时间
      finishTimeEnd: "",
      priority: ['2','4'], //优先级（多选）不传默认全部
      overdueStatus: "1", //状态（单选）不传默认全部，1未逾期，2已逾期，3逾期完成
      endDate: "2", //完成时间 不传默认全部，1当天 2次日 3三日内 5五日内 7近期
      isFinish: "9", //是否完成 不传默认全部，0未完成，1已完成
      systemId: ['主数据','任务中心'], //来源（多选）不传默认全部
    };
  },
  methods: {
    onClose() {
       modalController.dismiss({});
    },
     //优先级
    getPriority(value) {
      if (this.priority.includes(value)) {
        this.priority = this.priority.filter(function (ele) {
          return ele != value;
        });
      } else {
        if (value != "0") {
          for (let a = 0; a < this.priority.length; a++) {
            if (this.priority[a] == 0) {
              this.priority.splice(a, 1);
              break;
            }
          }
          this.priority.push(value);
        } else {
          this.priority = ["0"];
        }
      }
      if (this.priority.length == 0) {
        this.priority.push("0");
      }
      if (this.priority.length == this.priorityArr.length - 1) {
        if (this.priority.indexOf("0") == -1) {
          this.priority.push("0");
        } else {
          for (let a = 0; a < this.priority.length; a++) {
            if (this.priority[a] == 0) {
              this.priority.splice(a, 1);
              break;
            }
          }
        }
      }
    },
    //状态
    getStatus(value) {
      this.overdueStatus = value;
    },
    //结束时间
    getEndDate(value) {
      this.endDate = value;
    },
    //是否完成
    getFinish(value) {
      this.isFinish = value;
    },
    //来源
    getsystemId(value) {
      if (this.systemId.includes(value)) {
        this.systemId = this.systemId.filter(function (ele) {
          return ele != value;
        });
      } else {
        if (value != "全部") {
          for (let a = 0; a < this.systemId.length; a++) {
            if (this.systemId[a] == "全部") {
              this.systemId.splice(a, 1);
              break;
            }
          }
          this.systemId.push(value);
        } else {
          this.systemId = ["全部"];
        }
      }

      if (this.systemId.length == 0) {
        this.systemId.push("全部");
      }
      if (this.systemId.length == this.systemIdArr.length - 1) {
        if (this.systemId.indexOf("全部") == -1) {
          this.systemId.push("全部");
        } else {
          for (let a = 0; a < this.systemId.length; a++) {
            if (this.systemId[a] == "全部") {
              this.systemId.splice(a, 1);
              break;
            }
          }
        }
      }
    },
    //确定检索
    confirm() {
      if (this.$refs.createTimeStart.value != "") {
        this.createTimeStart = moment(this.$refs.createTimeStart.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.createTimeEnd.value != "") {
        this.createTimeEnd = moment(this.$refs.createTimeEnd.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.startTimeStart.value != "") {
        this.startTimeStart = moment(this.$refs.startTimeStart.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.startTimeEnd.value != "") {
        this.startTimeEnd = moment(this.$refs.startTimeEnd.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.endTimeStart.value != "") {
        this.endTimeStart = moment(this.$refs.endTimeStart.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.endTimeEnd.value != "") {
        this.endTimeEnd = moment(this.$refs.endTimeEnd.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.finishTimeStart.value != "") {
        this.finishTimeStart = moment(this.$refs.finishTimeStart.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }
      if (this.$refs.finishTimeEnd.value != "") {
        this.finishTimeEnd = moment(this.$refs.finishTimeEnd.value).format(
          "yyyy-MM-DD HH:mm:ss"
        );
      }

      const obj = {};
      obj.name = this.name;
      obj.createTimeStart = this.createTimeStart;
      obj.createTimeEnd = this.createTimeEnd;
      obj.startTimeStart = this.startTimeStart;
      obj.startTimeEnd = this.startTimeEnd;
      obj.endTimeStart = this.endTimeStart;
      obj.endTimeEnd = this.endTimeEnd;
      obj.finishTimeStart = this.finishTimeStart;
      obj.finishTimeEnd = this.finishTimeEnd;
      obj.priority = this.priority.toString();
      obj.overdueStatus = this.overdueStatus;
      obj.endDate = this.endDate;
      obj.isFinish = this.isFinish;
      obj.systemId = this.systemId.toString();
      for (const key in obj) {
        if (obj[key] == "") {
          Utils.presentToastWarning("请添加查询条件");
        }
      }
    },
    //删除

    //重置检索条件
    reset() {
      // this.searchName={};
      this.name = ""; //名称
      this.createTimeStart = ""; //创建时间
      this.createTimeEnd = "";
      this.startTimeStart = ""; //开始时间
      this.startTimeEnd = "";
      this.endTimeStart = ""; //截止时间
      this.endTimeEnd = "";
      this.finishTimeStart = ""; //完成时间
      this.finishTimeEnd = "";
      this.priority = ["0"]; //优先级（多选）不传默认全部
      this.overdueStatus = ["0"]; //状态（单选）不传默认全部，1未逾期，2已逾期，3逾期完成
      this.endDate = "0"; //完成时间 不传默认全部，1当天 2次日 3三日内 5五日内 7近期
      this.isFinish = "9"; //是否完成 不传默认全部，0未完成，1已完成
      this.systemId = ["全部"]; //来源（多选）不传默认全部
    }, 
    //执行人
    async selectExecutor() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    },
    //参与人
    async selectJoiner() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "joiner",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    }, 
    
     
  },
});
</script>