import { GroupApi } from '@/api/okr/GroupApi';
import BaseService from '@/services/base/BaseService';
import UserInfo from '../selectexecutor/UserInfo';

export class GroupService extends BaseService {

    private GroupApi = new GroupApi();


    public getGroupList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.GroupApi.getGroupList().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    /**
     * 获取组成员
     * @param param 
     * groupId:团队项目Id
     * name:姓名
     */
    public getGroupUserList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.GroupApi.getGroupUserList(param).then(async (res: any) => {
                let arrayUser = [];
                if (res.data && res.data.length > 0) {
                    for (let index = 0; index < res.data.length; index++) {
                        const element = res.data[index];
                        const user = new UserInfo();
                        user.id = element.userId;
                        user.name = element.userName;
                        user.pernr = element.userId;
                        user.userId = element.userId;
                        //user.department = element.partId;
                        user.department=element.partNameCn;
                        arrayUser.push(user);
                    }
                }
                resolve(arrayUser);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}