
import { modalController, menuController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue"; //
import Header from "@/components/Header.vue";
import PTask from "./ptask/PTask.vue";
import ProjectPageSearchPanel from "./ProjectPageSearchPanel.vue";
import MicroIssuesList from "../microIssues/list/MicroIssuesList.vue";
import ProjectDoc from "./document/ProjectDoc.vue";
import OverViewList from "./overview/OverViewList.vue";
import TeamList from "./team/TeamList.vue";
import ProjectMenu from "./menu/ProjectMenu.vue";
// import GanntPage from "./view/GanntPage.vue";
import ProjectView from "./view/ProjectView.vue";
import { useRouter, useRoute } from "vue-router";
import Chat from "@/views/message/Chat.vue";
// 权限 类
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import { getInstance } from "@/services/projectPageService";
import ProjectTransfer from "./menu/projectTransfer/ProjectTransfer.vue";
import ProjectSetting from "./menu/projectSetting/ProjectSetting.vue";
import { ProjectService } from "@/services/project/projectService";
import SelectLable from "@/views/task/info/selectlable/SelectLable.vue";
import SystemUtils from "@/utils/SystemUtils";
import ChangeTaskShowPullDown from "./modal/ChangeTaskShowPullDown.vue";

import { AnimationSelf } from "@/libs/animation";
import { nextTick } from "process";

export default defineComponent({
  name: "ProjectPage",
  components: {
    PTask,
    MicroIssuesList,
    Chat,
    Header,
    ProjectDoc,
    OverViewList,
    TeamList,
    ProjectPageSearchPanel,
    ProjectView,
  },
  data() {
    const router = useRouter();
    //iconshaixuanxuanzhong
    const btnIconRight: any = [
      "iconiconqiehuan",
      "iconshaixuanxuanzhong",
      "icongengduo",
    ];
    const btnSizeRight: any = ["34px", "34px", "45px"];
    const params = useRoute().query;
    const projectPageService = getInstance();
    const animationRight = new AnimationSelf().modalRight;
    return {
      permissionService: new PermissionService(),
      userProjectPermissionArr: new Array<Permission>(0),
      router: router,
      useRoute: useRoute(),
      // indicators: ["任务", "文档", "概览", "统计", "协同", "微议题", "视图"],
      indicators: ["任务", "文档", "概览", "统计", "协同", "视图"],
      selSeg: 0,
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      params: params,
      projectId: params.projectId,
      projectName: params.projectName,
      isStart: params.isStart,
      showAdd: false,
      chatGroupId: params.chatGroupId,
      folderId: params.folderId,
      isShowSelSeg: true,
      isMember: false,
      projectPermission: {
        isHasEditProject: false, //编辑项目权限
        isHasTransfer: false, //转移项目权限
        isHasSuspend: false, //归档项目权限
        isHasArchive: false, //移到回收站权限
        isHasDel: false, //删除权限
      },
      projectFilePermission: {
        isHasFileUpload: false, //上传文件权限
        isHasFileDownload: false, //下载文件权限
        isHasFileDel: false, //删除文件权限
        isHasFilePutArchive: false, //移到回收站权限/恢复回收站权限
      },
      tagPermission: {
        isHasTagDel: false, //删除标签
        isHasTagPost: false, //创建标签
        isHasTagPut: false, //编辑标签
        isHasTagPutArchive: false, //移到回收站权限/恢复回收站权限
      }, //标签权限
      archivePermission: {
        taskPermission: {
          isHasPutArchive: false,
          isHasDel: false,
          taskPermissionArr: new Array(),
        },
        filePermission: {
          isHasPutArchive: false,
          isHasDel: false,
        },
        tagPermission: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
        },
        documentPermission: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
        },
        taskGroupPermission: {
          isHasPutArchive: false,
          isHasDel: false,
        },
      },
      isReload: 0,
      projectPageService: projectPageService,
      projectService: new ProjectService(),
      $refs: {
        issuesList: MicroIssuesList,
        chat: Chat,
      },
      project: { isDeleted: false, isFinished: false },
      tagGroupId: "",
      refreshTeamData: false, //刷新团队页面
      animationRight: animationRight,
      showError: false, //展示异常信息
      searchPanelForm: {
        searchKey: "", //搜索名称
        workUsers: "", //执行者ids
        dueToday: "", //今日截止
        completedToday: "", //今日完成
        myExecute: "", //我执行的
        myCreate: "", //我创建的
        undone: "", //未完成的任务
        unassigned: "", //未分配的任务
        finished: "", //已完成的任务
        postponed: "", //已延期的任务
      },
      btnIconRight_temp: [
        "iconiconqiehuan",
        "iconshaixuanxuanzhong",
        "icongengduo",
      ],
      topHeight: 100, //header的height
      refreshTaskPageSlider: false, //刷新task页面slider高度
      taskShowId: 1, //任务视图，1：列表视图 2：阶段视图
    };
  },
  ionViewWillEnter() {
    this.isReload++;
    this.getProjectListByUserByID(() => {
      this.getUserPermission();
      /**注释了微议题begin****************/
      /* if (this.selSeg == 5) {
        this.$refs.issuesList.getListData(true);
      }*/
      /**注释了微议题end****************/
      //刷新项目-任务页面
      if (this.selSeg == 0 && this.isReload > 1) {
        console.log("刷新项目-任务页面");
        // this.searchPanelForm={
        //   searchKey: "", //搜索名称
        //   workUsers: "",//执行者ids
        //   dueToday: "", //今日截止
        //   completedToday: "", //今日完成
        //   myExecute: "", //我执行的
        //   myCreate: "", //我创建的
        //   undone: "",//未完成的任务
        //   unassigned: "", //未分配的任务
        //   finished: "",//已完成的任务
        //   postponed: "", //已延期的任务
        // };
        let pTaskList: any = this.$refs.pTask;
        if (pTaskList) {
        //  pTaskList.initData();
          pTaskList.initPageData();
          pTaskList.refreshStatistics();
        }
      } else if (this.selSeg == 3) {
        this.refreshTeamData = true;
        let that = this;
        setTimeout(() => {
          that.refreshTeamData = false;
        }, 1000);
      }
      //刷新任务数量
      this.refreshStatistics();
      //获取项目详情
      this.getProjectInfo(this.projectId + "");
      // 获取当前用户是否在当前项目中
      this.existProjectByUser(
        this.projectId as string,
        SystemUtils.loginUser.id
      );

      let divProjectPageEmpty: any = this.$refs.divProjectPageEmpty;
      this.topHeight = divProjectPageEmpty.getBoundingClientRect().top;
    });
  },
  methods: {
    //根据用户获取项目信息
    getProjectListByUserByID(callback: any) {
      const queryParam = this.useRoute.query;
      if (queryParam.projectId != null) {
        this.projectId = queryParam.projectId as string;
        this.projectName = queryParam.projectName as string;
      }
      this.projectService
        .getProjectListByUserByID(SystemUtils.loginUser.id, this.projectId)
        .then((res: any) => {
          var select = res.find((item: any) => item.id == this.projectId);
          if (select == null) {
            //无访问权限
            this.btnIconRight = null;
            this.btnSizeRight = null; //["34px","45px"];
            this.showError = true;
          } else {
            this.showError = false;
            callback();
          }
        });
    },
    //获取权限
    getUserPermission() {
      this.permissionService
        .getUserPermissionByProject(this.projectId + "")
        .then((resp: AllResponse<Permission>) => {
          let projectPermissionArr = new Array();
          if (resp) {
            projectPermissionArr = resp.data;
          }
          if (projectPermissionArr && projectPermissionArr.length > 0) {
            this.userProjectPermissionArr = projectPermissionArr;
            // 编辑项目权限
            this.projectPermission.isHasEditProject = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "project.put.info"
            );
            // 转移项目权限
            this.projectPermission.isHasTransfer = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "project.put.transfer"
            );
            // 归档项目权限
            this.projectPermission.isHasSuspend = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "project.put.suspend"
            );
            // 移到回收站权限
            this.projectPermission.isHasArchive = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "project.put.archive"
            );

            // 移到回收站权限
            this.projectPermission.isHasDel = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "project.del"
            );
            //file.del  删除文件权限
            this.projectFilePermission.isHasFileDel = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "file.del"
            );
            //file.get.download 下载文件权限
            this.projectFilePermission.isHasFileDownload = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "file.get.download"
            );
            //file.post  上传文件权限
            this.projectFilePermission.isHasFileUpload = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "file.post"
            );
            this.projectFilePermission.isHasFilePutArchive = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "file.put.archive"
            );
            //删除标签
            this.tagPermission.isHasTagDel = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "tag.del"
            );
            //创建标签
            this.tagPermission.isHasTagPost = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "tag.post"
            );

            //编辑标签
            this.tagPermission.isHasTagPut = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "tag.put"
            );

            //移到回收站/恢复标签
            this.tagPermission.isHasTagPutArchive = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "tag.put.archive"
            );

            let isHasTaskPutArchive: boolean = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "task.put.archive"
            );
            let isHasTaskDel: boolean = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "task.del"
            );
            this.getTaskPermissionByProject(isHasTaskPutArchive, isHasTaskDel);
            this.archivePermission.filePermission.isHasDel = this.projectFilePermission.isHasFileDel;
            this.archivePermission.filePermission.isHasPutArchive = this.projectFilePermission.isHasFilePutArchive;
            this.archivePermission.tagPermission.isHasDel = this.tagPermission.isHasTagDel;
            this.archivePermission.tagPermission.isHasPutArchive = this.tagPermission.isHasTagPutArchive;
            //document.del  document.put.archive
            this.archivePermission.documentPermission.isHasDel = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "document.del"
            );
            this.archivePermission.documentPermission.isHasPutArchive = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "document.put.archive"
            );
            this.archivePermission.taskGroupPermission.isHasDel = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "taskgroup.del"
            );
            this.archivePermission.taskGroupPermission.isHasPutArchive = this.permissionService.isHasPermissionByCode(
              projectPermissionArr,
              "taskgroup.put.archive"
            );
          }
        }),
        (e: any) => {
          // Utils.presentToastWarning("projectPate获取权限异常");
        };
    },
    //获取任务权限
    getTaskPermissionByProject(
      isHasTaskPutArchive: boolean,
      isHasTaskDel: boolean
    ) {
      // 任务移到回收站/恢复回收站
      this.archivePermission.taskPermission.isHasPutArchive = isHasTaskPutArchive;
      // 彻底删除任务
      this.archivePermission.taskPermission.isHasDel = isHasTaskDel;
      this.permissionService
        .getTaskPermissionByProject(this.projectId + "")
        .then((resp: AllResponse<Permission>) => {
          let projectPermissionArr = new Array();
          if (resp) {
            projectPermissionArr = resp.data;
          }
          if (projectPermissionArr && projectPermissionArr.length > 0) {
            this.archivePermission.taskPermission.taskPermissionArr = projectPermissionArr;
          }
        });
    },
    segmentChanged(ev: CustomEvent) {
      this.selSeg = ev.detail.value;
      /**注释了微议题begin ****************/
      /* if (this.selSeg == 5) {
        this.showAdd = true;
        setTimeout(() => {
          this.$refs.issuesList.getListData(true);
        }, 300);
      } else */
      /**注释了微议题end ****************/
      {
        if (this.selSeg == 0) {
          //iconshaixuanxuanzhong
          // console.log(JSON.stringify(this.btnIconRight_temp));
          // if(this.btnIconRight_temp.length>0)
          this.btnIconRight = JSON.parse(
            JSON.stringify(this.btnIconRight_temp)
          );
          this.refreshTaskPageSlider = true;
          let that = this;
          setTimeout(function () {
            that.refreshTaskPageSlider = false;
          }, 500);
        } else {
          this.btnIconRight = ["icongengduo"];
          this.btnSizeRight = ["34px"];
        }
        this.showAdd = false;
        if (this.selSeg == 4) {
          setTimeout(() => {
            this.$refs.chat.scrollToBottom();
          }, 100);
        }
      }
    },
    async openProjectMenuModal(right_: any) {
      if (this.btnIconRight.length > 1 && right_ == "1_icon") {
        //任务筛选面板
        menuController.enable(true, "ProjectPageMenu");
        menuController.open("ProjectPageMenu");
      } else if (
        (this.btnIconRight.length > 1 && right_ == "2_icon") ||
        (this.btnIconRight.length == 1 && right_ == "0_icon")
      ) {
        const modal = await modalController.create({
          component: ProjectMenu,
          // cssClass: "project-info-bottom-modal-class",
          backdropDismiss: true,
          componentProps: {
            project: this.project,
            projectId: this.projectId,
            isStart: this.isStart,
            projectName: this.projectName,
            projectPermission: this.projectPermission,
            isFinished: this.project.isFinished,
            isDeleted: this.project.isDeleted,
          },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
          if (data.isCollect) {
            this.isStart = data.isCollect;
          } else if (data.isReload) {
            this.router.go(-1);
          } else if (data.openTransfer) {
            //项目转移
            this.projectTransfer();
          } else if (data.openTransfer) {
            //项目转移
            this.projectTransfer();
          } else if (data.openSetting) {
            //项目设置
            this.projectSetting();
          } else if (data.openRecycle) {
            //项目回收站
            this.projectRecycle();
          } else if (data.openTag) {
            //标签管理
            this.tagManage();
          }
        }
      } else if (this.btnIconRight.length > 1 && right_ == "0_icon") {
        //切换阶段
        const popover = await popoverController.create({
          component: ChangeTaskShowPullDown,
          cssClass: "projectPagePullDown",
          mode: "ios",
          componentProps: {
            searchId: this.taskShowId,
          },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data && data.selectId) {
          let isChangeTaskShow = false;
          if (this.taskShowId != data.selectId) {
            isChangeTaskShow = true;
          }
          this.taskShowId = data.selectId;
          if (isChangeTaskShow) {
            this.showTaskList();
          }
        }
      }
    },
    showTaskList() {
      let pTaskList: any = this.$refs.pTask;
      if (pTaskList) {
        pTaskList.changeShowTaskList(this.taskShowId);
      }
    },
    //绑定得到查询条件
    getQueryParms(queryParms: any) {
      //这种修改不能监听到变化
      // this.btnIconRight.splice(0,1,"iconshaixuanxuanzhong");
      if (queryParms.isSelected == "true") {
        //选中图标
        this.btnIconRight = [
          "iconiconqiehuan",
          "iconshaixuanxuanzhong_iconSelected",
          "icongengduo",
        ];
        this.btnIconRight_temp = [
          "iconiconqiehuan",
          "iconshaixuanxuanzhong_iconSelected",
          "icongengduo",
        ];
      } else {
        //未选中图标
        this.btnIconRight = ["iconiconqiehuan","iconshaixuanxuanzhong", "icongengduo"];
        this.btnIconRight_temp = ["iconiconqiehuan","iconshaixuanxuanzhong", "icongengduo"];
      }
      this.btnSizeRight = ["34px", "45px"];
      this.searchPanelForm = queryParms;
      nextTick(() => {
        let pTaskList: any = this.$refs.pTask;
        if (pTaskList) {
         // pTaskList.initData();
            pTaskList.initPageData();
        }
      });
    },
    // 新建微议题
    addMicroIssues() {
      if (this.projectId != null && this.projectName != null) {
        this.router.push({
          name: "MicroIssuesAdd",
          params: {
            type: "project",
            projectId: this.projectId.toString(),
            projectName: this.projectName.toString(),
          },
        });
      }
    },
    showSegment(obj: any) {
      if (obj == "show") this.isShowSelSeg = true;
      else this.isShowSelSeg = false;
    },
    //更新项目任务数量
    refreshStatistics() {
      const parmsStatus = {
        id: this.projectId,
        isRefresh: true,
      };
      let that = this;
      this.projectPageService.refreshStatistics(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            this.project = r.data;
            //刷新完成
          }
        },
        (e: any) => {
          console.log("ProjectPageVue-refreshStatistics接口请求异常");
        }
      );
    },

    existProjectByUser(projectId: string, userId: string) {
      this.projectService
        .existProjectByUserId(projectId, userId)
        .then((res: any) => {
          this.isMember = res.data;
        })
        .catch((err: any) => {});
    },
    //项目转移
    //  enterAnimation: this.animationRight.enterAnimation,
    // leaveAnimation: this.animationRight.leaveAnimation,
    async projectTransfer() {
      const modal = await modalController.create({
        component: ProjectTransfer,
        cssClass: "notice-modal-class",
        mode: "ios",

        componentProps: {
          projectId: this.projectId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.isReflush) {
        const newModel = {
          belonger: data.belonger,
          belongerId: data.belongerId,
          id: this.projectId,
          setFields: ["belongerId", "belonger"],
        };
        this.projectService.updateFieldsById(newModel).then(
          (r: any) => {
            if (r) {
              console.log("保存成功");
              this.router.go(-1);
            } else {
              console.log("暂无数据");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },

    //项目设置
    // enterAnimation: this.animationRight.enterAnimation,
    //     leaveAnimation: this.animationRight.leaveAnimation,

    async projectSetting() {
      const isHasPermission = this.projectPermission
        ? this.projectPermission.isHasEditProject
        : false;
      const modal = await modalController.create({
        component: ProjectSetting,
        mode: "ios",

        cssClass: "notice-modal-class",
        componentProps: {
          projectId: this.projectId,
          isHasPermission: isHasPermission,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.projectInfo) {
        this.projectName = data.projectInfo.name;
      }
    },
    //回收站
    async projectRecycle() {
      this.router.push({
        path: "/project/recycle-manage",
        query: {
          projectId: this.projectId,
          folderId: this.folderId,
          archivePermission: JSON.stringify(this.archivePermission),
        },
      });
    },
    //标签
    //  enterAnimation: this.animationRight.enterAnimation,
    // leaveAnimation: this.animationRight.leaveAnimation,
    async tagManage() {
      const modal = await modalController.create({
        component: SelectLable,
        cssClass: "notice-modal-class",
        // backdropDismiss: false,
        mode: "ios",

        componentProps: {
          data: {
            busId: "",
            type: "manage",
            groupId: this.tagGroupId,
            busTable: "tt_task",
            checkedLabelList: [],
          },
          tagPermission: this.tagPermission,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.checkedLabelList) {
      }
    },
    //获取项目信息
    getProjectInfo(id: string) {
      const parmsStatus = {
        id: id,
      };
      let that = this;
      this.projectPageService.getProjectInfoById(parmsStatus).then(
        (r: any) => {
          if (r && r.data && r.data[0]) {
            this.project = r.data[0];
            this.tagGroupId = r.data[0].tagGroupId;
            this.project.isFinished = r.data[0].isFinished;
            this.chatGroupId = r.data[0].chatGroupId;
          } else {
            // console.log("无项目信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
  },
});
