export default class ProjectQueryCondition {
    projectName: string;
    createTimeCB: string;
    createTimeCE: string;
    endTimeCB: string;
    endTimeEE: string;
    projectLight: string;
    systemId: string;
    partakeType: string;
    createIds: string;

    constructor(item?: any) {
        if (item) {
            this.projectName = item.qname;
            this.createTimeCB = item.qcreateTimeB;
            this.createTimeCE = item.qcreateTimeE;
            this.projectLight = item.qstartTimeB;
            this.endTimeCB = item.qendTimeB;
            this.endTimeEE = item.qendTimeE;
            this.systemId = item.qsource;
            this.partakeType = item.qexecutorIds;
            this.createIds = item.qcreatorIds;
        }else{
            this.projectName = "";
        this.createTimeCB = "";
        this.createTimeCE = "";
        this.projectLight = "";
        this.endTimeCB = "";
        this.endTimeEE = "";
        this.systemId = "";
        this.partakeType = "";
        this.createIds = "";
        }
    }
}