
import TaskCards from "@/components/ListCard/TaskCards.vue";
import { TaskService } from "@/services/task/TaskService";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "NoRelationTask",
  components: {
    TaskCards,
  },
  props: {
    refresh: Boolean,
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.parameters.offset = 1;
        this.getListRequest((res: any) => {
          this.list = res;
        });
      }
    },
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      list: [],
      api: new TaskService(),
      showNoDate: false,
      parameters: {
        offset: 1,
        limit: 10,
        type: 2,
      },
    };
  },
  mounted() {
    this.getListRequest((res: any) => {
      this.list = res;
      if (res.length == 0) {
        this.showNoDate = true;
      }
    });
  },
  methods: {
    openTask(id: any) {
      this.router.push({ path: "/task-page", query: { id: id } });
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= this.parameters.limit) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.api.getTaskFromType(this.parameters).then((res: any) => {
        callback(res);
      });
    },
  },
});
