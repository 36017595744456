
import { IonContent, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
import { CalendarService } from "@/services/calendar/CalendarService.ts";

export default defineComponent({
  name: "SearchBarCalendarPullDown",
  components: { IonContent },
  props: {
    searchName: String,
    searchId:String,
  },
  data() {
    const itemAll=[{id:"",name:"--全部日历--"}];
    return {
      searchKey: this.searchName,
      searchParams: {
        limit: 999999,
        offset: 1,
        name: "",
      },
      canlendarList: new Array(),
      calendarService: new CalendarService(),
      itemAll:itemAll,
      searchIdValue:"",
    };
  },
  created() {
    if(this.searchId){
      this.searchIdValue=this.searchId;
    }
  },
  mounted() {
    this.resetPage();
  },
  methods: {
    //获取下拉数据
    getSearchName(e: any) {
      const NameArr = this.canlendarList.filter((item) => {
        return item.id == e.detail.value;
      });
      this.searchKey = NameArr[0].id;
      popoverController.dismiss({ item: NameArr[0] });
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.canlendarList = this.itemAll.concat(res);
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.canlendarList = this.canlendarList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    getListRequest(callback: any) {
      this.calendarService.getPageMyCal(this.searchParams).then((res: any) => {
        callback(res.data);
      });
    },
  },
});
