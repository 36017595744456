
import { modalController, alertController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { getInstance } from "@/services/project/projectService";
import BaseService from "@/services/base/BaseService";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
// 权限 类
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import { defineComponent } from "vue";
// import { utils } from "gantt";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
export default defineComponent({
  name: "OKRDetailstMenu",
  props: {
    project: Object,
    projectId: String,
    isStart: String,
    projectName: String,
    projectPermission: Object,
    isFinished: Boolean,
    isDeleted: Boolean,
    isTargetPrincipal: Boolean, //目标负责人
    isResultprincipal: Boolean, //目标下关键结果负责人
    isparentOkRUsers: Boolean, //上级负责人
    targetProgress: Number,
    targetId: String,
  },
  data() {
    const router = useRouter();
    const projectService = getInstance();
    return {
      isCollect: this.isStart,
      router: router,
      projectService: projectService,
      objectiveService: new ObjectiveService(),
      targetObj: Object.create(null),
    };
  },
  methods: {
    closeModel() {
      modalController.dismiss({ isCollect: this.isCollect });
    },
    scoreOKR() {
      modalController.dismiss({ closeQuery: "scoreOKR" });
    },
    //结束目标
    async overOKR() {
      console.log(111111);
      const alert = await alertController.create({
        header: "结束目标",
        message:
          this.targetProgress && this.targetProgress < 100
            ? "此目标还未完成，您确定要结束吗？"
            : "您确定要结束此目标吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              let param = {
                id: this.targetId,
                setFields: ["status"],
                status: 2,
              };
              this.objectiveService
                .updateFieldsById(param)
                .then((res: any) => {
                  if (res) {
                    modalController.dismiss({ closeQuery: "overOKR" });
                  } else {
                    Utils.presentToastWarning("结束目标失败！");
                  }
                })
                .catch(() => {});
            },
          },
        ],
      });
      await alert.present();
    },

    // 回收站
    async moveToRecycle(isDelete: boolean) {
      console.log(isDelete);
      const tips = isDelete ? "还原" : "移至回收站";
      const alert = await alertController.create({
        header: tips,
        message: "您确定要把此目标" + tips + "吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              this.objectiveService
                .removeOrRestoreObjective(this.targetId as string, isDelete)
                .then((res: any) => {
                  if (res) {
                    modalController.dismiss({ closeQuery: "removeOKR" });
                  } else {
                    Utils.presentToastWarning("目标" + tips + "失败！");
                  }
                })
                .catch(() => {});
            },
          },
        ],
      });
      await alert.present();
    },

    async delToRecycle() {
      const alert = await alertController.create({
        header: "彻底删除",
        message: "您确定要把此目标彻底删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              this.objectiveService
                .delObjective(this.targetId as string)
                .then((res: any) => {
                  if (res) {
                    modalController.dismiss({ closeQuery: "removeOKR" });
                  } else {
                    Utils.presentToastWarning("目标删除失败！");
                  }
                })
                .catch(() => {});
            },
          },
        ],
      });
      await alert.present();
    },
    //编辑
    editOKR() {
      modalController.dismiss({ closeQuery: "editOKR" });
    },
  },
});
