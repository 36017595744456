import TaskBaseService from '../base/TaskBaseService';

export class HomeModuleApi extends TaskBaseService {
    constructor() {
        super("hmModulePersonal");
    }

    /**
     * 获取所有可选模块
     */
    public getAll() {
        const path = 'getAllHmModules';
        return this.request(path, this.rest.METHOD_GET);
    }

    /**
     * 获取我本人定制的首页模块
     */
    public getMyModules() {
        const path = 'getMyModules';
        return this.request(path, this.rest.METHOD_GET);
    }

    /**
     * 保存当前用户的定制内容
     */
    public saveModules(data: any) {
        const path = 'saveMyModules';
        return this.request(path, this.rest.METHOD_POST, data);
    }

    public getMyWorkItems(params: any) {
        return this.request("getMyWorkItems", this.rest.METHOD_GET,params);
    }
    //根据日期范围，获取我的事项列表
    public getDateMarkersMyWorkItems(params: any) {
        return this.request("getMyWorkItemByDateInterval", this.rest.METHOD_GET,params);
    }
}