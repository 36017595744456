
import { defineComponent } from "vue";
import { popoverController, modalController } from "@ionic/vue";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import DetailCalendar from "../detail/DetailCalendar.vue";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "SubscribeCard",
  components: { AvatarList },
  props: {
    SubscribeCardObj: Object,
    showCheckBox: Boolean,
    showDeleteBtn: Boolean,
    showStatus: Boolean,
    showShareType: Boolean,
  },

  data() {
    return {};
  },
  emits: ["on-delete", "on-create", "on-check"],
  created() {},
  methods: {
    //删除
    deleteItem(item: Array<any>, index: number, id: string) {
      // item.splice(index, 1);
      this.$emit("on-delete", id);
    },
    //订阅他人日历
    async create() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: new Array(0),
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data.length > 0) {
        //返回选人集合
        let userIds: Array<String> = data.data.map(function (
          e: any,
          i: number
        ) {
          return e.userId;
        });
        //初始化
        this.$emit("on-create", userIds);
      }
    },
    checkChangeHandler(e: any, item: any) {
      const isChecked: boolean = e.target.checked;
      this.$emit("on-check", {
        checked: isChecked,
        item: item,
      });
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    async detailHandler(id: any) {
      const modal = await modalController.create({
        component: DetailCalendar,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          id: id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.workUserName, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
