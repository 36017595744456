<template>
  <ion-page>
    <ion-header
      class="common ion-no-border"
      mode="ios"
      v-if="menuRole == 'phone'"
    >
      <toolbar
        :src="require('../../assets/images/toolbarImg.png')"
        title="试制生产管理系统"
      />
    </ion-header>
    <ion-content :scroll-y="true" class="iconContent">
      <div class="cardTitle">
        <div>快捷入口</div>
        <div  class="right">
          <span @click="customizeMenu()" class="iconfont icon-tianjiaweiyiti"></span>
        </div>
      </div>
      <ion-grid>
        <ion-row>
          <ion-col
            size="6"
            v-for="(item, idx) in fastInput.slice(0, 4)"
            :key="item.name"
          >
            <div
              :class="[
                idx == 0
                  ? 'primary'
                  : idx == 1
                  ? 'warning'
                  : idx == 2
                  ? 'purple'
                  : 'sky',
                'card',
              ]"
              @click="navPage(item.name)"
            >
              <img
                :src="require('@/assets/images/line.png')"
                class="lineImg"
                width="15"
                height="3"
                alt=""
              />
              <img
                :src="require('@/assets/images/bg2.png')"
                style="left: 0; bottom: 0"
                width="57"
                height="35"
                alt=""
              />
              <img
                :src="require('@/assets/images/bg3.png')"
                style="right: 0; bottom: 0"
                width="111"
                height="19"
                alt=""
              />

              <i class="iconfont" :class="[item.icon]"></i>
              <span class="cardText">{{ item.title }}</span>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="cardTitle">
        <div>工作区</div>
        <!-- <a @click="isShow = !isShow">{{ isShow ? "收起" : "展开" }}</a> -->
      </div>
      <div class="moduleMenu" :style="{ overflow: 'hidden' }">
        <ModuleMenu :colNum="8" :modulesProp="menuModal"></ModuleMenu>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import ModuleMenu from "@/views/home/ModuleMenu.vue";
import toolbar from "./toolbar";
import { getPrinterIP } from "@/api/login/login";
import * as dd from "dingtalk-jsapi";
import SystemUtils from "@/utils/SystemUtils";
import { clearInterval } from "timers";
import {  isPlatform, modalController } from "@ionic/vue";
import customizeMenu from "@/views/home/customizeMenu.vue";
import { AnimationSelf } from "@/libs/animation";
import { selectUserSiteMap } from "@/api/home/customizeMenu";

export default {
  name: "homePage",
  components: {
    toolbar,
    ModuleMenu,
    modalController,
  },
  data() {
    return {
      isShow: true,
      modulesProp: [
        {
          id: "",
          name: "checkpointPutinStorage",
          title: "检查站接收检查件",
          icon: "icon-jianchazhanjieshoujianchajian",
          visible: true,
          background: "#F15541",
          role: "phone",
          loginRole: "CheckMonitor",
        },
        {
          id: "",
          name: "subPage/仓储管理/goods",
          title: "仓储管理",
          icon: "icon-kucunguanli",
          visible: true,
          background: "#3295FA",
          role: "phone",
          loginRole: "WarehouseKeeper",
        },
        {
          id: "",
          name: "subPage/生产作业管理/car",
          title: "生产作业管理",
          icon: "icon-zhuangchejindu",
          visible: true,
          background: "#FF943E",
          role: "phone",
          loginRole: "WarehouseKeeper",
        },
        {
          id: "",
          name: "subPage/质量管理/question",
          title: "质量管理",
          icon: "icon-zhiliangxinxi",
          visible: true,
          background: "#F15541",
          role: "dd",
          loginRole: "",
        },
        {
          id: "",
          name: "vehicleList",
          title: "叉车调度管理",
          icon: "icon-chacheguanli",
          visible: true,
          background: "#FEAE00",
          role: "dd",
          loginRole: "",
        },
        {
          id: "",
          name: "taskList",
          title: "任务管理",
          icon: "icon-shengchanzhiliang",
          visible: true,
          background: "#FF7640",
          role: "dd",
          loginRole: "",
        },
        {
          id: "",
          name: "partsInfo",
          title: "零部件点检统计",
          icon: "icon-lingbujianxinxi",
          visible: true,
          background: "#15BC83",
          role: "dd",
          loginRole: "",
        },
        {
          id: "",
          name: "subPage/模具管理/templateInfo",
          title: "模具管理",
          icon: "icon-yangchexinxi",
          visible: true,
          background: "#32D5E2",
          role: "dd",
          loginRole: "",
        },
        {
          id: "",
          name: "workshopPartReception",
          title: "车间零件接收",
          icon: "icon-lingjianjieshou",
          visible: true,
          background: "#32D5E2",
          role: "phone",
          loginRole: "WarehouseKeeper",
        },
      ],
      subModulesProp: [
        {
          id: "",
          name: "templateInfo",
          title: "外协模具信息",
          icon: "icon-yangchexinxi",
          visible: true,
          background: "#32D5E2",
        },
        {
          id: "",
          name: "templateCheck/1",
          title: "外协模具点检",
          icon: "icon-shaixuan",
          visible: true,
          background: "#32D5E2",
        },
        {
          id: "",
          name: "templateCheck/0",
          title: "自制模具点检",
          icon: "icon-shujushaixuan",
          visible: true,
          background: "#32D5E2",
        },
        {
          id: "",
          name: "partsofWarehouse",
          title: "零件出库",
          icon: "icon-lingjianchuku",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "partsComplement",
          title: "零件补码",
          icon: "icon-lingjianbuma",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "sweepCodeOn",
          title: "扫码上架",
          icon: "icon-saomashangjia",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "warehouseReceiving",
          title: "入库接收",
          icon: "icon-rukujieshou",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "checkPutinStorage",
          title: "检查件入库",
          icon: "icon-jianchajianruku",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "questionAdd",
          title: "问题记录",
          icon: "icon-wentijilu",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "question",
          title: "问题查看",
          icon: "icon-shujushaixuan",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "questionManage/0",
          title: "管理类问题",
          icon: "icon-tongjifenxifeixuanzhong",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "questionManage/1",
          title: "制件类问题",
          icon: "icon-okrrenwu",
          visible: true,
          background: "#3295FA",
        },
        {
          id: "",
          name: "doEntrucking",
          title: "扫码装车",
          icon: "icon-tongjifenxifeixuanzhong",
          visible: true,
          background: "#FF943E",
        },
        {
          id: "",
          name: "carLoaded",
          title: "扫码装车查看",
          icon: "icon-zhuangchejindu",
          visible: true,
          background: "#FF943E",
        },
      ],
      fastInput: [], //快捷入口菜单集合
      menuModal: [], //工作区菜单集合
      subMenuCollection: [], //子菜单集合
      menuCollection: [], //菜单集合
      siteMapList: [], //用户自定义菜单集合
      cardColor: ['primary','warning','purple','sky'],
      menuRole: "phone",
    };
  },
  mounted() {
    document.addEventListener("deviceready", function () {}, false);
    this.menuRole = localStorage.getItem("menuRole");
     if (isPlatform("capacitor")) {
       this.getPrinterIP();
     }

    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "试制生产管理系统", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    var _this = this;
      var loginRole = JSON.parse(localStorage.getItem("loginRole")) || [];

      var deviceList = [];
      for (var i = 0; i < loginRole.length; i++) {
        var list = _this.modulesProp.filter((e) => {
          if(loginRole[i].titleCN == e.title&&e.role==_this.menuRole){
            e.id = loginRole[i].id;
          }
          return loginRole[i].titleCN == e.title&&e.role==_this.menuRole; //钉钉权限做完再开发
        });
        var subList = _this.subModulesProp.filter((e) => {
          if(loginRole[i].titleCN == e.title){
            e.id = loginRole[i].id;
          }
          return loginRole[i].titleCN == e.title; //钉钉权限做完再开发
        });
        deviceList = deviceList.concat(list);
        _this.subMenuCollection = _this.subMenuCollection.concat(subList);
      }
      // deviceList = deviceList.concat(_this.modulesProp.filter((e) => {
      //       return '叉车调度管理' == e.title; //钉钉权限做完再开发
      //     }))
      _this.modulesProp = deviceList;
      _this.menuModal = _this.modulesProp;
      _this.menuCollection = _this.menuCollection.concat(_this.menuModal).concat(_this.subMenuCollection);
      _this.menuCollection = _this.setCardColor();
      _this.initCustomizeMenu(deviceList);
      localStorage.setItem("menuList", JSON.stringify(_this.fastInput));
  },
  methods: {
    async customizeMenu() {
      const  result = AnimationSelf.prototype.modalRight;
      const modal = await modalController.create({
        component: customizeMenu,
        cssClass: "custommade-modal-class",
        enterAnimation: result.enterAnimation,
        leaveAnimation: result.leaveAnimation,
        componentProps: {
          menuCollection: this.menuCollection,
          siteMapList: this.siteMapList,
          menuRole: this.menuRole,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if(data.customizeInput.length>0){
          this.fastInput = data.customizeInput;
        }else{
          this.fastInput = this.menuModal.slice(0, 4);
          this.siteMapList = [];
        }
      }
    },
    navPage(url) {
      this.$router.push("/" + url);
    },
    async getPrinterIP() {
      getPrinterIP({ nameCN: SystemUtils.loginUser.id }).then((res) => {
        localStorage.removeItem("printHttp");
        localStorage.removeItem("printPort");
        if (
          !res.data.isError &&
          res.data.data.isSuccess &&
          res.data.data.resultData
        ) {
          localStorage.setItem("printHttp", res.data.data.resultData.IP);
          localStorage.setItem("printPort", res.data.data.resultData.code);
        }
      });
    },
    initData(){

    },
    setCardColor() {
      const newList = [];
      this.menuCollection.forEach(item =>{
        const arrIndex = Math.floor(Math.random()*this.cardColor.length);
        item.cardColor = this.cardColor[arrIndex];
        newList.push(item);
      });
      return newList;
    },
    initCustomizeMenu(deviceList) {
      const params = {
        userId: SystemUtils.loginUser.id,
        menuRole:this.menuRole
      }
      selectUserSiteMap(params).then(res=>{
        if (!res.data.isError && res.data.data.isSuccess) {
          if(res.data.data.resultData.length>0&&res.data.data.resultData[0].siteMapIds) {
            this.menuCollection.forEach(item =>{
              var ids = res.data.data.resultData[0].siteMapIds.split(',');
              for(var i=0;i<ids.length;i++){
                if(ids[i] == item.id) {
                  this.siteMapList.push(item);
                }
              }
            });
            this.fastInput = this.siteMapList;
          } else {
            var list = localStorage.getItem("menuList")
                    ? JSON.parse(localStorage.getItem("menuList"))
                    : [];
            if (list.length == 0) {
              for (
                      var i = 0;
                      i < (deviceList.length >= 4 ? 4 : deviceList.length);
                      i++
              ) {
                var item = deviceList[i];
                item.num = 1;
                this.fastInput.push(item);
              }
            } else {
              this.fastInput = list;
            }
          }
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.noticeTitle {
  width: 12px;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
}

.iconContent {
  --background: #ffffff;
}

.notice {
  width: calc(100% - 16px);
  padding: 14px 7px;
  margin: 0 auto;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    justify-content: start;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  .titleBox {
    width: 290px;
    overflow: hidden;
    margin: 0 auto;
  }
  .titleAnimate {
    padding-left: 20px;
    font-size: 12px;
    color: #000;
    display: inline-block;
    white-space: nowrap;
    animation: 5s wordsLoop linear infinite normal;
  }
  @keyframes wordsLoop {
    0% {
      transform: translateX(200px);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
.cardTitle {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    font-size: 16px;
    font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
    font-weight: bold;
    text-align: left;
    color: #434343;
  }
  a {
    font-size: 12px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: left;
    color: #989898;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(96);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
    }
  }
}
.moduleMenu {
  transition: height 0.51s;
}
.card {
  width: 100%;
  height: 60px;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 5px 14px 0px rgba(53, 125, 251, 0.23);
  position: relative;
  img {
    position: absolute;
  }
  i {
    position: absolute;
    color: #ffffff;
    opacity: 0.8;
    font-size: 25px;
    right: 16px;
    bottom: 16px;
  }
}
.card.primary {
  background: linear-gradient(262deg, #3687fe 0%, #1a66fd 100%);
}
.card.warning {
  background: linear-gradient(77deg, #fdac34 0%, #fd894f 100%);
}
.card.purple {
  background: linear-gradient(77deg, #bd7dfb 0%, #af71fa 100%);
}
.card.sky {
  background: linear-gradient(77deg, #40dfeb 0%, #29c6d5 100%);
}

.lineImg {
  left: 18px;
  top: 18px;
}
.cardText {
  font-size: 14px;
  font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 18px;
  top: 18px;
}
</style>