
import { modalController, IonPage } from "@ionic/vue";
import { TaskService } from "@/services/task/TaskService";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import AvatarList from "@/components/AvatarList.vue";
//时间处理
import moment from "moment";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ConvertToChildTask",
  props: {
    source: Number, // 0：转为子任务；1：新建微议题选择项目
    taskId: String,
    projectId: String,
    isIndependentTask: Boolean,
  },
  components: {
    HeaderModal,
    IonPage,
    SearchBar,
    AvatarList,
  },
  data() {
    return {
      selectedId: "",
      selectedItem: {},
      api: new TaskService(),
      list: new Array<any>(),
      parameters: {
        sort: "name",
        taskName: "",
        excludeTaskId: this.taskId,
        offset: 1,
        limit: 15,
        projectId: this.isIndependentTask ? "" : this.projectId,
      },
      timeStatus: "",
      startYear: "",
      endYear: "",
    };
  },
  mounted() {
    this.getListRequest((res: any) => {
      if (res) {
        this.list = res;
      }
      this.timeFormat();
      this.timeBg();
    });
  },
  methods: {
    timeBg() {
      for (let index = 0; index < this.list.length; index++) {
        let timeStatusClass = "ngBg"; //lower 还早 general正常 urgency即将截止 very_urgency逾期 ngBg
        if (this.list[index].endTime == "") {
          timeStatusClass = "lower_bg";
        } else {
          let endStr = moment(this.list[index].endTime).format(
            "YYYY-MM-DD HH:mm"
          );
          endStr = endStr.replace(/-/g, "/");
          const d = new Date(endStr).getTime() - new Date().getTime();
          const difference = Math.floor(d / (24 * 3600 * 1000));
          if (difference > 7) {
            timeStatusClass = "lower_bg"; //大于7天 33117529
          }
          if (difference > 7 && difference > 1) {
            timeStatusClass = "general_bg"; //7天内
          }
          if (0 == difference) {
            timeStatusClass = "urgency_bg"; //当天
          }
          if (difference < 0) {
            timeStatusClass = "very_urgency_bg"; //逾期
          }
        }
        if (this.list[index].isFinished) {
          timeStatusClass = "noBg";
        }
        this.list[index].timeStatusClass = timeStatusClass;
      }
    },
    timeFormat() {
      for (let index = 0; index < this.list.length; index++) {
        const LstartTime = this.list[index].startTime;
        const LendTime = this.list[index].endTime;

        const startTime = moment(LstartTime).format("YYYY-MM-DD");
        const endTime = moment(LendTime).format("YYYY-MM-DD");

        const startY = moment(LstartTime).format("YYYY");
        const endY = moment(LendTime).format("YYYY");

        const startM = moment(LstartTime).format("MM");
        const endM = moment(LendTime).format("MM");

        const startD = moment(LstartTime).format("DD");
        const endD = moment(LendTime).format("DD");

        const startH = moment(LstartTime).format("HH:mm");
        const endH = moment(LendTime).format("HH:mm");

        const nowY = new Date().getFullYear() + "";
        let time = "";
        if (startY != nowY && startY != "Invalid date") {
          this.startYear = startY + "年";
        }
        if (endY != nowY && endY != "Invalid date") {
          this.endYear = endY + "年";
        }

        if (startTime == "Invalid date" && endTime != "Invalid date") {
          time = this.endYear + endM + "月" + endD + "日" + " 截至";
        } else if (endTime == "Invalid date" && startTime != "Invalid date") {
          time = this.startYear + startM + "月" + startD + "日" + " 开始";
        } else if (
          startTime == endTime &&
          startTime != "Invalid date" &&
          endTime != "Invalid date"
        ) {
          //同一天
          time =
            this.startYear +
            startM +
            "月" +
            startD +
            "日" +
            startH +
            "-" +
            endH;
        } else if (
          startTime != endTime &&
          startTime != "Invalid date" &&
          endTime != "Invalid date"
        ) {
          //不为一天
          time =
            this.startYear +
            startM +
            "月" +
            startD +
            "日" +
            "-" +
            this.endYear +
            endM +
            "月" +
            endD +
            "日";
        }
        this.list[index].time = time;
      }
    },
    checkHandler(item: any) {
      this.selectedItem = item;
      this.selectedId = item.id;
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.parameters.taskName = data.searchKey;
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
        this.timeFormat();
        this.timeBg();
      });
    },
    closeModel(type: any) {
      if (type == 4) {
        this.saveHandler();
      } else {
        modalController.dismiss({});
      }
    },
    filteredItems: function (items: any) {
      return items.slice(0, 2);
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        this.timeFormat();
        this.timeBg();
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    saveHandler() {
      if (!this.selectedId) {
        Utils.presentToast("请选择任务");
        return;
      }
      if (this.source == 0) {
        const _item: any = this.selectedItem;
        if (_item.isFinished) {
          Utils.presentToast("目标任务已完成，转化失败");
          return;
        }
        this.api
          .convertToChildTask({
            taskId: this.taskId,
            parentId: this.selectedId,
          })
          .then((res) => {
            if (res) {
              Utils.presentToast("转为子任务成功");
              //关闭页面
              modalController.dismiss({
                refresh: true,
              });
            } else {
              Utils.presentToast("转为子任务失败");
            }
          });
      } else if (this.source == 1) {
        //-----------------
        //关闭页面
        modalController.dismiss({
          selectTask: this.selectedItem,
        });
      }
    },
    getListRequest(callback: any) {
      if (this.source == 0) {
        this.api.getToBeParentTaskList(this.parameters).then((res) => {
          callback(res);
        });
      } else if (this.source == 1) {
        this.api.getAppTaskListByMember(this.parameters).then((res) => {
          callback(res);
        });
      }
    },
    avatarData(getData: any) {
      let users = new Array();
      if (getData) {
        let userArr = getData.taskUser
          .filter((item: any) => {
            return item.workType == 1;
          })
          .map(function (e: any, i: number) {
            return e.name;
          });
        let usersName = userArr;
        usersName.map((item: any, index: any) => {
          //  把原数组循环一下
          let obj = { name: item, img: "" };
          users.push(obj); //  img.jpg 数组的每一行添加一个 disabled:'true'  属性
        });
      }
      return users;
    },
  },
});
