
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import GesamtStatus from "@/views/dashboard/component/GesamtStatus.vue";
import Freigabe from "@/views/dashboard/component/Freigabe.vue";
import PabProgress from "@/views/dashboard/component/PabProgress.vue";
import GroundInfo from "@/views/dashboard/component/GroundInfo.vue";
import Milestone from "@/views/dashboard/component/Milestone.vue";
import { useRouter } from "vue-router";
import echarts from "echarts";
import { DashboardService } from "@/services/dashboardService";
import Utils from "@/utils/Utils";
import mileGantt from "@/views/dashboard/project/component/milestone.vue";
export default defineComponent({
  name: "DashboardDetails",
  components: {
    Header,
    GesamtStatus,
    Freigabe,
    PabProgress,
    GroundInfo,
    Milestone,
    mileGantt,
  },
  data() {
    const router = useRouter();
    const collectIcon = ["iconyishoucang" + " " + "iconcolor"];
    const noCollectIcon = ["icondianjishoucang"];
    const btnSizeRight = ["34px"];
    return {
      isBack: true,
      api: new DashboardService(),
      boardCheckpint: new Array(0),
      router: router,
      echarts: echarts,
      collectIcon: collectIcon,
      noCollectIcon: noCollectIcon,
      btnSizeRight: btnSizeRight,
      indicators: [
        "Gesamt Status",
        "P-Freigabe",
        "B-Freigabe",
        "K-Freigabe",
        "PAB/OTS/BMG",
        "Design & Vorentwicklung",
        "Daten Management",
        "Versuchbautraeger",
        "Grund Infomationen",
        "Uebergabe",
        "GesamtFzg",
      ],
      selSeg: 0,
      projectName: "",
      gesamt: {
        sopTime: "",
        projectCartypePlatform: "",
        projectType: "",
        factoryName: "",
        planningVolume: "",
      },
      index: 1,
      freigabeP: new Array(0),
      freigabeB: new Array(0),
      freigabeK: new Array(0),
      pabProgress: new Array(0),
      milestone: new Array(0),
      collect: 0,
      projectPeriod: "",
      projectId: "",
      planId: 0,
      gantt: {},
      milestoneData: {},
      sopDate: "",
    };
  },
  props: {},
  methods: {
    getPlanByProjectId() {
      this.api.getMainMileStone({ id: this.projectId }).then((res) => {
        if (res.data.data) {
          this.planId = res.data.data.id;
        }
      });
    },
    goPlan() {
      this.router.push({
        path: "/dashboard/ProjectTabs",
        query: {
          projectId: this.projectId,
          planId: this.planId,
          sopDate: this.sopDate,
          folderId: "project@folder-" +this.projectId,
          currentTab: "Plan"
        },
      });
    },
    segmentChanged(ev: CustomEvent) {
      this.selSeg = ev.detail.value;
    },
    onCollect() {
      const params = {
        projectId: this.projectId,
        isCollection: this.collect == 1 ? 0 : 1,
      };
      this.api.getCollection(params).then(() => {
        this.collect = this.collect == 1 ? 0 : 1;
        if (this.collect) {
          Utils.presentToast("收藏成功！");
        } else {
          Utils.presentToast("取消收藏成功！");
        }
      });
    },
    dealWithData() {
      this.freigabeP = this.boardCheckpint.filter((item: any) => {
        return item.cardName == "P-Freigabe";
      });
      this.freigabeB = this.boardCheckpint.filter((item: any) => {
        return item.cardName == "B-Freigabe";
      });
      this.freigabeK = this.boardCheckpint.filter((item: any) => {
        return item.cardName == "K-Freigabe";
      });
      this.pabProgress = this.boardCheckpint.filter((item: any) => {
        return item.cardName == "PAB/OTS/BMG";
      });
      this.milestone = this.boardCheckpint.filter((item: any) => {
        return (
          item.cardName == "Design & Vorentwicklung" ||
          item.cardName == "Daten Management" ||
          item.cardName == "Versuchbautraeger" ||
          item.cardName == "Uebergabe" ||
          item.cardName == "GesamtFzg"
        );
      });

      for (const item of this.pabProgress[0].list) {
        if (item.soll != 0) {
          item.percent = ((item.ist / item.soll) as any).toFixed(2) * 100;
        } else {
          item.percent = 0;
        }
      }
    },
    getDashboardCheckpint() {
      const params = {
        milestone: this.projectPeriod,
        projectId: this.projectId,
      };
      this.api.getDashboardCheckpint(params).then((res) => {
        this.boardCheckpint = res.data.data;
        this.dealWithData();
      });
    },
    getHits() {
      const params = {
        projectId: this.projectId,
      };
      this.api.getHits(params).then((res) => {
        console.log(res.data.msg);
      });
    },
  },
  created() {
    this.collect = this.$route.query.isCollection as any;
    this.projectPeriod = this.$route.query.projectPeriod as any;
    this.projectId = this.$route.query.projectId as any;
    this.projectName = this.$route.query.projectName as any;
    this.gesamt.sopTime = this.$route.query.sopTime as any;
    this.gesamt.projectCartypePlatform = this.$route.query
      .carBodyPlattform as any;
    this.gesamt.projectType = this.$route.query.projectType as any;
    this.gesamt.factoryName = this.$route.query.factoryName as any;
    this.gesamt.planningVolume = this.$route.query.planningVolume as any;

    this.getPlanByProjectId();
    this.getHits();
    this.getDashboardCheckpint();
  },
});
