
import { defineComponent } from "vue";
import { alertController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { TaskGroupService } from "@/services/task/TaskGroupService";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "taskGroupList",
  components: {},
  props: {
    projectId: String,
    isHasPermission:  {
      type: Object,
      required: false,
      default: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
      },
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    return {
      taskGroupList: new Array(0),
      taskGroupService: new TaskGroupService(),
      router: router,
      useRoute: useRoute(),
      searchParams: _param,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.resetSearchParam();
      this.resetPage();
    },
    resetSearchParam() {
      this.searchParams = {
        limit: 20,
        offset: 1,
        projectId: this.projectId,
      };
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        if (res == null || res.length <= 0) {
          this.taskGroupList = [];
        } else {
          this.taskGroupList = res;
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.taskGroupList = this.taskGroupList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
          //  event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.taskGroupService
        .getByProjectForDelete(this.searchParams)
        .then((res: any) => {
          callback(res);
        });
    },
    //删除
    async clearById(id: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要把该任务分组删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                id: id,
              };
              this.taskGroupService.clearById(params).then((res: any) => {
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    //还原
    async restoreById(id: any) {
      const alert = await alertController.create({
        header: "还原",
        message: "您确定要把该任务分组还原吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                id: id,
              };
              this.taskGroupService.restoreTaskGroup(params).then((res: any) => {
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    formatTime(modifyTime: any) {
      return SystemUtils.fromatTime(modifyTime);
    },
  },
});
