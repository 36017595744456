
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import MilestoneSlide from "@/views/dashboard/component/MilestoneSlide.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "Milestone",
  components: {},
  props: {
    taskItem: Object,
    projectId: String,
    projectPeriod: String,
    planId: Number,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
    };
  },
  methods: {
    async itemClick(item: any) {
      const modal = await modalController.create({
        component: MilestoneSlide,
        cssClass: "notice-modal-class",
        componentProps: {
          name: item.name,
          projectId: this.projectId,
          projectPeriod: this.projectPeriod,
        },
      });
      await modal.present();
    },
    goFolder(item: any) {
      this.router.push({
        path: "/dashboard/ProjectTabs",
        query: {
          folderId:
            "project@folder-" + this.projectId + "-" + item.name,
          planId: this.planId,
          projectId: this.projectId,
          currentTab: "Document"
        },
      });
    },
  },
});
