
import { modalController, alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import SubscribeCard from "../component/SubscribeCard.vue";
import AddCalendarOther from "../add/AddCalendarOther.vue";
import { CalendarSubscribeService } from "@/services/calendar/CalendarSubscribeService";
import { CalendarSubscribeApplyService } from "@/services/calendar/CalendarSubscribeApplyService";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";

export default defineComponent({
  name: "MySubscribeSetting",
  components: {
    SubscribeCard,
  },
  data() {
    const router = useRouter();
    const createCalendar = [
      {
        calName: "",
        sourceType: "",
        workUserId: "create",
      },
    ];
    return {
      mySubscribeService: new CalendarSubscribeService(),
      router: router,
      canlendarList: new Array(0),
      searchParams: {
        limit: 10,
        offset: 1,
        searchKey: "",
      },
      createCalendar: createCalendar,
      userId: "",
    };
  },
  props: {
    refresh: Boolean, //是否刷新页面
    params: Object, //搜索条件
  },
  created() {},
  mounted() {
    if (this.params != undefined && this.params.searchKey != undefined) {
      this.searchParams.searchKey = this.params.searchKey;
    }
    this.initData();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        if (this.params != undefined && this.params.searchKey != undefined) {
          this.searchParams.searchKey = this.params.searchKey;
        }
        // this.searchParams.name=this.searchValue+"";
        this.resetPage();
      }
    },
  },
  methods: {
    initData() {
      const currentUser: any = SystemUtils.loginUser;
      this.userId = currentUser.id;
      this.resetPage();
    },
    bindResultEmit(r: any) {
      if (r) {
        //选择时间结果
        if (r.type == 0) {
          console.log(r.value);
        }
        //更多
        if (r.type == 1) {
          console.log(r.value);
        }
      }
    },
    //重新从第一页获取
    resetPage(event?: any) {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.canlendarList = res;
        if (event) {
          event.target.complete();
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        event.target.complete();
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            this.canlendarList = this.canlendarList.concat(result);
          }
        }
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    doRefresh(event: any) {
      this.resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //删除
    async deleteItem(id: string) {
      if (id) {
        const alert = await alertController.create({
          header: "删除",
          message: "您确定要删除吗？",
          mode: "ios",
          buttons: [
            {
              text: "取消",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {},
            },
            {
              text: "确定",
              handler: () => {
                //删除
                this.deleteById(id);
              },
            },
          ],
        });
        await alert.present();
      }
    },
    //订阅他人日历
    async create() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: new Array(0),
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data.length > 0) {
        //返回选人集合
        let userIds: Array<String> = data.data.map(function (
          e: any,
          i: number
        ) {
          return e.userId;
        });
        //不能订阅自己的日历
        let that = this;
        let list: any = userIds.filter((ele: any) => {
          return ele != that.userId;
        });
        if (userIds.length == list.length) {
          const modal = await modalController.create({
            component: AddCalendarOther,
            cssClass: "notice-modal-class",
            componentProps: {
              userIds: userIds,
            },
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if (data && data.saveList) {
            //保存数据库
            const applyService = new CalendarSubscribeApplyService();
            applyService.insertList(data.saveList).then((res: any) => {
              if (res) {
                Utils.presentToastSuccess("订阅申请发送成功");
              }
            });
          }
        } else {
          Utils.presentToastWarning(
            "请不要订阅自己的日历。请重新选择订阅人员。"
          );
        }
      }
    },
    //订阅他人日历[目前返回一个人员，因为现在接口不支持多选]userIds:any
    async createHandler(userIds: Array<string>) {
      //不能订阅自己的日历
      let that = this;
      let list: any = userIds.filter((ele: any) => {
        return ele != that.userId;
      });
      if (userIds.length == list.length) {
        const modal = await modalController.create({
          component: AddCalendarOther,
          cssClass: "notice-modal-class",
          componentProps: {
            userIds: userIds,
          },
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data && data.saveList) {
          //保存数据库
          const applyService = new CalendarSubscribeApplyService();
          applyService.insertList(data.saveList).then((res: any) => {
            if (res) {
              Utils.presentToastSuccess("订阅申请发送成功");
            }
          });
        }
      } else {
        Utils.presentToastWarning("请不要订阅自己的日历。请重新选择订阅人员。");
      }
    },
    //获取我订阅的日历
    getListRequest(callback: any) {
      this.mySubscribeService
        .getMySubscribeList(this.searchParams)
        .then((res: any) => {
          if (res && res.data) {
            callback(res.data);
          }
        });
    },
    //删除我订阅的日历
    deleteById(id: string) {
      let that = this;
      that.mySubscribeService.deleteById({ id: id }).then((res: any) => {
        if (res) {
          Utils.presentToastSuccess("删除成功");
          that.resetPage();
        }
      });
    },
  },
});
