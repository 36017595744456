/**
 * ProjectPage 项目详情-概览 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectTeamApi extends TaskBaseService {

   constructor() {
      super("projectTeams");
   }
   //查询项目团队任务统计情况
   public getProjectTeamMemberCount(params: any): Promise<any> {
      const path = 'getProjectTeamMemberCount';

      return this.request(path, this.rest.METHOD_GET, params);
   }
   //查询项目团队角色 tree
   public getAllNodeByTreeId(params: any): Promise<any> {
      const path = 'getAllNodeByTreeId';
      return this.request(path, this.rest.METHOD_GET, params);
   }
   //获取用户所有团队
   public getAllTeamsByUserId(params: any): Promise<any> {
      const path = 'getAllTeamsByUserId';
      return this.request(path, this.rest.METHOD_GET, params);
   }

   //判断子团队是否存在
   public getExistsTeam(params: any): Promise<any> {
      const path = 'existsTeam';
      return this.request(path, this.rest.METHOD_GET, params);
   }

   //添加子团队
   public getInsertProjectTeam(params: any): Promise<any> {
      const path = 'insertProjectTeam';
      return this.request(path, this.rest.METHOD_POST, params);
   }

   //更新子团队
   public getUpdateProjectTeam(params: any): Promise<any> {
      const path = 'updateProjectTeam';
      return this.request(path, this.rest.METHOD_POST, params);
   }
   //删除子团队
   public deleteProjectTeam(params: any, projectId: any): Promise<any> {
      const path = 'deleteByKeys?projectId=' + projectId;
      return this.request(path, this.rest.METHOD_POST, params);
   }
}