
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { DashboardService } from "@/services/dashboardService";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "GroundInfo",
  components: {},
  props: {
    projectId: String,
    planId: Number,
  },
  data() {
    const router = useRouter();
    return {
      api: new DashboardService(),
      router: router,
      groundList: [
        {
          icon: "iconD8D8D8",
          text: "Orga",
          color: "#75bdf8",
        },
        {
          icon: "iconModelmix",
          text: "Modelmix",
          color: "#95df89",
        },
        {
          icon: "iconAermdungsumfang",
          text: "Aerndrung sumfang",
          color: "#e98160",
        },
        {
          icon: "iconSilhoulette",
          text: "Silhoulette",
          color: "#8699f4",
        },
        {
          icon: "iconSteckbrief",
          text: "Steckbrief",
          color: "#efc17f",
        },
        {
          icon: "iconBudget",
          text: "Budget",
          color: "#e49595",
        },
      ],
    };
  },
  methods: {
    async goFolder(item: any) {
      if (item.text == "Orga") {
        this.router.push({
          path: "/dashboard/ProjectTabs",
          query: {
            selSeg: 3,
            planId: this.planId,
            projectId: this.projectId,
            folderId: "project@folder-" +this.projectId,
            currentTab: "mainInfo"
          },
        });
      } else if (item.text == "Budget") {
        let auth = true;
        await this.api
          .getCheckAuth({ id: this.projectId })
          .then((res: any) => {
            auth = res.data.data;
          });
        if (!auth) {
          Utils.presentToast("您没有权限查看Budget文件！");
        } else {
          this.router.push({
            path: "/dashboard/ProjectTabs",
            query: {
              folderId:
                "project@folder-" + this.projectId + "-" + item.text,
              planId: this.planId,
              projectId: this.projectId,
              currentTab: "Document"
            },
          });
        }
      } else {
        this.router.push({
          path: "/dashboard/ProjectTabs",
          query: {
            folderId:
              "project@folder-" + this.projectId + "-" + item.text.replace(" ",""),
            planId: this.planId,
            projectId: this.projectId,
            currentTab: "Document"
          },
        });
      }
    },
  },
});
