<template>
  <ion-page>
    <ion-tabs>
      <!-- <Home v-if="currentTab == 'home'" ref="home" /> -->
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" animation="no-animation" href="/tabs/message">
          <ion-img
            class="otherImg"
            :src="
              currentTab == 'message'
                ? require('@/assets/images/messageH.png')
                : require('@/assets/images/message.png')
            "
          ></ion-img>
          <ion-label
            :style="{
              color: currentTab == 'message' ? '#6391d9' : '#9b9db1',
              marginTop: '7px',
            }"
            >消息</ion-label
          >
        </ion-tab-button>
        <ion-tab-button tab="tab2" animation="no-animation" :href="'/tabs/homePage?corpId='+corpId">
          <!--<div class="homeIcon checked" v-if="currentTab == 'home'">
            <span :class="homeCheck"></span>
          </div>
          <div class="homeIcon unchecked" v-else>
            <span :class="home"></span>
          </div>-->

          <ion-img
            class="homeImg"
            :src="require('@/assets/images/home.png')"
          ></ion-img>
          <ion-label :style="{ color: currentTab == 'home' ? '#6391d9' : '#9b9db1'}" style="margin-top:6px">首页</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab3" animation="no-animation"  href="/tabs/mine">
          <ion-img
            class="otherImg"
            :src="
              currentTab == 'mine'
                ? require('@/assets/images/mineSelect.png')
                : require('@/assets/images/mine.png')
            "
          ></ion-img>
          <ion-label
            :style="{ color: currentTab == 'mine' ? '#6391d9' : '#9b9db1',
              marginTop: '7px', }"
            >我的</ion-label
          >
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  useBackButton,
  useIonRouter,
  IonImg,
  IonItem,
  IonRouterOutlet,
} from "@ionic/vue";

import { defineComponent, reactive, ref, watch, onUnmounted } from "vue";
// lottie
import lottieVue from "vue-lottie";
import lottie from "./Lottie.vue";
import * as animationDataMsg from "../../assets/images/home/msg.json";
import * as animationDataMine from "../../assets/images/home/setting.json";
import Bus from "@/utils/bus";

import Home from "@/views/home/Home.vue";
import Mine from "@/views/mine/Mine.vue";
import { Plugins } from "@capacitor/core";
import { closeCircle } from "ionicons/icons";
const { SplashScreen, App } = Plugins;

animationDataMsg.assets.forEach((item) => {
  item.u = "";
  if (item.w && item.h) {
    item.p = require(`@/assets/images/home/images/${item.p}`);
  }
});
animationDataMine.assets.forEach((item) => {
  item.u = "";
  if (item.w && item.h) {
    item.p = require(`@/assets/images/home/images/${item.p}`);
  }
}); // 获取静态资源
export default defineComponent({
  name: "Tabs",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    lottieVue,
    lottie,
    Home,
    Mine,
    IonImg,
    IonItem,
    IonRouterOutlet,
  },
  watch: {
    $route(to, from,self) {
      if (from.path == "/login") {
        this.currentTab = "home";
      }else if(to.path=="/tabs/mine"){
        this.currentTab = "mine";
      }else if(to.path=="/tabs/message"){
        this.currentTab = "message";
      }else{
         this.currentTab = "home";
      }
    },
  },
  data() {
    const date = new Date();
    const timeout = null;
    return {
      /*message: "iconfont iconxiaoxi",
      messageCheck: "iconfont iconxiaoxixuanzhong",*/
      message: "#iconxiaoxi",
      messageCheck: "#iconxiaoxixuanzhong",
      home: "iconfont iconiconshouyeweixuanzhong",
      homeCheck: "iconfont iconiconshouyexuanzhong",
      /*mine: "iconfont iconwode",
      mineCheck: "iconfont iconwodexuanzhong",*/
      mine: "#iconwode",
      mineCheck: "#iconwodexuanzhong",
      currentTab: "home",
      closeCircle,
      defaultOptions1: {
        animationData: animationDataMsg.default,
        renderer: "svg",
        loop: false,
        autoplay: false,
      },
      defaultOptions2: {
        animationData: animationDataMine.default,
        renderer: "svg",
        loop: false,
        autoplay: false,
      },
      defaultAnim1: {},
      defaultAnim2: {},
      aiRobot: {},
      hasBg: false,
      lWidth: 26,
      lHeight: 30,
      count: 0,
      isRefresh: false,
      date: date,
      timeout: timeout,
      corpId:''
    };
  },
  ionViewDidEnter() {
    if (this.currentTab == "setting") {
      this.isRefresh = !this.isRefresh;
    }

    SplashScreen.hide();
  },
  created() {
      this.corpId = localStorage.getItem("corpId");
      document.addEventListener("deviceready", function(){}, false);
    this.t;
    Bus.$on("not_read_msg_count", (count) => {
      this.count = count;
    });
  },
  setup() {
    onUnmounted(() => {
      Bus.$off("not_read_msg_count");
    });
    const ionRouter = useIonRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  },
  methods: {
    bindTab(tabBtn) {
      this.currentTab = tabBtn;
      if (tabBtn == "setting") {
        this.defaultAnim2.goToAndPlay(2);
        this.defaultAnim1.goToAndStop(1);
        //this.$router.push({path:`/tabs/mine`})
      } else if (tabBtn == "message") {
        this.defaultAnim1.goToAndPlay(2);
        this.defaultAnim2.goToAndStop(1);
        //this.$router.push({path:`/tabs/message`})
      } else {
        this.defaultAnim1.goToAndStop(1);
        this.defaultAnim2.goToAndStop(1);
        //this.$router.push({path:`/tabs/home`})
      }
    },

    handleAnimation1: function (anim) {
      this.defaultAnim1 = anim;
    },
    handleAnimation2: function (anim) {
      this.defaultAnim2 = anim;
    },
    gotoHome(flag) {
      this.bindTab("home");
    },
    navbar(url){
      this.$router.push({
        path:url
      })
      // window.location.href(url)
    }
  },
});
</script>
<style scoped lang="scss">
/* 下面这条样式用来隐藏 Tabs 自动生成的 ion-router-outlet */
// :deep() .tabs-inner {
//   display: none;
// }
.number {
  z-index: 100;
  width: px2rem(24);
  height: px2rem(24);
  font-size: px2rem(17);
  margin: 0 0 px2rem(-25) px2rem(50);
  color: white;
  background: #e55f5f;
  border-radius: 50%;
  line-height: px2rem(24);
  text-align: center;
}
ion-tab-bar {
  --background: var(--ion-tab-bar-background);
  --border: none;
  height:9rem;
  background: url(#{$tab-background-png}) no-repeat;
  background-size: 110% 100%;
  background-position: center;
  background-color: #{$tab-page-background};
  ion-tab-button {
    --color: #{$font-default-color};
    --color-focused: #{$font-selected-color};
    --color-selected: var(--ion-tab-bar-background);
    --background-focused: var(--ion-tab-bar-background);
    --background-focused-opacity: 0;
    padding-top: 0.8rem;
    height: 100%;
    span,
    svg {
      /*font-size: px2rem(40px);*/
      width: px2rem(98);
      height: px2rem(98);
      margin: px2rem(15) 0 px2rem(5) 0;
    }
    ion-label {
      font-size: $default-font-size;
    }
    div {
      width: px2rem(98px);
      height: px2rem(98px);
      line-height: px2rem(102px);
      text-align: center;
      margin: px2rem(8px) 0 px2rem(4px) 0;
    }
    div.homeIcon {
      background: #ffffff;
      border-radius: 50%;
    }
    .unchecked {
      span {
        font-size: px2rem(98px);
        color: #{$font-default-color};
      }
    }
    .checked {
      span {
        font-size: px2rem(94px);
        color: $color;
      }
    }
  }
}
.lottie {
  width: 40px;
  height: 40px;
}
.homeImg {
  width: 40px;
  height: 40px;
}
.otherImg {
  width: 20px;
  height: 20px;
}
ion-tab-button{
  justify-content:flex-end
}
</style>
