
import { defineComponent } from "vue";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "Test",
  components: {},
  data() {
    return {
      date: "",
      suggestDatetime: "",
    };
  },
  created() {
    this.formatDatetime("DEND", "2021-05-31");
  },

  // Y2021W17  这周
  // Y2021W18  下周
  // Y2021M04D20 今天
  // Y2021M04D21 明天
  // Q02 这个季度
  // Q01 上个季度
  // Q03  第三季度
  // Y2021Q01 今年第一季度
  // Y2020Q03 去年第三季度
  // Y2021M03 上个月
  // Y2021M04 这个月
  // M04 4月
  // Y2020M04 去年4月
  // M01/M05 一月到5月
  // Y2021W17/Y2021W18 这周到下周
  // Y2021W17WD03/Y2021W17WD05 这周三到周五 2021-04-21/2021-04-23
  // Y2021W17WD05 这周五 2021-04-23
  // Y2021M04D20/Y2021M04D22 今天到后天
  // Y2021M04D20/Y2021M04D22Th08m00s00 今天到后天八点
  // Y2021M04D20Th08m00s00/Y2021M04D20Th12m00s00 今天八点到12点
  // Y2021M04D20Th08m00s00/Y2021M04D22 今天八点到后天
  // O-[7D] 近七天
  // O+[7D] 往后七天内
  // O-[3M] 近三个月
  // O+[3M] 往后三个月
  // Y2021M04D22TAM 上午
  // Th14m00s00 下午2点
  // Y2021W18WD03Th18m00s00  下周三下午6点
  // WD05 周五
  // Y2021M04D23Th09m00s00 明天上午9点
  // Y2021M04D23TPM  明天下午
  // M04D25Th09m00s00  4月25日9点
  // Y2021Q02  2021年第二季度
  // D26Th08m00s00  26日8点
  // M04  4月份
  // Y2021M04D25Th10m00s00/Y2021M04W19WD03Th18m00s00 昨天10点到下周三18点 2021-04-25T10:00:00/T18:00:00
  // D00 2021-05-00  月底
  // Y2021M05D00  2021-05-00  本月底
  // DEND  2021-05-31 月底
  methods: {
    // 格式化时间
    formatDatetime(date: any, suggestDatetime: any, isMerge: boolean = false) {
      let dateArr = [];
      if (date.indexOf("/") >= 0) {
        dateArr = date.split("/");
      } else {
        dateArr.unshift(date, date);
      }

      let suggestArr = [];
      if (suggestDatetime.indexOf("/") >= 0) {
        suggestArr = suggestDatetime.split("/");
      } else {
        suggestArr.unshift(suggestDatetime, suggestDatetime);
      }

      let start = "";
      let end = "";
      for (let i = 0; i < dateArr.length; i++) {
        let d = dateArr[i];
        let year = "";
        let month = "";
        let day = "";
        let T = "";
        let h = "";
        let m = "";
        let s = "";
        // 获取年
        let index = d.indexOf("Y");
        if (index >= 0) {
          year = d.substr(index + 1, 4);
        } else {
          year = moment().year().toString();
        }
        // 获取月
        index = d.indexOf("M");
        if (index >= 0) {
          month = d.substr(index + 1, 2);
        } else {
          month = (moment().month() + 1).toString();
          month = month.length < 2 ? "0" + month : month;
        }
        // 获取日
        index = d.indexOf("D");
        if (index >= 0) {
          day = d.substr(index + 1, 2);
        } else {
          if (d.indexOf("Y") < 0 && d.indexOf("M") < 0) {
            day = moment().date().toString();
            day = day.length < 2 ? "0" + day : day;
          } else {
            if (i == 0) {
              day = "01";
            } else {
              day = moment(year + "-" + month + "-01")
                .endOf("month")
                .format("YYYY-MM-DD");
              day = day.substr(day.length - 2);
            }
          }
        }
        // 周
        index = d.indexOf("W");
        if (index >= 0) {
          index = d.indexOf("WD");
          if (index >= 0) {
            //   console.log("2021-04-28T18:00:00".replace("T", " "));
            if (suggestArr[i].length > 10) {
              suggestArr[i] = suggestArr[i].replace("T", " ");
            }
            if (i == 0) {
              start = suggestArr[i] + " ";
            } else {
              end = suggestArr[i] + " ";
            }
          } else {
            if (i == 0) {
              start = moment(suggestArr[i])
                .startOf("isoWeek")
                .format("YYYY-MM-DD ");
            } else {
              end = moment(suggestArr[i])
                .endOf("isoWeek")
                .format("YYYY-MM-DD ");
            }
          }
        }
        // 月底
        index = d.indexOf("D00") >= 0 ? d.indexOf("D00") : d.indexOf("DEND");
        if (index >= 0) {
          let suggest =
            suggestArr[i].indexOf("-00") >= 0
              ? suggestArr[i].replace("-00", "-01")
              : suggestArr[i];
          if (i == 0)
            start = moment(suggest).endOf("month").format("YYYY-MM-DD ");
          else end = moment(suggest).endOf("month").format("YYYY-MM-DD ");
        }
        // 季度
        index = d.indexOf("Q");
        if (index >= 0) {
          if (i == 0) {
            start = moment(suggestArr[i])
              .startOf("quarter")
              .format("YYYY-MM-DD ");
          } else {
            end = moment(suggestArr[i]).endOf("quarter").format("YYYY-MM-DD ");
          }
        }
        // 日期时间前后
        index = d.indexOf("O");
        if (index >= 0) {
          let now = moment().format("YYYY-MM-DD HH:mm:ss");
          if (i == 0) {
            if (d.indexOf("-") >= 0) {
              end = now;
              start = suggestArr[i] + " ";
            } else {
              start = now;
              end = suggestArr[i] + " ";
            }
          }
        }
        // 时间
        index = d.indexOf("T");
        if (index >= 0) {
          index = d.indexOf("h");
          if (index >= 0) {
            h = d.substr(index + 1, 2);
          }
          index = d.indexOf("m");
          if (index >= 0) {
            m = d.substr(index + 1, 2);
          } else {
            if (i == 0) {
              m = "00";
            } else {
              m = "59";
            }
          }
          index = d.indexOf("s");
          if (index >= 0) {
            s = d.substr(index + 1, 2);
          } else {
            if (i == 0) {
              s = "00";
            } else {
              s = "59";
            }
          }
          T = h + ":" + m + ":" + s;
          index = d.indexOf("T");
          let timeFrame = d.substr(index + 1);
          switch (timeFrame) {
            case "EAM":
              if (i == 0) {
                T = "01:00:00";
              } else {
                T = "06:00:00";
              }
              break;
            case "AM":
              if (i == 0) {
                T = "06:00:00";
              } else {
                T = "12:00:00";
              }
              break;
            case "MID":
              if (i == 0) {
                T = "12:00:00";
              } else {
                T = "13:00:00";
              }
              break;
            case "PM":
              if (i == 0) {
                T = "13:00:00";
              } else {
                T = "18:00:00";
              }
              break;
            case "EV":
              if (i == 0) {
                T = "18:00:00";
              } else {
                T = "20:00:0";
              }
              break;
            case "NI":
              if (i == 0) {
                T = "20:00:0";
              } else {
                T = "23:59:59";
              }
              break;
          }
        } else {
          if (i == 0) {
            T = "00:00:00";
          } else {
            T = "23:59:59";
          }
        }
        // 最后处理时间
        if (i == 0 && !start) {
          start = year + "-" + month + "-" + day + " ";
        }
        if (i == 0 && start && start.length < 19) {
          start = start + T;
        }
        if (i == 1 && !end && d.indexOf("O") <= 0) {
          end = year + "-" + month + "-" + day + " ";
        }
        if (i == 1 && end && end.length < 19) {
          end = end + T;
        }
      }
      console.log("formatDatetime： " + start + " 至 " + end);
      if (
        isMerge &&
        start.length >= 10 &&
        end.length >= 10 &&
        start.substr(0, 10) == end.substr(0, 10)
      ) {
        return start.substr(0, 10);
      }
      if (start.length < 19 || end.length < 19) {
        return null;
      }
      return [start, end];
    },
  },
});
