import { TaskApi } from '@/api/task/TaskApi';
import { TaskSystemsApi } from '@/api/task/TaskSystemsApi';
import { TaskLinkApi } from '@/api/task/TaskLinkApi';
import SystemUtils from '@/utils/SystemUtils';
import BaseService from '../base/BaseService';
import User from '@/models/task/User';
import moment from 'moment';
import TagLink from './model/TagLink';
import Tag from './model/Tag';
import { DocumentApi } from '@/api/file/DocumentApi';
import Utils from '@/utils/Utils';
import Permission, { EPermissionType } from '@/models/permission/Permission';
import { PermissionService } from '../permission/PermissionService';
import { MainPlanApi } from '@/api/mainplan/MainPlanApi';
import Bus from "@/utils/bus";
/**
 * 任务模块：任务列表 & 基础信息
 */
export class TaskService extends BaseService {
    private api = new TaskApi();
    private permissionService = new PermissionService();
    private taskLinkApi = new TaskLinkApi();
    private taskSystemApi = new TaskSystemsApi();
    private documentApi = new DocumentApi();
    private mainPlanApi = new MainPlanApi();
    public getTaskList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.list(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //独立任务回收站列表
    public getDeleteIndependentTask(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDeleteIndependentTask(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getTaskInfo(taskId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getTaskInfo({
                "id": taskId
            }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 转为子任务
     * @param param 
     */
    public convertToChildTask(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.convertToChildTask(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取任务可转为子任务的父任务
     * @param param 
     */
    public getToBeParentTaskList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getByProjectIdAndVisibility(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取新增微议题可选任务列表，根据项目id
     * @param param 
     */
    public getAppTaskListByMember({
        projectId = "",
        taskName = "",
        offset = 1,
        limit = 15
    }): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAppTaskListByMember({
                projectId,
                taskName,
                offset,
                limit,
            }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }



    public getNewModel(info?: any) {
        let taskData: any = super.newModel("task", "task");

        //新增任务时的默认值
        taskData.isUserFinished = true;
        taskData.isChildsFinished = true;
        taskData.isCheckFinished = true;
        taskData.isDeletedProject = false;
        taskData.isExistChild = false;//是否存在子任务
        taskData.isExistMsg = false;
        taskData.isExistLink = false;//是否存在外链接
        taskData.code = null;
        taskData.finishId = null;
        taskData.finisher = null;
        taskData.isFinished = false;
        taskData.isUserDelete = true;
        taskData.executorUsers = [];
        taskData.partUsers = [];
        taskData.remark = info.remark;
        taskData.actualStartTime = null;
        taskData.actualEndTime = null;
        taskData.actualWorkingHour = 0;
        taskData.progress = 0;

        if (info && typeof (info.taskVisibility) == "number") {
            taskData.taskVisibility = info.taskVisibility + "";
        } else {
            //可见性，有项目，默认项目成员可见。无项目默认任务成员可见。
            taskData.taskVisibility = info.projectId ? "2" : "3";
        }

        if (info) {
            if (info.executorUsers) {
                info.executorUsers.map((e: any) => {
                    taskData.executorUsers.push({
                        userId: e.userId,
                        name: e.name,
                        workType: e.workType,
                    });
                });
            }
            if (info.partUsers) {
                info.partUsers.map((e: any) => {
                    taskData.partUsers.push({
                        userId: e.userId,
                        name: e.name,
                        workType: e.workType,
                    });
                });
            }
            taskData = Object.assign(info, taskData);
            if (typeof (taskData.priority) == "number") {
                taskData.priority = taskData.priority + "";
            }
        }

        return taskData;
    }

    /**
     * 任务保存（包括子任务）
     * @param data 保存的内容
     */
    public insertTask(data: any, tagLink?: Array<Tag>): Promise<any> {
        //root ，若root为空，那么就等于id
        if (!data.rootId) {
            data.rootId = data.id;
        }
        //时间的处理
        if (data.startTime) {
            data.startTime = moment(data.startTime).format("YYYY-MM-DD HH:mm:ss");
        }
        if (data.endTime) {
            data.endTime = moment(data.endTime).format("YYYY-MM-DD HH:mm:ss");
        }

        if (tagLink && tagLink.length > 0) {
            const taskTags: TagLink[] = [];
            tagLink.map((e) => {
                const newvalue = new TagLink(e, data.id);
                taskTags.push(newvalue);
            });
            data.tagLink = taskTags;
        }
        return new Promise((resolve, reject) => {
            this.api.addTask(data).then(async (res: any) => {
                if (res.data)
                    resolve(data);
                else
                    resolve(null);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //获取项目任务视图-- 我执行的 my 所有任务 all 今天的任务 today 未完成的任务 noFinish 已完成的任务 finish    
    public getTaskFroProjectIndexList(param: any): Promise<any> {
        const user = SystemUtils.loginUser;
        param.userId = user.id;
        return new Promise((resolve, reject) => {
            this.api.getTaskFroProjectIndexList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 移到回收站
     * @param id 
     */
    public removeToRecycle(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.removeToRecycle(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                Utils.presentToastWarning("删除失败");
                reject(err);
            });
        });
    }


    /**
     * 取消内容关联
     * @param id 
     */
    public removeLinkContent(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.taskLinkApi.removeLink(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 更新某个字段
     * @param data 
     */
    public updateFieldsById(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.updateFieldsById(data).then(async (res: any) => {
                Bus.$emit("data_update_listener", {type:"task"});
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //获取任务来源
    public getTaskSourceList(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.taskSystemApi.getSelectAll().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public changeFinishStatus(taskId: string, isFinished: boolean): Promise<any> {
        const data: any = { id: taskId, isFinished: isFinished, setFields: ["isFinished", "finishTime", "finisher", "finishId"] };
        if (isFinished) {
            const currentUser: User = SystemUtils.loginUser;
            data.finishId = currentUser.id;
            data.finisher = currentUser.name;
            data.finishTime = moment().format("yyyy-MM-DD HH:mm:ss");
        } else {
            data.finishId = "";
            data.finisher = "";
            data.finishTime = null;
        }

        return this.updateFieldsById(data);
    }

    public getFilesByDocmentIds(ids: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.documentApi.getFilesByIds(ids).then(async (res: any) => {
                const lst: any[] = [];
                res.data.map((e: any) => {
                    lst.push({
                        fileId: e.id,
                        name: e.name,
                        fileUrl: e.fileUrl,
                        mainFileId: e.mainFileId,
                        extName: e.extName,
                        previewImage: e.previewImage,
                        className: e.className,
                        systemId: e.systemId,
                        busId: e.busId,
                        busTable: e.busTable,
                        busTag: e.busTag,
                        rootId: e.rootId,
                        folderId: e.folderId,
                        size: e.size,
                        documentId: e.documentId
                    });
                });
                resolve(lst);
            }).catch((err: any) => {
                reject(err);
            });
        });

    }


    public moveTask(taskId: string, targetProjectId: string, targetTaskGroupId: string, targetTaskStageId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.moveTask(taskId, targetProjectId, targetTaskGroupId, targetTaskStageId).then(async (res: any) => {
                if (!res.data) {
                    Utils.presentToastWarning("操作失败");
                }
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getTaskOverView(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getTaskOverView().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
    * 获取项目任务完成情况最近7天
    * */
    public getFinishTaskCount(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getFinishTaskCount(param).then(async (res: any) => {
                if (!res.data) {
                    Utils.presentToastWarning("操作失败");
                }
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getTaskPermissionByUser(isIndependentTask: boolean, busId: string): Promise<any> {
        const userId = SystemUtils.loginUser.id;
        return new Promise((resolve, reject) => {
            if (isIndependentTask) {
                //仅读取独立任务权限
                this.permissionService.getIndependentTaskPermission(busId, userId).then((res: any) => {
                    resolve(res);
                });
            } else {
                //promiseAll 对象数组
                let promiseArr = new Array<Promise<any>>();
                //读取项目任务权限
                promiseArr.push(this.permissionService.getUserPermissionByProject(busId, userId));
                promiseArr.push(this.permissionService.getTaskPermissionByProject(busId, userId));
                promiseArr.push(this.permissionService.getByEnterpriseIdAndUser());
                //promiseAll 处理，多个promise全部 
                Promise.all(promiseArr).then((allPromisResArr: Array<any>) => {
                    let per: Array<Permission> = [];
                    allPromisResArr.map(m => {
                        per = per.concat(m.data);
                    })
                    resolve(per);
                })
            }
        });

    }

    /**
    * 项目-回收站-任务列表
    * */
    public getByProjectForDelete(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getByProjectForDelete(param).then(async (res: any) => {
                if (!res.data) {
                    Utils.presentToastWarning("操作失败");
                }
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
    * 项目-回收站-任务列表--删除
    * */
    public clearById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.clearById(param).then(async (res: any) => {
                if (!res.data) {
                    Utils.presentToastWarning("操作失败");
                }
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
        * 项目-回收站-任务列表--还原
        * */
    public restoreById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.restoreById(param).then(async (res: any) => {
                if (!res.data) {
                    Utils.presentToastWarning("操作失败");
                }
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getTaskFromType(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getTaskFromType(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //获取计划中心的任务
    public getMainPlanTask(id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.mainPlanApi.getMainPlanTask(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //更新任务完成时间
    public updateIsFinishedByUser(taskId: any): Promise<any> {
        let params = {
            id: taskId,
            isFinished: true,
            userId: SystemUtils.loginUser.id,
            userName: SystemUtils.loginUser.name
        };
        return new Promise((resolve, reject) => {
            this.api.updateIsFinishedByUser(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //更新主计划任务
    public updataMainPlanInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.mainPlanApi.updataMainPlanInfo(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //任务转派
    public taskTransfer(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.taskTransfer(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //任务转派
    public getAllTaskByProjectIdAndParams(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAllTaskByProjectIdAndParams(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //任务工时是否饱满
    public isfullworkingHour(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.isfullworkingHour(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
     ////获取任务工时详细
     public getWorkingHourByTaskId(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getWorkingHourByTaskId(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
     //任务-项目列表任务列表
     public getProjectByTask(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getProjectByTask(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    
}