export default class AudioUtils{

}
// Determines which platform the demo runs on
export const isMobile = {
    /**
     * @return {boolean}
     */
    Android: function () {
        return /Android/i.test(navigator.userAgent);
    },
    /**
     * @return {boolean}
     */
    BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
    },
    iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS());
    }
};

/**
 *
 * @param onSuccess
 * @param onDenied
 * @param onError
 */
export const getMicrophonePermission =(onSuccess:any, onDenied:any, onError:any) => {
    (<any>window).audioinput.checkMicrophonePermission(function (hasPermission:any) {
        try {
            if (hasPermission) {
                if (onSuccess) onSuccess();
            }
            else {
                (<any>window).audioinput.getMicrophonePermission(function (hasPermission:any, message:any) {
                    try {
                        if (hasPermission) {
                            if (onSuccess) onSuccess();
                        }
                        else {
                            if (onDenied) onDenied("User denied permission to record: " + message);
                        }
                    }
                    catch (ex) {
                        if (onError) onError("Start after getting permission exception: " + ex);
                    }
                });
            }
        }
        catch (ex) {
            if (onError) onError("getMicrophonePermission exception: " + ex);
        }
    });
};

export const pad2=(n:any)=> {
    return (n < 10 ? '0' : '') + n;
};