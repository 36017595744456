
import { defineComponent, ref } from "vue";
import { Px2rem } from "../libs/px2rem";
import { createAnimation, createGesture } from "@ionic/core";
import { settings } from "cluster";

export default defineComponent({
  name: "Calendar",
  data() {
    const calendarId = "calendar_" + new Date().getTime();
    return {
      minDate: new Date(),
      maxDate: new Date(),
      fontColor: "#3F3F4B",
      animateTopHei: 208,
      animateRowHei: 58,
      btnPoint: true,
      allWeek: 0,
      checkWeek: 0,
      durationAni: 400,
      pageYear: 0,
      pageMonth: 0,
      initDate: new Date(),
      pointDate_: new Date(),
      markerDays: new Array<number>(),
      calendarId: calendarId,
      lastAllWeek: 0,
      today: "",
    };
  },
  emits: ["transfer"],
  props: {
    pointDate: {
      type: Date || String,
      required: false,
      default: new Date() || "", //字符串："2020-01-02"
    },
    isMore: {
      type: Boolean,
      required: false,
      default: true,
    },
    isPage: {
      type: Boolean,
      required: false,
      default: true,
    },
    pageGesture: {
      type: Boolean,
      required: false,
      default: true,
    },
    bgColor: {
      type: String,
      required: false,
      default: "#538fff",
    },
    dateMarkers: {
      type: Array, //字符串数组格式：["2020-01-02","2020-02-04"]
      required: false,
      default: [],
    },
    isOpen: {
      //日历组件是否展开
      type: Boolean,
      required: false,
      default: false,
    },
    showExt: {
      //是否需要显示扩展组件
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    dateMarkers(newVal, oldVal) {
      this.findMarkers(this.pointDate_.getFullYear(), this.pointDate_.getMonth() + 1);
    },
  },
  created() {
    //页面渲染前初始化
    let pdt: Date = new Date();
    if (this.pointDate) {
      if (typeof this.pointDate == "string") {
        pdt = new Date(this.pointDate);
      } else {
        pdt = this.pointDate;
      }
    }
    this.today = pdt.getFullYear() + "-" + (pdt.getMonth() + 1) + "-" + pdt.getDate();
    this.initRankDate(pdt);
    this.initDefaultCheckDate(pdt);
    this.initStatisticsWeek(pdt);
  },
  mounted() {
    //页面渲染完成后初始化
    if (this.isOpen) {
      this.btnPoint = this.isOpen;
    } else {
      this.btnPoint = false;
    }
    
    if (this.pageGesture) {
      this.pageMoveCalendar();
    }
  },
  methods: {
    //初始选择时间范围
    initRankDate(now: Date) {
      this.pageYear = now.getFullYear();
      this.pageMonth = now.getMonth();
      this.setMonthPage();
    },
    initDefaultCheckDate(pdt: Date) {
      if (pdt) {
        this.initDate = pdt;
        this.pointDate_ = pdt;
        this.findMarkers(pdt.getFullYear(), pdt.getMonth() + 1);
      }
    },
    //计算月份内周数
    initStatisticsWeek(dt: Date) {
      if (dt) {
        const y = dt.getFullYear();
        const m = dt.getMonth();
        this.allWeek = this.getWeekOfMonth(y, m);
        this.checkWeek = this.getWeekOfDay(y, m, dt.getDate());
      }
    },
    //初始选择单行
    initCheckRow(scrollBody:any) {
      if (scrollBody) {
        const scrollHei = scrollBody.scrollHeight;
        const scrollPos = this.checkWeek == 1 ? 0 : (scrollHei * (this.checkWeek - 1)) / this.allWeek;
        console.log(scrollHei+','+this.checkWeek+","+this.allWeek);
        scrollBody.scrollTo({
          top: scrollPos,
        });
      }
    },
    bindFormatter(day: any) {
      if (day) {
        const date = day.date.getDate();
        let dayStr =
          day.date.getFullYear() +
          "-" +
          (day.date.getMonth() + 1) +
          "-" +
          day.date.getDate();
        let isToday = false;
        if (this.today == dayStr) {
          isToday = true;
        }
        let hasMaker = -1; //-1:无 0有且选择 1有不选择
        if (this.markerDays.length > 0) {
          this.markerDays.forEach((d: number) => {
            if (d === date) {
              day.topInfo = "0";
              day.type = "selected";
              if (date == this.pointDate_.getDate()) {
                day.className = "marker-sel";
                hasMaker = 0;
              } else {
                day.className = "marker";
                hasMaker = 1;
              }
            }
          });
        }
        if (isToday) {
          switch (hasMaker) {
            case -1:
              day.className = "no-maker today-class";
              break;
            case 0:
              day.className = "marker-sel today-class";
              break;
            case 1:
              day.className = "marker today-class";
              break;
          }
        }
      }
      return day;
    },
    findMarkers(year: number, month: number) {
      this.markerDays = new Array<number>();
      this.dateMarkers.forEach((ele: any) => {
        if (ele) {
          const marker: string = ele;
          if (
            parseInt(marker.substr(0, 4)) === year &&
            parseInt(marker.substr(5, 2)) === month
          ) {
            this.markerDays.push(parseInt(marker.substr(8, 2)));
          }
        }
      });
    },
    //上一月
    gotoUpMonth() {
      if (!this.btnPoint) {
        this.lastAllWeek = this.allWeek;
        this.pointDate_ = new Date(this.pageYear, this.pageMonth, 0);
        if (this.pageMonth == 0) {
          this.pageMonth = 11;
          this.pageYear = this.pageYear - 1;
        } else {
          this.pageMonth = this.pageMonth - 1;
        }
        this.setMonthPage();
        this.allWeek = this.getWeekOfMonth(this.pageYear, this.pageMonth);
        this.checkWeek = this.allWeek;
        this.findMarkers(this.pageYear, this.pageMonth + 1);
        this.resetBodyHeight();
        let m = this.pageMonth + 1;
        let month = m <= 9 ? "0" + m : m;
        const firstDay = this.pageYear + "-" + month + "-01";
        const lastDay = this.pageYear + "-" + month + "-" + this.pointDate_.getDate();
        this.setResult(2, { firstDay, pointDay: lastDay, lastDay });
      }
    },

    //下一月
    gotoDownMonth() {
      if (!this.btnPoint) {
        this.lastAllWeek = this.allWeek;
        if (this.pageMonth == 11) {
          this.pageMonth = 0;
          this.pageYear = this.pageYear + 1;
        } else {
          this.pageMonth = this.pageMonth + 1;
        }
        this.setMonthPage();
        this.allWeek = this.getWeekOfMonth(this.pageYear, this.pageMonth);
        this.checkWeek = this.getWeekOfDay(this.pageYear, this.pageMonth, 1);
        this.pointDate_ = new Date(this.pageYear, this.pageMonth, 1);
        this.findMarkers(this.pageYear, this.pageMonth + 1);
        this.resetBodyHeight();
        let m = this.pageMonth + 1;
        let month = m <= 9 ? "0" + m : m;
        const firstDay = this.pageYear + "-" + month + "-01";
        const lastDate = new Date(this.pageYear, m, 0);
        const lastDay = lastDate.getFullYear() + "-" + month + "-" + lastDate.getDate();
        this.setResult(2, { firstDay, pointDay: firstDay, lastDay });
      }
    },
    pageMoveCalendar() {
      let started = false;
      const square = document.querySelector(`#${this.calendarId}`);
      if (square != null) {
        const gesture = createGesture({
          el: square,
          threshold: 0,
          gestureName: "square-drag",
          onMove: (ev: any) => onMove(ev),
          onEnd: (ev: any) => onEnd(ev),
        });

        gesture.enable(true);

        const onMove = (ev: any) => {
          if (!started) {
            started = true;
          }
        };

        const onEnd = (ev: any) => {
          if (!started) {
            return;
          }
          gesture.enable(false);
          if (ev) {
            let x = ev.deltaX;
            let y = ev.deltaY;
            if (x > 50 && Math.abs(y) < 50) {
              //向右滑动
              this.gotoUpMonth();
            }
            if (x < -50 && Math.abs(y) < 50) {
              //向左滑动
              this.gotoDownMonth();
            }
            started = false;
            gesture.enable(true);
          }
        };
      }

      this.bindCalendarMove();
    },
    resetBodyHeight() {
      const scrollBody = document.querySelector(
        `#${this.calendarId} .van-calendar__days`
      );
      if (scrollBody) {
        if (this.lastAllWeek != this.allWeek) {
          const bodyHei = Px2rem.getRem(this.animateRowHei * this.allWeek + "px");
          const scrollHei = Px2rem.getRem(scrollBody.scrollHeight + "px");
          const myCalendarRef = ref(scrollBody);
          createAnimation()
            .addElement(myCalendarRef.value)
            .duration(this.durationAni)
            .fromTo("height", scrollHei, bodyHei)
            .play();
        }
      }
    },
    setMonthPage() {
      this.minDate = new Date(this.pageYear, this.pageMonth, 1);
      if (this.pageMonth == 11) {
        this.maxDate = new Date(this.pageYear + 1, 0, 0);
      } else {
        this.maxDate = new Date(this.pageYear, this.pageMonth + 1, 0);
      }
    },
    bindCalendarMove() {
      const scrollBody = document.querySelector(
        `#${this.calendarId} .van-calendar__days`
      );
      if (scrollBody) {
        const bodyHei = Px2rem.getRem(this.animateRowHei * this.allWeek + "px");
        const rowHei = Px2rem.getRem(this.animateRowHei + "px");
        const myCalendarRef = ref(scrollBody);
        if (this.btnPoint) {
          //展开
          createAnimation()
            .addElement(myCalendarRef.value)
            .duration(this.durationAni)
            .fromTo("height", rowHei, bodyHei)
            .play();
        } else {
          //收起
          let showqiTimeout = setTimeout(()=>{
            createAnimation()
              .addElement(myCalendarRef.value)
              .duration(this.durationAni)
              .fromTo("height", bodyHei, rowHei)
              .onFinish((r: any) => {
                this.initCheckRow(scrollBody);
                clearTimeout(showqiTimeout);
              })
              .play();
          },1);
        }
      }
      this.btnPoint = !this.btnPoint;
    },
    //设置回调结果
    setResult(type: number, value?: any) {
      let result = {
        type,
      };
      //1:更多
      if (type === 1) {
        result = Object.assign(result, { value: "more" });
      }
      //2:上一月或下一月,0:选中日期
      if (type === 0 || type === 2) {
        result = Object.assign(result, value);
      }
      this.$emit("transfer", result);
    },
    //选择时间回调
    bindSelectValue(value: Date) {
      this.pointDate_ = value;
      this.initStatisticsWeek(value);
      let m = value.getMonth() + 1;
      let month = m <= 9 ? "0" + m : m;
      const day = value.getDate() <= 9 ? "0" + value.getDate() : value.getDate();
      const pointDay = value.getFullYear() + "-" + month + "-" + day;
      const firstDay = value.getFullYear() + "-" + month + "-01";
      const lastDate = new Date(value.getFullYear(), m, 0);
      const lastDay = lastDate.getFullYear() + "-" + month + "-" + lastDate.getDate();
      this.setResult(0, { firstDay, pointDay, lastDay });
    },
    //更多回调
    bindCalendarMore() {
      this.setResult(1);
    },
    //获取某月份内周数
    getWeekOfMonth(y: number, m: number): number {
      if (m == 11) {
        m = 0;
        y = y + 1;
      } else {
        m = m + 1;
      }
      return this.getWeekOfDay(y, m, 0);
    },
    //计算某天月份第几周
    getWeekOfDay(y: number, m: number, d: number): number {
      const dt = new Date(y, m, d);
      let dw = dt.getDay();
      dw = dw == 0 ? 7 : dw;
      const dm = dt.getDate();
      return dm <= dw ? 1 : Math.ceil((dm - dw) / 7) + 1;
    },
  },
});
