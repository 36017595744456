
import { modalController, IonPage } from "@ionic/vue";
import { ScheduleService } from "@/services/schedule/ScheduleService";
import { ScheduleRelationService } from "@/services/schedule/ScheduleRelationService";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { defineComponent } from "vue";
import moment from "moment";
import BaseService from "@/services/base/BaseService";
export default defineComponent({
  name: "TaskFileList",
  props: {
    taskId: String,
    projectId: String,
    isIndependentTask: Boolean,
    searchKey: String,
  },
  components: {
    HeaderModal,
    SearchBar,
    IonPage,
  },
  data() {
    const pointDate = new Date();
    return {
      ScheduleService: new ScheduleService(),
      linkService: new ScheduleRelationService(),
      list: new Array(0),
      searchParams: {
        limit: 10,
        offset: 1,
        repeat: "0",
        schduleDate: "",
        scheduleName: "",
      },
      idList: new Array<any>(), //选中的id集合
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    searchKey() {
      this.searchParams.offset = 1;
      this.searchParams.scheduleName = this.searchKey as string;
      this.initData();
    },
  },
  methods: {
    initData() {
      this.searchParams.offset = 1;
      this.requsetData(this.searchParams, null);
    },

    fromatTime(beginTime: any, endTime: any) {
      let str = "";
      let beginTimeT = moment(beginTime).format("YYYY-MM-DD HH:mm");
      let endTimeT = moment(endTime).format("YYYY-MM-DD HH:mm");
      if (beginTimeT.substring(0, 10) == endTimeT.substring(0, 10)) {
        str =
          beginTimeT.substring(0, 10) +
          " " +
          beginTimeT.substring(11, 16) +
          "~" +
          endTimeT.substring(11, 16);
      } else {
        str = beginTimeT + "~" + endTimeT;
      }
      return str;
    },
    //输入文字搜索
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchParams.scheduleName = searchParam.searchKey;
      this.initData();
    },
    //获取选择的Id集合
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        const newItem = {
          scheduleTimeId: item.scheduleTimeId,
          formId: this.taskId,
          type: 1,
          id: BaseService.newId("", ""),
        };
        this.idList.push(newItem);
      } else {
        this.idList = this.idList.filter((e) => {
          return e.targetId != item.id;
        });
      }
    },

    //获取选择的Id集合
    getSelectedItems() {
      return this.idList;
    },
    //下拉
    doRefresh(event: any) {
      this.searchParams.offset = 1;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页
    nextPage(event: any) {
      this.searchParams.offset++;
      this.requsetData(this.searchParams, event);
    },
    //请求数据
    requsetData(pamas: any, event: any) {
      this.ScheduleService.getMyScheduleList(this.searchParams).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              if (this.searchParams.offset == 1) {
                this.list = [];
              }
              event.target.disabled = true;
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
            console.log(this.list);
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //关闭modal
    // closeModel(type: number) {
    //   if (type == 4) {
    //     //完成选择，保存数据并关闭模态
    //     const items = this.idList;
    //     if (!items || items.length == 0) {
    //       Utils.presentToastTopWarning("请选择至少一项关联日程");
    //       return;
    //     }
    //     console.log(this.idList);

    //     this.linkService.taskLinkSchedule(this.idList).then((res: any) => {
    //       if (res) {
    //         // 提醒，添加成功
    //         Utils.presentToastSuccess("添加成功");
    //         //关闭页面
    //         modalController.dismiss({ refresh: true });
    //       } else {
    //         Utils.presentToastWarning("添加失败");
    //       }
    //     });
    //   } else {
    //     modalController.dismiss({});
    //   }
    // },
  },
});
