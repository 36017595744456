import { WarningApi } from '@/api/warning/WarningApi';

import BaseService from '../base/BaseService';

/**
 * 日程模块
 */
export class WarningService extends BaseService {
    private  api = new WarningApi();

    // 获取模块
    public getModules(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getModules().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 根据模块获取未读消息列表
    public getListByModules(params:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getListByModules(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 更新为已读状态
    public readstausChange(params:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.readstausChange(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 获取未读消息数量
    public getUnreadCount(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getUnreadCount().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
}