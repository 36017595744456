
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import { popoverController } from "@ionic/vue";
export default defineComponent({
  name: "PostPopover",
  props: {
    postId: String,
    list: Array,
  },
  setup(props) {
    const { postId, list } = toRefs(props);
    //reactive内只能传对象{}
    const postList = reactive({
      data: list?.value,
    });
    //职位选择事件
    const bindPostChange = (e: any) => {
      let postName = "";
      if (postList.data) {
        postList.data.forEach((item: any) => {
          if (e == item.id) {
            postName = item.name;
          }
        });
      }
      popoverController.dismiss({
        dismissed: true,
        id: e,
        name: postName,
      });
    };
    return { postList, postId, bindPostChange };
  },
});
