
//时间格式化
import moment from "moment";
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";
import { log } from "util";
import { Switch } from "vant";

export default defineComponent({
  name: "ScheduleCardObj",
  components: {},
  props: {
    ScheduleCardObj: Object,
    operabilityCard: Boolean,
  },
  watch: {
    time(newName, oldName) {
      this.timeBg();
    },
    ScheduleCardObj() {
      this.timeFormat();
      this.timeBg();
    },
  },

  data() {
    return {
      timeStatusClass: "", //lower 还早 general正常 urgency即将截止 very_urgency逾期 ngBg
      time: "",
      startYear: "",
      endYear: "",
      sourceText: "",
    };
  },
  emits: ["on-detail"],
  mounted() {
    this.timeFormat();
    this.timeBg();
    var width = document.body.scrollWidth;
    if (width >= 360) {
      //this.sourceText = "来源：";
      this.sourceText = " ";
    }
  },
  methods: {
    checkHandler(e: any, id: any) {
      // console.log(e.detail.checked);
    },
    subStringText(name: string) {
      if (!name) {
        return "";
      }
      if (name.length > 4) {
        return Utils.subStringText(name, 4);
      } else {
        return name;
      }
    },
    timeFormat() {
      if (this.ScheduleCardObj) {
        const isAllDay = this.ScheduleCardObj.isAllday;
        const startTime = moment(this.ScheduleCardObj.startTime).format(
          "YYYY-MM-DD HH:mm"
        );
        const endTime = moment(this.ScheduleCardObj.endTime).format(
          "YYYY-MM-DD HH:mm"
        );

        const startY = moment(this.ScheduleCardObj.startTime).format("YYYY");
        const endY = moment(this.ScheduleCardObj.endTime).format("YYYY");

        const startM = moment(this.ScheduleCardObj.startTime).format("MM");
        const endM = moment(this.ScheduleCardObj.endTime).format("MM");

        const startD = moment(this.ScheduleCardObj.startTime).format("DD");
        const endD = moment(this.ScheduleCardObj.endTime).format("DD");

        const startH = moment(this.ScheduleCardObj.startTime).format("HH:mm");
        const endH = moment(this.ScheduleCardObj.endTime).format("HH:mm");

        const nowY = new Date().getFullYear() + "";

        if (startY != nowY && startY != "Invalid date") {
          this.startYear = startY + "年";
        }
        if (endY != nowY && endY != "Invalid date") {
          this.endYear = endY + "年";
        }
        if (startTime == "Invalid date" && endTime != "Invalid date") {
          this.time = this.endYear + endM + "月" + endD + "日" + " 截止";
        } else if (endTime == "Invalid date" && startTime != "Invalid date") {
          this.time = this.startYear + startM + "月" + startD + "日" + " 开始";
        } else if (
          startTime == endTime &&
          startTime != "Invalid date" &&
          endTime != "Invalid date"
        ) {
          //同一天
          this.time =
            this.startYear +
            startM +
            "月" +
            startD +
            "日" +
            (isAllDay ? "" : startH == endH ? startH : startH + "-" + endH);
        } else if (
          startTime != endTime &&
          startTime != "Invalid date" &&
          endTime != "Invalid date"
        ) {
          //不为一天
          if (startY == endY && startM == endM && startD == endD) {
            this.time =
              this.startYear +
              startM +
              "月" +
              startD +
              "日" +
              (isAllDay ? "" : startH == endH ? startH : startH + "-" + endH);
          } else {
            this.time =
              this.startYear +
              startM +
              "月" +
              startD +
              "日" +
              (isAllDay ? "" : startH) +
              "-" +
              this.endYear +
              endM +
              "月" +
              endD +
              "日" +
              (isAllDay ? "" : endH);
          }
        }
        return this.time;
      }
    },
    timeBg() {
      if (this.ScheduleCardObj) {
        this.timeStatusClass = "noBg";
        if (this.ScheduleCardObj.isfinish) {
          this.timeStatusClass = "noBg";
        } else {
          if (this.ScheduleCardObj.startTime == "") {
            this.timeStatusClass = "noBg";
          } else {
            let startT = moment(this.ScheduleCardObj.startTime).format(
              "YYYY-MM-DD HH:mm"
            );
            let endT = moment(this.ScheduleCardObj.endTime).format(
              "YYYY-MM-DD HH:mm"
            );
            let endD = moment(this.ScheduleCardObj.endTime).format(
              "YYYY-MM-DD"
            );
            let nowD = moment(new Date()).format("YYYY-MM-DD");
            let nowT = moment(new Date()).format("YYYY-MM-DD HH:mm");
            startT = startT.replace(/-/g, "/");
            endT = endT.replace(/-/g, "/");
            nowT = nowT.replace(/-/g, "/");
            const startHM = new Date(startT).getTime();
            const endHM = new Date(endT).getTime();
            const nowHM = new Date(nowT).getTime();

            if (startHM > nowHM) {
              //未到开始时间
              if (startHM < nowHM + 1800000 && nowHM < endHM) {
                this.timeStatusClass = "urgency_bg";
              }
            } else if (startHM < nowHM) {
              //已过开始时间
              if (nowHM < endHM) {
                this.timeStatusClass = "urgency_bg";
              }
            }

            // console.log( this.timeStatusClass);
          }
        }
      }
    },
    getPriority(priority: any) {
      let returnValue = "";
      if(typeof priority === "number"){
        priority = priority.toString();
      }
      switch (priority) {
        case "0":
          returnValue = "低";
          break;
        case "1":
          returnValue = "中";
          break;
        case "2":
          returnValue = "高";
          break;
      }
      return returnValue;
    },
    clickHandler(item: any) {
      this.$emit("on-detail", {
        item: item,
      });
    },
  },
});
