
import {
  modalController,
  IonPage,
  // IonList,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "../../components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { getInstance1 } from "@/services/selectexecutor/selectExecutorTeamService";
import SelectTeamStaff from "@/components/selectexecutor/SelectTeamStaff.vue";
import { AnimationSelf } from "@/libs/animation";
import { GroupService } from "@/services/okr/GroupService";
import SelectGroupUser from "@/views/OKR/modals/SelectGroupUser.vue";

export default defineComponent({
  name: "SelectForTeamList",
  components: {
    IonPage,
    HeaderModal,
    // IonList,
    IonItem,
    SearchBar,
  },
  data() {
    const seleceExecutorTeamService = getInstance1();
    return {
      teamList: new Array(0),

      navLines: new Array(0),
      seleceExecutorTeamService: seleceExecutorTeamService,

      navCheck: 0,
      navFolders: [0],

      groupService: new GroupService(),

      allTeamList: new Array(0),

      //   noticeService: noticeService,
    };
  },
  props: {
    intentExecutorSelectList: Object,
    organize: Object,
    isCheckBox: Boolean,
  },
  created() {
    //获取团队
    this.loadTeamList(0, 0, 0);
  },
  methods: {
    filterNavs() {
      const navs = ["一级", "二级", "三级", "四级", "五级", "六级", "七级"];
      const res = navs.filter((value, index, arr) => {
        return index <= this.navCheck;
      });
      this.navLines = res;
    },

    bindFolderGrade(check: number) {
    //   this.loadTeamList(this.navFolders[check], check, 0);

    this.teamList = this.allTeamList[check];
    // if(check != 0){
        this.allTeamList.splice(check+1,this.allTeamList.length);
    // }
      //   this.teamList = r;
      if (check == -1) {
        this.navCheck += 1;
      } else {
        this.navCheck = check;
      }
      this.navFolders[this.navCheck] = this.navFolders[check];
      this.filterNavs();
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type == 3) {
        modalController.dismiss({
          isReturn: false,
        });
      } else if (type == 4) {
        modalController.dismiss();
      }
    },
    loadTeamList(pId: number, type: number, position: number) {
      this.groupService.getGroupList().then(
        (r: any) => {
          if (r) {
            // this.folderList = r.data;

            // if (r.length == 0) {
            //   this.intentteamstaff(position);
            // } else {
            //   this.teamList = r;
            //   if (type == -1) {
            //     this.navCheck += 1;
            //   } else {
            //     this.navCheck = type;
            //   }
            //   this.navFolders[this.navCheck] = pId;
            //   this.filterNavs();
            // }
            this.teamList = r;
            this.allTeamList.push(this.teamList);
            if (type == -1) {
              this.navCheck += 1;
            } else {
              this.navCheck = type;
            }
            this.navFolders[this.navCheck] = pId;
            this.filterNavs();
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    isIntent(fId: number, position: number) {
      //   this.loadTeamList(fId, -1, position);

      if (this.teamList[position].children.length != 0) {
        this.teamList = this.teamList[position].children;
        this.allTeamList.push(this.teamList);
        this.navCheck += 1;
        this.navFolders[this.navCheck] = fId;
        this.filterNavs();
      }else {
        
        this.intentteamstaff(position);

      }
    },
    //跳转至模态窗
    async intentteamstaff(posotion: number) {
      const result = AnimationSelf.prototype.modalRight;
      const modal = await modalController.create({
        component: SelectGroupUser,
        // component: LabeDetailPage,
        cssClass: "notice-modal-class",
        mode: "ios",
        enterAnimation: result.enterAnimation,
        leaveAnimation: result.leaveAnimation,
        backdropDismiss: true,
        componentProps: {
          intentExecutorSelectList: this.intentExecutorSelectList,
          groupId: this.teamList[posotion].id,
          isCheckBox: this.isCheckBox,
        },
      });

      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.isReturn) {
          console.log("我的团队" + data.executorSuperiorSelectList),
            modalController.dismiss({
              isReturn: true,
              executorSuperiorSelectList: data.executorSuperiorSelectList,
            });
        }
      }
    },
  },
});
