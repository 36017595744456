
import { defineComponent } from "vue";
import KeyResultItem from "@/components/ListCard/KeyResultItem.vue";

export default defineComponent({
  name: "TestPreview",
  components: {
    KeyResultItem,
  },
});
