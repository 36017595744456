
import { defineComponent, onUnmounted } from "vue";
import { modalController } from "@ionic/vue";
import KeyResultItem from "@/components/ListCard/KeyResultItem.vue";
import UpdateProgress from "@/views/OKR/progress/UpdateProgress.vue";
import Utils from "@/utils/Utils";
import { useRouter, useRoute } from "vue-router";
import { KeyResultService } from "@/services/okr/KeyResultService";
import KeyResult from "@/models/okr/KeyResult";
import SystemUtils from "@/utils/SystemUtils";
import Bus from "@/utils/bus";

export default defineComponent({
  name: "KeyResultList",
  components: {
    KeyResultItem,
  },
  props: {
    OStatus: Number,
  },
  emits: ["isIncludeCurrentUser"],
  data() {
    const router = useRouter();
    const service = new KeyResultService();
    return {
      router: router,
      service: service,
      mTarget: { id: "", name: "" },
      krList: new Array<KeyResult>(0),
      isTargetPrincipal: false,
      // page: 1,
    };
  },

  created() {
    this.getDataList(/* true */);
    Bus.$on("refresh_key_result_list", () => {
      this.getDataList(/* true */);
    });
  },

  setup() {
    onUnmounted(() => {
      Bus.$off("refresh_key_result_list");
    });
  },
  methods: {
    getDataList(/* reload: boolean, event?: any */) {
      if (this.mTarget && this.mTarget.id) {
        // this.page++;
        // if(reload){
        //   this.page = 1;
        // }
        this.service
          .getListByOid(this.mTarget.id /* , this.page */)
          .then(
            (r: any) => {
              /* if (reload)  */ this.krList = r;
              // else this.krList = this.krList.concat(r);
              this.isIncludeCurrentUser();
              // if (event) event.target.complete();
            },
            (e: any) => {
              Utils.presentToastWarning(e.message);
              // if (event) event.target.complete();
            }
          )
          .catch((err: any) => {
            Utils.presentToastWarning(err.message);
            // if (event) event.target.complete();
          });
        // setTimeout(() => {
        //   if (event) event.target.complete();
        // }, 2000);
      }
    },

    // 更新进度
    async updataProgress(kr: KeyResult) {
      const modal = await modalController.create({
        component: UpdateProgress,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          id: kr.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      console.log(JSON.stringify(data));

      if (data.isRefresh) {
        //刷新页面
        kr.progress = data.progress;
        kr.status = this.service.calStatus(kr);
      }
    },

    // 转为目标
    toTheTarget(kr: KeyResult) {
      if (!this.mTarget || !this.mTarget.name) {
        Utils.presentToastWarning("未获取到当前目标信息");
        return;
      }
      this.router.push({
        name: "addOKR",
        params: {
          sourceKrId: kr.id as string,
          sourceKrName: kr.name as string,
          userId: kr.userId as string,
          userName: kr.userName as string,
          object: JSON.stringify(this.mTarget),
        },
      });
    },

    //查看对应目标
    previewTarget(kr: KeyResult) {
      // this.$emit("previewTarget", kr.linkOid);
      this.router.push({
        path: "/OKR/details",
        query: { id: kr.linkOid as string },
      });
    },

    // item点击  进入详情
    toKeyResultInfo(kr: KeyResult) {
      this.router.push({
        path: "/OKR/KeyResultInfo",
        query: {
          id: kr.id,
        },
      });
    },

    // 判断关键结果列表中负责人是否包含当前登录用户
    isIncludeCurrentUser() {
      let flag = false;
      for (let kr of this.krList) {
        if (kr.userId == SystemUtils.loginUser.id) {
          flag = true;
        }
      }
      this.$emit("isIncludeCurrentUser", flag);
    },

    // 获取到目标数据后调用
    setThisTarget(target: any, isTargetPrincipal: boolean) {
      this.mTarget = target;
      this.isTargetPrincipal = isTargetPrincipal;
      this.getDataList(/* true */);
    },
  },
});
