import ProjectOrganizationsApi from "@/api/project/ProjectOrganizationsApi"
import BaseService from '../base/BaseService';
import ProjectOrganization from './projectOrganization/ProjectOrganization';

export default class ProjectOrganizationsService extends BaseService {
    private api = new ProjectOrganizationsApi();

    public getList(conditions: any): Promise <any> {
        return new Promise((resolve, reject) => {
            this.api.getList(conditions).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public delete(id: string): Promise <any> {
        return new Promise((resolve, reject) => {
            this.api.delete(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public add(data: any,id: string): Promise <any> {
        data.projectId=id;
        data.id=BaseService.newId("task","projectorganization");
        const newArray: ProjectOrganization[]=[];
        newArray.push(new ProjectOrganization(data));

        return new Promise((resolve, reject) => {
            this.api.add(newArray).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

}