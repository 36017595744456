import { ScheduleApi } from '@/api/schedule/ScheduleApi';
import { ScheduleTimeApi } from '@/api/schedule/ScheduleTimeApi';
import { rejects } from 'assert';
import { resolve } from 'path';

import BaseService from '../base/BaseService';
import Bus from "@/utils/bus";

/**
 * 日历模块
 */
export class ScheduleService extends BaseService {
    private static scheduleApi = new ScheduleApi();
    private static ScheduleTimeApi = new ScheduleTimeApi();

    // 获取我的日历列表:我是创建人或参与人
    public getMyScheduleList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getMyScheduleList(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //  获取他人日程列表：我订阅的他人可显示的日程
    public getOtherScheduleList({ offset = 1, limit = 10 }): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getOtherScheduleList({
                offset,
                limit,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //日程回收站列表
    public getRecycleSchedule({ offset = 1, limit = 10, name = "" }): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getRecycleSchedule({
                offset,
                limit,
                name
            }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //日程回收站还原
    public restoreSchedule({
        scheduleTimeId = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.restoreSchedule({
                scheduleTimeId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err)
            })
        })
    }
    //日程删除
    public clearById({scheduleTimeId = ""} = {}): any {
        return new Promise((resolve, reject) => {
            ScheduleService.ScheduleTimeApi.clearById({
                scheduleTimeId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err)
            })
        })
    }

    //insert 日程
    public insertSchedule(parms: any): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.insertSchedule(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //编辑 日程
    public updateSchedule(parms: any): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.updateSchedule(parms).then(async (res: any) => {
                Bus.$emit("data_update_listener", {type:"schedule", data: res.data});
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 根据日程id删除全部日程
    public deleteScheduleByScheduleId({ scheduleId = "" } = {}): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.deleteScheduleByScheduleId({ scheduleId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 删除当前及以后日程
    public deleteScheduleThisAndAfter({ scheduleId = "", scheduleTimeId = "" } = {}): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.deleteScheduleThisAndAfter({ scheduleId, scheduleTimeId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 根据当前日程明细id删除当前日程 
    public deleteScheduleTimeById(param: any): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.deleteScheduleTimeById(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    // 获取日程详情，含关联项目、任务、日历、参与人
    public getScheduleDetail({ scheduleId = "", scheduleTimeId = "" }): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getScheduleDetail({
                scheduleId,
                scheduleTimeId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新日程信息--完成，反馈
    public updateFieldsById(parms: any): any {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.updateFieldsById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 日程概览（首页使用）
     */
    public getScheduleOverView(): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getScheduleOverView().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

     /**
     * 日程每日待办数量：按月
     */
    public getCountByMonth(parms: any): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getCountByMonth(parms).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    
    /**
     * 是否有查看权限
     */
    public getLimitDetail(parms: any): Promise<any> {
        return new Promise((resolve, reject) => {
            ScheduleService.scheduleApi.getLimitDetail(parms).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}