
import { modalController, popoverController, menuController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Utils from "@/utils/Utils";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import TargetCards from "@/components/ListCard/TargetCards.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import moment from "moment";
import { KeyResultService } from "@/services/okr/KeyResultService";
export default defineComponent({
  name: "MyOKRList",
  components: {
    TargetCards,
    CardLoading,
  },
  watch: {
    $route(from, to) {
      if (to.path == "/OKR/details") this.resetPage();
    },
    searchKey(newVal) {
      if (newVal == undefined) {
        newVal = "";
      }
      this.searchParams.name = newVal;
      this.onInit();
    },
  },
  props: {
    searchKey: {
      type: String,
      require: false,
      default: "",
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    return {
      objectiveService: new ObjectiveService(),
      router: router,
      targetList: new Array<any>(),
      searchParams: {
        limit: 10,
        offset: 1,
        dutyType: "2",
        name: this.searchKey, //关键字
      },
      islist: true,
      showNoDate: false,
      targetObj: Object.create(null),
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: "okr",
      },
      isLastPage: false,
      dimensionalityArr: new Array<any>(),
      myResultsList: new Array<any>(),
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    //页面初始化
    onInit() {
      this.resetPage();
    },
    //重新从第一页获取
    resetPage() {
      this.isLastPage = false;
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.targetList = res;
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    //刷新
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页
    nextPage(event: any) {
      if (this.isLastPage) {
        event.target.complete();
        return;
      }
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        if (result) {
          this.targetList = this.targetList.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //请求数据
    getListRequest(callback: any) {
      this.objectiveService
        .objectiveList(this.searchParams)
        .then((res: any) => {
          this.islist = false;
          if (res.length < this.searchParams.limit) {
            this.isLastPage = true;
          }
          callback(res);
        })
        .catch(() => {
          this.islist = false;
        });
    },

    resetSearchParam() {
      this.searchParams.offset = 1;
    },
    openTargetInfo(id: any) {
      this.router.push({
        path: "/OKR/details",
        query: {
          id: id,
        },
      });
    },
  },
});
