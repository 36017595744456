
import { defineComponent } from "vue";
import { modalController, actionSheetController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { KeyResultService } from "@/services/okr/KeyResultService";
import KeyResult from "@/models/okr/KeyResult";
import Utils from "@/utils/Utils";
import BaseRsp from "@/models/base/BaseRsp";
import SelectTaskList from "../../../views/calendar/component/SelectTask.vue";
import { useRouter, useRoute } from "vue-router";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import { ObjectiveService } from "@/services/okr/ObjectiveService";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "AddKeyResult",
  components: {
    HeaderModal,
    TaskCards,
  },
  data() {
    const service = new KeyResultService();
    const objectiveService = new ObjectiveService();
    const kr = new KeyResult();
    const router = useRouter();
    return {
      router: router,
      kr: kr,
      service: service,
      objectiveService: objectiveService,
      customChecked: false,
      name: "",
      user: { userId: "", name: "", department: "" },
      startDate: "",
      endDate: "",
      priority: 3,
      measurements: "",
      remark: "",
      weight: 0,
      unit: "完成/未完成",
      unitStartValue: "未完成",
      unitEndValue: "完成",
      surplusWeight: 0,
      selectTaskList: Array(0),
      more: false,
      isUpdateKO: false,
      requesting: false,
    };
  },
  props: {
    id: String, // 新建传目标ID：oid   编辑传关键结果ID
    isEdit: Boolean,
    target: Object,
    isOPrincipal: Boolean,
  },
  created() {
    if (this.isEdit) {
      this.service
        .getDetailById(this.id as string)
        .then(
          (res: any) => {
            this.kr = res;
            this.setKRToView(this.kr);
            this.getSurplusWeight(this.kr.oid as string, this.kr.id);
            this.getTaskListData(this.kr.id as string);
          },
          (err: any) => {
            console.log(JSON.stringify(err));
          }
        )
        .catch((err: any) => {
          console.log(JSON.stringify(err));
        });
    } else {
      this.isUpdateKO = true;
      this.user.userId = SystemUtils.loginUser.id as string;
      this.user.name = SystemUtils.loginUser.name as string;
      this.user.department = SystemUtils.loginUser.department as string;
      this.getSurplusWeight(this.id as string, "");
      let target: any = this.target;
      if (
        target.cycleList &&
        target.cycleList.length > 0 &&
        target.cycleList[0].cycleStartDate
      )
        this.startDate = target.cycleList[0].cycleStartDate;
      if (
        target.cycleList &&
        target.cycleList.length > 0 &&
        target.cycleList[0].cycleEndDate
      )
        this.endDate = target.cycleList[0].cycleEndDate;
    }
    if (!this.target) {
      Utils.presentToastWarning("获取目标信息失败！");
      modalController.dismiss({});
    }
  },
  methods: {
    // 关闭modal
    closeModel(type: any) {
      if (type == 4) {
        // 点击完成
        if (this.surplusWeight < this.weight) {
          Utils.presentToastWarning(
            // "当前权重已超出,可分配最大权重" + this.surplusWeight + "%"
            "累计权重已超出100%"
          );
          return;
        }
        if (this.isEdit) this.updateKeyResult();
        else this.addKeyResult();
      } else {
        modalController.dismiss({});
      }
    },

    setKRToView(kr: KeyResult) {
      if (kr.name) this.name = kr.name;
      if (kr.userId) this.user.userId = kr.userId;
      if (kr.userName) this.user.name = kr.userName;
      if (kr.startDate) this.startDate = kr.startDate;
      if (kr.endDate) this.endDate = kr.endDate;
      if (kr.priority) this.priority = kr.priority;
      if (kr.measurements) this.measurements = kr.measurements;
      if (kr.weight) this.weight = kr.weight;
      if (kr.remark) this.remark = kr.remark;
      if (kr.unit) this.unit = kr.unit;
      if (kr.unitStartValue) this.unitStartValue = kr.unitStartValue;
      if (kr.unitEndValue) this.unitEndValue = kr.unitEndValue;
      this.isUpdateKO = kr.progressLogs.length <= 0;
      this.customChecked = kr.isCustomUnit != 0;
    },

    checkChange(event: any) {
      this.customChecked = event.detail.checked;
      if (this.customChecked) {
        this.unit = "";
        this.unitStartValue = "";
        this.unitEndValue = "";
      } else {
        this.unit = "完成/未完成";
        this.unitStartValue = "未完成";
        this.unitEndValue = "完成";
      }
    },

    startNumberInputChange(event: any) {
      const value: string = event.detail.value;
      if (value.length > 12) {
        setTimeout(() => {
          this.unitStartValue = value.substr(0, 12);
        }, 50);
      }
    },

    endNumberInputChange(event: any) {
      const value: string = event.detail.value;
      if (value.length > 12) {
        setTimeout(() => {
          this.unitEndValue = value.substr(0, 12);
        }, 50);
      }
    },

    getSurplusWeight(oid: string, krId?: string) {
      this.service
        .getSurplusWeight(oid, krId)
        .then(
          (res: any) => {
            this.surplusWeight = res;
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
          }
        )
        .catch((err: BaseRsp) => {
          Utils.presentToastWarning(err.message);
        });
    },

    // 获取任务列表
    getTaskListData(krId: string) {
      this.objectiveService
        .objectivelinkTaskList({
          limit: 1000,
          offset: 1,
          sourceId: krId,
          sourceType: 2,
        })
        .then((res: any) => {
          this.selectTaskList = res;
        })
        .catch((err: any) => {
          Utils.presentToastWarning(err.message);
        });
    },

    updateKeyResult() {
      this.requesting = true;
      this.service
        .updateKeyResultExpose(
          this.target,
          this.kr,
          this.name,
          this.user,
          this.startDate,
          this.endDate,
          this.priority,
          this.measurements,
          this.remark,
          this.weight as number,
          this.customChecked,
          this.unit,
          this.unitStartValue,
          this.unitEndValue,
          this.selectTaskList
        )
        .then(
          (isSuccess: any) => {
            if (isSuccess) {
              Utils.presentToastSuccess("修改完成");
              modalController.dismiss({ isRefresh: true });
            } else {
              Utils.presentToastWarning("修改失败");
            }
            this.requesting = false;
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
            this.requesting = false;
          }
        )
        .catch((err: BaseRsp) => {
          Utils.presentToastWarning(err.message);
          this.requesting = false;
        });
    },

    addKeyResult() {
      this.requesting = true;
      this.service
        .addKeyResultExpose(
          this.target,
          this.id as string,
          this.name,
          this.user,
          this.startDate,
          this.endDate,
          this.priority,
          this.measurements,
          this.remark,
          this.weight as number,
          this.customChecked,
          this.unit,
          this.unitStartValue,
          this.unitEndValue,
          this.selectTaskList
        )
        .then(
          (isSuccess: any) => {
            if (isSuccess) {
              Utils.presentToastSuccess("添加成功");
              modalController.dismiss({ isRefresh: true });
            } else {
              Utils.presentToastWarning("添加失败");
            }
            this.requesting = false;
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
            this.requesting = false;
          }
        )
        .catch((err: BaseRsp) => {
          Utils.presentToastWarning(err.message);
          this.requesting = false;
        });
    },

    // 选择负责人
    async selectPeople() {
      if (!this.isOPrincipal || this.kr.linkOid) {
        return;
      }
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: this.user.userId ? [this.user] : null,
            isCkeckbox: false,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        console.log(JSON.stringify(data.data));

        if (data.data.length > 0) {
          this.user.userId = data.data[0].userId as string;
          this.user.name = data.data[0].name;
          this.user.department = data.data[0].department;
        } else {
          this.user.userId = "";
          this.user.name = "";
          this.user.department = "";
        }
      }
    },

    // 重要性
    bindSignificance(event: any) {
      if (event.detail.value == this.priority) {
        return;
      } else {
        this.priority = event.detail.value;
      }
    },

    // 添加关联任务
    async associatedTasks() {
      const modal = await modalController.create({
        component: SelectTaskList,
        cssClass: "notice-modal-class",
        componentProps: {
          paramList: { list: this.selectTaskList },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedItem) {
        this.selectTaskList = data.selectedItem;
      }
    },

    openTask(item: any) {
      this.router.push({
        path: "/task-page",
        query: {
          id: item.id,
          taskName: item.name,
        },
      });
    },

    async cancelRelevancy(item: any) {
      let index = this.selectTaskList.indexOf(item);
      if (index >= 0) {
        this.selectTaskList.splice(index, 1);
      }
    },

    weightFocus(event: any) {
      let value: string = event.detail.value;
      if (!value) value = "0";
      let index = value.indexOf(".");
      if (index > -1) {
        Utils.presentToastWarning("权重请勿输入小数，小数点后无效");
        value = value.substr(0, index + 1);
      }
      this.weight = Number.parseInt(value);
      if(this.weight < 0){
        Utils.presentToastWarning("权重不能小于0");
      }
    },

    ismore() {
      this.more = !this.more;
    },
  },
});
