
import { defineComponent } from "vue";
import { modalController, IonPage } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";

import HeaderModal from "@/components/modal/components/HeaderModal.vue";

export default defineComponent({
  name: "SelectDate",
  props: {
    selectList: Object,
  },
  components: {
    IonPage,
    HeaderModal,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      useRoute: useRoute(),
      selectedItem: {},
      selectedId: "",
      list: new Array(),
      title: "",
      isRollback:true,
    };
  },
  mounted() {
    this.initList();
  },
  methods: {
    initList() {
      let listTemp = new Array();
      if (this.selectList && this.selectList.length > 0) {
        this.selectList.forEach((ele: any) => {
          listTemp.push(ele);
        });
      }
      this.list = [];

      for (let i = 1; i <= 31; i++) {
        let newItem = {
          id: i,
          text: i,
          checked: false,
        };
        let l=listTemp.filter((ele:any) => {
          return ele==i;
        });
        if(l.length>0){
          newItem.checked=true;
          this.list.push(newItem);
        }
        else{
          this.list.push(newItem);
        }
      }
      this.title = "选择";
    },
    selectDateHandler(e: any, item: any) {
      item.checked = item.checked ? false : true;
    },
    closeModel(type: any) {
      if (type == 4) {
        let selectItems = [];
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].checked) {
            selectItems.push(this.list[i].id);
          }
        }
        //关闭页面
        modalController.dismiss({
          data: selectItems,
        });
      }
      modalController.dismiss({});
    },
  },
});
