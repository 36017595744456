interface ProjectOrgProperties {
    description?: string
}

  
class ProjectOrganization {

    private id: string;
  
    public organizationId: string;

    public organizationName: string;

    public organizationNamecn: string;

    public organizationNameen: string;

    public projectId: string;

    private systemId="task";
  
    constructor (item: any) {
      this.organizationId = item.orgId;
      this.organizationName=item.shortName;
      this.organizationNamecn=item.description;
      this.organizationNameen=item.shortName;
      this.projectId=item.projectId;
      this.id=item.id;
    }
  
  }
  
  export {
    ProjectOrganization,
    ProjectOrganization as default
  }
  