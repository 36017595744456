
import { defineComponent, toRefs, ref, reactive } from "vue";
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { FeedbackService } from "@/services/feedback/FeedbackService";
import { FileHelper } from "@/utils/FileHelper";
import { SingleFile } from "@/models/file/Storage";
import Utils from "@/utils/Utils";
import { nextTick } from "process";
import BaseModel from "@/services/base/model/BaseModel";
export default defineComponent({
  name: "UpdateScheduleModal",
  components: {
    HeaderModal,
  },
  props: {
    userId: String,
    folderId: String,
  },
  setup(props) {
    //表单对象
    const feedbackObj = reactive({
      userId: "",
      folderId: "",
      name: "",
      type: 1,
      replyContent:"",
      remark:"",
      id: "",
    });
    const progressFiles = reactive({
      data: new Array<any>(),
      progress: "0",
    });
    const { userId, folderId } = toRefs(props);
    let showRequiredStar = ref(true);
    // let type = ref(1);

    if (userId != undefined && userId.value != undefined) {
      feedbackObj.userId = userId.value;
    }

    const feedbackService = new FeedbackService();

    let isSaved = false;
    //保存提交
    const saveFeedback = () => {
      if (isSaved) return;
      feedbackObj.id = new BaseModel().generateIdNoSys();
      feedbackService.addFeedback(feedbackObj).then((r: any) => {
        console.log(r);
        if (r) {
          Utils.presentToastSuccess("保存成功");
          modalController.dismiss({ isrefresh: true,feedbackObj:feedbackObj });
        }
      });
    };

    //关闭、保存回调
    const closeModel = (r: any) => {
      if (r == 2) {
        nextTick(() => {
          if (feedbackObj.name.trim() == "") {
            Utils.presentToastWarning("请填写任务具体执行情况说明");
          } else {
            saveFeedback();
          }
        });
      } else {
        modalController.dismiss();
      }
    };

    //多行文本内容绑定
    const bindChangeValue = (v: any) => {
      let val: string = v.detail.value;
      if (val.length > 200) {
        feedbackObj.name = val.slice(200);
      } else {
        feedbackObj.name = val;
      }
    };

    const fileHelper = new FileHelper();

    const bindUploadPicture = async () => {
      if (feedbackObj.id == "") {
        feedbackObj.id = new BaseModel().generateIdNoSys();
      }
      if (feedbackObj.folderId != null && feedbackObj.folderId != "") {
        openFileSheet();
      } else {
        if (feedbackObj.id) {
          createSubFolder(feedbackObj.id);
        }
      }
    };

    let storage: SingleFile = {
      folderId: "",
      systemId: "",
    };

    const createSubFolder = (busId: string) => {
      console.log(folderId);

      if (
        folderId != undefined &&
        folderId.value != undefined &&
        folderId.value != ""
      ) {
        const params = {
          parentId: folderId.value,
          name: "意见反馈",
          busId: busId,
          busTable: "okr_feedback",
          busTag: "",
        };
        fileHelper.createSubFolder(params).then(
          (r: string) => {
            if (r) {
              feedbackObj.folderId = r;
              storage.folderId = r;
              storage.busId = params.busId;
              storage.busTable = params.busTable;
              storage.busTag = params.busTag;
              storage.extName = "";
              openFileSheet();
            } else {
              Utils.presentToastWarning("创建文件夹失败");
            }
          },
          (e: any) => {
            Utils.presentToastWarning("创建反馈目录失败:" + e);
          }
        );
      } else {
        Utils.presentToastWarning("文件夹标识为空");
      }
    };

    const openFileSheet = async () => {
      if (feedbackObj.folderId != null && feedbackObj.folderId != "") {
        fileHelper.uploadWay = "common";
        fileHelper.uploadProgress = true;
        //打开选择框
        fileHelper.openFileSheet(
          feedbackObj.folderId,
          (fId: string, data: any) => {
            commitUploadData(fId, data);
          },
          (error: string) => {
            Utils.presentToastWarning(error);
          }
        );
      }
    };

    const downloadFileThumb = (index: number) => {
      let obj = progressFiles.data[index];
      if (obj) {
        let extName = obj.name.substring(obj.name.lastIndexOf(".") + 1);
        fileHelper.downloadThumb(obj.id, extName, 1).then(
          (r: string) => {
            progressFiles.data[index].url = r;
          },
          (e: any) => {}
        );
      }
    };

    //执行上传
    const commitUploadData = (fId: string, data: any) => {
      if (fId != null && data != null) {
        const option = {
          id: "",
          url: "",
          status: "uploading",
          message: "上传中...",
          isGif: false,
          name: "",
          documentId: "",
          isImage: true, //非图片扩展名下强制使用图片标签渲染
        };
        progressFiles.data.push(option);
        const i = progressFiles.data.length - 1;
        fileHelper.getStorageFolder(
          storage,
          data,
          (result: any) => {
            if (result) {
              progressFiles.data[i].id = result.id;
              progressFiles.data[i].documentId = result.documentId;
              progressFiles.data[i].url = "";
              progressFiles.data[i].isGif = result.previewUrl == "";
              progressFiles.data[i].name = result.name;
              progressFiles.data[i].status = "done";
              progressFiles.data[i].message = "上传完成...";
              downloadFileThumb(i);
            }
          },
          (e: string) => {
            progressFiles.data[i].status = "failed";
            progressFiles.data[i].message = "上传失败...";
            setTimeoutClose(i);
          }
        );
      }
    };

    //清除上传失败动画
    const setTimeoutClose = (i: number) => {
      setTimeout(() => {
        progressFiles.data.splice(i, 1);
      }, 2000);
    };

    let isPreview: boolean = true;
    let page: number = 0;
    let currentId: string = "";

    //原图预览
    const bindPreviewImage = (ev: any) => {
      if (ev && isPreview) {
        isPreview = false;
        page = 0;
        currentId = ev.id;
        let urls = new Array<string>();
        downloadFile(0, urls);
        setTimeout(() => {
          isPreview = true;
        }, 5000);
      }
    };

    const downloadFile = (index: number, urls: Array<string>) => {
      const id = progressFiles.data[index].id;
      if (currentId == id) {
        page = index;
      }
      let name = progressFiles.data[index].name;
      let extName = name.substring(name.lastIndexOf(".") + 1);
      fileHelper.downloadOriginal(id, extName, 1).then(
        (r: string) => {
          urls.push(r);
          if (index + 1 == progressFiles.data.length) {
            isPreview = true;
            fileHelper.previewVant(urls, page);
          } else {
            downloadFile(index + 1, urls);
          }
        },
        (e: any) => {
          downloadFile(index + 1, urls);
        }
      );
    };

    //删除文件
    const bindDeletePicture = (ev: any) => {
      if (ev && "id" in ev) {
        fileHelper.clearFile(ev.documentId).then(
          (r: any) => {
            removeFile(ev.id); //移除集合内数据
            Utils.presentToastSuccess("删除成功");
          },
          (e: any) => {
            Utils.presentToastWarning("删除失败");
          }
        );
      }
    };

    const removeFile = (id: string) => {
      for (let index = 0; index < progressFiles.data.length; index++) {
        if (id === progressFiles.data[index].id) {
          progressFiles.data.splice(index, 1);
        }
      }
    };

    return {
      feedbackObj,
      closeModel,
      saveFeedback,
      bindChangeValue,
      bindUploadPicture,
      progressFiles,
      bindPreviewImage,
      bindDeletePicture,
      showRequiredStar,
    };
  },
});
