
import { defineComponent, onMounted, toRefs, ref } from "vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { IonLabel, IonInput, IonItem, modalController } from "@ionic/vue";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "EditStage",
  components: { HeaderModal, IonLabel, IonInput, IonItem },
  props: {
    title: String,
    stageName: String,
  },

  setup(props) {
    const { stageName } = toRefs(props);
    const name = ref(stageName ? stageName.value : "");
    function bindConfirmStatus(type: any) {
      if (type == 2) {
        if (!name.value || name.value.trim().length == 0) {
          Utils.presentToastWarning("请填写阶段名称");
          return;
        } else {
          if (name.value.length > 15) {
            Utils.presentToastWarning("阶段名称不能超过15个字符");
            return;
          }
        }
        modalController.dismiss({
          dismissed: true,
          name: name.value,
        });
      } else {
        modalController.dismiss({
          dismissed: true,
        });
      }
    }

    return { name, bindConfirmStatus };
  },
});
