
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import {
  IonItem,
  IonItemSliding,
  IonLabel,
  IonList,
  alertController,
  modalController,
} from "@ionic/vue";
import StatusModal from "./StatusModal.vue";
import { ProjectStatusService } from "@/services/project/ProjectStatusService";
import ProjectStatus from "@/models/project/ProjectStatus";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "StatusSetting",
  components: {
    IonItem,
    IonItemSliding,
    IonLabel,
    IonList,
  },
  props: {
    projectId: String,
    isHasPermission: Boolean,
  },
  setup(props) {
    const { projectId } = toRefs(props);
    const projectStatusService = new ProjectStatusService();
    let status = reactive({
      title: "新增状态",
      name: "",
      id: "",
      isEdit: false,
    });
    let isShowDialog = ref(false);

    async function presentAlertConfirm(i: number, id: string) {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "确定",
        message: "确定要删除此状态？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "light",
          },
          {
            text: "确定",
            handler: () => {
              projectStatusService
                .deleteProjectStatusById({
                  projectId: projectId?.value,
                  id: id,
                })
                .then(
                  (r: any) => {
                    console.log(r.data);
                    if (r.data) {
                      statusList.list.splice(i, 1);
                    } else {
                      Utils.presentToastTopWarning(
                        "项目状态在应用，不能被删除"
                      );
                    }
                  },
                  (e: any) => {
                    Utils.presentToastTopWarning(e.message);
                  }
                );
            },
          },
        ],
      });
      return alert.present();
    }

    async function saveStatus() {
        if (Utils.trim(status.name)) {
          if (status.isEdit) {
            editProjectStatus({
              id: status.id,
              nameCn: status.name,
              setFields: ["nameCn"],
            });
          } else {
            const ps = new ProjectStatus({
              nameCn: status.name,
              projectId: projectId?.value,
            });
            addProjectStatus(ps);
          }
          isShowDialog.value = false;
        } else {
          Utils.presentToastWarning("请输入状态名称");
        }
    }

    function openStatusModal(obj: any) {
      if (obj) {
        status.title = "编辑状态";
        status.name = obj.nameCn;
        status.id = obj.id;
        status.isEdit = true;
      } else {
        status.title = "新增状态";
        status.name = "";
        status.id = "";
        status.isEdit = false;
      }
    }

    //删除状态
    function bindStatusDelete(i: number, id: string) {
      presentAlertConfirm(i, id);
    }
 
    //新增/编辑状态
    async function bindStatusAdd(obj: any) {
      openStatusModal(obj);      
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: status.title,
        mode: "ios",
        inputs: [
          {
            placeholder: "状态名称(不超过10个字)",
            attributes: {
              maxlength: 10,
              value:status.name,
            },
          },
        ],
        buttons: [
          {
            text: "取消",
            role: "cancel",
          },
          {
            text: "确定",
            handler: (e:any) => { 
              status.name=e[0]
              saveStatus()
             
            },
          },
        ],
      });
      return alert.present();
    }

    let statusList = reactive({ list: new Array() });
    const getProjectStatus = () => {
      if (projectId != undefined && projectId.value != undefined) {
        projectStatusService.getByProjectStatusId(projectId.value).then(
          (r: any) => {
            if (r) {
              statusList.list = r.data;
            }
          },
          (e: any) => {}
        );
      }
    };

    //新增状态
    const addProjectStatus = (params: any) => {
      projectStatusService.addProjectStatus(params).then(
        (r: any) => {
          if (r) {
            statusList.list.push(r);
          }
        },
        (e: any) => {
          Utils.presentToastWarning("新增项目状态失败");
        }
      );
    };

    //编辑状态
    const editProjectStatus = (params: any) => {
      projectStatusService.updateFieldsById(params).then(
        (r: any) => {
          if (r) {
            for (let s of statusList.list) {
              if (s.id == params.id) {
                s.nameCn = params.nameCn;
              }
            }
          }
        },
        (e: any) => {
          Utils.presentToastWarning("修改项目状态失败");
        }
      );
    };
    
    onBeforeMount(() => {
      getProjectStatus();
    });

    return {
      isShowDialog,
      status,
      statusList,
      bindStatusDelete,
      bindStatusAdd,
      saveStatus, 
    };
  },
});
