import CoreService from './CoreService';

export default class AccountBaseService extends CoreService {
    constructor(resource: string, version?: string,baseURL?:string) {
        super({
            apiBaseURL:"attapi",
            name: "",
            resource: resource,
            version
        });
    }
}