
  //时间格式化
  import { defineComponent } from "vue";
  import AvatarList from "@/components/AvatarList.vue";
  import Utils from "@/utils/Utils";

  export default defineComponent({
    name: "TargetCards",
    components: {
      AvatarList,
    },
    props: {
      targetObj: {
        type: Object,
        required: true,
        default: {},
      },
    },
    //   emits: ["on-click"],
    watch: {
      targetObj(newName, oldName) {},
    },
    data() {
      return {};
    },
    mounted() {},
    methods: {
      avatarData(getData: any) {
        if (getData) {
          var users = [];
          getData.map((item: any, index: any) => {
            let obj = {
              name: item.userName,
              img: ""
            };
            users.push(obj);
          });
        }
        return users;
      },

      // subStringText(name: string) {
      //   if (!name) {
      //     return "";
      //   }
      //   if(name.length>4){
      //     return Utils.subStringText(name, 4);
      //   }else{
      //     return name
      //   }
      // },

      // bindDetail() {
      //   this.$emit("on-click", this.TaskCardObj);
      // },
    },
  });
