
import { defineComponent } from "vue";
import { eyeOutline, cloudDownloadOutline, cloudOfflineOutline } from "ionicons/icons";
import { FileHelper } from "@/utils/FileHelper";
import Utils from "../../utils/Utils";

export default defineComponent({
  name: "FileCard",
  components: {},
  emits: ["on-click", "on-check", "on-preview", "on-download"],
  props: {
    FileObj: Object,
    allowCheckDownload: {
      type: Boolean,
      required: false,
      default: true,
    }, //下载是否验证下载权限
    allowCheck: Boolean, //允许选择
    isFile: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHasFileDownload: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDownloadOver: {
      type: Boolean,
      required: false,
      default: false,
    },
    index: Number,
    downloadFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const fileHelper = new FileHelper();
    return {
      canCheck: false,
      eyeOutline,
      cloudDownloadOutline,
      cloudOfflineOutline,
      downloading: false,
      downloaded: false,
      fileHelper: fileHelper,
    };
  },
  watch: {
    //下载中
    isDownloadOver(newVal, oldVal) {
      if (newVal) {
        this.downloading = !newVal;
      }
    },
    //是否已下载
    downloadFlag(newVal, oldVal) {
      if (newVal) {
        this.downloaded = newVal;
      }
    },
  },
  created() {
    if (this.FileObj != null) {
      this.FileObj["isPic"] = false;
    }
  },
  mounted() {
    if (this.allowCheck) {
      this.canCheck = true;
    }
    if (
      this.FileObj != null &&
      this.FileObj.hasOwnProperty("extName") &&
      this.FileObj.hasOwnProperty("name")
    ) {
      this.FileObj["isPic"] = this.checkExt(4, this.FileObj.extName, this.FileObj.name);
      if (!this.FileObj["isPic"]) {
        this.fileHelper.checkExistsFile(this.FileObj.mainFileId, this.FileObj.name).then(
          (r: any) => {
            if (r) {
              this.downloaded = true;
            }
          },
          (e: any) => {
            if (!e) {
              this.downloaded = false;
            }
          }
        );
      } else {
        this.downloaded = true;
      }
    }
  },
  methods: {
    itemClick() {
      this.$emit("on-click");
    },
    checkChangeHandler(e: any, id: string, name: string) {
      this.$emit("on-check", {
        checked: e.target.checked,
        id: id,
        name: name,
      });
    },
    checkExtFromName(type: number, name: string): boolean {
      if (name != "") {
        const ext = name.substr(name.lastIndexOf(".") + 1);
        return this.checkExt(type, ext, "");
      }
      return false;
    },
    checkExt(type: number, ext: string, name: string): boolean {
      let flag: boolean = false;
      if (type == 0) {
        if (ext == "pdf") {
          return true;
        }
        flag = true;
      }
      if (type == 1) {
        if (ext == "doc" || ext == "docx") {
          return true;
        }
        flag = true;
      }
      if (type == 2) {
        if (ext == "xls" || ext == "xlsx") {
          return true;
        }
        flag = true;
      }
      if (type == 3) {
        if (ext == "ppt" || ext == "pptx") {
          return true;
        }
        flag = true;
      }
      if (type == 4) {
        if (ext == "jpg" || ext == "jpeg") {
          return true;
        }
        flag = true;
      }
      if (flag) {
        return this.checkExtFromName(type, name);
      }
      if (type == 5) {
        if (ext == "txt" || ext == "png" || ext == "gif" || ext == "bmp") {
          return true;
        }
        flag = true;
      }

      return false;
    },
    //预览
    bindPreviewClick(previewImage: string, fileId: string, name: string, isPic: boolean) {
      this.$emit("on-preview", {
        previewImage: previewImage,
        fileId: fileId,
        name: name,
        isPic: isPic,
      });
    },
    //下载
    bindDownloadClick(FileObj: any) {
      if (this.allowCheckDownload) {
        this.fileHelper.checkFilePermission(FileObj).then(
          (r: any) => {
            if (r && r.isDownload) {
              this.downloading = true;
              this.$emit("on-download", {
                fileId: FileObj.mainFileId,
                name: FileObj.name,
                index: this.index,
                previewImage: FileObj.previewImage,
                isPic: FileObj.isPic,
              });
            } else {
              Utils.presentToastWarning("无下载权限");
            }
          },
          (e: string) => {
            Utils.presentToastWarning(e);
          }
        );
      } else {
        //不需要验证下载权限
        this.downloading = true;
        this.$emit("on-download", {
          fileId: FileObj.mainFileId,
          name: FileObj.name,
          index: this.index,
        });
      }
    },
  },
});
