import { ProjectTeamRolesApi } from '@/api/project/ProjectTeamRolesApi';
import BaseService from '../base/BaseService';
/**
 * 项目列表模块
 */
export class ProjectTeamRolesService extends BaseService {
    private api = new ProjectTeamRolesApi();
    //查询项目团队角色是否存在
    public getProjectExistsTeamRole({ projectTeamRoleId = "",
        projectTeamRoleName = "",
        projectId = "", } = {}): any {
        return new Promise((resolve, reject) => {
            this.api.getProjectExistsTeamRole({ projectTeamRoleId, projectTeamRoleName, projectId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //添加团队角色
    public addProjectTeamRole(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.addProjectTeamRole(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //编辑团队角色
    public editProjectTeamRole(params: any): any {
        return new Promise((resolve, reject) => {
            this.api.editProjectTeamRole(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //删除团队角色
    public deleteProjectTeamRole({ teamRoleId = "",
        projectId = "", } = {}): any {
        return new Promise((resolve, reject) => {
            this.api.deleteProjectTeamRole({
                teamRoleId,
                projectId,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}
