
//时间格式化
import moment from "moment";
import { defineComponent } from "vue";
import { TaskService } from "@/services/task/TaskService";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "TaskCards",
  components: {
    AvatarList,
  },
  props: {
    TaskCardObj: {
      type: Object,
      required: true,
      default: {},
    },
    operabilityCard: Boolean,
    projectName: Boolean,
    isOKR: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-click", "on-cancelRelevancy", "on-addworkhour"],
  watch: {
    TaskCardObj(newName, oldName) {
      this.timeBg();
      this.timeFormat();
    },
  },
  data() {
    return {
      taskapi: new TaskService(),
      timeStatusClass: "noBg", //lower 还早 general正常 urgency即将截止 very_urgency逾期 ngBg
      time: "",
      startYear: "",
      endYear: "",
      users: new Array<any>(),
      sourceText: "",
    };
  },
  mounted() {
    this.timeBg();
    var width = document.body.scrollWidth;
    if (width >= 360) {
      //this.sourceText = "来源：";
      this.sourceText = "";
    }
  },
  methods: {
    checkHandler(e: any, id: any) {
      // console.log(e.detail.checked);
      this.taskapi.changeFinishStatus(id, e.detail.checked).then((res) => {
        if (res) {
          if (this.TaskCardObj) {
            this.TaskCardObj.isFinished = e.detail.checked;
          }
          this.timeBg();
        } else {
          Utils.presentToast("操作失败");
        }
      });
    },
    subStringText(name: string) {
      if (!name) {
        return "";
      }
      if (name.length > 4) {
        return Utils.subStringText(name, 4);
      } else {
        return name;
      }
    },
    timeFormat() {
      if (this.TaskCardObj) {
        const startTime = moment(this.TaskCardObj.startTime).format(
          "YYYY-MM-DD"
        );
        const endTime = moment(this.TaskCardObj.endTime).format("YYYY-MM-DD");

        const startY = moment(this.TaskCardObj.startTime).format("YYYY");
        const endY = moment(this.TaskCardObj.endTime).format("YYYY");

        const startM = moment(this.TaskCardObj.startTime).format("MM");
        const endM = moment(this.TaskCardObj.endTime).format("MM");

        const startD = moment(this.TaskCardObj.startTime).format("DD");
        const endD = moment(this.TaskCardObj.endTime).format("DD");

        // const startH = moment(this.TaskCardObj.startTime).format("HH:mm");
        // const endH = moment(this.TaskCardObj.endTime).format("HH:mm");

        const nowY = new Date().getFullYear() + "";
        //当前年不显示年份
        if (startY != nowY && startY != "Invalid date") {
          this.startYear = startY + "-";
        }
        if (endY != nowY && endY != "Invalid date") {
          this.endYear = endY + "-";
        }
        if (startY == "Invalid date" && endY == "Invalid date") {
          this.time = "未设置开始时间";
        }

        if (startTime == "Invalid date" && endTime != "Invalid date") {
          //只有结束时间--显示结束时间
          this.time = this.endYear + endM + "-" + endD + "" + " 截止";
        } else if (endTime == "Invalid date" && startTime != "Invalid date") {
          //只有开始时间--显示开始时间
          this.time = this.startYear + startM + "-" + startD + "" + " 开始";
        } else if (
          startTime == endTime &&
          startTime != "Invalid date" &&
          endTime != "Invalid date"
        ) {
          //同一天--显示当天开始时间-结束时分
          this.time = this.startYear + startM + "-" + startD + "";
          // this.time =this.startYear +startM +"月" +startD +"日" +startH + "-" + endH;
        } else if (
          startTime != endTime &&
          startTime != "Invalid date" &&
          endTime != "Invalid date"
        ) {
          if (startY == endY) {
            //不同天同一年--显示开始日月时分-结束日月时分
            this.time =
              this.startYear +
              startM +
              "-" +
              startD +
              "" +
              "至" +
              endM +
              "-" +
              endD +
              "";
          } else {
            //不同天不同一年--显示开始开始日月时分-结束日月时分
            this.startYear = startY + "-";
            this.endYear = endY + "-";
            this.time =
              this.startYear +
              startM +
              "-" +
              startD +
              "" +
              "至" +
              this.endYear +
              endM +
              "-" +
              endD +
              "";
          }
        }
        return this.time;
      }
    },

    timeBg() {
      if (this.TaskCardObj) {
        if (
          this.TaskCardObj.isFinished ||
          (this.TaskCardObj.endTime == "" && this.TaskCardObj.startTime == "")
        ) {
          this.timeStatusClass = "noBg";
        } else {
          if (this.TaskCardObj.endTime == "") {
            this.timeStatusClass = "lower_bg ";
          } else {
            let endStr = moment(this.TaskCardObj.endTime).format(
              "YYYY-MM-DD HH:mm"
            );
            endStr = endStr.replace(/-/g, "/");
            const d = new Date(endStr).getTime() - new Date().getTime();
            const difference = d / (24 * 3600 * 1000);
            // console.log("difference*********" + difference);
            if (difference > 7) {
              this.timeStatusClass = "lower_bg "; //大于7天
            } else if (difference <= 0) {
              this.timeStatusClass = "very_urgency_bg "; //小于7天
            } else if (difference <= 7 && difference > 1) {
              this.timeStatusClass = "general_bg "; //7天内
            } else if (difference > 0 && difference <= 1) {
              this.timeStatusClass = "urgency_bg "; //当天
            }
          }
        }
        //this.TaskCardObj.timeStatusClass = this.timeStatusClass;
      }
    },
    timeOver() {
      let overTask = false;
      if (this.TaskCardObj) {
        if (
          this.TaskCardObj.isFinished ||
          (this.TaskCardObj.endTime == "" && this.TaskCardObj.startTime == "")
        ) {
          overTask = false;
        } else {
          if (this.TaskCardObj.endTime == "") {
            overTask = false;
          } else {
            let endStr = moment(this.TaskCardObj.endTime)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");
            endStr = endStr.replace(/-/g, "/");
            const d = new Date(endStr).getTime() - new Date().getTime();
            const difference = d / (24 * 3600 * 1000);
            // console.log("difference*********" + difference);
            if (difference >= 0) {
              overTask = false;
            } else {
              overTask = true;
            }
          }
        }
      }
      return overTask;
    },
    avatarData(getData: any) {
      if (getData) {
        this.users = [];
        let userArr = getData.taskUser
          .filter((item: any) => {
            return item.workType == 1;
          })
          .map(function (e: any, i: number) {
            return e.name;
          });
        let usersName = userArr;
        usersName.map((item: any, index: any) => {
          //  把原数组循环一下
          let obj = { name: item, img: "" };
          this.users.push(obj); //  img.jpg 数组的每一行添加一个 disabled:'true'  属性
        });
      }

      return this.users;
    },
    formatUsers(getData: any) {
      if (getData) {
        this.users = [];
        let userArr = getData.taskUser
          .filter((item: any) => {
            return item.workType == 1;
          })
          .map(function (e: any, i: number) {
            return e.name;
          });
        if (userArr.length > 0) {
          return userArr[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getPriority(priority: any) {
      let p = priority + "";
      let returnValue = "";
      switch (p) {
        case "0":
          returnValue = "低";
          break;
        case "1":
          returnValue = "中";
          break;
        case "2":
          returnValue = "高";
          break;
        case "3":
          returnValue = "高";
          break;
      }
      return returnValue;
    },
    bindDetail() {
      this.$emit("on-click");
    },
    bindCancelRelevancy() {
      this.$emit("on-cancelRelevancy");
    },
    addWorkHour(task: any) {
      this.$emit("on-addworkhour");
    },
    checkExector(task: any) {
      let userArr = task.taskUser.filter((item: any) => {
        return item.workType == 1 && item.userId == SystemUtils.loginUser.id;
      });
      if(userArr.length>0){
        return true;
      }
      else{
        return false;
      }
    },
  },
});
