
import { TaskLinkContentService } from "@/services/task/TaskLinkContentService";
import { defineComponent } from "vue";
export default defineComponent({
  name: "TaskLinkTaskList",
  props: {
    taskId: String,
    projectId: String,
    isIndependentTask: Boolean,
    searchKey: String,
  },
  data() {
    return {
      showNoDate: false,
      linkApi: new TaskLinkContentService(),
      parameters: {
        sort: "name",
        taskName: "",
        hasFold: "",
        excludeTaskId: this.taskId,
        offset: 1,
        limit: 15,
        projectId: this.isIndependentTask ? "" : this.projectId,
      },
      list: [],
      idList: new Array<any>(), //选中的id集合
    };
  },
  watch: {
    searchKey() {
      this.init(this.searchKey + "");
    },
  },
  mounted() {
    this.init(this.searchKey + "");
  },
  methods: {
    init(keyword: string) {
      this.parameters.taskName = keyword;
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
        this.showNoDate = res.length == 0;
      });
    },
    //获取选择的Id集合
    getSelectedItems() {
      return this.idList;
    },
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        const newItem = {
          targetId: item.id,
          targetProjectId: item.projectId,
          targetType: 2,
          name: item.name,
          type: "linked",
        };
        this.idList.push(newItem);
      } else {
        this.idList = this.idList.filter((e) => {
          return e.targetId != item.id;
        });
      }
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
        this.showNoDate = res.length == 0;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      if (this.isIndependentTask) {
        this.linkApi.getToLinkTaskList(this.parameters).then((res) => {
          callback(res);
        });
      } else {
        this.linkApi
          .getByUserIdAndVisibilityApp(this.parameters)
          .then((res) => {
            callback(res);
          });
      }
    },
  },
});
