
import { defineComponent } from "vue"; //
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import RemindUnreadList from "@/views/remind/RemindUnreadList.vue";
import HelperGuide from "./HelperGuide.vue";
import Feedback from "./Feedback.vue";
import Test from "./Test.vue";
import Utils from "@/utils/Utils";
import { modalController } from "@ionic/vue";
import { nextTick } from "process";
import AddFeedback from "./AddFeedback.vue";
import DocService from "@/services/doc/docService";
import { SubFolder, FolderParams } from "@/models/file/Folder";
import Setting from "@/rest/Setting";
import SystemUtils from "@/utils/SystemUtils";
import CookieHelper from "@/utils/CookieHelper";

export default defineComponent({
  name: "Helper",
  components: {
    Header,
    RemindUnreadList,
    HelperGuide,
    Feedback,
    AddFeedback,
    Test,
  },
  data() {
    const router = useRouter();
    let userId_ = SystemUtils.loginUser ? SystemUtils.loginUser.id : "";
    return {
      $refs: {
        feedback: Feedback,
      },
      tIndex: 0,
      refreshUnreadPage: true,
      docService: new DocService(),
      folderId: "okr@user-folder-feedback@temporary",
      userId: userId_,
    };
  },
  props: {},
  created() {},
  ionViewWillEnter() {},
  mounted() {},
  methods: {
    selectTypeHandler(type: number) {
      this.tIndex = type;
      if (this.tIndex == 2) {
        nextTick(() => {
          //判断是否已经创建目录
          this.$refs.feedback.initData();
        });
      }
    },
    async addFeedbackHandler() {
      if (!localStorage.getItem("okr@user-folder-feedback@temporary")) {
        console.log("没创建反馈目录");
        this.checkFolderExists(this.folderId, "用户反馈");
      }
      //弹出模态
      const modal = await modalController.create({
        component: AddFeedback,
        cssClass: "task-progress-update-class",
        componentProps: {
          userId: this.userId,
          folderId: this.folderId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.isrefresh) {
        this.$refs.feedback.initData(data.feedbackObj);
      }
    },

    checkFolderExists(pId: string, name: string) {
      this.docService.getFilesById(pId).then(
        (r: any) => {
          if (r) {
            if (r.data && r.data.id == pId) {
              localStorage.setItem(
                "okr@user-folder-feedback@temporary",
                "okr@user-folder-feedback@temporary"
              );
            }
          }
        },
        (e: any) => {
          let data = e.data ? e.data : { httpCode: "0000", code: "0000" };
          if (data.httpCode == "404" && data.code == "40000") {
            const folder: SubFolder = {
              parentId: "e0001",
              name: name,
              busId: "okr_feedback@temporary",
              busTable: "okr_feedback",
              busTag: "okr_feedback",
            };
            const params: FolderParams = Object.assign(
              { rootId: "0001", id: pId },
              folder
            );
            this.docService.createSubFolder(params).then(
              (id: string) => {
                console.log("意见反馈创建文件夹成功");
              },
              (e: any) => {
                console.log(JSON.stringify(e));
              }
            );
          }
        }
      );
    },
  },
});
