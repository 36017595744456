import PlanCenterBaseService from '../base/PlanCenterBaseService';

export class MainPlanApi extends PlanCenterBaseService {
    constructor() {
        super("main-plans");
    }

    //获得待办任务数据
    public getMainPlanTask(id: any) {
        return this.request("tasks/taskCenterData/"+id, this.rest.METHOD_GET,null);
    }
    //更新主计划任务
    public updataMainPlanInfo(params: any) {
        return this.request("tasks", this.rest.METHOD_PUT,params);
    }
}