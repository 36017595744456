/**
 * 任务 tasks接口定义，获取基础信息
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 首页
 */
export class TaskApi extends TaskBaseService {
    constructor() {
        super("tasks");
    }

    /**
     * 获取列表（检索）
     * @param param
     */
    public list(param: any): Promise<any> {
        const path = 'getMyTaskForApp';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 获取列表独立任务回收站（检索）
     * @param param
     */
    public getDeleteIndependentTask(param: any): Promise<any> {
        const path = 'getDeleteIndependentTask';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 获取任务详情，传参id
     * @param param
     */
    public getTaskInfo(param: any) {
        const path = 'getTaskEditVmById';
        return this.request(path, this.rest.METHOD_GET, param);
    }
    /**
     * 获取项目任务视图-- 我执行的 my 所有任务 all 今天的任务 today 未完成的任务 noFinish 已完成的任务 finish
     * @param param
     */
    public getTaskFroProjectIndexList(param: any) {
        const path = 'getTaskFroProjectIndex';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 新建任务
     * @param data
     */
    public addTask(data: any) {
        return this.request("createTask", this.rest.METHOD_POST, data);
    }

    /**
     * 添加子任务
     * @param data 
     */
    public addChildTask(data: any) {
        return this.request("", this.rest.METHOD_POST, data);
    }

    /**
     * 获取可见范围内的任务列表
     * 可用于任务【关联内容-独立任务】和【任务转为子任务】
     * @param param
     * projectId: 项目任务，必须传参项目Id
     * excludeTaskId: 去除掉任务本身
     */
    public getByProjectIdAndVisibility(param: any) {
        return this.request("getByProjectIdAndVisibility", this.rest.METHOD_GET, param);
    }

    /**
     * 获取新增微议题的任务列表
     */
    public getAppTaskListByMember(param: any) {
        return this.request("getAppTaskListByMember", this.rest.METHOD_GET, param);
    }

    /**
     * 项目内任务，获取可见范围内的任务列表
     * 可用于项目任务的【关联内容-独立任务】
     * @param param
     */
    public getByUserIdAndVisibilityApp(param: any) {
        return this.request("getByUserIdAndVisibilityApp", this.rest.METHOD_GET, param);
    }

    /**
     * 转换为子任务
     * @param param
     *  taskId: 当前任务Id
     *  parentId: 父任务Id
     */
    public convertToChildTask(param: any) {
        return this.request("toSub", this.rest.METHOD_GET, param);
    }

    /**
     * 任务移到回收站
     * @param id 
     */
    public removeToRecycle(id: string) {
        return this.request("app/" + id, this.rest.METHOD_DELETE);
    }
    //更新任务截止时间
    public saveList(param: any): Promise<any> {
        const path = "saveList";
        return this.request(path, this.rest.METHOD_POST, param);
    }
    //移动任务
    public updateByChangeGroupAndStage(param: any, stageId: string): Promise<any> {
        const path = "updateByChangeGroupAndStage?oldStageId=" + stageId;
        return this.request(path, this.rest.METHOD_POST, param);
    }


    /**
     * 根据Id更新某个字段
     */
    public updateFieldsById(data: any) {
        return this.request("updateFieldsById", this.rest.METHOD_PUT, data);
    }

    /**
     * 移动任务
     * @param taskId 
     * @param targetProjectId 
     * @param targetTaskGroupId 
     * @param targetTaskStageId 
     */
    public moveTask(taskId: string, targetProjectId: string, targetTaskGroupId: string, targetTaskStageId: string) {
        const param = {
            taskId: taskId,
            targetProjectId: targetProjectId,
            targetTaskGroupId: targetTaskGroupId,
            targetTaskStageId: targetTaskStageId
        };
        return this.request("toRoot", this.rest.METHOD_GET, param);
    }

    public getMyTrackTaskList(param: any) {
        return this.request("getMyTrackTaskList", this.rest.METHOD_GET, param);
    }

    getTaskOverView() {
        return this.request("getTaskOverview", this.rest.METHOD_GET);
    }

    /**
     * 获取项目任务完成情况最近7天
     * */
    public getFinishTaskCount(param: any) {
        return this.request("getFinishTaskCount", this.rest.METHOD_GET, param);
    }
    /**
     * 项目-回收站-任务列表
     * */
    public getByProjectForDelete(param: any) {
        return this.request("getByProjectForDelete", this.rest.METHOD_GET, param);
    }

    /**
     * 项目-回收站-任务列表-删除
     * */
    public clearById(param: any) {
        return this.request("app/clearTaskById?id=" + param.id, this.rest.METHOD_POST, null);
    }

    /**
     * 项目-回收站-任务列表-还原
     * */
    public restoreById(param: any) {
        return this.request("app/restoreById?id=" + param.id, this.rest.METHOD_PUT, null);
    }

    public getTreeTasksByProject(projectId: string) {
        return this.request("getTreeTasksByProject?projectId=" + projectId, this.rest.METHOD_GET, null);
    }

    public getTaskFromType(param: any) {
        return this.request("getTaskFromType", this.rest.METHOD_GET, param);
    }
    public updateIsFinishedByUser(params: any) {
        let path = "updateIsFinishedByUser?id=" + params.id + "&&isFinished=" + params.isFinished + "&&userId=" + params.userId + "&&userName=" + params.userName;
        return this.request(path, this.rest.METHOD_PUT, params);
    }
    //任务转派
    public taskTransfer(param: any) {
        return this.request("taskTransfer", this.rest.METHOD_GET, param);
    }
    //任务转派
    public getAllTaskByProjectIdAndParams(param: any) {
        return this.request("getAllTaskByProjectIdAndParams", this.rest.METHOD_GET, param);
    }
    //任务工时是否饱满
    public isfullworkingHour(param: any): Promise<any> {
        const path = 'isfullworkingHour?id=' + param;
        return this.request(path, this.rest.METHOD_GET);
    }
    //获取任务工时详细
    public getWorkingHourByTaskId(id: any) {
        return this.request("workingHour/detail?id=" + id,this.rest.METHOD_GET);
    }
    //任务-项目列表任务列表
    public getProjectByTask(params:any){
        return this.request("getMyTaskForAppProjectView/byproject/list",this.rest.METHOD_GET,params);
    }
}