
import { IonProgressBar, popoverController } from "@ionic/vue";
import PostPopover from "@/views/team/add/PostPopover.vue";
import RolePopover from "@/views/team/add/RolePopover.vue";
import { defineComponent } from "vue";
import AvatarList from "@/components/AvatarList.vue";
export default defineComponent({
  name: "ProjectMemberCard",
  components: {
    IonProgressBar,
    AvatarList,
  },
  props: {
    memberObj: Object,
    meberIndex: Number,
    projectId: String,
    allTeamRoles: Array,
    allProjectRoles: Array,
    hasDelMember: Boolean,
    hasEditMemberRole: Boolean,
  },
  data() {
    return {
      users: new Array<any>(),
    };
  },
  methods: {
    //设置职位
    async openPostPopover(ev: Event, postId: string) {
      const popover = await popoverController.create({
        component: PostPopover,
        cssClass: "my-popover-class",
        event: ev,
        translucent: false,
        componentProps: {
          postId: postId,
          list: this.allProjectRoles,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && "id" in data) {
        this.$emit("onSelectPostPopover", {
          index: this.meberIndex,
          selectObj: data,
        });
      }
    },
    //设置角色
    async openRolePopover(ev: Event, roleId: string) {
      const popover = await popoverController.create({
        component: RolePopover,
        cssClass: "my-popover-class",
        event: ev,
        translucent: false,
        componentProps: {
          roleId: roleId,
          list: this.allTeamRoles,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && "id" in data) {
        this.$emit("onOpenRolePopover", {
          index: this.meberIndex,
          selectObj: data,
        });
      }
    },

    bindDeleteUser(i: number) {
      this.$emit("onDeleteUser", i); //人员列表索引
    },
    avatarData(memberObj: any) {
      this.users = [];
      let obj = { name: memberObj.userName, img: "", id: memberObj.userId };
      this.users.push(obj);
      return this.users;
    },
  },
});
