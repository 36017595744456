import { SearchPanelApi } from '@/api/searchPanel/SearchPanelApi';
import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';
import { TaskSystemsApi } from '@/api/task/TaskSystemsApi'

export class SearchPanelService extends BaseService {


    private api = new SearchPanelApi()
    private systemsApi = new TaskSystemsApi();

    /**
    * 新建/编辑查询条件
    * @param param 
    */
    public saveSearch(data: any): Promise<any> {
        let newId = "";
        if (!data.id) {
            newId = BaseService.newId("task", "appQuerySave");
        }
        return new Promise((resolve, reject) => {
            this.api.saveSearch(data, newId).then((res: any) => {
                resolve(res.data)
            }).catch((err: any) => {
                reject(err)
            })
        })
    }

    //查询模板  获取任务/项目来源
    public getSourceList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.systemsApi.getSelectAll().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //获取查询条件列表
    public getAppQueryByUserId(params: any): Promise<any> {
        const newModle = Object.assign(params, { userId: SystemUtils.loginUser.id })
        return new Promise((resolve, reject) => {
            this.api.getAppQueryByUserId(newModle).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //删除任务阶段
    public clearById(id: string): any {
        return new Promise((resolve, reject) => {
            if (id) {
                this.api.clearById(id).then(async (res: any) => {
                    resolve(res);
                }).catch((err: any) => {
                    reject(err);
                });
            } else {
                reject({ error: "true", message: "id不能为空" });
            }


        })
    }

    //获取id查询
    public getById(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getById(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}
