
import { TaskGroupApi } from '../../api/task/TaskGroupApi';
import { TaskStagesApi } from '../../api/task/TaskStagesApi';
import { TaskApi } from '../../api/task/TaskApi';

import BaseService from '../base/BaseService';
/**
 * 项目列表模块
 */
export class ProjectViewService extends BaseService {
    //任务分组
    private taskGroupApi = new TaskGroupApi();
    //任务阶段
    private taskStagesApi = new TaskStagesApi();
    //最终返回结果
    //
    //根据项目ID获取项目下所有分组、阶段、任务（甘特图数据结构）
    public getProjectTaskTree(projectId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            //获取项目下所有分组
            this.taskGroupApi.getByProjectIdForMennu({ projectId: projectId }).then((res: any) => {
                let rootGroupArr: Array<any> = res.data;
                //promiseAll 对象数组
                let promiseArr = new Array<Promise<any>>();
                let arr = {
                    data: new Array(0),
                    links: new Array(0),
                };
                for (let groupObject of rootGroupArr) {
                    arr = {
                        data: new Array(0),
                        links: new Array(0),
                    };
                    const projectId = groupObject.projectId;
                    const taskGroupId = groupObject.id;
                    const groupName = groupObject.name;
                    let groupModel = {
                        id: taskGroupId,
                        text: groupName,
                        start_date: "",
                        end_date: "",
                        open: true,
                        execUser: "",
                    }
                    //添加分组到gantt数据集合中
                    arr.data.push(groupModel);
                    //构造promiseAll 数组对象，每次异步请求，返回一个promise对象
                    promiseArr.push(this.getAllTaskStageByGroupId(projectId, taskGroupId, arr));
                }
                //promiseAll 处理，多个promise全部 
                Promise.all(promiseArr).then((allPromisResArr: Array<any>) => {
                    let realViewArr = {
                        data: new Array(0),
                        links: new Array(0),
                    };
                    for (let promisRes of allPromisResArr) {
                        realViewArr.data = realViewArr.data.concat(promisRes.data);
                        realViewArr.links = realViewArr.data.concat(promisRes.links);
                    }
                    resolve(realViewArr);
                }).catch((err: any) => {
                    reject(err);
                });
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //通过分组获取所有阶段
    private getAllTaskStageByGroupId(projectId: any, taskGroupId: any, arr: any): any {
        return new Promise((resolve, reject) => {
            this.taskStagesApi.getStageListByProjecctAndTaskGroupId({ projectId, taskGroupId }).then((res: any) => {
                if (res && res.data.length > 0) {
                    const stageArr = res.data;
                    //处理每个阶段
                    for (let i = 0; i <= stageArr.length - 1; i++) {
                        //阶段对象
                        const stageObj = stageArr[i].taskStage;
                        let stageModel = {
                            id: stageObj.id,
                            text: stageObj.name,
                            start_date: "",
                            end_date: "",
                            // duration: 6,
                            open: true,
                            execUser: "",
                            parent: stageObj.taskGroupId
                        };
                        arr.data.push(stageModel);
                        //阶段下任务列表
                        const taskList: Array<any> = stageArr[i].taskList;
                        //阶段下包含任务
                        if (taskList.length > 0) {
                            for (let task of taskList) {
                                let executorUserNames = "";
                                if (task.taskUser.length > 0) {
                                    executorUserNames = this.getNewArr(task.taskUser).map(function (e, i) {
                                        return e.name
                                    }).join(',')
                                }
                                let taskModel = {
                                    id: task.id,
                                    text: task.name,
                                    start_date: task.startTime,
                                    end_date: task.endTime,
                                    // duration: 6,
                                    progress: task.progress,
                                    open: true,
                                    execUser: executorUserNames,
                                    parent: task.parentId ? task.parentId : stageObj.id
                                };
                                arr.data.push(taskModel);
                                //处理任务关系
                                if (task.taskRely.length > 0) {
                                    for (let rely of task.taskRely) {
                                        let taskLinkModel = {
                                            id: rely.id,
                                            source: rely.sourceTaskId,
                                            target: rely.targetTaskId,
                                            type: parseInt(rely.relyOnCondition) - 1
                                        };
                                        arr.links.push(taskLinkModel);
                                    }
                                }
                            }
                        }
                    }
                }
                resolve(arr);

            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //从数组中筛选满足条件的数据
    private getNewArr(data: Array<any>): Array<any> {
        return data.filter((item) => {
            return item.workType == 1;
        });
    }

    // 通过项目id获取项目下的任务 tree 
    public getTreeTasksByProject(projectId: string): Promise<any> {
        const api=new  TaskApi();
        return new Promise((resolve, reject) => {
            api.getTreeTasksByProject(projectId).then((res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}
