
import {TaskStagesApi} from '@/api/task/TaskStagesApi';
import {TaskApi} from '@/api/task/TaskApi'
import BaseService from '../base/BaseService';
/**
 * 任务阶段
 */
export class TaskStageService extends BaseService {

    private api=new TaskStagesApi();
    private taskApi=new TaskApi();
    
    //根据分组ID和项目ID获取 任务阶段/taskStages/getStageListByProjecctAndTaskGroupId
    public getStageListByProjecctAndTaskGroupId(params:any): any {
        return new Promise((resolve, reject) => {
            this.api.getStageListByProjecctAndTaskGroupId(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //编辑阶段名称
    public updateFieldsById(parms: any): any {
        return new Promise((resolve, reject) => {
            this.api.updateFieldsById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //在后增加阶段
    public insertAfter(parms: any): any {
        return new Promise((resolve, reject) => {
            this.api.insertAfter(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //增加阶段
    public addNewStage(parms: any): any {
        return new Promise((resolve, reject) => {
            this.api.addNewStage(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //删除任务阶段
    public clearById(parms: any):any {
        return new Promise((resolve, reject) => {
            this.api.clearById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //设置截止时间
    public updateTaskEndTime(parms: any):any {
        return new Promise((resolve, reject) => {
            this.taskApi.saveList(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    public moveSatgeTask(parms: any,stageId: string):any {
        return new Promise((resolve, reject) => {
            this.taskApi.updateByChangeGroupAndStage(parms,stageId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //创建一个新实体
    public newStage(){
        return super.newModel("task","TaskStage");
    }
    
}