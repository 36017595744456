
import { defineComponent, onUnmounted } from "vue";
import {
  IonContent,
  IonFooter,
  IonInput,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import { alertController } from "@ionic/vue";
import Massage from "@/components/ListCard/Massage.vue";
import Message from "@/models/message/Message";
import { getInstance } from "@/services/message/MessageService";
import SystemUtils from "@/utils/SystemUtils";
import BaseRsp from "@/models/base/BaseRsp";
import Utils from "@/utils/Utils";
import { FileHelper, UploadStatus } from "@/utils/FileHelper";
import Bus from "@/utils/bus";
import { SingleFile } from "@/models/file/Storage";
import SqlHelper from "@/utils/sql/SqlHelper";
import moment from "moment";
export default defineComponent({
  name: "Chat",
  components: {
    IonContent,
    IonFooter,
    IonInput,
    Massage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  props: {
    message: String,
    chatGroupId: String,
    folderId: String,
    disabled: Boolean,
    busTable: String,
    id: String,
    title: String,
    projectId: String,
    projectName: String,
    taskId: String,
    minTop: Boolean,
    isMember: Boolean,
    isHome: Boolean,
  },
  // emits: ["exitMessage"],
  data() {
    const service = getInstance();
    const fileHelper = new FileHelper();
    const sqlHelper = new SqlHelper();
    return {
      userId: SystemUtils.loginUser.id,
      messageList: Array<Message>(0),
      service: service,
      msgContent: "",
      page: 1,
      workType: "",
      fileHelper: fileHelper,
      mDisabled: this.disabled,
      mFolderId: "",
      mChatGroupId: "",
      mId: "",
      mTitle: "",
      mProjectId: "",
      mProjectName: "",
      mTaskId: "",
      isShowSend: false,
      mIsMember: this.isMember,
      isClickedPreviewPic: false,
      sqlHelper: sqlHelper,
      firstTime: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
  },

  created() {
    if (this.folderId) this.mFolderId = this.folderId + "@temporary";
    if (this.chatGroupId) this.mChatGroupId = this.chatGroupId;
    if (this.id) this.mId = this.id;
    if (this.title) this.mTitle = this.title;
    if (this.projectId) this.mProjectId = this.projectId;
    if (this.projectName) this.mProjectName = this.projectName;
    if (this.taskId) this.mTaskId = this.taskId;
    this.workType = this.busTable as string;
    if (this.isHome) {
      this.getChatMembersByGroupId();
    } else {
      if (!this.mIsMember) {
        Utils.presentToast("您无权限查看消息");
      } else {
        this.getMessageList();
        if (!this.mDisabled) this.registerMessageListener();
      }
    }
    // (window as any).addEventListener("keyboardWillHide", () => {});

    (window as any).addEventListener("keyboardDidShow", (event: any) => {
      this.scrollToBottom();
    });
  },

  setup() {
    onUnmounted(() => {
      Bus.$off("receive_message");
    });
  },

  methods: {
    getChatMembersByGroupId() {
      // this.service
      //   .getChatMembersByGroupId(this.mChatGroupId)
      //   .then((res: boolean) => {
          this.mIsMember = true;
          this.getMessageList();
          if (this.mIsMember) {
            this.registerMessageListener();
          } /* else {
            // this.exitMessage();
          } */
          // alert(this.mIsMember)
        // });
    },

    registerMessageListener() {
      Bus.$on("receive_message", (data: Message) => {
        if (data.chatGroupId == this.mChatGroupId) {
          this.messageList.push(data);
          this.sqlHelper.updateMessageState(this.mChatGroupId as string);
          this.service.updateMessageState(this.mChatGroupId as string);
        }
        this.scrollToBottom();
      });
    },

    settingMessageEnable(enable: boolean) {
      this.mDisabled = !enable;
    },

    settingChatFolderId(folderId: string) {
      if (folderId) this.mFolderId = folderId + "@temporary";
    },

    inputChange(event: any) {
      if (Utils.trim(event.detail.value).length > 0) {
        this.isShowSend = true;
      } else {
        this.isShowSend = false;
      }
    },

    // 相册选择按钮事件（选择图片、上传）
    clickFile() {
      if (
        this.mDisabled ||
        !this.mIsMember ||
        !this.mChatGroupId ||
        !this.mFolderId
      ) {
        if (this.mDisabled || !this.mIsMember) {
          Utils.presentToastWarning("已被禁用");
        } else if (!this.mChatGroupId) {
          Utils.presentToastWarning("消息组为空,不可发送内容");
        } else if (!this.mFolderId) {
          Utils.presentToastWarning("文件组不存在");
        }
        console.log(this.mFolderId);
        
        return;
      }
      this.fileHelper.uploadWay = "chat";
      this.fileHelper.uploadProgress = true;
      this.fileHelper.openPhotoFolderAndGetSize().then(
        (result: any) => {
          let expandedName = this.fileHelper.getExpandedName(
            result.imageData as string
          );
          // 选择的文件非图片（BMP、JPG、JPEG、PNG、GIF）
          if (this.fileHelper.imageExts.indexOf(expandedName) < 0) {
            this.notImgHint(false);
            return;
          }
          let message: Message = new Message();
          message.messageType = 4;
          message.content = "";
          message.sendUserName = SystemUtils.loginUser.name;
          message.sendUserId = SystemUtils.loginUser.id;
          message.sendTime = "上传中... "; // ☆☆☆ 这个字符串中最后边的空格不要删除掉
          message.imgs = [
            {
              url: "",
              status: UploadStatus.Uploading,
              message: "",
            },
          ];
          let i = this.messageList.length;
          this.messageList.push(message);
          this.scrollToBottom();
          let singleFile: SingleFile = {
            folderId: this.mFolderId as string,
            busId: this.mProjectId,
            busTable: this.mProjectId == "" ? "" : "tt_project",
            systemId: "",
            size: result.fileSize,
          };
          this.fileHelper.getStorageFolder(
            singleFile,
            result.imageData,
            (r: any) => {
              let response = JSON.parse(r.response);
              let file = {
                fileId: response.id,
                fileName: response.name,
                fileType: expandedName,
                fileSize: result.fileSize,
              };
              this.messageList[i].imgs = [
                {
                  url: this.fileHelper.getPreviewFile(response.id),
                  status: UploadStatus.Done,
                  message: "",
                },
              ];
              this.messageList.splice(i, 1);
              this.sendMessage(4, "", file);
            },
            (e: any) => {
              Utils.presentToastWarning("上传文件失败");
              this.messageList.splice(i, 1);
            }
          );
        },
        (e: any) => {
          Utils.presentToastWarning("选择手机相册文件失败");
        }
      );
    },

    // 选择非图片提示
    async notImgHint(isGIF: boolean) {
      const alert = await alertController.create({
        header: "选择相册失败",
        mode: "ios",
        message: isGIF
          ? "暂不支持发送gif，请重新选择"
          : "仅支持发送图片，请重新选择",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              this.clickFile();
            },
          },
        ],
      });
      await alert.present();
    },

    // 下载确认
    async downloadHint(message: Message) {
      const alert = await alertController.create({
        header: "文件下载",
        mode: "ios",
        message: "是否下载文件" + message.file.fileName + "？",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              this.downloadFile(message);
            },
          },
        ],
      });
      await alert.present();
    },

    // 获取消息列表
    getMessageList(isReload: boolean = true, event?: any) {
      if (
        this.mChatGroupId == null ||
        this.mChatGroupId == "" ||
        this.mChatGroupId == undefined ||
        this.mChatGroupId == "undefined"
      ) {
        Utils.presentToastWarning("未获取消息组");
        return;
      }
      if (isReload) {
        this.messageList = new Array<Message>(0);
        this.sqlHelper.updateMessageState(this.mChatGroupId);
        this.service.updateMessageState(this.mChatGroupId);
      }
      if (this.message && isReload) {
        this.sqlHelper
          .getSearchMessageList(JSON.parse(this.message))
          .then((data: any) => {
            this.messageList = data;
          });
      } else {
        this.sqlHelper
          .selectMessageListByChatGroupId(
            this.mChatGroupId as string,
            this.messageList.length
          )
          .then(
            (data: any) => {
              if (data.length <= 0) {
                if (this.messageList.length > 0) {
                  this.firstTime = this.messageList[0].sendTime;
                }
                this.getNetMessageList(this.page, this.firstTime);
              } else {
                this.messageList = data.concat(this.messageList);
                if (isReload) this.scrollToBottom();
              }
            },
            () => {
              this.getNetMessageList(this.page, this.firstTime);
            }
          )
          .catch(() => {
            this.getNetMessageList(this.page, this.firstTime);
          });
      }
      if (event)
        setTimeout(() => {
          event.target.complete();
        }, 800);
    },

    getNetMessageList(page: number, firstTime?: string) {
      this.service
        .getNetMessageList(this.mChatGroupId as string, page, firstTime)
        .then(
          (rsp: any) => {
            let isScroll = false;
            if (rsp.length > 0) {
              if (this.messageList) {
                if (this.messageList.length <= 0) {
                  isScroll = true;
                }
                for (let i = rsp.length; i > 0; i--) {
                  this.messageList.unshift(rsp[i - 1]);
                }
              }
              if (page == 1 && isScroll) {
                this.scrollToBottom();
              }
              this.page++;
            }
          },
          (err: any) => {}
        );
    },

    // 发送消息
    sendMessage(msgType: number, content?: string, file?: any) {
      if (this.mChatGroupId) {
        if (msgType == 1) {
          content = this.msgContent;
        }
        if (content || msgType != 1)
          this.service
            .sendMessageExpose(
              this.mChatGroupId,
              content,
              msgType,
              this.workType,
              this.mId + "",
              this.mTitle + "",
              {
                projectId: this.mProjectId,
                projectName: this.mProjectName,
                taskId: this.mTaskId,
              },
              file
            )
            .then(
              (rsp: BaseRsp) => {
                if (msgType == 1) {
                  this.msgContent = "";
                }
              },
              (err: BaseRsp) => {
                Utils.presentToastWarning(err.message);
              }
            );
      } else {
        Utils.presentToastWarning("消息组为空,不可发送内容");
      }
    },

    // 消息滚动到列表最下方
    scrollToBottom() {
      setTimeout(() => {
        const view: any = document.querySelector("#messageListView");
        if (view != null) {
          const ele: Element = view;
          ele.scrollTo({
            top: ele.scrollHeight,
          });
        }
      }, 200);
    },

    // 加载更多消息
    doRefresh(event: any) {
      this.getMessageList(false, event);
    },

    // 查看图片
    preViewImg(message: Message) {
      if (this.isClickedPreviewPic) {
        return;
      }
      this.isClickedPreviewPic = true;
      if (message.file && message.file.fileId && message.file.fileName) {
        let expandedName = this.fileHelper.getExpandedName(
          message.file.fileName
        );
        this.fileHelper
          .downloadOriginal(message.file.fileId, expandedName, 0)
          .then(
            (res: any) => {
              this.fileHelper.previewVant([res], 0);
              this.isClickedPreviewPic = false;
            },
            (err: any) => {
              this.isClickedPreviewPic = false;
            }
          )
          .catch(() => {
            Utils.presentToastWarning("加载失败");
            this.isClickedPreviewPic = false;
          });
      }
    },

    // 查看文件
    previewFile(message: Message) {
      this.fileHelper
        .checkExistsFile(message.file.fileId, message.file.fileName)
        .then(
          (res: any) => {
            this.fileHelper.previewNativeFile(
              message.file.fileId,
              message.file.fileName
            );
          },
          (err: any) => {
            this.downloadHint(message);
          }
        )
        .catch((err: any) => {
          Utils.presentToastWarning("文件检查失败");
        });
    },

    //下载文件
    downloadFile(message: Message) {
      this.fileHelper
        .downloadNative(message.file.fileId, message.file.fileName)
        .then(
          (result: any) => {
            let flag = false;
            if (result) {
              if ("nativeURL" in result && result.nativeURL != "") {
                Utils.presentToastSuccess("下载完成");
                flag = true;
              }
            }
            if (!flag) {
              Utils.presentToastWarning("下载失败");
            }
          },
          (e: any) => {
            Utils.presentToastWarning("下载失败");
          }
        );
    },

    // 更新聊天组件参数，并重新加载数据
    updateParam(
      chatGroupId: string,
      folderId: string,
      disabled: boolean,
      isMember: boolean,
      id: string,
      title: string,
      projectId: string,
      projectName: string,
      taskId: string
    ) {
      this.mChatGroupId = chatGroupId;
      if (folderId) this.mFolderId = folderId + "@temporary";
      this.mDisabled = disabled;
      this.mIsMember = isMember;
      this.mId = id;
      this.mTitle = title;
      this.mProjectId = projectId;
      this.mProjectName = projectName;
      this.mTaskId = taskId;
      this.messageList = Array(0);
      this.getMessageList();
    },
  },
});
