import { MicroissuesApi } from '@/api/microissues/MicroissuesApi';
import BaseRsp from '@/models/base/BaseRsp';
import Issues, { IssuesListRsp, IssuesRsp } from '@/models/microIssues/Issues';
import Member from '@/models/microIssues/Member';
import SystemUtils from '@/utils/SystemUtils';
import BaseService from './base/BaseService';
import moment from 'moment';
import Utils from '@/utils/Utils';

/**
 * 微议题
 */
export class MicroIssuesService extends BaseService {

    private readonly microIssuesApi: any = new MicroissuesApi();

    /** 新增微议题
     * @param issues 微议题
     */
    private addMicroIssues(issues: Issues): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.addMicroIssues(issues)
                .then(async (res: BaseRsp) => {
                    resolve(res);
                })
                .catch((err: BaseRsp) => {
                    reject(err);
                });
        });
    }

    /** 修改微议题信息
     * @param issues 微议题
     */
    private updateIssues(issues: Issues): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.updateMicroIssues(issues)
                .then(async (res: BaseRsp) => {
                    resolve(res);
                })
                .catch((err: BaseRsp) => {
                    reject(err);
                });
        });
    }

    /** 新增微议题（对外方法）
     * @param title 主题
     * @param projectId 项目id
     * @param projectName 项目名称
     * @param taskId 任务id 
     * @param taskName 任务名称
     * @param remark 备注
     * @param users 参与人
     */
    public addMicroIssuesExpose(
        title: string,
        projectId: string,
        projectName: string,
        taskId: string,
        taskName: string,
        remark: string,
        users: Array<any>,
    ): Promise<BaseRsp> {
        title = this.trim(title);
        if (!title || title.length <= 0) {
            return this.errRsp("请输入微议题主题");
        }
        if (Utils.isInCludeEmoji(title) || Utils.isInCludeEmoji(remark)) {
            return this.errRsp("请勿输入emoji表情");
        }
        if (projectId == "undefined" || projectId == null || projectId == "" || projectId == undefined) {
            projectName = "";
        }
        if (taskId == "undefined" || taskId == null || taskId == "" || taskId == undefined) {
            taskName = "";
        }
        if (projectName == "" && taskName == "") {
            return this.errRsp("请选择项目或任务");
        }
        if (!users || users.length <= 0) {
            return this.errRsp("请选择参与人员");
        }
        let members: Array<Member> = [];
        for (let user of users) {
            let member = {
                userId: user.userId + "",
                pernr: user.pernr,
                userName: user.name,
            };
            members.push(member);
        }
        let issues: Issues = {
            id: this.newId(),
            title: title,
            projectId: projectId,
            projectName: projectName,
            taskId: taskId,
            taskName: taskName,
            remark: remark,
            topicParticipantList: members,
            status: "0",
            isDeleted: false
        };
        return this.addMicroIssues(issues);
    }

    /** 修改微议题信息（对外方法）
     * @param issues 微议题对象
     * @param title 主题
     * @param projectId 项目id
     * @param projectName 项目名称
     * @param taskId 任务id 
     * @param taskName 任务名称
     * @param remark 备注
     * @param users 参与人
     */
    public updateIssuesExpose(
        issues: Issues,
        title: string,
        projectId: string,
        projectName: string,
        taskId: string,
        taskName: string,
        remark: string,
        users: Array<any>,
    ): Promise<BaseRsp> {
        title = this.trim(title);
        if (!title || title.length <= 0) {
            return this.errRsp("请输入微议题主题");
        }
        if (Utils.isInCludeEmoji(title) || Utils.isInCludeEmoji(remark)) {
            return this.errRsp("请勿输入emoji表情");
        }
        if (projectId == "undefined" || projectId == null || projectId == "" || projectId == undefined) {
            projectName = "";
        }
        if (taskId == "undefined" || taskId == null || taskId == "" || taskId == undefined) {
            taskName = "";
        }
        if (projectName == "" && taskName == "") {
            return this.errRsp("请选择项目或任务");
        }
        if (!users || users.length <= 0) {
            return this.errRsp("请选择参与人员");
        }
        let newMembers: Array<Member> = [];
        for (let user of users) {
            let isMatching = false;
            if (issues.topicParticipantList) {
                for (let member of issues.topicParticipantList) {
                    if (member.userId == user.id) {
                        newMembers.push(member);
                        isMatching = true;
                        break;
                    }
                }
            }
            if (isMatching) continue;
            let newMember = {
                userId: user.userId + "",
                pernr: user.pernr,
                userName: user.name,
                topicId: issues.id,
                groupId: issues.groupId,
            };
            newMembers.push(newMember);
        }
        issues.title = title;
        issues.remark = remark;
        issues.newTopicParticipantList = newMembers;
        issues.status = "0";
        return this.updateIssues(issues);
    }

    /** 获取微议题列表
     * @param param0 微议题查询条件
     */
    public getMicroIssuesList({
        offset = 1,
        limit = 15,
        projectId = "",
        taskId = "",
        searchText = "",
        userId = SystemUtils.loginUser.id,
    }):Promise<any> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.getMicroIssuesList({
                offset,
                limit,
                projectId,
                taskId,
                searchText,
                userId,
            }).then(async (res: IssuesListRsp) => {
                for (let issues of res.data) {
                    issues.createTime = moment(issues.createTime).format("YYYY-MM-DD HH:mm");
                }
                resolve(res.data);
            }).catch((err: BaseRsp) => {
                reject(err);
            });
        })
    }

    /** 获取微议题回收站列表
     * @param param0 微议题查询条件
     */
    public getMicroIssuesRecycleList({
        userId = SystemUtils.loginUser.id,
        offset = 1,
        limit = 15,
        name = "",
    }): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.getMicroIssuesRecycleList({
                userId,
                offset,
                limit,
                name
            }).then(async (res: IssuesListRsp) => {
                for (let issues of res.data) {
                    issues.createTime = moment(issues.createTime).format("YYYY-MM-DD HH:mm");
                }
                resolve(res.data);
            }).catch((err: BaseRsp) => {
                reject(err);
            });
        })
    }

    /** 获取微议题详情
     * @param id 微议题id
     */
    public getIssuesInfo(id: string): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.getIssuesInfo(id)
                .then(async (res: IssuesRsp) => {
                    let issues = Object.assign(res.data, { users: [] });
                    for (let member of issues.topicParticipantList) {
                        let user = {
                            // gesch: member.gesch,
                            id: member.userId,
                            pernr: member.pernr,
                            // phone: member.phone,
                            name: member.userName,
                            userId: member.userId,
                            // vorna: member.vorna,
                        }
                        issues.users.push(user);
                    }
                    resolve(issues);
                }).catch((err: BaseRsp) => {
                    reject(err);
                });
        })
    }

    /** 微议题移至、移出回收站
     * @param id 微议题id
     * @param isCollect 微议题当前是否在回收站
     */
    public recycleIssues(id: string, isCollect: boolean): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.recycleIssues(id, isCollect)
                .then(async (res: BaseRsp) => {
                    resolve(res);
                }).catch((err: BaseRsp) => {
                    reject(err);
                });
        })
    }

    //删除微议题
    public clearById(id: string): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.deleteById(id)
                .then(async (res: BaseRsp) => {
                    resolve(res);
                }).catch((err: BaseRsp) => {
                    reject(err);
                });
        })
    }

    public getFolderIdByBusInfo(busId: string, busTable: string): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.getFolderIdByBusInfo({ busId: busId, busTable: busTable })
                .then(async (res: BaseRsp) => {
                    resolve(res);
                }).catch((err: BaseRsp) => {
                    reject(err);
                });
        })
    }

    public getBusIsDelete(busId: string, busTable: string): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            this.microIssuesApi.getBusIsDelete({ busId: busId, busTable: busTable })
                .then(async (res: BaseRsp) => {
                    resolve(res.data);
                }).catch((err: BaseRsp) => {
                    reject(err);
                });
        })
    }

}

export function getInstance() {
    return new MicroIssuesService();
}