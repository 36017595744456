<template>
  <ion-slides pager="true" :options="slideOpts">
    <ion-slide v-for="(r, index) in pageItems" :key="index">
      <ion-grid>
        <ion-row>
          <ion-col v-for="(c, i) in r" :key="i" size="3">
            <div
              v-if="c.name != ''"
              class="left-block"
              @click="bindModuleRouter(c)"
            >
              <!-- <span :class="c.icon" :style="{ color: c.color }"></span> -->

              <div
                class="iconBox"
                :style="{ background: c.background }"
                :class="{ activated: c.activated }"
              >
                <i class="iconfont" :class="[c.icon]"></i>
              </div>
              <ion-label>{{ c.title }}</ion-label>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-slide>
  </ion-slides>
</template>

<script>
import { IonSlides, IonSlide, IonLabel, IonAvatar } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { getStatistic } from "@/api/questionManage/selectStage";
import {
  getStatisticByManage,
  getStatisticByPart,
} from "@/api/questionManage/selectStageManage";
import Bus from "@/utils/bus";
import Setting from "@/rest/Setting";
import FolderTreeVue from "../project/menu/ProjectRecycle/child/FolderTree.vue";
export default defineComponent({
  name: "ModuleMenu",
  components: {
    IonSlides,
    IonSlide,
    IonLabel,
    IonAvatar,
  },
  props: {
    colNum: {
      type: Number,
      default: 4,
    },
    modulesProp: {
      type: Array,
      default: [],
    },
  },
    watch: {
    'modulesProp':function(newVal,oldVal) {
      // this.initModule();
      this.pageItems=[]
      this.initModule();
    this.initData();
    },
  },
  data() {
    const router = useRouter();
    const pageItems = [];
    const modules = [];
    return {
      router: router,
      slideNum: 1,
      slideOpts: {},
      pageItems: pageItems,
      modules: modules,
    };
  },
  created() {
    this.initModule();
    this.initData();
    this.initOption();

    if (
      this.modulesProp.some((e) => {
        return e.title == "问题查看";
      })
    ) {
      this.modulesProp.map((e) => {
        if (e.title == "问题查看") {
          getStatistic({ isLoading: 1, isEnd: 1 }).then((res) => {
            var list = [];
            list = res.data.data.resultData;
            list.forEach((element) => {
              if (element.unRead > 0) {
                e.activated = true;
              }
            });
          });
        }
        if (e.title == "管理类问题") {
          getStatisticByManage({ isLoading: 1, isEnd: 1 }).then((res) => {
            var list = [];
            list = res.data.data.resultData;
            list.forEach((element) => {
              if (element.unRead > 0) {
                e.activated = true;
              }
            });
          });
        }
        if (e.title == "制件类问题") {
         getStatisticByPart({ isLoading: 1, isEnd: 1 }).then((res) => {
            var list = [];
            list = res.data.data.resultData;
            list.forEach((element) => {
              if (element.unRead > 0) {
                e.activated = true;
              }
            });
          });
        }
      });
    }
  },
  methods: {
    initModule() {
      let isVisible = !Setting.isSVW;
      // this.modules = [
      //   {
      //     name: "task-list",
      //     title: "任务",
      //     icon: "#iconiconrenwu",
      //     visible: true,
      //   },
      //   {
      //     name: "OKR",
      //     title: "OKR",
      //     icon: "#iconiconOKR",
      //     visible: true,
      //   },
      //   {
      //     name: "calendar",
      //     title: "日历",
      //     icon: "#iconiconrili",
      //     visible: true,
      //   },
      //   {
      //     name: "project",
      //     title: "项目",
      //     icon: "#iconiconxiangmu",
      //     visible: isVisible,
      //   },
      //   {
      //     name: "attendance",
      //     title: "考勤",
      //     icon: "#iconiconkaoqin",
      //     visible: true,
      //   },
      //   {
      //     name: "meeting",
      //     title: "会议",
      //     icon: "#iconiconhuiyi",
      //     visible: true,
      //   },
      //   {
      //     name: "visitor",
      //     title: "访客",
      //     icon: "#iconiconfangke",
      //     visible: true,
      //   },
      // ];
      this.modules = this.modulesProp;
      this.modules = this.modules.filter((item) => {
        return item.visible;
      });
    },
    initOption() {
      this.slideOpts = {
        initialSlide: 0,
        speed: 400,
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
      };
    },

    initData() {
      const len = this.modules.length;
      if (len === 0) {
        this.slideNum = 0;
        return;
      }
      if (this.colNum <= 0) {
        this.slideNum = 0;
        return;
      }
      const mod = (len > this.colNum ? len : 0) % this.colNum;
      const c = parseInt((len / this.colNum).toString());
      this.slideNum = mod === 0 ? 1 : c + 1;
      for (let i = 0; i < this.slideNum; i++) {
        const row = [];
        for (let j = 0; j < this.colNum; j++) {
          const index = i * this.colNum + j;
          if (index + 1 <= this.modules.length) {
            const e = this.modules[index];
            row.push(e);
          }
        }
        this.pageItems.push(row);
        console.log(this.pageItems);
      }
    },
    bindModuleRouter(item) {
      //移除消息监听
      Bus.$off("receive_message_home");
      var menuList = localStorage.getItem("menuList");
      var list = menuList ? JSON.parse(menuList) : [];

      if (item.visible) {
        if (
          list.length > 0 &&
          list.some((e) => {
            return e.name == item.name;
          })
        ) {
          list.forEach((element) => {
            if (item.name == element.name) {
              element.num += 1;
            }
          });
        } else {
          item.num = 1;
          list.push(item);
        }
        var len = list.length;
        for (var i = 0; i < len - 1; i++) {
          for (var j = 0; j < len - 1 - i; j++) {
            // 相邻元素两两对比，元素交换，小的元素交换到后面
            if (list[j].num < list[j + 1].num) {
              var temp = list[j];
              list[j] = list[j + 1];
              list[j + 1] = temp;
            }
          }
        }
        localStorage.setItem("menuList", JSON.stringify(list));
      }
      this.router.push("/" + item.name);
    },
  },
});
</script>

<style lang="scss" scoped>
ion-slides {
  width: 100%;
  background: #{$ion-background};
  --bullet-background-active: #{$font-selected-color};
  margin-top: px2rem(15);
  ion-slide {
    height: 100%;
    ion-grid {
      height: 80%;
      --ion-grid-padding: 0;
      --ion-grid-width: 0;
      text-align: left;
      padding: 0;
      width: 100%;
      white-space: nowrap;

      ion-row {
        height: 100%;

        ion-col {
          height: 100%;
          text-align: center;
          --ion-grid-column-padding: 0;
        }
      }

      div.left-block {
        text-align: center;
        display: inline-block;
        height: 100%;
        margin-bottom: 27px;

        // ion-avatar {
        //   margin: 0 auto;
        //   width: px2rem(80px);
        //   height: px2rem(80px);
        //   line-height: px2rem(80px);
        //   text-align: center;
        //   background: #ffffff;
        //   .icon {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: center;
        ion-label {
          font-size: $navigation-font-size;
          margin-top: 10px;
        }
      }
    }
  }
}
.iconBox {
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 5px;
  display: table;
}
.iconfont {
  font-size: 30px;
  color: white;
  vertical-align: middle;
  display: table-cell;
}
.activated {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: -3px;
    top: -3px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: red;
  }
}
</style>
