
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import SubscribeCard from "../component/SubscribeCard.vue";
import { CalendarSubscribeApplyService } from "@/services/calendar/CalendarSubscribeApplyService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "SubscribeApprovaHistory",
  components: {
    SubscribeCard,
  },
  data() {
    const router = useRouter();
    return {
      mySubscribeService: new CalendarSubscribeApplyService(),
      router: router,
      canlendarList: new Array(0),
      searchParams: {
        limit: 10,
        offset: 1,
        searchKey: "",
      },
    };
  },
  props: {
    refresh: Boolean, //是否刷新页面
    params: Object, //搜索条件
  },
  created() {},
  mounted() {
    if (this.params != undefined && this.params.searchKey != undefined) {
      this.searchParams.searchKey = this.params.searchKey;
    }
    this.initData();
  },
  watch: {
    refresh() {
      if (this.refresh) {
        if (this.params != undefined && this.params.searchKey != undefined) {
          this.searchParams.searchKey = this.params.searchKey;
        }
        // this.searchParams.name=this.searchValue+"";
        this.resetPage();
      }
    },
  },
  methods: {
    initData() {
      this.resetPage();
    },
    //重新从第一页获取
    resetPage(event?: any) {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.canlendarList = res;
        if (event) {
          event.target.complete();
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        event.target.complete();
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            this.canlendarList = this.canlendarList.concat(result);
          }
        }
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    doRefresh(event: any) {
      this.resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //获取我订阅的日历
    getListRequest(callback: any) {
      this.mySubscribeService
        .getApplyRecord(this.searchParams)
        .then((res: any) => {
          if (res && res.data) {
            callback(res.data);
          }
        });
    },
  },
});
