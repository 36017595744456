import { ScheduleFeedbackApi } from '@/api/schedule/ScheduleFeedbackApi';

import BaseService from '../base/BaseService';

/**
 * 日历模块
 */
export class ScheduleFeedbackService extends BaseService {
    private static scheduleFeedbackApi = new ScheduleFeedbackApi();
      //更新日程信息--反馈
    public updateFieldsById(parms: any): any {
        return new Promise((resolve, reject) => {
            ScheduleFeedbackService.scheduleFeedbackApi.updateFieldsById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

}