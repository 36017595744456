import OkrBaseService from '../base/OkrBaseService';

export class ObjectiveApi extends OkrBaseService {
    constructor() {
        super("okr");
    }

    /**
     * 级别列表
     * @param param
     */
    public levelList(param: any): Promise<any> {
        const path = 'level/listByPermission';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 可用周期列表
     * @param param
     */
    public cycleList(): Promise<any> {
        const path = 'cycle/list';
        return this.request(path, this.rest.METHOD_GET, null);
    }
    /**
     * 可用维度列表
     * @param param
     */
    public categoryList(): Promise<any> {
        const path = 'category/list';
        return this.request(path, this.rest.METHOD_GET, null);
    }

    /**
     * 新建目标
     * @param param
     */
    public addObjective(param: any): Promise<any> {
        const path = 'objective/insert';
        return this.request(path, this.rest.METHOD_POST, param);
    }
    /**
     * 编辑目标
     * @param param
     */
    public editObjective(param: any): Promise<any> {
        const path = 'objective/update';
        return this.request(path, this.rest.METHOD_PUT, param);
    }
    /**
     * 编辑目标
     * @param param
     */
    public objectiveDetails(param: any): Promise<any> {
        const path = 'objective/getDetailById';
        return this.request(path, this.rest.METHOD_GET, param);
    }
    /**
     * 新建目标
     * @param param
     */
    public objectiveList(param: any): Promise<any> {
        const path = 'objective/list';
        return this.request(path, this.rest.METHOD_POST, param);
    }
    /**
     * 更新进度
     * @param param
     */
    public progressInsert(id: any): Promise<any> {
        const path = 'objective/progress/insert?id=' + id;
        return this.request(path, this.rest.METHOD_POST, {});
    }

    /**
     * 目标关联任务列表
     * @param param
     */
    public objectivelinkTaskList(param: any): Promise<any> {
        const path = 'linktask/list';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 批量添加目标关联任务
     * @param param
     */
    public objectiveAddLinkTaskList(param: any, sourceId?: string): Promise<any> {
        let path = 'linktask/batchSave';
        if (sourceId) {
            path = path + "?sourceId=" + sourceId
        }
        return this.request(path, this.rest.METHOD_POST, param);
    }
    /**
    * 结束目标
    * @param param
    */
    public updateFieldsById(param: any): Promise<any> {
        const path = 'objective/updateFieldsById';
        return this.request(path, this.rest.METHOD_PUT, param);
    }

    /**
     * 取消目标关联任务
     * @param param
     */
    public cancelKeyTask(param: any): Promise<any> {
        const path = 'linktask/' + param.id;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }

    /**
     * 根据周期ID获取目标树结构
     * @param cycleId
     */
    public getObjectiveTree(cycleId: any): Promise<any> {
        const path = 'objective/tree?cycleId=' + cycleId;
        return this.request(path, this.rest.METHOD_GET, null);
    }

    /**
     * 获取回收站目标列表
     * @param cycleId
     */
    public getRecycleObjectiveList(params: any): Promise<any> {
        const path = 'objective/recycle/list';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 删除目标
     * @param cycleId
     */
    public delObjective(params: any): Promise<any> {
        const path = 'objective/clearById';
        return this.request(path, this.rest.METHOD_DELETE, params);
    }

    // 移至回收站
    public removeOrRestoreObjective(id: string, isDelete: boolean): Promise<any> {
        let path = '';
        if (isDelete) {
            path = "objective/restoreById?id=" + id;
            return this.request(path, this.rest.METHOD_PUT);
        } else {
            path = "objective/" + id;
            return this.request(path, this.rest.METHOD_DELETE);
        }
    }


}