/**
 * 文件
 */
import FileBaseService from '../base/FileBaseService';
/**
 * 首页
 */
export class DocumentApi extends FileBaseService {

    constructor() {
        super("documents");
    }

    /**
     * 根据文件夹获取文档列表信息
     * @param param folderId
     */
    public getDocumentsByFolderId(params: any): Promise<any> {
        const path = 'getDocumentsByFolderId';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 逗号分隔的id
     * @param ids 
     */
    public getFilesByIds(ids: string) {
        const path = 'getByIds';
        return this.request(path, this.rest.METHOD_GET, { ids: ids });
    }

    /**
     * 按Id进行物理删除
     * @param fileId 
     */
    public clearFileById(fileId: string): Promise<any> {
        const path = 'clearById?id=' + fileId;
        return this.request(path, this.rest.METHOD_DELETE, {});
    }

    /**
     * 按Id进行删除
     * @param fileId 
     */
    public deleteById(fileId: string): Promise<any> {
        const path = 'deleteById?id=' + fileId;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }

    /**
     * 项目-回收站-文件列表
     * */
    public getPagedRootDeletedDocuments(param: any) {
        return this.request("getPagedRootDeletedDocuments", this.rest.METHOD_GET, param);
    }

    /**
     * 项目-回收站-文件列表-还原
     * */
    public restoreDocumnetById(param: any) {
        return this.request("restoreDocumnetById?documentId=" + param.documentId + "&targetFolderId=" + param.targetFolderId, this.rest.METHOD_PUT, null);
    }


}