
import { defineComponent } from "vue";
import { LogService } from "@/services/log/LogService";
import moment from "moment";
import {
  modalController,
  IonPage,
  IonContent,
  alertController,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
// import SearchBar from "@/components/SearchBar.vue";

export default defineComponent({
  name: "OperationLog",
  components: {
    IonPage,
    HeaderModal,
    IonContent,
    // SearchBar,
  },
  props: {
    busId: String,
  },
  data() {
    return {
      logService: new LogService(),
      // recivesBusId: "",
      parameters: {
        fieldNames: "name,is_finished,start_time,end_time,remark,priority",
        tableName: "tt_task",
        nestedQuery: "",
        keyword: "",
        offset: 1,
        limit: 10,
      },
      list: [],
    };
  },
  mounted() {
    this.loadDataFromPageOne();
  },
  methods: {
    operateText(type: any) {
      var operatename = "";
      if (type == "INSERT") {
        operatename = "新增";
      } else if (type == "UPDATE") {
        operatename = "更新";
      } else if (type == "DELETE") {
        operatename = "删除";
      } else {
        operatename = "修改";
      }

      return operatename;
    },
    closeModel() {
      modalController.dismiss({});
    },
    //根据fieldName类型判断newValue的值所代表的含义 dinglf
    operateNewValus(fieldName: any, newValue: any) {
      //优先级
      if (fieldName == "priority") {
        if (newValue == "0") {
          return "较低";
        } else if (newValue == "1") {
          return "普通";
        } else if (newValue == "2") {
          return "紧急";
        } else if (newValue == "3") {
          return "非常紧急";
        }
      } else if (fieldName == "is_finished") {
        //是否完成
        if (newValue == "0") {
          return "未完成";
        } else if (newValue == "1") {
          return "已完成";
        }
      } else {
        //其他
        return newValue;
      }
    },
    //测试要求改为yyyy-mm-dd hh:mm:ss显示
    formatDate(element: any) {
      var dateTime = new Date(element.replace(/-/g, "/"));
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = "刚刚";
      } else if (
        1000 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟前";
      } else if (
        1000 * 60 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时前";
      } else if (
        1000 * 60 * 60 * 24 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24 * 15
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天前";
      } else if (
        milliseconds > 1000 * 60 * 60 * 24 * 15 &&
        year == now.getFullYear()
      ) {
        // timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
        timeSpanStr = year + "-" + month + "-" + day;
      } else {
        // timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
        timeSpanStr = year + "-" + month + "-" + day;
      }

      return timeSpanStr;

      // moment.locale("zh-cn");
      // console.log(
      //   moment("20210113 09:50:30", "YYYYMMDD hh:mm:ss")
      //     .startOf("hour")
      //     .fromNow()
      // );
      // console.log(moment("2021-01-13").startOf("day").fromNow());
      // //  let elementF= moment(element).format("YYYY-MM-DD HH:mm");

      // // return moment(element).startOf('hour').fromNow();
      // return moment("2021-01-13 09:00:00").startOf("day").fromNow();
    },
    loadDataFromPageOne() {
      //不动态修改，会导致nestedQuery每次刷新都叠加data.id:
      this.parameters.nestedQuery = this.busId as string;
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.loadDataFromPageOne();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.logService.getList(this.parameters).then((result: any) => {
        callback(result);
      });
    },
  },
});
