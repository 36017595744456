
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import Header from "@/components/Header.vue";
import AvatarList from "@/components/AvatarList.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { KrScoreLogService } from "@/services/okr/KrScoreLogService.ts";

export default defineComponent({
  name: "ScoreDetail",
  components: { Header, AvatarList, CardLoading },
  props: {
    taskId: String,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      useRoute: useRoute(),
      krScoreLogService: new KrScoreLogService(),
      islist: true,
      title: "",
      oscore:"0",
      cycleName:"",
      scoreList: new Array(),
      oscoreId: "",
    };
  },
  created() {
    if (this.useRoute.params && this.useRoute.params.oscoreId) {
      const oscoreIdParam = this.useRoute.params.oscoreId as string;
      const titleParam = this.useRoute.params.title as string;
      this.cycleName=this.useRoute.params.cycleName as string;
      this.oscore=this.useRoute.params.oscore as string;
      this.oscoreId = oscoreIdParam;
      this.title = titleParam;
      this.initData();
    }
  },
  methods: {
    initData() {
      this.resetPage();
    },
    //重新获取
    resetPage() {
      this.getListRequest((res: any) => {
        this.scoreList = res;
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    getListRequest(callback: any) {
      let params = { oscoreId: this.oscoreId };
      this.krScoreLogService
        .getKrListByOscoreId(params)
        .then((res: any) => {
          this.islist = false;
          res.data.forEach((ele: any) => {
            let fzrList = ele.details.filter((dele: any) => {
              return dele.type == 2;
            });
            if (fzrList.length == 1) {
              ele.quanzhong = fzrList[0].krWeight;
            } else {
              let selfList = ele.details.filter((dele: any) => {
                return dele.type == 1;
              });
              if (selfList.length == 1) {
                ele.quanzhong = selfList[0].krWeight;
              } else {
                ele.quanzhong = "--";
              }
            }
          });
          callback(res.data);
        })
        .catch(() => {
          this.islist = false;
        });
      /*
      let res = [
        {
          id: "1",
          quanzhong: "50%",
          title: "完成日程、团队、消息模块开发",
          selfScoreTime: "2020-12-31 13:25",
          selfUserId: "123",
          selfUserName: "高世红",
          selfScore: 4.0,
          selfInfo: "完成了日程、团队、消息模块开发",
          managerScoreTime: "2020-12-31 13:25",
          managerUserId: "456",
          managerUserName: "刘云",
          managerScore: 3.5,
          managerInfo: "完成了日程、团队、消息模块开发",
        },
        {
          id: "2",
          quanzhong: "50%",
          title: "完成系统集成测试",
          selfScoreTime: "2020-12-31 13:25",
          selfUserId: "123",
          selfUserName: "高世红",
          selfScore: 4.3,
          selfInfo: "完成了日程、团队、消息模块开发",
          managerScoreTime: "2020-12-31 13:25",
          managerUserId: "456",
          managerUserName: "刘云",
          managerScore: 3.8,
          managerInfo: "完成了日程、团队、消息模块开发",
        },
        {
          id: "3",
          quanzhong: "50%",
          title: "完成任务、项目模块开发",
          selfScoreTime: "2020-12-31 13:25",
          selfUserId: "123",
          selfUserName: "高世红",
          selfScore: 4.2,
          selfInfo: "完成了任务、项目模块开发",
          selfUserPhoto: "",
          managerScoreTime: "2020-12-31 13:25",
          managerUserId: "456",
          managerUserName: "刘云",
          managerScore: 5.0,
          managerInfo: "完成了任务、项目模块开发",
          managerUserPhoto: "",
        },
      ];
      callback(res);*/
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = {
        name: item.creator,
        img: "",
        id: item.creatorId,
      };
      users.push(obj);
      return users;
    },
  },
});
