import { HomeModuleApi } from '@/api/home/HomeModuleApi';
import Setting from '@/rest/Setting';
import Utils from '@/utils/Utils';
import { HmModule, HmModulePersonal, moduleGroup } from './model/HmModule';

export default class HmModuleService {

    private api = new HomeModuleApi();

    public getAllModoules(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAll().then(async (res: any) => {
                const formatModules: moduleGroup[] = [];
                res.data.map((e: any) => {
                    if (Setting.isSVW) {
                        //项目的话不显示
                        if (e.name != "项目") {
                            formatModules.push(new moduleGroup(e));
                        }
                    } else {
                        formatModules.push(new moduleGroup(e));
                    }
                })
                resolve(formatModules);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getMyModules(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMyModules().then(async (res: any) => {
                const formatModules: HmModule[] = [];
                res.data.map((e: any) => {
                    const isCondition = e.conditonId ? true : false;
                    if (Setting.isSVW) {
                        if (e.id != "task.leader" && e.id != "task.noreation" && e.id != "task.subordinate"
                            && e.type != 2) {
                            formatModules.push(new HmModule(isCondition ? e.conditonId : e.moduleId, e.moduleName, isCondition ? 2 : 1, "", e.pagePath));
                        }
                    } else {
                        formatModules.push(new HmModule(isCondition ? e.conditonId : e.moduleId, e.moduleName, isCondition ? 2 : 1, "", e.pagePath));
                    }
                });
                localStorage.removeItem('myHomeModules');
                localStorage.setItem("myHomeModules", JSON.stringify(formatModules));
                resolve(formatModules);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public saveMyModules(datas: HmModule[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const newDatas: HmModulePersonal[] = []
            datas.map((d) => {
                newDatas.push(new HmModulePersonal(d.type, d.id));
            });
            if (newDatas.length == 0) {
                Utils.presentToast("请至少选择一个模块");
                return;
            }
            this.api.saveModules(newDatas).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                Utils.presentToastWarning("保存失败");
                reject(err);
            });
        });
    }
}