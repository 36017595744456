
import { IonPage, modalController } from "@ionic/vue";
import { defineComponent } from "vue";

//组件
import moment from "moment";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import SearchBar from "@/components/SearchBar.vue";
import SubscribeCard from "../component/SubscribeCard.vue";
import { CalendarService } from "@/services/calendar/CalendarService";
export default defineComponent({
  name: "AddCalendarOther",
  components: {
    HeaderModal,
    SearchBar,
    SubscribeCard,
  },
  props: {
    userIds: Array,
  },
  data() {
    let userIdArr: any;
    if (this.userIds && this.userIds != null) {
      userIdArr = this.userIds;
    }
    return {
      calendarService: new CalendarService(),
      canlendarList: new Array(),
      searchParams: {
        limit: 10,
        offset: 1,
        calName: "",
        workUserId: userIdArr,
      },
      selectArr: new Array<any>(),
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.resetPage();
    },
    saveHandler(type: any) {
      console.log(this.selectArr);
      if (this.selectArr.length > 0 && type == 4) {
        modalController.dismiss({ saveList: this.selectArr });
      } else {
        modalController.dismiss();
      }
    },
    //搜索
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchParams.calName = searchParam.searchKey;
      this.resetPage();
    },
    //重新从第一页获取
    resetPage(event?: any) {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.canlendarList = res;
        if (event) {
          event.target.complete();
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        event.target.complete();
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            this.canlendarList = this.canlendarList.concat(result);
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.calendarService
        .getCalListByUserAndWorkUser(this.searchParams)
        .then((res: any) => {
          if (res && res.data) {
            callback(res.data);
          }
        });
    },
    //是否日程显示
    onCheck(res: any) {
      if (res && res.item) {
        const item = res.item;
        //选中
        if (res.checked) {
          this.selectArr.push({
            calId: item.calId,
            workUserId: item.workUserId,
          });
        } else {
          //取消选中
          let newArr = this.selectArr.concat(new Array(0));
          for (let i = 0; i < newArr.length; i++) {
            if (
              this.selectArr[i].calId == item.calId &&
              this.selectArr[i].workUserId == item.workUserId
            ) {
              this.selectArr.splice(i, 1);
              break;
            }
          }
          console.log(this.selectArr);
        }
      }
    },
  },
});
