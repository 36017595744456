import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dataList" }
const _hoisted_2 = {
  key: 0,
  class: "projectRecycleList"
}
const _hoisted_3 = { class: "title ellipsis" }
const _hoisted_4 = { class: "center" }
const _hoisted_5 = { class: "imgs" }
const _hoisted_6 = {
  key: 1,
  class: "no_data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")
  const _component_ion_refresher = _resolveComponent("ion-refresher")
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")
  const _component_ion_content = _resolveComponent("ion-content")

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content)
        ]),
        _: 1
      }),
      _createVNode("div", _hoisted_1, [
        (_ctx.taskList.length > 0)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.taskList, (item, index) => {
                return (_openBlock(), _createBlock("div", {
                  class: "item",
                  key: index
                }, [
                  _createVNode("div", {
                    class: "top",
                    onClick: ($event: any) => (_ctx.openTaskInfo(item))
                  }, [
                    _createVNode("p", _hoisted_3, _toDisplayString(item.name), 1)
                  ], 8, ["onClick"]),
                  _createVNode("div", _hoisted_4, [
                    _createVNode("p", {
                      class: "time",
                      onClick: ($event: any) => (_ctx.openTaskInfo(item))
                    }, _toDisplayString(_ctx.formatTime(item.modifyTime)), 9, ["onClick"]),
                    _createVNode("p", _hoisted_5, [
                      (
                  _ctx.getTaskRolePermission(
                    item.creatorId,
                    item.taskUser,
                    _ctx.isHasPermission.isHasPutArchive,
                    'taskpermission.put.archive'
                  )
                )
                        ? (_openBlock(), _createBlock("span", {
                            key: 0,
                            class: "iconfont icon63huanyuan",
                            onClick: ($event: any) => (_ctx.restoreById(item.id))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (
                  _ctx.getTaskRolePermission(
                    item.creatorId,
                    item.taskUser,
                    _ctx.isHasPermission.isHasDel,
                    'taskpermission.del'
                  )
                )
                        ? (_openBlock(), _createBlock("span", {
                            key: 1,
                            class: "iconfont iconshanchujieduan",
                            onClick: ($event: any) => (_ctx.clearById(item.id))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("", true),
        (_ctx.taskList.length == 0)
          ? (_openBlock(), _createBlock("div", _hoisted_6, "暂无数据"))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ion_infinite_scroll, {
        threshold: "50px",
        onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nextPage($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_infinite_scroll_content, { loadingSpinner: "bubbles" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}