

import { HelpGuideApi } from '@/api/helpguide/HelpGuideApi';
import BaseService from '../base/BaseService';
/**
 * 项目，任务模块：任务分组
 */
export class HelpGuideService extends BaseService {

    private api = new HelpGuideApi();
   
     /**
     * 使用说明列表
     * @param param 
     */
    public getHelpGuideList(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.getHelpGuideList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


}