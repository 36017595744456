<template>
  <ion-menu side="end" menu-id="dashboardMenu" class="my-custom-menu" content-id="Dashboard">
    <ion-header class="theme2">
      <ion-toolbar>
        <p slot="start">
          <span class="iconfont iconrenwushaixuan"></span>&nbsp;数据筛选
        </p>
        <ion-buttons slot="end" class="close">
          <ion-button @click="onClose()">
            <span class="iconfont iconguanbi"></span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content id="SearchPanel">
      <div class="PanelBox">
        <div class="item">
          <div class="content dashboard-one">
            <div class="projectserach">
              <SearchBar :isAudio="false" theme="1" @on-enter="searchHandler" placeholder="项目名称"></SearchBar>
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">项目状态</ion-text>
          <div class="content dashboard-one">
            <span v-for="(item, index) in lamps" :key="index" class="traffic-light" :class="[item.color,index === status ? 'traffic-active' : '']" @click="lampChange(item.color,index)"></span>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">品牌</ion-text>
          <div class="content dashboard-one">
            <div class="labelBtn" :class="index == brand ? 'active' : ''" @click="handleItem(item, index, 1)" v-for="(item, index) in brandList" :key="index">
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">车身平台</ion-text>
          <div class="content dashboard-one">
            <div class="labelBtn" :class="index == platform ? 'active' : ''" @click="handleItem(item, index, 2)" v-for="(item, index) in platformList" :key="index">
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">类型</ion-text>
          <div class="content dashboard-one">
            <div class="labelBtn" :class="index == type ? 'active' : ''" @click="handleItem(item, index, 3)" v-for="(item, index) in projectTypeList" :key="index">
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">阶段</ion-text>
          <div class="content dashboard-one">
            <div class="labelBtn" :class="index == milestone ? 'active' : ''" @click="handleItem(item, index, 4)" v-for="(item, index) in milestoneList" :key="index">
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">动力总成</ion-text>
          <div class="content dashboard-one">
            <div class="labelBtn" :class="index == power ? 'active' : ''" @click="handleItem(item, index, 5)" v-for="(item, index) in projectPowerTypeList" :key="index">
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="item">
          <ion-text class="label">结构</ion-text>
          <div class="content dashboard-one">
            <div class="labelBtn" :class="index == netPlat ? 'active' : ''" @click="handleItem(item, index, 6)" v-for="(item, index) in netPlattformList" :key="index">
              {{ item.itemText }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="content Btn2">
            <ion-button color="warning" @click="onSearch">确定</ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script>
import { menuController } from "@ionic/vue";
import { defineComponent } from "vue";
import { DashboardService } from "@/services/dashboardService";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "../../../utils/Utils";

export default defineComponent({
  name: "SearchPanel",
  components: {
    SearchBar
  },
  props: {
    searchKey: {
      type: String,
      required: false,
      default: "",
    },
  },
  watch: {
    searchKey(newVal) {
      if (newVal == undefined) {
        newVal = "";
      }
      this.onSearch();
    },
  },
  data() {
    return {
      api: new DashboardService(),
      projectName: "",
      projectState: "",
      menuObj: {},
      brand: "",
      platform: "",
      type: "",
      milestone: "",
      power: "",
      netPlat: "",
      projectBrand: "",
      projectCartypePlatform: "",
      projectType: "",
      projectPeriod: "",
      projectPowerType: "",
      netPlattform: "",
      brandList: [],
      platformList: [
        { itemText: "alle", itemValue: "" },
        { itemText: "PQ", itemValue: "PQ" },
        { itemText: "MEB", itemValue: "MEB" },
        { itemText: "MLB", itemValue: "MLB" },
        { itemText: "MQB", itemValue: "MQB" },
      ],
      projectTypeList: [],
      milestoneList: [],
      projectPowerTypeList: [],
      netPlattformList: [],
      lamps: [
        { name: "green", color: "green", opacity: 0.2 },
        { name: "yellow", color: "yellow", opacity: 0.2 },
        { name: "redYellow", color: "redYellow", opacity: 0.2 },
        { name: "red", color: "red", opacity: 0.2 },
        { name: "gray", color: "gray", opacity: 0.2 }
      ],
      status: ""
    };
  },
  created() {
    this.menuObj.openMenu = this.openMenu;
  },
  methods: {
    // 状态灯改变
    lampChange(val, index) {
      this.projectState = val;
      this.status = index;
    },
    //搜索框查询
    searchHandler(searchParam) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.projectName = searchParam.searchKey;
      this.onSearch();
    },
    onClose() {
      menuController.close();
    },
    openMenu() {
      menuController.enable(true, "dashboardMenu");
      menuController.open("dashboardMenu");
    },
    onSearch() {
      const params = {
        projectName: this.projectName,
        projectState: this.projectState,
        brand: this.projectBrand,
        projectCartypePlatform: this.projectCartypePlatform,
        projectType: this.projectType,
        projectPeriod: this.projectPeriod,
        projectPowerType: this.projectPowerType,
        netPlattform: this.netPlattform,
      };
      this.$emit("on-search", params);
      menuController.close();
    },
    handleItem(item, index, num) {
      switch (num) {
        case 1:
          this.brand = index;
          this.projectBrand = item.itemText == "alle" ? "" : item.itemValue;
          break;
        case 2:
          this.platform = index;
          this.projectCartypePlatform =
            item.itemText == "alle" ? "" : item.itemValue;
          break;
        case 3:
          this.type = index;
          this.projectType = item.itemText == "alle" ? "" : item.itemValue;
          break;
        case 4:
          this.milestone = index;
          this.projectPeriod = item.itemText == "alle" ? "" : item.itemValue;
          break;
        case 5:
          this.power = index;
          this.projectPowerType = item.itemText == "alle" ? "" : item.itemValue;
          break;
        case 6:
          this.netPlat = index;
          this.netPlattform = item.itemText == "alle" ? "" : item.itemValue;
          break;
      }
    },
    getDictData() {
      this.api.getMasterData({ dictCode: "project_brand" }).then((res) => {
        if (res.data.data) {
          this.brandList = res.data.data.filter((item) => {
            return item.hiddenOption == false;
          });
        }
        this.brandList.unshift({
          itemText: "alle",
          itemValue: "",
        });
      });

      this.api.getMasterData({ dictCode: "project_type" }).then((res) => {
        if (res.data.data) {
          this.projectTypeList = res.data.data.filter((item) => {
            return item.hiddenOption == false;
          });
        }
        this.projectTypeList.unshift({
          itemText: "alle",
          itemValue: "",
        });
      });
      this.api.getMilestoneNames({ dictCode: "milestone" }).then((res) => {
        if (res.data.data) {
          res.data.data.map((item) => {
            this.milestoneList.push({ itemText: item, itemValue: item });
          });
        }
        this.milestoneList.unshift({
          itemText: "alle",
          itemValue: "",
        });
      });
      this.api.getMasterData({ dictCode: "project_power_type" }).then((res) => {
        if (res.data.data) {
          this.projectPowerTypeList = res.data.data.filter((item) => {
            return item.hiddenOption == false;
          });
        }
        this.projectPowerTypeList.unshift({
          itemText: "alle",
          itemValue: "",
        });
      });
      this.api.getMasterData({ dictCode: "project_body_net_platform" }).then((res) => {
        if (res.data.data) {
          this.netPlattformList = res.data.data.filter((item) => {
            return item.hiddenOption == false;
          });
        }
        this.netPlattformList.unshift({
          itemText: "alle",
          itemValue: "",
        });
      });
    },
  },
  mounted() {
    this.getDictData();
  },
});
</script>
<style lang="scss" scoped>
@import "@/assets/css/searchPanel";
@import "../dashboard.scss";
</style>