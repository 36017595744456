
import { defineComponent } from "vue";
import { isPlatform, modalController } from "@ionic/vue";
import { TaskExecuteService } from "@/services/task/TaskExecuteService";
import moment from "moment";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import AddExecute from "./AddExecute.vue";
export default defineComponent({
  name: "ExecuteList",
  components: { AddExecute },
  props: {
    taskId: String,
    folderId: String,
    showAdd: Boolean,
  },
  data() {
    return {
      user: {},
      taskExecuteApi: new TaskExecuteService(),
      taskExecuteParams: {
        offset: 1,
        limit: 10,
        taskId: this.taskId,
      },
      executeCaseList: new Array(),
      showNoDate: false, //展示暂无数据
    };
  },
  mounted() {
    this.initData();
    this.user = SystemUtils.loginUser;
  },

  methods: {
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    initData() {
      this.taskExecuteParams.offset = 1;
      this.requsetData(this.taskExecuteParams, null);
    },

    doRefresh(event: any) {
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },

    nextPage(event: any) {
      this.taskExecuteParams.offset++;
      this.requsetData(this.taskExecuteParams, event);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    requsetData(pamas: any, event: any) {
      this.taskExecuteApi.getTaskExecuteList(pamas).then((r: any) => {
        if (r) {
          const result = r;
          if (result == null || result.length <= 0) {
            if (event) {
              event.target.disabled = true;
            } else {
              this.executeCaseList = result;
            }
          } else {
            if (event != null) {
              this.executeCaseList = this.executeCaseList.concat(result);
            } else {
              this.executeCaseList = result;
            }
            setTimeout(() => {
              if (event) {
                event.target.complete();
              }
            }, 500);
          }
        } else {
          if (event != null) {
            this.executeCaseList = [];
          }
        }
      });
    },
    async addTaskHandler() {
      const modal = await modalController.create({
        component: AddExecute,
        cssClass: "task-progress-update-class",
        componentProps: {
          taskId: this.taskId,
          folderId: this.folderId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.isrefresh) {
        this.initData();
      }
    },
  },
});
