import Setting from '@/rest/Setting';
import CoreService from './CoreService';

export default class OkrBaseService extends CoreService {
    constructor(resource: string, version?: string) {
        super({
            name: Setting.apiBaseServiceName.okrservice,
            resource: resource
        });
    }
}