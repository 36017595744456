import { ProjectApi } from '@/api/project/ProjectApi';
import { TaskApi } from '@/api/task/TaskApi';
import { TaskLinkApi } from '@/api/task/TaskLinkApi';
import { TaskLinkContentType } from '@/models/task/Enum';
import BaseService from '../base/BaseService';

export class TaskLinkContentService extends BaseService{

    private projectApi = new ProjectApi();
    private taskApi=new TaskApi();
    private taskLinkApi=new TaskLinkApi();


    /**
     * 获取可连接的项目列表
     * @param param 
     */
    public getToLinkProjectList(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.projectApi.getProjectListByUser(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取可关联的任务
     * @param param 
     */
    public getToLinkTaskList(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.taskApi.getByProjectIdAndVisibility(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取项目任务的可关联的任务
     * @param param 
     */
    public getByUserIdAndVisibilityApp(param: any): Promise <any> {
        return new Promise((resolve, reject) => {
            this.taskApi.getByUserIdAndVisibilityApp(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public addLink(dataList: any): Promise <any> {
        return new Promise((resolve, reject) => {
            this.taskLinkApi.insertList(dataList).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public getByTaskId(taskId: string): Promise <any> {
        return new Promise((resolve, reject) => {
            this.taskLinkApi.getByTaskId(taskId).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
   
}