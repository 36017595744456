import Setting from '@/rest/Setting';
import CoreService from './CoreService';

export default class TaskBaseService extends CoreService {
    constructor(resource: string, version?: string) {
        super({
            name: Setting.apiBaseServiceName.taskservice,
            resource: resource
        });
    }
    //企业id
    public enterpriseId = Setting.currentEnterprise.id;
    //更新实体
    public updateFieldsById(param: any): Promise<any> {
        const path = 'updateFieldsById';
        return this.request(path, this.rest.METHOD_PUT, param);
    }
    //删除实体 逻辑删除
    public clearById(id: any): Promise<any> {
        const path = id;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }
}