<template>
  <ion-page>
    <span
      class="iconfont iconguanbi close-icon"
      @click="confirm"
    ></span>
    <ion-content class="ion-padding" id="MilestoneSlide">
      <div
        class="common-content"
        v-for="(item, index) in checkpointStatesDatas"
        :key="index"
      >
        <div class="common-flex">
          <div class="round">
            <span
              class="circle"
              :class="item.state || 'white'"
            ></span>
            <h2 class="description">{{ item.description }}</h2>
          </div>
        </div>
        <div class="common-flex">
          <div class="date m-t17">
            {{ item.scope }}
          </div>
        </div>
        <div class="common-flex sort-light" >
          <div class="round m-r10 m-t17" v-for="(value, index) in item.list" :key="index">
            <span class="plan m-r10">{{ value.name }}</span>
            <span
              class="circle"
              :class="value.state || 'white'"
            ></span>
          </div>
        </div>
      </div>
      <!-- 无数据 -->
      <div class="no_data" v-if="empty">暂无数据</div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { DashboardService } from "@/services/dashboardService";
export default defineComponent({
  name: "MilestoneSlide",
  components: { IonPage },
  props: {
    name: String,
    projectId: String,
    projectPeriod: String,
  },
  data() {
    return {
      api: new DashboardService(),
      checkpointStatesDatas: new Array(0),
      empty: false
    };
  },
  methods: {
    confirm() {
      modalController.dismiss({});
    },
    getCheckpointsStates() {
      const params = {
        milestone: this.projectPeriod,
        name: this.name,
        projectId: this.projectId,
      };
      this.api.getCheckpointsStates(params).then((res) => {
        if (res.data.data && res.data.data.data && !res.data.data.data.length) {
          this.empty = true;
        } else {
          this.empty = false;
        }
        if(res.data.data && res.data.data.data) {
          this.checkpointStatesDatas = res.data.data.data;
        }
        this.dealWithData();
      });
    },
    dealWithData() {
      const arr = this.checkpointStatesDatas;
      arr.forEach((item) => {
        item.list = [];
      });
      for (let i = 0; i < arr.length; i++) {
        for (const value in arr[i]) {
          if (value.indexOf("key") > -1) {
            arr[i].list.push({ state: arr[i][value] });
          }
        }
        arr[i].list.forEach((item, index) => {
          item.name = "第" + (index + 1) + "轮";
        });
      }
    },
  },
  mounted() {
    this.getCheckpointsStates();
  },
});
</script>
<style lang="scss" scoped>
@import "@/views/dashboard/dashboard";

.common-content {
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.2);
  width: 100%;
  .date {
    margin-left: px2rem(30);
    width: px2rem(580);
  }
}
</style>