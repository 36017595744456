export default class LangHelper {
    public static lang() {
        let lang = "cn";
        if (localStorage.getItem("localLang")) {
            lang = localStorage.getItem("localLang") + "";
        } else {
            lang = navigator.language; //浏览器语言判断
            lang = lang.substr(0, 2);
            switch (lang) {
                case 'zh': //中文
                    lang = 'cn';
                    break;
                case 'en': //英文
                    lang = 'en';
                    break;
                case 'de': //英文
                    lang = 'de';
                    break;
                default:
                    lang = 'cn';
            }
            localStorage.setItem("localLang",lang);
        }
        return lang;
    }

}