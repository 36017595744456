export interface Folder {
    name: string,
    rootId: string,
    parentId: string,
    busId: string,
    busTable: string,
    busTag: string,
    allowRepeat: string,
    batchError: string,
    batchId: string,
    className: string,
    color: string,
    dataState: number,
    isHide: string,
    isParentDeleted: string,
    permisionId: string,
    modelId: string,
    mongoId: string,
    setFields: Array<string>,
}

export function FoleModel(): Folder {
    return {
        name: "",
        rootId: "",
        parentId: "",
        busId: "",
        busTable: "",
        busTag: "",
        //默认参数
        allowRepeat: "",
        className: "folder",
        color: "",
        dataState: -1,
        isHide: "",
        isParentDeleted: "",
        permisionId: "",
        //多余参数
        batchError: "",
        batchId: "",
        modelId: "",
        mongoId: "",
        setFields: new Array()
    }
}

export interface FolderParams {
    name: string,
    rootId: string,//根目录不传
    parentId: string,//根目录不传
    busId: string,
    busTable: string,
    busTag: string,
    id?: string
}

export interface SubFolder {
    parentId: string,
    name: string,
    busId: string,
    busTable: string,
    busTag: string,
}

export interface RootFolder {
    name: string,
    busId: string,
    busTable: string,
    busTag: string,
}