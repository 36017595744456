
import { defineComponent } from "vue";
import { alertController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { TagLinkService } from "@/services/TagLinkService";
import { ProjectPageService } from "@/services/projectPageService";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "tagList",
  components: {},
  props: {
    projectId: String,
    isHasPermission:  {
      type: Object,
      required: false,
      default: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
      },
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    return {
      tagList: new Array(0),
      tagLinkService: new TagLinkService(),
      projectPageService: new ProjectPageService(),
      router: router,
      useRoute: useRoute(),
      searchParams: _param,
      groupId: "",
    };
  },
  mounted() {
    let that = this;
    this.getProjectInfo(this.projectId + "", function () {
      that.onInit();
    });
  },
  methods: {
    getProjectInfo(id: string, callback: any) {
      const parmsStatus = {
        id: id,
      };
      let that = this;
      this.projectPageService.getProjectInfoById(parmsStatus).then(
        (r: any) => {
          if (r && r.data && r.data[0]) {
            this.groupId = r.data[0].tagGroupId;
            callback();
          } else {
            // console.log("无项目信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    onInit() {
      this.resetSearchParam();
      this.resetPage();
    },
    resetSearchParam() {
      this.searchParams = {
        limit: 20,
        offset: 1,
        groupId: this.groupId,
      };
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        if (res == null || res.length <= 0) {
          this.tagList = [];
        } else {
          this.tagList = res;
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.tagList = this.tagList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
          //  event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.tagLinkService
        .getDeletedByGroupId(this.searchParams)
        .then((res: any) => {
          callback(res);
        });
    },
    //删除
    async clearById(id: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要把该标签删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                id: id,
              };
              this.tagLinkService.clearById(params).then((res: any) => {
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    //还原
    async restoreById(id: any) {
      const alert = await alertController.create({
        header: "还原",
        message: "您确定要把该标签还原吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                id: id,
              };
              this.tagLinkService.restoreById(params).then((res: any) => {
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    formatTime(modifyTime: any) {
      return SystemUtils.fromatTime(modifyTime);
    },
  },
});
