<template>
<!-- 项目里的任务卡片 -->
  <div> 
         <!-- cpt --> 
      <div v-show="TackStageObj1.priority==0||TackStageObj1.priority == 1||TackStageObj1.priority == 2||TackStageObj1.priority == 3" :class="{
         'fab lower': TackStageObj1.priority == 0,
          'fab general':TackStageObj1.priority == 1,
          'fab urgency':TackStageObj1.priority == 2,
          'fab very_urgency': TackStageObj1.priority == 3,
        }" ><span class="iconfont iconjiaobiao"></span></div>

    <div class="start">
      <ion-checkbox  :checked="TackStageObj1.isFinished"  :disabled="TackStageObj1.isFinished?true:false"
        @ionChange="checkHandler($event)"></ion-checkbox>
    </div>
    <div :class="TackStageObj1.isFinished? 'center already' : 'center'">
      <h2>{{ TackStageObj.name }}</h2> 
      <h3 class="timeContent" >
        <p v-show="time"  :class="timeStatusClass">{{timeFormat() }}</p>
      </h3>
    </div>
    <div class="end">
      <div class="user" v-for="(TaskObj, index) in TackStageObj.taskUser" :key="index">
        <p class="name"  v-if="index<2" >
          <span >{{
            TaskObj.name.length >= 3
              ? TaskObj.name.substring(TaskObj.name.length - 2)
              : TaskObj.name
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script >
//时间格式化
import moment from "moment";
import { IonCheckbox } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "TaskStageCard",
  components: { IonCheckbox },
  props: {
    TackStageObj: Object,
  },
  data() {
    return {
      timeStatusClass:"",//lower 还早 general正常 urgency即将截止 very_urgency逾期 ngBg
      time:"",
     TackStageObj1:this.TackStageObj,
     startYear:"",
     endYear:"",
    };
  },
  created() {
     this.timeFormat()
     this.timeBg()
  },
   methods: {
     checkHandler(e) {
       if(e.detail.checked){
          this.TackStageObj1.isFinished=e.detail.checked
            this.timeStatusClass='noBg';
       }
       
    },
     timeFormat(){
       if (this.TackStageObj) { 
         const startTime =moment(this.TackStageObj.startTime).format('YYYY-MM-DD HH:mm')
         const endTime =moment(this.TackStageObj.endTime).format('YYYY-MM-DD HH:mm')

         const startY=moment(this.TackStageObj.startTime).format('YYYY')
         const endY=moment(this.TackStageObj.endTime).format('YYYY')

         const startM =moment(this.TackStageObj.startTime).format('MM')
         const endM =moment(this.TackStageObj.endTime).format('MM')

         const startD =moment(this.TackStageObj.startTime).format('DD')
         const endD =moment(this.TackStageObj.endTime).format('DD')

         const startH =moment(this.TackStageObj.startTime).format('HH:mm')
         const endH =moment(this.TackStageObj.endTime).format('HH:mm');

         const nowY=new Date().getFullYear()

         if(startY!=nowY&&startY!="Invalid date"){this.startYear=startY+"年"}
         if(endY!=nowY&&endY!="Invalid date"){ this.endYear=endY+"年"} 
         if(startTime=="Invalid date"&&endTime!="Invalid date"){//只有结束时间
            this.time=this.endYear+endM+"月"+endD+'日'+endH+" 截至";
         } else if(endTime=="Invalid date"&&startTime!="Invalid date"){//只有开始时间
            this.time=this.startYear+startM+"月"+startD+'日'+startH+" 开始";
         } else if(startTime==endTime&&startTime!="Invalid date"&&endTime!="Invalid date"){ //同一天
            this.time=this.startYear+startM+"月"+startD+'日'+ startH+"-"+endH 
         } else if(startTime!=endTime&&startTime!="Invalid date"&&endTime!="Invalid date"){//不为一天
            this.time=this.startYear+startM+"月"+startD+'日'+"-"+this.endYear+endM+"月"+endD+'日' 
         }
         this.TackStageObj1.time=this.time
         //解决 数据改变后未更新格式的问题 gaozhq 2020年11月30日10:33:49
         return this.time;
       }

    },

    timeBg(){  
      if (this.TackStageObj) { 
         let endStr = moment(this.TackStageObj.endTime).format('YYYY-MM-DD HH:mm')
         endStr=endStr .replace(/-/g, '/'); 
         const d=new Date(endStr).getTime() -new Date().getTime() 
        const difference= Math.floor(d / (24 * 3600 * 1000));
        //  console.log(difference); 
        
          if(difference>7){
              this.timeStatusClass='lower_bg' //大于7天 33117529 
          }
          if(1<difference<7){ 

              this.timeStatusClass='general_bg' //7天内

          }else if(0==difference){

              this.timeStatusClass='urgency_bg' //当天 
               
          }else if(difference<0){

             this.timeStatusClass='very_urgency_bg' //逾期
          }
          if(this.TackStageObj1.isfinish){
            this.timeStatusClass='noBg' 
          }
          this.TackStageObj1.timeStatusClass=this.timeStatusClass 
       
      }
    }
   }
   
});
</script>
