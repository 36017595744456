import { ProjectStatusChangesApi } from '@/api/project/ProjectStatusChangesApi';
import BaseService from '../base/BaseService';
/**
 * 项目列表模块
 */
export class ProjectStatusChangesService extends BaseService {
    private api = new ProjectStatusChangesApi();
    //获取项目状态
    public getByProjectIdForLsit(projectId: string): any {
        return new Promise((resolve, reject) => {
            this.api.getByProjectIdForLsit({ projectId: projectId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
}
