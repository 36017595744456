
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "selectCycle",
  components: {
    HeaderModal,
  },
  data() {
    return {
      idList: new Array(0),
    };
  },
  props: {
    data: Object,
    // querylist: Array(),
    // selectList: Array(),
  },
  mounted() {
    if (this.data) {
      let list = this.data.selectList;
      for (let index = 0; index < list.length; index++) {
        this.idList.push(list[index]);
      }
    }
  },
  methods: {
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        const newItem = {
          cycleId: item.id,
          cycleName: item.name,
          cycleEndDate: item.endDate,
          cycleStartDate: item.startDate,
        };
        this.idList.push(newItem);
      } else {
        this.idList = this.idList.filter((e) => {
          return e.cycleId != item.id;
        });
      }
    },

    closeModel(type: any) {
      if (type == 4) {
        modalController.dismiss({ idList: this.idList });
      } else {
        modalController.dismiss();
      }
    },
    isCheck(item: any) {
      if (this.data) {
        for (let position = 0; position < this.idList.length; position++) {
          const element1 = this.idList[position];

          if (item.id == element1.cycleId) {
            return true;
          }
        }

        return false;
      }
    },
  },
});
