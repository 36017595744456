
import TaskBaseService from '../base/TaskBaseService';
/**
 * 首页
 */
export class TaskPermissionApi extends TaskBaseService {

    constructor() {
        super("taskPermissions");
    }

    /**
     * 获取任务权限（根据ProjcetId）
     * @param param projectId
     */
    public getTaskPermissionByProject(param: any): Promise <any> {
        const path = 'getTableByProjectId';
        return this.request(path, this.rest.METHOD_GET,param);
    }

    /**
     * 获取用户权限（根据taskId，用于独立任务）
     * @param param 
     */
    public getTaskPermissionByTask(param: any) {
        const path = 'getTableByTaskIdApp';
        return this.request(path, this.rest.METHOD_GET, param);
    }

    /**
     * 项目-任务设置-保存
     * @param data 
     */
    public savePermissionForProject(data: any){
        return this.request("updateByPermissionIdAndProjectId", this.rest.METHOD_POST, data);
    }

    /**
     * 独立任务-任务设置-保存
     * @param data 
     */
    public savePermissionForTask(data: any){
        return this.request("updateByPermissionIdAndTaskId", this.rest.METHOD_POST, data);
    }
}