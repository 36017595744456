
import { defineComponent } from "vue";
import {
  modalController,
  IonPage,
  IonContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
export default defineComponent({
  name: "ProjectOpennessModal",
  components: {
    IonPage,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    HeaderModal,
  },
  methods: {
    closeModel(type: any) {
      if (type == 4&&this.selValue!=-1) {
        modalController.dismiss({ value: this.options[this.selValue] });
      } else {
        modalController.dismiss();
      }
    },
    changed(event: any) {
      this.selValue = event.detail.value;
    },
  },
  props: {
    selIndex: Number,
  },
  created() {
    if (this.selIndex||this.selIndex==0) this.selValue = this.selIndex.valueOf();
  },
  data() {
    return {
      selValue: -1,
      options: [
        { key: 0, value: "公开" },
        { key: 1, value: "非公开" },
        // { key: 2, value: "任务人员" },
      ],
    };
  },
});
