import { CalendarSubscribeApi } from '@/api/calendar/CalendarSubscribeApi';

import BaseService from '../base/BaseService';

/**
 * 日历订阅模块
 */
export class CalendarSubscribeService extends BaseService {
    private static calendarSubscribeApi = new CalendarSubscribeApi();

    // 我的订阅列表
    public getMySubscribeList({ offset = 1, limit = 10,searchKey='' }): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarSubscribeService.calendarSubscribeApi.getMySubscribeList({
                offset,
                limit,
                searchKey,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 检查数据状态 DataState 插入,更新,删除实体
    public save(parms: any): any {
        return new Promise((resolve, reject) => {
            CalendarSubscribeService.calendarSubscribeApi.save(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //删除
    public deleteById({ id = ""} = {}): any {
        return new Promise((resolve, reject) => {
            CalendarSubscribeService.calendarSubscribeApi.clearById(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新实体
    public updateFieldsById(params:any): any {
        return new Promise((resolve, reject) => {
            CalendarSubscribeService.calendarSubscribeApi.updateFieldsById(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 日历分享
    public insertList(parms: any): any {
        return new Promise((resolve, reject) => {
            CalendarSubscribeService.calendarSubscribeApi.insertList(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }



}