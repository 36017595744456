
import { defineComponent } from "vue";
import ProjectMemberCard from "@/components/ListCard/ProjectMemberCard.vue";
import { ProjectService } from "@/services/project/projectService";
import { ProjectTeamService } from "@/services/project/ProjectTeamService";
import { ProjectMemberService } from "@/services/project/ProjectMemberService";
import Utils from "@/utils/Utils";
import { useRouter } from "vue-router";
import { alertController } from "@ionic/vue";

export default defineComponent({
  name: "AllMember",
  components: {
    ProjectMemberCard,
  },
  props: {
    hasDelMember: Boolean, //是否拥有删除权限
    hasEditMemberRole: Boolean, // 是否拥有编辑权限
  },
  data() {
    const router = useRouter();
    return {
      router: router,
      list: new Array(0),
      projectService: new ProjectService(),
      projectMemberService: new ProjectMemberService(),
      seachParams: {
        projectId: "",
        menberName: "",
        offset: 1,
        limit: 10,
      },
      teamRoleList: new Array(0),
      projectRoleList: new Array(0),
    };
  },
  mounted() {
    this.initTaskData();
  },
  methods: {
    refreshPage() {
      this.seachParams.offset = 1;
      this.requestDate((result: any) => {
        this.list = result;
      });
    },
    goToEditPage(user: any) {
      if (!this.hasEditMemberRole) {
        return;
      }
      this.router.push({
        name: "teamMemberEdit",
        params: {
          receiveInfo: JSON.stringify(user),
          teamRoleList: JSON.stringify(this.teamRoleList),
          projectRoleList: JSON.stringify(this.projectRoleList),
          hasEditMemberRole: this.hasEditMemberRole ? "1" : "0",
          hasDelMember: this.hasDelMember ? "1" : "0",
          projectId: this.seachParams.projectId,
        },
      });
    },
    initTaskData() {
      this.seachParams.offset = 1;
      if (this.$route.query.projectId) {
        this.seachParams.projectId = this.$route.query.projectId.toString();
      }
      this.requestDate((result: any) => {
        this.list = result;
      });
      this.getAllTeamRoles(this.seachParams.projectId);
      this.getProjectRoles();
    },
    requestDate(calblack: any) {
      this.projectService.getTeamMemberByProjectIdApp(this.seachParams).then(
        (r: any) => {
          if (r) {
            calblack(r.data);
          } else {
            // console.log("无组织");
          }
        },
        (e: any) => {
          // console.log(e);
        }
      );
    },
    async bindDeleteUser(i: number) {
      let id = this.list[i].memberIds;
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.projectMemberService
                .deleteMembers(
                  [
                    {
                      id: id,
                      dataState: 4,
                    },
                  ],
                  this.seachParams.projectId
                )
                .then(
                  (r: any) => {
                    if (r) {
                      this.list.splice(i, 1);
                    }
                  },
                  (e: any) => {
                    Utils.presentToastWarning("删除失败");
                  }
                );
            },
          },
        ],
      });
      await alert.present();
      // this.projectMemberService
      //   .deleteMembers(
      //     [
      //       {
      //         id: id,
      //         dataState: 4,
      //       },
      //     ],
      //     this.seachParams.projectId
      //   )
      //   .then(
      //     (r: any) => {
      //       if (r) {
      //         //删除成员
      //         this.list.splice(i, 1);
      //       }
      //     },
      //     (e: any) => {
      //       // console.log("删除成员AllMember-bindDeleteUser");
      //     }
      //   );
    },
    selectPostHandle(obj: any) {
      if (obj) {
        const postObj = obj.selectObj;
        this.list[obj.index].projectRoleId = postObj.id;
        this.list[obj.index].projectRoleName = postObj.name;
        
        const item=this.list[obj.index];
        this.updateProjectRoleRequest(item.memberIds,postObj.id,()=>{
          this.list[obj.index].list[0].projectRoleId=postObj.id;
          this.list[obj.index].list[0].projectRoleName=postObj.name;
        });
        //this.updataMember(this.list[obj.index]);
      }
    },
    selectRoleHandle(obj: any) {
      if (obj) {
        const postObj = obj.selectObj;
        this.list[obj.index].projectTeamRoleId = postObj.id;
        this.list[obj.index].projectTeamRoleName = postObj.name;
        this.projectMemberService.existsMember({ teamRoleId: postObj.id }).then(
          (r: boolean) => {
            if (r == false) {
              // console.log("已存在");
            } else {
              this.updataMember(this.list[obj.index]);
            }
          },
          (e: any) => {
            // console.log("更新失败AllMember-updataMember");
          }
        );
      }
    },
    //获取团队角色
    getAllTeamRoles(projectId: string) {
      const projectTeamService = new ProjectTeamService();
      projectTeamService.getAllNodeByTreeId(projectId).then(
        (r: any) => {
          if (r) {
            this.teamRoleList = r;
          }
        },
        (e: any) => {}
      );
    },
    //获取项目角色
    getProjectRoles() {
      this.projectService.getRolesByProjectId().then(
        (r: any) => {
          if (r) {
            this.projectRoleList = r.data;
          }
        },
        (e: any) => {}
      );
    },
    updateProjectRoleRequest(id:string,roleId:string,callback?:any){
      this.projectMemberService.updateProjectRole(id,roleId).then((r:any)=>{
        if(!r){
          Utils.presentToastWarning("更新失败");
        }else{
          if(callback){
            callback();
          }
        }
      });
    },
    //更新成员
    updataMember(params: any) {
      this.projectMemberService.updateModel(params).then(
        (r: any) => {
          if (r) {
            // console.log("更新成功");
          }
        },
        (e: any) => {
          // console.log("更新失败AllMember-updataMember");
        }
      );
    },
    //下拉刷新
    doRefresh(event: any) {
      if (event) {
        event.target.disabled = true;
      }
      this.seachParams.offset = 1;
      this.requestDate((result: any) => {
        this.list = result;
      });
      this.getAllTeamRoles(this.seachParams.projectId);
      setTimeout(() => {
        event.target.disabled = false;
        event.target.complete();
      }, 1000);
    },
    //搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.seachParams.offset = 1;
      this.seachParams.menberName = value.searchKey;
      this.requestDate((result: any) => {
        this.list = result;
      });
    },
    nextPage(event: any) {
      if (event) {
        event.target.disabled = true;
      }
      this.seachParams.offset++;
      this.requestDate((result: any) => {
        if (result == null || result.length <= 0) {
          Utils.presentToast("已无更多数据");
        } else {
          if (event) {
            this.list = this.list.concat(result);
          }
        }
      });
      setTimeout(() => {
        event.target.disabled = false;
        event.target.complete();
      }, 1000);
    },
  },
});
