
import { defineComponent, onMounted, toRefs, ref } from "vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { IonLabel, IonItem, modalController } from "@ionic/vue";

export default defineComponent({
  name: "PTaskStatusModal",
  components: { HeaderModal, IonLabel, IonItem },
  props: {
    checked: Number,
  },
  setup(props) {
    const title = ref("选择任务状态");
    const { checked } = toRefs(props);
    const radioCheck = ref(checked);

    let result = '';
    function bindCheckStatus(value: any) {
      if (value) {
        result = value;
      }
    }

    function bindConfirmStatus() {
      modalController.dismiss({
        dismissed: true,
        checked: result,
      });
    }

    return { title, radioCheck, bindConfirmStatus, bindCheckStatus };
  },
});
