
import { defineComponent } from "vue";
import { Px2rem } from "../../../libs/px2rem";
import { IonProgressBar } from "@ionic/vue";
import { getInstance } from "@/services/projectPageService";
import { ProjectTeamService } from "@/services/project/ProjectTeamService";
import { ProjectMemberService } from "@/services/project/ProjectMemberService";
import AvatarList from "@/components/AvatarList.vue";
import * as echarts from "echarts";
import { TaskService } from "@/services/task/TaskService";

export default defineComponent({
  name: "TeamList",
  components: { IonProgressBar, AvatarList },
  props: {
    refresh: Boolean,
  },
  emits: ["projectInfo"],
  data() {
    const circleSize = Px2rem.getRem("220px");
    const circleWid = 80;
    const projectPageService = getInstance();
    const projectId = this.$route.query.projectId;

    const team = {
      id: "",
      memberCount: 0,
      taskQuantity: 0,
      starting: 0,
      completeQuantity: 0,
      nearfutureQuantity: 0,
      completionRate: 0,
      overdueQuantity: 0,
      willexpireQuantity: 0,
    };
    const projectStatistic = {
      completeQuantity: "0",
      incompleteQuantity: "0",
      overdueQuantity: "0",
      noClaimeQuantity: "0",
      todayQuantity: "0",
      overdueFinshQuantity: "0",
      taskQuantity: "1",
    };
    return {
      projectPageService: projectPageService,
      projectTeamService: new ProjectTeamService(),
      projectMemberService: new ProjectMemberService(),
      taskService: new TaskService(),
      ChartTableXData: new Array(),
      ChartTableSData: new Array(),
      ChartTableColor: new Array(),
      projectId: projectId,
      team: team,
      circleSize,
      circleWid,
      memberList: new Array(0),
      searchParam: {
        projectId: "",
        offset: 1,
        limit: 10,
      },
      isMore: true,
      memberCount: 0,

      users: new Array<any>(),
      projectStatistic,
    };
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.refreshStatistics();
        this.initProjectTeamMemberCount();
        this.getMemberCountByProjectApp();
      }
    },
  },
  mounted() {
    if (this.projectId) {
      this.getFinishTaskCount();
      this.initProjectStatistic(this.projectId + "");
      // this.getProjectInfo(this.projectId.toString());
      this.refreshStatistics();
      this.searchParam.projectId = this.projectId.toString();
      this.initProjectTeamMemberCount();
      this.getMemberCountByProjectApp();
    }
  },
  methods: {
    avatarData(memberObj: any) {
      this.users = [];
      let obj = { name: memberObj.userName, img: "", id: memberObj.userId };
      this.users.push(obj);
      return this.users;
    },
    //获取项目任务完成情况最近7天
    getFinishTaskCount() {
      let that = this;
      const params = {
        projectId: this.projectId,
      };
      this.taskService.getFinishTaskCount(params).then(
        (r: any) => {
          if (r) {
            r.forEach((element: any) => {
              that.ChartTableXData.push(element.localDate.substring(5, 10));
              that.ChartTableSData.push(element.count);
            });
            that.initChartTable();
          } else {
            //  console.log("无项目动态信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    initChartTable() {
      const elEchart = document.querySelector("#projectEchart");
      if (elEchart) {
        elEchart.removeAttribute("_echarts_instance_"); // 移除容器上的 _echarts_instance_ 属性,
        const ec = echarts as any;
        const myChart = ec.init(elEchart);
        // 指定图表的配置项和数据
        const option = {
          grid: {
            left: "10",
            right: "5%",
            bottom: "0",
            top: "5%",
            containLabel: true,
          },

          xAxis: {
            data: this.ChartTableXData,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
          },
          yAxis: {
            type: "value",
            max: function (value: any) {
              // return value.max+(Math.ceil(value.max/5)-value.max%5);
              return value.max + 10;
            },
            min: 0,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: 15,
            },
          },
          series: [
            {
              type: "bar",
              barWidth: "60%",
              data: this.ChartTableSData,
              itemStyle: {
                normal: {
                  color: function (params: any) {
                    const colors = [
                      "#7195D9",
                      "#D97171",
                      "#E2B27F",
                      "#84D5AE",
                      "#7195D9",
                    ];
                    return colors[params.dataIndex];
                  },
                  barBorderRadius: [8, 8, 0, 0],
                },
              },
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option, true);
      }
    },
    initProjectStatistic(id: string) {
      const parmsStatus = {
        projectId: id,
      };
      this.projectPageService.getByIdForOverview(parmsStatus).then(
        (r: any) => {
          if (r && r[0]) {
            this.projectStatistic = r[0];
          } else {
            //console.log("无项目分组信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getProjectInfo(id: string) {
      const parmsStatus = {
        id: id,
      };
      let that = this;
      this.projectPageService.getProjectInfoById(parmsStatus).then(
        (r: any) => {
          if (r && r.data && r.data[0]) {
            // console.log("getProjectInfoById3333"+ JSON.stringify(r));
            this.team = r.data[0];
            this.$emit("projectInfo", r.data[0]);
          } else {
            // console.log("无项目信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getMemberCountByProjectApp() {
      const parmsStatus = {
        projectId: this.projectId,
      };
      let that = this;
      this.projectMemberService.getMemberCountByProjectApp(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            this.memberCount = r.data;
          } else {
            // console.log("无项目信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //更新项目任务数量
    refreshStatistics() {
      const parmsStatus = {
        id: this.projectId,
        isRefresh: true,
      };
      let that = this;
      this.projectPageService.refreshStatistics(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            this.team = r.data;
            this.$emit("projectInfo", r.data);
          } else {
            if (event != null) {
              this.memberList = [];
              console.log("暂无数据");
            }
          }
        },
        (e: any) => {
          console.log("project/team-refreshStatistics接口请求异常");
        }
      );
    },
    //初始化
    initProjectTeamMemberCount() {
      //  this.getProjectInfo(this.projectId+"");
      this.memberList = [];
      this.searchParam.offset = 1;
      this.isMore = true;
      this.getProjectTeamMemberCount(this.searchParam, null);
    },
    //请求接口
    getProjectTeamMemberCount(pamas: any, event: any) {
      this.projectTeamService.getProjectTeamMemberCount(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              if (event) {
                event.target.complete();
                this.isMore = false;
              }
            }
            if (event != null) {
              this.memberList = this.memberList.concat(result);
            } else {
              this.memberList = result;
            }
          } else {
            if (event != null) {
              // this.isEmpty = true;
              this.memberList = [];
              console.log("暂无数据");
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      // this.searchParams.projectName = "";
      //下拉刷新任务数
      this.refreshStatistics();
      this.getMemberCountByProjectApp();
      this.searchParam.offset = 1;
      this.initProjectTeamMemberCount();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //加载下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.searchParam.offset++;
        this.getProjectTeamMemberCount(this.searchParam, event);
      }
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
  },
});
