
import { defineComponent } from "vue";
import { modalController, IonPage, IonRadioGroup, IonRadio } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import {OrganizationService} from "@/services/organization/OrganizationService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "ChooseOrganization",
  components: {
    HeaderModal,
    IonPage,
    SearchBar,
    IonRadioGroup,
    IonRadio,
  },
  props: {
    finalSelId: Number,
  },
  data() {
    const organizationService = new OrganizationService();
    return {
      parameters: {
        pageNum:1,
        likeConditions: "",
      },
      list: [],
      idList: [], //选中的id集合
      selId: -1,
      selectedObj:null,
      organizationService:organizationService
    };
  },
  mounted() {
    this.init("");
  },
  created() {
    if (this.finalSelId) this.selId = this.finalSelId.valueOf();
  },
  methods: {
    init(keyword: any) {
      this.parameters.likeConditions = keyword;
      this.parameters.pageNum = 1;
      this.requestDate((result: any) => {
        this.list =result;
      });
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.parameters.likeConditions = data.searchKey;
      this.parameters.pageNum = 1;
      this.requestDate((result: any) => {
        this.list =result;
      });
    },
    closeModel(type: any) {
      //todo 保存选择的机构信息
      if(type==4)
      modalController.dismiss({ refresh: true, selectedObj: this.selectedObj });
      else
       modalController.dismiss();
    },
    changed(event: any) {
      this.selId = event.detail.value;
      console.log(event.detail.value);
    },
    nextPage(event: any) {
      this.parameters.pageNum++;
      this.requestDate((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.pageNum = 1;
      event.target.disabled = true;
      setTimeout(() => {
        event.target.disabled = false;
      }, 2000);
      this.requestDate((result: any) => {
        event.target.complete();
        this.list =result;
      });
    },
    requestDate(calblack: any){
      this.organizationService.getOrgList(this.parameters).then(
        (r: any) => {
          if (r) {
            calblack(r.data.rows);
          } else {
            console.log("无组织");
          }
        },
        (e: any) => {
          console.log(e);
        });
    },
    clickOrg(item: any){
      this.selectedObj=item;
    }
  },
});
