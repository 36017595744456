
import {
  modalController,
  IonPage,
  alertController,
} from "@ionic/vue";
import Utils from "@/utils/Utils";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SlideMore",
  components: { IonPage },
  props: { 
    taskStagePermission:Object,
    isShowMen: Boolean
   },
  data() {
    return {
      isEdit: false,
    };
  },
  methods: {
    //设置截止时间
    settingTimeAlertPrompt() {
       this.closeModel("setting");
    },
    //移动阶段
    moveAlertPrompt() {
      this.closeModel("move");
    },
    //编辑阶段
    editAlertPrompt() {
        this.closeModel("edit");
    },
    //当前阶段后边增加阶段
     addAlertPrompt() {
        this.closeModel("add");
    },
    //删除阶段
    async moveToRecycleBinHandler() {
      const alert = await alertController.create({
        header: "删除阶段",
        message: "删除阶段将彻底删除此阶段上的所有任务，请确认是否要删除整个阶段？",
        mode:"ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: () => {
              this.closeModel("del");
            },
          },
        ],
      });
      await alert.present();
    },
    closeModel(status: string, selectStageObj?: any) {
      // console.log(type); //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      modalController.dismiss({
        dismiss:true,
        state: status
      });
    },
  },
});
