
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import { debug } from "console";
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "ConditionPop",
  data() {
    return {
      conditionApi: new SearchPanelService(),
      postList: [],
      selectdId: "",
    };
  },
  emits: ["on-select"],
  props: {
    queryType: String,
  },
  created() {
    this.getMySearchConditionRequest();
  },
  methods: {
    show(id: string) {
      this.selectdId = id;
    },
    getMySearchConditionRequest() {
      this.conditionApi
        .getAppQueryByUserId({
          queryType: this.queryType ? this.queryType : "task",
          queryName: "",
          qIsIndexCustomize: "",
        })
        .then((res) => {
          const all = { id: "-1", queryName: "--请选择--" };
          res.splice(0, 0, all);
          this.postList = res;
          //是否有default qisDefault
          const defaultCond = res.find((o: any) => {
            return o.qisDefault;
          });
          if (defaultCond) {
            //有默认条件，那么默认条件选中
            this.selectdId = defaultCond.id;
            this.$emit("on-select", defaultCond);
          } else {
            //解决，有默认查询条件时 ，查询两遍列表 2020年12月21日11:23:08
            this.$emit("on-select", null);
          }
        });
    },
    bindChangeEvent(id: string) {
      if (this.selectdId == id) {
        return;
      }
      this.selectdId = id;
      const index = this.postList.findIndex((e: any) => {
        return e.id == id;
      });
      let res: any = this.postList[index];
      if (res.id == "-1") {
        res = { id: "", queryName: "" };
      }
      this.$emit("on-select", res);
    },
  },
});
