
import { defineComponent } from "vue";
import { IonProgressBar } from "@ionic/vue";
import { useRouter } from "vue-router";
import { DashboardService } from "@/services/dashboardService";
export default defineComponent({
  name: "PabProgress",
  components: { IonProgressBar },
  props: {
    taskItem: Array,
    projectId: String,
    projectPeriod: String,
    planId: Number,
  },
  data() {
    const router = useRouter();
    return {
      api: new DashboardService(),
      router: router,
      checkpointTimesDatas: new Array(0),
      showNoData: false, //展示暂无数据
    };
  },
  methods: {
    goFolder(item: any) {
      this.router.push({
        path: "/dashboard/ProjectTabs",
        query: {
          folderId:
            "project@folder-" + this.projectId + "-" + item.name,
          planId: this.planId,
          projectId: this.projectId,
          currentTab: "Document"
        },
      });
    },
    handleProgress(name: any) {
      this.getCheckpointTimes(name);
    },
    getCheckpointTimes(name: any) {
      const params = {
        milestone: this.projectPeriod,
        name: name,
        projectId: this.projectId,
      };
      this.api.getCheckpointTimes(params).then((res: any) => {
        this.checkpointTimesDatas = res.data.data;
        this.showNoData = res.data.data == null || res.data.data.length <= 0;
      })
    },
  },
  mounted() {
    this.getCheckpointTimes('PAB');
  },
});
