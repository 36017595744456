
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import CalendarCard from "@/views/calendar/component/CalendarCard.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { CalendarService } from "@/services/calendar/CalendarService.ts";
import { useRouter } from "vue-router";
import Utils from "@/utils/Utils";
import SearchBar from "@/components/SearchBar.vue";
import Header from "@/components/Header.vue";
import { CalendarSubscribeService } from "@/services/calendar/CalendarSubscribeService.ts";
export default defineComponent({
  name: "Calendar",
  components: { Header, SearchBar, CalendarCard, SelectExecutor },
  data() {
    return {
      calendarSubscribeService: new CalendarSubscribeService(),
      router: useRouter(),
      calendarService: new CalendarService(),
      list: new Array(0),
      isMore: true,
      cancelSwitch: false, //接口开关
      isedit: false,
      calendarParams: {
        limit: 10,
        offset: 1,
        calName: "",
      },
      shareUserList: [],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.calendarParams.calName = "";
      this.calendarParams.offset = 1;
      this.requsetData(this.calendarParams, null);
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      this.calendarParams.offset = 1;
      this.isedit = false;
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 500);
    },
    //下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.calendarParams.offset++;
        this.requsetData(this.calendarParams, event);
      }
    },
    //加载数据
    requsetData(pamas: any, event: any) {
      this.calendarService.getPageMyCal(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              Utils.presentToastWarning("暂无数据，请换个搜索条件吧！");
              event.target.disabled = true;
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
              setTimeout(() => {
                if (event) {
                  event.target.complete();
                }
              }, 500);
            }
            console.log(this.list);
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.calendarParams.offset = 1;
      this.calendarParams.calName = value.searchKey;
      this.requsetData(this.calendarParams, null);
    },
    //选择分享人
    async share(item: any) {
      let that = this;
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        that.shareUserList = data.data;
        that.calendarSubscribe(item.item.id);
      }
    },
    //日历分享
    calendarSubscribe(calId: String) {
      let that = this;
      let params = new Array();
      for (let i = 0; i < that.shareUserList.length; i++) {
        let user: any = that.shareUserList[i];
        let t = {
          id: Utils.getUuid,
          calId: calId,
          userId: user.id,
          userName: user.name,
          status: 1,
        };
        params.push(t);
      }
      that.calendarSubscribeService.insertList(params).then((res: any) => {
        if (res) {
          Utils.presentToast("分享成功");
          // that.resetPage();
        }
      });
    },
    //编辑
    editItem(item: any) {
      const info = {
        id: item.item.id,
      };
      this.router.push({
        name: "add-calendar",
        params: {
          info: JSON.stringify(info),
        },
      });
    },
    //删除
    deleteItem(item: any) {
      let that = this;
      Utils.confirmDialog("确认", "确认要删除吗？", function () {
        let params = { id: item.item.id };
        that.calendarService.deleteById(params).then((res: any) => {
          if (res) {
            Utils.presentToast("删除成功");
            that.initData();
          }
        });
      });
    },
  },
});
