
import { defineComponent, reactive } from "vue";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { useRoute } from "vue-router";
import { popoverController } from "@ionic/vue";
import PostPopover from "./PostPopover.vue";
import RolePopover from "./RolePopover.vue";

export default defineComponent({
  name: "TeamPageAdd",
  components: {
    Header,
    SearchBar,
  },
  setup() {
    const btnTextRight = reactive(["保存"]);
    const btnSizeRight = reactive(["28px"]);

    const dataList = reactive({
      data: [
        {
          id: "1",
          name: "王利军",
          type: "ETM-1",
          imgPath: "",
          role: "1",
          roleName: "项目经理",
          post: "1",
          postName: "拥有者",
        },
        {
          id: "2",
          name: "朱会用",
          type: "ETM-1",
          imgPath: "",
          role: "2",
          roleName: "仪表盘设计工程师",
          post: "2",
          postName: "成员",
        },
        {
          id: "3",
          name: "耿乾风",
          type: "ETM-1",
          imgPath: "",
          role: "3",
          roleName: "内敛工程师",
          post: "2",
          postName: "成员",
        },
      ],
    });

    const bindSaveEmit = (r: any) => {
      console.log(r);
    };

    //设置职位
    async function openPostPopover(ev: Event, i: number) {
      const popover = await popoverController.create({
        component: PostPopover,
        cssClass: "my-popover-class",
        event: ev,
        translucent: false,
        componentProps: {
          postId: dataList.data[i].post,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && "id" in data) {
        dataList.data[i].post = data.id;
        dataList.data[i].postName = data.name;
      }
    }

    //设置角色
    async function openRolePopover(ev: Event, i: number) {
      const popover = await popoverController.create({
        component: RolePopover,
        cssClass: "my-popover-class",
        event: ev,
        translucent: false,
        componentProps: {
          roleId: dataList.data[i].role,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && "id" in data) {
        dataList.data[i].role = data.id;
        dataList.data[i].roleName = data.name;
      }
    }

    const bindDeleteUser = (i: number) => {
      let id = dataList.data[i].id;
      //删除请求
      console.log(id);
      dataList.data.splice(i, 1);
    };

    return {
      btnTextRight,
      btnSizeRight,
      dataList,
      bindSaveEmit,
      openPostPopover,
      openRolePopover,
      bindDeleteUser,
    };
  },
});
