/**
 * okr目标级别 api
 */
import OkrBaseService from '../base/OkrBaseService';

export class GroupApi extends OkrBaseService {

    constructor() {
        super("okr");
    }
    //目标级别列表
    public getGroupList(): Promise<any> {
        const path = 'group/tree/all';
        return this.request(path, this.rest.METHOD_GET);
    }
    //组成员列表
    public getGroupUserList(param:any): Promise<any> {
        const path = 'groupUser/list/distinct';
        return this.request(path, this.rest.METHOD_GET,param);
    }
}