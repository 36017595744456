
import { defineComponent } from "vue";
import { popoverController, modalController } from "@ionic/vue";
import AvatarList from "@/components/AvatarList.vue";
import moment from "moment";

export default defineComponent({
  name: "CalendarCard",
  components: { AvatarList },
  props: {
    RemindCardObj: Object,
    selectTypeId: String,
    showFixed: {
      type: Boolean,
      default: false,
    },
    scrollEnable: {
      type: Boolean,
      default: false,
    },
    fatherInitData: {
      type: Function,
      default: null,
    },
    index: Number,
  },
  emits: ["on-show-item", "on-detail"],
  data() {
    return {};
  },
  methods: {
    selectTypeHandler(item: any) {
      this.$emit("on-show-item", { item: item, index: this.index });
    },
    avatarDataAllList(item: any) {
      let obj = { name: item.senderName, img: "", id: item.senderId };
      return obj;
    },
    formatCreateTime(time: any) {
      if (time.indexOf("T")>-1) {
        return moment(time).utc().format("yyyy/MM/DD HH:mm");
      } else {
        let  thisTime = new Date(time.replace(/\-/g, "/"));
        return moment(thisTime).format("yyyy/MM/DD HH:mm");
      }
      //  return moment(time).format("yyyy/MM/DD HH:mm");
    },
    detailHandler(item: any) {
      this.$emit("on-detail", { item: item, index: this.index });
    },
  },
});
