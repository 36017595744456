
import AppBaseService from '@/api/attendance/base/AppBaseService';

export class privacyPolicyApi extends AppBaseService {
    constructor() {
        super("privacyPolicy");
    }
    //同意个人隐私申明
    public signAgree(params:any):Promise<any>{
       return  this.request('sign/agree?userId='+params,this.rest.METHOD_POST)
    }
 
}