
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
import { Downloader, DownloadRequest, NotificationVisibility } from "@ionic-native/downloader";
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser';
import { ImageResizer, ImageResizerOptions } from '@ionic-native/image-resizer';
import { SingleFile, MergeFileParam, UploadFileParam } from '@/models/file/Storage';
import { actionSheetController, isPlatform, modalController } from "@ionic/vue";
import { SubFolder, RootFolder, FolderParams } from '@/models/file/Folder';
import { AndroidPermissions } from '@ionic-native/android-permissions';
import PreviewModal from '@/views/project/document/PreviewModal.vue';
import { Camera, CameraOptions } from "@ionic-native/camera";
import { PermissionParam } from '@/models/file/Permission';
import { AnimationSelf } from '@/libs/animation';
import { PreviewAnyFile } from '@ionic-native/preview-any-file';
import { PhotoViewer } from '@ionic-native/photo-viewer';
import DocService from '../services/doc/docService';
import CookieHelper from '../utils/CookieHelper';
import Setting from '@/rest/Setting';
import { File, Entry, Metadata } from '@ionic-native/file';
import { Plugins } from '@capacitor/core';
import { ImagePreview } from 'vant';
import { BuildInfo } from '@ionic-native/build-info';

const { LocalNotifications } = Plugins;

/**
 * 文件上传工具类
 */
export class FileHelper {

    constructor() {
        this.initFolder();
    }

    private readonly docService = new DocService();
    private readonly folderSystemId = 'task';
    private readonly docHttp = Setting.apiBaseURL?.startsWith('http') ? '' : 'http://ematrix-app-dev.bingocloudos.net';
    private readonly webPreviewHttp = Setting.baseURL + "/owa/op/view.aspx?src=";
    public readonly downloadChatPath = 'Download/chat';
    private readonly fileSystemHead = 'file:';
    private readonly appID = isPlatform('ios') ? '' : ('/' + BuildInfo.packageName);//包名Get the packageName of Application ID

    private static isHasChat: boolean = false;//聊天模块
    private static isHasProgress: boolean = false;//进度模块
    private static isHasDocument: boolean = false;//文档模块
    private static isHasMine: boolean = false;//我的模块
    private static isHasDownload: boolean = false;//公共下载，非图片文件

    private readonly PicFolders = ['chat', 'progress', 'document', 'mine'];
    public readonly downloadPath = 'Download';
    public readonly fileSystemPath = isPlatform('ios') ? File.dataDirectory : File.externalRootDirectory;

    private initFolder() {
        this.generateFolder(File.cacheDirectory, this.PicFolders[0]).then((t: boolean) => { FileHelper.isHasChat = t; }, (f: boolean) => { FileHelper.isHasChat = f; });
        this.generateFolder(File.cacheDirectory, this.PicFolders[1]).then((t: boolean) => { FileHelper.isHasProgress = t; }, (f: boolean) => { FileHelper.isHasProgress = f; });
        this.generateFolder(File.cacheDirectory, this.PicFolders[2]).then((t: boolean) => { FileHelper.isHasDocument = t; }, (f: boolean) => { FileHelper.isHasDocument = f; });
        this.generateFolder(File.cacheDirectory, this.PicFolders[3]).then((t: boolean) => { FileHelper.isHasMine = t; }, (f: boolean) => { FileHelper.isHasMine = f; });
        if (isPlatform('ios')) {
            this.generateFolder(this.fileSystemPath, this.downloadPath).then((t: boolean) => { FileHelper.isHasDownload = t; }, (f: boolean) => { FileHelper.isHasDownload = f; });
        } else {
            this.generateFolder(this.fileSystemPath + this.downloadPath, BuildInfo.packageName).then((t: boolean) => { FileHelper.isHasDownload = t; }, (f: boolean) => { FileHelper.isHasDownload = f; });
        }
    }

    private generateFolder(rootPath: string, folder: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            File.checkDir(rootPath, folder).then((b: any) => {
                resolve(true);
            }, (f: any) => {
                File.createDir(rootPath, folder, true).then((e: any) => {
                    resolve(true);
                }, (e: any) => {
                    reject(false);
                });
            });
        });
    }

    //相机参数
    private cameraOptions: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.CAMERA,
        destinationType: Camera.DestinationType.FILE_URI,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.FRONT,
    }

    //相机参数Base64
    private cameraOptionsBase64: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.CAMERA,
        destinationType: Camera.DestinationType.DATA_URL,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.FRONT,
    }

    // 从相册选取
    private photoOptions: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: Camera.DestinationType.FILE_URI,
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        cameraDirection: Camera.Direction.BACK,
    }

    //文件选取配置
    private folderOptions: CameraOptions = {
        quality: 100,
        sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
        destinationType: Camera.DestinationType.FILE_URI,
        mediaType: Camera.MediaType.ALLMEDIA,
        cameraDirection: Camera.Direction.BACK,
    }

    //上传业务（common：分片，chat：聊天，single：单个上传）
    private _uploadWay: string = 'common';
    //上传进度（true：需要，false：否）
    private _uploadProgress: boolean = false;

    public get uploadWay(): string {
        return this._uploadWay;
    }

    public set uploadWay(uploadWay: string) {
        this._uploadWay = uploadWay;
    }

    public get uploadProgress(): boolean {
        return this._uploadProgress;
    }

    public set uploadProgress(uploadProgress: boolean) {
        this._uploadProgress = uploadProgress;
    }

    //权限判断
    private checkPermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (isPlatform('android')) {
                let ps = [AndroidPermissions.PERMISSION.CAMERA, AndroidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE];
                this.getNotHasPermissions(ps, 0).then((r: Array<string>) => {
                    if (r != null && r.length > 0) {
                        AndroidPermissions.requestPermissions(r).then((rr: any) => {
                            resolve(true);
                        }, (re: any) => {
                            console.log('申请权限失败：' + JSON.stringify(re));
                            reject(false);
                        });
                    } else {
                        resolve(true);
                    }
                }, (e: string) => {
                    console.log(e);
                    reject(false);
                });
            } else {
                resolve(true);
            }
        });
    }

    private getNotHasPermissions(ps: Array<string>, index: number): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
            AndroidPermissions.checkPermission(ps[index]).then(
                (result: any) => {
                    ps.splice(index, 1);
                    if (ps.length > index) {
                        this.getNotHasPermissions(ps, index).then((r: Array<string>) => {
                            resolve(r);
                        }, (e: string) => {
                            reject(e);
                        });
                    } else {
                        resolve(ps);
                    }
                },
                (err: any) => {
                    if (ps.length > index + 1) {
                        this.getNotHasPermissions(ps, index + 1).then((r: Array<string>) => {
                            resolve(r);
                        }, (e: string) => {
                            reject(e);
                        });
                    } else {
                        resolve(ps);
                    }
                }
            ).catch((c: any) => {
                reject('权限检查异常');
            });
        });
    }

    //选择相机
    private openCamera(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.cameraOptions).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    //选择相机
    public openCameraBase64(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.cameraOptionsBase64).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    //选择本地图片
    public openPhotoFolder(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.photoOptions).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    //选择本地图片并获取文件大小
    public openPhotoFolderAndGetSize(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.photoOptions).then((imageData: any) => {
                        if (imageData) {
                            let fileInfo = {
                                imageData: imageData,
                                fileSize: 0
                            }
                            let data = imageData.indexOf(this.fileSystemHead) == 0 ? imageData : (this.fileSystemHead + '//' + imageData);
                            File.resolveLocalFilesystemUrl(data).then((b: Entry) => {
                                b.getMetadata((meta: Metadata) => {
                                    fileInfo.fileSize = meta.size;
                                    resolve(fileInfo);
                                }, (e: any) => {
                                    reject("");
                                })
                            }, (f: any) => {
                                reject("");
                            });
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    //选择本地文件
    public openFileFolder(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkPermission().then((r: boolean) => {
                if (r) {
                    Camera.getPicture(this.folderOptions).then((imageData: any) => {
                        if (imageData) {
                            resolve(imageData);
                        } else {
                            reject("");
                        }
                    }, (e: any) => {
                        console.log(e);
                        reject("");
                    });
                }
            });
        });
    }

    private static actionSheet: any = null;

    /**
     * 文件上传弹出框-（拍照和相册）
     * @param folderId 文件夹标识
     * @param successCallback 
     * @param errorCallback 
     */
    public async openFileSheet(folderId: string, successCallback: any, errorCallback: any) {
        if (folderId != "" && folderId != null) {
            FileHelper.actionSheet = await actionSheetController.create({
                mode: 'ios',
                buttons: [
                    {
                        text: "拍照",
                        handler: () => {
                            this.openCamera().then((result: any) => {
                                successCallback(folderId, result);
                            }, (e: string) => {
                                console.log('拍照异常：' + e);
                                errorCallback("拍照失败");
                            });
                        },
                    },
                    {
                        text: "手机相册",
                        handler: () => {
                            this.openPhotoFolder().then((result: any) => {
                                successCallback(folderId, result);
                            }, (e: string) => {
                                console.log(e);
                                errorCallback("选择图片失败");
                            });
                        },
                    },
                    {
                        text: "取消",
                        role: "cancel",
                    },
                ],
            });
            await FileHelper.actionSheet.present();
        } else {
            errorCallback("文件标识为空，请先创建文件夹");
        }
    }

    /**
     * 打开本地文件选择弹出框
     * @param folderId 
     * @param successCallback 
     * @param errorCallback 
     */
    public openPhotoFolderExternal(folderId: string, successCallback: any, errorCallback: any) {
        if (folderId != "" && folderId != null) {
            return this.openFileFolder().then((result: any) => {
                this.getStorageFolder(folderId, result, successCallback, errorCallback);
            }, (e: string) => {
                console.log(e);
                errorCallback("选择文件失败");
            });
        } else {
            errorCallback("文件标识为空，请先创建文件夹");
        }
    }

    /**
     * 打开本地相册弹出框
     * @param folderId 文件夹标识
     * @param successCallback 
     * @param errorCallback 
     */
    public openPhotoLibrary(folderId: string, successCallback: any, errorCallback: any) {
        if (folderId != "" && folderId != null) {
            this.openPhotoFolder().then((result: any) => {
                this.getStorageFolder(folderId, result, successCallback, errorCallback);
            }, (e: string) => {
                console.log(e);
                errorCallback("选择照片失败");
            });
        } else {
            errorCallback("文件标识为空，请先创建文件夹");
        }
    }

    /**
     * 获取文件存储位置信息
     * @param folderId 业务对象标识
     */
    public getStorageFolder(folderId: string | SingleFile, data: string, successCallback: any, errorCallback: any): void {
        let storage: SingleFile = {
            folderId: '',
            systemId: this.folderSystemId
        }
        if (typeof (folderId) == 'string') {
            storage.folderId = folderId;
        } else {
            storage.folderId = folderId.folderId;
            if (folderId.systemId) {
                storage.systemId = folderId.systemId;
            }
            storage.busId = folderId.busId;
            storage.busTable = folderId.busTable;
            storage.busTag = folderId.busTag;
            storage.name = folderId.name;
            storage.extName = folderId.extName;
            storage.size = 0;
            storage.documentId = folderId.documentId;
        }
        if (isPlatform('capacitor')) {
            data = data.indexOf(this.fileSystemHead) == 0 ? data : (this.fileSystemHead + '//' + data);
            File.resolveLocalFilesystemUrl(data).then((b: Entry) => {
                storage.extName = this.getExpandedName(b.name);
                storage.name = this.renameFileName("", b.name);
                b.getMetadata((meta: Metadata) => {
                    storage.size = meta.size;
                    if (this.uploadWay == 'common' || this.uploadWay == 'single') {
                        this.upload(storage, data).then((r: any) => {
                            successCallback(r);
                        }, (e: string) => {
                            errorCallback(e);
                        });
                    } else {
                        this.uploadChat(data, storage).then((r: any) => {
                            successCallback(r);
                        }, (e: string) => {
                            errorCallback(e);
                        });
                    }
                }, (e: any) => {
                    console.log('异常：' + JSON.stringify(e));
                })
            }, (f: any) => {
                console.log('文件异常：' + JSON.stringify(f));
                errorCallback(f);
            });
        } else {
            errorCallback('无法上传');
        }
    }

    /**
     * 文件上传
     * @param result 
     */
    public upload(storage: SingleFile, data: string): Promise<any> {
        if (data != "" && data != null) {
            if (this.uploadProgress) {
                return this.uploadFile(data, storage);
            } else {
                return this.docService.singleUploadFile(storage, data);
            }
        }
        return new Promise((resolve, reject) => {
            reject("文件数据不存在");
        });
    }

    /**
     * 文件上传(chat)
     * @param data 
     */
    public uploadChat(data: string, storage: SingleFile): Promise<any> {
        if (data != "" && data != null) {
            if (this.uploadProgress) {
                return this.uploadFile(data, storage);
            } else {
                return this.docService.chatUploadFile(data);
            }
        }
        return new Promise((resolve, reject) => {
            reject("文件数据不存在");
        });
    }

    private readonly fileTransfer: FileTransferObject = FileTransfer.create();

    //文件参数
    private fileOptions: FileUploadOptions = {
        fileKey: 'file',
        fileName: '',
        httpMethod: "POST",
        mimeType: "text/plain",
        chunkedMode: false,
        headers: {
            'enterpriseId': Setting.currentEnterprise.id,
            'Authorization': CookieHelper.get("Authorization") || CookieHelper.get("token")
        },
        params: {}
    }

    private static chunkUploadId: number = 0;
    private static chunkUploadFolderId: string = '';

    /**
     * 本地文件上传
     * @param filePath //本地文件地址android或ios
     */
    public uploadFile(filePath: string, storage: SingleFile): Promise<any> {
        this.fileOptions.fileName = storage.name;
        const token = CookieHelper.get(Setting.authCookieName);
        if (token != null && token != "") {
            const header = {
                'X-APP-ID': Setting.defalutAPPID,
                'Authorization': JSON.parse(token).access_token
            }
            this.fileOptions.headers = header;
        }

        const optionParams = {
            folderId: storage.folderId,
            systemId: storage.systemId,
            busId: storage.busId,
            busTable: storage.busTable,
            busTag: storage.busTag,
            name: storage.name,
            extName: storage.extName,
            size: storage.size,
            documentId: storage.documentId
        };
        this.fileOptions.params = optionParams;
        //上传api地址
        let uploadUrl = '';
        if (this.uploadWay == 'chat') {
            uploadUrl = encodeURI(this.docHttp + this.docService.getChatUploadFile());
        } else if (this.uploadWay == 'common') {
            uploadUrl = encodeURI(this.docHttp + this.docService.getChunkUploadFile());
        } else if (this.uploadWay == 'single') {
            uploadUrl = encodeURI(this.docHttp + this.docService.getSingleUploadFile());
        }
        return new Promise((resolve, reject) => {
            if (this.uploadWay == 'common') {
                this.docService.chunkUploadFile(storage).then((res: any) => {
                    let size: number = storage.size != undefined ? storage.size : 0;
                    let rootId = res.rootId;
                    let id = res.id;
                    if (FileHelper.chunkUploadFolderId == optionParams.folderId) {
                        FileHelper.chunkUploadId += 1;
                    } else {
                        FileHelper.chunkUploadId = 0;
                        FileHelper.chunkUploadFolderId = optionParams.folderId;
                    }
                    const uploadFileParam: UploadFileParam = {
                        id: `WU_FILE_${FileHelper.chunkUploadId}`,
                        name: storage.name == undefined ? '' : storage.name,
                        type: 'image/jpeg',
                        lastModifiedDate: new Date(),
                        size: size,
                        chunkSize: size * 2,
                        chunk: 0,
                        uploadId: res.uploadId,
                        bucketName: res.bucketName,
                        objectKey: res.objectKey,
                        file: new Blob(),
                    }
                    this.fileOptions.params = Object.assign(this.fileOptions.params, uploadFileParam);
                    this.fileTransfer.upload(filePath, uploadUrl, this.fileOptions)
                        .then((data: any) => {
                            if (data.responseCode == 200) {
                                let mergeFileParam = {
                                    id: id,
                                    bucketName: uploadFileParam.bucketName,
                                    busId: optionParams.busId,
                                    busTable: optionParams.busTable,
                                    extName: optionParams.extName,
                                    folderId: optionParams.folderId,
                                    name: uploadFileParam.name,
                                    objectKey: uploadFileParam.objectKey,
                                    rootId: rootId,
                                    size: uploadFileParam.size,
                                    systemId: optionParams.systemId,
                                    uploadId: uploadFileParam.uploadId,
                                    className: res.className,
                                    documentId: res.documentId,
                                    previewUrl: ''
                                }
                                if (storage != undefined && storage.extName != undefined) {
                                    this.resizeImage(filePath, 200, 200, storage.extName).then((r: string) => {
                                        mergeFileParam.previewUrl = r;
                                        const params = Object.assign(MergeFileParam, mergeFileParam);
                                        this.docService.merge(params).then((mr: any) => {
                                            resolve(mr.data);
                                        }, (e: string) => {
                                            reject(e);
                                        });
                                    }, (e: string) => {
                                    });
                                }
                            } else {
                                reject('上传失败');
                            }
                        }, (err: any) => {
                            console.log("error:" + JSON.stringify(err));
                            reject("上传异常");
                        })
                }, (e: string) => {
                    reject(e);
                });
            } else {
                this.fileTransfer.upload(filePath, uploadUrl, this.fileOptions)
                    .then((data: any) => {
                        resolve(data);
                    }, (err: any) => {
                        console.log("error:" + JSON.stringify(err));
                        reject("上传异常");
                    })
            }
        });
    }

    /**
     * 截图
     * @param internetPath 
     * @param width 
     * @param height 
     */
    public resizeImage(filePath: string, width: number, height: number, extName: string): Promise<string> {
        let options = {
            uri: filePath,
            folderName: 'Protonet',
            quality: 100,
            width: width,
            height: height
        } as ImageResizerOptions;
        return new Promise((resolve, reject) => {
            if (extName.toLowerCase() === 'gif') {
                ImageResizer
                    .resize(options)
                    .then((base64Path: string) => {
                        let index = base64Path.lastIndexOf('/');
                        let path = base64Path.substring(0, index);
                        let fileName = base64Path.substring(index + 1);
                        File.readAsDataURL(path, fileName).then((b: any) => {
                            this.deleteNativeFile(path, fileName);
                            resolve(b);
                        }, (f: any) => {
                            console.log(JSON.stringify(f));
                            resolve('');
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        reject('');
                    });
            } else {
                resolve('');
            }
        });
    }

    /**
     * 下载文件
     * @param filePath 
     */
    public downloadFile(filePath: string): Promise<any> {
        const fileName = filePath.substr(filePath.lastIndexOf("/") + 1);
        return new Promise((resolve, reject) => {
            this.fileTransfer.download(filePath, this.fileSystemPath + this.downloadPath + '/' + fileName).then((entry) => {
                resolve(entry.toURL());
            }, (e: any) => {
                reject('');
            });
        });
    }

    /**
     * 监听上传进度
     * @param listenerCallback 
     */
    public uploadListener(listenerCallback: any) {
        this.fileTransfer.onProgress((event: any) => {
            listenerCallback(event);
        });
    }

    /**
     * 向手机发送提示消息
     */
    private async generateNotifs() {
        const notifs = await LocalNotifications.schedule({
            notifications: [
                {
                    title: "提示",
                    body: "你有短消息啦",
                    id: 1,
                    schedule: { at: new Date(Date.now() + 1000 * 2) },
                    sound: "",
                    attachments: undefined,
                    actionTypeId: "",
                    extra: null
                }
            ]
        });
    }

    /**
     * 下载参数对象
     */
    private downloaderRequest: DownloadRequest = {
        uri: "",
        title: '',
        description: '',
        mimeType: '',
        visibleInDownloadsUi: true,
        notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
        destinationInExternalFilesDir: {
            dirType: this.downloadPath,
            subPath: ''
        }
    };

    /**
     * 手机文件下载（android）
     * @param fileId 文件标识
     * @param name 文件名称
     */
    public phoneDownloader(fileId: string, name: string) {
        if (fileId != null && name != null) {
            let token = CookieHelper.get("Authorization") || CookieHelper.get("token");
            this.downloaderRequest.uri = encodeURI(this.docHttp + this.docService.getDownloadFile(fileId) + `?access_token=${token}`);
            this.downloaderRequest.title = name;
            this.downloaderRequest.notificationVisibility = NotificationVisibility.VisibleNotifyOnlyCompletion;
            this.downloaderRequest.destinationInExternalFilesDir = {
                dirType: this.downloadPath,
                subPath: name
            };
            Downloader.download(this.downloaderRequest)
                .then((location: string) => {
                    console.log(location);
                })
                .catch((error: any) => {
                    console.error(error);
                });
        }
    }

    /**
     * 下载文件
     * @param fileId 
     * @param fileName 
     */
    public downloadNative(fileId: string, fileName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (FileHelper.isHasDownload) {
                fileName = this.renameFileName(fileId, fileName);
                this.checkDownloadFile(fileId, fileName).then((r: any) => {
                    resolve(r);
                }, (e: any) => {
                    reject(e);
                });
            }
        });
    }

    public renameFileName(fileId: string, fileName: string) {
        let extName = fileName.substring(fileName.lastIndexOf('.') + 1);
        let fName = fileName.trim().replace("." + extName, '').replace(/\s{1,}/g, '_');
        if (fName.length > 20) {
            fileName = fName.substr(0, 20) + fileId + '.' + extName;
        } else {
            fileName = fName + fileId + '.' + extName;
        }
        return fileName;
    }

    /**
     * 获取原图-base64
     * @param fileId 
     * @param type 图片类型：0、聊天模块，1、进度模块，2、文档模块 
     */
    public downloadOriginal(fileId: string, extName: string, type: number): Promise<string> {
        extName = extName.indexOf('?') > 0 ? extName.substring(0, extName.indexOf("?")) : extName;
        const fileName = fileId + '.' + extName;
        const path = File.cacheDirectory + this.PicFolders[type];
        return new Promise((resolve, reject) => {
            if ((FileHelper.isHasChat && type == 0) || (FileHelper.isHasProgress && type == 1) || (FileHelper.isHasDocument && type == 2) || (FileHelper.isHasMine && type == 3)) {
                let filePath = path + "/" + fileName;
                File.readAsDataURL(path, fileName).then((b: string) => {
                    resolve(b);
                }, (f: any) => {
                    File.createFile(path, fileName, true).then((entry: any) => {
                        this.docService.downloadOriginal(fileId, filePath).then((r: any) => {
                            File.readAsDataURL(path, fileName).then((b: any) => {
                                resolve(b);
                            }, (f: any) => {
                                reject('');
                            });
                        }, (e: any) => {
                            this.deleteNativeFile(path, fileName);
                            reject('');
                        });
                    }, (e: any) => {
                        reject('');
                    });
                });
            } else {
                reject('');
            }
        });
    }

    /**
     * 获取缩略图-base64
     * @param fileId 
     * @param type 图片类型：0、聊天模块，1、进度模块，2、文档模块 ,3、我的
     */
    public downloadThumb(fileId: string, extName: string, type: number): Promise<string> {
        extName = extName.indexOf('?') > 0 ? extName.substring(0, extName.indexOf("?")) : extName;
        const fileName = fileId + '_thumb.' + extName;
        const path = File.cacheDirectory + this.PicFolders[type];
        return new Promise((resolve, reject) => {
            if ((FileHelper.isHasChat && type == 0) || (FileHelper.isHasProgress && type == 1) || (FileHelper.isHasDocument && type == 2) || (FileHelper.isHasMine && type == 3)) {
                let filePath = path + "/" + fileName;
                File.readAsDataURL(path, fileName).then((b: any) => {
                    resolve(b);
                }, (f: any) => {
                    File.createFile(path, fileName, true).then((entry: any) => {
                        this.docService.downloadChatThumb(fileId, filePath).then((r: any) => {
                            File.readAsDataURL(path, fileName).then((b: any) => {
                                resolve(b);
                            }, (f: any) => {
                                reject('');
                            });
                        }, (e: any) => {
                            this.deleteNativeFile(path, fileName);
                            reject('');
                        });
                    }, (e: any) => {
                        reject('');
                    });
                });
            } else {
                reject('');
            }
        });
    }

    private checkDownloadFile(fileId: string, fileName: string): Promise<any> {
        const path = this.fileSystemPath + this.downloadPath + this.appID;
        return new Promise((resolve, reject) => {
            let filePath = path + "/" + fileName;
            filePath = filePath.indexOf(this.fileSystemHead) == 0 ? filePath : (this.fileSystemHead + '//' + filePath);
            File.resolveLocalFilesystemUrl(filePath).then((b: Entry) => {
                this.docService.downloadFile(fileId, filePath).then((r: any) => {
                    resolve(r);
                }, (e: any) => {
                    this.deleteNativeFile(path, fileName);
                    reject(e);
                });
            }, (f: any) => {
                File.createFile(path, fileName, true).then((entry: any) => {
                    this.docService.downloadFile(fileId, filePath).then((r: any) => {
                        resolve(r);
                    }, (e: any) => {
                        this.deleteNativeFile(path, fileName);
                        reject(e);
                    });
                }, (e: any) => {
                    reject(e);
                });
            });
        });
    }

    public deleteNativeFile(path: string, fileName: string) {
        File.removeFile(path, fileName).then((r: any) => {
            console.log('删除文件成功');
        }, (e: any) => {
            console.log('删除文件异常');
        });
    }

    /**
     * 检查文件是否已下载
     * @param fileName 
     */
    public checkExistsFile(fileId: string, fileName: string): Promise<boolean> {
        const path = this.fileSystemPath + this.downloadPath + this.appID;
        return new Promise((resolve, reject) => {
            if (isPlatform('capacitor')) {
                let filePath = path + "/" + this.renameFileName(fileId, fileName);
                filePath = filePath.indexOf(this.fileSystemHead) == 0 ? filePath : (this.fileSystemHead + '//' + filePath);
                File.resolveLocalFilesystemUrl(filePath).then((b: Entry) => {
                    resolve(true);
                }, (f: string) => {
                    reject(false);
                });
            } else {
                reject(false);
            }
        });
    }

    public checkFilePermission(FileObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const params: PermissionParam = {
                id: FileObj.id,
                systemId: FileObj.systemId,
                className: FileObj.className,
                busId: FileObj.busId,
                busTable: FileObj.busTable,
                busTag: FileObj.busTag,
                mainFileId: FileObj.mainFileId,
                name: FileObj.name,
                rootId: FileObj.rootId,
                folderId: FileObj.folderId,
                extName: FileObj.extName,
                size: FileObj.size,
                documentId: FileObj.documentId,
                isDeleted: false,
            }
            this.docService.checkPermission(params).then((r: any) => {
                if (r && r.data && r.data.status == 'OK') {
                    resolve(r.data);
                } else {
                    reject('无权限');
                }
            }, (e: any) => {
                console.log(JSON.stringify(e));
                reject('无权限');
            });
        });
    }

    //图片扩展名
    public readonly imageExts = ["png", "jpg", "jpeg", "gif", "bmp", "svg", "tif", "pcx", "tga", "psd", "raw", "wmf", "webp", "jif"];

    private checkExtExists(ext: string): boolean {
        if (ext != null && ext != "") {
            for (let index = 0; index < this.imageExts.length; index++) {
                if (ext.toLowerCase() === this.imageExts[index]) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * 浏览
     * @param id 记录标识
     * @param name 文件名称
     * @param fileId 文件标识
     */
    public phonePreview(previewImage: string, fileId: string, name: string, isGif = false) {
        if (previewImage != null && fileId != null) {
            let subPath = '';
            let ext = '';
            if (isGif) {
                subPath = previewImage.substring(0, previewImage.indexOf("?"));
                ext = 'gif';
            } else {
                subPath = previewImage;
                //扩展名
                ext = subPath.substr(subPath.lastIndexOf(".") + 1);
            }
            if (name == null || name == '') {
                name = subPath.substring(subPath.lastIndexOf("/") + 1);
                if (subPath.indexOf("?") >= 0) {
                    name = "";
                }
            }
            if (previewImage.startsWith("data:image") || this.checkExtExists(ext)) {
                //图片
                const options = {
                    share: false,
                    closeButton: true,//ios
                    copyToReference: true,//ios
                    headers: '',
                    piccasoOptions: {}
                };
                if (name != null) {
                    const subLen = 60;
                    let len = name.length;
                    let reName = name.replace(/[\u4E00-\u9FA5]/g, '#@');
                    if (reName.length > subLen) {
                        let wordLen = reName.substring(0, subLen).match(/[#@]/g)?.length;
                        len = wordLen != undefined ? subLen - wordLen / 2 : subLen;
                        name = name.substr(0, len) + '...';
                    }
                }
                PhotoViewer.show(this.getPreviewFile(fileId), name, options);
            } else {
                if (previewImage != '') {
                    //其他文件：web端浏览器实现
                    if (!previewImage.startsWith("http")) {
                        previewImage = this.docHttp + previewImage;
                    }
                    const uri = encodeURI(`${this.webPreviewHttp}${previewImage}`);
                    const options: InAppBrowserOptions = {
                        allowInlineMediaPlayback: 'yes',
                        beforeload: 'get',
                        hidenavigationbuttons: 'yes',
                        location: 'yes',
                        closebuttoncolor: '#ffffff',
                        toolbar: 'yes',
                        toolbarcolor: '#3880ff',
                        toolbarposition: 'top',
                        footer: 'no',
                        hardwareback: 'yes',
                        hideurlbar: 'yes',
                        lefttoright: 'yes',
                        fullscreen: 'no'
                    }
                    InAppBrowser.create(uri, isPlatform('ios') ? "_system" : "_self", options);
                }
            }
        }
    }

    /**
     * 预览本地已下载文件
     * @param fileName 
     */
    previewNativeFile(fileId: string, fileName: string) {
        const path = this.fileSystemPath + this.downloadPath + this.appID;
        fileName = this.renameFileName(fileId, fileName);
        PreviewAnyFile.preview(path + "/" + fileName)
            .then((res: any) => console.log(res))
            .catch((error: any) => console.error(error));
    }

    public static imagePreviewInstance: any;
    public static imagePreviewFlag: number = 0;

    /**
     * 分页预览多张图片
     * @param photos 
     * @param index 
     */
    public async previewVant(photos: Array<string>, index: number) {
        if (isPlatform('ios')) {
            this.previewImage(photos, index);
        } else {
            FileHelper.imagePreviewFlag = 0;
            const animation = new AnimationSelf();
            const modal = await modalController
                .create({
                    component: PreviewModal,
                    cssClass: 'preview-modal-class',
                    enterAnimation: animation.modalCenter.enterAnimation,
                    componentProps: {
                        photos: photos,
                        index: index
                    },
                })
            return modal.present();
        }
    }

    public previewImage(photos: Array<string>, index: number) {
        FileHelper.imagePreviewInstance = ImagePreview({
            images: photos, //需要预览的图片 URL 数组
            showIndex: true, //是否显示页码
            loop: false, //是否开启循环播放
            startPosition: index, //图片预览起始位置索引
            closeOnPopstate: true,
            onClose: () => {
                if (!isPlatform('ios')) {
                    if (FileHelper.imagePreviewFlag != 2) {
                        FileHelper.imagePreviewFlag = 1;
                        modalController.dismiss({
                            'dismissed': true,
                        });
                    }
                }
            }
        });
    }

    /**
     * 预览原图
     * @param fileId 文件标识
     */
    public getPreviewFile(fileId: string): string {
        let viewUrl = this.docHttp + this.docService.getPreviewFile(fileId);
        return viewUrl;
    }

    /**
     * 获取浏览地址(缩略图)
     * @param previewUrl 
     */
    public getPreviewPath(previewUrl: string): string {
        const params = previewUrl.substr(previewUrl.lastIndexOf("?"));
        return this.docHttp + this.docService.getPreviewImage() + params;
    }

    /**
     * 获取保存相对路径
     * @param fileId 
     * @param objectKey 
     */
    public getRelativePath(fileId: string, objectKey: string): string {
        let url = this.docService.getPreviewImage();
        if (Setting.apiBaseURL != undefined && Setting.apiBaseURL != '') {
            url = this.docService.getPreviewImage().replace(Setting.apiBaseURL, '');
        }
        return `${url}?id=${fileId}&objectKey=${objectKey}`;
    }

    /**
     * 获取预览下载地址
     * @param fileId 
     */
    public getPreviewDownloadPath(fileId: string): string {
        let token = CookieHelper.get("Authorization") || CookieHelper.get("token");
        return encodeURI(this.docHttp + this.docService.getDownloadFile(fileId) + `?access_token=${token}`);
    }

    /**
     * 新增根文件夹
     */
    public createRootFolder(folder: RootFolder): Promise<any> {
        const params: FolderParams = Object.assign(folder, { rootId: "", parentId: "" });
        return this.docService.createRootFolder(params);
    }

    /**
     * 新增文件夹
     * @param folder 
     */
    public createSubFolder(folder: SubFolder): Promise<any> {
        return new Promise((resolve, reject) => {
            this.docService.getFileByFolderId({ folderId: folder.parentId + "@temporary" }).then(
                (r: any) => {
                    if (r && r.data) {
                        const result = r.data.folder;
                        if (result) {
                            const params: FolderParams = Object.assign({ rootId: result.rootId }, folder);
                            this.docService.createSubFolder(params).then(
                                (r: string) => {
                                    resolve(r);
                                },
                                (e: any) => {
                                    reject(e);
                                }
                            );
                        } else {
                            reject('文件夹不存在');
                        }
                    } else {
                        reject('文件夹不存在');
                    }
                },
                (e: any) => {
                    console.log('异常：' + JSON.stringify(e));
                    reject('文件夹检查异常');
                }
            );
        });
    }

    /**
     * 删除文件(物理文件 + 业务数据)
     * @param fileId 
     */
    public clearFile(fileId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.docService.clearFileById(fileId).then((r: any) => {
                if (r) {
                    this.docService.deleteById(fileId).then((res: any) => {
                        resolve(res);
                    }, (e: any) => {
                        reject(e);
                    });
                } else {
                    reject('');
                }
            }, (e: any) => {
                reject(e);
            });
        });
    }

    /**
     * 获取文件信息
     * @param fileIds 逗号分隔的id
     */
    public getFilesByIds(fileIds: string): Promise<any> {
        return this.docService.getFilesByIds(fileIds);
    }

    /**
     * 根据文件名称获取后缀名
     * @param fileName 文件名称
     */
    public getExpandedName(fileName: string): string {
        let expandedName = fileName.substring(fileName.lastIndexOf(".") + 1);
        let index = expandedName.indexOf("?");
        if (index > 0) {
            expandedName = expandedName.substring(0, index);
        }
        return expandedName.toLowerCase();
    }
}

export interface Picture {
    id: string,
    url: string,
    status: string,
    message: string,
}

export interface UploadOption {
    pictures: Array<Picture>,
    progress: string
}

export enum UploadStatus {
    Uploading = 'uploading',
    Failed = 'failed',
    Done = 'done',
}

export enum UploadMessage {
    Uploading = '上传中...',
    Failed = '上传失败...',
    Done = '上传完成...',
}
