
import { defineComponent, onUnmounted } from "vue";
import Header from "@/components/Header.vue";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import SqlHelper from "@/utils/sql/SqlHelper";
import SystemUtils from "@/utils/SystemUtils";
import { useRouter, useRoute } from "vue-router";
import { getInstance } from "@/services/MicroIssuesService";
import { Network } from "@ionic-native/network";
import Utils from "@/utils/Utils";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import {
  modalController,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from "@ionic/vue";
import MessageSearch from "@/views/message/MessageSearch.vue";

export default defineComponent({
  name: "Message",
  components: {
    Header,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    CardLoading,
  },
  created() {
    // let testMsg: Message = new Message();
    // testMsg.busId = "task@(4f65e18e-8fcb-4bd7-811b-8d2cd7e1da1d)";
    // testMsg.busTable = "tt_topic";
    // testMsg.busTag = {
    //   projectName: "",
    //   id: "task@(4f65e18e-8fcb-4bd7-811b-8d2cd7e1da1d)",
    //   title: "项目唯一题11111",
    //   userName: "王利军",
    //   userColor: "#00a2ae",
    // };
    // testMsg.chatGroupId = "5ca0b754-fb27-4938-be43-64a15cb86074";
    // testMsg.content = "1234567";
    // testMsg.id = "task@(8f2682bb-b9e6-426b-ade9-8699d60c20d0)";
    // testMsg.isAt = false;
    // testMsg.isList = true;
    // testMsg.messageType = 1;
    // testMsg.sendTime = "2021-01-20";
    // testMsg.sendUserId = "32948";
    // testMsg.sendUserName = "王利军";
    // testMsg.systemId = "task";
    // this.messageList.push(testMsg);

    // let testMsg1: Message = new Message();
    // testMsg1.busId = "dd7e5290-7a6b-4a39-9043-8fafda73a205";
    // testMsg1.busTable = "cal_schedule";
    // testMsg1.busTag = {
    //   projectId: "",
    //   projectName: "",
    //   taskId: "",
    //   title: "钱培庆的0118日历22",
    //   userName: "钱培庆",
    //   userColor: "#00a2ae",
    // };
    // testMsg1.chatGroupId = "dd362074-2811-4db1-a086-2fef4213254b";
    // testMsg1.content =
    //   "修改了日程（钱培庆的0118日历22）：【日程时间】：2021年01月22日11:31-11:31   【地点】：西三旗   【参与人】：钱培庆，李宗科   【需要反馈】";
    // testMsg1.id = "task@(c9566022-0589-4126-a3a5-ddb00548efe5)";
    // testMsg1.isAt = false;
    // testMsg1.isList = true;
    // testMsg1.messageType = 3;
    // testMsg1.sendTime = "13:09:24";
    // testMsg1.sendUserId = "67751";
    // testMsg1.sendUserName = "钱培庆";
    // testMsg1.systemId = "task";
    // this.messageList.push(testMsg1);

    //无网络连接
    if (Network.type === "none" || Network.type === "NONE") {
      Utils.presentToastWarning("网络连接失败，请检查网络");
    }
    this.selectLocalMessage();

    Bus.$on("receive_home_message", () => {
      this.selectLocalMessage();
    });

    Bus.$on("receive_mq_connected", (connect: boolean) => {
      this.isConnect = connect;
    });

    this.isConnect = Utils.mqttConnected;
  },

  setup() {
    onUnmounted(() => {
      Bus.$off("receive_home_message");
      Bus.$off("receive_mq_connected");
    });
  },
  data() {
    const router = useRouter();
    const sqlHelper = new SqlHelper();
    return {
      btnIconRight: ["iconsousuo"],
      btnSizeRight: ["34px"],
      messageList: Array<Message>(0),
      sqlHelper: sqlHelper,
      router: router,
      isConnect: false,
      islist: false,
    };
  },
  props: {
    isHome: String,
    searchKey: {
      type: String,
      required: false,
      default: "",
    },
  },
  watch: {
    searchKey(newVal) {
      if (newVal == undefined) {
        newVal = "";
      }
      //加载消息列表
    },
  },
  methods: {
    selectLocalMessage(offset: number = 0) {
      this.sqlHelper
        .selectHomeMessageList(SystemUtils.loginUser.id, offset)
        .then(
          (r: any) => {
            this.analysisData(r);
            if (offset == 0) this.messageList = r;
            else this.messageList = this.messageList.concat(r);
            this.islist = true;
          },
          (e: string) => {
            console.log(e);
            this.islist = true;
          }
        )
        .catch((e: string) => {
          console.log(e);
          this.islist = true;
        });
    },

    doRefresh(event: any) {
      this.selectLocalMessage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },

    nextPage(event: any) {
      this.selectLocalMessage(this.messageList.length);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },

    analysisData(messages: Array<Message>) {
      if (messages != null && messages.length > 0) {
        for (let message of messages) {
          let busTag = JSON.parse(message.busTag as string);
          message.busTag = busTag;
          if (message.file && message.file != "undefined") {
            let file = JSON.parse(message.file as string);
            message.file = file;
          }
          this.sqlHelper.selectHomeMessageList3(message);
        }
      }
    },

    openMessage(message: Message, index: number) {
      message.messageState = 2;
      message.count = 0;
      // 跳转消息详情页
      this.router.push({
        name: "ChatPage",
        params: {
          chatGroupId: message.chatGroupId as string,
          busId: message.busId as string,
          busTable: message.busTable as string,
          busTag: JSON.stringify(message.busTag),
        },
      });
    },

    random(max: number, min: number = 1) {
      return Math.floor(Math.random() * (max - min)) + 1;
    },

    randomTime(): string {
      let number = Math.floor(Math.random() * (60 - 1));
      return number < 10 ? "0"+number : number+"";
    },

    async searchMessage() {
      // for (let i = 0; i < 20; i++) {
      //   let message = {
      //     id:
      //       "task@(eeb88246-4cf2-4fd9-9cb2-60ac3262084a)" + this.random(10000),
      //     creatorId: "",
      //     creator: "",
      //     createTime: "",
      //     modifierId: "",
      //     modifier: "",
      //     modifyTime: "",
      //     dataState: -1,
      //     content: "测试消息" + this.random(300),
      //     messageType: 1,
      //     sendTime:
      //       "2021-04-01 10:" + this.randomTime() + ":" + this.randomTime(),
      //     sendUserId: "1f813a38-6ccc-4f0c-9f1f-3cc79b6ea14c",
      //     sendUserName: "王旭东",
      //     systemId: "task",
      //     chatMessageMongoId: "464fda1d-0e2a-41cc-8c24-51c3bf31a4a0",
      //     chatGroupId:
      //       "msg@chatgroup(projects@task(97264ccf-2f18-49de-a91c-fa34590222a7))" +
      //       this.random(100),
      //     busId: "projects@task(97264ccf-2f18-49de-a91c-fa34590222a7)",
      //     busTag:
      //       '{"projectId":"projects@task(97264ccf-2f18-49de-a91c-fa34590222a7)","projectName":"wxd测试项目","taskId":"","title":"wxd测试项目","userName":"王旭东","userColor":"#00a2ae"}',
      //     busTable: "tt_project",
      //     toUserId: "1f813a38-6ccc-4f0c-9f1f-3cc79b6ea14c",
      //     url: "",
      //     enterpriseId: "",
      //     remark: "",
      //     messageState: 2,
      //     messageCount: "",
      //     messageInfo: [],
      //     isDeleted: false,
      //     isAt: false,
      //     isList: true,
      //     title: "wxd测试项目模拟数据",
      //   };
      //   this.sqlHelper.insertMessageData(message);
      // }

      const modal = await modalController.create({
        component: MessageSearch,
        cssClass: "full-modal-class",
        // componentProps: {
        // },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
      }
    },

    deleteMessage(msg: Message, index: number) {
      let el = <HTMLIonItemSlidingElement>document.getElementById("setItemRef"+index);
      if(el) el.close();
      const i = this.messageList.indexOf(msg);
      this.messageList.splice(i, 1);
      this.sqlHelper.deleteMessageByChatGroupId(
        msg.chatGroupId as string,
        true
      );
    },
  },
});
