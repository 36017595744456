/**
 * 日历订阅-订阅申请 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class CalendarSubscribeApplyApi extends TaskBaseService {
    constructor() {
        super("calApply");
    }

     // 订阅审核列表
     public getCalApplyVoList(params: any): Promise<any> {
        const path = 'getCalApplyVoList';
        return this.request(path, this.rest.METHOD_GET, params);
    }
     // 订阅审核-操作
     public approve(params: any): Promise<any> {
        const path = 'approve';
        return this.request(path, this.rest.METHOD_POST, params);
    }
    //申请记录    
    public getApplyRecord(params: any): Promise<any> {
       const path = 'getApplyRecord';
       return this.request(path, this.rest.METHOD_GET, params);
   }
   // 批量申请-操作
   public insertList(params: any): Promise<any> {
      const path = 'insertList';
      return this.request(path, this.rest.METHOD_POST, params);
  }

}