
import {
  modalController,
  IonPage,
  // IonList,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "../../components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { getInstance1 } from "@/services/selectexecutor/selectExecutorTeamService";
import SelectTeamStaff from "@/components/selectexecutor/SelectTeamStaff.vue";
import { AnimationSelf } from "@/libs/animation";

export default defineComponent({
  name: "SelectMyTeam",
  components: {
    IonPage,
    HeaderModal,
    // IonList,
    IonItem,
    SearchBar,
  },
  data() {
    const seleceExecutorTeamService = getInstance1();
    return {
      teamList: new Array(0),

      navLines: new Array(0),
      seleceExecutorTeamService: seleceExecutorTeamService,

      navCheck: 0,
      navFolders: [0],

      //   noticeService: noticeService,
    };
  },
  props: {
    intentExecutorSelectList: Object,
    organize: Object,
    isCheckBox: Boolean,
  },
  created() {
    //加载团队列表
    console.log(this.organize);

    if (this.organize) {
      this.loadTeamList(this.organize.orgId, 0, 0);
    }
  },
  methods: {
    filterNavs() {
      const navs = ["一级", "二级", "三级", "四级", "五级", "六级", "七级"];
      const res = navs.filter((value, index, arr) => {
        return index <= this.navCheck;
      });
      this.navLines = res;
    },

    bindFolderGrade(check: number) {
      this.loadTeamList(this.navFolders[check], check, 0);
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type == 3) {
        modalController.dismiss({
          isReturn: false,
        });
      } else if (type == 4) {
        modalController.dismiss();
      }
    },
    loadTeamList(pId: number, type: number, position: number) {
      let params = {};
      if (pId != 0) {
        params = {
          parentId: pId + "",
        };
      }
      this.seleceExecutorTeamService.getOrganizationTeamList(params).then(
        (r: any) => {
          if (r) {
            // this.folderList = r.data;

            if (r.data.data.length == 0) {
              this.intentteamstaff(position);
            } else {
              this.teamList = r.data.data;
              if (type == -1) {
                this.navCheck += 1;
              } else {
                this.navCheck = type;
              }
              this.navFolders[this.navCheck] = pId;
              this.filterNavs();
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    isIntent(fId: number, position: number) {
      this.loadTeamList(fId, -1, position);
    },
    //跳转至模态窗
    async intentteamstaff(posotion: number) {
      const result = AnimationSelf.prototype.modalRight;
      const modal = await modalController.create({
        component: SelectTeamStaff,
        // component: LabeDetailPage,
        cssClass: "notice-modal-class",
        mode: "ios",
        enterAnimation: result.enterAnimation,
        leaveAnimation: result.leaveAnimation,
        backdropDismiss: true,
        componentProps: {
          intentExecutorSelectList: this.intentExecutorSelectList,
          tsOrgId: this.teamList[posotion].eId,
          isCheckBox: this.isCheckBox,
        },
      });

      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.isReturn) {
          console.log("我的团队" + data.executorSuperiorSelectList),
            modalController.dismiss({
              isReturn: true,
              executorSuperiorSelectList: data.executorSuperiorSelectList,
            });
        }
      }
    },
  },
});
