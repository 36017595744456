/**
 * projectGroups 项目分组控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectGroupsApi extends TaskBaseService {

    constructor() {
        super("projectGroups");
    }
    //获取all项目分组
    public getByUserId(params: any): Promise<any> {
       const path = 'getByUserId';
       return this.request(path,this.rest.METHOD_GET, params);       
    }

    //获取顶级节点任务分组
    public getAllNodeByUserId(params: any): Promise<any> {
        const path = 'getAllNodeByUserId';
        return this.request(path,this.rest.METHOD_GET, params);       
     }
     //根据父ID获取下级分组
    public getChildNodeByParentId(parentId : any): Promise<any> {
        const path = 'getChildNodeByParentId?parentId='+parentId;
        return this.request(path,this.rest.METHOD_GET,null );       
     }
    //获取项目信息
    public getByProjectId(params: any): Promise<any> {
        const path = 'getByProjectId';
        return this.request(path,this.rest.METHOD_GET, params);       
    }

}