/**
 * calendar 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class CalendarDetailApi extends TaskBaseService {
    constructor() {
        super("calDetail");
    }

    // 保存
    public saveCalendarDetail(params: any): Promise<any> {
        const path = 'saveCalendarDetail?scheduleId='+params.scheduleId;
        return this.request(path, this.rest.METHOD_POST, params.calendarIds);
    }

    // 按Id逻辑删除实体
    public deleteById(params: any): Promise<any> {
        const path ='recycle/'+ params.id;
        return this.request(path, this.rest.METHOD_DELETE, {});
    }

    // 按Id逻辑删除实体 批量
    public deleteByIds(params: any): Promise<any> {
        const path ='recycleList';
        return this.request(path, this.rest.METHOD_POST, params.ids);
    }

}