
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import MyCalendar from "./MyCalendar.vue";
import MySubscribe from "./MySubscribe.vue";
import SubscribeApprova from "./SubscribeApprova.vue";
import SubscribeApprovaHistory from "./SubscribeApprovaHistory.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "SettingCalendar",
  components: {
    Header,
    MyCalendar,
    MySubscribe,
    SubscribeApprova,
    SubscribeApprovaHistory,
    SearchBar,
  },
  emits:['segmentChanage'],
  data() {
    const router = useRouter();
    const indicators = ["我的日历", "我的订阅", "订阅审核", "订阅历史"];
    return {
      router: router,
      useRoute: useRoute(),
      indicators: indicators,
      selSeg: 0,
      isLoad: false,
      refreshMyCalendar: false, //是否要刷新我的日历页面
      refreshMySubscribe: false, //是否要刷新我的订阅页面
      refreshSubscribeApprova: false, //是否刷新订阅审核页面
      refreshSubscribeApprovaHistory: false, ///是否刷新订阅历史页面
      searchKey: "",
      placeholder: "根据日历名称搜索",
      searchParamsCalendar: {
        searchKey: "",
      },
      searchParamsSubscribe: {
        searchKey: "",
      },
      searchParamsApprova: {
        searchKey: "",
      },
      searchParamsHistory: {
        searchKey: "",
      },
    };
  },
  created() {
    if (this.useRoute.params && this.useRoute.params.param) {
    }
    this.initPageValue();
  },
  ionViewWillEnter() {
    let that = this;
    that.isLoad = true;
    if (that.selSeg == 0) {
      that.refreshMyCalendar = true;
      setTimeout(function () {
        that.refreshMyCalendar = false;
      }, 1000);
    }
  },
  mounted() {
    if (!this.isLoad) {
    }
  }, 
  methods: {
    initPageValue() {},
    segmentChanged(ev: CustomEvent) {
      this.selSeg = ev.detail.value;
      switch (this.selSeg) {
        case 0:
          this.placeholder = "根据日历名称搜索";
          break;
        case 1:
          this.placeholder = "根据日历名称/姓名搜索";
          break;
        case 2:
          this.placeholder = "根据日历名称/姓名搜索";
          break;
        case 3:
          this.placeholder = "根据日历名称/姓名搜索";
          break;
      }
      this.$emit("segmentChanage", this.selSeg);
    },
    goBack() {
      this.router.go(-1);
    },
    //创建新的日历
    create() {
      const info = {
        // rootId: this.task.rootId,
        // orgId: this.task.orgId,
        action: "create",
      };
      this.router.push({
        name: "add-calendar",
        params: {
          info: JSON.stringify(info),
        },
      });
    },
    //输入文字搜索
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchKey = searchParam.searchKey;
      
      let that = this;
      switch (that.selSeg+"") {
        case "0":
          that.searchParamsCalendar.searchKey = this.searchKey;
          that.refreshMyCalendar = true;
          setTimeout(function () {
            that.refreshMyCalendar = false;
          }, 500);
          break;
        case "1":
          that.searchParamsSubscribe.searchKey = this.searchKey;
          that.refreshMySubscribe = true;
          setTimeout(function () {
            that.refreshMySubscribe = false;
          }, 500);
          break;
        case "2":
          that.searchParamsApprova.searchKey = this.searchKey;
          that.refreshSubscribeApprova = true;
          setTimeout(function () {
            that.refreshSubscribeApprova = false;
          }, 500);
          break;
        case "3":
          that.searchParamsHistory.searchKey = this.searchKey;
          that.refreshSubscribeApprovaHistory = true;
          setTimeout(function () {
            that.refreshSubscribeApprovaHistory = false;
          }, 500);
          break;
      }
    },
  },
});
