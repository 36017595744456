
import { defineComponent, ref } from "vue";
import ConditionPop from "@/components/popover/ConditionPop.vue";
import BaseService from "@/services/base/BaseService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "SearchBar",
  props: {
    queryType: String,
    isModal: {
      //是否需要弹出模态框
      type: Boolean,
      required: false,
      default: false,
    },
    isCancel: Boolean,
    theme: String,
    placeholder: String,
    isAudio: Boolean,
    hasCondition: String,
    searchValue: String,
    toSearchId: String,
  },
  emits: ["on-Shade", "on-enter", "on-login", "on-change"],
  components: {
    ConditionPop,
  },
  watch: {
    searchValue(newVal: string, oldVal: string) {
      console.log('监听',newVal)
      this.searchKey = newVal;
    },
  },
  data() {
    return {
      cancelType: "never",
      placeholderValue: this.placeholder,
      themeValue: this.theme,
      len: 30,
      searchKey: "",
      showConditionPop: false,
      showIconPop: true,
      allowPickCondition: false,
      searchId: "", //选中的Id
      searchName: "", //选中的条件名称
      conditionParam: {}, //选中的条件内容
      $refs: {
        conditionRef: ConditionPop,
      },
      baseService: new BaseService(),
      isShow: true,
    };
  },

  created() {
    if (this.toSearchId) this.searchId = this.toSearchId;
    if (!this.placeholderValue) {
      this.placeholderValue = "搜索";
    }
    this.themeValue = !this.themeValue ? "default" : "theme" + this.theme;
    if (this.allowPickCondition) {
      this.themeValue += " cond-search";
    }
    this.cancelType = this.isCancel ? "focus" : "never";
    if (this.searchValue) {
      this.searchKey = this.searchValue;
    }
  },
  methods: {
    /**
     * @param 是否只清空文本，不搜索
     */
    bindSearchModal(isNotSerachDate?: boolean) {
      this.isShow = true;
      if (!isNotSerachDate) {
        this.setResultEmitter("", this.isModal, false);
      } else {
        this.searchKey = "";
      }
    },
    bindOnChangeEvent(event: any) {
      if (event != null && event.detail != null) {
        this.searchKey = event.detail.value;
        if (this.searchKey == "") {
          this.isShow = true;
        }
        this.$emit("on-change", {
          searchKey: this.searchKey,
        });
      }
    },
    bindSearchEnter(event: any) {
      this.isShow = false;
      const keycode = window.event ? event.keyCode : event.which;
      if (keycode === 13) {
        this.searchKey = this.searchKey.slice(0, this.len);
        this.setResultEmitter(this.searchKey, this.isModal, false);
      }
    },
    bindSerchInput() {
      this.searchKey = this.searchKey.slice(0, this.len);
    },
    bindSerchBlur() {
      // this.isShow=true;
      this.searchKey = this.searchKey.slice(0, this.len);
      this.showIconPop = true;
    },
    bindFocuEvent(event: any) {
      // this.isShow=false;
      if (this.isModal) {
        this.setResultEmitter("", this.isModal, true);
      } else {
        this.showIconPop = false;
        this.showConditionPop = false;
      }
    },
    setResultEmitter(sk: string, isModal: boolean, isFocus: boolean) {
      sk = this.baseService.trim(sk);
      if (sk && Utils.isInCludeEmoji(sk)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }

      this.$emit("on-enter", {
        searchKey: sk,
        isModal: isModal,
        isfocus: isFocus,
      });
    },
    login() {
      this.$emit("on-login");
    },
  },
});
