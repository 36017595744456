/**
 * projectUserstars 项目收藏 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目收藏/取消收藏
 */
export class ProjectUserstarsApi extends TaskBaseService {

    constructor() {
        super("projectUserstars");
    }
    //项目收藏/取消收藏
    public updateForProjectList(params: any): Promise<any> {
        const path = "updateForProjectList?userId=" + params.userId + "&&id=" + params.id + "&&projectId=" + params.projectId + "&&isStart=" + params.isStart + "&&systemId=" + params.systemId;
        return this.request(path, this.rest.METHOD_POST, null);
    }

}