
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import Calendar from "@/components/Calendar.vue";
import ScheduleCard from "@/components/ListCard/ScheduleCard.vue";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import moment from "moment";
import Utils from "@/utils/Utils";
import ScheduleService from "@/services/calendar/ScheduleService";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
export default defineComponent({
  name: "OtherCalendar",
  components: {
    Calendar,
    ScheduleCard,
    TaskCards,
    CardLoading,
  },
  props: {
    refresh: Boolean, //是否刷新页面
    params: Object, //搜索条件
  },
  setup(props) {
    const { refresh, params } = toRefs(props);
    const router = useRouter();
    const pointDate = new Date();
    const scheduleService = new ScheduleService();
    const scheduleList = reactive({ data: new Array() });
    const dateMarkers = reactive({ data: new Array() });
    let searchParams = reactive({
      limit: 10,
      offset: 1,
      sort: "",
      schduleDate: moment(pointDate).format("YYYY-MM-DD"),
    });
    //滚动到顶部
    const scrollToTop = () => {
      nextTick(() => {
        let ionContent: any = document.querySelector("#otherCalendarMain");
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    };
    const isMore = ref(false);
    let islist = ref(true); //是否显示加载中的标识
    const initData = () => {
      resetPage();
      //searchParams.schduleDate 所在的月的第1天，最后一天
      const firstDay = searchParams.schduleDate.substring(0, 7) + "-01";
      let days = new Date(
        parseInt(searchParams.schduleDate.substring(0, 4)),
        parseInt(searchParams.schduleDate.substring(5, 7)),
        0
      ).getDate();
      const lastDay = searchParams.schduleDate.substring(0, 7) + "-" + days;
      getCountByMonth(firstDay, lastDay);
    };
    const bindResultEmit = (r: any) => {
      if (r) {
        //选择时间结果
        if (r.type == 0||r.type == 2) {
          searchParams.schduleDate = r.pointDay;
          resetPage();
           //上一月/下一月
          if (r.type == 2) {
            const firstDay = moment(new Date(r.firstDay)).format("YYYY-MM-DD");
            const lastDay = moment(new Date(r.lastDay)).format("YYYY-MM-DD");
            getCountByMonth(firstDay, lastDay);
          }
        }
        //更多
        if (r.type == 1) {
        }
      }
    };
    const getCountByMonth = (firstDay: any, lastDay: any) => {
      let searchParamsStr = JSON.stringify(searchParams);
      let newParams = JSON.parse(searchParamsStr);
      newParams.firstDay = firstDay;
      newParams.lastDay = lastDay;
      scheduleService
        .getOtherCountByMonth(newParams)
        .then((res: any) => {
          dateMarkers.data = res;
        })
        .catch(() => {
          islist.value = false;
        });
    };
    //重新从第一页获取
    const resetPage = (event?: any) => {
      searchParams.offset = 1;
      getListRequest((res: any) => {
        scheduleList.data = res;
        if (event) {
          setTimeout(() => {
            event.target.complete();
          }, 1000);
        }
        scrollToTop();
      });
    };
    const nextPage = (event: any) => {
      searchParams.offset++;
      getListRequest((result: any) => {
        setTimeout(() => {
          event.target.complete();
        }, 1000);
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            scheduleList.data = scheduleList.data.concat(result);
          }
        }
      });
    };
    const doRefresh = (event: any) => {
      resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      scheduleService
        .getOtherScheduleList(searchParams)
        .then((res: any) => {
          if (res && res.data) {
            islist.value = false;
            callback(res.data);
          }
        })
        .catch(() => {
          islist.value = false;
        });
    };
    //详情
    const detailHandler = (params: any) => {
      router.push({
        path: "/schedule/detail/feedback",
        query: {
          id: params.item.id,
          scheduleTimeId: params.item.scheduleTimeId,
          showCalendar: "false",
        },
      });
    };
    onMounted(() => {
      Bus.$on("receive_message_schedule", (data: Message) => {
        if (
          data.content?.includes("创建") ||
          data.content?.includes("修改") ||
          data.content?.includes("删除")
        ) {
          resetPage();
        }
      });
      if (params != undefined && params.value != undefined) {
        searchParams = Object.assign(searchParams, params.value);
      }
      initData();
    });
    onUnmounted(() => {
      Bus.$off("receive_message_schedule");
    });

    watch(refresh, (newValue, oldVlaue) => {
      if (newValue) {
        searchParams.offset = 1;
        if (params != undefined && params.value != undefined) {
          searchParams = Object.assign(searchParams, params.value);
        }
        initData();
      }
    });
    return {
      islist,
      router,
      scheduleService,
      pointDate,
      scheduleList,
      searchParams,
      isMore,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      bindResultEmit,
      detailHandler,
      dateMarkers,
    };
  },
  // watch: {
  //   refresh() {
  //     if (this.refresh) {
  //       console.log("refresh");
  //       this.searchParams.offset = 1;
  //       this.searchParams = Object.assign(this.searchParams, this.params);
  //       this.initData();
  //     }
  //   },
  // },
});
