
import { defineComponent, reactive, toRefs, onBeforeMount } from "vue";
import { popoverController } from "@ionic/vue";

export default defineComponent({
  name: "RolePopover",
  props: {
    roleId: String,
    list: Array,
  },
  setup(props) {
    const { roleId, list } = toRefs(props);
    //reactive内只能传对象{}
    const roleList = reactive({
      data: list?.value,
    });
    const bindRoleChange = (e: any) => {
      let roleName = "";
      if (roleList.data) {
        roleList.data.forEach((item: any) => {
          if (e == item.id) {
            roleName = item.name;
          }
        });
      }
      popoverController.dismiss({
        dismissed: true,
        id: e,
        name: roleName,
      });
    };
    return { roleList, roleId, bindRoleChange };
  },
});
