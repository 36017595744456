
import { defineComponent, toRefs, ref, reactive, onMounted } from "vue";
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { TaskScheduleService } from "@/services/task/TaskScheduleService";
import moment from "moment";
import { FileHelper } from "@/utils/FileHelper";
import { SingleFile } from "@/models/file/Storage";
import Utils from "@/utils/Utils";
import { nextTick } from "process";
import { RecordService } from "@/services/attendance/record/RecordService";
import SystemUtils from "@/utils/SystemUtils";
import Setting from "@/rest/Setting";
import regExps from "@/utils/regExps";
import { TaskService } from "@/services/task/TaskService";
export default defineComponent({
  name: "UpdateScheduleModal",
  components: {
    HeaderModal,
  },
  props: {
    startTime: String,
    endTime: String,
    taskProgress: Number,
    hour: Number,
    taskId: String,
    folderId: String,
    actualProgress: Object,
    isIndependentTask: Boolean,
    isfinish: Boolean,
  },
  setup(props) {
    onMounted(() => {
      initData();
    });
    const {
      startTime,
      endTime,
      taskProgress,
      hour,
      taskId,
      folderId,
      isIndependentTask,
      isfinish,
    } = toRefs(props);

    const scheduleServices = new TaskScheduleService();
    const taskApi = new TaskService();
    //考勤城市信息
    const recordService = new RecordService();
    const cityWorkingHours = ref(8);
    // const user: any = SystemUtils.loginUser;
    const user = ref(SystemUtils.loginUser);
    const historyPage: number = 1;
    const nowDate = moment(new Date()).format("YYYY-MM-DD");
    const workingHoursList = reactive([
      {
        taskId: "",
        folderId: "",
        startTime: nowDate,
        endTime: "",
        progress: "",
        workingHours: "0",
        useWorkingHours: 0,
        nightWorkingHours: "0",
        useNightWorkingHours: 0,
        showEndTime: "",
        remark: "",
      },
    ]);
    const historyList = reactive(new Array());
    const getHistory = () => {
      scheduleServices
        .getTaskScheduleList({
          offset: historyPage,
          taskId: taskId != undefined ? taskId.value : "",
        })
        .then(
          (r: any) => {
            if (r) {
              r.forEach((item: any) => {
                item.startTime = moment(item.startTime).format("YYYY-MM-DD");
                item.endTime = moment(item.endTime).format("YYYY-MM-DD");
                historyList.push(item);
              });
            }
          },
          (e: any) => {}
        );
    };
    let workingHourDetail = reactive({
      parentPlanWorkingHour: "",
      parentSumActualWorkingHour: "",
      currentTaskPlanWorkingHour: "",
      currentTaskSumChildrenWorkingHours: "",
    });
    const maxworkingHour = ref();
    const getWorkingHour = () => {
      taskApi
        .getWorkingHourByTaskId(taskId != undefined ? taskId.value : "")
        .then((res: any) => {
          console.log(JSON.stringify(res));
          workingHourDetail.parentPlanWorkingHour = res.parentPlanWorkingHour;
          workingHourDetail.parentSumActualWorkingHour =
            res.parentSumActualWorkingHour;
          workingHourDetail.currentTaskPlanWorkingHour =
            res.currentTaskPlanWorkingHour;
          workingHourDetail.currentTaskSumChildrenWorkingHours =
            res.currentTaskSumChildrenWorkingHours;
          console.log(workingHourDetail);
          maxworkingHour.value =
            Number(workingHourDetail.currentTaskPlanWorkingHour) -
            Number(workingHourDetail.currentTaskSumChildrenWorkingHours);
          console.log("最大可填工时" + maxworkingHour.value);
        });
    };
    const initData = () => {
      let params = { userId: user.value.id };
      recordService
        .getAttenCity(params)
        .then((res: any) => {
          cityWorkingHours.value = res.workHourse;
        })
        .catch(() => {});
      getHistory();
      getWorkingHour();
    };

    //添加日期
    const addWorking = () => {
      let onflag = ref(true);
      workingHoursList.forEach((item: any) => {
        if (item.startTime == "") {
          onflag.value = false;
          Utils.presentToastWarning("还有未填写的工时哦！");
        }
      });
      if (onflag.value) {
        let obj = {
          taskId: "",
          folderId: "",
          startTime: "",
          endTime: "",
          progress: "",
          workingHours: "0",
          useWorkingHours: 0,
          nightWorkingHours: "0",
          useNightWorkingHours: 0,
          showEndTime: "",
          remark: "",
        };
        workingHoursList.push(obj);
        let i = workingHoursList.length - 1;
        setTimeout(() => {
          let a: any = document.querySelector("#root" + i);
          a.scrollIntoView({
            behavior: "auto",
            block: "start",
            inline: "start",
          });
        }, 500);
      }
    };

    //删除日期
    const removeWorking = (index: number) => {
      workingHoursList.splice(index, 1);
      if (
        workingHoursList.filter((item: any) => item.needTicket == 1).length > 0
      ) {
        // isUser = true;
      } else {
        // isUser = false;
      }
    };

    //日期变化 计算白天可用工时 和 夜晚可用工时
    const ChangeStartTime = (ev: any, obj: any) => {
      let onflag = ref(true);
      obj.startTime = moment(ev.detail.value).format("YYYY-MM-DD");
      let a = sureDate(obj.startTime);
      if (a > 0) {
        onflag.value = false;
      }
      let WorkingNum = new Array();
      workingHoursList.forEach((itemWorking: any) => {
        if (itemWorking.startTime != "") {
          itemWorking.startTime = moment(itemWorking.startTime).format(
            "YYYY-MM-DD"
          );
        }
        if (itemWorking.startTime === obj.startTime) {
          WorkingNum.push("0");
        }
        if (WorkingNum.length > 1) {
          Utils.presentToastWarning("工时日期不可以重复,请重新选择");
          onflag.value = false;
        }
      });

      if (onflag.value) {
        let params = { date: obj.startTime, userId: user.value.id };
        scheduleServices.listByUserAndDate(params).then((res: any) => {
          const workingHoursSum = ref(0);
          const nightWorkingHoursSum = ref(0);
          res.forEach((resItem: any) => {
            workingHoursSum.value += Number(resItem.workingHours);
            nightWorkingHoursSum.value += Number(resItem.workingHours);
          });
          obj.useWorkingHours = cityWorkingHours.value - workingHoursSum.value;
          let Sum = 24 - cityWorkingHours.value - nightWorkingHoursSum.value;
          obj.useNightWorkingHours = Sum;
        });
      } else {
        // setTimeout(() => {
        //   obj.startTime = "";
        //   console.log(workingHoursList);
        // });
      }
    };
    //历史数据日期是否已存在
    const sureDate = (date: any) => {
      let WorkingNum = new Array();
      historyList.forEach((itemWorking: any) => {
        if (itemWorking.startTime != "") {
          itemWorking.startTime = moment(itemWorking.startTime).format(
            "YYYY-MM-DD"
          );
        }
        if (itemWorking.startTime === date) {
          WorkingNum.push(itemWorking.startTime);
        }
        if (WorkingNum.length >= 1) {
          Utils.presentToastWarning(
            "此日期已填写工时，请先删除此日期的填报记录"
          );
        }
      });
      return WorkingNum.length;
    };
    //删除历史
    const removeHistory = (item: any, index: any) => {
      Utils.confirmDialog("确认", "请确认是否删除工时", function () {
        scheduleServices.clearById(item.id).then((res: any) => {
          Utils.presentToast("删除成功！");
          historyList.splice(index, 1);
        });
      });
    };

    const save = () => {
      const onflag = ref(true);
      let workingArr = reactive(new Array());
      workingHoursList.forEach((item) => {
        let obj = {
          taskId: item.taskId,
          folderId: item.folderId,
          startTime: item.startTime,
          endTime: item.endTime,
          progress: item.progress,
          workingHours: item.workingHours,
          nightWorkingHours: item.nightWorkingHours,
          showEndTime: item.showEndTime,
          id: Utils.getUuid,
          creatorId: user.value.id,
          creator: user.value.name,
          modifierId: user.value.id,
          modifier: user.value.name,
          isDeleted: false,
          remark: item.remark,
          enterpriseId: Setting.currentEnterprise.id,
        };
        if (obj.startTime != "") {
          workingArr.push(obj);
        }
      });
      if (workingHoursList[workingHoursList.length - 1].workingHours == "0.0") {
        Utils.presentToastWarning("请添加工时数值，此值不能为0");
        return;
      }
      workingArr = workingArr.concat(historyList);

      let flag = true;
      for (let i = 1; i < workingArr.length; i++) {
        if (workingArr[0].startTime == workingArr[i].startTime) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        Utils.presentToastWarning("此日期已填写工时，请先删除此日期的填报记录");
        return;
      }

      if (workingArr.length > 0) {
        let workingHoursNum = 0;
        workingHoursList.forEach((item: any) => {
          if (item.startTime == "") {
            Utils.presentToastWarning("请填写工时日期");
            onflag.value = false;
          }
          workingHoursNum += item.workingHours;
          // if (item.workingHours == 0 && item.nightWorkingHours == 0) {
          //   Utils.presentToastWarning("请填写工时");
          //   onflag.value = false;
          // }
          if (Utils.isInCludeEmoji(item.remark)) {
            Utils.presentToastWarning("不能输入表情或特殊字符哦！");
            onflag.value = false;
          }
        });
        for (let i = 0; i < workingHoursList.length - 1; i++) {
          const element1 = workingHoursList[i];

          for (let j = i + 1; j < workingHoursList.length; j++) {
            const element2 = workingHoursList[j];

            console.log(element1.startTime + "------" + element2.startTime);

            if (element1.startTime == element2.startTime) {
              Utils.presentToastWarning("工时日期不可以重复,请重新选择");
              onflag.value = false;
            }
          }
        }
        if (workingHoursNum > maxworkingHour.value) {
          Utils.presentToastWarning("任务总工时超过计划工时！");
          onflag.value = false;
        }
      }

      if (onflag.value == true) {
        const confirmText = ref("请确认是否提交工时");
        if (isfinish.value) {
          confirmText.value =
            "请确认是否提交工时,提交完成之后工时信息将不可修改！";
        }
        Utils.confirmDialog("确认", confirmText.value, function () {
          formatData(workingArr);
        });
      }
    };
    const submit = (arr: any) => {
      if (taskId != undefined && taskId.value != undefined) {
        let params = {
          taskId: taskId.value,
          taskProgress: arr,
        };
        scheduleServices.saveBatchList(params).then((res: any) => {
          Utils.presentToast("已提交您的工时信息！");
          modalController.dismiss({ isOk: true });
        });
      }
    };
    const formatData = (arr: any) => {
      arr.forEach(
        (item: {
          taskId: string;
          folderId: string;
          startTime: moment.MomentInput;
          endTime: any;
        }) => {
          if (
            taskId != undefined &&
            taskId.value != undefined &&
            folderId != null &&
            folderId.value != undefined
          ) {
            item.taskId = taskId.value;
            item.folderId = folderId.value;
            item.startTime = moment(item.startTime).format(
              "YYYY-MM-DD 00:00:00"
            );
            item.endTime = item.startTime;
          }
        }
      );
      submit(arr);
    };
    const closeModel = (r: any) => {
      if (r == 2 || r == 4) {
        nextTick(() => {
          save();
        });
      } else {
        modalController.dismiss();
      }
    };

    return {
      user,
      closeModel,
      workingHoursList,
      addWorking,
      removeWorking,
      ChangeStartTime,
      historyList,
      removeHistory,
      maxworkingHour,
    };
  },
});
