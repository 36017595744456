class OkrUserInfo {

    public id?: string;            //主键id
    public oid?: string;            //  目标id
    public userId?: string;      // 用户唯一ID
    public userName?: string;         // 名称
    public account?: string;       //账号
    public partNameCn?: string;        // 用户所属部门中文名
    public partNameEn?: string;        // 用户所属部门中文名
    public partId?: string;   //部门id
    public type?: Number;   //人员类型
    constructor() {
        // this.id = item.id;
        // this.userId = item.userId;
        // this.name = item.name;
        // this.photo = item.photo;
        // this.pernr = item.pernr;
        // this.department = item.department;
    }

}

export {
    OkrUserInfo,
    OkrUserInfo as default
}