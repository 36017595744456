import CoreService from '@/api/base/CoreService';
export default class UserBaseService extends CoreService {
    constructor(resource: string) {
        super({
            apiBaseURL: "attapi",
            name: "",
            resource: resource,
            version: ""
        });
    }
}