import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")
  const _component_ion_radio = _resolveComponent("ion-radio")
  const _component_ion_item = _resolveComponent("ion-item")
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")
  const _component_ion_list = _resolveComponent("ion-list")

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_ctx.roleList.data.length > 0)
        ? (_openBlock(), _createBlock(_component_ion_radio_group, {
            key: 0,
            onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.bindRoleChange($event.detail.value))),
            value: _ctx.roleId
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.roleList.data, (item) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: item.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_radio, {
                      value: item.id
                    }, null, 8, ["value"])
                  ]),
                  _: 2
                }, 1024))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}