import { ScheduleRelationApi } from '@/api/schedule/ScheduleRelationApi';
import { rejects } from 'assert';

import BaseService from '../base/BaseService';

/**
 * 日程
 */
export class ScheduleRelationService extends BaseService {
    private static ScheduleRelationApi = new ScheduleRelationApi();
   //任务关联日程
    public taskLinkSchedule(parms: any): any {
        return new Promise((resolve, reject) => {
            ScheduleRelationService.ScheduleRelationApi.taskLinkSchedule(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //刪除日程 deleteLinkSchedule
    public deleteLinkSchedule(parms:any):any{
        return new Promise((resolve,reject)=>{
            ScheduleRelationService.ScheduleRelationApi.deleteLinkSchedule(parms).then(async (res:any)=>{
                resolve(res);
            }).catch((err:any)=>{
            reject(err)
        })
    })
}
}