
    import { defineComponent } from "vue";
    import {DashboardService} from "@/services/dashboardService";
    export default defineComponent({
        name: "modelProductInfo",
        data() {
            return {
                api: new DashboardService(),
                projectId:"",
                data:{
                    modelCode:"",
                    productNameZh:"",
                    productNameDe:"",
                    productNameEn:"",
                    modelNameZh:"",
                    modelYear:"",
                    modelYearChangeDate:"",
                    productValidity:"",
                    productComment:"",
                }
            }
        },
        methods: {
            getCarTypeProduct() {
                this.api.getCarTypeProduct({projectId:this.projectId}).then(value => {
                    if(value.data.data){
                        this.data=value.data.data;
                    }
                });
            },
        },
        mounted() {
            this.projectId=this.$route.query.projectId as any;
            this.getCarTypeProduct();

        },
    });
