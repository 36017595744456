import { CalendarSubscribeApplyApi } from '@/api/calendar/CalendarSubscribeApplyApi';

import BaseService from '../base/BaseService';
import SubscribeApplyInfo from '@/models/calendar/SubscribeApplyInfo'

/**
 * 日历订阅申请模块
 */
export class CalendarSubscribeApplyService extends BaseService {
    private static api = new CalendarSubscribeApplyApi();

    // 订阅审核列表
    public getCalApplyVoList({ offset = 1, limit = 10,searchKey='' } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarSubscribeApplyService.api.getCalApplyVoList({
                offset,
                limit,
                searchKey
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    // 订阅审核：status 1同意，2 拒绝
    /**
     * 
     * @param parms {applyId:"",calId:"",calName:"",color:"",userId:"",userName:"",status:""}
     */
    public approve(parms: SubscribeApplyInfo): any {
        return new Promise((resolve, reject) => {
            CalendarSubscribeApplyService.api.approve(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //获取申请记录    
    public getApplyRecord(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarSubscribeApplyService.api.getApplyRecord(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //批量申请
    public insertList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            CalendarSubscribeApplyService.api.insertList(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    

}