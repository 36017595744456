
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import Calendar from "@/components/Calendar.vue";
import ScheduleCard from "@/components/ListCard/ScheduleCard.vue";
import { ScheduleService } from "@/services/schedule/ScheduleService";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
export default defineComponent({
  name: "MyCalendar",
  components: {
    Calendar,
    ScheduleCard,
    CardLoading,
  },
  props: {
    refresh: {
      type: Boolean,
      required: false,
      default: false,
      refresh: Boolean,
    }, //是否刷新页面
    params: {}, //搜索条件
    isHome: String,
    homeSearchParam: String, //页面接收参数
    searchKey: {
      type: String,
      required: false,
      default: "",
    },
    condition: Number, //0：清空所有条件，1:本页面搜索框输入，2：本页面选择日历本，3：筛选条件页面查询，4:本页面搜索框输入清空
    hasPage: Boolean, //是否有ion-page包裹
  },
  emits: ["onselectdate"],
  setup(props,{ emit }) {
    const {
      refresh,
      params,
      isHome,
      homeSearchParam,
      searchKey,
      condition,
      hasPage,
    } = toRefs(props);
    const router = useRouter();
    let pointDate: any;
    if (isHome != undefined && isHome.value != "yes") {
      pointDate = new Date();
    }
    let islist = ref(true); //是否显示加载中的标识
    let searchParams = reactive({
      limit: 10,
      offset: 1,
      schduleDate: moment(pointDate).format("YYYY-MM-DD"),
      scheduleName: "",
      calendarId: "",
      sort: "",
      searchKey: "",
    });
    const currentUser: any = SystemUtils.loginUser;
    const currentUserId = currentUser.id;
    const scheduleList = reactive({ data: new Array() });
    const dateMarkers = reactive({ data: new Array() });
    let selectDate=searchParams.schduleDate;

    //滚动到顶部
    const scrollToTop = () => {
      nextTick(() => {
        let ionContent: any = document.querySelector("#myCalendarMain");
        // let ionContent:any=this.$refs.parojectcontent;
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    };
    const initData = () => {
      if (homeSearchParam != undefined && homeSearchParam.value) {
        const _search: any = JSON.parse(homeSearchParam.value);
        searchParams = Object.assign(searchParams, _search);
      }
      resetPage();
      changeSelectDate();
      //searchParams.schduleDate 所在的月的第1天，最后一天
      const firstDay = searchParams.schduleDate.substring(0, 7) + "-01";
      let days = new Date(
        parseInt(searchParams.schduleDate.substring(0, 4)),
        parseInt(searchParams.schduleDate.substring(5, 7)),
        0
      ).getDate();
      const lastDay = searchParams.schduleDate.substring(0, 7) + "-" + days;
      getCountByMonth(firstDay, lastDay);
    };
    const bindResultEmit = (r: any) => {
      if (r) {
        console.log(JSON.stringify(r));
        //选择时间结果
        if (r.type == 0 || r.type == 2) {
          const startTime = moment(new Date(r.pointDay)).format("YYYY-MM-DD");
          searchParams.schduleDate = startTime;
          selectDate=startTime;
          changeSelectDate();
          resetPage();
          //上一月/下一月
          if (r.type == 2) {
            const firstDay = moment(new Date(r.firstDay)).format("YYYY-MM-DD");
            const lastDay = moment(new Date(r.lastDay)).format("YYYY-MM-DD");
            getCountByMonth(firstDay, lastDay);
          }
        }
        //更多
        else if (r.type == 1) {
        }
      }
    };
    const getCountByMonth = (firstDay: any, lastDay: any) => {
      if (isHome == undefined||(isHome != undefined && isHome.value != "yes")) {
        if (
          condition != undefined &&
          (condition.value == 1 || condition.value == 3)
        ) {
          searchParams.schduleDate = "";
        } else {
          searchParams.schduleDate = moment(
            searchParams.schduleDate == ""
              ? pointDate
              : searchParams.schduleDate
          ).format("YYYY-MM-DD");
        }
        if (isHome != undefined && isHome.value == "yes") {
          searchParams.schduleDate = "";
        }
        let searchParamsStr = JSON.stringify(searchParams);
        let newParams = JSON.parse(searchParamsStr);
        newParams.firstDay = firstDay;
        newParams.lastDay = lastDay;
        scheduleService
          .getCountByMonth(newParams)
          .then((res: any) => {
            dateMarkers.data = res;
          })
          .catch(() => {
            islist.value = false;
          });
      }
    };
    //重新从第一页获取
    const resetPage = () => {
      searchParams.offset = 1;
      getListRequest((res: any) => {
        scheduleList.data = res;
        scrollToTop();
      });
    };
    const nextPage = (event: any) => {
      searchParams.offset++;
      getListRequest(
        (result: any) => {
          scheduleList.data = scheduleList.data.concat(result);
          setTimeout(() => {
            event.target.complete();
          }, 1000);
          if (result) {
            if (result == null || result.length <= 0) {
              //  event.target.disabled = true;
            }
          }
        },
        () => {
          event.target.complete();
        }
      );
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };
    const getListRequest = (callback: any, errCalback?: any) => {
      if (
        condition != undefined &&
        (condition.value == 1 || condition.value == 3)
      ) {
        searchParams.schduleDate = "";
      } else {
        searchParams.schduleDate = moment(
          searchParams.schduleDate == "" ? pointDate : searchParams.schduleDate
        ).format("YYYY-MM-DD");
      }
      if (isHome != undefined && isHome.value == "yes") {
        searchParams.schduleDate = "";
      }
      scheduleService
        .getMyScheduleList(searchParams)
        .then((res: any) => {
          res.data.forEach((element: any) => {
            if (element.typeValue + "" == "") {
              element.isSure = false;
            } else if (element.typeValue + "" == "0") {
              if (element.creatorId == currentUserId) {
                element.isSure = false;
              } else {
                element.isSure = true;
              }
            } else {
              element.isSure = false;
            }
          });
          islist.value = false;
          callback(res.data);
        })
        .catch(() => {
          islist.value = false;
          if (errCalback) {
            errCalback();
          }
        });
    };
    //详情
    const detailHandler = (params: any) => {
      router.push({
        path: "/schedule/detail/feedback",
        query: {
          id: params.item.id,
          scheduleTimeId: params.item.scheduleTimeId,
          showCalendar: "true",
        },
      });
    };
    const changeSelectDate=()=>{
      emit("onselectdate", selectDate);
    };

    onMounted(() => {
      Bus.$on("receive_message_schedule", (data: Message) => {
        if (
          data.content?.includes("创建") ||
          data.content?.includes("修改") ||
          data.content?.includes("删除")
        ) {
          resetPage();
        }
      });

      searchParams.searchKey = searchKey.value;
      if (params != undefined && params.value != undefined) {
        searchParams = Object.assign(searchParams, params.value);
      }
      if (!refresh.value) initData();
    });
    onUnmounted(() => {
      Bus.$off("receive_message_schedule");
    });

    let updateCard = false;
    const scheduleService = new ScheduleService();
    let isMore = false;
    watch([refresh, searchKey], (newValue, oldVlaue) => {
      if (newValue[0]) {
        if (searchParams) {
          searchParams.offset = 1;
          if (params != undefined && params.value != undefined) {
            searchParams = Object.assign(searchParams, params.value);
          }
          // initData();
        }
      }
      if (newValue[1] != oldVlaue[1]) {
        console.log("watch-searchKey");
        searchParams.searchKey = newValue[1].toString();
        initData();
      }
    });
    return {
      islist,
      router,
      pointDate,
      scheduleList,
      searchParams,
      updateCard,
      scheduleService,
      isMore,
      currentUserId,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      bindResultEmit,
      detailHandler,
      dateMarkers,
      selectDate,
    };
  },
});
