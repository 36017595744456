
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import DocService from "@/services/doc/docService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "FolderTree",
  components: {
    modalController,
  },
  props: {
    projectId: String,
  },
  data() {
    const router = useRouter();
    return {
      folderList: new Array(0),
      folderOriList: new Array(0),
      docService: new DocService(),
      router: router,
      useRoute: useRoute(),
      searchParams: {
        projectId: this.projectId,
      },
      page: 0,
      showFolderId: "", //当前显示的文件夹id
      title: "还原至",
      tempRootArr: new Array(0),
      tempTitleArr: new Array(0),
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.resetPage();
    },
    //获取
    resetPage() {
      this.getListRequest((res: any) => {
        if (res == null || res.length <= 0) {
          this.folderList = [];
          Utils.presentToast("暂无数据");
        } else {
          this.folderList = res;
          this.tempRootArr.push(this.folderList);
          this.tempTitleArr.push(this.folderList[0].name);
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.docService.getTreeByProjectId(this.searchParams).then((res: any) => {
        this.folderOriList = res.data;
        callback(res.data);
      });
    },
    //选中文件夹
    selectOption(option: any) {
      modalController.dismiss({ folderId: option.id });
    },
    closeModel() {
      modalController.dismiss();
    },
    //下一级
    clickNextGroup(option: any) {
      if (option.childNodes) {
        this.showFolderId = option.id;
        this.page++;
        this.folderList = option.childNodes;
        this.tempRootArr.push(this.folderList);
        this.title = option.name;
        this.tempTitleArr.push(this.title);
      } else {
      }
    },
    //上一级
    clickLastGroup() {
      if (this.page <= 0) return;
      this.tempRootArr.splice(this.page, 1);
      this.page--;
      this.folderList = this.tempRootArr[this.page];
      this.tempTitleArr.splice(this.page, 1);
      this.title = this.tempTitleArr[this.page];
    },
  },
});
