
import {
  modalController,
  IonPage,
  // IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonCheckbox,
} from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import { GroupService } from "@/services/okr/GroupService";
import AvatarList from "@/components/AvatarList.vue";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "SelectGroupUser",
  components: {
    IonPage,
    HeaderModal,
    // IonList,
    IonItem,
    SearchBar,
    IonCheckbox,
    AvatarList,
  },
  data() {
    return {
      groupService: new GroupService(),
      executorAllList: new Array(0),
      executorAllOldList: new Array(0),
      executorDepartmentList: new Array(0),

      executorSelectList: new Array(0),
      departmentName: "",

      //是否请求过数据了
      isHttp: false,
      isSelected: "",
    };
  },
  props: {
    intentExecutorSelectList: Object,
    groupId: Number,
    isCheckBox: Boolean,
  },
  mounted() {
    if (this.intentExecutorSelectList) {
      for (
        let position = 0;
        position < this.intentExecutorSelectList.length;
        position++
      ) {
        const element = this.intentExecutorSelectList[position];
        this.executorSelectList.push(
          Object.assign(element, { isChecked: true })
        );
      }
    }
    this.loadData("");
  },
  // created() {},
  methods: {
    avatarDataAllList(item: any) {
      let obj = { name: item.name, img: "", id: item.userId };
      return obj;
    },
    clickCheckRow(userInfo: any) {
      const indexSelected = this.executorSelectList.findIndex((item) => {
        return item.userId == userInfo.userId;
      });
      const allIndex = this.executorAllList.findIndex((a) => {
        return a.userId == userInfo.userId;
      });
      const ck = this.executorAllList[allIndex].isChecked;
      if (indexSelected > -1) {
        this.executorAllList[allIndex].isChecked = false;
      } else {
        this.executorAllList[allIndex].isChecked = true;
      }
    },
    loadData(searchKey: string) {
      const params = {
        groupId: this.groupId,
        name: searchKey,
      };

      this.groupService.getGroupUserList(params).then(
        (r: any) => {
          if (r) {
            this.isHttp = true;
            this.executorAllList = r;

            if (this.executorSelectList != null) {
              if (this.executorSelectList.length != 0) {
                for (
                  let position = 0;
                  position < this.executorSelectList.length;
                  position++
                ) {
                  const element = this.executorSelectList[position];
                  const allIndex = this.executorAllList.findIndex((a) => {
                    return a.userId == element.userId;
                  });

                  if (allIndex > -1) {
                    const temObje = this.executorAllList[allIndex];
                    temObje.isChecked = true;
                    this.executorAllList.splice(allIndex, 1, temObje);
                  }
                }
              }
            }
            for (let i = 0; i < this.executorAllList.length; i++) {
              this.executorAllOldList.push(this.executorAllList[i]);
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    isChecked(index: any) {
      console.log("isChecked");
      if (this.executorSelectList.length != 0) {
        for (
          let position = 0;
          position < this.executorSelectList.length;
          position++
        ) {
          const element1 = this.executorSelectList[position];
          if (this.executorAllList[index].userId == element1.userId) {
            this.isSelected = element1.userId;
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },

    SelectStaffInfo(ev: any, index: number) {
      const checkObj = this.executorAllList[index];
      checkObj.isChecked = ev.detail.checked;
      if (checkObj.isChecked) {
        const allIndex = this.executorSelectList.findIndex((a) => {
          return a.userId == checkObj.userId;
        });
        if (allIndex <= -1) {
          if (this.isCheckBox == false) {
            this.executorSelectList.splice(0, this.executorSelectList.length);
            for (
              let index1 = 0;
              index1 < this.executorAllList.length;
              index1++
            ) {
              const element = this.executorAllList[index1];
              if (index1 != index) {
                element.isChecked = false;
              }
            }
          }
          this.executorSelectList.push(this.executorAllList[index]);
        }
      } else {
        for (
          let index1 = 0;
          index1 < this.executorSelectList.length;
          index1++
        ) {
          const element = this.executorSelectList[index1];
          if (element.userId == this.executorAllList[index].userId) {
            this.executorSelectList.splice(index1, 1);
          }
        }
      }
    },

    //搜索框模糊查询
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.loadData(searchParam.searchKey);
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      if (type == 3) {
        modalController.dismiss({
          isReturn: false,
        });
      } else if (type == 4) {
        modalController.dismiss({
          isReturn: true,
          executorSuperiorSelectList: this.executorSelectList,
        });
      }
    },
  },
});
