
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { TaskService } from "@/services/task/TaskService";
import { TaskUserService } from "@/services/task/TaskUserService";
import { EUserWorkType } from "@/models/task/Enum";
//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectExecutorByProject from "@/components/selectexecutor/SeleceExecutorByProject.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import SelectVisibleProject from "@/views/project/selectVisibleProject/selectVisibleProjectModel.vue";
import SelectLable from "@/views/task/info/selectlable/SelectLable.vue";
import ProjectGroupingModal from "../../modal/ProjectGroupingModal.vue";
import { ProjectService } from "@/services/project/projectService";
import { TaskStageService } from "@/services/task/TaskStageService";
import TagLink from "@/services/task/model/TagLink";
import Tag from "@/services/task/model/Tag";
import User from "@/models/task/User";
import Setting from "@/rest/Setting";
import { Network } from "@ionic-native/network";
import Bus from "@/utils/bus";

export default defineComponent({
  name: "AddTask",
  components: {
    IonPage,
    IonContent,
    IonText,
    IonInput,
    Header,
    ProjectGroupingModal,
  },
  data() {
    const router = useRouter();
    const taskInfo: any = {
      priority: "0",
      projectId: "",
      executorUsers: [],
      partUsers: [],
    };
    const _tagArray: Array<Tag> = new Array();
    const currentUser: User = SystemUtils.loginUser;
    //获取当前人信息
    const user = {
      userId: currentUser.id,
      name: currentUser.name,
      photo: currentUser.photo,
      pernr: currentUser.pernr,
      department: currentUser.department,
    };
    return {
      taskApi: new TaskService(),
      projectService: new ProjectService(),
      stageService: new TaskStageService(),
      taskUserApi: new TaskUserService(),
      router: router,
      useRoute: useRoute(),
      more: false,
      task: taskInfo,
      isNewTask: true, //是否是新建任务
      isChildTask: false, //是否是子任务
      allowChangeProject: true, //是否允许修改项目
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      group: {
        id: "",
        name: "",
      }, //分组
      tagLink: _tagArray,
      tagGroupId: "", //标签分组Id
      isSVW: Setting.isSVW,
      currentUser: user,
      isLookOver: false,
      isDepenceTask: true, //true:项目任务 false：独立任务
      isRelevance: "",
      fromType: 0, //0:项目及项目阶段在‘更多’中。1：项目及项目阶段在单独的div（项目详情-任务列表视图-添加任务时此处为1） root中
    };
  },
  created() {
    console.log(this.useRoute.params.task);

    //无网络连接
    this.isLookOver = false;
    if (Network.type === "none" || Network.type === "NONE") {
      Utils.presentToastWarning("网络连接失败，请检查网络");
    }
    this.isDepenceTask = true;
    if (this.useRoute.params) {
      if (this.useRoute.params.isRelevance)
        this.isRelevance = this.useRoute.params.isRelevance as string;
      if (this.useRoute.params.task) {
        const strtask = this.useRoute.params.task as string;
        this.task = Object.assign(this.task, JSON.parse(strtask));
        this.isNewTask = !this.task.id;
        this.task = this.taskApi.getNewModel(this.task);
        this.isChildTask = this.task.parentId ? true : false;
        this.allowChangeProject = this.task.projectId ? false : true;
      }
      if (this.useRoute.params.isDepenceTask) {
        this.isDepenceTask =
          this.useRoute.params.isDepenceTask == "false" ? false : true;
      }
      if (
        this.useRoute.params.fromType != null &&
        this.useRoute.params.fromType != ""
      ) {
        this.fromType = parseInt(this.useRoute.params.fromType as string);
      }
      //是否是独立任务
      if (this.allowChangeProject) {
        this.more = true;
      }

      if (this.useRoute.params.tagGroupId) {
        this.tagGroupId = this.useRoute.params.tagGroupId as string;
      }
      let nowDate = new Date();
      this.task.startTime = moment(nowDate).format("YYYY-MM-DD");
      this.task.endTime = this.task.startTime;

      this.task.difficulty = "1";
    }
    this.initPageValue();
  },
  methods: {
    initPageValue() {
      //默认当前人是执行人，但是当创建子任务时，不指定执行人
      //如果taskUser没有值，那就去默认值
      //所有新建任务都去掉默认执行人
      /*if (!this.isChildTask) {
        if (this.task.executorUsers && this.task.executorUsers.length == 0) {
          this.task.executorUsers.push(
            Object.assign(this.currentUser, {
              workType: EUserWorkType.Executor,
            })
          );
        }
      }*/
      if (this.task.partUsers && this.task.partUsers.length == 0) {
        //默认当前人就是参与人
        this.task.partUsers.push(
          Object.assign(this.currentUser, { workType: EUserWorkType.Party })
        );
      }

      //标签
      if (this.task.tagLink && this.task.tagLink.length > 0) {
        this.task.tagLink.map((e: TagLink) => {
          this.tagLink.push({
            id: e.tagId,
            name: e.tagName,
            color: e.tagColor,
            groupId: e.groupId,
          });
        });
      }
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    bindTaskPriority(e: any) {
      this.task.priority = e.detail.value;
    },
    bindTaskDifficulty(e: any) {
      this.task.difficulty = e.detail.value;
    },
    bindTaskVisibility(e: any) {
      this.task.taskVisibility = e.detail.value;
    },
    ismore() {
      this.more = !this.more;
    },
    //清空时间
    resetTime() {
      this.task.startTime = "";
      this.task.endTime = "";
    },
    pickUserHandler(type: Number) {
      //选择执行人
      if (type == 1) {
        if (this.task.projectId) {
          this.selectTeamUserExecutor(type);
        } else {
          this.selectUserFromAllIn(true);
        }
      } else {
        if (this.task.projectId) {
          this.selectTeamUserExecutor(type);
        } else {
          this.selectUserFromAllIn(false);
        }
      }
    },
    //从项目内成员选择人
    async selectTeamUserExecutor(type: Number) {
      let postUser = [];
      if (type == 1) {
        postUser = this.task.executorUsers;
      } else {
        postUser = this.task.partUsers;
      }
      const modal = await modalController.create({
        component: SelectExecutorByProject,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            projectId: this.task.projectId,
            executorList: postUser,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        if (type == 1) {
          this.task.executorUsers = data.data;
        } else {
          this.task.partUsers = this.addCurrentUser(data.data);
        }
      }
    },
    //从全员-选择执行人、参与人
    async selectUserFromAllIn(isExecutor: boolean) {
      let isCkeckbox = isExecutor ? false : true;
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            isCkeckbox: isCkeckbox,
            type: isExecutor ? "executor" : "joiner",
            executorList: isExecutor
              ? this.task.executorUsers
              : this.task.partUsers,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        if (isExecutor) {
          this.task.executorUsers = data.data;
        } else {
          this.task.partUsers = this.addCurrentUser(data.data);
        }
      }
    },
    addCurrentUser(data: Array<any>) {
      if (!data) {
        data = [];
      }
      const idx = data.findIndex((o) => {
        return o.userId == this.currentUser.userId;
      });
      if (idx < 0) {
        //默认当前人就是参与人
        data.push(
          Object.assign(this.currentUser, {
            workType: EUserWorkType.Party,
          })
        );
      }
      return data;
    },
    //选择项目
    async chooseProjectHandler() {
      if (!this.allowChangeProject) return;
      let paramProject = {};
      if (this.task.projectId) {
        paramProject = {
          id: this.task.projectId,
          name: this.task.projectName,
        };
      }
      const modal = await modalController.create({
        component: SelectVisibleProject,
        cssClass: "notice-modal-class",
        componentProps: {
          source: 0,
          paramProject: paramProject,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedProject) {
        if (this.task.projectId != data.selectedProject.id) {
          this.resetTaskBaseInfo();
          this.task.projectId = data.selectedProject.id;
          this.task.projectName = data.selectedProject.name;
          //获取分组和阶段，都取值第一项
          this.projectService
            .getByProjectIdForMennu({
              projectId: this.task.projectId,
            })
            .then((res: any) => {
              if (res.data && res.data.length > 0) {
                this.group = res.data[0];
                this.task.taskGroupId = this.group.id;
                this.task.taskGroupName = this.group.name;
                //获取阶段
                this.stageService
                  .getStageListByProjecctAndTaskGroupId({
                    projectId: this.task.projectId,
                    taskGroupId: this.group.id,
                  })
                  .then((res: any) => {
                    if (res.data && res.data.length > 0) {
                      this.tagGroupId = res.data[0].tagGroupId;
                      this.task.stageId = res.data[0].taskStage.id;
                      this.task.stageName = res.data[0].taskStage.name;
                    }
                  });
              }
            });
        }
        if (!data.selectedProject.id) {
          this.resetTaskBaseInfo();
        }
      }
      if (this.isNewTask) {
        //可见性，有项目，默认项目成员可见。无项目默认任务成员可见。
        this.task.taskVisibility = this.task.projectId ? "2" : "3";
      }
    },
    resetTaskBaseInfo() {
      this.task.projectId = "";
      this.task.projectName = "";
      this.task.taskGroupId = "";
      this.task.taskGroupName = "";
      this.task.stageId = "";
      this.task.stageName = "";
      this.group = {
        id: "",
        name: "",
      };
      //标签
      this.tagLink = [];
      //执行人、参与人改为当前人
      //默认当前人是执行人，但是当创建子任务时，不指定执行人
      this.task.executorUsers = [];
      this.task.partUsers = [];

      if (!this.isChildTask) {
        this.task.executorUsers.push(
          Object.assign(this.currentUser, {
            workType: EUserWorkType.Executor,
          })
        );
      }
      //默认当前人就是参与人
      this.task.partUsers.push(
        Object.assign(this.currentUser, {
          workType: EUserWorkType.Party,
        })
      );
    },
    //选择分组与阶段
    async chooseGroupStage() {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.task.taskGroupId, //分组Id
          isSelPhases: true, //是否选择阶段
          selPhaseId: this.task.stageId, //阶段Id
          projectId: this.task.projectId, //项目Id
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.staged) {
        this.group = data.group;
        this.tagGroupId = data.staged.tagGroupId;
        this.task.stageId = data.staged.taskStage.id;
        this.task.stageName = data.staged.taskStage.name;
        this.task.taskGroupId = data.staged.taskStage.taskGroupId;
        this.task.taskGroupName = data.group.name;
      }
    },
    //选择标签
    async chooseTags() {
      const modal = await modalController.create({
        component: SelectLable,
        cssClass: "notice-modal-class",
        componentProps: {
          data: {
            busId: "",
            type: "create",
            checkedLabelList: JSON.parse(JSON.stringify(this.tagLink)),
            groupId: this.tagGroupId,
            busTable: "tt_task",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.checkedLabelList) {
        this.tagLink = data.checkedLabelList;
      }
    },
    //获取格式化后的用户
    parseUserFormat(type: number, list: Array<any>) {
      const taskUsers = [];
      if (list) {
        for (let i = 0; i < list.length; i++) {
          taskUsers.push(
            this.taskUserApi.getNewUserModel({
              userId: list[i].userId,
              name: list[i].name,
              workType: type,
              taskId: this.task.id,
              projectId: this.task.projectId ? this.task.projectId : "",
            })
          );
        }
      }
      return taskUsers;
    },
    validateForm() {
      if (!this.task.name || !this.task.name.replace(/\s+/g, "")) {
        Utils.presentToastWarning("请输入任务名称");
        return false;
      }

      if (Utils.isInCludeEmoji(this.task.name)) {
        Utils.presentToastWarning("任务名称不可以包含表情");
        return false;
      }
      //计划工时，只能是大于0的数字
      if (this.task.planWorkingHour > this.task.maxWorkingHours) {
        Utils.presentToastWarning("子任务计划工时之和不能超过父任务计划工时！");
        return false;
      }
      if (
        typeof this.task.planWorkingHour == "string" &&
        this.task.planWorkingHour.replace(/\s+/g, "")
      ) {
        var reg = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,1}$/;
        if (!reg.test(this.task.planWorkingHour)) {
          Utils.presentToastWarning("计划工时必须是正数");
          return false;
        }
      }

      //开始结束时间
      if (this.task.startTime > this.task.endTime) {
        Utils.presentToastWarning("开始时间不能晚于结束时间");
        return false;
      }
      return true;
    },
    saveHandler() {
      if (!this.validateForm()) {
        return;
      }
      if (this.fromType == 1) {
        if (this.task.stageId == null || this.task.stageId == "") {
          Utils.presentToastWarning("请选择所属阶段");
          return false;
        }
      }

      if (Utils.isInCludeEmoji(this.task.remark)) {
        Utils.presentToastWarning("任务备注不可以包含表情");
        return false;
      }

      //参与人 执行人
      let newTask: any = {};
      if (this.task.executorUsers || this.task.partUsers) {
        const executorUsers = this.parseUserFormat(
          EUserWorkType.Executor,
          this.task.executorUsers
        ); //执行人类型
        const partUsers = this.parseUserFormat(
          EUserWorkType.Party,
          this.task.partUsers
        ); //参与人类型
        this.task.taskUser = executorUsers.concat(partUsers);
        newTask = JSON.parse(JSON.stringify(this.task));
        delete newTask.executorUsers;
        delete newTask.partUsers;
      }

      this.taskApi.insertTask(newTask, this.tagLink).then((task: any) => {
        if (task) {
          Utils.presentToast("保存成功");
          if (this.isRelevance) {
            Bus.$emit("receive_task_add_successful", task);
            this.router.go(-1);
          } else
            this.router.replace({
              path: "/task-page",
              query: {
                id: this.task.id,
                taskName: this.task.name,
              },
            });
        } else {
          Utils.presentToastWarning("保存失败");
        }
      });
    },
    hexToRgba(hex: string) {
      return Utils.hexToRgba(hex, 0.2);
    },
    deleteTag(index: number) {
      this.tagLink.splice(index, 1);
    },
    //选择可见性
    chooseVisibility() {
      let selectOperate: any = document.getElementById(
        "select-Visibility-task"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
    choosePriority() {
      let selectOperate: any = document.getElementById("select-Priority-task");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    chooseDiffculty() {
      let selectOperate: any = document.getElementById(
        "select-Difficulty-task"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
  },
});
