import { SQLite } from "@ionic-native/sqlite";

//原生插件
import { SQLiteObject, SQLiteOriginal, DbTransaction } from '@ionic-native/sqlite';

//自定义服务
import SqliteConstants from './SqliteConstants';


/**
 * SQLite数据库
 * @export
 * @class SQLService
 */
export default class SQLService {

  //window对象
  private win: any = window;
  //数据库对象
  private database?: SQLiteObject;

  private static instance: SQLService;
  private constructor() { }
  public static getInstance() {
    if (!this.instance) {
      this.instance = new SQLService();
    }
    return this.instance;
  }

  /**
   * 自动判断环境创建sqlite数据库
   * @memberof SQLService
   */
  public initDB() {
    if (!this.win.sqlitePlugin) {
      //window.openDatabase("数据库名字", "版本","数据库描述",数据库大小);
      this.database = this.win.openDatabase(SqliteConstants.dbName, SqliteConstants.dbVersion,
        SqliteConstants.describe, SqliteConstants.maxSize);
      return;
    }

    SQLite.create({
      name: SqliteConstants.dbName,
      location: 'default'
    }).then((db) => {
      console.log("db created");
      this.database = db;
      console.log(this.database);
    }).catch(err => {
      console.log("db create failure");
      console.log(err);
    });
  }

  // 执行查询sql
  executeSelectSql(sql: string, params: any = []): Promise<any> {
    console.log("executeSelectSql 执行查询")
    if (this.database && '_objectInstance' in this.database) {
      console.log("executeSelectSql 开始执行")
      this.database.open();
      return this.database.executeSql(sql,params);
    } 
    return new Promise((resolve, reject) => {
      reject("executeSelectSql(): 初始化sqlite数据库失败");
    });
  }

  // 执行sql批处理
  executeSqlBatch(sqls: string[]): Promise<any> {
    if (this.database && '_objectInstance' in this.database) {
      this.database.open();
      return this.database.sqlBatch(sqls);
    } else {
      return new Promise((resolve, reject) => {
        reject("executeSqlBatch(): 初始化sqlite数据库失败");
      });
    }
  }


  /**
   * 执行sql语句
   * @param {string} sql
   * @param {*} [params={}]
   * @returns {Promise<any>}
   * @memberof SQLService
   */
  executeSql(sql: string, params: any = []): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.database && '_objectInstance' in this.database) {
          this.database.open();
          this.database.transaction((tx: DbTransaction) => {
            console.log(sql);
            tx.executeSql(sql, params, (success: any) => {
              resolve({ tx: tx, res: success });
            }, (error: any) => {
              reject({ tx: tx, err: error });
            });
          });
          // this.database.close();
        }else{
          return new Promise((resolve, reject) => {
            reject("executeSql(): 初始化sqlite数据库失败");
          });
        }
      }
      catch (err) {
        reject({ err: err });
      }
    });
  }

}