/**
 * 日历订阅 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class CalendarSubscribeApi extends TaskBaseService {
    constructor() {
        super("calSubscribe");
    }

     // 我的订阅列表
     public getMySubscribeList(params: any): Promise<any> {
        const path = 'getMySubscribeList';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    // 保存
    public save(params: any): Promise<any> {
        const path = 'save';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    // 日历分享
    public insertList(params: any): Promise<any> {
        const path = 'insertList';
        return this.request(path, this.rest.METHOD_POST, params);
    }

     // 按Id逻辑删除实体
     public deleteById(params: any): Promise<any> {
        const path = params.id;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }


}