class UserInfo {

    public id?: string;            //  
    public userId?: string;      // 用户唯一ID
    public name?: string;         // 名称
    public photo?: string;         // 头像
    public pernr?: string;        // 用户的员工编号
    public department?: string;        // 用户所属部门
    public isChecked?: boolean;     //是否选中
    public departmentId?: string;   //部门id

    constructor() {
        // this.id = item.id;
        // this.userId = item.userId;
        // this.name = item.name;
        // this.photo = item.photo;
        // this.pernr = item.pernr;
        // this.department = item.department;
    }

}

export {
    UserInfo,
    UserInfo as default
}
