/**
 * okr_kr okr-关键结果 api
 */
import KeyResult from '@/models/okr/KeyResult';
import TaskBaseService from '../base/TaskBaseService';
/**
 * okr-关键结果
 */
export class KeyResultApi extends TaskBaseService {

    constructor() {
        super("okr/keyresult");
    }

     //获取关键结果详情
     public getDetail(params: any): Promise<any> {
        const path = 'detailById';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    
    //更新进度
    public progressInsert(params: any): Promise<any> {
        const path = 'progress/insert';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    /**
     * 获取目标下的关键结果列表
     * @param params 
     */
    public getListByOid(params: any) {
        const path = 'getListByOid';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    /**
     * 获取关键结果详情
     * @param params 
     */
    public detailById(params: any) {
        const path = 'detailById';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    public addKeyResult(kr: KeyResult){
        const path = 'insert';
        return this.request(path, this.rest.METHOD_POST, kr);
    }

    public updateKeyResult(kr: KeyResult){
        const path = 'update';
        return this.request(path, this.rest.METHOD_PUT, kr);
    }

    public deleteKeuResultByIds(params: any) {
        const path = 'deleteByIds';
        return this.request(path, this.rest.METHOD_DELETE, params);
    }

    public deleteKeuResultById(id: string) {
        const path = id;
        return this.request(path, this.rest.METHOD_DELETE);
    }

    public getSurplusWeight(params: any) {
        const path = 'available/weight';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //我的关键结果列表
    public myResultsList() {
        const path = 'toconvert/list';
        return this.request(path, this.rest.METHOD_GET);
    }


}