
export default {
  name: "HomeTask",
  components: {},
  props: {
    TaskObj: Object,
  },

  data() {
    return {};
  },
};
