import TaskBaseService from '../base/TaskBaseService';

export class TaskExecuteApi extends TaskBaseService {
    constructor() {
        super("taskExecute");
    }
     /**
     * 任务执行情况列表
     * @param param
     */
    public getTaskExecuteList(param: any): Promise<any> {
        const path = 'getTaskExecuteListByParams';
        return this.request(path, this.rest.METHOD_GET, param);
    }  
      /**
     * 任务执行情况列表
     * @param param
     */
    public addTaskExecute(param: any): Promise<any> {
        const path = '';
        return this.request(path,this.rest.METHOD_POST, param);
    }
}