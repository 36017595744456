
export class Px2rem {

    private static unit_px = 'px';
    private static unit_em = 'em';
    private static unit_rem = 'rem';
    private static baseFont = 16;

    static getRem(px: string): string {
        if (px == '' || px == null || px == undefined) {
            px = '0';
        }
        px = px.replace(Px2rem.unit_px, '');
        if (px.indexOf(Px2rem.unit_em) >= 0) {
            px = px.replace(Px2rem.unit_em, Px2rem.unit_rem);
        } else {
            px = parseFloat(px) / Px2rem.baseFont + 'rem';
        }
        return px;
    }
}