
import { UserInfoByUserNameApi } from '@/api/organization/UserInfoByUserNameApi'
import UserInfo from './UserInfo';

/**
 * 选择执行人及选择参与人
 */
export class UserInfoByUserNameService {

    private static userInfoByUserNameApi = new UserInfoByUserNameApi();


    //获取组织结构所有部门人员列表及筛选
    public getOrganizationAllTeamStaffList({
        limit = 20,
        offset = 1,
        searchKey = "",
    } = {}): any {
        return new Promise((resolve, reject) => {
            UserInfoByUserNameService.userInfoByUserNameApi.getAllTeamStaffList({
                limit,
                offset,
                searchKey,
            }).then(async (res: any) => {
                resolve(this.getUserList(res.data));
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    getUserList(data: any) {
        
        const userList = new Array();
        
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const user = new UserInfo();
            user.id = element.id;
            user.name = element.userName;
            user.pernr = element.pernr;
            user.photo = element.photo;
            user.userId = element.userId;
            user.department = element.department;
            userList.push(user);
        }
        return userList;
    }


    /**通过拼音获取用户信息 */
    public getUserInfoByPinyin({
        pinyin = "",
    } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            UserInfoByUserNameService.userInfoByUserNameApi.getUserInfoByPinyin({pinyin}).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
}

export function getInstance() {
    return new UserInfoByUserNameService();
}