
import { modalController, menuController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue"; //
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import RemindUnreadList from "./RemindUnreadList.vue";
import RemindReadedList from "./RemindReadedList.vue";
import { nextTick } from "process";
import { WarningService } from "@/services/warning/WarningService.ts";
import SearchBar from "@/components/SearchBar.vue";

export default defineComponent({
  name: "RemindPage",
  components: {
    Header,
    RemindUnreadList,
    RemindReadedList,
    SearchBar,
  },
  data() {
    const router = useRouter();
    const warningService = new WarningService();
    return {
      targetIndex: 0,
      selSeg: "",
      moduleList: new Array(),
      refreshUnreadPage: false, //刷新未读
      refreshReadedPage: false, //刷新已读
      searchParams: {
        limit: 10,
        offset: 1,
        keyWord: "",
        moduleId: "",
      },
      warningService: warningService,
      selectModuleCode: "",
    };
  },
  props: {},
  created() {},
  ionViewWillEnter() {},
  mounted() {
    this.getModules();
  },
  methods: {
    selectTypeHandler(type: number) {
      this.targetIndex = type;
      let that = this;
      if (this.targetIndex == 0) {
        this.refreshUnreadPage = true;
        setTimeout(function () {
          that.refreshUnreadPage = false;
        }, 500);
      } else {
        this.refreshReadedPage = true;
        setTimeout(function () {
          that.refreshReadedPage = false;
        }, 200);
      }
    },
    segmentChanged(ev: any) {
      this.selSeg = ev.detail.value;
      this.searchParams.moduleId = this.selSeg;
      let list = this.moduleList.filter((ele: any) => {
        return ele.id == this.selSeg;
      });
      this.selectModuleCode = list[0].code;
      this.refreshReadedPage = true;
      let that = this;
      setTimeout(function () {
        that.refreshReadedPage = false;
      }, 200);
    },
    getModules() {
      this.warningService
        .getModules()
        .then((res: any) => {
          this.moduleList = res.data;
          this.selSeg = this.moduleList[0].id;
          this.searchParams.moduleId = this.selSeg;
          this.selectModuleCode = this.moduleList[0].code;
        })
        .catch(() => {});
    },
  },
});
