
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { AnimationSelf } from "@/libs/animation";
import TaskStageCard from "@/components/ListCard/TaskStageCard.vue";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import SlideMore from "./task-slideMore/SlideMore.vue";
import { getInstance } from "@/services/project/projectService";
import { TaskStageService } from "@/services/task/TaskStageService";
import { TaskGroupService } from "@/services/task/TaskGroupService";
import { TaskService } from "@/services/task/TaskService";
import EditStage from "./task-slideMore/EditStage.vue";
import { useRouter } from "vue-router";
import Utils from "@/utils/Utils";
import ProjectGroupingModal from "@/views/project/modal/ProjectGroupingModal.vue";
// 权限 类
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
import SystemUtils from "@/utils/SystemUtils";
import moment from "moment";
import UpdateScheduleModal from "@/views/task/info/schedule/UpdateScheduleModal.vue";
import CheckScheduleModal from "@/views/task/info/schedule/CheckScheduleModal.vue";

export default defineComponent({
  name: "PTask",
  components: {
    TaskStageCard,
    TaskCards,
    CardLoading,
    UpdateScheduleModal,
    CheckScheduleModal,
  },
  props: {
    projectId: String,
    projectName: String,
    isPullParent: Boolean,
    tagGroupId: String,
    searchPanelForm: {
      type: Object,
      required: false,
      default: {
        searchKey: "", //搜索名称
        workUsers: "", //执行者ids
        dueToday: "", //今日截止
        completedToday: "", //今日完成
        myExecute: "", //我执行的
        myCreate: "", //我创建的
        undone: "", //未完成的任务
        unassigned: "", //未分配的任务
        finished: "", //已完成的任务
        postponed: "", //已延期的任务
      },
    },
    topHeight: Number,
    refresh: Boolean,
  },
  emits: ["refreshProjectStatistics"],
  data() {
    const router = useRouter();
    const projectService = getInstance();
    const creatStage = {
      tagGroupId: "",
      text: "新建任务阶段",
    };
    const searchParams = {
      offset: 1,
      limit: 10,
      projectId: "",
    };
    return {
      router: router,
      permissionService: new PermissionService(),
      projectRoleArr: new Array<any>(),
      //权限 start
      hasAddTaskGroup: false, //新增任务分组权限
      hasEditTaskGroup: false, //编辑任务分组权限
      hasDelTaskGroup: false, //删除任务分组权限
      hasPutArchiveTaskgroup: false, //移到回收站/恢复任务分组
      taskStagePermission: {
        hasMoveAllTasks: false, //移动阶段下任务权限 taskstage.moveAllTasks
        hasSetAllEndTime: false, //设置阶段下所有任务截止时间 taskstage.setAllEndTime
        hasEditTaskstage: false, // 编辑任务阶段权限 taskstage.put
        hasAddTaskstage: false, // 创建任务阶段下任务权限 taskstage.post、
        hasDelTaskstage: false, //删除阶段权限
      },
      hasAddTask: false, //新增任务
      hasTaskStageGroup: false, //任务阶段更多菜单权限
      //权限 end
      slideOpts: {
        initialSlide: 0,
        speed: 400,
      },
      projectService: projectService,
      taskStageService: new TaskStageService(),
      taskGroupService: new TaskGroupService(),
      taskGroupList: Array(0),
      typeTitle: "",
      currentGroupId: "",
      isDeletedProject: false,
      dataStageArr: new Array(0),
      requestDataStageArr: false,
      creatStage: creatStage,
      endTime: "",
      customPickerOptions: {},
      stageIndex: 0,
      stageId: "",
      projectTaskStageSlideIndex: -1,
      selectSlideItem: {}, //slide当前的阶段信息
      islist: true, //是否显示加载中的标识
      sliderHeight: "400px",
      sliderItemHeight: "350px",
      taskListHeight: "600px",
      showTaskList: 1, //1:列表视图 2：阶段视图
      taskList: new Array(),
      searchParams: searchParams,
      showNoDate: false, //展示暂无数据
      taskService: new TaskService(),
      userId: "",
    };
  },
  created() {
    //获取权限
    // this.refreshStatistics();
    // this.initData();
    this.initPageData();
  },
  mounted() {
    const currentUser: any = SystemUtils.loginUser;
    this.userId = currentUser.id;
    this.calSliderHeight();
  },
  watch: {
    refresh(newVal, oldVal) {
      if (newVal) {
        this.calSliderHeight();
      }
    },
  },
  methods: {
    calSliderHeight() {
      this.sliderItemHeight = this.getHeight(0.785);
      this.sliderHeight = this.getHeight(0.95);
      this.taskListHeight = this.getHeight(0.99);
    },
    changeShowTaskList(n: number) {
      this.showTaskList = n;
      this.initPageData();
    },
    initPageData() {
      this.getUserPermission();
      if (this.showTaskList == 1) {
        this.searchParams = Object.assign(
          this.searchParams,
          this.searchPanelForm
        );
        this.searchParams = Object.assign(this.searchParams, {
          projectId: this.projectId,
        });
        // let params = {
        //   userId: this.userId,
        //   projectId: this.projectId,
        //   taskName: "=()",
        // };
        // if (
        //   this.searchPanelForm.searchKey != null &&
        //   this.searchPanelForm.searchKey != ""
        // ) {
        //   params.taskName = "=(" + this.searchPanelForm.searchKey + ")";
        // }
        //this.searchParams = Object.assign(this.searchParams, params);
        this.resetPageList();
      } else {
        this.initData();
        // this.refreshStatistics();
      }
    },
    initData(event?: any) {
      // this.searchPanelForm=qParams;
      this.dataStageArr = [];
      this.islist = true;
      this.requestDataStageArr = false;
      //获取项目-任务分组
      this.projectService
        .getByProjectIdForMennu({ projectId: this.projectId })
        .then(
          (r: any) => {
            if (r) {
              const result: Array<any> = r.data;
              if (result != null && result.length > 0) {
                this.currentGroupId = result[0].id;
                this.getStageListByGroupId(result[0].id);
              }
            } else {
              console.log("暂无数据");
            }
            if (event) event.target.complete();
          },
          (e: any) => {
            if (event) event.target.complete();
            console.log(e);
          }
        );
      this.customPickerOptions = {
        buttons: [
          {
            text: "取消",
            role: "cancle",
          },
          {
            text: "确定",
            handler: (e: any) => {
              const selectTime =
                e.year.text + "-" + e.month.text + "-" + e.day.text;
              this.endTime =
                moment(selectTime.replace(/\-/g, "/"))
                  .endOf("day")
                  .format("yyyy-MM-DD HH:mm:ss") + "";
              //保存数据
              console.log("设置结束时间" + this.endTime);
              // let temArr=new Array();
              //当前阶段内是否有时间不在范围内
              let falg = false;
              for (let task of this.dataStageArr[this.stageIndex].taskList) {
                // temArr.push(task);
                if (
                  task.startTime &&
                  new Date(task.startTime).getTime() >
                    new Date(this.endTime).getTime()
                ) {
                  Utils.presentToastWarning(
                    "本阶段下所有任务开始时间不能晚于截止时间"
                  );
                  return false;
                } else {
                  task.code = null;
                  task.finisher = null;
                  task.endTime = this.endTime;
                  task.dataState = 1;
                }
              }
              //保存数据库
              this.updateAllTaskEndTime(
                this.dataStageArr[this.stageIndex].taskList
              );
              return true;
            },
          },
        ],
      };
    },
    getHeight(point: any) {
      // return (
      //   document.body.clientHeight *
      //     ((document.body.clientHeight / 832) * point) +
      //   "px"
      // );
      let height = 100;
      if (this.topHeight == null || this.topHeight == 0) {
        height = 100;
      } else {
        height = this.topHeight;
      }
      return (document.body.clientHeight - height) * point + "px";
    },
    slideChange(ev: any) {
      let that = this;
      ev.currentTarget.getActiveIndex().then((index: number) => {
        that.projectTaskStageSlideIndex = index;
      });
    },
    //查看任务详情后slide自动滚回任务的slide
    slideDidLoad(ev: any) {
      let that = this;
      ev.currentTarget.length().then((data: number) => {
        if (data - 1 >= that.projectTaskStageSlideIndex) {
          ev.target.slideTo(that.projectTaskStageSlideIndex, 1);
        }
      });
    },
    //下拉刷新
    doRefresh(event: any) {
      // this.refreshStatistics();
      this.initData(event);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //更新项目分组
    refreshStatistics() {
      const parmsStatus = {
        id: this.projectId,
        isRefresh: true,
      };
      this.taskGroupService.refreshStatistics(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            //刷新完成
          } else {
            // console.log("无项目信息");
          }
        },
        (e: any) => {
          console.log("pTaskVue-refreshStatistics接口请求异常");
        }
      );
    },
    setStageList(taskGroup: any) {
      this.typeTitle = taskGroup.name;
      this.currentGroupId = taskGroup.id;
      this.dataStageArr = [];
      this.islist = true;
      this.requestDataStageArr = false;
      this.projectTaskStageSlideIndex = -1;
      this.getStageListByGroupId(taskGroup.id);
    },
    //根据群组id和任务id获取任务阶段
    getStageListByGroupId(groupId: any) {
      const params = Object.assign(
        { projectId: this.projectId, taskGroupId: groupId },
        this.searchPanelForm
      );
      this.taskStageService.getStageListByProjecctAndTaskGroupId(params).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result != null && result.length > 0) {
              // result.push(this.stageModel);
              this.dataStageArr = result;
              this.requestDataStageArr = true;
              this.islist = false;
            }
          } else {
            console.log("暂无数据");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //跳转任务详情
    PTaskInfo(id: any) {
      // console.log(999);
      // this.router.push("/ProjectPage/PTaskInfo");
      this.router.push({
        path: "/task-page",
        query: {
          id: id,
        },
      });
    },
    //添加任务
    addTask(obj: any) {
      const newTask = {
        projectId: this.projectId,
        projectName: this.projectName,
        taskGroupId: obj.taskStage.taskGroupId,
        taskGroupName: this.typeTitle,
        stageId: obj.taskStage.id,
        stageName: obj.taskStage.name,
        pos: this.getMaxPos(obj.taskList) + 1, //本任务本阶段第几个任务
        isDeletedProject: this.isDeletedProject, 
      };
      this.router.push({
        name: "edit-task",
        params: { task: JSON.stringify(newTask), tagGroupId: obj.tagGroupId },
      });
    },
    addTaskFab() {
      if (this.projectTaskStageSlideIndex >= 0) {
        this.selectSlideItem = this.dataStageArr[
          this.projectTaskStageSlideIndex
        ];
      } else {
        this.selectSlideItem = this.dataStageArr[0];
      }
      this.addTask(this.selectSlideItem);
    },
    addTaskHandler() {
      const newTask = {
        projectId: this.projectId,
        projectName: this.projectName,
        taskGroupId: "",
        taskGroupName: this.typeTitle,
        stageId: "",
        stageName: "",
        pos: 0, //本任务本阶段第几个任务
        isDeletedProject: this.isDeletedProject,
      };
      this.router.push({
        name: "edit-task",
        params: { task: JSON.stringify(newTask), fromType: 1 },
      });
    },
    //打开阶段菜单
    async slideMore(objStage: any, index: number) {
      let isShowMen = false;
      if (objStage.taskList.length > 0) {
        isShowMen = true;
      }
      this.stageIndex = index;
      this.stageId = objStage.id;
      const md = await modalController.create({
        id: "SlideMore",
        component: SlideMore,
        cssClass: "bottom-modal-class",
        backdropDismiss: true,
        showBackdrop: true,
        componentProps: {
          taskStagePermission: this.taskStagePermission,
          isShowMen: isShowMen,
        },
      });
      md.present();
      const { data } = await md.onDidDismiss();
      if (data && data.state) {
        switch (data.state) {
          case "setting":
            //设置截止时间
            const dom: any = document.querySelector("#startDate");
            if (dom != null) {
              dom.click();
            }
            break;
          case "move":
            //转移任务
            console.log("转移任务");
            this.moveAlertPrompt();
            break;
          case "edit":
            //编辑阶段
            this.editAlertPrompt();
            break;
          case "add":
            //当前阶段后边增加阶段
            this.addAlertPrompt();
            break;
          case "del":
            //删除阶段
            console.log("del");
            this.clearStage(objStage.taskStage.id);
            this.dataStageArr.splice(index, 1);
            break;
          default:
            break;
        }
      }
    },

    //菜单-移动阶段
    async moveAlertPrompt() {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        backdropDismiss: true,
        showBackdrop: true,
        mode: "md",
        componentProps: {
          selIndex: this.currentGroupId, //分组Id
          isSelPhases: true, //是否选择阶段
          selPhaseId: this.stageId, //阶段Id
          projectId: this.projectId, //项目Id
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.staged) {
        let stageObject: any = this.dataStageArr[this.stageIndex];
        let retSelectedStage = data.staged.taskStage;
        let maxPos = 0;
        if (stageObject != null) {
          if (stageObject.taskStage.id == retSelectedStage.id) {
            Utils.presentToastCenter("不能移动到当前阶段");
            return;
          } else {
            //获取选择阶段里任务的最大位置
            for (let item of this.dataStageArr) {
              if (item.taskStage.id == retSelectedStage.id) {
                maxPos = this.getMaxPos(item.taskList) + 1;
              }
            }
            let oldStageId: string = stageObject.taskList[0].stageId;
            for (let task of stageObject.taskList) {
              task.projectId = retSelectedStage.projectId;
              task.taskGroupId = retSelectedStage.taskGroupId;
              task.taskGroupName = data.group.name;
              task.stageId = retSelectedStage.id;
              task.stageName = retSelectedStage.name;
              task.pos = maxPos++;
              task.dataState = 1;
            }
            //保存数据库
            this.moveStageTask(stageObject.taskList, stageObject.taskStage.id);
            //移动任务
          }
        }
      }
    },
    //菜单-编辑阶段
    async editAlertPrompt() {
      let stageObject: any = this.dataStageArr[this.stageIndex];
      const modal = await modalController.create({
        component: EditStage,
        cssClass: "project-status-modal-class",
        backdropDismiss: true,
        showBackdrop: true,
        mode: "md",
        componentProps: {
          title: "编辑阶段",
          stageName: stageObject.taskStage.name,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && "name" in data) {
        this.dataStageArr[this.stageIndex].taskStage.name = data.name;
        this.updateStageName(this.dataStageArr[this.stageIndex].taskStage);
      }
    },
    //菜单-当前阶段后边增加阶段
    async addAlertPrompt() {
      const modal = await modalController.create({
        component: EditStage,
        cssClass: "project-status-modal-class",
        backdropDismiss: true,
        showBackdrop: true,
        mode: "md",
        componentProps: {
          title: "新增阶段",
          stageName: "",
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && "name" in data) {
        console.log("此后添加新阶段");
        this.dataStageArr[this.stageIndex].taskStage.name = data.name;
        let updateStageArr = new Array();
        const newStage: any = this.taskStageService.newStage();
        newStage.pos = this.dataStageArr[this.stageIndex].taskStage.pos + 1;
        newStage.name = data.name;
        newStage.projectId = this.dataStageArr[
          this.stageIndex
        ].taskStage.projectId;
        newStage.taskGroupId = this.dataStageArr[
          this.stageIndex
        ].taskStage.taskGroupId;
        newStage.isDeletedTaskGroup = false;
        newStage.isDeletedProject = false;
        newStage.isUserDelete = 1;
        newStage.isUserEdit = 1;
        newStage.dataState = 0;
        newStage.busId = "";
        newStage.busTable = "";
        newStage.busNo = "";
        newStage.remark = "";
        updateStageArr.push(newStage);
        for (let item of this.dataStageArr) {
          if (item.taskStage.pos >= newStage.pos) {
            item.taskStage.pos += 1;
            item.taskStage.dataState = 1;
            updateStageArr.push(item.taskStage);
          }
        }
        //保存数据库
        this.addForAfter(updateStageArr);
      }
    },
    //编辑阶段名称-保存数据库
    updateStageName(stageObj: any) {
      const newModel = Object.assign(stageObj, { setFields: ["name"] });
      this.taskStageService.updateFieldsById(newModel).then(
        (r: any) => {
          if (r) {
            console.log("保存成功");
          } else {
            console.log("暂无数据");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //保存-之后添加新阶段
    addForAfter(updateStageArr: any) {
      this.taskStageService.insertAfter(updateStageArr).then(
        (r: any) => {
          if (r) {
            console.log("新增成功");
            //重新渲染 silid
            //重新渲染任务列表
            this.dataStageArr = [];
            this.islist = true;
            this.requestDataStageArr = false;
            this.initData();
          } else {
            console.log("新增失败");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //保存-删除阶段-移到回收站
    clearStage(stageId: any) {
      this.taskStageService.clearById(stageId).then(
        (r: any) => {
          if (r) {
            console.log("保存成功");
            //重新渲染 silid
            this.dataStageArr = [];
            this.islist = true;
            this.requestDataStageArr = false;
            //重新渲染任务列表
            this.initData();
            // this.refreshStatistics();
            this.$emit("refreshProjectStatistics");
          } else {
            console.log("暂无数据");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //保存-设置截止时间
    updateAllTaskEndTime(taskList: any) {
      this.taskStageService.updateTaskEndTime(taskList).then(
        (r: any) => {
          if (r) {
            console.log("设置任务截止时间");
            //重新渲染任务列表
          } else {
            console.log("设置任务截止时间");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //保存-移动任务
    moveStageTask(taskList: any, stageId: string) {
      this.taskStageService.moveSatgeTask(taskList, stageId).then(
        (r: any) => {
          if (r) {
            console.log("移动任务成功");
            this.dataStageArr = [];
            this.islist = true;
            this.requestDataStageArr = false;
            //重新渲染任务列表
            this.initData();
          } else {
            console.log("移动任务失败");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getMaxPos(list: any) {
      let max = 0;
      for (let item of list) {
        if (max < item.pos) {
          max = item.pos;
        }
      }
      return max;
    },
    //添加阶段
    async addTaskStage(index: number) {
      const modal = await modalController.create({
        component: EditStage,
        cssClass: "project-status-modal-class",
        // presentingElement: await modalController.getTop(),
        componentProps: {
          title: "新增阶段",
          stageName: "",
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && "name" in data) {
        const newStage: any = this.taskStageService.newStage();
        const lastStage = this.dataStageArr[index == 0 ? 0 : index - 1];
        newStage.pos = index == 0 ? 0 : lastStage.taskStage.pos + 1;
        newStage.name = data.name;
        newStage.projectId = this.projectId;
        newStage.taskGroupId = this.currentGroupId;
        newStage.isDeletedTaskGroup = false;
        newStage.isDeletedProject = false;
        newStage.isUserDelete = 1;
        newStage.isUserEdit = 1;
        newStage.dataState = 0;
        newStage.busId = "";
        newStage.busTable = "";
        newStage.busNo = "";
        newStage.remark = "";
        this.taskStageService.addNewStage(newStage).then(
          (r: any) => {
            if (r) {
              console.log("保存成功");
              this.dataStageArr = [];
              this.islist = true;
              this.requestDataStageArr = false;
              //重新渲染任务列表
              this.initData();
              console.log("initData-addTaskStage");
            } else {
              console.log("暂无数据");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },
    //根据用户ID获取项目权限
    getUserPermission() {
      this.permissionService
        .getUserPermissionByProject(this.projectId + "")
        .then(
          (resp: AllResponse<Permission>) => {
            if (resp) {
              this.projectRoleArr = resp.data;
              let permissionArr = resp.data;
              //新增任务分组
              this.hasAddTaskGroup = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskgroup.post"
              );
              //编辑任务分组
              this.hasEditTaskGroup = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskgroup.put"
              );
              //删除任务分组
              this.hasDelTaskGroup = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskgroup.del"
              );
              //删除任务分组
              this.hasPutArchiveTaskgroup = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskgroup.put.archive"
              );

              //移动阶段下任务权限 taskstage.moveAllTasks
              this.taskStagePermission.hasMoveAllTasks = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskstage.moveAllTasks"
              );
              //设置阶段下所有任务截止时间 taskstage.setAllEndTime
              this.taskStagePermission.hasSetAllEndTime = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskgroup.put"
              );
              // 编辑任务阶段权限 taskstage.put
              this.taskStagePermission.hasEditTaskstage = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskstage.put"
              );
              // 创建任务阶段权限 taskstage.post
              this.taskStagePermission.hasAddTaskstage = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskstage.post"
              );
              //taskstage.del 删除阶段
              this.taskStagePermission.hasDelTaskstage = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "taskstage.del"
              );
              //hasAddTask
              this.hasAddTask = this.permissionService.isHasPermissionByCode(
                permissionArr,
                "task.post"
              );
              //是否包含菜单组权限
              this.hasTaskStageGroup = this.permissionService.isHasGroupPermissionByCode(
                permissionArr,
                "taskstage"
              );
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
    },
    //列表视图：重新从第一页获取
    resetPageList() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.taskList = res;
        this.scrollToTop();
        this.showNoDate = res == null || res.length <= 0;
      });
    },
    nextPageList(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        if (result) {
          this.taskList = this.taskList.concat(result);
        }
        setTimeout(() => {
          event.target.complete();
        }, 2000);
      });
    },
    doRefreshList(event: any) {
      this.resetPageList();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    getListRequest(callback: any) {
      //getTaskList  getTaskFroProjectIndexList
      this.taskService
        .getAllTaskByProjectIdAndParams(this.searchParams)
        .then((res: any) => {
          this.islist = false;
          callback(res.data);
        })
        .catch(() => {
          this.islist = false;
        });
    },
    //滚动到顶部
    scrollToTop() {
      nextTick(() => {
        let ionContent: any = document.querySelector("#taskDetailInfo");
        if (ionContent != null) {
          ionContent.scrollToTop();
        }
      });
    },
     openWorkHours(task: any) {
        if (task.isFinished) {
        this.checkSchedule(task);
      } else {
        this.updateSchedule(task.isFinished, task);
      }
    },
    //工时填报
    async updateSchedule(finish: any, task: any) {
      let folederId = task.folderId;
      //这里注释了，不用上传附件了，所以可以不传folderId了
      // if (this.info.project != "") {
      //   folederId = this.info.project.folderId;
      // }
      const modal = await modalController.create({
        component: UpdateScheduleModal,
        cssClass: "task-progress-update-class",
        componentProps: {
          startTime: task.actualStartTime,
          endTime: task.actualEndTime,
          taskProgress: task.progress,
          hour: task.actualWorkingHour,
          taskId: task.id,
          folderId: folederId,
          isIndependentTask: task.projectId == "" ? true : false,
          isfinish: finish,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //只填写工时
      /*
      if (data) {
        if (data.isOk) {
          if (finish) {
            this.taskService.isfullworkingHour(task.id).then((res) => {
              if (res) {
                this.changeFinish(true, task);
              } else {
                this.changeFinish(false, task);
                Utils.presentToastWarning("请填写完整的工时");
              }
            });
          } else {
            this.changeFinish(false, task);
            // this.bindTaskFinshStatus("0", false);
          }
        }
      } else {
        this.changeFinish(false, task);
        // this.bindTaskFinshStatus("0", false);
        // this.bindTaskFinshStatus("0", false);
      }*/
    },
    changeFinish(finish: any, task: any) {
      this.taskService.changeFinishStatus(task.id, finish).then((res) => {
        if (!res) {
          Utils.presentToast("操作失败");
        } else {
          if (finish) {
            task.isFinish = true;
          }
        }
      });
    },
    //进展情况
    async checkSchedule(task:any) {
      const modal = await modalController.create({
        component: CheckScheduleModal,
        cssClass: "task-progress-update-class",
        backdropDismiss: false,
        componentProps: {
          taskId: task.id,
        },
      });
      await modal.present();
    },
  },
});
