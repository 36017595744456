
import { defineComponent } from "vue";
import { TaskService } from "@/services/task/TaskService";
import { modalController, IonPage, IonContent } from "@ionic/vue";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SelectVisibleProject from "@/views/project/selectVisibleProject/selectVisibleProjectModel.vue";
import ProjectGroupingModal from "@/views/project/modal/ProjectGroupingModal.vue";
export default defineComponent({
  name: "ConvertToProjectTask",
  components: {
    IonPage,
    IonContent,
    HeaderModal,
    SelectVisibleProject,
    ProjectGroupingModal,
  },
  props: {
    taskId: String,
  },
  data() {
    const _reciveProject: any = {
      id: "",
      name: "",
    };
    const _stageInfo: any = {
      stageId: "",
      stageName: "",
      taskGroupId: "",
      taskGroupName: "",
    };
    return {
      taskApi: new TaskService(),
      projectInfo: _reciveProject,
      stageInfo: _stageInfo,
      sourceTaskId: this.taskId ? this.taskId : "",
    };
  },
  methods: {
    //选择项目
    async chooseProjectHandler() {
      const modal = await modalController.create({
        component: SelectVisibleProject,
        cssClass: "notice-modal-class",
        componentProps: {
          source: 2,
          paramProject: this.projectInfo,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedProject) {
        if (this.projectInfo.id != data.selectedProject.id) {
          this.projectInfo.id = data.selectedProject.id;
          this.projectInfo.name = data.selectedProject.name;
          //清除阶段和分组
          this.stageInfo = {};
        }
      }
    },
    async selectStageHandler() {
      if (!this.projectInfo.id) {
        Utils.presentToast("请先选择项目");
        return;
      }
      let propsParam: any = {
        projectId: this.projectInfo.id,
        isSelPhases: true,
      };
      if (this.stageInfo.taskGroupId) {
        propsParam.selIndex = this.stageInfo.taskGroupId; //分组Id
        propsParam.selPhaseId = this.stageInfo.stageId; //阶段Id
      }
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: propsParam,
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.staged) {
        this.stageInfo.stageId = data.staged.taskStage.id;
        this.stageInfo.stageName = data.staged.taskStage.name;
        this.stageInfo.taskGroupId = data.staged.taskStage.taskGroupId;
        this.stageInfo.taskGroupName = data.group.name;
      }
    },
    closeModel(type: any) {
      if (type == 4) {
        let isValid=true;
        if (!this.projectInfo.id) {
          Utils.presentToastWarning("请选择项目");
          isValid=false;
        }
        if(isValid){
          if (!this.stageInfo.taskGroupId) {
            Utils.presentToastWarning("请选择分组");
            isValid=false;
          }
        }
        if(isValid){
          if (!this.stageInfo.stageId) {
            Utils.presentToastWarning("请选择阶段");
            isValid=false;
          }
        }
       
         if(isValid) {
          //保存
          Utils.confirmDialog(
            "转为项目任务",
            "您确定要转为项目任务吗？操作不可逆，转换后不能再转为独立任务，且执行人、参与人需要重新从项目内成员中选择",
            () => {
              this.saveRequest();
            }
          );
        }
      } else {
        modalController.dismiss({});
      }
    },
    saveRequest() {
      this.taskApi
        .moveTask(
          this.sourceTaskId,
          this.projectInfo.id,
          this.stageInfo.taskGroupId,
          this.stageInfo.stageId
        )
        .then((res) => {
          Utils.presentToastSuccess("保存成功");
          modalController.dismiss({ refresh: true });
        });
    },
  },
});
