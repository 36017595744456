
import { defineComponent } from "vue";
import DocService from "../../../services/doc/docService";
import FileCard from "@/components/ListCard/FileCard.vue";
import Utils from "../../..//utils/Utils";
import { FileHelper } from "@/utils/FileHelper";
import CardLoading from "@/components/ListCard/CardLoading.vue";

export default defineComponent({
  name: "ProjectDoc",
  components: {
    FileCard,
    CardLoading,
  },
  props: {
    busId: String, //关联业务Id，例如ProjectId或TaskId
    folderId: {
      type: String,
      required: false,
      default: "",
    }, //文件夹id
    allowCheckDownload: {
      type: Boolean,
      required: false,
      default: true,
    }, //下载是否验证下载权限
    allowCheck: {
      type: Boolean,
      required: false,
      default: false,
    }, //允许选择
    //项目文档操作权限
    projectFilePermission: {
      type: Object,
      required: false,
      default: {
        isHasFileUpload: false, //上传文件权限
        isHasFileDownload: false, //下载文件权限
        isHasFileDel: false, //删除文件权限
      },
    },
    showFolder: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const docService = new DocService();
    const fileHelper = new FileHelper();
    return {
      navLines: new Array<string>(),
      navFolders: ["root"],
      navCheck: -1,
      folderList: new Array<any>(),
      fileList: new Array<any>(),
      docService: docService,
      checkList: new Array<any>(),
      rootId: "",
      fileHelper: fileHelper,
      folderIndex: -1,
      islist: true, //是否显示加载中的标识
      showNoDate: false, //展示暂无数据
    };
  },
  created() {
    if (this.busId) {
      this.loadRootFolderId(this.busId);
    } else if (this.folderId) {
      this.rootId = this.folderId;
      this.loadFolderList(this.folderId, -1);
    } else {
    }
  },
  methods: {
    loadRootFolderId(busId: string) {
       this.showNoDate = false;
      this.docService.getByIdForFinish({ id: busId }).then(
        (r: any) => {
          if (r && r.data.length > 0) {
            this.rootId = r.data[0].folderId;
            //加载文件夹列表
            this.loadFolderList(this.rootId, 0);
            // this.loadFildList(this.rootId);
          }else {
            this.islist = false;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    filterNavs(navName: string) {
      if (this.navLines.length <= this.navCheck) {
        this.navLines.push(navName);
      } else {
        const res = this.navLines.filter((value, index, arr) => {
          return index < this.navCheck;
        });
        res.push(navName);
        this.navLines = res;
      }
      setTimeout(() => {
        const ul = document.querySelector(".nav ul");
        if (ul != null) {
          ul.scrollTo({ left: ul.scrollWidth });
        }
      }, 500);
    },

    bindFolderInto(fId: string, i: number) {
      this.folderIndex = i;
      this.loadFolderList(fId, -1);
    },

    bindFolderGrade(check: number) {
      this.loadFolderList(this.navFolders[check], check);
      // this.loadFildList(this.navFolders[check]);
    },

    loadFolderList(pId: string, i: number) {
       this.showNoDate = false;
      const params = {
        folderId: pId,
      };
      this.docService.getFileByFolderId(params).then(
        (r: any) => {
          if (r) {
            if (r.data.subFolders.length > 0 || r.data.documents.length > 0) {
              this.folderList = r.data;
              this.islist = false;
              if (this.folderList != null && "documents" in this.folderList) {
                let documents: any = this.folderList["documents"];
                if (documents && documents instanceof Array) {
                  for (let index = 0; index < documents.length; index++) {
                    let ele = documents[index];
                    ele["isDownloadOver"] = true;
                    ele["downloadFlag"] = false;
                    this.fileHelper
                      .checkExistsFile(ele.mainFileId, ele.name)
                      .then(
                        (fr: boolean) => {
                          ele["downloadFlag"] = fr;
                        },
                        (fe: boolean) => {
                          ele["downloadFlag"] = fe;
                        }
                      );
                  }
                }
              }
              if (i == -1) {
                this.navCheck += 1;
              } else {
                this.navCheck = i;
              }
              this.navFolders[this.navCheck] = pId;
              if (i == 0) {
                if (this.folderId) {
                  this.filterNavs(r.data.folder.name);
                } else {
                  this.filterNavs("文件库");
                }
              } else {
                this.filterNavs(r.data.folder.name);
              }
            } else {
              if (this.folderList != null && "subFolders" in this.folderList) {
                let subFolders: any = this.folderList["subFolders"];
                if (subFolders && subFolders instanceof Array) {
                  let folderParent = subFolders[this.folderIndex];
                  if (folderParent) {
                    folderParent["hasFolder"] = false;
                    Utils.presentToastWarning("暂无数据");
                  }
                }
              }
            }
          }
          let list:any=this.folderList;
           if(list!=null
           &&(list.subFolders==null||list.subFolders.length==0)
           &&(list.documents==null||list.documents.length==0)){
               this.showNoDate = true;
            }
            else{
              this.showNoDate = false;
            }
            this.islist = false;
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //暂时不需要（文件夹接口一并返回了文档数据）
    loadFildList(pId: string) {
      const params = {
        folderId: pId,
      };
      this.docService.getFileList(params).then(
        (r: any) => {
          if (r) {
            this.folderList = r.data;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    fileItemClick(item: any) {
      console.log(item);
    },
    /**************选中方法************* */
    getSelectId() {
      return this.checkList;
    },
    checkChangeHandler(info: any) {
      if (info.checked) {
        this.checkList.push({ id: info.id, name: info.name });
      } else {
        const index = this.checkList.findIndex((e) => {
          return e.id == info.id;
        });
        if (index > -1) {
          this.checkList.splice(index, 1);
        }
      }
    },
    //下载
    downloadFile(r: any) {
      if (r) {
        if (this.folderList != null && "documents" in this.folderList) {
          let docs: any = this.folderList["documents"];
          if (docs instanceof Array) {
            let doc = docs[r.index];
            if (doc != null && doc.hasOwnProperty("isDownloadOver")) {
              doc["isDownloadOver"] = false;
              this.fileHelper.downloadNative(r.fileId, r.name).then(
                (result: any) => {
                  let flag = false;
                  if (result) {
                    if ("nativeURL" in result && result.nativeURL != "") {
                      doc["isDownloadOver"] = true;
                      doc["downloadFlag"] = true;
                      Utils.presentToastSuccess("下载完成");
                      flag = true;
                      if (r.previewImage != "") {
                        this.previewFile(r);
                      }
                    }
                  }
                  if (!flag) {
                    doc["isDownloadOver"] = true;
                    Utils.presentToastWarning("下载失败");
                  }
                },
                (e: any) => {
                  doc["isDownloadOver"] = true;
                  Utils.presentToastWarning("下载失败");
                }
              );
            }
          }
        }
      }
    },
    //浏览
    previewFile(r: any) {
      if (r) {
        if (r.isPic) {
          let extName = r.name.substring(r.name.lastIndexOf(".") + 1);
          this.fileHelper.downloadOriginal(r.fileId, extName, 2).then(
            (b: any) => {
              this.fileHelper.previewVant([b], 0);
            },
            (e: any) => {}
          );
        } else {
          this.fileHelper.previewNativeFile(r.fileId, r.name);
        }
      }
    },
  },
});
