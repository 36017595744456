import User from '@/models/task/User';
import {Rest} from '@/rest/rest';
import Setting from '@/rest/Setting';
import CookieHelper from '@/utils/CookieHelper';
import {State} from '@/utils/state';
import SystemUtils from '@/utils/SystemUtils';
import Utils, {transformRequest} from '@/utils/Utils';
const rest = new Rest()
const terminal = '0';
//登录方式
const grantTypePwd = 'password';
const grantTypeCode = 'sms_code';
//默认登录方式
const grantType_def = 'client_credentials';
const clientId_def = 'app_client';
//刷新token
const grantTypeRefresh = 'refresh_token';

const refreshTokening = false;


function logOut() {
    window.location.href = '/login';
} 

function getUrl(url) {
    //api 版本
    // let res = this.service.apiBaseURL ? Setting.baseURL + "/" + this.service.apiBaseURL : Setting.apiBaseURL;
    // if (this.service.version) {
    //     res += `/${this.service.version}`;
    // }
    // //微服务名称  可为空
    // if (this.service.name)
    //     res += `/${this.service.name}`;
    // //业务模块controller名称 可为空
    // if (this.service.resource)
    //     res += `/${this.service.resource}`;
    // if (url) {
    //     res += "/" + url;
    // }
    var path =  process.env.VUE_APP_BASE_API+url
    return path;
}
function getExpiresTimestamp(expires) {
    let timestamp = new Date().getTime() + (expires - 60) * 1000;
    return timestamp;
}
function refreshAuthHandler(state){
    let refreshTokening = CookieHelper.get(Setting.refreshingTokenName) == "1"; //是否在刷新token
    if (refreshTokening) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve('success');
            }, 500);
        });
    } else {
        CookieHelper.save({
            key: Setting.refreshingTokenName,
            value: "1"
        });
        const user = SystemUtils.loginUser;
        if (user == null) {
            return new Promise((resolve, reject) => {
                reject();
            });
        }
        let params = {
            'grant_type': grantTypeRefresh, //refresh token
            'client_id': clientId_def,
            'client_secret': Setting.clientSecret,
            'refresh_token': state.refresh_token //refresh token 时必须有值
        };
        const stringpart = transformRequest(params);
        const url = Setting.baseURL + '/attapi/oauth/token?' + stringpart;

        return rest.request(url, rest.METHOD_POST, {})
            .then((result) => {
                const success = !result.data["error"];
                if (success) {
                    let data = result.data;
                    //非公共授权
                    if (data.refresh_token) {
                        state.refresh_token = data.refresh_token;
                        if (state.refreshToken_expires == 0) {
                            state.refreshToken_expires = getExpiresTimestamp(30 * 24 * 60 * 60); //有效期三十天
                        }
                        state.accessToken_expires = getExpiresTimestamp(data.expires_in);
                    }
                    state.access_token = "Bearer " + data["access_token"];
                    state.refresh_token = data.refresh_token;

                    CookieHelper.deleteCookie(Setting.refreshingTokenName);
                    CookieHelper.deleteCookie(Setting.authCookieName);

                    CookieHelper.save({
                        key: Setting.refreshingTokenName,
                        value: "0"
                    });
                    CookieHelper.save({
                        key: Setting.authCookieName,
                        value: JSON.stringify(state)
                    });
                } else {
                    logOut();
                }
            })
    }
}
function checkRefreshTokenExpire(refreshToken_expires) {
    return new Date().getTime() < refreshToken_expires;
}
function checkAccessTokenExpire(accessToken_expires) {
    return new Date().getTime() < accessToken_expires;
}
function request(url, method, params,
    files, //用于文件上传:本地文件地址（支持数组['file://file.txt']）或者下载文件的本地地址（全路径）
    names //用于文件上传:文件地址对应的文件标识（支持数组['file']）
){
  

    //无token，或有token且未过期，直接请求

        const path = getUrl(url);
        console.log(path)
        return rest.request(path, method, params, files, names)
            .catch((error) => {
               
                Utils.presentToastTopWarning(error.message||error.statusText||'error');
                return Promise.reject(error);
            });
    
}
export default request