<template>
  <ion-page>
    <HeaderModal
      btnTypeLeft=""
      isRollback="true"
      btnTypeRight=""
      themeType="1"
      btnTextRight=""
      title="查询条件管理"
      @on-close="closeModel"
    >
    </HeaderModal>
    <ion-content>
      <div class="search-bar">
        <SearchBar @on-enter="searchHandler" placeholder="根据条件名称搜索" ></SearchBar>
      </div>
      <div class="list">
        <ion-item class="listItem" v-for="(item, index) in list" :key="index">
          <ion-label> {{ item.name }}</ion-label>
          <!-- <ion-checkbox></ion-checkbox> -->
          <span class="iconfont iconshouyexuanzhong"></span>
          <ion-text slot="end" @click="edit()"> 编辑</ion-text>
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script scoped>
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import EditSearchConditionCalendar from "../modal/EditSearchCondition.vue";
export default {
  name: "SearchConditionManageModalCalendar",
  props: {
    taskId: String,
  },
  components: {
    HeaderModal,
    SearchBar,
  },
  data() {
    return {
      list: [
        { id: "0", name: "aaa" },
        { id: "1", name: "bbb" },
      ],
    };
  },
  methods: {
    segmentChanged(e) {
      this.selSeg = e.detail.value;
    },
    searchHandler(data) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.$refs.component.init(data.searchKey);
    },
    closeModel(type) {
      if (type == 4) {
        //完成选择，保存数据并关闭模态
        modalController.dismiss({});
        return;
        //todo save 方法
      }
      modalController.dismiss({});
    },
    //编辑
    async edit() {
      const modal = await modalController.create({
        component: EditSearchConditionCalendar,
        cssClass: "notice-modal-class",
        componentProps: {},
        // presentingElement: await this.modalController.getTop()
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    },
  },
};
</script>
<style lang="scss" scoped>
.search-bar {
  margin-left: px2rem(40);
  margin-right: px2rem(40);
  margin-top: px2rem(30);
}
.list {
  margin: px2rem(20) px2rem(40);
  .listItem {
    ion-checkbox {
      margin: 0 1.5rem;
    }
  }
}
</style>