import BaseModel from '@/services/base/model/BaseModel';

/**
 * 任务关联
 */
export default class TaskLink extends BaseModel {
    name: string;
    projectId: string;
    sourceType: number;
    sourceId: string;
    targetId: string;
    targetProjectId: string;
    targetType: number;

    constructor(targetId: string, targetName: string, targetProjectId: string, targetType: number, sourceId: string, sourceProjectId: string, sourceType: number) {
        super();
        this.projectId = sourceProjectId ? sourceProjectId : "";
        this.sourceId = sourceId;
        this.sourceType = sourceType;
        this.targetId = targetId;
        this.name = targetName;
        this.targetProjectId = targetProjectId;
        this.targetType = targetType;
        this.id = this.generateId("task", "tasklink");
    }
}