import User from '@/models/task/User';
import Setting from '@/rest/Setting';
import SystemUtils from '@/utils/SystemUtils';

export default class BaseModel {
    id? : string;
    creatorId? : string;
    creator? : string;
    createTime? : Date;
    modifierId? : string;
    modifier? : string;
    modifyTime? : Date;
    isDeleted? : boolean;
    remark? : string;
    enterpriseId? :string;
    systemId? :string;

    constructor() {
        const user: User = SystemUtils.loginUser;
            this.creatorId=user.id;
            this.creator=user.name;
            this.modifierId=user.id;
            this.modifier=user.name;
            this.isDeleted=false;
            this.remark="";
            this.enterpriseId=Setting.currentEnterprise.id;
            this.systemId =SystemUtils.systemId;
    }
    public generateId(systemName: string, table: string) {
        return Guid.generate(systemName, table);
    }

    public generateIdNoSys(){
        return Guid.generateId();
    }
}

class Guid {
    public static generate(systemName: string, table: string): string {
        const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        //id = id.replace('/\-/g', "");
        return systemName + "@" + table + "(" + id + ")";
    }
    public static generateId(): string {
        const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        //id = id.replace('/\-/g', "");
        return id;
    }
}