
import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { defineComponent } from "vue";
import { ProjectViewService } from "@/services/project/ProjectViewService";
import { useRouter, useRoute } from "vue-router";
declare let Gantt: any;
export default defineComponent({
  name: "GanntPage",
  data() {
    const params = useRoute().query;
    return {
      ganttObject: Gantt.getGanttInstance(),
      projectViewService: new ProjectViewService(),
      projectId: params.projectId,
      screenOrientation: ScreenOrientation,
      unitData: [
        { label: "天", value: "day" },
        { label: "周", value: "week" },
        { label: "月", value: "month" },
        { label: "年", value: "year" },
      ],
      linksFormatter: null,
      isHorizontal: false,
      currentUnitView: "week", //当前视图单位
    };
  },
  mounted() {
    //请求数据
    this.projectViewService.getProjectTaskTree(this.projectId + "").then(
      (r: any) => {
        let tasks = r;
        this.setConfigs();
        this.setUnitView();
        // 初始化
        this.ganttObject.init(this.$refs.gantt);
        // 数据解析
        this.ganttObject.parse(tasks);
        // this.parseData();
      },
      (e: any) => {
        console.log(e);
      }
    );
    this.listenerBackbutton();
  },
  methods: {
    setConfigs() {
      this.ganttObject.config.readonly = true;
      //  let gantt = this.gantt;
      //当task的长度改变时，自动调整图表坐标轴区间用于适配task的长度
      this.ganttObject.config.tooltip_hide_timeout = 20;
      this.ganttObject.config.tooltip_timeout = 500;
      //自适应甘特图的尺寸大小, 使得在不出现滚动条的情况下, 显示全部任务
      this.ganttObject.config.autosize = "xy";
      //图表的最小宽度
      this.ganttObject.config.autosize_min_width = 300;
      //初始化的时候就展开树结构
      this.ganttObject.config.open_tree_initially = true;
      this.ganttObject.config.date_format = "%Y-%m-%d %H:%i:%s";
      // 底层按照分钟计算
      this.ganttObject.config.duration_unit = "minute";
      this.ganttObject.config.duration_step = 1;
      // this.ganttObject.config.grid_resizer_attribute = "gridresizer";
      // // 定义甘特图的行高
      this.ganttObject.config.row_height = 40;
      // //设置甘特图的表头高度
      // this.ganttObject.config.scale_height = 50;
      //时间轴列的最小宽度
      this.ganttObject.config.min_column_width = 70;
      this.ganttObject.config.round_dnd_dates = false;
      this.ganttObject.config.touch = false;

      // this.ganttObject.config.smart_rendering = false;
      // this.ganttObject.config.smart_scales = false;
      // 是否显示进度
      this.ganttObject.config.show_progress = false;
      // 是否显示连接线
      this.ganttObject.config.show_links = true;
      // 时间刻度
      this.ganttObject.config.scales = [
        {
          unit: "year",
          format: "%Y ",
        },
        {
          unit: "month",
          step: 1,
          date: "%F",
        },
      ];
      this.ganttObject.config.columns = [
        {
          name: "text",
          label: "任务名称",
          width: 300,
          required: true,
          resize: false,
          tree: true,
          align: "left",
        },
        {
          name: "execUser",
          label: "执行人",
          width: 150,
          resize: false,
          align: "center",
        },
        {
          name: "start_date",
          label: "开始时间",
          width: 150,
          resize: false,
          align: "center",
          template: function (obj: any) {
            let year = obj.start_date.getFullYear();
            let month = obj.start_date.getMonth() + 1;
            let day = obj.start_date.getDate();
            let sTime = year + "-" + month + "-" + day;
            if (sTime == "1970-1-1") return "";
            else return obj.start_date;
          },
        },
        {
          name: "end_date",
          label: "结束时间",
          width: 150,
          resize: false,
          align: "center",
          template: function (obj: any) {
            let eDate = obj.end_date;
            if (eDate) {
              let year = eDate.getFullYear();
              let month = eDate.getMonth() + 1;
              let day = eDate.getDate();
              let sTime = year + "-" + month + "-" + day;
              if (sTime == "1970-1-1") return "";
              else return eDate;
            } else {
              return "";
            }
          },
        },
        {
          name: "progress",
          label: "进度",
          width: 80,
          resize: false,
          align: "center",
          template: function (obj: any) {
            if (obj.progress) return obj.progress + "%";
            else return "";
          },
        },
      ];
    },
    // 设置单位视图
    setUnitView() {
      //   let gantt = this.gantt;
      let zoomConfig = {
        levels: [
          {
            name: "day",
            scale_height: 50,
            min_column_width: 20,
            scales: [
              { unit: "month", step: 1, format: "%Y  %M" },
              { unit: "day", step: 1, format: "%d" },
            ],
          },
          {
            name: "week",
            scale_height: 50,
            min_column_width: 25,
            scales: [
              { unit: "month", format: "%Y %F" },
              {
                unit: "week",
                step: 1,
                format: function (date: any) {
                  let weekNum = gantt.date.date_to_str("%W")(date);
                  return weekNum;
                },
              },
            ],
          },
          {
            name: "month",
            scale_height: 50,
            min_column_width: 40,
            scales: [
              { unit: "year", step: 1, format: "%Y " },
              { unit: "month", format: "%F" },
            ],
          },
          {
            name: "quarter",
            height: 50,
            min_column_width: 90,
            scales: [
              { unit: "month", step: 1, format: "%M" },
              {
                unit: "quarter",
                step: 1,
                format: function (date: any) {
                  var dateToStr = gantt.date.date_to_str("%M");
                  var endDate = gantt.date.add(
                    gantt.date.add(date, 3, "month"),
                    -1,
                    "day"
                  );
                  return dateToStr(date) + " - " + dateToStr(endDate);
                },
              },
            ],
          },
          {
            name: "year",
            scale_height: 27,
            min_column_width: 30,
            scales: [{ unit: "year", step: 1, format: "%Y " }],
          },
        ],
      };
      this.ganttObject.ext.zoom.init(zoomConfig);
      this.ganttObject.ext.zoom.setLevel(this.currentUnitView);
    },
    // 切换单位视图
    handleUnitViewChange(event: any) {
      this.currentUnitView = event.detail.value;
      this.ganttObject.ext.zoom.setLevel(event.detail.value);
    },

    // 切换布局
    changeModel() {
      //切换竖屏
      if (this.isHorizontal) {
        // 定义甘特图的行高
        this.ganttObject.config.row_height = 40;
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );
        this.$emit("showSegment", "show"); //显示导航
      } else {
        // 定义甘特图的行高
        this.ganttObject.config.row_height = 30;
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.LANDSCAPE
        );
        this.$emit("showSegment", "hid"); //隐藏导航
      }

      this.ganttObject.init(this.$refs.gantt);
      this.isHorizontal = !this.isHorizontal;
      // this.screenOrientation.unlock();
    },
    eventBackButton(evt: any) {
      if (this.isHorizontal) {
        this.isHorizontal = !this.isHorizontal;
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );
        document.removeEventListener("backbutton", this.eventBackButton, false);
      }
    },
    listenerBackbutton() {
      document.addEventListener("backbutton", this.eventBackButton, false);
    },
  },
});
