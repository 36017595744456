import BaseService from '../base/BaseService';
import { ProjectApi } from "../../api/project/ProjectApi";
import { FolderApi } from '../../api/file/FolderApi';
import { DocumentApi } from '../../api/file/DocumentApi';
import { StorageApi } from '../../api/file/StorageApi';
import { FilePermissionApi } from '../../api/file/FilePermissionApi';
import { SingleFile, ChunkFileParam, CheckFileParam, MergeFile } from '../../models/file/Storage';
import { PermissionParam } from '../../models/file/Permission';
import { FolderParams, FoleModel } from '@/models/file/Folder';


export default class DocService extends BaseService {

    constructor() {
        super();
    }

    private readonly projectApi = new ProjectApi();
    private readonly folderApi = new FolderApi();
    private readonly documentApi = new DocumentApi();
    private readonly storageApi = new StorageApi();
    private readonly filePermissionApi = new FilePermissionApi();

    //获取文件夹节点标识
    public getByIdForFinish({
        id = '',
    } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this.projectApi.getByIdForFinish(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //根据文件夹标识获取文件列表
    public getFileByFolderId({
        folderId = '',
        systemId = "",
        offset = 1,
        limit = 100
    } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this.folderApi.getFileByFolderId({ folderId, systemId, offset, limit }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //根据文件夹获取文档列表信息
    public getFileList({ folderId = '', } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this.documentApi.getDocumentsByFolderId({ folderId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 单文件上传
     */
    public singleUploadFile(params: SingleFile, localPath: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.singleUploadFile(params, localPath).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("上传异常");
            });
        })
    }

    /**
     * 单文件上传(chat:聊天)
     */
    public chatUploadFile(localPath: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.chatUploadFile(localPath).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("上传异常");
            });
        })
    }

    /**
     * 分片检查
     * @param storage
     * @param fileName 
     */
    public chunkUploadFile(storage: SingleFile): Promise<any> {
        return new Promise((resolve, reject) => {
            //检查文件
            const checkFileParam: CheckFileParam = {
                folderId: storage.folderId,
                name: storage.name == undefined ? '' : storage.name,
                systemId: storage.systemId,
                documentId: storage.documentId
            }
            this.checkfile(checkFileParam).then(async (res: any) => {
                if (res) {
                    let data = res.data;
                    //检查分片
                    const chunkFileParam: ChunkFileParam = {
                        chunkNumber: 0,
                        uploadId: data.uploadId
                    }
                    this.checkchunk(chunkFileParam).then(async (r: any) => {
                        resolve(data);
                    }, (e: string) => {
                        reject(e);
                    });
                }
            }, (e: string) => {
                reject(e);
            });
        })
    }

    public checkchunk(params: ChunkFileParam): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.checkchunk(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("检查分片异常");
            });
        })
    }

    public checkfile(params: CheckFileParam): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.checkfile(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("检查文件异常");
            });
        })
    }

    public merge(params: MergeFile): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.merge(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("合并分片异常");
            });
        })
    }

    /**
     * 检查文件权限
     */
    public checkPermission(params: PermissionParam): Promise<any> {
        return new Promise((resolve, reject) => {
            this.filePermissionApi.checkPermission(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 下载文件
     * @param fileId 
     * @param storagePath 
     */
    public downloadFile(fileId: string, storagePath: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.downloadFile(fileId, storagePath).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(JSON.stringify(err));
                reject("下载异常");
            });
        })
    }

    /**
     * 下载聊天缩略图
     * @param fileId 
     * @param storagePath 
     */
    public downloadChatThumb(fileId: string, storagePath: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.getPreviewImageByFileId(fileId, storagePath).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(JSON.stringify(err));
                reject("下载异常");
            });
        })
    }

    /**
     * 下载原图
     * @param fileId 
     * @param storagePath 
     */
    public downloadOriginal(fileId: string, storagePath: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storageApi.getPreviewFileByFileId(fileId, storagePath).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(JSON.stringify(err));
                reject("下载异常");
            });
        })
    }


    /**
     * 获取单文件上传地址
     */
    public getSingleUploadFile(): string {
        return this.storageApi.getSingleUploadFile();
    }

    /**
     * 获取分片文件上传地址
     */
    public getChunkUploadFile(): string {
        return this.storageApi.getChunkUploadFile();
    }

    /**
     * 获取聊天文件上传地址
     */
    public getChatUploadFile(): string {
        return this.storageApi.getChatUploadFile();
    }

    /**
     * 获取文件下载地址链接
     * @param fileId 
     */
    public getDownloadFile(fileId: string): string {
        return this.storageApi.getDownloadFile(fileId);
    }

    /**
     * 获取文件预览地址链接
     * @param fileId 
     */
    public getPreviewFile(fileId: string): string {
        return this.storageApi.getPreviewFile(fileId);
    }

    /**
     * 获取图片预览地址链接
     */
    public getPreviewImage(): string {
        return this.storageApi.getPreviewImage();
    }

    /**
     * 删除文件(物理删除)
     * @param fileId 
     */
    public clearFileById(fileId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.documentApi.clearFileById(fileId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject("删除文件异常");
            });
        })
    }

    /**
     * 删除文件（逻辑删除）
     * @param fileId 
     */
    public deleteById(fileId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.documentApi.deleteById(fileId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("删除文件异常");
            });
        })
    }

    /**
     * 新增根文件夹
     * @param folder 
     */
    public createRootFolder(folder: FolderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = Object.assign(this.getModel(), Object.assign(folder, FoleModel));
            this.folderApi.createRootFolder(params).then(async (res: any) => {
                if (res) {
                    resolve(params.id);
                } else {
                    resolve("");
                }
            }).catch((err: any) => {
                console.log(err);
                reject("新增文件异常");
            });
        })
    }

    /**
     * 新增文件夹
     * @param folder 
     */
    public createSubFolder(folder: FolderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            const params = Object.assign(this.getModel(), Object.assign(folder, FoleModel));
            this.folderApi.createSubFolder(params).then(async (res: any) => {
                if (res) {
                    resolve(params.id);
                } else {
                    reject("创建文件夹失败");
                }
            }).catch((err: any) => {
                console.log(err);
                reject("创建文件夹异常");
            });
        })
    }

    /**
    *项目-回收站-文件列表
    * @param  
    */
    public getPagedRootDeletedDocuments(params: any) {
        return new Promise((resolve, reject) => {
            this.documentApi.getPagedRootDeletedDocuments(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("获取文件列表异常");
            });
        })
    }

    /**
    * 还原文件
    * @param  
    */
    public restoreDocumnetById(params: any) {
        return new Promise((resolve, reject) => {
            this.documentApi.restoreDocumnetById(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("还原文件异常");
            });
        })
    }

    /**
    * 项目-回收站-文件夹tree
    * */
    public getTreeByProjectId(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.folderApi.getTreeByProjectId(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    private getModel() {
        return this.newModel('doc', 'folder');
    }

    /**
     * 删除文件
     * @param fileId 
     */
    public delFileById(fileId: string) {
        return new Promise((resolve, reject) => {
            this.documentApi.deleteById(fileId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("删除文件异常");
            });
        })
    }

    /**
     * 获取文件信息
     * @param fileIds 逗号分隔的id
     */
    public getFilesByIds(fileIds: string) {
        return new Promise((resolve, reject) => {
            this.documentApi.getFilesByIds(fileIds).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject("获取文件信息异常");
            });
        })
    }

    /**
     * 获取文件信息
     * @param fileId
     */
    public getFilesById(fileId: string) {
        return new Promise((resolve, reject) => {
            this.folderApi.getFilesById(fileId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //项目回收站： 文件夹列表
    public getPagedRootDeletedFolders({
        rootId = '',
        offset = 1,
        limit = 20
    } = {}): Promise<any> {
        return new Promise((resolve, reject) => {
            this.folderApi.getPagedRootDeletedFolders({ rootId, offset, limit }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //项目回收站： 还原文件夹
    public restoreFolder(folderId: string, targetFolderId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.folderApi.restoreFolder(folderId, targetFolderId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 删除目录
     * @param folder
     */
    public clearFolder(folder: string) {
        return new Promise((resolve, reject) => {
            this.folderApi.clearFolder(folder).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                console.log(err);
                reject("删除文件异常");
            });
        })
    }

}