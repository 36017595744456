export class State {

    public access_token?:string; //登录token
    public refresh_token: string = '';
    public accessToken_expires: number = 0;//单位毫秒
    public refreshToken_expires: number = 0;//单位毫秒 30天默认

    public getExpiresTimestamp(expires: number): number {
        let timestamp = new Date().getTime() + (expires - 60) * 1000;
        return timestamp;
    }
}

