
import { IonCheckbox } from "@ionic/vue";
export default {
  name: "HomeSchedule",
  components: {IonCheckbox},
  props:{
    ScheduleObj:Object
  },
  data() {
    return {
      
    }
  },
};
