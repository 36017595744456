import BaseModel from '@/services/base/model/BaseModel';

export class Task extends BaseModel {
    id!: string;
    name: string="";
    rootId!: string;
    parentId?: string;
    startTime?: string;
    endTime?: string;
    taskVisibility?: number;
    stageId?: string;
    taskTypeId?: string;
    pos?: number;
    projectId?: string;
    finishTime?: string;
    finishId?: string;
    finisher?: string;
    handleUrl?: string;
    busTemplate?: string;
    busId?: string;
    priority?: string;
    difficulty?: string;
    expireGrade?: number;
    chatGroupId?: string;
    orgId?: string;
    code?: string;
    busTable?: string;
    projectName?: string;
    taskGroupName?: string;
    stageName?: string;
    stageNo?: number;
    busTag?: string;
    busNo?: string;
    taskGroupId?: string;
    folderId?: string;
    parentName?: string;
    executorIds?: string;
    executorNames?: string;
    partyIds?: string;
    partyNames?: string;
    isDeliveryTemplate?: boolean;
    deliveryRequire?: string;
    childCount: number = 0;
    childFinishedCount: number = 0;
    isParentFinished?: boolean;
    subTask: any[]=[];//子任务
    taskLink: any[]=[];
    taskRely: any[]=[];
    taskData: any[]=[];
    taskUser: any[]=[];
    systemName?: string;
    isCheckFinished?: boolean;
    isCheckVisible?: boolean;
    tagLink: any[]=[];
    taskRole?: string;
    planWorkingHour: string="";
    actualStartTime: string="";
    actualEndTime: string="";
    actualWorkingHour: number=0;
    progress: number = 0;
    isFinished?: boolean;
    isFinishedStr: string="";
    isUserFinished?: boolean;;
    isChildsFinished?: boolean;;
    isDeletedProject?: boolean;;
    isDeletedTaskGroup?: boolean;;
    isDeletedTaskStage?: boolean;;
    isUserDelete?: boolean;;
    isExistLink?: boolean;;
    isExistChild?: boolean;;
    isExistMsg?: boolean;

    /**自定义 */
    parentTaskId?: string;
    parentTaskName?: string;

    executorUsers: any[]=[];//执行人集合
    partUsers: any[]=[];//参与人集合

    constructor(item?: any) {
        super();
        if(!item){return;}
        this.id=item.id;
        this.name=item.name;
        this.rootId=item.rootId;
        this.parentId=item.parentId;
        this.startTime=item.startTime;
        this.endTime=item.endTime;
        this.taskVisibility=item.taskVisibility;
        this.stageId=item.stageId;
        this.taskTypeId=item.taskTypeId;
        this.pos=item.pos;
        this.projectId=item.projectId;
        this.finishTime=item.finishTime;
        this.finishId=item.finishId;
        this.finisher=item.finisher;
        this.handleUrl=item.handleUrl;
        this.busTemplate=item.busTemplate;
        this.busId=item.busId;
        this.priority=item.priority+"",
        this.difficulty = item.difficulty+"",
        this.expireGrade=item.expireGrade,
        this.chatGroupId=item.chatGroupId;
        this.orgId=item.orgId;
        this.code=item.code;
        this.busTable=item.busTable;
        this.projectName=item.projectName;
        this.taskGroupName=item.taskGroupName;
        this.stageName=item.stageName;
        this.stageNo=item.stageNo;
        this.busTag=item.busTag;
        this.busNo=item.busNo;
        this.taskGroupId=item.taskGroupId;
        this.folderId=item.folderId;
        this.parentName=item.parentName;
        this.executorIds=item.executorIds;
        this.executorNames=item.executorNames;
        this.partyIds=item.partyIds;
        this.partyNames=item.partyNames;
        this.isDeliveryTemplate=item.isDeliveryTemplate,
        this.deliveryRequire=item.deliveryRequire;
        this.childCount=item.childCount,
        this.childFinishedCount=item.childFinishedCount,
        this.isParentFinished=item.isParentFinished;
        this.subTask=item.subTask;
        this.taskLink=item.taskLink;
        this.taskRely=item.taskRely;
        this.taskData=item.taskData;
        this.taskUser=item.taskUser;
        this.systemName=item.systemName;
        this.isCheckFinished=item.isCheckFinished;
        this.isCheckVisible=item.isCheckVisible;
        this.tagLink=item.tagLink;
        this.taskRole=item.taskRole;
        this.planWorkingHour=item.planWorkingHour;
        this.actualStartTime=item.actualStartTime;
        this.actualEndTime=item.actualEndTime;
        this.actualWorkingHour=item.actualWorkingHour;
        this.progress=item.progress;
        this.isDeleted=item.isDeleted;
        this.isFinished=item.isFinished;
        this.isFinishedStr=item.isFinished?"1":"0";
        this.isUserFinished=item.isUserFinished;
        this.isChildsFinished=item.isChildsFinished;
        this.isDeletedProject=item.isDeletedProject;
        this.isDeletedTaskGroup=item.isDeletedTaskGroup;
        this.isDeletedTaskStage=item.isDeletedTaskStage;
        this.isUserDelete=item.isUserDelete;
        this.isExistLink=item.isExistLink;
        this.isExistChild=item.isExistChild;
        this.isExistMsg=item.isExistMsg;
        this.remark=item.remark;
        this.creatorId=item.creatorId;
    }
}