
import { defineComponent } from "vue"; //
import { useRouter, useRoute } from "vue-router";
import SearchBar from "@/components/SearchBar.vue";
import { HelpGuideService } from "@/services/helphuide/HelpGuideService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "HelperGuide",
  components: { SearchBar },
  data() {
    const router = useRouter();
    return {
      helpGuideService: new HelpGuideService(),

      listParams: {
        searchKey: "",
        offset: 1,
        limit: 10,
      },

      dataList: [],
      router: router,
      isList: false,
    };
  },
  props: {},
  created() {},
  ionViewWillEnter() {},
  mounted() {
    this.getHelpGuide();
  },
  methods: {
    getHelpGuide() {
      this.helpGuideService
        .getHelpGuideList(this.listParams)
        .then((r: any) => {
          this.isList = true;
          if (r) {
            this.dataList = r;
          }
        })
        .catch((error: any) => {
          Utils.presentToastWarning("获取反馈记录异常");
          this.isList = true;
        });
    },

    openDetails(item: any) {
      this.router.push({
        path: "/HelperDetails",
        query: {
          name: item.name,
          replyContent: item.content,
        },
      });
    },

    searchHandler() {},
  },
});
