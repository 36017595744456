
import { IonContent, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "segmentPullDown",
  components: { IonContent },
  props: {
    fromType:String,
    searchNameArr:Array,
    SelectId:String,
  },
  data() { 
    return { 
      searchSelectId:this.SelectId ,
      searchSelectType:"", 
    };
  },
  
  methods: {
    getHeight(){
      let point=0.8;
      if(this.fromType && this.fromType=="taskList"){
        point=0.6;
      }
      return document.body.clientHeight * (document.body.clientHeight/812*point) +'px';
    },
   
    clickItem(obj: any) {
      if (obj) {
        this.searchSelectId = obj.id;
        this.searchSelectType = obj.queryType;
        popoverController.dismiss({searchSelectId:this.searchSelectId,searchSelectType:this.searchSelectType});
      }else{
         popoverController.dismiss();
      }
    },
  },
});
