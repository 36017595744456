import axios from 'axios';
import Utils, { transformRequest, toCamel } from '@/utils/Utils';
import { ErrorModel } from '@/models/base/ReponseModel';
import CookieHelper from '../utils/CookieHelper';
import Setting from '@/rest/Setting';

const headers = {};

axios.defaults.withCredentials = false;
axios.interceptors.request.use(
    (config: any) => {
        if (config.url.indexOf('attapi') > -1) {
            config.headers['X-APP-ID'] = Setting.defalutAPPID;
        }
        else {
            //企业编码
            config.headers['enterpriseId'] = Setting.currentEnterprise.id;
        }
        if (config.url.indexOf('/oauth') >= 0) {
            return config;
        }

        const token = CookieHelper.get(Setting.authCookieName);
        if (token && token != "null") {
            config.headers['Authorization'] = JSON.parse(token).access_token;
        }

        if (config.url.indexOf("/getMqttAddress")<0 && (token == null || token == "null")) {
            quitAccount();
        }
        return config
    },
    (error: any) => {
        //SystemUtils.showNormalError(error.message, "Error");
        return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    response => {
        const res = response.data;
        if (response.status !== 200) {
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            const resModel: any = {};
            resModel.method = response.config.method as string;
            resModel.data = response.data;
            resModel.status = response.status;
            resModel.statusText = response.statusText;
            //循环headers赋值
            if (response.headers) {
                for (const item in response.headers) {
                    if (item.indexOf("x-") > -1) {
                        const itemName = toCamel(item);
                        resModel[itemName] = response.headers[item];
                    }
                }
            }
            return resModel;
        }
    },
    error => {
        const errorModel: ErrorModel = {};
        if(error.config){
            errorModel.method = error.config.method as string;
        }
        if (error.response) {
            errorModel.data = error.response.data;
            errorModel.code = error.response.data.code;
            errorModel.httpCode = error.response.data.httpCode;
            errorModel.message = error.response.data.message;
            errorModel.status = error.response.status;
            errorModel.statusText = error.response.statusText;

            //if (error.response.status == 401 && error.url.indexOf("/getMqttAddress")<0) {
                //todo 若重复登陆三次还是失败，那么给出提示，并退出到login页面
                //Utils.presentToastWarning("认证信息失效，请重新认证");
                //quitAccount();
            //}
    
            if (error.response.status >= 500) {
                Utils.presentToastWarning("服务器外出一会儿~");
            }
        }
        
        return Promise.reject(errorModel);
    }
);


//退出账号
const quitAccount = () => {
    if(window.location.href.indexOf("/login")<0){
         Utils.presentToastWarning("验证信息失效");
        window.location.href = '/login';
    }
}

export const postRequest = (url: string, params: any) => {
    return axios({
        method: 'post',
        url: url,
        data: params,
        headers: Object.assign({
            'Content-Type': 'application/json'
        }, headers)
    });
}
export const uploadFileRequest = (url: string, params: any) => {
    return axios({
        method: 'post',
        url: url,
        data: params,
        transformRequest: [function (data) {
            let ret = ''
            for (const it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }],
        headers: Object.assign({
            'Content-Type': 'multipart/form-data'
        }, headers)
    });
}
export const putRequest = (url: string, params: any) => {
    return axios({
        method: 'put',
        url: url,
        data: JSON.stringify(params),
        headers: Object.assign({
            'Content-Type': 'application/json'
        }, headers)
    });
}
export const deleteRequest = (url: string, bodyData?: any) => {
    let data: string;
    data = "";
    if (bodyData) {
        data = JSON.stringify(bodyData);
    }

    return axios({
        method: 'delete',
        url: url,
        data: data,
        headers: Object.assign({
            'Content-Type': 'application/json'
        }, headers)
    });
}

export const getRequest = (url: string, data: any) => {
    if (data) {
        const params = transformRequest(data);
        if (url.indexOf('?') != -1) {
            url = url + "&" + params;
        } else {
            url = url + "?" + params;
        }
    }
    return axios({
        headers: headers,
        method: 'get',
        url: url,
    });
}
export const getRequestCustom = (url: string, data: any, header: any) => {
    if (data) {
        const params = transformRequest(data);
        if (url.indexOf('?') != -1) {
            url = url + "&" + params;
        } else {
            url = url + "?" + params;
        }
    }
    return axios({
        headers: header,
        method: 'get',
        url: url,
    });
}