/**
 * 业务配置
 * */
export default class Setting {
    //应用标识
    public static appID = process.env.APP_ID;

    public static enableByod = process.env.VUE_APP_ENABLE_BYOD == "true";

    // 接口请求地址  
    public static baseURL = process.env.VUE_APP_BASE_URL;
    public static apiBaseURL = Setting.baseURL + "/api";

    public static mqttIp = process.env.VUE_APP_MQTT_IP;
    public static mqttPort = process.env.VUE_APP_MQTT_PORT;

    //上汽环境 开关
    public static appDeployment = process.env.VUE_APP_DEPLOYMENT;

    public static apiBaseServiceName = {
        "accountservice": "auth",
        "dashboardservice": "",
        // "fileservice": "fileservice/v1",
        "fileservice": "docservice/v1",
        "taskservice": "taskservice/v1",
        "msgservice": "messageservice/v1",
        "tagservice": "tagservice/v1",
        // "orgnaiservice": "organization",
        "orgnaiservice": "taskservice",
        "microissuesservice": "microissues",
        "logservice": "datalogservice/v1",
        "planCenter": "plan-center",
        "labelservice": "label",
        "okrservice": "taskservice/v1",
    };

    public static isSVW=Setting.appDeployment?.startsWith("SVW") as boolean;
    // curl获取byod信息链接
    public static kCURLOPT_URL = "https://byod.csvw.com/api/v2/appstore/apps?adminDeviceSpaceId=1&query=";
    // kByodAccountName byod 的对应账号
    public static kByodAccountName = "appapi";
    // kByodAccountPassword byod 的对应 密码
    public static kByodAccountPassword = "1qaz@WSX";
    public static defalutAPPID = '010001';
    static clientSecret: string = "6c8dee3e9bdc11e9861d5254008baa09";

    public static authCookieName = "state";
    public static refreshingTokenName = "refreshingToken";

    //用于角色类型区分，管理端应用:MANAGER_LOGIN
    public static roleType = 'MANAGER_LOGIN';

    /**
     * 获取当前企业Id
     */
    public static get currentEnterprise() {
        return {
            id: "1",
            name: "上汽大众"
        };
    }
}