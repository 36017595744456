
import { defineComponent } from "vue";
import { modalController, IonPage } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";

import CalendarCard from "../component/CalendarCard.vue";

import { CalendarService } from "@/services/calendar/CalendarService.ts";

export default defineComponent({
  name: "SelectCalendar",
  props: {
    paramList: Object,
  },
  components: {
    HeaderModal,
    IonPage,
    SearchBar,
    CalendarCard,
  },
  data() {
    const list = [];
    return {
      calendarService: new CalendarService(),
      selectedItem: { id: "", name: "" },
      selectModel: true,
      selectedId: "",
      list: new Array(),
      parameters: {
        limit: 10,
        offset: 1,
        calName: "",
      },
      isRollback: true,
      isModal: true,
      selectList: new Array(),
    };
  },
  mounted() {
    if (this.paramList) {
      this.selectList = this.paramList.list;
    }
    this.resetPage();
  },
  methods: {
    removeSelectItem() {
      this.selectList = new Array();
      this.selectedItem = { id: "", name: "" };
      this.selectedId = "";
      this.list.forEach((ele: any) => {
        ele.checked = false;
      });
    },
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        this.selectList.push(item);
      } else {
        this.selectList = this.selectList.filter((e) => {
          return e.id != item.id;
        });
      }
    },
    //重新从第一页获取
    resetPage() {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.parameters.calName = data.searchKey;
      this.resetPage();
    },
    closeModel(type: any) {
      if (type == 4) {
        if (this.selectList.length > 0) {
          //关闭页面
          modalController.dismiss({
            selectedCalendar: this.selectList,
          });
        } else {
          modalController.dismiss({ selectedCalendar: new Array() });
        }
      } else {
        modalController.dismiss({});
      }
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            //  event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      let that = this;
      this.calendarService.getPageMyCal(this.parameters).then((res: any) => {
        res.data.forEach((ele: any) => {
          let tempList = that.selectList.filter((sel: any) => {
            return sel.id == ele.id;
          });
          if (tempList.length == 0) {
            ele.checked = false;
          } else {
            ele.disabled = tempList[0].canCheck ? "false" : "true";
            ele.checked = true;
          }
        });
        callback(res.data);
      });
    },
  },
});
