
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import RemindItemCard from "@/components/ListCard/RemindItemCard.vue";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import moment from "moment";
import Utils from "@/utils/Utils";
import { WarningService } from "@/services/warning/WarningService.ts";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
export default defineComponent({
  name: "RemindReadedList",
  components: {
    RemindItemCard,
    TaskCards,
    CardLoading,
  },
  props: {
    refresh: Boolean, //是否刷新页面
    params: Object, //搜索条件
    selectModuleCode: String,
  },
  setup(props) {
    const { refresh, params, selectModuleCode } = toRefs(props);
    const router = useRouter();
    const pointDate = new Date();
    const warningService = new WarningService();
    const remindList = reactive({ data: new Array() });
    const dateMarkers = reactive({ data: new Array() });
    let searchParams = reactive({
      limit: 10,
      offset: 1,
      isReaded:1,
    });
    //滚动到顶部
    const scrollToTop = () => {
      nextTick(() => {
        let ionContent: any = document.querySelector("#otherCalendarMain");
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    };
    const isMore = ref(false);
    let islist = ref(true); //是否显示加载中的标识
    const initData = () => {
      resetPage();
    };

    //重新从第一页获取
    const resetPage = (event?: any) => {
      searchParams.offset = 1;
      getListRequest((res: any) => {
        remindList.data = res;
        if (event) {
          setTimeout(() => {
            event.target.complete();
          }, 1000);
        }
        scrollToTop();
      });
    };
    const nextPage = (event: any) => {
      searchParams.offset++;
      getListRequest((result: any) => {
        setTimeout(() => {
          event.target.complete();
        }, 1000);
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            remindList.data = remindList.data.concat(result);
          }
        }
      });
    };
    const doRefresh = (event: any) => {
      resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      warningService
        .getListByModules(searchParams)
        .then((res: any) => {
          if (res && res.data) {
            islist.value = false;
            res.data.forEach((ele: any) => {
              ele.propertiesJson = JSON.parse(ele.properties);
            });
            callback(res.data);
          }
        })
        .catch(() => {
          islist.value = false;
        });
    };
    //详情
    const detailHandler = (result: any) => {
      let moduleCode = "";
      if (selectModuleCode && selectModuleCode.value) {
        moduleCode = selectModuleCode.value;
      }
      switch (moduleCode) {
        case "task":
          router.push({
            path: "/task-page",
            query: {
              id: result.item.propertiesJson._sys_params.taskId,
              taskName: result.item.propertiesJson._sys_params.taskName,
            },
          });
          break;
        case "OKR":
          router.push({
            path: "/OKR/details",
            query: {
              id: result.item.itemId,
            },
          });
          break;
        case "schedule":
          router.push({
            path: "/schedule/detail/feedback",
            query: {
              id: result.item.propertiesJson._sys_params.scheduleId,
              scheduleTimeId: result.item.propertiesJson._sys_params.timeId,
              showCalendar: "true",
            },
          });
          break;
        case "project":
          router.push({
            path: "/ProjectPage",
            query: {
              projectId: result.item.propertiesJson._sys_params.projectId,
              projectName: result.item.propertiesJson._sys_params.projectName,
              isStart: result.item.propertiesJson._sys_params.isStart,
              chatGroupId: result.item.propertiesJson._sys_params.chatGroupId,
              folderId: result.item.propertiesJson._sys_params.folderId,
              isDeleted: result.item.propertiesJson._sys_params.isDeleted,
            },
          });
          break;
      }
    };
    onMounted(() => {
      if (params != undefined && params.value != undefined) {
        searchParams = Object.assign(searchParams, params.value);
      }
      initData();
    });
    onUnmounted(() => {});

    watch(refresh, (newValue, oldVlaue) => {
      if (newValue) {
        searchParams.offset = 1;
        if (params != undefined && params.value != undefined) {
          searchParams = Object.assign(searchParams, params.value);
        }
        initData();
      }
    });
    return {
      islist,
      router,
      pointDate,
      remindList,
      searchParams,
      isMore,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      detailHandler,
    };
  },
});
