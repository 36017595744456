import { Calendar, CalendarOptions, NameOrOptions } from '@ionic-native/calendar';
import { AndroidPermissions } from '@ionic-native/android-permissions';
import { isPlatform, alertController } from "@ionic/vue";
import SystemUtils from "@/utils/SystemUtils";

/**
 * 本地日程操作类
 */
export class CalendarNative {

    constructor() {
    }

    public readonly CalendarNameIOS = 'TaskCalendar';
    public readonly CalendarKeyIOS = ['Subscription', 'Birthday', 'Mail'];
    public readonly CalendarKeyAndroid = SystemUtils.loginUser.email;

    /**
     * 检查读取权限
     */
    public checkReadPermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Calendar.hasReadPermission().then((r: boolean) => {
                if (r) {
                    resolve(true);
                } else {
                    AndroidPermissions.requestPermissions([AndroidPermissions.PERMISSION.READ_CALENDAR]).then((rr: any) => {
                        resolve(true);
                    }, (re: any) => {
                        reject(false);
                    }).catch((c: any) => {
                        reject(false);
                    });
                }
            }, (e: any) => {
                reject(false);
            }).catch((c: any) => {
                reject(false);
            });
        });
    }

    /**
     * 检查写入权限
     */
    public checkWritePermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Calendar.hasWritePermission().then((r: boolean) => {
                if (r) {
                    resolve(true);
                } else {
                    AndroidPermissions.requestPermissions([AndroidPermissions.PERMISSION.WRITE_CALENDAR]).then((rr: any) => {
                        resolve(true);
                    }, (re: any) => {
                        reject(false);
                    }).catch((c: any) => {
                        reject(false);
                    });
                }
            }, (e: any) => {
                reject(false);
            }).catch((c: any) => {
                reject(false);
            });
        });
    }

    /**
     * 检查读写权限
     */
    public checkReadWritePermission(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Calendar.hasReadWritePermission().then((r: boolean) => {
                if (r) {
                    resolve(true);
                } else {
                    const pm = [AndroidPermissions.PERMISSION.READ_CALENDAR, AndroidPermissions.PERMISSION.WRITE_CALENDAR];
                    AndroidPermissions.requestPermissions(pm).then((rr: any) => {
                        resolve(true);
                    }, (re: any) => {
                        reject(false);
                    }).catch((c: any) => {
                        reject(false);
                    });
                }
            }, (e: any) => {
                reject(false);
            }).catch((c: any) => {
                reject(false);
            });
        });
    }

    /**
     * 创建日历（ios-多日历，android-单日历）
     */
    public createCalendar(color16?: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.checkReadWritePermission().then((bo: boolean) => {
                if (bo) {
                    if (isPlatform('ios')) {
                        this.createCalendarIos(this.CalendarNameIOS, color16);
                    } else {
                        // Utils.presentToastWarning('该系统无需创建日历');
                        reject(false);
                    }
                } else {
                    // Utils.presentToastWarning('日历没有创建权限');
                    reject(false);
                }
            }, (e: boolean) => {
                // Utils.presentToastWarning('日历创建权限检查异常');
                reject(false);
            });
        });
    }

    /**
     * 创建日历IOS
     * @param calendarName 
     */
    private createCalendarIos(calendarName: string, color?: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let param: string | NameOrOptions;
            if (color != undefined && color != null && color != '') {
                const option: NameOrOptions = Calendar.getCreateCalendarOptions();
                option.calendarName = calendarName;
                option.calendarColor = color;
                param = option;
            } else {
                param = calendarName;
            }
            Calendar.createCalendar(param).then(
                (r: any) => {
                    console.log(JSON.stringify(r));
                    resolve(true);
                },
                (e: any) => {
                    // Utils.presentToastWarning('创建日历失败');
                    reject(false)
                }
            );
        });
    }

    /**
     * 创建事件（系统默认账户：android）
     */
    public createEvent(option: EventOption): Promise<any> {
        return new Promise((resolve, reject) => {
            const title = option.title;
            const eventLocation = option.eventLocation;
            const notes = option.notes;
            const startDate = option.startDate;
            const endDate = option.endDate;
            Calendar.createEvent(title, eventLocation, notes, startDate, endDate).then(
                (r: any) => { resolve(r); },
                (e: any) => { reject(e); }
            );
        });
    }

    /**
     * 创建事件（静默）
     * @param option 
     */
    public createEventSilently(option: EventOption): Promise<any> {
        const calOptions = Calendar.getCalendarOptions();
        calOptions.firstReminderMinutes = 60;
        calOptions.secondReminderMinutes = 5;
        if (isPlatform('ios')) {
            calOptions.calendarName = option.calendarName;
        } else {
            calOptions.calendarId = parseInt(option.calendarId);
        }
        const title = option.title;
        const eventLocation = option.eventLocation;
        const notes = option.notes;
        const startDate = option.startDate;
        const endDate = option.endDate;
        return new Promise((resolve, reject) => {
            Calendar.createEventWithOptions(title, eventLocation, notes, startDate, endDate, calOptions).then(
                (r: any) => {
                    console.log('日程事件标识：' + JSON.stringify(r));
                    resolve(r);
                },
                (e: any) => {
                    console.log('失败：' + JSON.stringify(e));
                    reject(false);
                }
            ).catch((c: any) => {
                console.log('异常：' + JSON.stringify(c));
                reject(false);
            });
        });
    }

    private static delTimes = 3;//最多删除次数

    /**
     * 修改事件（静默）
     * @param optionOld 
     * @param optionNew 
     */
    public modifyEventSilently(id: string, optionNew: EventOption): Promise<any> {
        const calOptions = Calendar.getCalendarOptions();
        calOptions.firstReminderMinutes = 60;
        calOptions.secondReminderMinutes = 5;
        if (isPlatform('ios')) {
            calOptions.calendarName = optionNew.calendarName;
        } else {
            calOptions.calendarId = parseInt(optionNew.calendarId);
        }
        return new Promise((resolve, reject) => {
            this.operateDelEvent(id);
            Calendar.createEventWithOptions(optionNew.title, optionNew.eventLocation, optionNew.notes, optionNew.startDate, optionNew.endDate, calOptions).then(
                (r: any) => {
                    console.log('日程事件标识：' + JSON.stringify(r));
                    resolve(r);
                },
                (e: any) => {
                    console.log('失败：' + JSON.stringify(e));
                    reject(false);
                }
            ).catch((c: any) => {
                console.log('异常：' + JSON.stringify(c));
                reject(false);
            });
        });
    }

    private operateDelEvent(id: string) {
        console.log('删除事件');
        this.deleteEventById([id]).then((dr: any) => {
            console.log("删除完成：" + JSON.stringify(dr));
        }, (e: boolean) => {
            console.log('删除事件错误');
        });
    }

    public deleteEventByOption(option: EventOption): Promise<any> {
        return new Promise((resolve, reject) => {
            Calendar.deleteEvent(option.title, option.eventLocation, option.notes, option.startDate, option.endDate).then((r: any) => {
                this.createEventSilently(option).then((r: any) => {
                    resolve(r);
                }, (e: boolean) => {
                    resolve(e);
                });
            }, (e: any) => {
                if (e == true) {
                    this.createEventSilently(option).then((r: any) => {
                        resolve(r);
                    }, (e: boolean) => {
                        resolve(e);
                    });
                }
                reject(false);
            });
        });
    }

    /**
     * 删除日历(ios)
     * @param calendarName 
     */
    public deleteCalendar(calendarName: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.checkReadWritePermission().then((bo: boolean) => {
                if (bo) {
                    Calendar.deleteCalendar(calendarName).then(
                        (r: any) => {
                            console.log(JSON.stringify(r));
                            resolve(true);
                        },
                        (e: any) => {
                            console.log(JSON.stringify(e));
                            reject(false);
                        }
                    );
                } else {
                    // Utils.presentToastWarning('日历没有操作权限');
                    reject(false);
                }
            }, (e: boolean) => {
                // Utils.presentToastWarning('日历操作权限检查异常');
                reject(false);
            });
        });
    }

    public async presentAlert(message: string) {
        const alert = await alertController.create({
            message: message,
            buttons: ['OK']
        });
        return alert.present();
    }

    /**
     * 获取所有日历（android单日历，ios多个日历）
     */
    public findAllCalendarNative(): Promise<Array<CalendarIOS | CalendarAndroid>> {
        return new Promise((resolve, reject) => {
            this.checkReadWritePermission().then((bo: boolean) => {
                if (bo) {
                    Calendar.listCalendars().then((r: any) => {
                        if (r) {
                            if (isPlatform('ios')) {
                                let cals = r.filter((item: any, index: number, arr: any) => {
                                    let flag = true;
                                    for (let i = 0; i < this.CalendarKeyIOS.length; i++) {
                                        if (item.type === this.CalendarKeyIOS[i]) {
                                            flag = false;
                                            i = this.CalendarKeyIOS.length;
                                        }
                                    }
                                    return flag;
                                });
                                resolve(cals);
                            } else {
                                resolve(r);
                            }
                        } else {
                            // Utils.presentToastWarning('日历不存在');
                            console.log('获取列表错误');
                            reject(null);
                        }
                    }, (e: any) => {
                        // Utils.presentToastWarning('获取日历列表失败');
                        console.log('获取列表异常');
                        reject(null);
                    });
                } else {
                    // Utils.presentToastWarning('日历没有读取权限');
                    console.log('权限错误');
                    reject(null);
                }
            }, (e: boolean) => {
                console.log('权限异常');
                // Utils.presentToastWarning('日历读取权限检查异常');
                reject(null);
            });
        });
    }

    /**
     * 获取所有日历（android单日历，ios多个日历）
     */
    public findAllCalendar(): Promise<Array<CalendarIOS | CalendarAndroid>> {
        return new Promise((resolve, reject) => {
            Calendar.listCalendars().then((r: any) => {
                if (r) {
                    const flag = localStorage.getItem('isSyncNative');
                    const account = localStorage.getItem('syncNativeAccount');
                    if (flag === 'true') {
                        if (isPlatform('ios')) {
                            let cals = r.filter((item: any, index: number, arr: any) => {
                                return item.name === account;
                            });
                            if (cals == null || cals.length == 0) {
                                // Utils.presentToastWarning('未发现关联Exchange账户的系统日历');
                            }
                            resolve(cals);
                        } else {
                            let cals = r.filter((item: any, index: number, arr: any) => {
                                return item.id + '' === account;
                            });
                            if (cals == null || cals.length == 0) {
                                // Utils.presentToastWarning('未发现与该用户邮箱进行关联的系统日历');
                            }
                            resolve(cals);
                        }
                    } else {
                        resolve([]);
                    }
                } else {
                    // Utils.presentToastWarning('日历不存在');
                    reject(null);
                }
            }, (e: any) => {
                // Utils.presentToastWarning('获取日历列表失败');
                reject(null);
            });
        });
    }

    /**
     * 获取所有事件
     */
    public findAllEvents(): Promise<Array<EventAndroid | EventIOS>> {
        return new Promise((resolve, reject) => {
            let endDate = new Date(2022, 1, 1, 0, 0, 0, 0);
            let startDate = new Date(2015, 1, 1, 0, 0, 0, 0);
            this.findAllCalendar().then((r: Array<CalendarIOS | CalendarAndroid>) => {
                if (r != null && r.length > 0) {
                    if (isPlatform('ios')) {
                        let cals: Array<CalendarIOS> = new Array<CalendarIOS>();
                        r.forEach((cal: any) => {
                            if (cal != null) {
                                let calendar: CalendarIOS = {
                                    id: cal.id,
                                    name: cal.name,
                                    type: cal.type
                                }
                                cals.push(calendar);
                            }
                        });
                        this.recursionEventsIos(startDate, endDate, cals, 0).then((events: Array<EventIOS>) => {
                            resolve(events);
                        });
                    } else {
                        if (r.length > 0) {
                            let cal: any = r[0];
                            let calendar: CalendarAndroid = {
                                id: cal.id,
                                displayname: cal.displayname,
                                isPrimary: cal.isPrimary
                            }
                            if (cal.name != undefined) {
                                calendar.name = cal.name;
                            }
                            this.findAllEventsAndroid(startDate, endDate, calendar).then((events: Array<EventAndroid>) => {
                                resolve(events);
                            }, (e: any) => {
                                reject(e);
                            });
                        }
                    }
                } else {
                    reject(null);
                }
            }, (e: any) => {
                reject(null);
            });
        });
    }

    /**
     * 获取android日历下事件
     * @param startDate 
     * @param endDate 
     */
    private findAllEventsAndroid(startDate: Date, endDate: Date, calendar: CalendarAndroid): Promise<Array<EventAndroid>> {
        return new Promise((resolve, reject) => {
            let option: CalendarOptions = Calendar.getCalendarOptions();
            option.calendarId = parseInt(calendar.id);
            Calendar.findEventWithOptions(undefined, undefined, undefined, startDate, endDate, option).then((r: any) => {
                let events = new Array<EventAndroid>();
                if (r && r.length > 0) {
                    r.forEach((ele: any) => {
                        let event: EventAndroid = {
                            id: ele.id,
                            title: ele.title,
                            location: ele.location,
                            message: ele.message,
                            startDate: ele.startDate,
                            endDate: ele.endDate,
                            allday: ele.allday
                        }
                        events.push(event);
                    });
                }
                resolve(events);
            }, (e: any) => {
                // Utils.presentToastWarning('获取事件列表失败');
                reject(null);
            });
        });
    }

    /**
     * 递归获取事件（ios）
     * @param startDate 
     * @param endDate 
     * @param cals 
     * @param index 
     */
    private recursionEventsIos(startDate: Date, endDate: Date, cals: Array<CalendarIOS>, index: number): Promise<Array<EventIOS>> {
        return new Promise((resolve, reject) => {
            this.findAllEventsIos(startDate, endDate, cals[index]).then((events: Array<EventIOS>) => {
                if (index + 1 < cals.length) {
                    this.recursionEventsIos(startDate, endDate, cals, index + 1).then((next: Array<EventIOS>) => {
                        resolve(next.concat(events));
                    });
                } else {
                    resolve(events);
                }
            }, (e: any) => {
                resolve(new Array<EventIOS>());
            });
        });
    }

    /**
     * 获取ios日历下事件
     * @param startDate 
     * @param endDate 
     * @param cal 
     */
    private findAllEventsIos(startDate: Date, endDate: Date, cal: CalendarIOS): Promise<Array<EventIOS>> {
        return new Promise((resolve, reject) => {
            let option: CalendarOptions = Calendar.getCalendarOptions();
            option.calendarName = cal.name;
            Calendar.findEventWithOptions(undefined, undefined, undefined, startDate, endDate, option).then((r: any) => {
                let events = new Array<EventIOS>();
                if (r && r.length > 0) {
                    r.forEach((ele: any) => {
                        let event: EventIOS = {
                            calendar: ele.calendar,
                            id: ele.id,
                            title: ele.title,
                            location: ele.location,
                            message: ele.message,
                            startDate: ele.startDate,
                            endDate: ele.endDate,
                            lastModifiedDate: ele.lastModifiedDate,
                        }
                        if (ele.rrule != undefined && ele.rrule != null) {
                            let rule: ruleIOS = {
                                freq: ele.rrule.freq,
                                interval: ele.rrule.interval,
                                calendar: ele.rrule.calendar
                            }
                            if (ele.rrule.until != undefined && ele.rrule.until != null) {
                                let until: untilIOS = {
                                    count: ele.rrule.until.count
                                }
                                rule.until = until;
                            }
                            event.rrule = rule;
                        }
                        events.push(event);
                    });
                }
                resolve(events);
            }, (e: any) => {
                // Utils.presentToastWarning('获取事件列表失败');
                reject(null);
            });
        });
    }

    /**
     * 删除日历事件
     * @param events 
     */
    public deleteEventByIds(events: Array<string>): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.deleteEventById(events).then((r: boolean) => {
                resolve(r);
            }, (e: boolean) => {
                reject(e);
            }).catch((c: any) => {
                reject(false);
            });
        });
    }

    public deleteEventById(ids: Array<string>): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (ids != undefined && ids.length > 0) {
                Calendar.deleteEventById(ids[0]).then((r: any) => {
                    ids.splice(0, 1);
                    if (ids.length > 0) {
                        this.deleteEventById(ids).then((r1: boolean) => {
                            resolve(r1);
                        }, (e1: boolean) => {
                            resolve(e1);
                        }).catch((c1: any) => {
                            resolve(false);
                        });
                    } else {
                        resolve(true);
                    }
                }, (e: any) => {
                    resolve(e);
                }).catch((c: any) => {
                    console.log(3 + ',异常提示：' + JSON.stringify(c));
                    reject(false);
                });
            }
        });
    }
}

export interface CalendarAndroid {
    id: string,
    displayname: string,//关联邮箱名称
    isPrimary: boolean,
    name?: string
}

export interface EventAndroid {
    id: string,
    title: string
    location: string,
    message: string,
    startDate: string,
    endDate: string,
    allday: boolean
}

export interface CalendarIOS {
    id: string,
    name: string,
    type: string //Exchange
}

export interface EventIOS {
    calendar: string,
    id: string,
    title: string
    location: string,
    message: string,
    startDate: string,
    endDate: string,
    lastModifiedDate: string,
    rrule?: ruleIOS
}

export interface ruleIOS {
    freq: string,
    interval: number,
    calendar: string
    until?: untilIOS,
}

export interface untilIOS {
    count: number
}

export interface EventOption {
    calendarName: string,
    calendarId: string,
    title: string,
    eventLocation: string,
    notes: string,
    startDate: Date,
    endDate: Date,

    recurrence?: string,
    recurrenceEndDate?: Date,
    recurrenceInterval?: number
}

export enum EnumRecurrence {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly'
}