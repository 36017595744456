import BaseModel from '@/services/base/model/BaseModel';
import Tag from './Tag';

export default class TagLink extends BaseModel {
    tagId: string;
    tagName: string;
    tagColor: string;
    groupId: string;
    busId: string;
    isDeletedGroup: boolean=false;

    constructor(tag: Tag, busId: string) {
        super();
        this.tagId = tag.id;
        this.tagName = tag.name;
        this.tagColor = tag.color;
        this.groupId = tag.groupId;
        this.busId = busId;
        this.id = this.generateId("task", "taglink");
    }
}