import { PermissionApi } from '@/api/permission/PermissionApi';
import Setting from '@/rest/Setting';
import SystemUtils from '@/utils/SystemUtils';
import BaseService, { AllResponse } from '@/services/base/BaseService';
import Permission from '@/models/permission/Permission';
/**
 * 项目、任务 权限模块
 */
export class PermissionService extends BaseService {
    private api = new PermissionApi();
    /**
    * 根据企业Id,获取用户的企业权限
    * @param enterpriseId 企业Id
    */
    public getByEnterpriseIdAndUser(): Promise<AllResponse<Permission>> {
        const enterpriseId = Setting.currentEnterprise.id;
        const userId = SystemUtils.loginUser.id;
        return new Promise((resolve, reject) => {
            this.api.getByEnterpriseIdAndUser(enterpriseId, userId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    /**
    * 根据项目Id,获取用户的项目权限
    * @param projectId userId 
    */
    public getUserPermissionByProject(projectId: string, userId?: string): Promise<AllResponse<Permission>> {
        userId = userId ? userId : SystemUtils.loginUser.id;
        return new Promise((resolve, reject) => {
            this.api.getUserPermissionByProject({ projectId: projectId, userId: userId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 获取独立任务的权限
     * @param taskId 
     */
    public getIndependentTaskPermission(taskId: string,userId?: string): Promise<AllResponse<Permission>> {
        return new Promise((resolve, reject) => {
            this.api.getTaskPermissionByTaskIdApp({ taskId: taskId,userId:userId}).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
     * 根据项目Id，获取用户在此项目内，任务的操作权限
     * @param projectId 
     * @param userId 
     */
    public getTaskPermissionByProject(projectId:string,userId?:string): Promise<AllResponse<Permission>> {
        userId = userId ? userId : SystemUtils.loginUser.id;
        return new Promise((resolve, reject) => {
            this.api.getTaskPermissionByProject({ projectId: projectId,userId:userId}).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
    * 判断权限代码是否在当前权限集合
    * @param permissionData:拥有的权限集合 code：权限代码 
    */
    public isHasPermissionByCode(permissionData: Array<Permission>, code: string): boolean {
        //判断是否拥有权限
        let putInfo = permissionData.findIndex((item) => item.id == code);
        if (putInfo < 0) {
            return false;
        } else {
            return true;
        }
    }

    
    /**
    * 根据权限组id判断是否包含其权限组下任意权限
    * @param permissionData:拥有的权限集合 code：权限代码 
    */
   public isHasGroupPermissionByCode(permissionData: Array<Permission>, permissionGroupId: string): boolean {
    //判断是否拥有权限
    let putInfo = permissionData.findIndex((item) => item.permissionGroupId == permissionGroupId);
    if (putInfo < 0) {
        return false;
    } else {
        return true;
    }
}
}
