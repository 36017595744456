
import { IonContent, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChangeTaskShowPullDown",
  components: { IonContent },
  props: {
    searchId: Number,
  },
  data() {
    const showList = [
      { id: 1, name: "列表视图" },
      { id: 2, name: "阶段视图" },
    ];
    return {
      showList: showList,
      searchIdValue: 1,
    };
  },
  created() {
    if (this.searchId != null) {
      this.searchIdValue = this.searchId;
    } else {
      this.searchIdValue = 1;
    }
  },
  mounted() {},
  methods: {
    getSearchName(e: any) {
      this.searchIdValue = e.detail.value;
      popoverController.dismiss({ selectId: this.searchIdValue });
    },
  },
});
