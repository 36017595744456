
  import { defineComponent, Ref, ref, onUnmounted } from "vue";
  import { IonProgressBar, modalController } from "@ionic/vue";
  import ProjectCss from "@/components/ListCard/ProjectCss.vue";
  import Header from "@/components/Header.vue";
  import SearchBar from "@/components/SearchBar.vue";
  import { useRouter, useRoute } from "vue-router";
  import ConditionPop from "@/components/popover/ConditionPop.vue";
  import Utils from "@/utils/Utils";
  import CardLoading from "@/components/ListCard/CardLoading.vue";
  import OKRDetailsMenuVue from "./OKRDetailsMenu.vue";
  import AddKeyResult from "@/views/OKR/modals/AddKeyResult.vue";
  import KeyResultList from "@/views/OKR/keyResult/KeyResultList.vue";
  import { ObjectiveService } from "@/services/okr/ObjectiveService";
  import TaskList from "@/views/OKR/menu/editTarget/TaskList.vue";
  import SelectTaskList from "../../../views/calendar/component/SelectTask.vue";
  import { OkrTaskListInfo } from "./OkrTaskListInfo";
  import SystemUtils from "@/utils/SystemUtils";
  import Bus from "@/utils/bus";
  import { json } from "gantt";
  import NoDate from "@/components/NoData.vue";
  export default defineComponent({
    name: "OKRDetails",
    components: {
      ProjectCss,
      Header,
      SearchBar,
      ConditionPop,
      CardLoading,
      IonProgressBar,
      modalController,
      KeyResultList,
      TaskList,
      NoDate,
    },
    data() {
      const router = useRouter();
      const params = this.$route.query;
      const btnIconRight = ["icongengduo"];
      const btnSizeRight = ["34px"];
      return {
        router: router,
        params: params,
        indicators: ["关键结果", "关联任务"],
        selSeg: 0,
        btnSizeRight: btnSizeRight,
        btnIconRight: btnIconRight,
        //判断是否显示更多 默认 false
        isMore: false,
        detailsParams: {
          id: params.id,
        },

        levelName: "",
        parentOkRName: "",
        status: 0,
        objectiveName: "",
        progress: 0,
        score: "",
        cycleName: "",
        visibleRange: 0,
        categoryName: "",

        cycleList: new Array(),

        objectiveService: new ObjectiveService(),

        leaderList: new Array(0),
        takeUsers: new Array(0),
        parentOkRusers: new Array(0),
        $refs: {
          TaskList: TaskList,
          KeyResultList: KeyResultList,
        },

        okrLinkTaskParams: {
          list: new Array(0),
        },

        detailsInfo: params,
        //（控制评分按钮的展示）是否是上级目标的目标负责人
        isparentOkRUsers: false,
        //添加关联任务按钮是否显示(是否目标负责人)
        isShowKeyTask: false,
        //添加关键结果按钮是否显示（是否关键结果负责人）
        isShowKeyResult: false,
        noVisitPermission: false, //无访问权限
      };
    },

    watch: {
      $route(to, from) {
        if (from.path == to.path && to.path == "/OKR/details") {
          if (to.query.id != from.query.id) {
            this.params.id = to.query.id;
            this.detailsParams.id = this.params.id;
            this.isShowKeyTask = false;
            this.isparentOkRUsers = false;
            this.getDetails();
            //刷新tab列表
            let task: any = this.$refs.TaskList;

            if (task) {
              task.routeRefresh(this.params.id);
            }
            // let result: any = this.$refs.KeyResultList;
            // if (result) {
            //   result.updateThisTarget(this.detailsParams);
            // }
            this.scrollToTop();
          }
        } else if (from.path == "/OKR/details/editTarget") {
          console.log("进入刷新详情界面");

          //刷新tab列表
          let task: any = this.$refs.TaskList;

          if (task) {
            task.routeRefresh(this.params.id);
            task.onInit();
          }
          // let result: any = this.$refs.KeyResultList;

          // if (result) {
          //   result.updateThisTarget(this.detailsParams);
          // }
        } else if (to.path == "/OKR/details") {
          if (to.query.id != from.query.id) {
            this.params.id = to.query.id;
            this.detailsParams.id = this.params.id;
            this.getDetails();
            //刷新tab列表
            let task: any = this.$refs.TaskList;

            if (task) {
              task.routeRefresh(this.params.id);
            }
            // let result: any = this.$refs.KeyResultList;

            // if (result) {
            //   result.updateThisTarget(this.detailsParams);
            // }
          }
        }
      },
    },

    ionViewWillEnter() {
      this.getDetails();
      Bus.$emit("refresh_key_result_list");
    },

    created() {
      Bus.$on("receive_task_add_successful", (data: any) => {
        let list = new Array();
        this.$refs.TaskList.taskList.forEach((ele: any) => {
          list.push({
            id: ele.id,
            name: ele.text
          });
        });
        list.push(data);
        this.relevanceTask(list);
      });
    },

    setup() {
      onUnmounted(() => {
        Bus.$off("receive_task_add_successful");
      });
    },

    methods: {
      getDetails() {
        this.objectiveService.objectiveDetails(this.detailsParams).then(
          (r: any) => {
            if (r) {
              if (r) {
                this.leaderList.splice(0, this.leaderList.length);
                this.takeUsers.splice(0, this.takeUsers.length);
                //传递
                this.detailsInfo = r;

                this.levelName = r.levelName;
                this.status = r.status;
                this.objectiveName = r.name;
                this.progress = r.progress;
                this.score = r.score;
                this.cycleName = r.cycleName;
                this.visibleRange = r.visibleRange;
                this.categoryName = r.categoryName;
                this.cycleList = r.cycleList;
                this.parentOkRName = r.parentOkR.name;
                this.parentOkRusers = r.parentOkR.users;

                for (let index = 0; index < r.users.length; index++) {
                  const element = r.users[index];

                  if (element.type == 1) {
                    this.leaderList.push(element);
                  } else if (element.type == 2) {
                    this.takeUsers.push(element);
                  }
                }
                for (let index = 0; index < this.leaderList.length; index++) {
                  const element = this.leaderList[index];

                  if (element.userId == SystemUtils.loginUser.id) {
                    this.isShowKeyTask = true;
                  }
                }
                let result: any = this.$refs.KeyResultList;
                if (result) {
                  result.setThisTarget(this.detailsInfo, this.isShowKeyTask);
                }

                if (this.parentOkRusers)
                  for (
                    let index = 0; index < this.parentOkRusers.length; index++
                  ) {
                    const itemUsers = this.parentOkRusers[index];
                    if (itemUsers.userId == SystemUtils.loginUser.id)
                      this.isparentOkRUsers = true;
                  }
              }
            }
          },
          (e: any) => {
            if (e.httpCode == "404") {
              this.noVisitPermission = true;
            }
          }
        );
      },

      segmentChanged(ev: CustomEvent) {
        this.selSeg = ev.detail.value;
        if (this.selSeg == 0) {
          setTimeout(() => {
            let result: any = this.$refs.KeyResultList;
            if (result) {
              result.setThisTarget(this.detailsInfo, this.isShowKeyTask);
            }
          }, 50);
        }
      },
      async onRightClick() {
        const modal = await modalController.create({
          component: OKRDetailsMenuVue,
          cssClass: "okr-info-bottom-modal-class",
          backdropDismiss: true,
          componentProps: {
            isTargetPrincipal: this.isShowKeyTask,
            isResultprincipal: this.isShowKeyResult,
            isparentOkRUsers: this.isparentOkRUsers,
            targetProgress: this.detailsInfo.progress,
            targetId: this.detailsInfo.id,
            isDeleted: this.detailsInfo.isDeleted,
          },
        });
        await modal.present();
        const {
          data
        } = await modal.onDidDismiss();
        if (data && data.closeQuery) {
          switch (data.closeQuery) {
            case "scoreOKR":
              this.router.push({
                path: "/okr/toscore",
                query: {
                  id: this.params.id,
                  isOKRCharger: this.isShowKeyTask ? "1" : "0",
                  isParentOKRCharger: this.isparentOkRUsers ? "1" : "0",
                },
              });
              break;
            case "editOKR":
              this.router.push({
                name: "EditTarget",
                params: {
                  info: JSON.stringify(this.detailsInfo),
                  isTargetPrincipal: this.isShowKeyTask ? "1" : "0",
                  isResultprincipal: this.isShowKeyResult ? "1" : "0",
                  isOPrincipal: this.isShowKeyTask ? "1" : "0",
                },
              });
              break;
            case "overOKR":
              this.getDetails();
              // this.$emit("on-back");
              // this.router.go(-1);
              break;
            case "removeOKR":
              setTimeout(() => {
                this.router.go(-1);
              }, 200);
              break;
          }
        }
      },
      moreDetails() {
        this.isMore = !this.isMore;
      },
      // 添加关键结果
      async addKeyResult() {
        const modal = await modalController.create({
          component: AddKeyResult,
          cssClass: "notice-modal-class",
          componentProps: {
            id: this.detailsParams.id,
            isEdit: false,
            target: this.detailsInfo,
            isOPrincipal: this.isShowKeyTask,
          },
        });
        await modal.present();
        const {
          data
        } = await modal.onDidDismiss();
        if (data) {
          Bus.$emit("refresh_key_result_list");
        }
      },
      // 添加关联任务
      async addKeyTask() {
        let list = new Array();
        this.$refs.TaskList.taskList.forEach((ele: any) => {
          list.push({
            id: ele.id,
            name: ele.text
          });
        });
        const modal = await modalController.create({
          component: SelectTaskList,
          cssClass: "notice-modal-class",
          componentProps: {
            paramList: {
              list: list
            },
            isAdd: true,
          },
        });
        await modal.present();
        const {
          data
        } = await modal.onDidDismiss();
        if (data) {
          if (data.isAdd) {
            const task = {
              pos: 0,
            };
            this.router.push({
              name: "edit-task",
              params: {
                task: JSON.stringify(task),
                isRelevance: "1",
              },
            });
            return;
          }
          if (!data.selectedItem) return;
          this.relevanceTask(data.selectedItem);
        }
      },

      relevanceTask(data: any) {
        let taskList = new Array < OkrTaskListInfo > ();
        data.forEach((ele: any) => {
          let okrTaskList = new OkrTaskListInfo();
          okrTaskList.id = this.getId();
          okrTaskList.sourceId = this.params.id as string;
          okrTaskList.creator = ele.creator;
          okrTaskList.modifierTime = ele.modifyTime;
          okrTaskList.systemId = ele.systemId;
          okrTaskList.enterpriseId = ele.enterpriseId;
          okrTaskList.sourceType = 1;
          okrTaskList.taskId = ele.id;
          okrTaskList.remark = ele.remark;
          okrTaskList.isDeleted = ele.isDeleted;
          okrTaskList.createTime = ele.createTime;
          okrTaskList.modifierId = ele.modifierId;
          okrTaskList.creatorId = ele.creatorId;
          okrTaskList.modifier = ele.modifier;
          taskList.push(okrTaskList);
        });
        this.objectiveService
          .objectiveAddLinkTaskList(taskList, this.detailsInfo.id as string)
          .then(
            (r: any) => {
              if (r) {
                if (r) {
                  let task: any = this.$refs.TaskList;

                  if (task) {
                    task.resetPage();
                  }
                }
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
      },

      isIncludeCurrentUser(flag: boolean) {
        this.isShowKeyResult = flag;
      },

      getId() {
        const id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            const r = (Math.random() * 16) | 0,
              v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );

        return id;
      },
      //跳转评分页面
      openScorePage() {
        this.router.push({
          path: "/score/list",
          query: {
            oid: this.detailsParams.id, //目标id
            cycleId: "", //周期id
          },
        });
      },
      //更新目标进度
      progressUpdate() {
        this.objectiveService.progressInsert(this.detailsParams.id).then(
          (r: any) => {
            if (r) {
              Utils.presentToastSuccess("更新目标进度成功");
              this.getDetails();
            } else {
              Utils.presentToastWarning("更新目标进度失败");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      },

      scrollToTop() {
        let ionContent: any = document.querySelector("#contentView");
        if (ionContent) {
          ionContent.scrollToTop();
        }
      },
    },
  });
