
import { defineComponent } from "vue";
import { modalController, IonPage } from "@ionic/vue";
import { TaskLinkContentService } from "@/services/task/TaskLinkContentService";
import { TaskLinkContentType } from "@/models/task/Enum";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import TaskLinkTaskList from "../../task/menu/link/TaskList.vue";
import SearchBar from "@/components/SearchBar.vue";
import { TaskService } from "@/services/task/TaskService";

export default defineComponent({
  name: "SelectTaskList",
  props: {
    paramList: Object,
    isAdd: Boolean,
  },
  components: {
    HeaderModal,
    SearchBar,
    IonPage,
    TaskLinkTaskList,
  },
  data() {
    return {
      linkApi: new TaskLinkContentService(),
      parameters: {
        limit: 20,
        offset: 1,
        name: "",
        searchKey: "",
      },
      list: [],
      isRollback: true,
      isModal: true,
      selectedItem: {},
      selectedId: "",
      taskService: new TaskService(),
      selectList: new Array(),
    };
  },
  mounted() {
    console.log(this.isAdd);
    if (this.paramList) {
      this.selectList = this.paramList.list;
    }
    this.init("");
  },
  methods: {
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.init(data.searchKey);
    },
    closeModel(type: any) {
      if (type == 4) {
        //关闭页面
        modalController.dismiss({
          selectedItem: this.selectList,
        });
      }
      modalController.dismiss({});
    },
    init(keyword: any) {
      this.parameters.name = keyword;
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        this.selectList.push(item);
      } else {
        this.selectList = this.selectList.filter((e) => {
          return e.id != item.id;
        });
      }
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            // event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      let that = this;
      this.taskService.getTaskList(this.parameters).then((res: any) => {
        res.forEach((ele: any) => {
          let tempList = that.selectList.filter((sel: any) => {
            return sel.id == ele.id;
          });
          if (tempList.length == 0) {
            ele.checked = false;
          } else {
            ele.checked = true;
          }
        });
        callback(res);
      });
    },

    addTaskHandler() {
      modalController.dismiss({ isAdd: true });
    },
  },
});
