
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import { DashboardService } from "@/services/dashboardService";
export default defineComponent({
  name: "Freigabe",
  components: {},
  props: {
    taskItem: Object,
    projectId: String,
    projectPeriod: String,
    planId: Number,
  },
  data() {
    const router = useRouter();
    return {
      api: new DashboardService(),
      router: router,
      checkpointTimesDatas: new Array(0),
      showNoData: false, //展示暂无数据
    };
  },
  methods: {
    goFolder() {
      (this as any).router.push({
        path: "/dashboard/ProjectTabs",
        query: {
          folderId:
            "project@folder-" +
            this.projectId +
            "-" +
            (this as any).taskItem[0].list[0].name,
          planId: this.planId,
          projectId: this.projectId,
          currentTab: "Document"
        },
      });
    },
    getCheckpointTimes() {
      const params = {
        milestone: this.projectPeriod,
        name: (this as any).taskItem[0].list[0].name,
        projectId: this.projectId,
      };
      this.api.getCheckpointTimes(params).then((res: any) => {
        this.checkpointTimesDatas = res.data.data;
        this.showNoData = res.data.data == null || res.data.data.length <= 0;
      })
    },
    setMychart() {
      const elEchart = document.querySelector("#projectEchart");
      if (elEchart) {
        elEchart.removeAttribute("_echarts_instance_"); // 移除容器上的 _echarts_instance_ 属性,
        const ec = echarts as any;
        const myChart = ec.init(elEchart);
        const series = [
          {
            name: "",
            type: "gauge",
            z: 15,
            min: 0,
            max: 100,
            splitNumber: 10,
            radius: "100%",
            axisLine: {
              // 坐标轴线
              lineStyle: {
                // 属性lineStyle控制线条样式
                width: 5,
                // color: [[0.2, '#88fac1'], [0.8, '#19be6b'], [1, '#237f27']]
                color: [
                  [0.2, "#06ade3"],
                  [0.8, "#0f90d3"],
                  [1, "#0077c8"],
                ],
              },
            },
            axisTick: {
              // 坐标轴小标记
              length: 13, // 属性length控制线长
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: "auto",
              },
            },
            splitLine: {
              // 分隔线
              length: 18, // 属性length控制线长
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "auto",
              },
            },
            detail: {//仪表盘详情数据相关
              formatter: '{value}%',
              textStyle: {
                fontSize:20,
              }
            },
            axisLabel: {//大刻度标签。
              textStyle: {//数字刻度样式
                fontSize: 10,
              }
            },
            data: [{ value: 0, name: "" }],
          },
        ];
        if ((this as any).taskItem[0].list[0].soll != 0) {
          series[0].data[0].value =
            ((((this as any).taskItem[0].list[0].ist as any) /
              (this as any).taskItem[0].list[0].soll) as any).toFixed(2) * 100;
        } else {
          series[0].data[0].value = 0;
        }
        myChart.setOption({ series: series });
      }
      this.getCheckpointTimes();
    },
  },
  mounted() {
    this.setMychart();
  },
});
