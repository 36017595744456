
import { modalController, IonPage } from "@ionic/vue";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import ProjectDoc from "@/views/project/document/ProjectDoc.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "TaskFileList",
  props: {
    busId: String,
  },
  components: {
    HeaderModal,
    IonPage,
    ProjectDoc,
  },
  data() {
    return {
      projectFilePermission: {
        isHasFileUpload: true, //上传文件权限
        isHasFileDownload: true, //下载文件权限
        isHasFileDel: true, //删除文件权限
      },
    };
  },
  methods: {
    closeModel() {
      modalController.dismiss();
    },
  },
});
