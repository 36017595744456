<template>
  <div id="myChart" :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from "echarts";
require("echarts/theme/macarons");

export default {
  name:"OrgTree",
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "500px"
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted() {
    this.initChart();
    //是否需要自适应-加了防抖函数
    this.__resizeHandler = this.debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeUnmount() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
  debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }
  },
    initChart() {
      console.log("initChart");
      const elEchart = document.querySelector("#myChart");
      if (elEchart) {
        // elEchart.removeAttribute("_echarts_instance_"); // 移除容器上的 _echarts_instance_ 属性
      this.chart = echarts.init(elEchart, "macarons");
      this.setOptions(this.chartData);
      // console.log(this.chartData);
      // console.log(this.chart._chartsViews[0]);
      const nodes = this.chart._chartsViews[0]._data._graphicEls;
      // const nodes = this.chartData.children;
      let allNode = 0;
      for(let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        if (node === undefined) {
          continue
        }
        allNode++;
      }
      const height = window.innerHeight;
      const width = window.innerWidth;
      const currentHeight = 50 * allNode;
      const currentWidth =155* allNode;
      const newHeight = Math.max(currentHeight, height);
      const newWidth = Math.max(currentWidth, width);
      const tree_ele = elEchart;
      // tree_ele.style.height = newHeight + 'px';  //设置高度自适应
       tree_ele.style.width = newWidth + 'px';   //设置宽度自适应
       this.chart.resize();

      this.chart.on('click', this.chartData.clickCallback);   //节点点击事件
      
      }
    },
    setOptions(data) {
      this.chart.setOption({
        series: [
          {
            name: "组织视图",
            type: "tree",
            orient: "TB", //竖向或水平   TB代表竖向  LR代表水平
            top: '10%',
            //  left:"0",
            center: ["50%", '50%'],
            initialTreeDepth: 10,  //树图初始展开的层级（深度）
            expandAndCollapse: false,   //点击节点时不收起子节点，default: true
            // symbol:"roundRect",
            symbolSize: [155, 40],
            itemStyle: {
              color: 'transparent',
              borderWidth: 0,
            },
            lineStyle: {
              color: '#D5D5D5',
              width: 1,
              curveness: 1,
            },
            data: [data],
          }
        ]
      });
    },
    sidebarResizeHandler(e) {
      if (e.propertyName === "width") {
        this.__resizeHandler();
      }
    }
  }
};
</script>