
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Header from "@/components/Header.vue";
import { KrScoreLogService } from "@/services/okr/KrScoreLogService.ts";

export default defineComponent({
  name: "ScoreList",
  components: {
    CardLoading,
    Header,
  },
  props: {
    params: {}, //搜索条件
  },
  setup(props) {
    const { params } = toRefs(props);
    const router = useRouter();
    const route = useRoute();
    let islist = ref(true); //是否显示加载中的标识
    const oid = route.query.oid; //目标id
    const cycleId = route.query.cycleId; //周期id
    let searchParams = reactive({
      limit: 10,
      offset: 1,
      oid: oid,
      cycleId: cycleId,
      type: "",
    });
    const krScoreLogService = new KrScoreLogService();
    const currentUser: any = SystemUtils.loginUser;
    const currentUserId = currentUser.id;
    const scoreList = reactive({ data: new Array() });

    const initData = () => {
      resetPage();
    };
    //重新从第一页获取
    const resetPage = () => {
      searchParams.offset = 1;
      getListRequest((res: any) => {
        scoreList.data = res;
      });
    };
    const nextPage = (event: any) => {
      searchParams.offset++;
      getListRequest((result: any) => {
        scoreList.data = scoreList.data.concat(result);
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      krScoreLogService
        .getObjectiveList(searchParams)
        .then((res: any) => {
          islist.value = false;
          callback(res.data);
        })
        .catch(() => {
          islist.value = false;
        });
        /*
      let res = [
        { oname: "2020年12月评分", score: "5.0", id: "1",oId:"111",cycleId:"22e2",oscoreId:"a1" },
        { oname: "2020年11月评分", score: "4.5", id: "2" ,oId:"222",cycleId:"3333",oscoreId:"a2" },
        { oname: "2020年10月评分", score: "3.8", id: "3",oId:"333",cycleId:"4444",oscoreId:"a3"  },
        { oname: "2020年9月评分", score: "2.9", id: "4" ,oId:"444",cycleId:"5555",oscoreId:"a4" },
      ];
      callback(res);*/
    };
    //详情
    const detailHandler = (item: any) => {
      router.push({
        name: "score-detail",
        params: {
          oscoreId: item.id,
          title: item.oname,
          cycleName:item.cycleName,
          oscore:item.score,//目标得分
        },
      });
    };

    onMounted(() => {
      initData();
    });

    let updateCard = false;
    let isMore = false;
    return {
      islist,
      router,
      scoreList,
      searchParams,
      updateCard,
      isMore,
      currentUserId,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      detailHandler,
    };
  },
});
