
    import { defineComponent, Ref, ref } from "vue";
    import { IonPage, IonContent, menuController } from "@ionic/vue";
    import CardPrejectItem from "./component/CardPrejectItem.vue";
    import {DashboardService} from "@/services/dashboardService";
    import Header from "@/components/Header.vue";
    import { useRouter } from "vue-router";
    import SearchPanel from "./component/SearchPanel.vue";
    import CardLoading from "@/components/ListCard/CardLoading.vue";
    export default defineComponent({
        name: "CardProject",
        props: {
            isHome: String,
        },
        components: {
            CardLoading,
            IonPage,
            IonContent,
            CardPrejectItem,
            SearchPanel,
            Header,
        },
        data() {
            const btnIconRight = ["iconsousuo"];
            const btnSizeRight = ["34px"];
            const projectMenu: Ref<any> = ref(null);
            const router = useRouter();
            return {
                api: new DashboardService(),
                projectLists: new Array(0),
                btnIconRight: btnIconRight,
                btnSizeRight: btnSizeRight,
                projectMenu: projectMenu,
                router: router,
                isMore: true,
                isEmpty:false,
                parameters: {
                    pageNum: 1,
                    pageSize: 20,
                },
                searchParams: {
                    limit: 10,
                    offset: 1,
                    projectName: "",
                },
                showNoData: false, //展示暂无数据
                islist: true, //是否显示加载中的标识
            };
        },
        created() {
            this.resetPage();
        },
        methods: {
            //滚动到顶部
            scrollToTop() {
                const ionContent: any = document.querySelector("#CardProject");
                if (ionContent) {
                    ionContent.scrollToTop();
                }
            },
            doRefresh(event: any) {
                this.resetPage();
                setTimeout(() => {
                    event.target.complete();
                }, 2000);
                },
            onFiltClick() {
                menuController.open();
            },
            onSearch(obj: any) {
                this.parameters = Object.assign(this.parameters, obj);
                this.resetPage();
            },
            nextPage(event: any) {
                this.parameters.pageNum++;
                this.getListRequest((result: any) => {
                    this.projectLists = this.projectLists.concat(result);
                    event.target.complete();
                    if (result) {
                        if (result == null || result.length <= 0) {
                            event.target.disabled = true;
                        }
                    }
                });
            },
            getListRequest(callback: any) {
                this.api.getCardProject(this.parameters).then((res: any) => {
                    this.islist = false;
                    if (res.data.data && res.data.data.rows) {
                        callback(res.data.data.rows);
                    }
                });
            },
            resetPage() {
                this.parameters.pageNum = 1;
                this.getListRequest((res: any) => {
                    this.projectLists = res;
                    this.showNoData = res == null || res.length <= 0;
                    this.scrollToTop();
                });
            },
            itemClick(item: any){
                this.router.push({
                    path: "/dashboard/ProjectTabs",
                    query: {
                        projectId: item.id,
                        folderId: "project@folder-" + item.id,
                        currentTab: "mainInfo"
                    },
                });
            }
        },
    });
