
import User from "@/models/task/User";
import { ScheduleService } from "@/services/schedule/ScheduleService";
import TaskQueryCondition from "@/services/task/model/TaskQueryCondition";
import { TaskService } from "@/services/task/TaskService";
import SystemUtils from "@/utils/SystemUtils";
import { modalController, IonPage } from "@ionic/vue";
import moment from "moment";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import HeaderModal from "../../components/modal/components/HeaderModal.vue";

export default defineComponent({
  name: "Overview",
  components: { IonPage, HeaderModal },
  data() {
    const router = useRouter();
    const _user: User = SystemUtils.loginUser;
    return {
      taskService: new TaskService(),
      scheduleService: new ScheduleService(),
      currentUser: _user,
      router: router,
      task: {
        totalTodo: 0,
        todayToDo: 0,
        weekTodo: 0,
        overdueTodo: 0,
        newReceiveTodo: 0,
      },
      schedule: {
        todayCount: 0,
        weekCount: 0,
        newReceivedCount: 0,
        unAcceptCount: 0,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      //获取任务概览
      this.getTaskInfo();
      //获取日程概览
      this.getScheduleInfo();
    },
    getTaskInfo() {
      this.taskService.getTaskOverView().then((res: any) => {
        this.task = res;
      });
    },
    getScheduleInfo() {
      this.scheduleService.getScheduleOverView().then((res: any) => {
        this.schedule = res;
      });
    },
    goToTask(type: number) {
      //1 今日待办 2本周待办 3已逾期待办 4新收到待办 5全部待办
      const param: any = { workUsers: this.currentUser.id, isFinish: 0 };
      const today = moment().format("yyyy-MM-DD");
      let name = "全部待办";
      switch (type) {
        case 1:
          name = "今日待办";
          param.agent = 1;
          break;
        case 2:
          name = "本周待办";
          param.agent = 2;
          break;
        case 3:
          name = "已逾期待办";
          param.agent = 3;
          break;
        case 4:
          param.agent = 4;
          name = "新收到待办";
          break;
        case 5:
          param.agent = 5;
          name = "全部待办";
          break;
      }
      modalController.dismiss();
      this.router.push({
        name: "HomeSearchList",
        params: {
          segmentType: "0",
          name: name,
          searchParam: JSON.stringify(param),
          source: "homeTask",
        },
      });
    },
    goToSchedule(type: number) {
      //1 今日日程 2 本周日程 3 新收到 4 待反馈
      const param: any = { joinUsers: this.currentUser.id };
      const today = moment().format("yyyy-MM-DD");
      const _cweek = moment().weekday();
      switch (type) {
        case 1:
          param.agent = 1;
          break;
        case 2:
          param.agent = 2;
          break;
        case 3:
          param.agent = 4;
          break;
        case 4:
          param.agent = 3;
          break;
      }
      modalController.dismiss();
      this.router.push({
        name: "HomeSearchList",
        params: {
          segmentType: "0",
          searchParam: JSON.stringify(param),
          source: "homeSchedule",
        },
      });
    },
    closeModal() {
      modalController.dismiss({});
    },
  },
});
