/**
 * scheduleFeedback 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class ScheduleFeedbackApi extends TaskBaseService {
    constructor() {
        super("scheduleFeedback");
    }

     //更新日程信息--完成，反馈
    public updateFieldsById(params: any): Promise<any> {
        const path = "updateFieldsById";
        return this.request(path,this.rest.METHOD_PUT, params);       
    }
}