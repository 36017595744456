
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import EditSearchCondition from '../modal/EditSearchCondition.vue'
import { defineComponent } from "vue";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "SearchConditionManageModal",
  props: {
    searchNameArr:Array,
    systemIdArr:Array,
    queryType:String
  },
  components: {
    HeaderModal,
    SearchBar,
  },
  data() {
    return {
      searchPanelService:new SearchPanelService(),
      newList:new Array(0),
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: this.queryType? this.queryType:"task",
      },
    };
  },
  created() {
     this.getQueryList();
  },
  methods: {
    searchHandler(data: any) {
    if (Utils.isInCludeEmoji(data.searchKey)) {
      Utils.presentToastWarning("搜索内容不可以包含表情");
      return;
    }
     this.searchListParams.queryName= data.searchKey;
     this.getQueryList();
    },
    closeModel(type:any) {
        modalController.dismiss({queryList:this.newList});
    },
    // 编辑 
     async edit(item: any,index: number) {
      const modal = await modalController.create({
        component: EditSearchCondition,
        cssClass: "notice-modal-class",
        componentProps: {
          queryObj:item,
          systemIdArr:this.systemIdArr
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if(data&&data.isSussess){
        if(data.isDeleted){
          this.newList.splice(index,1)
        }else{
           item=Object.assign(item,data.newModle);
        }
      }
    },
    //请求查询条件列表
    getQueryList() {
      this.searchPanelService.getAppQueryByUserId(this.searchListParams).then(
        (res: any) => {
          if (res) {
            this.newList = res;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
  },
});
