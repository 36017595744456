

import { FeedbackApi } from '@/api/feedback/FeedbackApi';
import BaseService from '../base/BaseService';
/**
 * 项目，任务模块：任务分组
 */
export class FeedbackService extends BaseService {

    private api = new FeedbackApi();
   
     /**
     * 任务执行情况列表
     * @param param 
     */
    public getFeedbackList(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.getFeedbackList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //新建执行情况
    public addFeedback(parms: any): any {
        return new Promise((resolve, reject) => {
            this.api.addFeedback(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //新建执行情况
    public getFeedback(parms: any): any {
        return new Promise((resolve, reject) => {
            this.api.getFeedback(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


}