/**
 * ProjectPage 项目详情-概览 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectStatusApi extends TaskBaseService {

    constructor() {
        super("projectStatuss");
    }
    
    public getByProjectStatusId(params: any): Promise<any> {
        const path = 'getByProjectId';
        
        return this.request(path,this.rest.METHOD_GET, params);       
     }
     //新增状态
    public addProjectStage(params: any): Promise<any> {
        const path = '';
        return this.request(path,this.rest.METHOD_POST, params);       
     }
     //删除状态 
     public deleteProjectStatusById(params: any): Promise<any> {
        const path = "deleteProjectStatusById?projectId=" + params.projectId + "&&id=" + params.id ;
        return this.request(path,this.rest.METHOD_PUT, null);       
     }
     
}