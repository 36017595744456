
import { modalController, IonPage } from "@ionic/vue";
import { ProjectService } from "@/services/project/projectService";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SelectVisibleProject",
  props: {
    source: Number, // 0：新增任务AddTask; 1:新增微议题MicroIssuesAdd；2：独立任务转项目任务时选择项目
    paramProject: Object,
  },
  components: {
    HeaderModal,
    IonPage,
    SearchBar,
  },
  data() {
    return {
      selectedItem: {},
      selectedId: "",
      api: new ProjectService(),
      list: [],
      parameters: {
        isDeleted: 0,
        userId: "",
        projectName: "",
        createTimeOrder: "DESC",
        statusLightOrder: "DESC",
        orderType: "createTime",
        offset: 1,
        limit: 15,
      },
    };
  },
  mounted() {
    if (this.paramProject) {
      this.selectedItem = this.paramProject;
      this.selectedId = this.paramProject.id;
    }
    // let ps = {
    //   awakeDate: "",
    //   beginTime: "",
    //   belonger: "耿乾锋",
    //   belongerId: "e14dbf58-9978-4ed4-8cf1-8f770fe328f8",
    //   busId: "",
    //   busNo: "",
    //   busTable: "",
    //   busTag: "",
    //   chatGroupId:
    //     "msg@chatgroup(projects@task(ef415acf-91ee-40de-b69b-b3fe27efa7e8))",
    //   completeQuantity: 2,
    //   completionRate: 100,
    //   createTime: "2021-03-18 14:33:10",
    //   creator: "耿乾锋",
    //   creatorId: "e14dbf58-9978-4ed4-8cf1-8f770fe328f8",
    //   currentStage: "",
    //   dataState: -1,
    //   defectTypes: "",
    //   deleteTime: "",
    //   deleteUser: "",
    //   deleteUserId: "",
    //   deliveryFolderId: "",
    //   endTime: "",
    //   enterpriseId: "",
    //   finishTime: "",
    //   finishUser: "",
    //   finishUserId: "",
    //   folderId: "doc@folder(3be634b7e7cd4139a48b60d9cda2bc6e)",
    //   id: "projects@task(ef415acf-91ee-40de-b69b-b3fe27efa7e8)",
    //   incompleteQuantity: 0,
    //   isDeleted: false,
    //   isDeliveryTemplate: "",
    //   isFinished: false,
    //   isPublic: "",
    //   isStart: false,
    //   isTemplate: "",
    //   isUserDelete: "",
    //   isUserEdit: "",
    //   isWorkflow: "",
    //   modifier: "",
    //   modifierId: "",
    //   modifyTime: "",
    //   name: "geng项目项目项目项目项目项目项目项目项目项目项目项目项目项目项目项目项目项目项目",
    //   nearfutureQuantity: 0,
    //   no: "",
    //   noClaimeQuantity: 0,
    //   organizationId: "",
    //   organizationName: "",
    //   organizationNameCn: "",
    //   organizationNameDe: "",
    //   organizationNameEn: "",
    //   overdueFinshQuantity: 0,
    //   overdueQuantity: 0,
    //   pictureUrl: "",
    //   planCanEdit: "",
    //   planId: "",
    //   planStatus: "",
    //   planVersion: "",
    //   projectData: [],
    //   projectDuration: "",
    //   projectGroupId: "",
    //   projectRelationStatus: 0,
    //   projectStatus: "",
    //   projectTeamId: "",
    //   projectTypeId: "",
    //   refreshTime: "2021-04-26 14:37:38",
    //   remark: "",
    //   severity: "",
    //   statusChangeRemark: "",
    //   statusChangeTime: "",
    //   statusChangeUser: "",
    //   statusLight: 1,
    //   statusName: "正常",
    //   synopsis: "",
    //   systemId: "task",
    //   systemName: "任务中心",
    //   tagGroupId: "",
    //   taskNoPrefix: "",
    //   taskQuantity: 2,
    //   templateId: "",
    //   todayQuantity: 0,
    //   totalWorkingHour: "",
    //   vehicleType: "",
    //   willexpireQuantity: 0,
    //   workingHoursRate: "",
    // };

    // this.list.push(ps);

    const currentUser = SystemUtils.loginUser;
    this.parameters.userId = currentUser.id;
    this.getListRequest((res: any) => {
      // res.unshift(ps);
      this.list = res;
    });
  },
  methods: {
    removeSelectItem() {
      this.selectedItem = {};
      this.selectedId = "";
    },
    isChecked(id: any) {
      //   this.getListRequest((res: any) => {
      //   // res.unshift(ps);
      //   this.list = res;
      // });

      if (id == this.selectedId) {
        console.log(id + "===" + this.selectedId);
        return true;
      } else {
        return false;
      }
    },
    checkHandler(item: any) {
      if (this.selectedId != item.id) {
        this.selectedItem = item;
        this.selectedId = item.id;
      } else {
        this.removeSelectItem();
      }
    },
    searchHandler(data: any) {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.parameters.projectName = data.searchKey;
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
    },
    closeModel(type: any) {
      if (type == 4) {
        if (this.source == 0) {
          if (Object.keys(this.selectedItem).length == 0) {
            Utils.presentToast("请选择一个项目");
            return;
          }
          Utils.confirmDialog(
            "选择项目",
            "您确定要更换项目吗？执行人、参与人、标签等都会清空",
            () => {
              modalController.dismiss({ selectedProject: this.selectedItem });
            }
          );
        } else {
          modalController.dismiss({ selectedProject: this.selectedItem });
        }
      } else {
        modalController.dismiss({});
      }
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.list = this.list.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.list = res;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      if (this.source == 0 || this.source == 2) {
        this.api
          .getProjectListWithCreatePermission(this.parameters)
          .then((res: any) => {
            callback(res.data);
          });
      } else if (this.source == 1) {
        this.api.getProjectListByMember(this.parameters).then((res) => {
          callback(res.data);
        });
      }
    },
  },
});
