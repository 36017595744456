import { getRequest, postRequest, deleteRequest, putRequest, uploadFileRequest, getRequestCustom } from '@/rest/HttpRequest';
import { httpPost, httpGet, httpPut, httpDelete, httpUploadFile, httpDownloadFile, httpGetCustom } from '@/rest/IonicHttp';
import { isPlatform } from "@ionic/vue";
import { Network } from '@ionic-native/network';
import Utils from '@/utils/Utils';
export class Rest {
    public readonly METHOD_POST: string = 'POST';
    public readonly METHOD_GET: string = 'GET';
    public readonly METHOD_DELETE: string = 'DELETE';
    public readonly METHOD_PUT: string = 'PUT';
    public readonly METHOD_UPLOAD: string = 'UPLOAD';
    public readonly METHOD_DOWNLOAD: string = 'DOWNLOAD';
    public readonly ERROR_TEXT: string = '服务有事出去一会儿.马上就回来了';

    public request(
        url: string,
        method: string,
        parameters: object,
        files?: any, //用于文件上传:本地文件地址（支持数组['file://file.txt']）或者下载文件的本地地址（全路径）
        names?: any  //用于文件上传:文件地址对应的文件标识（支持数组['file']）
    ): Promise<any> {
        //无网络连接
        if (Network.type && Network.type.toLowerCase() === "none") {
            return new Promise((resolve, reject) => {
                const errorModel = { message: "网络连接失败，请检查网络！", status: 4000 };
                Utils.presentToastWarning("网络连接失败，请检查网络");
                reject(errorModel)
            });
        }
        if (isPlatform('capacitor')) {
            if (method == this.METHOD_GET) {
                return httpGet(url, parameters);
            } else if (method == this.METHOD_POST) {
                return httpPost(url, parameters);
            } else if (method == this.METHOD_DELETE) {
                return httpDelete(url, parameters);
            } else if (method == this.METHOD_PUT) {
                return httpPut(url, parameters);
            } else if (method == this.METHOD_UPLOAD) {
                return httpUploadFile(url, files, names, parameters);
            } else if (method == this.METHOD_DOWNLOAD) {
                return httpDownloadFile(url, files, parameters);
            }
        }
        if (method == this.METHOD_GET) {
            return getRequest(url, parameters);
        } else if (method == this.METHOD_POST) {
            if (parameters != undefined && parameters != null && parameters.hasOwnProperty('file')) {
                return uploadFileRequest(url, parameters);
            } else {
                return postRequest(url, parameters);
            }
        } else if (method == this.METHOD_DELETE) {
            return deleteRequest(url, parameters);
        } else if (method == this.METHOD_PUT) {
            return putRequest(url, parameters);
        }
        return new Promise((resolve, reject) => {
            reject("请求地址不存在");
        });
    }

    public requestCustom(url: string, method: string, parameters: any, headers: any): any {
        //无网络连接
        if (Network.type && Network.type.toLowerCase() === "none") {
            return new Promise((resolve, reject) => {
                const errorModel = { message: "网络连接失败，请检查网络！", status: 4000 };
                Utils.presentToastWarning("网络连接失败，请检查网络");
                reject(errorModel)
            });
        }
        if (isPlatform('capacitor')) {
            if (method == this.METHOD_GET) {
                return httpGetCustom(url, parameters, headers);
            }
        } else {
            if (method == this.METHOD_GET) {
                return getRequestCustom(url, parameters, headers);
            }
        }
    }
}