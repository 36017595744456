import BaseModel from "@/services/base/model/BaseModel";
import BaseRsp from "../base/BaseRsp";

export class KeyResultsRsp extends BaseRsp {
    krs?: Array<KeyResult> = this.data;
}

export class KeyResultRsp extends BaseRsp{
    kr?: KeyResult = this.data;
}

export default class KeyResult extends BaseModel {
    rootOid?: string;           //    // "1",
    pathOid?: string;           //    // "1.46b22384-6149-11eb-9332-d00d680e69c0",
    name?: string;              //    // "test1",
    userId?: string;            //    // "67751",
    userName?: string;          //    // "钱培庆",
    partNameCn?: string;        //    // "",
    partNameEn?: string;        //    // "",
    startDate?: string;         //    // "2021-01-27T16:00:00.000+0000",
    endDate?: string;           //    // "2021-01-29T16:00:00.000+0000",
    priority?: number;          //    // 3,
    weight: number = 0;            //    // 25,
    isCustomUnit: number =  0;  //    // 0,
    unit?: string;              //    // "完成/未完成",
    unitStartValue?: string;    //    // "未完成",
    unitEndValue?: string;      //    // "完成",
    unitCurrentValue?: string;  //    // "",
    score?: number;             //    // 0,
    progress?: number;          //    // 20,
    measurements?: string;      //  完成衡量标准  // "标准",
    linkOid?: string;           //    // "",
    linkTasks: Array<any> = [];     //    // [],
    progressLogs: Array<any> = [];  //    // [],
    oid?: string;               //    // "1",
    status: number = 0;

    constructor(){
        super();
        this.id = this.generateIdNoSys();
    }

}