
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
} from "vue";
import MyCalendar from "@/views/calendar/my/MyCalendar.vue";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import { log } from "util";

export default defineComponent({
  name: "MyHomeSchedule",
  components: {
    MyCalendar,
  },
  props: {
    refresh: Boolean,
  },
  watch: {
    refresh() {
      this.refreshMyPage = this.refresh;
    },
  },
  emits: ["onselectdate"],
  setup(props, { emit }) {
    const { refresh } = toRefs(props);
    let searchParams = reactive({
      limit: 10,
      offset: 1,
      scheduleName: "",
      calendarId: "",
      keyName: "",
      creatorId: "",
      joinUsers: "",
      startTimeStart: "",
      startTimeEnd: "",
      endTimeStart: "",
      endTimeEnd: "",
      priority: "",
      endDate: "",
      userIds: "",
    });
    let refreshMyPage = ref(false); //刷新我的日历列表
    let searchCondition = ref(0);
    const hasPage = ref(true);
    let selectDate = ref("");
    const selectDateHandler = (param: any) => {
      selectDate.value = param;
      emit("onselectdate", selectDate.value);
    };

    onMounted(() => {
      Bus.$on("receive_message_schedule", (data: Message) => {
        if (
          data.content?.includes("创建") ||
          data.content?.includes("修改") ||
          data.content?.includes("删除")
        ) {
          refreshMyPage.value = true;
        }
      });
    });
    onUnmounted(() => {
      Bus.$off("receive_message_schedule");
    });

    return {
      searchParams,
      refreshMyPage,
      searchCondition,
      hasPage,
      selectDate,
      selectDateHandler,
    };
  },
});
