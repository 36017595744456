import { ProjectApi } from '@/api/project/ProjectApi';
import { ProjectGroupsApi } from '@/api/project/ProjectGroupsApi';
import { ProjectTypesApi } from '@/api/project/ProjectTypesApi';
import { TaskGroupApi } from '@/api/task/TaskGroupApi'
import { ProjectUserstarsApi } from '@/api/project/ProjectUserstarsApi'
import { ProjectMembersApi } from '@/api/project/ProjectMembers'
import { ProjectGroupToProjectApi } from '@/api/project/ProjectGroupToProjectApi'
import { ProjectRolesApi } from '@/api/project/ProjectRolesApi'
import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';
import BaseRsp from '@/models/base/BaseRsp';
import UserInfo from '@/services/selectexecutor/UserInfo';
import moment from "moment";
import Bus from "@/utils/bus";

/**
 * 项目列表模块
 */
export class ProjectService extends BaseService {

    // constructor(
    //     private projectApi: ProjectApi,
    //     private projectDocApi: ProjectDocApi
    // ) { }

    private static projectApi = new ProjectApi();
    //项目分组
    private static projectGroupsApi = new ProjectGroupsApi();
    //项目类型
    private static projectTypesApi = new ProjectTypesApi();
    //任务分组 TaskGroupApi
    private static taskGroupApi = new TaskGroupApi();
    // 项目收藏
    private static projectUserstarsApi = new ProjectUserstarsApi();
    //项目成员
    private static projectMembersApi = new ProjectMembersApi();
    //项目分组-项目对应关系api
    private static projectGroupToProjectApi = new ProjectGroupToProjectApi();
    //项目项目全选
    private static projectRolesApi = new ProjectRolesApi();


    public getProjectListByMember({ projectName = "", offset = 1, limit = 10 }): Promise<BaseRsp> {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getProjectListByMember({
                projectName,
                offset,
                limit,
            }).then(async (res: BaseRsp) => {
                resolve(res);
            }).catch((err: BaseRsp) => {
                reject(err);
            });
        })
    }


    //获取项目列表
    public getProjectList({
        isDeleted = 0,
        userId = SystemUtils.loginUser.id,
        projectName = "",
        createTimeCB = "",
        createTimeCE = "",
        endTimeCB = "",
        endTimeEE = "",
        projectLight = "",
        createTimeOrder = "DESC",
        statusLightOrder = "DESC",
        orderType = "createTime",
        systemId = "",
        createIds = "",
        partakeType = "",
        searchKey = "",
        offset = 1,
        limit = 30
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getProjectList({
                isDeleted,
                userId,
                projectName,
                createTimeCB,
                createTimeCE,
                endTimeCB,
                endTimeEE,
                projectLight,
                createTimeOrder,
                statusLightOrder,
                orderType,
                systemId,
                createIds,
                partakeType,
                searchKey,
                offset,
                limit,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //获取项目分组
    public getByUserId({
        userId = SystemUtils.loginUser.id
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectGroupsApi.getByUserId({
                userId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //获取项目分组一级节点
    public getAllNodeByUserId({
        userId = SystemUtils.loginUser.id
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectGroupsApi.getAllNodeByUserId({
                userId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //根据父Id获取下级项目分组
    public getChildNodeByParentId(parentId: any): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectGroupsApi.getChildNodeByParentId(parentId).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //获取项目类型
    public getBySystemId({
        systemId = "task"
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectTypesApi.getBySystemId({
                systemId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //新增项目
    public createProjectForData(parms: any): any {
        if (ProjectService.projectApi.service.resource) {
            const taskData: any = super.newModel(ProjectService.projectApi.service.resource, "task");
            parms = Object.assign(parms, taskData);
        }
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.createProjectForData(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //获取项目任务分组
    public getByProjectIdForMennu({
        projectId = ""
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.taskGroupApi.getByProjectIdForMennu({
                projectId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    // 项目收藏，取消收藏
    public updateForProjectList({
        userId = SystemUtils.loginUser.id,
        id = BaseService.newId("task", "projectuserstar"),
        projectId = "",
        isStart = 0,
        systemId = "task"
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectUserstarsApi.updateForProjectList({
                userId,
                id,
                projectId,
                isStart,
                systemId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //项目归档
    public setFinishById({
        projectId = "",
        isFinsh = 1,
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.setFinishById({
                projectId,
                isFinsh,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //移到回收站
    public deleteProjectDataById(id: any): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.deleteProjectDataById(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //回收站还原
    public recycleProject(id:any):any{
        return new Promise((resolve,reject)=>{
            ProjectService.projectApi.recycleProject(id).then(async(res:any)=>{
                resolve(res);
            }).catch((err: any)=>{
                reject(err);
            })
        })
    }
    //项目彻底删除
    public clearProject(id: any): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.clearProject(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新项目信息
    public updateFieldsById(parms: any): any {
        
        //时间的处理
        if (parms.beginTime) {
            parms.beginTime = moment(parms.beginTime).format("YYYY-MM-DD");
        }
        if (parms.endTime) {
            parms.endTime = moment(parms.endTime).format("YYYY-MM-DD");
        }
        if (parms.awakeDate) {
            parms.awakeDate = moment(parms.awakeDate).format("YYYY-MM-DD");
        }
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.updateFieldsById(parms).then(async (res: any) => {
                Bus.$emit("data_update_listener", {type:"project", data: res});
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //根据项目Id获取项目成员（用户不去重复）
    public getByProjectIdforDistinct({
        projectId = "",
        menberName = "",
        offset = 1,
        limit = 10
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectMembersApi.getMemberByProjectIdApp({
                projectId,
                menberName,
                offset,
                limit
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //根据项目Id获取项目成员（用户不去重复，人员信息聚合后）
    public getTeamMemberByProjectIdApp({
        projectId = "",
        menberName = "",
        offset = 1,
        limit = 10
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectMembersApi.getTeamMemberByProjectIdApp({
                projectId,
                menberName,
                offset,
                limit
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    
    //根据项目Id获取项目成员（用户去重复）
    public getPermissionUserByProjectIdApp({
        projectId = "",
        menberName = "",
        offset = 1,
        limit = 10
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectMembersApi.getPermissionUserByProjectIdApp({
                projectId,
                menberName,
                offset,
                limit
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //根据项目id获取项目详情
    public getInfoById(id: string): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getInfoById(id).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //根据项目id和当前用户id获取项目详情
    public getForInforSet({
        projectId = "",
        userId = SystemUtils.loginUser.id } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getForInforSet({
                projectId,
                userId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //根据用户Id、项目Id、用户项目分组Id获取项目与用户分组关系列表
    public updateProjectGroupToProjectByUser({
        projectId = "",
        userId = SystemUtils.loginUser.id,
        projectGroupId = "",
        systemId = "task" } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectGroupToProjectApi.updateProjectGroupToProjectByUser({
                projectId,
                userId,
                projectGroupId,
                systemId
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //根据项目Id获取项目成员-App（用户去重复）
    public getMemberByProjectIdApp({
        projectId = "",
        menberName = "",
        offset = 1,
        limit = 10 } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectMembersApi.getMemberByProjectIdApp({
                projectId,
                limit,
                menberName,
                offset,
            }).then(async (res: any) => {
                resolve(this.getUserList(res.data));
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    public getRolesByProjectId() {
        return new Promise((resolve, reject) => {
            ProjectService.projectRolesApi.getByProjectId().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //获取当前人可创建任务的项目列表
    public getProjectListWithCreatePermission({
        userId = SystemUtils.loginUser.id,
        projectName = "",
        offset = 1,
        limit = 30
    } = {}): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getProjectListWithCreatePermission({
                userId,
                projectName,
                offset,
                limit,
            }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


    getUserList(data: any) {
        
        const userList = new Array();
        
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const user = new UserInfo();
            user.id = element.id;
            user.name = element.name;
            user.pernr = element.pernr;
            user.photo = element.photo;
            user.userId = element.userId;
            user.department = element.partNameEn;
            userList.push(user);
        }
        return userList;
    }

    existProjectByUserId(projectId: string, userId: string): Promise<any>{
        return ProjectService.projectMembersApi.existProjectByUserId({projectId: projectId, userId: userId});
    }
    
    //获取我的已归档项目
    public getByFinishProjectList(params:any): any {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getByFinishProjectList(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //根据用户获取项目信息
    public getProjectListByUserByID(userId: string,projectId: any) {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getProjectListByUserByID({userId:userId,projectId:projectId}).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //  任务-项目列表视图
    public getProjectTask(params: any) {
        return new Promise((resolve, reject) => {
            ProjectService.projectApi.getProjectTask(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
}

export function getInstance() {
    return new ProjectService();
}