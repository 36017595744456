import TaskBaseService from '../base/TaskBaseService';

export class TaskLinkApi extends TaskBaseService {
    constructor() {
        super("taskLinks");
    }

    /**
     * 添加关联
     * @param data 
     */
    public insertList(data: any) {
        return this.request("insertList", this.rest.METHOD_POST, data);
    }

    /**
     * 取消关联
     * @param id 
     */
    public removeLink(id: string) {
        return this.request("clearById?id=" + id, this.rest.METHOD_DELETE);
    }

    /**
     * 根据sourceId和sourceType获取信息
     * @param sourceId 
     * @param sourceType 
     */
    public getByTaskId(taskId: string) {
        return this.request("getByTaskId", this.rest.METHOD_GET, {
            taskId: taskId
        });
    }
}