<template>
     <ion-toolbar >
        <ion-title>
          <ion-img
            class="titleImg"
            :src="src"
          ></ion-img
          >{{title}}</ion-title
        >
      </ion-toolbar>
</template>
<script>
export default {
    props:{
         src:{
            type:String,
            default:require('../../assets/images/about.png')
        },
        title:{
            type:String,
            default:'试制生产管理系统'
        }
    }
}
</script>
<style lang="scss" scoped>
    .titleImg {
  width: 34px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 11px;
}
ion-toolbar{
    --background: linear-gradient(#2768de 0%, #2b5399 100%);
   font-size: 34px;
font-family: Microsoft YaHei, Microsoft YaHei-Regular;
font-weight: bold;
text-align: center;
color: #ffffff;
}
ion-title{
}
</style>