
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import SubscribeApplyInfo from '@/models/calendar/SubscribeApplyInfo';
import Utils from "@/utils/Utils";
import DetailCalendar from "../detail/DetailCalendar.vue";
import AvatarList from "@/components/AvatarList.vue";

export default defineComponent({
  name: "SubscribeApproveCard",
  components: {AvatarList},
  props: {
    SubscribeApproveObj: Object,
  },

  data() {
    return {};
  },
  methods: {
    //同意
    agreeHandler(item: SubscribeApplyInfo) {
    //  item.status =1;
      this.$emit("on-agree",item);
    },
    //拒绝
    rejectHandler(item: SubscribeApplyInfo) {
     // item.status =2;
      this.$emit("on-reject", item);
    },
     subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
     async detailHandler(id: any) {
      const modal = await modalController.create({
        component: DetailCalendar,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          id: id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    },
     avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.userName, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
