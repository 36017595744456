<template>
    <!-- 甘特表格 -->
    <div ref="gantt"
        id="gantt"
        name='gantt'
        class="left-container"
        style="height: 100%;overflow: auto;">
    </div>
</template>

<script>
import { defineComponent } from "vue"; 
import { Gantt } from 'dhtmlx-gantt'; // 引入模块
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/locale/locale_cn'; // 本地化
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip.js';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker.js';

import { DashboardService } from "@/services/dashboardService";

const DAY_MINUTES = 1440; // 一天的分钟数
const GRID_WIDTH = 1; // 列表默认宽度
const BACK_COLOR = 'rgb(0, 112, 192)'; // 里程碑默认颜色
export default  defineComponent({
    name: 'bingo-gantt',
    data(){
        return{
            gantt: Gantt.getGanttInstance(),
            linksFormatter: null,
            api: new DashboardService(),
        }
    },
    emits: ['before-init', 'on-data-convert', 'after-load-data'],
    props: {
        type: {
            type: String,
            validator: (value) => {
                return ['milestone', 'task'].indexOf(value) >= 0;
            },
            default: 'task'
        },
        calendar: { // 按日历时间，还是工作时间
            type: Boolean,
            default: false
        },
        calendarGroup: {
            type: [Number,String],
            default: 0
        },
        planId: { // 计划主键
            type: Number,
            default: 0
        },
        tasks: {
            type: Object,
            default () {
                return {
                    data: [],
                    links: [],
                    resource: []
                }
            }
        },
        attentionTasks:{
            type: Array,
            default: []
        },
        moreMarks: {
            type: Boolean,
            default: false
        },
        milestones:{
            type: Object,
            default () {
                return {}
            }
        },
    },
    watch:{
        tasks: {
            deep: false,
            handler(tasks) {
                tasks.data.forEach(item => {
                    item.parent = String(item.parent);
                    item.duration = item.duration || 0;
                    item.type = item.taskType || 'task';
                    item.open = item.isOpen;
                    item.color = item.backColor;
                    if(this.type == 'task'){
                        item.start_date = item.startDate;
                        item.end_date = item.endDate;
                    }else if(this.type == 'milestone'){
                        item.start_date = item.startDate.replace(/\-/g, "/") + " 00:00:00";
                        item.end_date = item.endDate.replace(/\-/g, "/") + " 00:00:00"; 
                    }
                    item.resources = 'string' === typeof item.resources ? item.resources.split(',').map(parseFloat) : [];
                    this.$emit('on-data-convert', item);
                    // console.log(tasks.data,'watch task')
                });
                if (this.calendar) {
                    this.setCalendar(() => {
                        this.parseData(tasks);
                    });
                } else {
                    this.parseData(tasks);
                }
            }
        }
    },
    created(){
        let formatter = gantt.ext.formatters.durationFormatter({
            enter: 'day',
            store: gantt.config.duration_unit,
            format: 'auto',
            short: true
        });
        this.linksFormatter = gantt.ext.formatters.linkFormatter({durationFormatter: formatter});
        this.setGanttUtil();
        this.customTemplates(this.gantt);
    },
    mounted(){
        this.setTemplates();
        this.setConfigs();
        this.setServerList();
        this.setUnitView();
        this.registerEvents();
        this.$emit('before-init', this.gantt);
        this.gantt.init(this.$refs.gantt);
    },
    methods: {
        parseData (tasks) {
            this.gantt.clearAll();
            if (this.type == "task") {
                tasks.data.forEach(item=>{
                    this.updateTaskTiming(item);
                })
            }
            if(this.moreMarks){
                if(JSON.stringify(this.milestones) != "{}"){
                    this.addMilestoneMarks(this.milestones);
                }
            }
            this.gantt.parse(tasks)
        },
        updateTaskTiming(task) {
            task.start_date = this.gantt.getClosestWorkTime({ dir: "future", date: new Date(task.start_date), unit: task.unit, task: task });
            task.end_date = this.gantt.getClosestWorkTime({ dir: "future", date: new Date(task.end_date), unit: task.unit, task: task });
            task.duration = this.gantt.calculateDuration(task.start_date,task.end_date);
        },
        setConfigs(){
            let gantt = this.gantt;
            gantt.config.open_tree_initially = true;
            gantt.config.auto_scheduling = true;
            gantt.config.auto_scheduling_strict = true;
            gantt.config.auto_types = true;
            gantt.config.cascade_delete = true; // 级联删除
            gantt.config.correct_work_time = true;
            gantt.config.date_format = '%Y-%m-%d %H:%i:%s';
            gantt.config.details_on_create = false;
            gantt.config.details_on_dblclick = false;
            // 拖拽调整任务工期
            gantt.config.drag_resize = false;
            // 自动调整整体时间
            gantt.config.fit_tasks = true;
            // 底层按照分钟计算
            gantt.config.duration_unit = 'minute';
            gantt.config.grid_resizer_attribute = 'gridresizer';
            gantt.config.min_column_width = 50;
            gantt.config.open_split_tasks = true;
            // 拖拽
            gantt.config.order_branch = false;
            gantt.config.order_branch_free = false;
            gantt.config.row_height = 32;
            gantt.config.round_dnd_dates = false;
            gantt.config.redo = false;
            gantt.config.resource_store = 'resource';
            gantt.config.resource_property = 'resources';
            gantt.config.scale_height = 40;
            // 是否显示进度
            gantt.config.show_progress = false;
            // 是否显示连接线
            gantt.config.show_links = true;
            // 撤回
            gantt.config.undo = false;
            gantt.config.work_time = this.calendar;
            gantt.config.scroll_on_click = true
            if(this.type == 'task'){
                gantt.config.grid_width = GRID_WIDTH;
            }else{
                gantt.config.grid_width = 1;
            }
            // 时间刻度
            gantt.config.scales = [
                {
                    unit: 'year',
                    format: '%Y '
                },
                {
                    unit: 'month',
                    step: 1,
                    date: '%F'
                }
            ];
            
            // 自定义里程碑图形
            if ('milestone' === this.type) { // 块状图
                gantt.config.type_renderers[gantt.config.types.milestone] = (task, defaultRender) => {
                    let html = defaultRender(task);
                    let sop = gantt.sopDate ? gantt.sopDate instanceof Date ? gantt.sopDate : new Date(gantt.sopDate) : new Date();
                    let week = 'number' === typeof task.sopWeek ? task.sopWeek : this.dateCount(task.start_date, sop, 'week'); // 后端传值就赋值，否则需计算
                    let month = 'number' === typeof task.sopMonth ? task.sopMonth : this.dateCount(task.start_date, sop, 'month');
                    let backColor = task.backColor || BACK_COLOR;
                    let remark = task.remark ? `${task.remark}.` : '';
                    let borderColor = task.borderColor || backColor;
                    html.innerHTML = `<div class='milestone'>
                                    <p style='margin-bottom: -8px'>${task.shortName || task.text || '&nbsp'}</p>
                                    <div style='height: 29px'>
                                        <i class='gantt_task ivu-icon ${this.getIcon(task.graph || 'diamond')}' style='color: ${backColor}; font-size: 26px; -webkit-text-stroke: 1px ${borderColor}; text-align: center; padding: 1px;border-color:${borderColor}'></i>
                                        <div class='milestone_task' style='margin-top: -33px; color: white; font-size: 12px; transform: scale(0.9); position: relative;'>
                                            ${task.approvalAuthority || ' '}
                                        </div>
                                    </div>
                                    <p>${week}</p>
                                    <p>[${remark} ${month} Mon.]</p>
                                </div>`;
                    return html;
                };
            } else if ('task' === this.type) { // 甘特图
                gantt.config.type_renderers[gantt.config.types.milestone] = (task, defaultRender) => {
                    let html = defaultRender(task);
                    let week = gantt.date.getISOWeek(task.start_date);
                    let css = this.getIcon(task.graph || 'diamond');
                    let backColor =  task.backColor || (task.type === gantt.config.types.milestone ? 'rgb(54, 77, 110)' : 'rgb(189, 215, 238)');
                    let borderColor = task.borderColor || backColor;
                    let color = 'white';
                    if(backColor == '#FFFFFF' || backColor == '#ffffff' || backColor == 'rgb(255, 255, 255)'){
                        color = '#6e6e6e';
                    }
                    console.log(task,'milestone-task')
                    html.innerHTML = `<div class='task_milestone'>
                                    <i class='gantt_task ivu-icon ${css}' style='color: ${borderColor};-webkit-text-stroke: 1px ${borderColor}; text-align: center; padding: 1px;'></i>
                                    <div style='margin-top: -15px; color: ${color}; position: relative'>
                                        ${week}
                                    </div>
                                    <p>${task.shortName || task.text}</p>
                                </div>`;
                    return html;
                };
                gantt.config.type_renderers[gantt.config.types.task] = (task, defaultRender) => {
                        let html = defaultRender(task);
                        let backColor =  task.backColor || 'rgb(74, 151, 210)';
                        let borderColor = task.borderColor || 'rgb(0, 119, 200)';
                        html.style.backgroundColor = backColor;
                        html.style.border = `1px ${borderColor} solid`;
                        html.style.opacity = 1
                        return html;
                };
                gantt.config.type_renderers[gantt.config.types.project] = (task, defaultRender) => {
                    let milestoneTasks = this.getAllMileStoneChild(task.id);
                    let isSplited = gantt.isSplitTask(task)
                    let parentSize = gantt.getTaskPosition(task)
                    if (milestoneTasks.length) {
                        let html = defaultRender(task);
                        if (!task.$open) {
                            let innerStr = ''
                            milestoneTasks.forEach((item) => {
                                let size = gantt.getTaskPosition(item)
                                let week = gantt.date.getISOWeek(task.start_date);
                                let css = this.getIcon(item.graph || 'diamond');
                                let backColor =  item.backColor || (item.type === gantt.config.types.milestone ? 'rgb(54, 77, 110)' : 'rgb(189, 215, 238)');
                                let borderColor = item.borderColor || backColor;
                                let color = 'white';
                                if(backColor == '#FFFFFF' || backColor == '#ffffff' || backColor == 'rgb(255, 255, 255)'){
                                    color = '#6e6e6e';
                                }
                                if(item.taskType == 'milestone'){
                                    innerStr += `<div class='task_milestone' style="width:15px;position:absolute;left:${size.left - parentSize.left - 9}px;top:4px">
                                            <i class='gantt_task ivu-icon ${css}' style='color: ${backColor};-webkit-text-stroke: 1px ${borderColor};text-align: center; padding: 1px;'></i>
                                            <div style='margin-top: -15px; color: ${color}; position: relative'>
                                                ${week}
                                            </div>
                                            <p>${item.shortName || item.text}</p>
                                        </div>`;
                                }else{
                                    innerStr += `<div class='gantt_task_content 888' style="width:${size.width}px;border:1px solid ${borderColor};background-color:${backColor};position:absolute;left:${size.left - parentSize.left - 1}px;border-radius:2px;">
                                                    </div>`;
                                }
                            })
                            html.innerHTML = innerStr
                            html.style.backgroundColor = 'rgba(255,255,255,0)';
                            html.style.border = `1px transparent solid`;
                        }else{
                            let backColor =  task.backColor || 'rgb(74, 151, 210)';
                            let borderColor = task.borderColor || 'rgb(0, 119, 200)';
                            html.style.backgroundColor = backColor;
                            html.style.border = `1px ${borderColor} solid`
                            html.style.opacity = 1
                        }
                        return html;
                    } else {
                        let html = defaultRender(task);
                        let backColor =  task.backColor || 'rgb(74, 151, 210)';
                        let borderColor = task.borderColor || 'rgb(0, 119, 200)';
                        html.style.opacity = 1
                        if (!task.$open){
                            html.style.backgroundColor = 'rgba(255,255,255,0)';
                            html.style.borderColor = 'rgba(255,255,255,0)';
                        }else{
                            html.style.backgroundColor = backColor;
                            html.style.border = `1px ${borderColor} solid`
                        }
                        return html;
                    }
                }
            }
            // 自定义列
            if(this.type == 'task'){
                gantt.config.columns = [
                    {
                        name: 'text',
                        label: '名称',
                        width: 200,
                        required: true,
                        resize: true,
                        tree: true
                    },
                    {
                        name: 'shortName',
                        label: '简称',
                        width: 100,
                        resize: true
                    },
                    {
                        name: 'state',
                        label: '状态',
                        width: 80,
                        resize: true,
                        template: gantt.templates.state
                    },
                    {
                        name: 'duration',
                        label: '工期',
                        width: 100,
                        resize: true,
                        template: gantt.templates.duration,
                        align: 'right'
                    },
                    {
                        name: 'unit',
                        label: '单位',
                        width: 100,
                        template: gantt.templates.unit,
                        resize: true
                    },
                    {
                        name: 'start_date',
                        label: '计划开始日期',
                        width: 110,
                        resize: true
                    },
                    {
                        name: 'end_date',
                        label: '计划结束日期',
                        width: 110,
                        resize: true
                    },
                    {
                        name: 'predecessor',
                        label: '前置任务',
                        width: 100,
                        map_to: 'auto',
                        template: gantt.templates.predecessors,
                        resize: true
                    },
                    {
                        name: 'referenceMilestone',
                        label: '主里程碑参考点',
                        width: 120,
                        resize: true
                    },
                    {
                        name: 'differencePeriod',
                        label: '差异周期',
                        width: 90,
                        resize: true,
                        template (task) {
                            return 'number' === typeof task.differencePeriod ? `${task.differencePeriod >=0 ? '+' : '-'}${Math.abs(task.differencePeriod)}` : '';
                        },
                        align: 'right'
                    },
                    {
                        name: 'differenceUnit',
                        label: '差异单位',
                        width: 90,
                        template: (task) => {
                            return gantt.locale.labels[task.differenceUnit + 's'];
                        },
                        resize: true
                    },
                    {
                        name: 'attentionNode',
                        label: '关注节点',
                        min_width: 200,
                        width: 220,
                        template: (task) => {
                            let names = [];
                            if(task.attentionNode){
                                task.attentionNode.forEach(id => {
                                    if (id) {
                                        let name = '';
                                        this.attentionTasks.some(item => {
                                            if (item.taskId === id) {
                                                name = item.text;
                                                return true;
                                            }
                                        });
                                        if (!name) {
                                            gantt.eachTask(item => {
                                                if (item.taskId === id) {
                                                    // name = item.text;
                                                    name = this.composeNodePath(item);
                                                    return false;
                                                }
                                            })
                                        }
                                        if (name && name.length > 0) {
                                            names.push(name);
                                        }
                                    }
                                });
                            }
                            let attentionContent = '';
                            if (names && names.length > 0) {
                                attentionContent = names.toString();
                            }
                            return attentionContent;
                        },
                        resize: true
                    },
                    {
                        name: 'header',
                        label: '负责人',
                        min_width: 150,
                        width: 180,
                        template: (task) => {
                            let result = '';
                            let resourceName = [];
                            task.headers.forEach(header => {
                                if (header) {
                                    resourceName.push(header.headerName);
                                    result += '<span class="ivu-tag ivu-tag-checked department-label">' + header.headerName  + ','  + '</span>';
                                }
                            });
                            task.resourceName = resourceName.join(',');
                            return result;
                        },
                        resize: true
                    },
                    {
                        name: 'legend',
                        label: '图例',
                        width: 120,
                        editor: { type: 'legend', map_to: 'legend' },
                        resize: true,
                        template: gantt.templates.legend,
                        align: 'center'
                    },
                    {
                        name: 'sendState',
                        label: '下发状态',
                        width: 80,
                        template (task) {
                            return task.sendState === '1' ? '已下发' : '未下发';
                        },
                        resize: true
                    },
                    {
                        name: 'finished',
                        label: '完成状态',
                        width: 80,
                        template (task) {
                            return task.finished ? '已完成' : '未完成';
                        },
                        resize: true
                    },
                    {
                        name: 'finishTime',
                        label: '实际完成',
                        width: 150,
                        resize: true
                    },
                    {
                        name: 'noticeNode',
                        label: '提醒参考点',
                        width: 100,
                        resize: true
                    },
                    {
                        name: 'noticePeriod',
                        label: '提醒差异周期',
                        width: 90,
                        resize: true,
                        template (task) {
                            return 'number' === typeof task.noticePeriod ? `${task.noticePeriod >=0 ? '+' : '-'}${Math.abs(task.noticePeriod)}` : '';
                        },
                        align: 'right'
                    },
                    {
                        name: 'noticeUnit',
                        label: '提醒差异单位',
                        width: 90,
                        template: (task) => {
                            return gantt.locale.labels[task.noticeUnit + 's'];
                        },
                        resize: true
                    }
                ];
                gantt.config.task_height = 15;
            }else{
                gantt.config.columns = [];
            }
            // 添加WBS列
            gantt.config.columns.splice(0, 0, {
                name: 'wbs',
                label: 'WBS',
                width: 70,
                template: gantt.getWBSCode,
                resize: true
            })
        },
        //组装节点路径
        composeNodePath (task) {
            // let attentionName = task.$wbs + ' ' + task.text;
            let attentionName = task.text;
            if (task.parent != 0) {
                let curTask = task;
                while (curTask.parent != 0) {
                    curTask = this.gantt.getTask(curTask.parent);
                    let curPath = curTask.text + ' / ' + attentionName;
                    attentionName = curPath;
                }
            }
            return attentionName;
        },
        // 设置单位视图
        setUnitView() {
            let gantt = this.gantt;
            let zoomConfig = {
                levels: [
                    {
                        name: 'day',
                        scale_height: 50,
                        min_column_width: 20,
                        scales: [
                            {unit: 'month', step: 1, format: '%Y  %M'},
                            {unit: 'day', step: 1, format: '%d'}
                        ]
                    },
                    {
                        name: 'week',
                        scale_height: 50,
                        min_column_width: 25,
                        scales: [
                            {unit: 'month', format: '%Y %F'},
                            {
                                unit: 'week',
                                step: 1,
                                format: function (date) {
                                    let weekNum = gantt.date.date_to_str('%W')(date);
                                    return weekNum;
                                }
                            }
                        ]
                    },
                    {
                        name: 'month',
                        scale_height: 50,
                        min_column_width: 40,
                        scales: [
                            {unit: 'year', step: 1, format: '%Y '},
                            {unit: 'month', format: '%F'}
                        ]
                    },
                    {
                        name: 'quarter',
                        height: 50,
                        min_column_width: 90,
                        scales: [
                            {unit: 'month', step: 1, format: '%M'},
                            {
                                unit: 'quarter',
                                step: 1,
                                format: function (date) {
                                    var dateToStr = gantt.date.date_to_str("%M");
                                    var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                    return dateToStr(date) + " - " + dateToStr(endDate);
                                }
                            }
                        ]
                    },
                    {
                        name: 'year',
                        scale_height: 27,
                        min_column_width: 30,
                        scales: [
                            {unit: 'year', step: 1, format: '%Y '}
                        ]
                    }
                ]
            };
            gantt.ext.zoom.init(zoomConfig);
            gantt.ext.zoom.setLevel('month');
        },
        // 设置模板
        setTemplates() {
            let gantt = this.gantt;
            /* 任务条显示的文本 */
            gantt.templates.task_text = function (start, end, task) {
                return '';
            };

            gantt.templates.rightside_text = function(start, end, task){
                let name = ''
                if(!task.duration)name = task.shortName || task.text
                return name
            };

            /* tooltip显示内容 */
            if (this.tooltip) {
                gantt.templates.tooltip_text = (start, end, task) => {
                    return `<span class= 'tooltip'><b>${$t('planCenter.gantt.label.text')}：</b> ${task.shortName || task.text}<br/>
                        <b>${$t('planCenter.gantt.label.startDate')}：</b> ${gantt.templates.tooltip_date_format(start)}
                        <br/><b>${$t('planCenter.gantt.label.endDate')}：</b> ${gantt.templates.tooltip_date_format(end)}</span>`;
                };
            }

            /* 任务进度 */
            gantt.templates.progress_text = function (start, end, task) {
                let html = '<span style="text-align:left;">' +
                    Math.round(task.progress * 100) +
                    '% </span>';
                return html;
            };

            gantt.templates.grid_row_class = function(start, end, task) {
                let css = [];
                if (task.isEnable != undefined) {
                    task.readonly = !task.isEnable;
                    if (!task.isEnable) {
                        css.push('stop');
                    }
                }
                return css.join(' ');
            };

            gantt.templates.timeline_cell_class = (task, date) => {
                let css = [];
                if ('day' === this.viewUnit) { // 天视图
                    if (!gantt.isWorkTime(date, 'day')) {
                        css.push('weekend');
                    }
                }
                return css.join(' ');
            };

            /* 区分块状图/甘特图的样式 */
            gantt.templates.task_class = (start, end, task) => {
                let css = [this.type];
                if (!this.editable) {
                    css.push('readonly');
                }
                if(task.finished){
                    css.push('taskGreen')
                }else if (task.duration === 0){
                    css.push('blue4')
                } else {
                    css.push('taskBlue')
                }
                return css.join(' ');
            };
        },
        // 自定义行模板
        customTemplates(gantt) {
            // 字段模板：状态
            gantt.templates.state = (task) => {
                return `<span class='traffic-light ${task.state}' slot='prefix'></span>`;
            };
            // 字段模板：单位
            gantt.templates.unit = (task) => {
                return gantt.locale.labels[task.unit + 's'];
            };
            // 字段模板：图例
            gantt.templates.legend = (task) => {
                const icon = this.getMilestoneIcon(task.graph);
                return `<i class='ivu-icon ${icon} ${task.graph}' style='color: ${task.backColor}; -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: ${task.borderColor || task.backColor};'></i>`;
            };
            // 字段模板：资源
            gantt.templates.resource = (task) => {
                // task.type === gantt.config.types.project ||
                if(!task.resources){
                    return '';
                }
                let store = gantt.getDatastore(gantt.config.resource_store);
                let result = '';
                task.resources.forEach(id => {
                    let resource = store.getItem(id);
                    if (resource) {
                        result += '<span class="ivu-tag ivu-tag-checked department-label">' + resource.resourceName  + '</span>';
                    }
                });
                return result;
            };
            // 字段模板：工期
            gantt.templates.duration = (task) => {
                return this.getUnitDuration(task.start_date, task.end_date, task.unit);
            };
            // 字段模板：前置任务
            gantt.templates.predecessors = gantt.util.formatPredecessors;
        },
        // 在甘特上自定义公用方法
        setGanttUtil () {
            let gantt = this.gantt;
            gantt.util = {
                formatPredecessors: (task) => { // 格式化前置任务为字符串
                    let links = task.$target;
                    let labels = [];
                    for(let i = 0; i < links.length; i++){
                        let link = gantt.getLink(links[i]);
                        labels.push(gantt.getWBSCode(gantt.getTask(link.source)));
                        //labels.push(this.linksFormatter.format(link));
                    }
                    return labels.join(', ');
                },
                parseStringPredecessors: (value) => { // 字符串转化前置任务
                    let predecessors = (value || '').split(',');
                    for (let i = 0; i < predecessors.length; i++) {
                        let val = predecessors[i].trim();
                        if (val) {
                            predecessors[i] = val;
                        } else {
                            predecessors.splice(i, 1);
                            i--;
                        }
                    }
                    predecessors.sort();
                    return predecessors;
                },
                getLinksDiff: (task, predecessorCodes) => { // 字符串获取任务关系数据
                    let selectedLinks = [];
                    predecessorCodes.forEach(code => {
                        let link = this.linksFormatter.parse(code);
                        if(link){
                            link.lag = 0;
                            link.target = task.id;
                            if (gantt.isLinkAllowed(link)) {
                                selectedLinks.push(link);
                            }
                        }
                    });
                    let existingLinksSearch = {};
                    task.$target.forEach(function (linkId) {
                        let link = gantt.getLink(linkId);
                        existingLinksSearch[link.source + '_' + link.target + '_' + link.type + '_' + (link.lag||0)] = link.id;
                    });

                    let linksToAdd = [];
                    selectedLinks.forEach(function (link) {
                        let linkKey = link.source + '_' + link.target + '_' + link.type + '_' + (link.lag||0);
                        if (!existingLinksSearch[linkKey]) {
                            linksToAdd.push(link);
                        } else {
                            delete existingLinksSearch[linkKey];
                        }
                    });

                    let linksToDelete = [];
                    for (let i in existingLinksSearch) {
                        linksToDelete.push(existingLinksSearch[i]);
                    }

                    return {
                        add: linksToAdd,
                        remove: linksToDelete
                    };
                },
                savePredecessors(task, value) { // 保存前置任务
                    let oldValue = this.parseStringPredecessors(this.formatPredecessors(task));
                    let newValue = this.parseStringPredecessors(value);
                    if (oldValue.join() !== newValue.join()) { // 是否改变
                        let linksDiff = gantt.util.getLinksDiff(task, newValue);
                        if (linksDiff.add.length || linksDiff.remove.length) {
                            gantt.batchUpdate(function () {
                                linksDiff.add.forEach(function (link) {
                                    gantt.addLink(link);
                                });
                                linksDiff.remove.forEach(function (linkId) {
                                    gantt.deleteLink(linkId);
                                });
                                if (gantt.autoSchedule) {
                                    gantt.autoSchedule();
                                }
                            });
                        }
                    }
                }
            };
        },
        // 获取带单位的工期
        getUnitDuration (start, end, unit) {
            let gantt = this.gantt;
            let gantt_unit = gantt.config.duration_unit;
            gantt.config.duration_unit = unit;
            let duration = gantt.calculateDuration(start, end);
            gantt.config.duration_unit = gantt_unit;
            return duration;
        },
        // 设置基础数据源
        setServerList () {
            let gantt = this.gantt;
            gantt.serverList('unit', [
                { label: gantt.locale.labels.days, value: 'day' },
                { label: gantt.locale.labels.weeks, value: 'week' },
                { label: gantt.locale.labels.months, value: 'month' },
                { label: gantt.locale.labels.years, value: 'year' }
            ]);
            gantt.locale.labels.section_date = '日期';
            gantt.locale.labels.section_graph = '图形';
            gantt.locale.labels.section_color = '颜色';
            gantt.locale.labels.section_phase = '阶段';
        },
        dateCount(date1, date2, type) {
            let count = 0;
            switch (type) {
                case 'month':
                    count = Math.round(((date1.getFullYear() * 12 + date1.getMonth()) - (date2.getFullYear() * 12 + date2.getMonth())));
                    break;
                case 'week':
                    count = Math.round((date1.getTime() - date2.getTime()) / 1000 / 60 / 60 / 24 / 7);
                    break;
            }
            return count;
        },
        getIcon (type) {
            return this.getMilestoneIcon(type);
        },
        getMilestoneIcon(type) {
            let icon = '';
            switch (type) {
                case 'diamond': {
                    icon = 'iconicon-test2';
                    break;
                }
                case 'rectangle': {
                    icon = 'iconicon-test4';
                    break;
                }
                case 'circle': {
                    icon = 'iconicon-test3';
                    break;
                }
                case 'regularTriangle': {
                    icon = 'iconicon-test1';
                    break;
                }
                case 'invertedTriangle': {
                    icon = 'iconicon-test';
                    break;
                }
            }
            return 'iconfont ' + icon;
        },
        getAllMileStoneChild (parentTaskId) {
            let mileStoneChildren = [];
            this.recursionGetLeaf(parentTaskId, mileStoneChildren);
            return mileStoneChildren;
        },
        //递归获得所有叶子节点
        recursionGetLeaf (parentTaskId, mileStoneChildren) {
            let gantt = this.gantt;
            let childrenIds = gantt.getChildren(parentTaskId);
            if (childrenIds && childrenIds.length > 0) {
                childrenIds.forEach((itemId) => {
                    let task = gantt.getTask(itemId);
                    if (task.type == 'project') {
                        this.recursionGetLeaf(task.id, mileStoneChildren);
                    } else {
                        mileStoneChildren.push(task);
                    }
                });
            }
        },
        addMilestoneMarks(newValue){
            for(var key in newValue){
                this.gantt.addMarker({
                    start_date: new Date(newValue[key].startDate),
                    css: 'milestoneLine',
                    text: key,
                    title: newValue[key].shortName
                });
            }
        },
        // 设置当前时间线
        setTodayLine () {
            this.gantt.addMarker({
                start_date: new Date(),
                css: 'today',
                text: 'Heute',
                // title: this.$Date().format(DATE_FORMAT)
            });
        },
        // 切换单位视图
        handleUnitViewChange (value) {
            this.gantt.ext.zoom.setLevel(value);
        },
        // 设置日历
        setCalendar (callback) {
            let gantt = this.gantt;
            //修改日历后，需要删除原先缓存中的数据，设置新的数据后才能正确计算日期
            let calendar = this.gantt.getCalendar('global');
            for (let key in calendar.worktime.dates) {
                delete calendar.worktime.dates[key];
            }
            //
            gantt.setWorkTime({ hours: [0, 24] });
            gantt.setWorkTime({ day: 0, hours: false });
            gantt.setWorkTime({ day: 6, hours: false });
            this.api.PlanCalendarList({
                calendarGroup: this.calendarGroup,
                planId: this.planId
            }).then((res) => {
                let data = res.data.data;
                data.forEach((item) => {
                    if (item.skip) {
                        let date = new Date(item.startDate);
                        let end = new Date(item.endDate);
                        do {
                            if ('notWork' === item.calendarType) { // 休息日
                                gantt.setWorkTime({ date, hours: false });
                            } else if ('work' === item.calendarType) { // 工作日
                                gantt.setWorkTime({ date });
                            }
                            date = this.dateCalculate(date, 'day', 1, true);
                        } while (date < end);
                    } else {
                        let date = new Date(item.startDate);
                        let end = new Date(item.endDate);
                        do {
                            if ('work' === item.calendarType) { // 休息日
                                gantt.setWorkTime({ date, hours: false });
                            } else if ('notWork' === item.calendarType) { // 工作日
                                gantt.setWorkTime({ date });
                            }
                            date = this.dateCalculate(date, 'day', 1, true);
                        } while (date < end);
                    }
                });
                if (callback) {
                    callback();
                }
            });
        },
        dateCalculate(date, type, count) {
            let newDate = null;
            if ('string' === typeof date) {
                date = new Date(date.replace(/-/g, '/'));
            }
            switch (type) {
                case 'year': {
                    newDate = new Date(date.getFullYear() + count, date.getMonth(), date.getDate());
                    break;
                }
                case 'month': {
                    newDate = new Date(date.getFullYear(), date.getMonth() + count, date.getDate());
                    break;
                }
                case 'week': {
                    newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (count * 7));
                    break;
                }
                case 'day': {
                    newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + count);
                    break;
                }
            }
            return newDate;
        },
        // 注册甘特图事件
        registerEvents () {
            let gantt = this.gantt;
            gantt.attachEvent('onTaskCreated', (task) => {
                task.text = '';
                task.unit = 'day';
                task.unit_dictText = gantt.locale.labels.days;
                task.state = 'green';
                task.isEnable = 2;
                task.resources = [];
                task.type = 'milestone'; // 任务类型
                if ('task' === this.type) {
                    task.type = 'task'; // 任务类型
                    task.duration = DAY_MINUTES;//默认一天
                }
                this.$emit('on-create-task', task);
                return true;
            });
            gantt.attachEvent('onBeforeTaskUpdate', (id, task) => {
                this.$emit('on-update-task', task);
                task.startDate = task.start_date;
                task.endDate = task.end_date;
                task.taskType = task.type;
                return true;
            });
            // @yyl
            gantt.attachEvent("onAfterTaskUpdate", function(id,item){
                gantt.autoSchedule()
            });
            gantt.attachEvent('onParse', () => {
                if (this.$props.tasks.resource) {
                    this.gantt.$resourcesStore.parse(this.$props.tasks.resource);
                }
                this.$emit('after-load-data');
            });
        },
    },
})
</script>

<style>
    @import '~dhtmlx-gantt/codebase/dhtmlxgantt.css';
    #gantt .gantt_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }
    #gantt .gantt_task_line.gantt_selected {
        box-shadow: none;
    }

    #gantt .gantt_cell {
        padding: 0 2px;
    }

    
    #gantt .card-full .gantt_wrapper,
    .card-full .gantt_mount_point {
        height: calc(100vh - 130px)!important;
    }

    #gantt .gantt-row-disabled {
        pointer-events: none!important;
        opacity: 0.618!important;
    }
    #gantt .gantt-checkbox-column {
        width: 18px;
        height: 18px;
        vertical-align: middle;
    }

    #gantt .gantt_grid_add {
        color: #328ea0;
    }

    #gantt .gantt_grid_delete {
        color: red;
    }

    #gantt .gantt_grid_send {
        color: #0086e2;
    }

    /* 更改底层样式、用于导出图片 */
    #gantt .gantt_task_line.gantt_milestone {
        visibility: visible!important;
        background: none!important;
    }

    #gantt .milestone {
        line-height: 35px;
        text-align: center;
        font-size: 12px;
    }

    #gantt .milestone .gantt_task {
        cursor: pointer;
        vertical-align: middle;
    }

    #gantt .milestone .milestone_task {
        cursor: pointer;
        vertical-align: middle;
    }

    /* 只读状态的鼠标指针 */
     #gantt .readonly *{
        cursor: default!important;
    }
    /* 完成状态颜色 */
    #gantt .taskGreen *{
        background-color: '#84bd00';
    }
    /* 工期 == 0 颜色 */
    #gantt .blue4 *{
        background-color: 'rgb(54, 77, 110)';
    }
    /* 未完成状态颜色 */
    #gantt .taskBlue *{
        background-color: 'rgb(189, 215, 238)';
    }

    #gantt .milestone > p {
        line-height: 14px;
    }

    /* 任务里程碑 */
    #gantt .task_milestone {
        margin-top: -6px;
        text-align: center;
        font-size: 8px;
    }

    #gantt .task_milestone > i{
        text-align: center;
        padding: 1px;
        margin-left: -1px;
    }

    #gantt .traffic-light {
        width: 16px;
        height: 16px;
        font-size: 0;
        border-radius: 50%;
        margin: 0 auto;
        padding: 0;
        display: inline-block;
    }
    #gantt .red {
        background-color: #cb333b;
    }

    #gantt .yellow {
        background-color: #ffc72c;
    }

    #gantt .green {
        background-color: #84bd00;
    }
    #gantt .gantt_tree_content {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
    #gantt .stop .gantt_tree_content {
        text-decoration-line: line-through;
    }

    /* 节假日 */
    #gantt .weekend {
        background: #f4f7f4;
    }

    #gantt .gantt_selected .weekend {
        background: #f7eb91;
    }

    #gantt .ivu-btn-group > button {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    /* 校验 */
    #gantt .ivu-form-item-error .ivu-input:focus {
        border-color: #ed4014;
        box-shadow: 0 0 0 2px rgba(0, 119, 200, 0.2);
    }

    /* 任务上移 */
    #gantt .gantt_task_line.gantt_bar_task {
        margin-top: -5px;
    }

    #gantt .gantt_task_line.gantt_dependent_task {
        margin-top: -5px;
    }

    #gantt .milestone.gantt_split_parent {
        margin-top: unset!important;
    }

    /* 任务名称放在下面 */
    #gantt .gantt_task_line > .gantt_right {
        width: 100%;
        text-align: center;
        left: auto;
        padding-left: unset;
        top: 15px;
        font-size: 8px;
    }

    /* 资源组件 */
    #gantt .resource .ivu-select-selection > div {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    #gantt .resource .ivu-select-prefix {
        right: -24px;
    }

    #gantt .gantt_task_scale{
        /* background-color: rgb(255,255,155); */
        background: rgba(166,187,200,0.25);
        color: #7b94a3;
    }
    .gantt_task .gantt_task_scale .gantt_scale_cell {
        color: #7b94a3;
        border-right: 1px solid #ebebeb;
    }
    #gantt .milestoneLine{
        background-color:#0086e2;
    }
    .gantt_grid_scale, .gantt_task_scale, .gantt_task_vscroll{
        background: rgba(166,187,200,0.25);
    }
    .gantt_grid_scale, .gantt_task_scale{
        font-weight: 600;
        font-family: PingFangSC, PingFangSC-Medium;
    }
    .gantt_grid_scale .gantt_grid_head_cell{
        color: #7b94a3;
    }
    .gantt_container{
        border: none;
    }
    .gantt_layout_cell_border_right {
        border-right: 1px solid transparent;
    }
</style>
<style lang="scss">
    .gantt_message_area {
        z-index: -1000;
        display: none;
    }
</style>