import AccountBaseService from '../base/AccountBaseService';
import { isPlatform} from "@ionic/vue";
import Setting from '@/rest/Setting';

export class VersionApi extends AccountBaseService {
    constructor() {
        super("","");
    }

    /**
   * 获取最新版本
   */
    public getLastVersion(params: any,hearders: any): Promise<any> {  
           let res = this.service.apiBaseURL ? Setting.baseURL + "/" + this.service.apiBaseURL : Setting.apiBaseURL;  
        let url = isPlatform("ios")?"app/version/mobile/mgr/ios/latest":"app/version/mobile/mgr/android/latest";
        
        return this.rest.requestCustom(res+"/"+url, this.rest.METHOD_GET, params,hearders);
    }
}