
    import { modalController,IonPage } from "@ionic/vue";
    import HeaderModal from "@/components/modal/components/HeaderModal.vue";
    import PermissionSetting from "./setting/Permission.vue";
    import SetOrganizationList from "./setting/OrganizationList.vue";
    import { defineComponent } from "vue";

    export default defineComponent({
        name: "TaskSetting",
        props: {
            isProject: Boolean, //是否是项目设置，false则为任务设置
            id: String, //主键id，项目设置为projectId，否则为taskId
            isHasPermission: Boolean
        },
        components: {
            HeaderModal,
            PermissionSetting,
            SetOrganizationList
        },
        data() {
            return {
                selSeg: "0",
                tabs: [{
                        "name": "任务设置",
                        "path": "PermissionSetting"
                    },
                    {
                        "name": "参与机构",
                        "path": "SetOrganizationList"
                    }
                ]
            }
        },
        methods: {
            segmentChanged(e: any) {
                this.selSeg = e.detail.value;
            },
            closeModel(type: any) {
                modalController.dismiss({});
            },
        }
    })
