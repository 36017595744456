
import { modalController, alertController, isPlatform } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";

import { ScheduleService } from "@/services/schedule/ScheduleService.ts";
import { ScheduleFeedbackService } from "@/services/schedule/ScheduleFeedbackService.ts";
import { MessageService } from "@/services/message/MessageService.ts";
import BaseRsp from "@/models/base/BaseRsp";
import SelectCalendar from "../component/SelectCalendar.vue";
import { CalendarDetailService } from "@/services/calendar/CalendarDetailService.ts";
import { CalendarNative, EventOption } from "@/utils/CalendarNative";
import Setting from "@/rest/Setting";

export default defineComponent({
  name: "DetailScheduleFeedback",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    const calendarNative = new CalendarNative();
    const scheduleInfo: any = {
      id: "",
      name: "",
      address: "",
      startTime: "",
      endTime: "",
      isAllday: false,
      scheduleTimeIsDelete: false,
      remark: "",
      feedback: "",
      needFeedback: true,
      priority: -1,
      chatGroupId: "",
      relationFirstId: "",
      mobileScheduleId: "",
      calendarDetail: {
        id: "",
        calendarName: "",
      },
      calendarDetailList: [],

      userList: [],
      rejectUserList: [],
      repeat: {
        id: "never",
        text: "永不",
        repeat: "",
        span: 0,
        dateList: [],
        endTime: "",
      },
      remind: {
        id: "",
        text: "",
      },
      projectRelation: {
        id: "",
        text: "",
      },
      taskRelation: [],
      attachment: [],
    };
    const weekday = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    const weekday2 = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
      "星期日",
    ];
    const priorityList = ["低", "中", "高", "非常紧急"];
    const startTime = {
      date: "",
      day: "",
      time: "",
    };
    const endTime = {
      date: "",
      day: "",
      time: "",
    };
    return {
      scheduleService: new ScheduleService(),
      scheduleFeedbackService: new ScheduleFeedbackService(),
      router: router,
      useRoute: useRoute(),
      calendarNative: calendarNative,
      more: false,
      isBack: true,
      schedule: scheduleInfo,
      btnIconRight: ["icongengduo"],
      btnSizeRight: ["34px"],
      startTime: startTime,
      endTime: endTime,
      weekday: weekday,
      weekday2: weekday2,
      feedbackResult: -1, //反馈结果
      scheduleId: "",
      scheduleTimeId: "",
      feedbackId: "",
      currentUserId: "",
      showFeedback: false,
      selectOperate: "-1",
      selectDeleteType: "-1",
      isRepeat: false,
      rejectReason: "",
      showOperate: false, //是否显示编辑删除操作
      priorityList: priorityList,
      currentUserName: "",
      messageService: new MessageService(),
      calendarSelect: {
        id: "",
        name: "",
      },
      showSelectCalendar: false,
      calendarDetailService: new CalendarDetailService(),
      calendarSelectList: new Array(),
      calendarDeleteList: new Array(),
      showCalendar: "false", //是否显示关联日历行
      isSVW: Setting.isSVW,
    };
  },
  created() {
    // if (this.useRoute.params && this.useRoute.params.info) {
    //   const infoParam = this.useRoute.params.info as string;
    //   const infoParamJson: any = JSON.parse(infoParam);
    //   this.scheduleId = infoParamJson.id;
    //   this.scheduleTimeId = infoParamJson.scheduleTimeId;
    //   const showCal: any = this.useRoute.params.showCalendar;
    //   this.showCalendar = showCal;
    // }
    const currentUser: any = SystemUtils.loginUser;
    this.currentUserId = currentUser.id;

    this.currentUserName = currentUser.name;
  },
  ionViewWillEnter() {
    if (this.useRoute.query.id != null) {
      this.scheduleId = this.useRoute.query.id as string;
      this.scheduleTimeId = this.useRoute.query.scheduleTimeId as string;
      const showCal: any = this.useRoute.query.showCalendar;
      this.showCalendar = showCal;
    }
    this.initPageValue();
  },
  methods: {
    initPageValue() {
      this.resetPage();
      this.initPermission();
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    goBack() {
      this.router.go(-1);
    },
    ismore() {
      this.more = !this.more;
    },
    bindFeedbakc(e: any) {
      this.feedbackResult = e.detail.value;
      //参加 暂定
      if (e.detail.value == 1 || e.detail.value == 2) {
        //选中select后需要重置，否则再点select不会触发change
        this.submitHandler(e.detail.value);
        this.feedbackResult = -1;
      } else {
        if (this.feedbackResult != -1) {
          this.presentAlertPrompt();
          //选中select后需要重置，否则再点select不会触发change
          this.feedbackResult = -1;
        }
      }
    },
    async presentAlertPrompt() {
      let that = this;
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "请输入原因",
        mode: "ios",
        inputs: [
          {
            placeholder: "请输入原因",
            type: "textarea",
            value: that.rejectReason,
            cssClass: "specialClass",
            attributes: {
              rows: 10,
              maxlength: 50,
              inputmode: "textarea",
              id: "rejectReasonFeedback",
            },
          },
        ],
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: (e: any) => {
              that.rejectReason = e[0];
              let charFlag = false;
              if (that.rejectReason.replace(/\s+/g, "") == "") {
                Utils.presentToastWarning("请输入原因");
                charFlag = true;
              } else {
                if (that.rejectReason.length > 50) {
                  Utils.presentToastWarning("拒绝原因最多输入50个字符");
                  charFlag = true;
                }
              }
              if (Utils.isInCludeEmoji(that.rejectReason)) {
                Utils.presentToastWarning("原因不可以包含表情");
                charFlag = true;
              }
              if (charFlag) {
                that.presentAlertPrompt();
              } else {
                that.rejectApprove();
              }
            },
          },
        ],
      });
      await alert.present();
      await alert.onWillDismiss().then((data: any) => {
        if (data.role == "cancel" || data.role == "backdrop") {
          let doc: any = document.getElementById("rejectReasonFeedback");
          if (doc) {
            that.rejectReason = doc.value;
          }
        }
      });
    },
    initPermission() {
      this.calendarNative.checkReadWritePermission().then(
        (bo: boolean) => {},
        (e: boolean) => {}
      );
    },
    resetPage() {
      let params = {
        scheduleId: this.scheduleId,
        scheduleTimeId: this.scheduleTimeId,
      };
      this.scheduleService.getScheduleDetail(params).then((result: any) => {
        let res = result.data;
        if (res.scheduleTimeId == null || res.scheduleTimeId == "") {
          Utils.presentToastWarning("抱歉，该日程已不存在");
          this.goBack();
        } else {
          this.schedule.name = res.name;
          this.schedule.startTime = res.startTime;
          this.startTime = this.formatDate(res.startTime);
          this.schedule.endTime = res.endTime;
          this.endTime = this.formatDate(res.endTime);
          this.schedule.address = res.address;
          this.schedule.priority = res.priority;
          this.schedule.isAllday = res.isAllday;
          this.schedule.remark = res.remark;
          this.schedule.priority = res.priority;
          this.schedule.chatGroupId = res.chatGroupId;
          this.schedule.relationFirstId = res.relationFirstId;
          this.schedule.scheduleTimeIsDelete = res.scheduleTimeIsDelete;
          this.schedule.mobileScheduleId = res.mobileScheduleId;

          //创建人有操作按钮
          if (res.creatorId == this.currentUserId) {
            this.showOperate = true;
          }

          //日历 calendarDetailList
          if (res.calendarDetailList) {
            this.schedule.calendarDetailList = [];
            let that = this;
            res.calendarDetailList.forEach((ele: any) => {
              //不是我关联的不能修改
              let canCheck = true;
              if (ele.creatorId != that.currentUserId) {
                canCheck = false;
              }
              that.schedule.calendarDetailList.push({
                id: ele.id,
                calId: ele.calId,
                name: ele.calendarName,
                creatorId: ele.creatorId,
                canCheck: canCheck,
              });
            });
          }

          this.schedule.needFeedback = res.needFeedback;
          this.schedule.feedback = this.schedule.needFeedback ? "是" : "否";
          //需要反馈
          if (this.schedule.needFeedback) {
            //参与人反馈
            if (
              res.scheduleFeedbackModelList &&
              res.scheduleFeedbackModelList.length > 0
            ) {
              this.schedule.userList = [];
              this.schedule.rejectUserList = [];
              res.scheduleFeedbackModelList.forEach((ele: any) => {
                //statusType 999:发起人 0:未反馈 1：参加 2：暂定 3：拒绝
                if (ele.creatorId == ele.userId) {
                  ele.statusType = 999;
                } else {
                  ele.statusType = ele.typeValue;
                }
                ele.status = this.getStatus(ele.statusType);
                //拒绝
                if (ele.typeValue == 3) {
                  this.schedule.rejectUserList.push(ele);
                } else {
                  this.schedule.userList.push(ele);
                }
                //如果是当前人是参与人(创建人不参与反馈)，并且未反馈
                if (
                  ele.userId == this.currentUserId &&
                  ele.creatorId != this.currentUserId &&
                  ele.typeValue == 0
                ) {
                  this.feedbackId = ele.id;
                  this.showFeedback = true;
                }
              });
            }
          } else {
            this.schedule.userList = res.scheduleParticipantList;
          }

          //如果是当前人是参与人，可以重新选择日历
          res.scheduleParticipantList.forEach((ele: any) => {
            if (ele.userId == this.currentUserId) {
              this.showSelectCalendar = true;
            }
          });

          //提醒
          //remind: { id: "id1",text: "每十分钟提醒一次",},
          if (
            res.sheduleWarningRuleModel &&
            res.sheduleWarningRuleModel.id != null
          ) {
            this.schedule.remind.id = res.sheduleWarningRuleModel.id;
            this.schedule.remind.text = this.getRemindInfo(
              res.sheduleWarningRuleModel
            );
          }
          //重复
          let repeatParams: any = JSON.parse(
            res.scheduleRepeatSetting.parameter
          );
          if (repeatParams.type == "never") {
            this.isRepeat = false;
          } else {
            this.isRepeat = true;
          }
          //重复
          this.schedule.repeat.id = repeatParams.type;
          this.schedule.repeat.repeat = repeatParams.repeat;
          this.schedule.repeat.span = repeatParams.span;
          this.schedule.repeat.dateList = repeatParams.dateList;
          this.schedule.repeat.endTime = res.scheduleRepeatSetting.deadTime;
          this.getRepeatInfo();

          //项目关联
          this.schedule.projectRelation = [];
          if (
            res.projectRelation &&
            res.projectRelation != "" &&
            res.projectRelation != [] &&
            res.projectRelation.length > 0
          ) {
            let that = this;
            res.projectRelation.forEach((ele: any) => {
              that.schedule.projectRelation.push({
                id: ele.formId,
                text: ele.relationName,
              });
            });
          }
          //任务关联
          if (
            res.taskRelation &&
            res.taskRelation != "" &&
            res.taskRelation != [] &&
            res.taskRelation.length > 0
          ) {
            this.schedule.taskRelation = [];
            let that = this;
            res.taskRelation.forEach((ele: any) => {
              that.schedule.taskRelation.push({
                id: ele.formId,
                text: ele.relationName,
              });
            });
          } else {
            this.schedule.taskRelation = [];
          }
        }
      });
    },
    getRemindInfo(model: any) {
      let typeValue = "";
      switch (model.typeValue) {
        case 0:
          typeValue = "时";
          break;
        case 1:
          typeValue = "前";
          break;
      }
      let unit = "";
      switch (model.unit) {
        case "day":
          unit = "天";
          break;
        case "min":
          unit = "分钟";
          break;
        case "hour":
          unit = "小时";
          break;
      }
      if (model.typeValue + "" == "") {
        return "";
      } else if (model.typeValue == -1) {
        return "无";
      } else if (model.typeValue == 0) {
        return "任务开始时提醒一次";
      } else {
        return "任务开始" + typeValue + model.intervals + unit + "提醒一次";
      }
    },
    //拼接重复内容
    getRepeatInfo() {
      //重复参数格式 type: never:永不 everyday:每天 everyweek:每周  everytwoweek:每两周 everymonth:每月  everyyear:每年 everyworkday:每个工作日.diy:自定义
      switch (this.schedule.repeat.id) {
        case "never":
          this.schedule.repeat.text = "永不";
          break;
        case "everyday":
          this.schedule.repeat.text = "每天";
          break;
        case "everyweek":
          this.schedule.repeat.text = "每周";
          break;
        case "everytwoweek":
          this.schedule.repeat.text = "每两周";
          break;
        case "everymonth":
          this.schedule.repeat.text = "每月";
          break;
        case "everyyear":
          this.schedule.repeat.text = "每年";
          break;
        case "diy":
          //{type:'diy',repeat:'day',span:1,dateList:[1,2]}
          switch (this.schedule.repeat.repeat) {
            case "day":
              if (this.schedule.repeat.span != -1) {
                this.schedule.repeat.text =
                  "事件将每" + this.schedule.repeat.span + "天重复一次";
              }
              break;
            case "week":
              if (
                this.schedule.repeat.span != -1 &&
                this.schedule.repeat.dateList.length > 0
              ) {
                let str = "";
                for (let i = 0; i < this.schedule.repeat.dateList.length; i++) {
                  str +=
                    str == ""
                      ? this.weekday2[this.schedule.repeat.dateList[i]]
                      : "," + this.weekday2[this.schedule.repeat.dateList[i]];
                }
                this.schedule.repeat.text =
                  "事件将每" +
                  this.schedule.repeat.span +
                  "周于" +
                  str +
                  "重复一次";
              }
              break;
            case "month":
              if (this.schedule.repeat.span != -1) {
                let str = "";
                for (
                  let index = 0;
                  index < this.schedule.repeat.dateList.length;
                  index++
                ) {
                  str +=
                    str == ""
                      ? this.schedule.repeat.dateList[index] + "日"
                      : "," + this.schedule.repeat.dateList[index] + "日";
                }
                0;
                this.schedule.repeat.text =
                  "事件将每" +
                  this.schedule.repeat.span +
                  "个月于" +
                  str +
                  "重复一次";
              }
              break;
          }
          break;
      }
      if (this.schedule.repeat.id != "never") {
        this.schedule.repeat.text =
          this.schedule.repeat.text +
          "，截止：" +
          moment(this.schedule.repeat.endTime).format("YYYY-MM-DD");
      }
    },
    //0:未反馈 1：参加 2：暂定 3：拒绝
    getStatus(statusType: number) {
      let statusName = "";
      switch (statusType) {
        case 999:
          statusName = "发起人";
          break;
        case 0:
          statusName = "未反馈";
          break;
        case 1:
          statusName = "参加";
          break;
        case 2:
          statusName = "暂定";
          break;
        case 3:
          statusName = "拒绝";
          break;
      }
      return statusName;
    },
    getPriority(index: number) {
      return this.priorityList[index];
    },
    formatDate(value: any) {
      let info = {
        date: "",
        day: "",
        time: "",
      };
      let date: any = moment(value).format("YYYY-MM-DD");
      let todayDate = new Date();
      let dateToday: any = moment(todayDate).format("YYYY-MM-DD");
      todayDate.setDate(todayDate.getDate() - 1);
      let dateYestoday: any = moment(todayDate).format("YYYY-MM-DD");
      todayDate.setDate(todayDate.getDate() + 2);
      let dateTomorrow: any = moment(todayDate).format("YYYY-MM-DD");

      if (date == dateToday) {
        info.date = "今天";
      } else if (date == dateYestoday) {
        info.date = "昨天";
      } else if (date == dateTomorrow) {
        info.date = "明天";
      } else {
        info.date = date;
      }
      let day: number = moment(value).day();
      info.day = this.weekday[day];
      let dateHour: any = moment(value).format("YYYY-MM-DD HH:mm");
      info.time = dateHour.substr(10, 6);
      return info;
    },
    feedbackHandler() {
      //反馈
      let selectDay: any = document.getElementById("select-feedback");
      let el: HTMLElement = selectDay;
      el.click();
    },
    saveHandler() {
      //编辑 删除
      let selectOperate: any = document.getElementById(
        "select-operate-schedule"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindOperate(e: any) {
      this.selectOperate = e.detail.value;
      if (this.selectOperate == "edit") {
        //选中select后需要重置，否则再点select不会触发change
        this.selectOperate = "-1";
        this.editItem();
      } else {
        if (this.selectOperate != "-1") {
          //选中select后需要重置，否则再点select不会触发change
          this.selectOperate = "-1";
          if (this.isRepeat) {
            //删除
            let selectOperate: any = document.getElementById(
              "select-operate-delete-schedule"
            );
            let el: HTMLElement = selectOperate;
            el.click();
          } else {
            this.deleteThisRecord();
            this.selectDeleteType = "-1";
          }
        }
      }
    },
    bindOperateDelete(e: any) {
      this.selectDeleteType = e.detail.value;
      //删除本条记录
      if (this.selectDeleteType == "1") {
        this.deleteThisRecord();
        this.selectDeleteType = "-1";
      } else {
        //删除本条及以后全部记录
        if (this.selectDeleteType != "-1") {
          this.deleteThisAndAfterRecord();
          this.selectDeleteType = "-1";
        }
      }
      //删除本地日程
      this.removeScheduleEvent(this.schedule.mobileScheduleId);
    },
    removeScheduleEvent(eventKey: string) {
      if (eventKey != undefined && eventKey != "") {
        const eventJson = JSON.parse(eventKey);
        let keys = [];
        if (isPlatform("ios") && eventJson.ios != "") {
          keys.push(eventJson.ios);
        }
        if (isPlatform("android") && eventJson.android != "") {
          keys.push(eventJson.android);
        }
        this.calendarNative.deleteEventByIds(keys).then(
          (r: any) => {
            console.log("删除成功");
          },
          (e: any) => {
            console.log("删除失败");
          }
        );
      }
    },
    //还原日程或者删除
    async bindOperateRestore(e: any) {
      if (e.detail.value == "restore") {
        const alert = await alertController.create({
          header: "还原日程",
          message: "您确定要还原此日程",
          mode: "ios",
          buttons: [
            {
              text: "取消",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {},
            },
            {
              text: "确定",
              handler: () => {
                this.scheduleService
                  .restoreSchedule({ scheduleTimeId: this.scheduleTimeId })
                  .then(
                    (r: any) => {
                      // Utils.presentToast("日程还原成功");
                      // this.goBack();
                      this.send("6");
                      this.saveCalendarEvent(this.schedule);
                    },
                    (e: any) => {
                      Utils.presentToastWarning("日程还原失败");
                    }
                  );
              },
            },
          ],
        });
        await alert.present();
      } else if (e.detail.value == "thoroughDelete") {
        const alert = await alertController.create({
          header: "删除日程",
          message: "您确定要删除此日程",
          mode: "ios",
          buttons: [
            {
              text: "取消",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {},
            },
            {
              text: "确定",
              handler: () => {
                this.scheduleService
                  .clearById({ scheduleTimeId: this.scheduleTimeId })
                  .then(
                    (r: any) => {
                      // Utils.presentToast("日程删除成功");
                      // this.goBack();
                      this.send("7");
                      this.removeScheduleEvent(this.schedule.mobileScheduleId);
                    },
                    (e: any) => {
                      Utils.presentToastWarning("日程删除失败");
                    }
                  );
              },
            },
          ],
        });
        await alert.present();
      }
    },
    //删除本条记录
    deleteThisRecord() {
      let that = this;
      Utils.confirmDialog(
        "确认",
        "确认要移至本条日程到回收站吗？",
        function () {
          let params = {
            scheduleTimeId: that.scheduleTimeId,
          };
          that.scheduleService
            .deleteScheduleTimeById(params)
            .then((res: any) => {
              // that.goBack();
              that.send("4");
              that.removeScheduleEvent(that.schedule.mobileScheduleId);
            });
        }
      );
    },
    //删除本条及以后全部记录
    deleteThisAndAfterRecord() {
      let that = this;
      Utils.confirmDialog(
        "确认",
        "确认要移至本条及以后全部日程到回收站吗？",
        function () {
          let id = that.scheduleId;
          //找到最初的日程id
          if (
            that.schedule.relationFirstId &&
            that.schedule.relationFirstId != ""
          ) {
            id = that.schedule.relationFirstId;
          }
          let params = {
            scheduleId: id,
            scheduleTimeId: that.scheduleTimeId,
          };
          that.scheduleService
            .deleteScheduleThisAndAfter(params)
            .then((res: any) => {
              that.send("5");
              that.removeScheduleEvent(that.schedule.mobileScheduleId);
            });
        }
      );
    },
    //编辑
    editItem() {
      const info = {
        scheduleId: this.scheduleId,
        scheduleTimeId: this.scheduleTimeId,
      };
      this.router.push({
        name: "add-schedule",
        params: {
          schedule: JSON.stringify(info),
        },
      });
    },
    //反馈：同意 暂定
    submitHandler(type: number) {
      let params = {
        id: this.feedbackId,
        setFields: ["typeValue"],
        typeValue: type,
      };
      this.scheduleFeedbackService.updateFieldsById(params).then((res: any) => {
        if (res) {
          Utils.presentToastSuccess("保存成功");
          //  this.router.go(-1);
          this.send(type.toString());
        }
      });
    },
    //反馈拒绝
    rejectApprove() {
      if (!this.rejectReason || !this.rejectReason.replace(/\s+/g, "")) {
        Utils.presentToastWarning("请输入拒绝原因");
        return false;
      }
      let params = {
        id: this.feedbackId,
        setFields: ["typeValue", "reason"],
        typeValue: 3,
        reason: this.rejectReason,
      };
      this.scheduleFeedbackService.updateFieldsById(params).then((res: any) => {
        if (res && res.data) {
          Utils.presentToastSuccess("保存成功");
          // this.router.go(-1);
          this.send("3");
        }
      });
    },
    //发送消息
    send(type: any) {
      let that = this;
      let chatGroupId = that.schedule.chatGroupId;
      let scheduleTimeId = that.scheduleTimeId;
      let scheduleId = that.scheduleId;

      let saveType = "";
      switch (type) {
        case "1":
          saveType = "参加";
          break;
        case "2":
          saveType = "待定";
          break;
        case "3":
          saveType = "拒绝";
          break;
        case "4":
          saveType = that.schedule.startTime + "-" + that.schedule.endTime;
          break;
        case "5":
          saveType = "移至回收站当前及以后";
          break;
        case "6":
          saveType = "还原";
          break;
        case "7":
          saveType = "物理删除";
          break;
      }
      let reason = type == 3 ? "。原因：" + this.rejectReason : "";
      let content = "";
      switch (type) {
        case "1":
        case "2":
        case "3":
          content =
            that.currentUserName + "进行了日程反馈：" + saveType + reason;
          break;
        case "4":
          if (that.isRepeat) {
            content =
              that.currentUserName +
              "移至回收站了" +
              moment(that.schedule.startTime).format("yyyy年MM月DD日") +
              "的日程：" +
              that.schedule.name +
              "。";
          } else {
            content =
              that.currentUserName +
              "移至回收站了日程：" +
              that.schedule.name +
              "。";
          }
          break;
        case "5":
          content =
            that.currentUserName +
            "移至回收站了" +
            moment(that.schedule.startTime).format("yyyy年MM月DD日") +
            "及以后的日程：" +
            that.schedule.name +
            "。";
          break;
        case "6":
          content =
            that.currentUserName +
            "还原了" +
            moment(that.schedule.startTime).format("yyyy年MM月DD日") +
            "的日程：" +
            that.schedule.name +
            "。";
          break;
        case "7":
          content =
            that.currentUserName +
            "物理删除了" +
            moment(that.schedule.startTime).format("yyyy年MM月DD日") +
            "的日程：" +
            that.schedule.name +
            "。";
          break;
      }
      that.messageService
        .sendMessageExpose(
          chatGroupId,
          content,
          3,
          "cal_schedule",
          scheduleId + "",
          that.schedule.name + ""
        )
        .then(
          (rsp: BaseRsp) => {
            that.router.go(-1);
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
          }
        );
    },
    //选择日历
    async chooseCalendarHandler() {
      let list = new Array();
      let that = this;
      this.schedule.calendarDetailList.forEach((ele: any) => {
        list.push({ id: ele.calId, name: ele.name, canCheck: ele.canCheck });
      });
      const modal = await modalController.create({
        component: SelectCalendar,
        cssClass: "notice-modal-class",
        componentProps: {
          paramList: { list: list },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedCalendar) {
        that.calendarSelectList = new Array();
        that.calendarDeleteList = new Array();
        if (data.selectedCalendar.length > 0) {
          data.selectedCalendar.forEach((sel: any) => {
            let tList = that.schedule.calendarDetailList.filter((ele: any) => {
              return sel.id == ele.calId;
            });
            if (tList.length == 0) {
              that.calendarSelectList.push(sel);
            }
          });
          that.schedule.calendarDetailList.forEach((sel: any) => {
            let tList = data.selectedCalendar.filter((ele: any) => {
              return sel.calId == ele.id;
            });
            if (tList.length == 0) {
              that.calendarDeleteList.push(sel);
            }
          });

          if (that.calendarSelectList.length > 0) {
            if (that.calendarDeleteList.length > 0) {
              that.submitCalendarHandler(function () {
                that.delCalendarList(function () {
                  Utils.presentToastSuccess("日历修改成功");
                  that.resetPage();
                });
              });
            } else {
              that.submitCalendarHandler(()=> {
                Utils.presentToastSuccess("日历修改成功");
                that.resetPage();
              });
            }
          } else {
            if (that.calendarDeleteList.length > 0) {
              that.delCalendarList(function () {
                Utils.presentToastSuccess("日历修改成功");
                that.resetPage();
              });
            }
          }
        } else {
          that.schedule.calendarDetailList.forEach((ele: any) => {
            that.calendarDeleteList.push(ele);
          });
          if (that.calendarDeleteList.length > 0) {
            that.delCalendarList(()=> {
              Utils.presentToastSuccess("日历修改成功");
              that.resetPage();
            });
          }
        }
      }
    },
    //修改日历
    submitCalendarHandler(callback: any) {
      let ids = new Array();
      this.calendarSelectList.forEach((ele: any) => {
        ids.push(ele.id);
      });
      let params = {
        scheduleId: this.scheduleId,
        calendarIds: ids,
      };
      this.calendarDetailService.saveCalendarDetail(params).then((res: any) => {
        if (res && res != "false") {
          //Utils.presentToastSuccess("日历添加成功");
          // this.resetPage();
          callback();
        }
      });
    },
    //删除日历
    delCalendar(item: any, index: number) {
      let that = this;
      Utils.confirmDialog("确认", "确认要删除该日历吗？", function () {
        let params = {
          id: item.id,
        };
        that.calendarDetailService.deleteById(params).then((res: any) => {
          if (res) {
            Utils.presentToastSuccess("日历删除成功");
            that.resetPage();
          }
        });
      });
    },
    //批量删除日历
    delCalendarList(callback: any) {
      let ids = new Array();
      this.calendarDeleteList.forEach((ele: any) => {
        ids.push(ele.id);
      });

      let params = {
        ids: ids,
      };
      this.calendarDetailService.deleteByIds(params).then((res: any) => {
        if (res) {
          // Utils.presentToastSuccess("日历修改成功");
          // this.resetPage();
          callback();
        }
      }).catch((ex: any)=>{
         Utils.presentToastWarning("删除失败");
      });;
    },
    projectDetailHandler(item: any) {
      this.router.push({
        path: "/ProjectPage",
        query: {
          projectId: item.id,
          projectName: item.text,
        },
      });
    },
    taskDetailHandler(item: any) {
      this.router.push({
        path: "/task-page",
        query: {
          id: item.id,
        },
      });
    },
    generateNotes(partUsers: Array<any>): string {
      let noteUsers = "";
      if (partUsers != undefined && partUsers.length > 0) {
        noteUsers = "参与人：";
        partUsers.forEach((user: any) => {
          noteUsers += user.userName + "、";
        });
        noteUsers = noteUsers.substring(0, noteUsers.length - 1);
      }
      return noteUsers;
    },
    compareMakeDate(startDate: Date, endDate: Date): Date {
      if (startDate.getTime() == endDate.getTime()) {
        endDate.setTime(endDate.getTime() + 1000 * 60);
      }
      return endDate;
    },
    saveCalendarEvent(item: any) {
      const title: string = item.name;
      const location: string = item.address;
      const notes: string = item.remark;
      const startTime: string = item.startTime;
      const endTime: string = item.endTime;
      if (startTime != "" && endTime != "") {
        let startDate = new Date(startTime.replace(/\-/g, "/"));
        let endDate = new Date(endTime.replace(/\-/g, "/"));
        const partUsers: Array<any> = item.userList;
        this.calendarNative.findAllCalendar().then(
          (r: any) => {
            if (r && r.length > 0) {
              let noteUsers = this.generateNotes(partUsers);
              let option: EventOption = {
                calendarName: "",
                calendarId: "",
                title: title,
                eventLocation: location,
                notes: noteUsers + (notes != "" ? "\n说明：" + notes : ""),
                startDate: startDate,
                endDate: this.compareMakeDate(startDate, endDate),
              };
              if (isPlatform("ios")) {
                option.calendarName = r[0].name;
              } else {
                option.calendarId = r[0].id;
              }
              //创建
              this.calendarNative.createEventSilently(option).then(
                (r: any) => {
                  this.updateMobileScheduleId(this.scheduleId, r);
                 // console.log("日程标识：" + JSON.stringify(r));
                },
                (e: boolean) => {
                  console.log("异常：" + JSON.stringify(e));
                }
              );
            } else {
              console.log("没有日历");
            }
          },
          (e: any) => {
            console.log("获取日历失败");
          }
        );
      }
    },
    //更新手机事件标识
    updateMobileScheduleId(scheduleId: string, eventId: string) {
      let eventJson = { ios: "", android: "" };
      if (isPlatform("ios")) {
        eventJson.ios = eventId;
      } else {
        eventJson.android = eventId;
      }
      const params = {
        id: scheduleId,
        setFields: ["mobileScheduleId"],
        mobileScheduleId: JSON.stringify(eventJson),
      };
      this.scheduleService.updateFieldsById(params).then((res: any) => {});
    },
  },
});
