import { DashboardApi } from '@/api/dashboard/DashboardApi';

/**
 * Dashboard
 */
export class DashboardService {
    private api = new DashboardApi();
    public getProjectInfo(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getProjectInfo(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getCarTypeProduct(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getCarTypeProduct(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getTeamUser(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getTeamUser(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getCardProject(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.getCardProject(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    // dashboard列表页
    public getDashboardList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDashboardList(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // dashboard列表页查询
    public getMasterData(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMasterData(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // dashboard列表页查询
    public getMilestoneNames(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMilestoneNames(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // dashboard详情页
    public getDashboardCheckpint(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDashboardCheckpint(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // Budget权限验证
    public getCheckAuth(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getCheckAuth(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 状态列表
    public getCheckpointTimes(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getCheckpointTimes(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 状态列表
    public getCheckpointsStates(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getCheckpointsStates(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 收藏
    public getCollection(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getCollection(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 点击
    public getHits(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getHits(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 获取dashboard详情信息
    public getDashboardById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getDashboardById(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 计划页面接口
    // 根据项目id获得最新版本的主里程碑计划(主里程碑tap页)
    public getMainMileStone(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMainMileStone(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //主里程碑计划任务(主里程碑tap页)
    public selectTasks(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.selectTasks(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    // 根据项目id获得最新版本的主里程碑计划(主计划tap页)
    public getMainPlanByProjectId(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMainPlanByProjectId(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //根据 relationid 获取关联里程碑任务(主计划tap页)
    public showMileStonePlan(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.showMileStonePlan(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //根据planid 获取任务数据(主计划tap页)
    public getMainTask(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMainTask(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // 关注任务
    public getAttentiontasks(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAttentiontasks(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public PlanCalendarList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.PlanCalendarList(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }


    public getRelationStone(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getRelationStone(param).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

}