export default class TaskQueryCondition {
    name: string;
    createTimeStart: string;
    createTimeEnd:  string;
    startTimeStart:  string;
    startTimeEnd:  string;
    endTimeStart:  string;
    endTimeEnd:  string;
    finishTimeStart:  string;
    finishTimeEnd:  string;
    priority:  string;
    overdueStatus:  string;
    endDate:  string;
    isFinish: string
    systemId:  string;
    workUsers:  string;
    joinUsers:  string;
    creatorId:  string;

    constructor(item: any) { 
        this.name=item.qname;
        this.createTimeStart= item.qcreateTimeb;
        this.createTimeEnd= item.qcreateTimee;
        this.startTimeStart= item.qstartTimeb;
        this.startTimeEnd= item.qstartTimee;
        this.endTimeStart= item.qendTimeb;
        this.endTimeEnd= item.qendTimee;
        this.finishTimeStart= item.qcompleteTimeb;
        this.finishTimeEnd= item.qcompleteTimee;
        this.priority= item.qpriorityValues;
        this.overdueStatus= item.qstatusValue;
        this.endDate= item.qendTimeType;
        this.isFinish= item.qisFinished;// item.q_is_finished;
        this.systemId= item.qsource;
        this.workUsers= item.qexecutorIds;
        this.joinUsers= item.qparterIds;
        this.creatorId= item.qcreatorIds;
    }
}
