
import { defineComponent } from "vue";
import TaskList from "@/views/task/TaskList.vue";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "MyTodayTask",
  components: {
    TaskList,
  },
  data() {
    return {
      currentUser: SystemUtils.loginUser,
      searchKey: "",
      searchParam: "",
      refreshMyPage:false
    };
  },
  props: {
    refresh: Boolean,
  },
  watch: {
    refresh() {
      console.log("mytodaytask:"+this.refreshMyPage);
      this.refreshMyPage=this.refresh;
    },
  },
  created() {
    this.searchParam = JSON.stringify({
      workUsers: SystemUtils.loginUser.id,
      isFinish: 0,
      agent: 1,
    });
  },
});
