
import { defineComponent } from "vue";
import { popoverController, modalController } from "@ionic/vue";
import * as echarts from "echarts";
import moment from "moment";
import { getInstance } from "@/services/projectPageService";
import { ProjectService } from "@/services/project/projectService";
import SystemUtils from "@/utils/SystemUtils";
import User from "@/models/task/User";
import Utils from "@/utils/Utils";
import StatusPopover from "./StatusPopover.vue";
import { TaskService } from "@/services/task/TaskService";
import HistoryUpdatedModal from "./HistoryUpdatedModal.vue";

export default defineComponent({
  name: "OverViewList",
  props: {
    isHasEditProject: Boolean,
  },
  data() {
    const projectPageService = getInstance();
    const projectId = this.$route.query.projectId;
    const user: User = SystemUtils.loginUser;

    const statusForm = {
      grade: -1,
      type: 0,
      reason: "",
      isPush: 1,
      systemId: "task",
    };
    const info = {
      id: "",
      name: "",
      creator: "",
      createTime: "",
      isPublic: false,
      groupName: "",
      organizationNameCn: "",
      organizationNameEn: "",
      organizationName: "",
      pubPost: "",
      type: "",
      chatGroupId: "",
      tagGroupId: "",
      synopsis: "",
      projectTypeId: "",
      systemId: "",
      beginTime: "",
      endTime: "",
      busNo: "",
      awakeDate: "",
    };
    const status = {
      statusName: "",
    };
    const situation = {
      id: "",
      name: "",
      photo: "",
      projectStatus: "",
      updateDate: "",
    };
    const projectGroup = {
      name: "",
    };
    const projectType = {
      name: "",
    };
    const projectStatistic = {
      completeQuantity: "0",
      incompleteQuantity: "0",
      overdueQuantity: "0",
      noClaimeQuantity: "0",
      todayQuantity: "0",
      overdueFinshQuantity: "0",
      taskQuantity: "1",
    };
    return {
      projectPageService: projectPageService,
      projectService: new ProjectService(),
      statusForm: statusForm,
      projectStatistic,
      info,
      situation,
      projectId: projectId,
      projectStatusList: [],
      projectStatusSelectId: "",
      projectStatusSelectName: "",
      user: user,
      projectGroup,
      projectType,
      messageList: [],
      lamps: [
        { name: "灯1", value: 1 },
        { name: "灯2", value: 2 },
        { name: "灯3", value: 3 },
        { name: "灯4", value: 4 },
      ],
      taskService: new TaskService(),
      ChartTableXData: new Array(),
      ChartTableSData: new Array(),
      ChartTableColor: new Array(),
      statusList: new Array(),
      status: status,
    };
  },
  created() {
    this.initProjectInfo(this.projectId + "");
    // this.initProjectStatistic(this.projectId + "");
    // this.getFinishTaskCount();
  },
  mounted() {
    //  this.initChartTable();
  },
  methods: {
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    subStringText(name: string) {
      if (!name) {
        return "请选择";
      }
      if (name.length > 4) {
        return Utils.subStringText(name, 4);
      } else {
        return name;
      }
    },
    initChartTable() {
      const elEchart = document.querySelector("#projectEchart");
      if (elEchart) {
        elEchart.removeAttribute("_echarts_instance_"); // 移除容器上的 _echarts_instance_ 属性,
        const ec = echarts as any;
        const myChart = ec.init(elEchart);
        // 指定图表的配置项和数据
        const option = {
          grid: {
            left: "10",
            right: "5%",
            bottom: "0",
            top: "5%",
            containLabel: true,
          },

          xAxis: {
            data: this.ChartTableXData,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
          },
          yAxis: {
            type: "value",
            max: function (value: any) {
              // return value.max+(Math.ceil(value.max/5)-value.max%5);
              return value.max + 10;
            },
            min: 0,
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              margin: 15,
            },
          },
          series: [
            {
              type: "bar",
              barWidth: "60%",
              data: this.ChartTableSData,
              itemStyle: {
                normal: {
                  color: function (params: any) {
                    const colors = [
                      "#7195D9",
                      "#D97171",
                      "#E2B27F",
                      "#84D5AE",
                      "#7195D9",
                    ];
                    return colors[params.dataIndex];
                  },
                  barBorderRadius: [8, 8, 0, 0],
                },
              },
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option, true);
      }
    },
    initProjectInfo(id: string) {
      const parmsStatus = {
        id: id,
      };
      let that = this;
      this.projectPageService.getProjectInfoById(parmsStatus).then(
        (r: any) => {
          if (r && r.data && r.data[0]) {
            this.info = r.data[0];
            //状态灯
            this.statusForm.grade = r.data[0].statusLight;
            //状态
            if (r.data[0].projectStatus && r.data[0].projectStatus != "") {
              this.projectStatusSelectId = r.data[0].projectStatus;
              this.getProjectStatus(this.projectId + "");
            }
            this.getProjectGroup(this.projectId + "");
            this.getProjectType(this.info.projectTypeId);
            if (this.info.chatGroupId && this.info.chatGroupId != "") {
              this.getMessageList(this.info.chatGroupId);
            }
          } else {
            // console.log("无项目信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getProjectGroup(id: string) {
      const parmsStatus = {
        projectId: id,
      };
      let that = this;
      this.projectService.getForInforSet(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            // this.projectGroup.name = r.data.projectGroupName;
            this.status.statusName = r.data.projectStatusName;
          } else {
            // console.log("无项目分组信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getProjectType(id: string) {
      const parmsStatus = {
        id: id,
      };
      let that = this;
      this.projectPageService.getProjectType(parmsStatus).then(
        (r: any) => {
          if (r && r.data && r.data[0]) {
            this.projectType = r.data[0];
          } else {
            //console.log("无项目分组信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    initProjectStatistic(id: string) {
      const parmsStatus = {
        projectId: id,
      };
      this.projectPageService.getByIdForOverview(parmsStatus).then(
        (r: any) => {
          if (r && r[0]) {
            this.projectStatistic = r[0];
            // this.status.statusName = r[0].statusName;
          } else {
            //console.log("无项目分组信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getProjectStatus(id: string) {
      const parmsStatus = {
        projectId: id,
      };
      this.projectPageService.getByProjectStatusId(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            this.statusList = r.data;
            let findList = this.statusList.filter((ele: any) => {
              return ele.id == this.projectStatusSelectId;
            });
            if (findList != null && findList.length > 0) {
              this.projectStatusSelectName = findList[0].nameCn;
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    getMessageList(groupId: string) {
      let userId = this.user.id;
      let that = this;
      let thisTime = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
      const params = {
        chatGroupId: groupId,
        isPage: true,
        offset: 1,
        limit: 5,
        firstTime: thisTime,
        userId: this.user.id,
        msgType: 3,
      };
      this.projectPageService.getMessageList(groupId, userId, params).then(
        (r: any) => {
          if (r) {
            //倒序
            let result: any = r;
            this.messageList = result.reverse();
          } else {
            //  console.log("无项目动态信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    bindStatusRadioCheck(ev: any) {
      if (ev && this.statusForm.grade != ev.detail.value) {
        this.statusForm.grade = ev.detail.value;
        const newModel = {
          id: this.projectId,
          setFields: ["statusLight"],
          statusLight: this.statusForm.grade,
        };
        this.updateFieldsById(newModel);
      }
    },
    //更新项目信息
    updateFieldsById(params: any) {
      this.projectPageService.updateFieldsById(params).then(
        (r: any) => {
          if (r) {
            console.log("保存成功");
          } else {
            console.log("暂无数据");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    async openStatusPopover(ev: Event) {
      if (this.projectStatusSelectName == "") {
        Utils.presentToastWarning(
          "本项目还没有创建状态选项，请联系管理人员维护"
        );
        return;
      }
      if (ev) {
        const popover = await popoverController.create({
          component: StatusPopover,
          cssClass: "my-popover-class",
          event: ev,
          translucent: false,
          componentProps: {
            projectId: this.projectId,
            statusId: this.projectStatusSelectId,
          },
        });
        popover.present();
        const { data } = await popover.onDidDismiss();
        if (data && "id" in data) {
          this.projectStatusSelectId = data.id;
          this.projectStatusSelectName = data.name;
          //更新项目状态
          const params = {
            projectId: this.projectId,
            statusId: data.id,
          };
          //选择后不更新，‘发布’时更新
         // this.updateProjectStatus(params);
        }
      }
    },
    //更新项目状态
    updateProjectStatus(params: any) {
      this.projectPageService.updateProjectStatus(params).then(
        (r: any) => {
          if (r) {
            this.getProjectGroup(this.projectId + "");
            console.log("保存成功");
          } else {
            console.log("暂无数据");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    bindContentChange(ev: any) {
      if (ev) {
        this.statusForm.reason = ev.detail.value;
      }
    },
    bindIsPushCheck(ev: any) {
      if (ev) {
        this.statusForm.isPush = ev.detail.checked ? 1 : 0;
      }
    },
    //项目状态发布
    bindStatusPublish() {
      //根据测试需求，按照PC端逻辑处理，任务状态变更理由无需必填 2020-01-05 dinglf
      //根据产品经理要求，变更项目状态，必须填写理由 gaosh 2021-4-19
      if (this.statusForm.reason.trim() == "") {
        Utils.presentToast("请填写项目变更状态理由！");
        return;
      }

      const changeTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      const parms = {
        changeRemark: this.statusForm.reason,
        changeTime: changeTime,
        id: "task@projectstatuschange(" + Utils.getUuid + ")",
        projectId: this.projectId,
        projectStatusId: this.projectStatusSelectId,
        statusLight: this.statusForm.grade,
        statusName: this.projectStatusSelectName,
        systemId: this.statusForm.systemId,
        userId: this.user.id,
        userName: this.user.nameCn,
      };
      this.projectPageService.projectStatusChanges(parms).then(
        (r: any) => {
          if (r) {
            if (this.statusForm.isPush == 1) {
              this.send();
            } else {
              this.initStatusData();
            }
            Utils.presentToast("发布成功！");
          } else {
            console.log("未选择信息");
          }
        },
        (e: any) => {
          Utils.presentToastWarning(e.message);
        }
      );
    },
    //推送到项目
    send() {
      const sendTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let busTag = {
        taskId: "",
        projectId: this.projectId,
        title: this.info.name,
        userColor: "#00a2ae",
        userName: this.user.name,
      };
      if (this.statusForm.reason == "") {
        this.statusForm.reason = "暂无原因";
      }
      let content = {
        msg:
          "项目状态更改为：" +
          this.projectStatusSelectName +
          "；原因：" +
          this.statusForm.reason,
        icon: "ios-alarm",
        msgType: 3,
      };
      const parms = {
        busId: this.projectId,
        busTable: "tt_project",
        busTag: JSON.stringify(busTag),
        chatGroupId: this.info.chatGroupId,
        content: JSON.stringify(content),
        id: "task@message(" + Utils.getUuid + ")",
        isAt: false,
        isList: true,
        messageState: 0,
        messageType: 1,
        sendTime: sendTime,
        sendUserId: this.user.id,
        sendUserName: this.user.name,
        systemId: this.info.systemId,
      };

      this.projectPageService.send(parms).then(
        (r: any) => {
          if (r) {
            if (this.info.chatGroupId && this.info.chatGroupId != "") {
              this.getMessageList(this.info.chatGroupId);
            }
          } else {
            console.log("发布失败");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    initStatusData() {
      this.statusForm.grade = -1;
      this.statusForm.reason = "";
      this.statusForm.isPush = 0;
      this.statusForm.systemId = "task";
    },
    //本周
    bindChartWeek() {
      const now = new Date();
      const d = now.getDay(); //本周第几天
      const weekStart = new Date();
      weekStart.setDate(now.getDate() - d);
      const weekEnd = new Date();
      weekEnd.setDate(now.getDate() + (7 - d));
      console.log(weekStart);
      console.log(weekEnd);
    },
    //本月
    bindChartMonth() {
      const now = new Date();
      const y = now.getFullYear();
      const m = now.getMonth() + 1;
      const month = y + "-" + m;
      console.log(month);
    },
    //获取项目任务完成情况最近7天
    getFinishTaskCount() {
      let userId = this.user.id;
      let that = this;
      const params = {
        projectId: this.projectId,
      };
      this.taskService.getFinishTaskCount(params).then(
        (r: any) => {
          if (r) {
            r.forEach((element: any) => {
              that.ChartTableXData.push(element.localDate.substring(5, 10));
              that.ChartTableSData.push(element.count);
            });
            that.initChartTable();
          } else {
            //  console.log("无项目动态信息");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //查看历史进度
    async historyUpdateHandler() {
      const modal = await modalController.create({
        component: HistoryUpdatedModal,
        cssClass: "task-progress-update-class",
        componentProps: {
          projectId: this.projectId,
        },
      });
      await modal.present();
    },
  },
});
