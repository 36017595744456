import BaseModel from '@/services/base/model/BaseModel';
import Setting from '@/rest/Setting';
export class HmModule {
    name: string;
    group: string;
    id: string;
    pagePath: string;
    type: number;

    constructor(id: string, name: string, type: number, group: string, pagePath: string) {
        this.type = type;
        this.id = id;
        this.name = name;
        this.group = group;
        this.pagePath = pagePath;
    }
}

export class moduleGroup {
    code: string;
    name: string;
    orderno: number;
    defaultconditionpath: string;
    modules: Array<HmModule>;

    constructor(e: any) {
        this.name = e.name;
        this.code = e.code;
        this.defaultconditionpath = e.defaultconditionpath;
        this.orderno = e.orderno;
        this.modules = [];
        if (e.modules && e.modules.length > 0) {
            e.modules.map((m: any) => {
                if (Setting.isSVW) {
                    if (m.id != "task.leader" && m.id != "task.noreation" && m.id != "task.subordinate"
                        && m.type != 2) {
                        this.modules.push(new HmModule(m.id, m.name, m.type, m.groupCode, m.pagePath));
                    }
                } else {
                    this.modules.push(new HmModule(m.id, m.name, m.type, m.groupCode, m.pagePath));
                }
            })
        }

    }
}

export class HmModulePersonal extends BaseModel {
    id: string;
    moduleId?: String;
    conditonId?: String;

    constructor(type: number, id: string) {
        super();
        this.id = this.generateIdNoSys();
        if (type == 1) {
            this.moduleId = id;
        } else {
            this.conditonId = id;
        }
    }
}