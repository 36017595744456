
import {
  modalController,
  IonPage,
  IonList,
  IonItem,
  IonIcon,
  alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import HeaderModal from "../../../../components/modal/components/HeaderModal.vue";
// import SearchBar from "@/components/SearchBar.vue";
import SelectLableDetails from "@/views/task/info/selectlable/selectlabledetails/SelectLableDetails.vue";
import { getInstance } from "@/services/TagLinkService";
import BaseService from "@/services/base/BaseService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "SelectLable",
  components: { IonPage, HeaderModal, IonList, IonItem, IonIcon },
  data() {
    const tagLinkService = getInstance();
    return {
      labelList: new Array(0),
      tagLinkList: new Array(0),
      checkedLabelList: new Array(0),
      tagLinkService: tagLinkService,

      baseService: new BaseService(),
    };
  },
  props: {
    data: Object, //data==> 1.type:创建任务跳转还是编辑任务跳转 2.groupId: 3.busId 4.checkedLabelList //type为create时使用
    //项目文档操作权限
    tagPermission: {
      type: Object,
      required: false,
      default: {
        isHasTagDel: false, //删除标签
        isHasTagPost: false, //创建标签
        isHasTagPut: false, //编辑标签
        isHasTagPutArchive: false, //移到回收站权限/恢复回收站权限
      },
    },
  },
  mounted() {
    if (this.data) {
      if (this.data.checkedLabelList) {
        this.checkedLabelList = this.data.checkedLabelList;
      }
    }
    this.loadData();
  },
  // created() {},
  methods: {
    loadData() {
      if (this.data) {
        //type为create
        if (this.data.type == "create" || this.data.type == "manage") {
          const params = {
            groupId: this.data.groupId,
          };

          this.tagLinkService.getTagList(params).then(
            (r: any) => {
              if (r) {
                this.labelList = r.data;
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        } else if (this.data.type == "edit") {
          //type为edit
          const params = {
            groupId: this.data.groupId,
            busId: this.data.busId,
            busTable: "tt_task",
          };

          this.tagLinkService.getTagListAndLinkList(params).then(
            (r: any) => {
              if (r) {
                this.labelList = r.data.tagList;
                this.tagLinkList = r.data.tagLinkList;
                this.checkedLabelList = this.tagLinkList;
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        } else if (this.data.type == "select") {
          //type为select
          const params = {
            groupId: this.data.groupId,
            busId: this.data.busId,
            busTable: "tt_task",
          };

          this.tagLinkService.getTagListAndLinkList(params).then(
            (r: any) => {
              if (r) {
                this.labelList = r.data.tagList;
                this.tagLinkList = this.checkedLabelList;
              }
            },
            (e: any) => {
              console.log(e);
            }
          );
        }
      }
    },

    isChecked(index: number) {
      if (this.data) {
        if (this.data.type == "create") {
          if (this.checkedLabelList.length != 0) {
            for (
              let position = 0;
              position < this.checkedLabelList.length;
              position++
            ) {
              const element = this.checkedLabelList[position];

              if (this.labelList[index].id == element.id) {
                return true;
              }
            }
            return false;
          } else {
            return false;
          }
        } else if (this.data.type == "edit") {
          if (this.tagLinkList.length != 0) {
            for (
              let position = 0;
              position < this.tagLinkList.length;
              position++
            ) {
              const element = this.tagLinkList[position];

              if (this.labelList[index].id == element.tagId) {
                return true;
              }
            }
            return false;
          } else {
            return false;
          }
        }
      }
    },

    confirm(type: any) {
      //0:关闭，1:取消，2:确定,3:左侧，4：右侧
      for (let index = 0; index < this.checkedLabelList.length; index++) {
        let isDelete = true;
        const element = this.checkedLabelList[index];
        for (let j = 0; j < this.labelList.length; j++) {
          const element1 = this.labelList[j];

          if (element.id == element1.id) {
            isDelete = false;
          }
        }
        if (isDelete) {
          this.checkedLabelList.splice(index, 1);
        }
      }
      if (type == 3) {
        modalController.dismiss({});
      } else if (type == 4) {
        modalController.dismiss({ checkedLabelList: this.checkedLabelList });
      }
    },
    closeModal() {
      for (let index = 0; index < this.checkedLabelList.length; index++) {
        let isDelete = true;
        const element = this.checkedLabelList[index];
        for (let j = 0; j < this.labelList.length; j++) {
          const element1 = this.labelList[j];

          if (element.tagId == element1.id) {
            isDelete = false;
          }
        }
        if (isDelete) {
          this.checkedLabelList.splice(index, 1);
        }
      }

      modalController.dismiss({ checkedLabelList: this.checkedLabelList });
    },
    pickLabel(checked: any, i: number) {
      // this.labelList[i]["checked"] = checked;\

      if (this.data) {
        if (checked) {
          if (this.data.type == "create") {
            this.checkedLabelList.push(this.labelList[i]);
          } else {
            let findId = this.labelList[i].id;
            let findList = this.checkedLabelList.filter((ele: any) => {
              return ele.tagId == findId;
            });
            if (findList.length == 0) {
              this.addTagLink(i);
            }
          }
        } else {
          if (this.data.type == "create") {
            for (let index = 0; index < this.checkedLabelList.length; index++) {
              const element = this.checkedLabelList[index];

              if (this.labelList[i].id == element.id) {
                this.checkedLabelList.splice(index, 1);
              }
            }
          } else {
            this.deleteTagLink(i);
          }
        }
      }
    },

    //添加选中tag
    addTagLink(i: number) {
      const modelJson: any = this.baseService.newModel("task", "tag");
      modelJson.systemId = "task";
      if (this.data) {
        modelJson.groupId = this.data.groupId;
        modelJson.busId = this.data.busId;
      }

      modelJson.busTable = "tt_task";
      modelJson.isDeletedGroup = false;
      modelJson.tagColor = this.labelList[i].color;
      modelJson.tagName = this.labelList[i].name;
      modelJson.tagId = this.labelList[i].id;

      this.tagLinkService.addTagLinks(modelJson).then(
        (r: any) => {
          if (r) {
            this.checkedLabelList.push(r.data);
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },

    //删除选中tag
    deleteTagLink(i: number) {
      if (this.data) {
        const params = {
          groupId: this.data.groupId,
          busId: this.data.busId,
          busTable: "tt_task",
          tagId: this.labelList[i].id,
        };
        this.tagLinkService.clearTagLinks(params).then(
          (r: any) => {
            if (r) {
              const index = this.checkedLabelList.findIndex((element) => {
                return this.labelList[i].id == element.tagId;
              });
              if (index > -1) {
                this.checkedLabelList.splice(index, 1);
              }
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      }
    },

    async labelDetail(type: any, item: any, i: any) {
      if (this.data) {
        const modal = await modalController.create({
          component: SelectLableDetails,
          // component: LabeDetailPage,
          cssClass: "notice-modal-class",
          backdropDismiss: true,
          componentProps: {
            datas: {
              item: item,
              type: type,
              labelList: this.labelList,
              i: i,
              groupId: this.data.groupId,
            },
          },
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
          if (type == "add") {
            data.checked = false;
            // this.labelList.unshift(data.tag);
            this.labelList.push(data.tag);
          } else {
            if (data.isDelete) {
              this.labelList = data.labelList;
            } else {
              this.labelList = this.labelList.map((item) =>
                item.id === data.item.id ? data.item : item
              );
            }
          }
        }
      }
    },

    async delete1(type: any, item: any, i: any) {
      const alert = await alertController.create({
        header: "删除标签",
        message: "确定要删除这个标签吗?",
        mode: "ios",
        buttons: [
          { text: "取消" },
          {
            text: "确定",
            handler: () => {
              if (item) {
                const params = {
                  id: item.id,
                };
                this.tagLinkService.deleteTag(params).then(
                  (r: any) => {
                    if (r) {
                      if (!r.data) {
                        Utils.presentToastWarning("删除标签失败，请检查");
                        return;
                      }
                      if (this.labelList.length > 0) {
                        this.labelList.splice(i, 1);
                      }
                    }
                  },
                  (e: any) => {
                    console.log(e);
                  }
                );
              }
            },
          },
        ],
      });

      await alert.present();
    },
  },
});
