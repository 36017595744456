
import { OrganizationApi } from '@/api/organization/OrganizationApi'
import UserInfo from './UserInfo';

/**
 * 选择执行人及选择参与人
 */
export class SelectExecutorTeamService {

    private static organizationApi = new OrganizationApi();

    public getOrganizationTeamList(params = {}): any {
        return new Promise((resolve, reject) => {
            SelectExecutorTeamService.organizationApi.getChildOrgsList(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //获取全部的组织机构
    public getAllOrganizationTeamList(): any {
        return new Promise((resolve, reject) => {
            SelectExecutorTeamService.organizationApi.getAllChildOrgsList().then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //获取组织结构单个部门人员列表
    public getOrganizationTeamStaffList({
        tsOrgId = 0,
        name = "",
    } = {}): any {
        return new Promise((resolve, reject) => {
            SelectExecutorTeamService.organizationApi.getDepartmentStaffList({
                tsOrgId,
                name,
            }).then(async (res: any) => {
                resolve(this.getUserList(res.data));
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    getUserList(data: any) {
        
        const userList = new Array();
        
        for (let index = 0; index < data.data.rows.length; index++) {
            const element = data.data.rows[index];
            const user = new UserInfo();
            user.id = element.tsUser.id;
            user.name = element.tsUser.name;
            user.pernr = element.tsUser.pernr;
            user.photo = element.tsUser.photo;
            user.userId = element.userId;
            //user.department = element.tsUser.department;
            user.department =element.tsUser.partNameCn==null?element.tsUser.department:element.tsUser.partNameCn;
            userList.push(user);
        }
        return userList;
    }

}

export function getInstance1() {
    return new SelectExecutorTeamService();
}