
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import ProjectQueryCondition from "@/services/project/models/ProjectQueryCondition";
import { getInstance } from "@/services/project/projectService";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ProjectConditionList",
  components: {
    ProjectCss,
  },
  props: {
    conditionId: String,
    refresh: Boolean,
  },
  watch: {
    conditionId(newValue: any, oldValue: any) {
      this.init();
    },
    refresh() {
      if (this.refresh) {
        this.init();
      }
    },
  },
  data() {
    const _projectService = getInstance();
    const _condtionService = new SearchPanelService();
    const router = useRouter();
    return {
      router: router,
      projectLists: [],
      projectService: _projectService,
      conditionService: _condtionService,
      showNoDate: false,
      parameters: {
        limit: 15,
        offset: 1,
        projectName: "",
        createTimeCB: "",
        createTimeCE: "",
        endTimeCB: "",
        endTimeEE: "",
        projectLight: "",
        systemId: "",
        partakeType: "",
        qcreateIds: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //根据conditionId，获取条件参数
      if (this.conditionId) {
        this.conditionService.getById(this.conditionId).then((res: any) => {
          //解析条件
          if (res) {
            const queryC: ProjectQueryCondition = new ProjectQueryCondition(
              res
            );
            this.parameters = Object.assign(this.parameters, queryC);
            this.getListRequest((res: any) => {
              this.projectLists = res;
              this.showNoDate = this.parameters.offset == 1 && res.length == 0;
            });
          } else {
            this.showNoDate = true;
          }
        });
      }
    },
    itemClick(projectInfo: any) {
      this.router.push({
        path: "/ProjectPage",
        query: {
          projectId: projectInfo.id,
          projectName: projectInfo.name,
          isStart: projectInfo.isStart,
          chatGroupId: projectInfo.chatGroupId,
        },
      });
    },
    nextPage(event: any) {
      this.parameters.offset++;
      this.getListRequest((result: any) => {
        this.projectLists = this.projectLists.concat(result);
        this.showNoDate =
          this.parameters.offset == 1 && this.projectLists.length == 0;
        event.target.complete();
        if (result) {
          if (result == null || result.length <= this.parameters.limit) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.parameters.offset = 1;
      this.getListRequest((res: any) => {
        this.projectLists = res;
        this.showNoDate =
          this.parameters.offset == 1 && this.projectLists.length == 0;
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.projectService.getProjectList(this.parameters).then((res: any) => {
        callback(res.data);
      });
    },
  },
});
