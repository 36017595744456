/**
 * ProjectPage 项目详情-概览 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectOverViewApi extends TaskBaseService {

    constructor() {
        super("projectStatusChanges");
    }
    // 项目状态变更 /v1/projectStatusChanges 
    public projectStatusChanges(params: any): Promise<any> {
       const path = '';
       return this.request(path,this.rest.METHOD_POST, params);       
    }

}