
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import SubscribeCard from "./SubscribeCard.vue";
import { CalendarSubscribeService } from "@/services/calendar/CalendarSubscribeService";
import Utils from "@/utils/Utils";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
export default defineComponent({
  name: "SelectSubscribeUserList",
  components: {
    HeaderModal,
    SearchBar,
    SubscribeCard,
  },
  data() {
    return {
      mySubscribeService: new CalendarSubscribeService(),
      canlendarList: new Array(0),
      searchParams: {
        limit: 10,
        offset: 1,
        name: "",
      },
      isReflush: false,
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    initData() {
      this.resetPage();
    },
    //右上角确定
    closeHandler(type: any) {
      modalController.dismiss({ isReflush: this.isReflush });
    },
    //是否日程显示
    onCheck(res: any) {
      if (res && res.item) {
        const item = res.item;
        //保存数据库，更新字段
        this.mySubscribeService
          .updateFieldsById({
            id: item.subscribeId,
            isShow: res.checked,
            setFields: ["isShow"],
          })
          .then((res: any) => {
            if (res) {
              this.isReflush = true;
              // console.log("更新成功"+item.calName);
            }
          });
      }
    },
    //重新从第一页获取
    resetPage(event?: any) {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        this.canlendarList = res;
        if (event) {
          event.target.complete();
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        event.target.complete();
        if (result) {
          if (result.length <= 0) {
            Utils.presentToast("已无更多数据");
          } else {
            this.canlendarList = this.canlendarList.concat(result);
          }
        }
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    doRefresh(event: any) {
      this.resetPage(event);
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    //获取我订阅的日历
    getListRequest(callback: any) {
      this.mySubscribeService
        .getMySubscribeList(this.searchParams)
        .then((res: any) => {
          if (res && res.data) {
            callback(res.data);
          }
        });
    },
  },
});
