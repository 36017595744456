/**
 * 任务来源
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 
 */
export class TaskSystemsApi extends TaskBaseService {

    constructor() {
        super("systems");
    }

    /**
     * 获取任务-项目来源
     */
    public getSelectAll(): Promise < any > {
        const path = 'selectAll';
        return this.request(path, this.rest.METHOD_GET, null);
    }

}