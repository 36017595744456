import { EUserWorkType, TaskLinkContentType } from '@/models/task/Enum';
import SystemUtils from '@/utils/SystemUtils';
import { Task } from './Task';
import { TaskPermission } from './TaskPermission';

export class TaskModel {

    project?: any; //项目
    task: Task; //任务
    subTaskList: any[] = [];//子任务

    taskRoles: Array<string> = [];//用户角色
    permission: TaskPermission = new TaskPermission();//任务权限
    taskLinkProjectList: any[] = [];//关联项目集合
    taskLinkTaskList: any[] = [];//关联任务集合
    taskLinkFileList: any[] = [];//关联文件集合
    taskLinkScheduleList: any[] = [];//关联日程集合
    taskOnRelyList: any[]=[];//任务依赖集合
    
    taskLinkList: any[]=[];
    taskUserList: any[]=[];

    isTaskDeleted:boolean=false;

    constructor(item?: any) {
        if (!item) {
            this.task = new Task();
            return;
        }
        this.task = new Task(item.task);
        this.project = item.project;
        //|| this.task.isDeletedProject 
        this.isTaskDeleted=this.task.isDeleted || this.task.isDeletedTaskGroup || this.task.isDeletedTaskStage?true:false;
        //获取父任务信息
        const title = item.parentTitles;
        if (title) {
            const _index = title.lastIndexOf(",");
            if (_index != -1) {
                this.task.parentTaskId = this.task.parentId;
                this.task.parentTaskName = title.substring(_index + 1, title.length - 1);
            }
        }
        this.taskLinkScheduleList= item.taskLinkScheduleList;
        
        //关联任务
        this.taskOnRelyList=item.taskOnRelyList;
      
        //子任务
        this.subTaskList=item.subTaskList;
        if(item.subTaskList){
            for (let i = 0, len = item.subTaskList.length; i < len; i++) {
                item.subTaskList[i].stausPermission=false;
                item.subTaskList[i].deletePermission=false;
            }
        }
        //关联内容
        this.taskLinkList=item.taskLinkList;
        if (item.taskLinkList) {  
            for (let i = 0, len = item.taskLinkList.length; i < len; i++) {
                const link = item.taskLinkList[i];
                if (link.type == "linked") {
                    switch (link.targetType) {
                        case TaskLinkContentType.Project:
                            this.taskLinkProjectList.push(link);
                            break;
                        case TaskLinkContentType.Task:
                            this.taskLinkTaskList.push(link);
                            break;
                        case TaskLinkContentType.Document:
                            this.taskLinkFileList.push(link);
                            break;
                    }
                }
            }

        }

        this.taskUserList=item.taskUserList;
        //参与人&执行人
        if (item.taskUserList && item.taskUserList.length > 0) {
            for (let i = 0, len = item.taskUserList.length; i < len; i++) {
                const user = item.taskUserList[i];
                if (user.workType == EUserWorkType.Executor) {
                    this.task.executorUsers.push(user);
                } else {
                    this.task.partUsers.push(user);
                }
            }
        }

        //当前用角色
        this.taskRoles = this.getTaskRoleIdList(item.task.creatorId, item.taskUserList);
    }

    public getTaskRoleIdList(creatorId: string, taskUserList: any): Array<string> {
        let userId: string = SystemUtils.loginUser.id;
        let roleIdList: Array<string> = [];
        if (creatorId == userId) {
            roleIdList.push("task.creator");
        }
        if (taskUserList != null && taskUserList.length > 0) {
            for (let taskUser of taskUserList) {
                if (taskUser.workType == EUserWorkType.Executor && taskUser.userId == userId) {
                    roleIdList.push("task.executor");
                } else if (taskUser.workType == EUserWorkType.Party && taskUser.userId == userId) {
                    roleIdList.push("task.actor");
                }
            }
        }
        return roleIdList;
    }
}