
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { popoverController } from "@ionic/vue";
import { getInstance } from "@/services/projectPageService";

export default defineComponent({
  name: "StatusPopover",
  props: {
    projectId: String,
    statusId: String,
  },
  setup(props) {
    const { statusId, projectId } = toRefs(props);

    const statusList = reactive({
      data: new Array<any>(),
    });

    const projectPageService = getInstance();

    const initProjectStatus = (id: string) => {
      const parmsStatus = {
        projectId: id,
      };
      projectPageService.getByProjectStatusId(parmsStatus).then(
        (r: any) => {
          if (r && r.data) {
            statusList.data = r.data;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    };

    onMounted(() => {
      if (projectId != undefined && projectId.value != undefined) {
        initProjectStatus(projectId.value);
      }
    });

    //职位选择事件
    const bindStatusChange = (e: any) => {
      let statusName = "";
      statusList.data.forEach((item: any) => {
        if (e == item.id) {
          statusName = item.nameCn;
        }
      });
      popoverController.dismiss({
        dismissed: true,
        id: e,
        name: statusName,
      });
    };
    const getHeight = () => {
      let point = 0.8;
      return (
        document.body.clientHeight * ((document.body.clientHeight / 812) * point) + "px"
      );
    };

    return { statusList, statusId, bindStatusChange, getHeight };
  },
});
