
import { modalController, IonPage } from "@ionic/vue";
import Utils from "@/utils/Utils";
import { useRouter } from "vue-router";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { ScheduleService } from "@/services/schedule/ScheduleService";
import { defineComponent } from "vue";
import moment from "moment";
import { FileHelper } from "@/utils/FileHelper";
import FileCard from "@/components/ListCard/FileCard.vue";
import Setting from "@/rest/Setting";
export default defineComponent({
  name: "TaskInfoModel",
  props: {
    index: Number,
    list: Object,
    modelTitle: String,
    folderId: String,
    isIndependentTask: Boolean,
    linkProjectName: String,
  },
  components: {
    HeaderModal,
    IonPage,
    FileCard,
  },
  data() {
    const router = useRouter();
    const fileHelper = new FileHelper();
    return {
      isLookOver: false,
      router: router,
      scheduleService: new ScheduleService(),

      fileHelper: fileHelper,

      indexType: "",
    };
  },

  //   created() {

  //   },
  mounted(){},
  methods: {
    closeModel(): void {
      modalController.dismiss();
    },

    formatDate(time: any) {
      return moment(time).format("YYYY-MM-DD");
    },

    //浏览
    previewFile(r: any) {
      if (r) {
        if (r.isPic) {
          let extName = r.name.substring(r.name.lastIndexOf(".") + 1);
          this.fileHelper.downloadOriginal(r.fileId, extName, 2).then(
            (r: any) => {
              this.fileHelper.previewVant([r], 0);
            },
            (e: any) => {
              Utils.presentToastWarning("查看原图失败");
            }
          );
        } else {
          this.fileHelper.previewNativeFile(r.fileId, r.name);
        }
      }
    },

    downloadHandler(obj: any) {
      if (obj.fileId) {
        this.downloadFile(obj);
      }
    },
    //下载
    downloadFile(obj: any) {
      let fileId = obj.fileId;
      let fileName = obj.name;
      if (fileId) {
        if (this.list) {
          const index = this.list.findIndex((f: any) => {
            return f.mainFileId == fileId;
          });
          if (index < 0) {
            return;
          }
          let doc = this.list[index];
          doc.isDownloadOver = false;
          this.fileHelper.downloadNative(fileId, fileName).then(
            (result: any) => {
              let flag = false;
              if (result) {
                if ("nativeURL" in result && result.nativeURL != "") {
                  doc.isDownloadOver = true;
                  doc.downloadFlag = true;
                  Utils.presentToastSuccess("下载完成");
                  flag = true;
                  if (obj.previewImage != "") {
                    this.previewFile(obj);
                  }
                }
              }
              if (!flag) {
                doc.isDownloadOver = true;
                Utils.presentToastWarning("下载失败");
              }
            },
            (e: any) => {
              doc.isDownloadOver = true;
              Utils.presentToastWarning("下载失败");
            }
          );
        }
      }
    },

    openTaskInfo(id: string) {
      this.isLookOver = false;
      this.router.push({
        path: "/task-page",
        query: {
          id: id,
        },
      });
      this.closeModel();
    },
    convertToPriorityDesc(priority: number) {
      if (priority == 0) {
        return "较低"; //"较低";
      } else if (priority == 1) {
        return "普通"; //"普通";
      } else if (priority == 2) {
        return "紧急"; // "紧急";
      } else if (priority == 3) {
        return "非常紧急"; //"非常紧急";
      }
    },
    convertToDifficultyDesc(difficulty: number) {
      if (difficulty == 1) {
        return "简单"; //"简单";
      } else if (difficulty == 2) {
        return "一般"; // "一般";
      } else if (difficulty == 3) {
        return "复杂"; //"复杂";
      }
    },
    //关联内容点击事件
    linkHandler(type: number, item: any) {
      if (type == 1) {
        this.openProjectInfo(item.targetProjectId, item.projectName); //跳转项目详情页
      } else if (type == 2) {
        this.openTaskInfo(item.targetId); //跳转任务详情页
      } else if (type == 3) {
        //预览或下载文件
        //this.downloadFile(item.fileId, item.name);
      } else if (type == 4) {
        //跳日程详情
        // this.router.push({
        //   name: "detail-schedule-feedback",
        //   params: {
        //     info: JSON.stringify(item),
        //   },
        // });
        this.openScheduleInfo(item);
      }

      this.closeModel();
    },

    openProjectInfo(id: string, name: string) {
      this.router.push({
        path: "/ProjectPage",
        query: {
          projectId: id,
          projectName: name,
          folderId: this.folderId,
        },
      });
    },

    openScheduleInfo(item: any) {
      let params = {
        scheduleId: item.id,
        scheduleTimeId: item.scheduleTimeId,
      };
      this.scheduleService.getLimitDetail(params).then((result: any) => {
        if (result) {
          this.router.push({
            path: "/schedule/detail/feedback",
            query: {
              id: item.id,
              scheduleTimeId: item.scheduleTimeId,
              showCalendar: "false",
            },
          });
        } else {
          Utils.presentToastWarning("无权限查看此页面");
        }
      });
    },
  },
});
