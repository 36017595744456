

import { ObjectiveApi } from '@/api/okr/objectiveapi';
import BaseService from '@/services/base/BaseService';
import { Task } from '../task/model/Task';
import Bus from "@/utils/bus";

export class ObjectiveService extends BaseService {

    private objectiveApi = new ObjectiveApi();

    //级别列表
    public levelList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.levelList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //可用周期列表
    public cycleList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.cycleList().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //可用维度列表
    public categoryList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.categoryList().then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //新增目标
    public addObjective(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.addObjective(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //编辑目标
    public editObjective(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.editObjective(param).then(async (res: any) => {
                Bus.$emit("data_update_listener", {type:"target"});
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //目标详情
    public objectiveDetails(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.objectiveDetails(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //目标列表
    public objectiveList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.objectiveList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //目标关联任务列表
    public objectivelinkTaskList(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.objectivelinkTaskList(param).then(async (res: any) => {
                // if (param.sourceType === 2) {
                //     resolve([res.data, this.convertTask(res.data)]);
                // } else
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //批量添加目标关联任务
    public objectiveAddLinkTaskList(param: any, sourceId?: string): Promise<any> {
        // if (sourceId)
        //     param = { links: param, sourceId: sourceId }
        return new Promise((resolve, reject) => {
            this.objectiveApi.objectiveAddLinkTaskList(param, sourceId).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //更新进度
    public progressInsert(id: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.progressInsert(id).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //取消关联任务
    public cancelKeyTask(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.cancelKeyTask(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    // // okr任务-->任务   数据类型转换
    // private convertTask(krLinkTasks: Array<Task>) {
    //     let taskList = new Array<Task>(0);
    //     krLinkTasks.forEach((okrTask: any) => {
    //         console.log(okrTask.taskId)
    //         let task = new Task();
    //         task.id = okrTask.taskId;
    //         task.priority = okrTask.priority;
    //         task.isFinished = okrTask.isFinished;
    //         task.name = okrTask.name;
    //         task.projectName = okrTask.projectName;
    //         task.systemName = okrTask.systemName;
    //         task.taskUser = okrTask.taskUser;
    //         taskList.push(task);
    //     })
    //     return taskList;
    // }

    //结束目标
    public updateFieldsById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.updateFieldsById(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    /**
     * 根据周期ID获取目标树结构
     * @param cycleId
     */
    public getObjectiveTree(cycleId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.getObjectiveTree(cycleId).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public removeOrRestoreObjective(id: string, isDelete: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.removeOrRestoreObjective(id, isDelete).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public delObjective(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.delObjective({id: id}).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 获取回收站OKR目标列表
     * @param params 参数信息
     * @returns 
     */
    public getRecycleObjectiveList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.objectiveApi.getRecycleObjectiveList(params).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            })
        })
    }
}