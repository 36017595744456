<template>
    <div class="ganttWraper">
        <div class="filterWrap"  style="height:40px">
            <span class='gantt-unit-view'><!--不同单位视图切换-->
                <ion-label>单位：</ion-label>
                <ion-select ref="ionSelect" value='month' placeholder="单位" interface="popover" @ionChange='handleUnitChange($event)'>
                    <ion-select-option v-for='(item, index) in unitData' :key='index' :value='item.value'>{{item.label}}</ion-select-option>
                </ion-select>
            </span>
            <div class="areaWrap"> <!--不同区域切换-->
                <span class="title"></span>
                <span class="itemWrap">
                    <span class="gridArea" :class="{'active': curIndex == index}" 
                    v-for="(item, index) in areaData" :key='index'
                    @click="changeArea(item,index)">{{item.label}}</span>
                </span>
            </div>
        </div>
        <!-- 甘特区域 -->
        <div class="ganttArea" style="height: calc( 100% - 40px )">
            <div style="height: 100%;position:relative;"  :style="curIndex == 0 ? 'width: 2380px' : 'width: 100%'">
                <div style="height: 50px;width:100%;position: absolute;top: 0;left:0;background: transparent;z-index:99999;" v-if="curIndex == 0"></div>
                <bingo-gantt ref="gantt" :type="ganttType" :planId="planId" :tasks='taskData' :milestones="relationPlanMilestone" 
                :attentionTasks="attentionTasks" :calendar="true" :calendarGroup="calendarGroup" :moreMarks="true"
                @beforeInit="beforeInit" @onDataConvert="dataConvert" @after-load-data='refreshSectionColor'></bingo-gantt>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from "vue"; //
    import { IonLabel, IonSelect, IonSelectOption } from "@ionic/vue";  
    import bingoGantt from './gantt/gantt.vue'
    import { DashboardService } from "@/services/dashboardService";
    import Utils from "@/utils/Utils";
    export default defineComponent({
        name: "task",
        components: {
            bingoGantt,
            IonLabel,
            IonSelect,
            IonSelectOption
        },
        props:{},
        data() {
            return {
                api: new DashboardService(),
                gantt:{},
                ganttType: 'task',
                id: 7994,
                attentionId: 0,
                projectId: 0,
                taskData:{
                    data:[],
                    link:[]
                },
                relationPlanMilestone:{},
                attrentionMilestone:{},
                attentionTasks:[],
                unitData:[
                    { label: '天', value: 'day' },
                    { label: '周', value: 'week' },
                    { label: '月', value: 'month' },
                    { label: '年', value: 'year' }
                ],
                areaData:[
                    {label: '列表区域', value: 'left'},
                    {label: '甘特图区域', value: 'right'}
                ],
                currentView: 'left',
                currentUnit: 'month',
                curIndex: 1,
                planId:0,
                calendarGroup: 0,
            };
        },
        created(){
            this.id = Number(this.$route.query.projectId);
            this.getData();
        },
        methods: {
            // 获取数据源
            async getData() {
                // 获取getPlanByProjectId
                this.api.getMainPlanByProjectId({id:this.id}).then(planData => {
                    // console.log(planData.data.data,'主计划信息---task组件')
                    if(planData.data.data){
                        this.planId = planData.data.data.plancenterId;
                        this.calendarGroup = planData.data.data.calendarGroup;
                        this.api.getMainTask(this.planId).then(res => {
                            // console.log(res.data,'任务数据---task组件')
                            if(res.data.data){
                                this.taskData = res.data.data;
                                // console.log(res.data.data,'this.taskData');

                            }else{
                                this.taskData = {
                                    data:[],
                                    link:[]
                                }
                                // Utils.presentToast("没有相关任务数据！");
                                this.changeArea({label: '甘特图区域', value: 'right'},1)
                            }
                            
                        });
                        this.attentionId = planData.data.data.attentionId;
                        this.api.getAttentiontasks({planId: this.attentionId}).then(response => {
                            this.attentionTasks = response.data.data;
                        });
                        // this.relationId = planData.data.data.id;
                        this.api.getRelationStone(this.planId).then(response => {
                            this.relationPlanMilestone = response.data.data;
                            console.log(this.relationPlanMilestone,'this.relationPlanMilestone')
                        });
                    }else{
                        Utils.presentToast("没有相关联计划数据！");
                        this.changeArea({label: '甘特图区域', value: 'right'},1)
                    }
                    
                })
            },
            //组装节点路径
            composeNodePath (task) {
                // let attentionName = task.$wbs + ' ' + task.text;
                let attentionName = task.text;
                if (task.parent != 0) {
                    let curTask = task;
                    while (curTask.parent != 0) {
                        curTask = this.gantt.getTask(curTask.parent);
                        let curPath = curTask.text + ' / ' + attentionName;
                        attentionName = curPath;
                    }
                }
                return attentionName;
            },
            beforeInit(gantt){
                this.gantt = gantt;
                this.registerEvents();
            },
            // 注册事件
            registerEvents() {
                const gantt = this.gantt;
                gantt.attachEvent('onBeforeGanttReady', () => {
                    this.setConfigLayout(gantt.config.grid_width || 1);
                });
                gantt.attachEvent('onGridResizeEnd', (oldWidth, newWidth) => {
                    if (newWidth <= 2) {
                        this.currentView = 'right';
                    } else if (newWidth > this.$el.offsetWidth - 5) {
                        this.currentView = 'left';
                    } else {
                        this.currentView = 'default';
                    }
                    return true;
                });
            },
            // 设置布局
            setConfigLayout(gridWidth) {
                const gantt = this.gantt;
                gantt.config.layout = {
                    css: 'gantt_container',
                    cols: [
                        {
                            width: gridWidth,
                            rows: [
                                { view: 'grid', scrollX: 'gridScroll', scrollable: true, scrollY: 'scrollVer' },
                                { view: 'scrollbar', id: 'gridScroll', group:'horizontal' }
                            ]
                        },
                        { resizer: true, width: 1 },
                        {
                            rows: [
                                { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
                                { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' }
                            ]
                        },
                        { view: 'scrollbar', id: 'scrollVer' }
                    ]
                };
            },
            dataConvert (task) {
                task.attentionNode = task.attentionNode.split(',').map(Number); // 多选转为数组
            },
            handleUnitChange(event){
                this.currentUnit = event.detail.value;
                this.$refs.gantt.handleUnitViewChange(this.currentUnit);
            },
            changeArea(item,index){
                this.currentView = item.value;
                this.curIndex = index;
                console.log(item.value)
                this.handleLayoutChange(this.currentView);
            },
            // 切换布局
            handleLayoutChange (value) {
                const gantt = this.gantt;
                const element = this.$el.querySelector('div[name="gantt"]');
                switch (value) {
                    case 'left': {
                        gantt.config.grid_width = '2380px';
                        break;
                    }
                    case 'default': {
                        gantt.config.grid_width = null;
                        break;
                    }
                    case 'right': {
                        gantt.config.grid_width = 1;
                        break;
                    }
                }
                this.gantt._reinit(element);
            },
            // 刷新相同股的任务同一种颜色标识
            refreshSectionColor () {
                let gantt = this.gantt;
                let visibleTasks = [];
                gantt.eachTask(task => {
                    let visible = gantt.isTaskVisible(task.id);
                    if (visible) {
                        visibleTasks.push(task);
                    }
                });
                if (visibleTasks.length > 0) {
                    visibleTasks.forEach((item, index) => {
                        if (index == 0) {
                            item.isSectionCss = false;
                        } else {
                            let preTask = visibleTasks[index - 1];
                            item.isSectionCss = this.isSameSection(preTask, item) ? preTask.isSectionCss : !preTask.isSectionCss;
                        }
                    });
                }
                gantt.refreshData();
            },
            // 比对两个任务是否属于同一个股
            isSameSection (task1, task2) {
                let headers1 = [],
                    headers2 = [];
                task1.headers.forEach(item => {
                    if(headers1.indexOf(item.sectionId)===-1){
                        headers1.push(item.sectionId);
                    }
                });
                task2.headers.forEach(item => {
                    if(headers2.indexOf(item.sectionId)===-1){
                        headers2.push(item.sectionId);
                    }
                });
                let isSame = true;
                if (headers1.length != headers2.length) {
                    isSame = false;
                }
                headers1.forEach((sec1) => {
                    if(headers2.indexOf(sec1) == -1){
                        isSame = false;
                    }
                });
                headers2.forEach((sec2) => {
                    if(headers1.indexOf(sec2) == -1){
                        isSame = false;
                    }
                });
                return isSame;
            },
        }
    });
</script>

<style lang="scss" scoped>
    .ganttWraper{
        width: 100%;
        height: 100%;
        overflow-x: auto
    }
    .filterWrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        background-color: #fff;
    }
    .gantt-unit-view{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .itemWrap{
        border: 1px solid #e2e2e2;
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
        box-sizing: border-box;
        display: flex;
    }
    .itemWrap .gridArea{
        display: inline-block;
        height: 28px;
        line-height: 28px;
        padding: 0 5px;
    }
    .itemWrap span:nth-child(1){
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }
    .itemWrap span:nth-child(2){
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
    }
    .active {
        background: #4981EB;
        // border: 1px solid #4981EB;
        color: #fff;
    }
    .ganttArea{
        width: 100%;
        overflow-x: scroll;
        border: 1px solid #cecece;
    }
</style>