/**
 * 用户项目分组与项目关系控制器
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectGroupToProjectApi extends TaskBaseService {

    constructor() {
        super("projectGroupToProjects");
    }
    //根据用户Id、项目Id、用户项目分组Id获取项目与用户分组关系列表
    public updateProjectGroupToProjectByUser(params: any): Promise<any> {
       const path = "updateProjectGroupToProjectByUser?projectId=" + params.projectId + "&&userId=" + params.userId + "&&projectGroupId=" + params.projectGroupId+ "&&systemId=" + params.systemId;
       return this.request(path,this.rest.METHOD_POST, null);       
    }

}