

import { TaskExecuteApi } from '@/api/task/TaskExecuteApi';
import BaseService from '../base/BaseService';
/**
 * 项目，任务模块：任务分组
 */
export class TaskExecuteService extends BaseService {

    private taskExecuteApi = new TaskExecuteApi();
   
     /**
     * 任务执行情况列表
     * @param param 
     */
    public getTaskExecuteList(param: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.taskExecuteApi.getTaskExecuteList(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //新建执行情况
    public addTaskExecute(parms: any): any {
        return new Promise((resolve, reject) => {
            this.taskExecuteApi.addTaskExecute(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


}