<template>
    <ion-page>
        <Header type="0" title="计划"></Header>
            <ion-segment
                class="segment plan"
                v-model="selSeg"
                mode="md"
                @ionChange="segmentChanged($event)">
            <ion-segment-button
                    class="segment_button"
                    v-for="(indicator, index) in indicators"
                    :value="index"
                    :key="index">
                {{ indicator }}
                <div class="indicator" v-if="selSeg == index"></div>
                <div class="noindicator" v-if="selSeg != index"></div>
            </ion-segment-button>
        </ion-segment>
        <div class="content">
            <div class="planInfo" v-show="mainPlanName">
                <div class="mainTitle">
                    <span>
                        基本信息
                    </span>
                </div>
                <div class="relationPlan">
                    名称：
                    <span class="status-light" :class="state"></span>
                    <span class="name">{{mainPlanName}}</span>
                </div>
                <div class="relationPlan"  v-show="selSeg == 0">
                    SOP日期：{{sopDate.slice(0,10)}}
                </div>
                <div class="relationPlan" v-show="selSeg == 1">
                    关联计划：<span class="name">{{attentionName}}</span>
                </div>
            </div>
            <div class="planInfo" v-show="!mainPlanName">
                <div class="planName">
                    暂无主计划信息
                </div>
                <div class="relationPlan">
                   
                </div>
            </div>
            <div v-if="selSeg == 0">
                <div class="milestoneContent">
                    <div class="mainTitle">
                        <span>
                            主里程碑计划
                        </span>
                    </div>
                    <div class="haha" style="height: calc( 100% - 30px )">
                        <milestone></milestone>
                    </div>
                </div>
            </div>
            <div v-if="selSeg == 1">
                <div class="milestoneContent">
                    <div class="mainTitle">
                        <span>
                            关联里程碑
                        </span>
                    </div>
                    <div class="haha" style="height: calc( 100% - 30px )">
                        <relationMilestone></relationMilestone>
                    </div>
                </div>
                <div class="planContent">
                    <div class="mainTitle">
                        <span>
                            计划信息
                        </span>
                    </div>
                    <div style="height: calc( 100% - 30px )">
                        <task></task>
                    </div>
                </div>
            </div>
            
        </div>
    </ion-page>
</template>

<script>
    import { defineComponent } from "vue";
    import Header from '@/components/Header.vue';
    import task from './component/task.vue'
    import milestone from './component/milestone.vue'
    import relationMilestone from './component/relation-milestone.vue'
    import { DashboardService } from "@/services/dashboardService";
    export default defineComponent({
        name: "plan",
        components: {
            Header,
            task,
            milestone,
            relationMilestone
        },
        data(){
            return{
                api: new DashboardService(),
                indicators:["主里程碑", "主计划"],
                selSeg: 0,
                // planId: 0,
                mainPlanName: '',
                attentionName: '',
                state: '',
                sopDate: '',
                projectId: 0,
            }
        },
        mounted(){
            this.projectId = this.$route.query.projectId;
            this.getMainMileStone(this.projectId);
        },  
        methods:{
            getMainMileStone(projectId){
                this.api.getMainMileStone({id:projectId}).then(planDate => {
                    // console.log(planDate,'api')
                    if(planDate.data.data){
                        this.mainPlanName = planDate.data.data.planName;
                        // this.attentionName = planDate.data.data.attentionName || '无';
                        this.state = planDate.data.data.state;
                        this.sopDate = planDate.data.data.sopDate;
                    }
                })
            },
            getMainPlanByProjectId(projectId){
                this.api.getMainPlanByProjectId({id:projectId}).then(planDate => {
                    console.log(planDate,'api')
                    if(planDate.data.data){
                        this.mainPlanName = planDate.data.data.planName;
                        this.attentionName = planDate.data.data.relation || '无';
                        this.state = planDate.data.data.state;
                        // this.sopDate = planDate.data.data.sopDate;
                    }
                })
            },
            segmentChanged(ev) {
                this.selSeg = ev.detail.value;
                if(this.selSeg == 0){
                    this.getMainMileStone(this.projectId);
                }else if(this.selSeg == 1){
                    this.getMainPlanByProjectId(this.projectId);
                }
            }
        },
    });
</script>

<style lang="scss" scoped>
@import "@/views/dashboard/project/projectTaps";
    .segment {
        height: 45px;
    }
    .content{
        height: calc( 100% - 60px);
        margin: 10px;
        overflow-y: auto;
    }
    .planInfo{
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 10px;
        .iconfont{
            font-size: 15px;
        }
        .planName{
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
        }
        .relationPlan{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #777;
            padding: 0 5px;
            font-size: #{$very-little-font-size};
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;

        }
        .relationPlan span.name {
            width: calc( 100% - 80px );
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .planContent{
        width: 100%;
        height: 400px;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
    }
    .milestoneContent{
        width: 100%;
        height: 190px;
        border-radius: 8px;
        padding: 10px;
        background-color: #fff;
        margin-bottom: 10px;
    }
    .mainTitle{
        position: relative;
        padding-left: 10px;
        margin-bottom: 5px;
        height: 25px;
        line-height: 25px;
    }
    .mainTitle::before{
        content: '';
        position: absolute;
        left: 0;
        top: 3px;
        width: 4px;
        height: 70%;
        background: #0077c8;
        border-radius: 5px;
    }
    .mainTitle span{
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333f48;
    }
    
</style>