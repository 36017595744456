
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { TaskService } from "@/services/task/TaskService";
import Utils from "@/utils/Utils";
import Setting from "@/rest/Setting";
export default defineComponent({
  name: "EditDeliveryRequireModel",
  components: {
    HeaderModal,
  },
  props: {
    taskId: String,
    deliveryRequire: String,
    isCanEdit: String,
  },
  data() {
    return {
      btnTextRight: ["保存"],
      btnSizeRight: ["34px"],
      isRollback: true,
      deliveryRequireInput: "",
      api: new TaskService(),
      canEdit: true,
    };
  },
  created() {
    if (this.isCanEdit && this.isCanEdit == "false") {
      this.canEdit = false;
    }
    if (this.deliveryRequire != undefined) {
      this.deliveryRequireInput = this.deliveryRequire;
    }
  },
  mounted() {},
  methods: {
    onDeliveryRequireBlur() {
      let that = this;
      const updataData = {
        id: this.taskId,
        deliveryRequire: that.deliveryRequireInput,
        setFields: ["deliveryRequire"],
      };
      if (Utils.isInCludeEmoji(updataData.deliveryRequire)) {
        Utils.presentToastWarning("交付要求不可以包含表情");
        return false;
      }
      that.updateFieldReques(updataData, () => {
        //关闭页面
        modalController.dismiss({
          info: that.deliveryRequireInput,
        });
      });
    },
    //更新某个字段
    updateFieldReques(updataData: any, callback: any) {
      this.api.updateFieldsById(updataData).then((response) => {
        if (response) {
          callback();
        }
      });
    },
    closeModel(type: any) {
      if (type == 4) {
        this.onDeliveryRequireBlur();
      } else {
        modalController.dismiss({});
      }
    },
  },
});
