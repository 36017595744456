
import { modalController, IonPage } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import HmModuleService from "@/services/home/customModule/HmModuleService";
import Utils from "@/utils/Utils";
//import Sortable from "Sortablejs";
import { defineComponent } from "vue";
import { HmModule } from "@/services/home/customModule/model/HmModule";
import { VueDraggableNext } from "vue-draggable-next";

export default defineComponent({
  name: "Setting",
  components: {
    IonPage,
    HeaderModal,
    draggable: VueDraggableNext,
  },
  data() {
    const _selectArray: HmModule[] = [];
    return {
      api: new HmModuleService(),
      hasChanged: false, //是否修改了配置
      isEdit: false,
      editName: "编辑", //按钮文字
      customList: _selectArray, //已选中的模块数组
      moduleList: [], //全部模块及分组
      list: [
        {
          id: 1,
          name: "Abby",
          sport: "basket",
        },
        {
          id: 2,
          name: "Brooke",
          sport: "foot",
        },
        {
          id: 3,
          name: "Courtenay",
          sport: "volley",
        },
        {
          id: 4,
          name: "David",
          sport: "rugby",
        },
      ],
    };
  },
  mounted() {
    this.getAllModulesRequest();
    this.getMySettingModulesRequest();
  },
  methods: {
    closeModel() {
      modalController.dismiss({
        hasChanged: this.hasChanged,
      });
    },
    isSelect(id: string) {
      return this.isEdit && this.customList.findIndex((o) => o.id == id) > -1;
    },
    //获取全部模块
    getAllModulesRequest() {
      this.api.getAllModoules().then((res) => {
        this.moduleList = res;
      });
    },
    //获取我定制的模块
    getMySettingModulesRequest() {
      this.api.getMyModules().then((res) => {
        this.customList = res;
      });
    },
    //保存
    saveModulesRequest() {
      this.api.saveMyModules(this.customList).then((res) => {
        this.isEdit = !this.isEdit;
        this.editName = this.isEdit ? "完成" : "编辑";
        this.hasChanged = true;
        //保存到localStorage
        localStorage.removeItem("myHomeModules");
        localStorage.setItem("myHomeModules", JSON.stringify(this.customList));
      });
    },
    bindEditClick() {
      if (this.isEdit) {
        //保存
        this.saveModulesRequest();
      } else {
        this.isEdit = !this.isEdit;
        this.editName = this.isEdit ? "完成" : "编辑";
      }
    },
    //移除已选
    customDelete(index: number) {
      this.customList.splice(index, 1);
    },
    //从所有模块上删除
    deleteModuleHandler(id: string) {
      if (this.customList.length == 1) {
        Utils.presentToastWarning("至少保留一个常用内容");
        return;
      }
      const index = this.customList.findIndex((o) => o.id == id);
      if (index >= 0) {
        this.customList.splice(index, 1);
      }
    },
    //添加模块
    addModuleHandler(custom: HmModule, index: number) {
      this.customList.push(custom);
    },
  },
});
