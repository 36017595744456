import { transformRequest } from '@/utils/Utils';
import UserBaseService from '../attendance/base/UserBaseService';

export default class UserApi extends UserBaseService {


    constructor() {
        super("user");
    }

    getMsgCode(data: any) {
        const urlparam = transformRequest(data);
        return this.request("getCode?" + urlparam, this.rest.METHOD_POST);
    }

    validateSMSCodeIs(data: any) {
        const urlparam = transformRequest(data);
        return this.request("checkCodeIsCorrect?" + urlparam, this.rest.METHOD_POST);
    }
    /** 通过组织Id获取其下所有用户*/
    getUserByDept(params: any) {
        return this.request("userBook/byDept", this.rest.METHOD_GET, params);
    }

    updatePassword(params: any) {
        return this.request("updatePasswordPhone?loginId=" + params.loginId + "&password=" + params.password + "&userId=" + params.userId, this.rest.METHOD_POST, params);
    }
}