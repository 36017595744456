
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { Px2rem } from "@/libs/px2rem";
import { debug } from "console";

export default defineComponent({
  name: "HeaderModal",
  props: {
    isRollback: Boolean,
    title: String,
    themeType: String,

    //标题左侧按钮themeType=1
    btnTypeLeft: String, //0:图片，1:文本,2:字体
    btnTextLeft: String, //文本内容
    btnImgLeft: String, //按钮图片路径
    btnIconLeft: String, //按钮字体标识
    btnSizeLeft: { type: String, default: "28px" },

    //标题右侧按钮themeType=1
    btnTypeRight: String,
    btnTextRight: String,
    btnImgRight: String,
    btnIconRight: String,
    btnSizeRight: { type: String, default: "28px" },
  },
  emits: ["on-close"],
  data() {
    return {
      themeStyle: "",
      iconfont: "iconfont",

      btnTypeLeft_: "",
      btnTextLeft_: "",

      btnTypeRight_: "",
      btnTextRight_: "",

      btnSizeRight_: "",
      btnSizeLeft_: "",

      btnIconRight_: "",
      mBtnIconRight: this.btnIconRight,
    };
  },

  created() {
    if (this.themeType == "0") {
      this.themeStyle = "theme2";
    }
    if (this.themeType == "1") {
      this.themeStyle = "theme3";

      if (this.btnTypeRight === "") {
        // this.btnTypeRight_ = "1";
        // this.btnTextRight_ = "完成";
        
        this.btnTypeRight_ = "2";
        this.mBtnIconRight = "iconduihao";
      } else {
        this.btnTypeRight_ = this.btnTypeRight == undefined ? "" : this.btnTypeRight;
        this.btnTextRight_ = this.btnTextRight == undefined ? "" : this.btnTextRight;
      }
      if (this.btnTypeRight === "2" || this.btnTypeRight === "") {
        this.btnIconRight_ = this.iconfont + " " + this.mBtnIconRight;
      }
      if (this.btnTypeRight === "1" || this.btnTypeRight === "2") {
        this.btnSizeRight_ = Px2rem.getRem(this.btnSizeRight);
      }
    }
    if (this.themeType == "2") {
      this.themeStyle = "theme4";
    }
    if (this.themeType == "3") {
      this.themeStyle = "theme4";
    }
  },

  methods: {
    bindClose(type: number) {
      if (this.isRollback) {
        this.setResultEmitter(type);
      } else {
        modalController.dismiss({
          dismissed: true,
        });
      }
    },
    // bindMoreOperateLeft() {
    //   if (this.themeType == "1") {
    //     this.themeStyle = "theme3";
    //     if (this.btnTypeLeft != undefined && this.btnTypeLeft == "1") {
    //       this.setResultEmitter(1);
    //     } else {
    //       this.setResultEmitter(3);
    //     }
    //   }
    // },
    bindMoreOperateRight() {
      if (this.themeType == "1") {
        if (this.btnTypeRight == "1") {
          this.setResultEmitter(2);
        } else {
          this.setResultEmitter(4);
        }
      }
    },
    setResultEmitter(type: number) {
      this.$emit("on-close", type); //0:关闭，1:取消，2:确定,3:左侧，4：右侧
    },
  },
});
