
import { useRouter, useRoute } from "vue-router";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import LoginDemo from "@/views/demo/Login.vue";
import SettingVue from "@/views/home/components/Setting.vue";
import { log } from "util";
import { FileHelper } from "@/utils/FileHelper";
import DocService from "@/services/doc/docService";
import Setting from "@/rest/Setting";
import { SubFolder, FolderParams } from "@/models/file/Folder";
import Bus from "@/utils/bus";

export default defineComponent({
  name: "Mine",
  components: {
    Header,
  },
  emits: ["gotoHome"],
  props: {
    isRefresh: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const router = useRouter();
    const fileHelper = new FileHelper();
    const docService = new DocService();
    return {
      router: router,
      userInfo: SystemUtils.loginUser,
      fileHelper: fileHelper,
      docService: docService,
      isGif: false,
      photoId: "",
      photoName: "",
      isVisible: false,
      menuRole:"phone"
    };
  },
  watch: {
    isRefresh(newVal) {
      this.renderPicture();
    },
  },
  created() {
    this.menuRole = localStorage.getItem('menuRole')||'phone';
    Bus.$on("login_successful", () => {
      this.userInfo = SystemUtils.loginUser;
      this.renderPicture();
    });
  },
  mounted() {
    this.renderPicture();
    this.isVisible = !Setting.isSVW;
  },
  methods: {
    renderPicture() {
      if (
        this.userInfo != undefined &&
        this.userInfo.pernr != undefined &&
        this.userInfo.hasOwnProperty("pernr")
      ) {
        const folderId =
          "organization@user-folder-picture-" + this.userInfo.pernr;
        // this.loadFolderList(folderId);
      }
    },
    loadFolderList(pId: string) {
      const params = {
        folderId: pId,
      };
      this.docService.getFileByFolderId(params).then(
        (r: any) => {
          if (r) {
            if (r.data.documents.length > 0) {
              if (r.data != null && "documents" in r.data) {
                let documents: any = r.data["documents"];
                if (documents && documents instanceof Array) {
                  if (documents.length > 0) {
                    let ele = documents[0];
                    this.photoId = ele.mainFileId;
                    this.userInfo.photo = "";
                    this.isGif = ele.previewImage == "";
                    this.photoName = ele.name;
                    let extName = ele.name.substring(
                      ele.name.lastIndexOf(".") + 1
                    );
                    this.fileHelper
                      .downloadThumb(ele.mainFileId, extName, 3)
                      .then(
                        (r: string) => {
                          this.userInfo.photo = r;
                        },
                        (e: any) => {}
                      );
                  }
                }
              }
            }
          }
        },
        (e: any) => {
          console.log("获取文件失败");
        }
      );
    },
    login() {
      this.openLoginDemoModel();
    },
    //打开登录Demo页面
    async openLoginDemoModel() {
      const modal = await modalController.create({
        component: LoginDemo,
        cssClass: "notice-modal-class",
        backdropDismiss: false,
        mode: "ios",
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.loginSuccess) {
          this.$emit("gotoHome", true);
        } else if (data.userInfo) {
          this.userInfo = data.userInfo;
        }
      }
    },
    openDetail(name: string) {
      switch (name) {
        case "Relation":
          this.router.push({
            path: "/MineRelation",
          });
          break;
        case "Edit":
          this.router.push({
            path: "/MineEdit",
          });
          break;
        case "Project":
          this.router.push({
            name: "MineTaskProject",
            params: {
              type: 0, //"Project"
            },
          });
          break;
        case "Task":
          this.router.push({
            name: "MineTaskProject",
            params: {
              type: 1, //"Task"
            },
          });
          break;
        case "Calendar":
          this.router.push({
            path: "/MineCalendar",
          });
          break;
        case "Collect":
          this.router.push({
            name: "MineCollect",
          });
          break;
        case "Archive":
          this.router.push({
            name: "MineArchive",
          });
          break;
        case "Recycle":
          this.router.push({
            name: "MineRecycle",
          });
          break;
        case "About":
          this.router.push({
            path: "/About",
          });
          break;
        case "MoreSetting":
          this.router.push({
            path: "/MoreSetting",
          });
        default:
          break;
      }
    },
    //原图预览
    bindPreviewImage() {
      if (
        this.photoId != "" &&
        this.userInfo != undefined &&
        this.userInfo.photo != undefined
      ) {
        let extName = this.photoName.substring(
          this.photoName.lastIndexOf(".") + 1
        );
        this.fileHelper.downloadOriginal(this.photoId, extName, 3).then(
          (r: any) => {
            this.fileHelper.previewVant([r], 0);
          },
          (e: any) => {}
        );
      }
    },
  },
});
