
import { modalController, menuController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue"; //
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import MyCalendar from "./my/MyCalendar.vue";
import OtherCalendar from "./other/OtherCalendar.vue";
import CalendarSearchPanel from "./CalendarSearchPanel.vue";
import SearchBarCalendarPullDown from "./component/SearchBarCalendarPullDown.vue";
import SearchBar from "@/components/SearchBar.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectSubscribeUserList from "./component/SelectSubscribeUserList.vue";
import ScheduleService from "@/services/calendar/ScheduleService";
import { nextTick } from "process";

export default defineComponent({
  name: "CalendarPage",
  components: {
    Header,
    MyCalendar,
    OtherCalendar,
    CalendarSearchPanel,
    SearchBar,
    popoverController,
  },
  data() {
    const router = useRouter();
    const btnSizeRight = ["38px", "38px"];
    const projectId = this.$route.query.projectId;
    const btnIconRight: Array<any> = ["iconrilishezhi", "iconshaixuanxuanzhong"];

    const addItem = {
      id: "-1",
      name: "",
      isNew: -1,
      photo: "",
    };
    return {
      scheduleService: new ScheduleService(),
      indicators: ["我的日历", "他人日历"],
      selSeg: 0,
      btnSizeRight: btnSizeRight,
      projectId: projectId,
      btnIconRight: btnIconRight,
      router: router,
      searchParams: {
        limit: 10,
        offset: 1,
        scheduleName: "",
        calendarId: "",
        keyName: "",
        creatorId: "",
        joinUsers: "",
        startTimeStart: "",
        startTimeEnd: "",
        endTimeStart: "",
        endTimeEnd: "",
        priority: "",
        endDate: "",
        userIds: "",
      },
      searchCondition: 0, //0：清空所有条件，1:本页面搜索框输入，2：本页面选择日历本，3：筛选条件页面查询,4:本页面搜索框输入清空
      myList: [],
      otherList: new Array<string>(),
      refreshMyPage: false, //刷新我的日历列表
      refreshOtherPage: false, //刷新他人日历列表
      refreshEnable:false,//刷新是否生效
      selSegUserId: "",
      userList: new Array(0),
      addItem: addItem,
      pageItems: new Array(0),
      slideOpts: {},
      slideNum: 1,
      colNum: 7,
      isNotNewColor: "#BEBEBE",
      isNewColor: "#7ADD7A",
      isLoad: false,
      placeholder: "全部日历",
      hasPage: false,
      selectDate:"",//我的日历选择日期
    };
  },
  props: {},
  created() {
    this.refreshEnable=false;
    this.initOption();
    this.initData("create");
  },
  ionViewWillEnter() {
    let that = this;
    that.isLoad = true;
    if (that.selSeg == 0) {
      if(this.refreshEnable){
        this.refreshMyPage = true;
      }
      if(!this.refreshEnable){
        this.refreshEnable=true;
      }
      setTimeout(function () {
        that.refreshMyPage = false;
      }, 1000);
    } else {
      that.refreshOtherPage = true;
      setTimeout(function () {
        that.refreshOtherPage = false;
      }, 1000);
    }
  },
  methods: {
    //滚动到顶部
    scrollToTop() {
      nextTick(() => {
        let ionContent: any = document.querySelector("#CalendarMain");
        if (ionContent) {
          ionContent.scrollToTop();
        }
      });
    },
    initOption() {
      this.slideOpts = {
        initialSlide: 0,
        speed: 400,
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
      };
    },
    initData(type:any) {
      this.pageItems = [];
      this.getListRequest((res: any) => {
        // this.pageItems = [];
        this.userList = res;
        this.otherList = res.map(function (e: any, i: number) {
          return e.id;
        });
        this.searchParams = Object.assign(this.searchParams, {
          userIds: this.otherList.join(","),
        });
        this.initUserList();
        if(type!="create"){
          this.resetPage();
        }
      });
    },
    //渲染 他人日历人员列表
    initUserList() {
      if (this.colNum > 0) {
        let userListNew = this.userList.concat(this.addItem);
        const len = userListNew.length;
        const mod = len % this.colNum;
        const c = Math.ceil(len / this.colNum);
        this.slideNum = c;
        for (let i = 0; i < this.slideNum; i++) {
          const row: Array<any> = [];
          for (let j = 0; j < this.colNum; j++) {
            const index = i * this.colNum + j;
            if (index + 1 <= userListNew.length) {
              const e = userListNew[index];
              row.push(e);
            } else {
              row.push({ isNew: -100 });
            }
          }
          this.pageItems.push(row);
        }
      }
    },
    subStringUserName(userName: string) {
      if (!userName) {
        return "";
      }
      return Utils.subStringUserName(userName, 2);
    },
    //点击他人日历列表
    bindModuleRouter(item: any) {
      if (item.id == "-1") {
        this.selectJoiners();
      } else {
        if (item.isNew == 0) {
          this.otherList.push(item.id);
        } else {
          this.otherList.splice(this.otherList.indexOf(item.id), 1);
        }
        item.isNew = item.isNew == 0 ? 1 : 0;
        this.searchParams = Object.assign(this.searchParams, {
          userIds: this.otherList.join(","),
        });
        this.resetPage();
      }
    },
    segmentChanged(selSeg: any) {
     this.selSeg=selSeg;
    },
    //右上角菜单
    openProjectMenuModal(item: any) {
      if (item == "1_icon") {
        menuController.open();
      } else {
        this.settingCalendar();
      }
    },
    //设置日历
    settingCalendar() {
      const param = {
        pos: 0,
      };
      this.router.push({
        name: "setting-calendar",
        params: {
          setting: JSON.stringify(param),
        },
      });
    },
    //添加日程
    addScheduleHandler() {
      const schedule = {
        // rootId: this.task.rootId,
        // orgId: this.task.orgId,
        pos: 0,
      };
      this.router.push({
        name: "add-schedule",
        params: {
          schedule: JSON.stringify(schedule),
          selectDate:this.selectDate,
        },
      });
    },
    //数据过滤
    menuEvent(data: any) {
      const newParam = JSON.parse(JSON.stringify(data));
      if (newParam.isSelected == "true") {
        //选中图标
        this.btnIconRight = ["iconrilishezhi", "iconshaixuanxuanzhong_iconSelected"];
      } else {
        //未选中图标
        this.btnIconRight = ["iconrilishezhi", "iconshaixuanxuanzhong"];
      }
      //参与人
      newParam.joinUsers = "";
      if (newParam.joinArr) {
        if (newParam.joinArr.length > 0) {
          for (let i = 0; i < newParam.joinArr.length; i++) {
            newParam.joinUsers += newParam.joinArr[i].id + ",";
          }
          newParam.joinUsers = newParam.joinUsers.substring(
            0,
            newParam.joinUsers.length - 1
          );
        }
        delete newParam.joinArr;
      }

      //创建人
      newParam.creatorId = "";
      if (newParam.creatArr) {
        if (newParam.creatArr.length > 0) {
          for (let i = 0; i < newParam.creatArr.length; i++) {
            newParam.creatorId += newParam.creatArr[i].id + ",";
          }
          newParam.creatorId = newParam.creatorId.substring(
            0,
            newParam.creatorId.length - 1
          );
        }
        delete newParam.creatArr;
      }

      this.searchParams = Object.assign(this.searchParams, newParam);
      if (this.selSeg == 0) {
        this.initSearchParams(3);
        this.searchCondition = 3;
      }
      this.resetPage();
    },
    //重新从第一页获取
    resetPage() {
      //刷新我的日历
      if (this.selSeg == 0) {
        this.refreshMyPage = true;
      } else {
        //刷新他人日历
        this.refreshOtherPage = true;
      }
      this.resetFreshStatue();
    },
    //重置刷新状态
    resetFreshStatue() {
      const that = this;
      setTimeout(function () {
        //重置我的日历
        if (that.selSeg == 0) {
          that.refreshMyPage = false;
        } else {
          //重置他人日历
          that.refreshOtherPage = false;
        }
      }, 100);
    },
    //输入文字搜索
    searchHandler(searchParam: any) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.searchParams.scheduleName = searchParam.searchKey;
      if (this.searchParams.scheduleName == "") {
        this.searchCondition = 4;
        this.initSearchParams(4);
      } else {
        this.searchCondition = 1;
        this.initSearchParams(1);
      }
      this.resetPage();
      // this.scrollToTop();
    },
    async bindSerchClick(ev: any) {
      const popover = await popoverController.create({
        component: SearchBarCalendarPullDown,
        cssClass: "myPullDown",
        event: ev,
        mode: "md",
        componentProps: {
          searchName: "",
          searchId: this.searchParams.calendarId,
        },
      });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data && data.item) {
        this.selectHandler(data.item);
      }
    },
    //选择日历搜索
    selectHandler(item: any) {
      this.searchParams.calendarId = item.id;
      this.initSearchParams(2);
      this.searchCondition = 2;
      this.resetPage();
    },
    //初始化查询条件
    //0：清空所有条件，1:本页面搜索框输入，2：本页面选择日历本，3：筛选条件页面查询,4:本页面搜索框输入清空
    initSearchParams(type: any) {
      if (this.selSeg == 0) {
        switch (type) {
          case 0:
            break;
          case 1: //1和4相同
            this.searchParams.calendarId = "";
            this.searchParams.keyName = "";
            this.searchParams.creatorId = "";
            this.searchParams.joinUsers = "";
            this.searchParams.startTimeStart = "";
            this.searchParams.startTimeEnd = "";
            this.searchParams.endTimeStart = "";
            this.searchParams.endTimeEnd = "";
            this.searchParams.priority = "";
            this.searchParams.endDate = "";
            break;
          case 2:
            this.searchParams.scheduleName = "";
            this.searchParams.keyName = "";
            this.searchParams.creatorId = "";
            this.searchParams.joinUsers = "";
            this.searchParams.startTimeStart = "";
            this.searchParams.startTimeEnd = "";
            this.searchParams.endTimeStart = "";
            this.searchParams.endTimeEnd = "";
            this.searchParams.priority = "";
            this.searchParams.endDate = "";
            break;
          case 3:
            this.searchParams.calendarId = "";
            this.searchParams.scheduleName = "";
            break;
          case 4:
            this.searchParams.calendarId = "";
            this.searchParams.keyName = "";
            this.searchParams.creatorId = "";
            this.searchParams.joinUsers = "";
            this.searchParams.startTimeStart = "";
            this.searchParams.startTimeEnd = "";
            this.searchParams.endTimeStart = "";
            this.searchParams.endTimeEnd = "";
            this.searchParams.priority = "";
            this.searchParams.endDate = "";
            break;
        }
      }
    },
    //他人日历，选择人员
    selectUserHandler(e: any) {
      this.selSegUserId = e.detail.value;
      if (this.selSegUserId == "-1") {
        this.selSegUserId = "-100";
        this.selectJoiners();
      } else {
        if (this.selSegUserId != "-100") {
          this.searchParams = Object.assign(this.searchParams, {
            selectUserId: this.selSegUserId,
          });
          this.resetPage();
          this.selSegUserId = "-100";
        }
      }
    },
    //选择订阅的日历人员
    //选择参与人
    async selectJoiners() {
      const modal = await modalController.create({
        component: SelectSubscribeUserList,
        cssClass: "notice-modal-class",
        componentProps: {},
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && "isReflush" in data) {
        this.initData("search");
        // this.resetPage();
      }
    },
    //选择参与人
    async selectJoiner() {
      let that = this;
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "joiner",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        that.userList = that.userList.concat(data.data);
      }
    },
    getListRequest(callback: any) {
      this.scheduleService.getSubscribeUserList().then((res: any) => {
        if (res && res.data) {
          callback(res.data);
        }
      });
    },
    selectDateHandler(param:any){
      this.selectDate=param;
    }
  },
});
