/**
 * okr_kr_score_log 评分记录 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 评分记录
 */
export class KrScoreLogApi extends TaskBaseService {

    constructor() {
        super("okr/score");
    }
    //目标评分记录
    public getObjectiveList(params: any): Promise<any> {
        const path = 'objective/list';
        return this.request(path, this.rest.METHOD_GET, params);
    }

    //目标评分记录：关键结果评分详情
    public getKrListByOscoreId(params: any): Promise<any> {
        const path = 'kr/list/byOscoreId';
        return this.request(path, this.rest.METHOD_GET, params);
    }
     //目标评分记录：关键结果评分详情  
     public getKrListByOidAndCycleId(params: any): Promise<any> {
        const path = 'kr/list/byOidAndCycleId';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    //保存评分 
    public getSaveScores(params: any): Promise<any> {
        const path = 'saveScores';
        return this.request(path, this.rest.METHOD_POST, params);
    } 
}