
import { TaskPermissionService } from "@/services/task/TaskPermissionService";
import { defineComponent } from "vue";
export default defineComponent({
  name: "PermissionSetting",
  props: {
    id: String,
    isProject: Boolean,
    isHasPermission: Boolean, //是否有编辑权限  true/false
  },
  data() {
    return {
      api: new TaskPermissionService(),
      permissionList: [],
    };
  },
  mounted() {
    this.getPermissionRequest();
  },
  methods: {
    //改变权限的事件
    changePermissionHandler(
      permissionId: string,
      checkValue: boolean,
      roleId: string
    ) {
      if (this.isHasPermission) {
        this.api.saveTaskPermissionSetting(
          this.id ? this.id : "",
          permissionId,
          checkValue,
          roleId,
          this.isProject
        );
      }
    },
    getPermissionRequest() {
      this.api
        .getTaskPermissionSettings(this.id ? this.id : "", this.isProject)
        .then((res: any) => { 
          this.permissionList = res; 
        });
    },
  },
});
