import { ErrorModel } from '@/models/base/ReponseModel';
import CookieHelper from '@/utils/CookieHelper';
import Utils, { toCamel } from '@/utils/Utils';
import { HTTP } from '@ionic-native/http';
import Setting from './Setting';

import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer';
//退出账号
const quitAccount = () => {
    if(window.location.href.indexOf("/login")<0){
        window.location.href = '/login';
    }
}

let headers: any = {
    'Content-Type': 'application/json'
};

const interceptorsRequest = (url: string) => {
    headers = { 'Content-Type': 'application/json' };
    if (url.indexOf('attapi') > -1) {
        headers['X-APP-ID'] = Setting.defalutAPPID;
    }
    else {
        //企业编码
        headers['enterpriseId'] = Setting.currentEnterprise.id;
    }
    if (url.indexOf('/oauth') >= 0) {
        return;
    }
    const token = CookieHelper.get(Setting.authCookieName);
    if (token && token != "null" && token.length > 0) {
        headers.Authorization = JSON.parse(token).access_token;;
    } else {
        if (url.indexOf("/getMqttAddress") < 0) {
            //是否有当前用户信息
            quitAccount();
        }
    }

}

export const httpReques = (url: string, method: string, param?: any) => {

}

const httpSuccessResponse = (response: any, method: string, resolve: any) => {
    const res = response.data;
    if (response.status == 200) {
        const resModel: any = {};
        resModel.method = method;
        try {
            resModel.data = JSON.parse(response.data);
        } catch (e) {
            resModel.data = response.data;
        }
        resModel.status = response.status;
        resModel.statusText = response.status;
        //循环headers赋值
        if (response.headers) {
            for (const item in response.headers) {
                if (item.indexOf("x-") > -1) {
                    const itemName = toCamel(item);
                    resModel[itemName] = response.headers[item];
                }
            }
        }
        return resolve(resModel);
    }
}

const httpErrorResponse = (error: any, reject: any) => {
    const errorModel: ErrorModel = {};
    errorModel.method = "";
    if (error.status == -1) {
        //请求未能成功发送
        // TODO : 简化错误处理       
    }

    if (error.error) {
        try {
            const response = JSON.parse(error.error);
            errorModel.message = response.message;
            errorModel.code = response.status;
        } catch (e) {
            errorModel.message = error.error;
            errorModel.code = error.status;
        }
        errorModel.data = error.error;
        errorModel.httpCode = error.status;
        errorModel.status = error.status;
        errorModel.statusText = error.status;
    }
    
    if (error.status == 401 && error.url.indexOf("/getMqttAddress")<0) {
        //todo 若重复登陆三次还是失败，那么给出提示，并退出到login页面
        // Utils.presentToastWarning("认证信息失效，请重新认证");
        // quitAccount();
    }

    if (error.status >= 500) {
        Utils.presentToastWarning("服务器外出一会儿~");
    }

    return reject(errorModel);
}

export const httpPost = (url: string, data?: any): Promise<any> => {
    HTTP.setDataSerializer('json');
    interceptorsRequest(url);
    return new Promise((resolve, reject) => {
        HTTP.post(url, data ? data : {}, headers)
            .then((res: any) => {
                httpSuccessResponse(res, "post", resolve);
            })
            .catch(error => {
                httpErrorResponse(error, reject);
            });
    });
}

export const httpGet = (url: string, param?: any): Promise<any> => {
    interceptorsRequest(url);
    if (param) {
        for (let key in param) {
            param[key] = '' + param[key];
        }
    }
    return new Promise((resolve, reject) => {
        HTTP.get(url, param ? param : {}, headers)
            .then((res: any) => {
                httpSuccessResponse(res, "get", resolve);
            })
            .catch(error => {
                httpErrorResponse(error, reject);
            });
    });
}

export const httpPut = (url: string, data?: any): Promise<any> => {
    HTTP.setDataSerializer('json');
    interceptorsRequest(url);
    return new Promise((resolve, reject) => {
        HTTP.put(url, data ? data : {}, headers)
            .then((res: any) => {
                httpSuccessResponse(res, "put", resolve);
            })
            .catch(error => {
                httpErrorResponse(error, reject);
            });
    });
}

export const httpDelete = (url: string, data?: any): Promise<any> => {
    HTTP.setDataSerializer('json');
    interceptorsRequest(url);
    return new Promise((resolve, reject) => {
        HTTP.delete(url, data ? data : {}, headers)
            .then((res: any) => {
                httpSuccessResponse(res, "delete", resolve);
            })
            .catch(error => {
                httpErrorResponse(error, reject);
            });
    });
}


/**
 * 上传文件
 * @param url 服务器地址
 * @param filePath 文件本地路径，支持string类型：file:///somepicture.jpg；若是多文件上传则是string数组：[file:///somepicture.jpg,file:///somepicture.jpg2]
 * @param name the name(s) of the parameter，对应filePath，支持string和string数组，例如：picture或['picture', 'document']
 * @param params 
 */
export const httpUploadFile = (url: string, filePath: any, name: any, params?: any): Promise<any> => {
    HTTP.setDataSerializer('json');
    return new Promise((resolve, reject) => {
        HTTP.uploadFile(url, params ? params : {}, headers, filePath, name)
            .then((res: any) => {
                console.log('上传成功：' + JSON.stringify(res));
                httpSuccessResponse(res, "upload", resolve);
            })
            .catch(error => {
                httpErrorResponse(error, reject);
            });
    });
}

/**
 * 下载文件
 * @param url 下载地址
 * @param filePath 本地地址 ，如file:///somepicture.jpg
 * @param params 
 * @returns  returns a cordova FileEntry object.
 */
export const httpDownloadFile = (url: string, filePath: any, params?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        HTTP.downloadFile(url, params ? params : {}, headers, filePath)
            .then((res: any) => {
                resolve(res);
            })
            .catch(error => {
                console.log(JSON.stringify(error));
                resolve(error);
            });
    });
}
export const httpPostAIUI = (url: string, header: any, filePath: any, fileName: any): Promise<any> => {
    HTTP.setDataSerializer("json");
    return new Promise((resolve, reject) => {
        HTTP.uploadFile(url, { time: Date.now().toString() }, header, filePath, fileName)
            .then((res: any) => {
                resolve(res);
            })
            .catch(error => {
                console.log("error:" + JSON.stringify(error));
                reject(error);
            });
    });
}

export const aiuiPostfileTransfer = (url: string, header: any, filePath: any, fileName: any): Promise<any> => {
    let fileTransfer = FileTransfer.create();
    //文件参数
    let fileOptions: FileUploadOptions = {
        fileKey: 'file',
        fileName: fileName,
        httpMethod: "POST",
        mimeType: "text/plain",
        chunkedMode: false,
        headers: header,
        params: {}
    }
    return new Promise((resolve, reject) => {
        fileTransfer.upload(filePath, url, fileOptions)
            .then((data: any) => {
                console.log("aiui   sussess："+JSON.stringify(data));
                data.data = data.response;
                resolve(data);
            }, (err: any) => {
                reject("上传失败-httpPostAIUI1");
            }).catch((c: any) => {
                reject("上传失败-httpPostAIUI1");
            });
    });
}

/**
 * 不需要baseurl的请求
 * @param url 服务器地址
 * @param params 
 * @param header 
 */
export const httpGetCustom = (url: string, param: any, header: any): Promise<any> => { 
    HTTP.setDataSerializer("json");
    if (param) {
        for (let key in param) {
            param[key] = '' + param[key];
        }
    }else{
        param={};
    } 
    return new Promise((resolve, reject) => {
        HTTP.get(url,param, header)
            .then((res: any) => {
                resolve(res);
            })
            .catch(error => {
                reject(error);
            });
    });
}
