import BaseModel from '@/services/base/model/BaseModel';

export class TeamUser extends BaseModel {
    //account: string="";
    name: string="";
    nameCn: string="";
    partNameCn: string="";
    partNameEn: string="";
    pernr: string="";
    photo: string="";
    //projectId: string="";
    userColor: string="";
    userId: string="";
    userName: string="";
    roleList: Array<{
        projectRoleId: string,
        projectRoleName: string,
        projectTeamRoleId: string,
        projectTeamRoleName: string,
        projectMemberId: string
    }> = new Array();

    constructor(info?: any) {
        super();
        if(!info){
            return;
        }
        //this.account = info.userId;
        this.name = info.userName;
        this.nameCn = info.nameCn;
        this.partNameCn = info.departmentCn;
        this.partNameEn = info.departmentEn;
        this.photo = info.photo;
        //this.projectId = info.projectId;
        this.userColor = info.userColor;
        this.userId = info.userId;
        this.pernr = info.pernr;
        this.userName = info.userName;
        if (info.list) {
            info.list.map((role:any)=>{
                this.roleList.push({
                    projectRoleId: role.projectRoleId,
                    projectRoleName: role.projectRoleName,
                    projectTeamRoleId: role.projectTeamRoleId,
                    projectTeamRoleName: role.projectTeamRoleName,
                    projectMemberId: role.memberId
                });
            });
        }
    }
}