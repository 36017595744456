export default class CookieHelper {
    private static _cookies: Array<any> = [];

    public static add(cookie: any): void {
        const index = CookieHelper.getIndex(cookie.key);
        if (index > -1) {
            CookieHelper._cookies.splice(index, 1);
        }
        CookieHelper._cookies.push(cookie);
    }

    public static get(key: string): string | null {
        const index = CookieHelper.getIndex(key);
        if (index > -1) {
            return CookieHelper._cookies[index].value;
        }
        return localStorage.getItem(key);
    }

    private static getIndex(key: string): number {
        const index = CookieHelper._cookies.findIndex((item) => {
            return item.key.toLowerCase() == key.toLowerCase();
        });
        return index;
    }

    public static deleteCookie(key: string): void {
        const cookie = CookieHelper.get(key);
        if (!cookie) {
            return;
        }
        localStorage.removeItem(key);
        const index = CookieHelper.getIndex(key);
        if (index > -1) {
            CookieHelper._cookies.splice(index, 1);
        }
    }

    public static deleteAllCookie(): void {
        CookieHelper._cookies.splice(0,CookieHelper._cookies.length);
        localStorage.clear();
    }

    public static save(cookie: any): void {
        CookieHelper.add(cookie);
        localStorage.removeItem(cookie.key);
        localStorage.setItem(cookie.key,cookie.value);
    }
}