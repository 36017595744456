
import { IonList, IonItem, IonLabel, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import SearchBar from "@/components/SearchBar.vue";
import { getInstance } from "@/services/MicroIssuesService";
import BaseRsp from "@/models/base/BaseRsp";
import Issues from "@/models/microIssues/Issues";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils";
import { nextTick } from "process";
export default defineComponent({
  name: "MicroIssuesList",
  components: {
    IonList,
    IonItem,
    IonLabel,
    SearchBar,
    CardLoading,
  },
  props: {
    type: String,
    id: String,
    allowAdd: Boolean,
    isIndependentTask: Boolean,
  },
  emits: ["addMicroIssues", "onSeach"],
  data() {
    const router = useRouter();
    const service = getInstance();
    const issuesList: Array<Issues> = Array(0);
    return {
      condition: { offset: 1, searchText: "", projectId: "", taskId: "" },
      service: service,
      microIssuesList: issuesList,
      router: router,
      rotate: false, //控制互转效果
      mId: this.id,
      mAllowAdd: this.allowAdd,
      islist: true, //是否显示加载中的标识
    };
  },
  methods: {
    getListData(isReload?: boolean, event?: any) {
      if (isReload) {
        this.condition.offset = 1;
      } else {
        this.condition.offset++;
      }
      if (this.type == "project") {
        // 查询项目下的微议题
        this.condition.projectId = this.mId as string;
      } else if (this.type == "task") {
        // 查询任务下的微议题
        this.condition.taskId = this.mId as string;
      }
      this.service.getMicroIssuesList(this.condition).then(
        (rsp: any) => {
          if (rsp) {
            if (isReload) this.microIssuesList = rsp;
            else this.microIssuesList = this.microIssuesList.concat(rsp);
          }
          if (event) event.target.complete();
          this.islist = false;
        },
        (err: BaseRsp) => {
          if (event) event.target.complete();
          this.islist = false;
        }
      );
      setTimeout(() => {
        if (event) event.target.complete();
      }, 2000);
    },
    itemClick(issues: Issues) {
      this.router.push({
        name: "MicroIssuesInfo",
        params: {
          issues: JSON.stringify(issues),
        },
      });
    },
    search(event: any) {
      if (Utils.isInCludeEmoji(event.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.condition.searchText = event.searchKey;
      this.getListData(true);
      nextTick(() => {
        this.$emit("onSeach");
      });
    },
    nextPage(event: any) {
      this.getListData(false, event);
    },
    doRefresh(event: any) {
      this.getListData(true, event);
    },
    updateGetDataId(id: string, allowAdd: boolean) {
      this.mId = id;
      this.mAllowAdd = allowAdd;
    },
    // 新建微议题
    addMicroIssues() {
      this.$emit("addMicroIssues");
    },
  },
});
