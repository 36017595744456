import { CalendarDetailApi } from '@/api/calendar/CalendarDetailApi';

import BaseService from '../base/BaseService';
import SystemUtils from '@/utils/SystemUtils';

/**
 * 日历模块
 */
export class CalendarDetailService extends BaseService {
    private static calendarDetailApi = new CalendarDetailApi();

    // 保存
    public saveCalendarDetail(parms: any): any {
        return new Promise((resolve, reject) => {
            CalendarDetailService.calendarDetailApi.saveCalendarDetail(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //删除
    public deleteById({ id = ""} = {}): any {
        return new Promise((resolve, reject) => {
            CalendarDetailService.calendarDetailApi.deleteById({id}).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //批量删除
    public deleteByIds(params:any): any {
        return new Promise((resolve, reject) => {
            CalendarDetailService.calendarDetailApi.deleteByIds(params).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }


}