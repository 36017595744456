
import { IonPage, IonContent, IonText, IonInput } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
//组件

export default defineComponent({
  name: "Form",
  components: {
    IonPage,
    IonContent,
    IonText,
    IonInput,
  },
  data() {
    const router = useRouter();
    return {
      router: router,
    };
  },
  methods: {
    goBack() {
      this.router.go(-1);
    },
  },
});
