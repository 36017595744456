<template>
    <ion-page>
        <ion-content :scroll-y="true" class="iconContent">
            <div class="cardTitle">
                <div class="title">请选择自定义快捷入口菜单</div>
                <div class="right">
                    <span @click="closeModal()" class="iconfont icon-guanbi"></span>
                </div>
            </div>
            <ion-grid>
                <ion-row>
                    <ion-col
                            size="6"
                            v-for="(item, idx) in menuCollection"
                            :key="item.name"
                    >
                        <div :class="activeTab.indexOf(idx)>-1?'setting-tab-active':'setting-tab'">
                              <div :class="[item.cardColor,'card',
                                  ]"
                                        @click="navPage(item, idx)"
                                >
                            <img
                                    :src="require('@/assets/images/line.png')"
                                    class="lineImg"
                                    width="15"
                                    height="3"
                                    alt=""
                            />
                            <img
                                    :src="require('@/assets/images/bg2.png')"
                                    style="left: 0; bottom: 0"
                                    width="57"
                                    height="35"
                                    alt=""
                            />
                            <img
                                    :src="require('@/assets/images/bg3.png')"
                                    style="right: 0; bottom: 0"
                                    width="111"
                                    height="19"
                                    alt=""
                            />

                            <i class="iconfont" :class="[item.icon]"></i>
                            <span class="cardText">{{ item.title }}</span>
                        </div>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <div class="item">
                <ion-button class="selectBtn" @click="confirm">确定</ion-button>
                <ion-button
                        class="selectBtn"
                        @click="reset"
                        style="margin-left: 10px"
                >重置</ion-button
                >
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
    import ModuleMenu from "@/views/home/ModuleMenu.vue";
    import toolbar from "./toolbar";
    import { getPrinterIP } from "@/api/login/login";
    import * as dd from "dingtalk-jsapi";
    import SystemUtils from "@/utils/SystemUtils";
    import { clearInterval } from "timers";
    import {  isPlatform, modalController } from "@ionic/vue";
    import Utils from "@/utils/Utils.ts";
    import customizeMenu from "@/views/home/customizeMenu.vue";
    import { AnimationSelf } from "@/libs/animation";
    import { createUserSiteMap } from "@/api/home/customizeMenu";

    export default {
        name: "homePage",
        props: {
            menuCollection: Object,
            siteMapList: Object,
            menuRole: String,
        },
        components: {
            toolbar,
            ModuleMenu,
            modalController,
        },
        data() {
            return {
                isShow: true,
                modulesProp: [
                    {
                        name: "checkpointPutinStorage",
                        title: "检查站接收检查件",
                        icon: "icon-jianchazhanjieshoujianchajian",
                        visible: true,
                        background: "#F15541",
                        role: "phone",
                        loginRole: "CheckMonitor",
                    },
                    {
                        name: "subPage/仓储管理/goods",
                        title: "仓储管理",
                        icon: "icon-kucunguanli",
                        visible: true,
                        background: "#3295FA",
                        role: "phone",
                        loginRole: "WarehouseKeeper",
                    },
                    {
                        name: "subPage/生产作业管理/car",
                        title: "生产作业管理",
                        icon: "icon-zhuangchejindu",
                        visible: true,
                        background: "#FF943E",
                        role: "phone",
                        loginRole: "WarehouseKeeper",
                    },
                    {
                        name: "subPage/质量管理/question",
                        title: "质量管理",
                        icon: "icon-zhiliangxinxi",
                        visible: true,
                        background: "#F15541",
                        role: "dd",
                        loginRole: "",
                    },
                    {
                        name: "vehicleList",
                        title: "叉车调度管理",
                        icon: "icon-chacheguanli",
                        visible: true,
                        background: "#FEAE00",
                        role: "dd",
                        loginRole: "",
                    },

                    {
                        name: "taskList",
                        title: "任务管理",
                        icon: "icon-shengchanzhiliang",
                        visible: true,
                        background: "#FF7640",
                        role: "dd",
                        loginRole: "",
                    },
                    {
                        name: "partsInfo",
                        title: "零部件点检统计",
                        icon: "icon-lingbujianxinxi",
                        visible: true,
                        background: "#15BC83",
                        role: "dd",
                        loginRole: "",
                    },
                    {
                        name: "subPage/模具管理/templateInfo",
                        title: "模具管理",
                        icon: "icon-yangchexinxi",
                        visible: true,
                        background: "#32D5E2",
                        role: "dd",
                        loginRole: "",
                    },
                    {
                        name: "workshopPartReception",
                        title: "车间零件接收",
                        icon: "icon-lingjianjieshou",
                        visible: true,
                        background: "#32D5E2",
                        role: "phone",
                        loginRole: "WarehouseKeeper",
                    },
                ],
                activeTab: [], //选中的卡片索引集合
                customizeInput: [], //选中的卡片内容集合
                cardColor: ['primary','warning','purple','sky'],
            };
        },
        mounted() {
            this.menuCollection.forEach((item, index) => {
                this.siteMapList.forEach((map) => {
                    if(map.id == item.id){
                        this.activeTab.push(index);
                    }
                });
            });
            this.customizeInput = this.siteMapList;
        },
        methods: {
            async customizeMenu() {
                const  result = AnimationSelf.prototype.modalRight;
                const modal = await modalController.create({
                    component: customizeMenu,
                    cssClass: "calendar-modal-class",
                    enterAnimation: result.enterAnimation,
                    leaveAnimation: result.leaveAnimation,
                    componentProps: {

                    },
                });
                await modal.present();
                const { data } = await modal.onDidDismiss();
                if (data) {
                    this.taskList = data.taskList
                }
            },
            navPage(item, index) {
                if(this.activeTab.indexOf(index)>-1){
                    this.remove(index);
                }else{
                    if(this.activeTab.length==4){
                        Utils.presentToastTopWarning("自定义快捷入口菜单最多可选四个", "danger");
                        return false;
                    }
                    this.activeTab.push(index);
                    this.customizeInput.push(item);
                }
            },
            async getPrinterIP() {
                getPrinterIP({ nameCN: SystemUtils.loginUser.id }).then((res) => {
                    localStorage.removeItem("printHttp");
                    localStorage.removeItem("printPort");
                    if (
                        !res.data.isError &&
                        res.data.data.isSuccess &&
                        res.data.data.resultData
                    ) {
                        localStorage.setItem("printHttp", res.data.data.resultData.IP);
                        localStorage.setItem("printPort", res.data.data.resultData.code);
                    }
                });
            },
            remove(val) {
                const index = this.activeTab.indexOf(val);
                if (index > -1) {
                    this.activeTab.splice(index, 1);
                    this.customizeInput.splice(index, 1);
                }
            },
            initData(){

            },
            confirm() {
                let siteMapIds = ""; //用户自定义导航ids
                this.customizeInput.forEach((item) => {
                    siteMapIds += "(" + item.id + ")";
                });
                const params = {
                    userId: SystemUtils.loginUser.id,
                    siteMapIds: siteMapIds,
                    menuRole: this.menuRole
                }
                createUserSiteMap(params).then(res=>{
                    if (!res.data.isError && res.data.data.isSuccess) {
                        modalController.dismiss({
                            isReturn: true,
                            customizeInput:this.customizeInput,
                        });
                        Utils.presentToastTopWarning("自定义快捷入口成功", "success");
                    } else {
                        Utils.presentToastTopWarning(
                            res.data.message || res.data.data.message,
                            "danger"
                        );
                    }
                });
            },
            reset() {
                this.activeTab = [];
                this.customizeInput = [];
            },
            closeModal() {
                modalController.dismiss();
            },
        },
    };
</script>
<style lang="scss" scoped>
    .noticeTitle {
        width: 12px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
    }

    .iconContent {
        --background: #ffffff;
    }

    .notice {
        width: calc(100% - 16px);
        padding: 14px 7px;
        margin: 0 auto;
        border-bottom: 1px solid #e7e7e7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
            display: flex;
            justify-content: start;
            align-items: center;
            img {
                margin-right: 10px;
            }
        }
        .titleBox {
            width: 290px;
            overflow: hidden;
            margin: 0 auto;
        }
        .titleAnimate {
            padding-left: 20px;
            font-size: 12px;
            color: #000;
            display: inline-block;
            white-space: nowrap;
            animation: 5s wordsLoop linear infinite normal;
        }
        @keyframes wordsLoop {
            0% {
                transform: translateX(200px);
            }
            100% {
                transform: translateX(-100%);
            }
        }
    }
    .cardTitle {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            font-size: 16px;
            font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
            font-weight: bold;
            text-align: left;
            color: #434343;
            width: 100%;
        }
        a {
            font-size: 12px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            color: #989898;
        }
        .right {
            width: 70%;
            /*height: px2rem(88);*/
            text-align: right;
            --color: black;
            /*line-height: px2rem(50);*/
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .iconfont {
                margin-left: px2rem(25);
            }
        }
    }
    .moduleMenu {
        transition: height 0.51s;
    }
    .card {
        width: 100%;
        height: 60px;
        border-radius: 8px 8px 8px 8px;
        box-shadow: 0px 5px 14px 0px rgba(53, 125, 251, 0.23);
        position: relative;
        img {
            position: absolute;
        }
        i {
            position: absolute;
            color: #ffffff;
            opacity: 0.8;
            font-size: 25px;
            right: 16px;
            bottom: 16px;
        }
    }
    .card.primary {
        background: linear-gradient(262deg, #3687fe 0%, #1a66fd 100%);
    }
    .card.warning {
        background: linear-gradient(77deg, #fdac34 0%, #fd894f 100%);
    }
    .card.purple {
        background: linear-gradient(77deg, #bd7dfb 0%, #af71fa 100%);
    }
    .card.sky {
        background: linear-gradient(77deg, #40dfeb 0%, #29c6d5 100%);
    }

    .lineImg {
        left: 18px;
        top: 18px;
    }
    .cardText {
        font-size: 14px;
        font-family: FZLanTingHeiS-R-GB, FZLanTingHeiS-R-GB-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        position: absolute;
        left: 18px;
        top: 18px;
    }

    .setting-tab{
        opacity: 0.8;
        font-size: 14px;
        /* font-family: PingFangSC, PingFangSC-Medium; */
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 20px;
        letter-spacing: 0.58px;
    }
    .setting-tab-active{
        opacity: 0.8;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: #1478f6;
        line-height: 20px;
        letter-spacing: 0.58px;
        border: 2px solid #0e67ed;
        padding: 2px;
    }
    .item {
        text-align: center;
        .selectBtn {
            margin: 0 auto;
        }
    }
</style>