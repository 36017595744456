
import { TaskGroupApi } from '@/api/task/TaskGroupApi';
import Setting from '@/rest/Setting';
import SystemUtils from '@/utils/SystemUtils';
import BaseService from '../base/BaseService';
/**
 * 项目，任务模块：任务分组
 */
export class TaskGroupService extends BaseService {

    private taskGroupApi = new TaskGroupApi();
    //新增任务分组 http://web.dev.bingo.cloud/ematrixjct/taskcenter/taskservice/v1/taskGroups/createTaskGroup
    public createTaskGroup(parms: any): any {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.createTaskGroup(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //编辑分组
    public updateFieldsById(parms: any): any {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.updateFieldsById(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }
    //删除分组
    public recycleTaskGroup(taskGroupId: string): any {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.recycleTaskGroup({ taskGroupId: taskGroupId }).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    //更新项目任务数量
    public refreshStatistics(parms: any): any {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.refreshStatistics(parms).then(async (res: any) => {
                resolve(res);
            }).catch((err: any) => {
                reject(err);
            });
        })
    }

    /**
    * 项目-回收站-任务分组列表
    * */
    public getByProjectForDelete(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.getByProjectForDelete(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
    * 项目-回收站-任务分组列表--删除
    * */
    public clearById(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.clearById(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
        * 项目-回收站-任务分组列表--还原
        * */
    public restoreTaskGroup(param: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.taskGroupApi.restoreTaskGroup(param).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public newId(systemName: string) {

        return BaseService.newId("task", "taskGroups");
    }


}