
import { defineComponent } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  menuController,
  IonMenu,
  popoverController,
  alertController,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { getInstance } from "@/services/project/projectService";
import SystemUtils from "@/utils/SystemUtils";
import { TaskStageService } from "@/services/task/TaskStageService";
import { ProjectTeamService } from "@/services/project/ProjectTeamService";
import NewTeamRole from "../rolemanager/NewTeamRole.vue";
import NewTeam from "../rolemanager/NewTeam.vue";
import RolePopover from "@/views/team/add/RolePopover.vue";
import { ProjectTeamRolesService } from "@/services/project/ProjectTeamRolesService";
export default defineComponent({
  name: "RoleManager",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    HeaderModal,
    IonMenu,
    popoverController,
  },
  props: {
    isSelPhases: Boolean, //是否选择阶段
    receiveTreeId: String, //树Id(团队的id)
    hasAddMember: Boolean, //是否拥有新增权限
    hasEditMemberRole: Boolean, // 是否拥有编辑权限
  },
  data() {
    const projectService = getInstance();
    return {
      projectService: projectService,
      stageService: new TaskStageService(),
      projectTeamService: new ProjectTeamService(),
      ProjectTeamRolesService: new ProjectTeamRolesService(),
      selValue: "",
      groupList: Array(0), //分组
      staged: {},
      stageList: Array(0), //阶段
      selOptions: Array(0), // 选择树形分组时,每选择一次分组,将分组加入该数组
      selectedObj: {},
      tempRootArr: Array(0),
      page: 0,
      isShowPhases: false,

      //modal title
      projectNameTitle: "团队角色管理",
      //modal title list
      titleList: Array(0),
      //已有角色list
      roleList: Array(0),
      //已有子团队
      childTeamList: Array(0),

      //项目 projectTeamRoleId
      projectTeamRoleId: "",
      //项目projectId
      projectChildId: "",
      //团队Id
      projectTeamId: "",
      //树id
      treeId: "",

      //新增内容
      addContent: [
        { id: 0, name: "新建团队角色" },
        { id: 1, name: "新建子团队" },
      ],
    };
  },
  created() {
    if (this.receiveTreeId) {
      this.treeId = this.receiveTreeId;
    }

    this.titleList.push("团队角色管理");
    // 获取树形分组相关
    //获取团队角色
    //实例treeid "task@project(487cd902a19c4e2486f22f240e56e924)"
    this.projectTeamService.getAllProjectTeam(this.treeId).then(
      (r: any) => {
        if (r) {
          if (r.data[0].childNodes) {
            this.groupList = r.data[0].childNodes;
            this.tempRootArr.push(r.data[0].childNodes);
            this.setRoleData(this.groupList);
          }
          //将团队ID 项目ID 赋值
          this.projectChildId = r.data[0].projectId;
          this.projectTeamId = r.data[0].id;
        }
      },
      (e: any) => {
        console.log(e);
      }
    );
  },
  methods: {
    //每个团队赋值
    setRoleData(groupLists: any) {
      this.childTeamList.splice(0, this.childTeamList.length);
      this.roleList.splice(0, this.roleList.length);
      for (let index = 0; index < groupLists.length; index++) {
        const element = groupLists[index];
        if (element.nodeType == "team") {
          this.childTeamList.push(element);
        } else if (element.nodeType == "teamRole") {
          this.roleList.push(element);
        }
      }
    },

    //点击加号按钮
    async openRolePopover(ev: Event) {
      const popover = await popoverController.create({
        component: RolePopover,
        cssClass: "my-popover-class",
        event: ev,
        translucent: false,
        componentProps: {
          list: this.addContent,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data) {
        if (data.id == 0) {
          this.newTeamRole("add", "", "");
        } else if (data.id == 1) {
          this.newTeam("add", "", "");
        }
      }
    },
    //返回上一级
    backUp() {
      if (this.page <= 0) return;
      this.tempRootArr.splice(this.page, 1);
      this.page--;
      let arrTemp = this.groupList;
      this.groupList = this.tempRootArr[this.page];
      if (this.tempRootArr.length > 0) {
        let t1 = this.tempRootArr[this.tempRootArr.length - 1];
        this.projectChildId = t1[t1.length - 1].projectId;
        this.projectTeamId = t1[t1.length - 1].parentId;
        console.log("this.projectChildId:" + this.projectChildId);
        console.log("this.projectTeamId:" + this.projectTeamId);
      } else {
        if (this.groupList.length > 0) {
          this.projectChildId = this.groupList[0].projectId;
          this.projectTeamId = this.groupList[0].id;
        } else {
          this.projectChildId = "";
          this.projectTeamId = "";
        }
      }

      for (let index = 0; index < this.groupList.length; index++) {
        if (this.groupList[index].id == this.projectTeamId) {
          this.groupList[index].childNodes = arrTemp;
          break;
        }
      }

      this.projectNameTitle = this.titleList[this.page];
      this.setRoleData(this.groupList);
    },
    //点击子团队
    clickGroup(groupObj: any) {
      this.page++;
      this.projectChildId = groupObj.projectId;
      this.projectTeamId = groupObj.id;

      if (groupObj.childNodes) {
        this.groupList = groupObj.childNodes;
        this.tempRootArr.push(this.groupList);
        this.setRoleData(this.groupList);
        this.titleList.push(groupObj.name);
        this.projectNameTitle = groupObj.name;
      } else {
        groupObj = Object.assign(groupObj, { childNodes: new Array(0) });
        this.groupList = groupObj.childNodes;
        this.tempRootArr.push(this.groupList);
        this.setRoleData(this.groupList);
        this.titleList.push(groupObj.name);
        this.projectNameTitle = groupObj.name;
      }
    },

    //新建/编辑团队角色
    async newTeamRole(type: any, name: any, roleObj: any) {
      const modal = await modalController.create({
        component: NewTeamRole,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          datas: {
            type: type,
            roleObj: roleObj,
            name: name,
            projectChildId: this.projectChildId,
            projectTeamId: this.projectTeamId,
            projectNameTitle: this.projectNameTitle,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.type == "add") {
          this.roleList.push(data.roleObj);
          this.groupList.push(data.roleObj);
        } else {
          this.roleList = this.roleList.map((item) =>
            item.id === data.roleObj.id ? data.roleObj : item
          );
          this.groupList = this.groupList.map((item) =>
            item.id === data.roleObj.id ? data.roleObj : item
          );
        }
      }
    },

    //新建/编辑团队
    async newTeam(type: any, name: any, roleObj: any) {
      console.log(this.projectTeamId);
      const modal = await modalController.create({
        component: NewTeam,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          datas: {
            type: type,
            roleObj: roleObj,
            name: name,
            projectChildId: this.projectChildId,
            projectTeamId: this.projectTeamId,
            projectNameTitle: this.projectNameTitle,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();

      if (data) {
        if (data.type == "add") {
          this.childTeamList.push(data.roleObj);
          this.groupList.push(data.roleObj);
        } else {
          this.childTeamList = this.childTeamList.map((item) =>
            item.id === data.roleObj.id ? data.roleObj : item
          );
          this.groupList = this.groupList.map((item) =>
            item.id === data.roleObj.id ? data.roleObj : item
          );
        }
      }
    },
    //删除团队
    async deleteTeam(index: any, option: any) {
       let that=this;
      const alert = await alertController.create({
        header: "删除子团队",
        message: "确定要删除" + option.name + "吗?",
        mode: "ios",
        buttons: [
          { text: "取消" },
          {
            text: "确定",
            handler: () => {
              // console.log(this.datas.labelList);
              const params = [
                {
                  nodeKey: option.id,
                  nodeLabel: option.name,
                  nodeType: "team",
                },
              ];

              this.projectTeamService
                .deleteProjectTeam(params, this.projectChildId)
                .then(
                  (r: any) => {
                    if (r) {
                      that.childTeamList.splice(index, 1);
                      that.tempRootArr.forEach((ele: any) => {
                        let i = ele.indexOf(option);
                        ele.splice(i, 1);
                      });
                    }
                  },
                  (e: any) => {
                    console.log(e);
                  }
                );
            },
          },
        ],
      });

      await alert.present();
    },

    //删除团队角色
    async deleteTeamRole(index: any, option: any) {
      let that=this;
      const alert = await alertController.create({
        header: "删除团队角色",
        message: "确定要删除" + option.name + "吗?",
        mode: "ios",
        buttons: [
          { text: "取消" },
          {
            text: "确定",
            handler: () => {
              // console.log(this.datas.labelList);

              const params = {
                teamRoleId: option.id,
                projectId: option.projectId,
              };

              this.ProjectTeamRolesService.deleteProjectTeamRole(params).then(
                (r: any) => {
                  if (r) {
                    that.roleList.splice(index, 1);
                    that.tempRootArr.forEach((ele: any) => {
                      let i = ele.indexOf(option);
                      ele.splice(i, 1);
                    });
                  }
                },
                (e: any) => {
                  console.log(e);
                }
              );
            },
          },
        ],
      });

      await alert.present();
    },

    closeModel() {
      modalController.dismiss();
    },
    checkPhases(event: any) {
      this.staged = event;
      modalController.dismiss({
        group: this.selectedObj,
        staged: this.staged,
      });
    },
    onCloseMenu() {
      menuController.close();
    },
    enterGroup(option: any) {
      this.page++;
      this.tempRootArr.push(option.childNodes);
      this.groupList = option.childNodes;
    },
    getChildNodeByParentId(parentId: any) {
      this.projectService.getChildNodeByParentId(parentId).then(
        (r: any) => {
          if (r) {
            this.groupList = r.data;
          } else {
            console.log("无分组新");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
  },
});
