import BaseRsp from '@/models/base/BaseRsp';
import Setting from '@/rest/Setting';
import SystemUtils from '@/utils/SystemUtils';
import {ResponseModel} from "@/models/base/ReponseModel";

export interface Service {
    httpServer?: string;
    /**
     * 服务名称
     */
    name?: string,
    /**
     * 版本号
     */
    version?: string;
    /**
     * 资源
     */
    resource?: string;
}

export default class BaseService {

    protected service: Service = {
        name: "task",
        version: "v1",
        resource: ""
    };

    public newModel(systemName: string, table: string) {
        const id = BaseService.newId(systemName, table);
        const user = SystemUtils.loginUser;
        return {
            id: id,
            creatorId: user.id,
            creator: user.name,
            modifierId: user.id,
            modifier: user.name,
            isDeleted: false,
            remark: "",
            enterpriseId: Setting.currentEnterprise.id,
            systemId: SystemUtils.systemId
        };
    }

    public newId(systemName?: string) {
        let tmpRes = "";
        if (this.service.resource)
            tmpRes = this.service.resource.toLocaleLowerCase();
        tmpRes = tmpRes.substring(0, tmpRes.length - 1);
        if (systemName != "" && systemName != undefined) {
            return BaseService.newId(systemName, tmpRes);
        } else {
            return BaseService.newId("task", tmpRes);
        }
    }

    public static newId(systemName: string, table: string) {
        const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        //id = id.replace('/\-/g', "");
        return systemName + "@" + table + "(" + id + ")";
    }

    /**
     * 构建本地校验不通过时返回的提示信息
     * @param tip 提示信息
     */
    protected errRsp(tip: string): Promise<BaseRsp> {
        return new Promise((resolve, reject) => { 
            let errRsp:BaseRsp = new BaseRsp;
            errRsp.message = tip;
            reject(errRsp); 
        });
    }

    /**
     * 去除字符串两侧空格
     * @param str 字符串
     */
    trim(str:string){
        const reg = /^\s+|\s+$/g;
        return str.replace(reg,'');
    }

}

export interface Response<T> extends ResponseModel{
    data:T;
}

export interface AllResponse<T> extends ResponseModel{
    data:Array<T>;
}
