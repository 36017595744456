
import { alertController } from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import { useRouter } from "vue-router";
import Issues from "@/models/microIssues/Issues";
import IssuesChat from "@/views/message/IssuesChat.vue";
import { modalController } from "@ionic/vue";
import IssuesMenu from "../menu/IssuesMenu.vue";
import { getInstance } from "@/services/MicroIssuesService";
import { TaskService } from "@/services/task/TaskService";
import { ProjectPageService } from "@/services/projectPageService";
import BaseRsp from "@/models/base/BaseRsp";
import Utils from "@/utils/Utils";
import { log } from "util";
import Member from "@/models/microIssues/Member";
import SystemUtils from "@/utils/SystemUtils";
import Setting from "@/rest/Setting";
import { MessageService } from "@/services/message/MessageService.ts";

export default defineComponent({
  name: "MicroIssuesInfo",
  components: {
    Header,
    IssuesChat,
  },
  data() {
    const params = this.$route.params;
    const btnIconRight = ["icongengduo"];
    const btnSizeRight = ["45px"];
    const service = getInstance();
    const router = useRouter();
    return {
      router: router,
      taskService: new TaskService(),
      projectService: new ProjectPageService(),
      isPull: false,
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      issues: new Issues(),
      params: params,
      service: service,
      isMember: false,
      $refs: {
        chat: IssuesChat,
      },
      isSvw: false,
      selectOperate: "-1",
      messageService: new MessageService(),
      selectList: new Array(),
    };
  },
  created() {
    this.issues = JSON.parse(this.params.issues as string);
    this.isSvw = Setting.isSVW;
    this.settingMessageEnable(this.issues.isDeleted);
    if (this.issues.isDeleted) {
      if (this.issues.taskId) {
        // 获取任务信息
        this.taskService.getTaskInfo(this.issues.taskId).then((res) => {
          let header: any = this.$refs.header;
          if (header) header.clearRightBtn(res.task.isDeleted);
        });
      } else {
        // 获取项目信息
        this.projectService
          .getProjectInfoById({ id: this.issues.projectId })
          .then((r: any) => {
            if (r && r.data && r.data[0]) {
              let header: any = this.$refs.header;
              if (header) header.clearRightBtn(r.data[0].isDeleted);
            }
          });
      }
    }
  },

  ionViewWillEnter() {
    this.getIssuesInfo(this.issues.id + "");
  },

  methods: {
    pullShow() {
      if (this.isPull == false) {
        this.isPull = true;
      } else {
        this.isPull = false;
      }
    },

    CloseDialog() {
      this.isPull = false;
    },

    settingMessageEnable(isDeleted: boolean) {
      let chat: any = this.$refs.chat;
      if (chat) chat.settingMessageEnable(!isDeleted && this.isMember);
    },

    async openIssuesMenuModal() {
      // const modal = await modalController.create({
      //   component: IssuesMenu,
      //   cssClass: "issues-bottom-modal-class",
      //   componentProps: {
      //     issues: JSON.stringify(this.issues),
      //     isCollect: this.issues.isDeleted,
      //     issuesId: this.issues.id,
      //     isSVW: this.isSvw,
      //     issuesTitle: this.issues.title,
      //     chatGroupId: this.issues.groupId,
      //   },
      // });
      // await modal.present();
      // const { data } = await modal.onWillDismiss();
      // if (data) {
      //   this.issues.isDeleted = data.isCollect;
      //   this.settingMessageEnable(this.issues.isDeleted);
      // }
      let selectOperate: any = document.getElementById("select-operate-issues");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    initSelectList() {
      this.selectList = [];
      if (this.hasPermission("") && !this.issues.isDeleted) {
        this.selectList.push({ value: "edit", text: "编辑基本信息" });
      }
      if (this.hasPermission("") && !this.issues.isDeleted && !this.isSvw) {
        this.selectList.push({ value: "setting", text: "微议题设置" });
      }
      if (this.hasPermission("")) {
        this.selectList.push({
          value: "recyle",
          text: this.issues.isDeleted ? "还原" : "移至回收站",
        });
      }
      if (this.issues.isDeleted) {
        this.selectList.push({ value: "delete", text: "彻底删除" });
      }
    },
    bindOperate(e: any) {
      this.selectOperate = e.detail.value;
      if (this.selectOperate == "edit") {
        //选中select后需要重置，否则再点select不会触发change
        this.selectOperate = "-1";
        this.editIssuesInfo();
      } else if (this.selectOperate == "setting") {
        this.selectOperate = "-1";
        this.issuesSetting();
      } else if (this.selectOperate == "recyle") {
        this.selectOperate = "-1";
        this.moveToRecycle();
      } else if (this.selectOperate == "delete") {
        this.selectOperate = "-1";
        this.thoroughDelete();
      } else {
        this.selectOperate = "-1";
      }
    },
    hasPermission(code: string) {
      return true;
    },
    // 编辑微议题信息
    editIssuesInfo() {
      modalController.dismiss();
      this.router.push({
        name: "MicroIssuesAdd",
        params: {
          isEdit: "true",
          issues: JSON.stringify(this.issues) + "",
        },
      });
    },

    // 微议题设置
    issuesSetting() {
      console.log("微议题设置");
      Utils.presentToast("敬请期待！");
      //  modalController.dismiss();
    },

    //移到回收站
    async moveToRecycle() {
      const alert = await alertController.create({
        header: this.issues.isDeleted ? "还原" : "移至回收站",
        mode: "ios",
        message: this.issues.isDeleted
          ? "您确定要把此微议题还原吗？"
          : "您确定要把此微议题移至回收站吗？",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            // handler: () => {
            //   console.log("Confirm Cancel");
            // },
          },
          {
            text: "确定",
            handler: () => {
              this.service
                .recycleIssues(this.issues.id + "", this.issues.isDeleted)
                .then(
                  (rsp: BaseRsp) => {
                    let contentType = this.issues.isDeleted
                      ? "还原"
                      : "移至回收站";
                    Utils.presentToastSuccess(
                      this.issues.isDeleted ? "微议题还原成功" : "已移至回收站"
                    );
                    this.issues.isDeleted = !this.issues.isDeleted;
                    // modalController.dismiss({ isCollect: this.issues.isDeleted });
                    // this.router.go(-1);
                    let content = "微议题" + contentType;
                    this.sendMessage(3, content);
                  },
                  (err: BaseRsp) => {
                    Utils.presentToastWarning(err.data.msg);
                    this.settingMessageEnable(this.issues.isDeleted);
                    // modalController.dismiss({ isCollect: this.issues.isDeleted });
                  }
                );
            },
          },
        ],
      });
      await alert.present();
    },
    //彻底删除
    async thoroughDelete() {
      const alert = await alertController.create({
        header: "删除",
        mode: "ios",
        message: "您确定删除此微议题吗？",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "确定",
            handler: () => {
              this.service.clearById(this.issues.id + "").then(
                (rsp: BaseRsp) => {
                  Utils.presentToastSuccess("删除成功");
                  this.settingMessageEnable(this.issues.isDeleted);
                  // modalController.dismiss({ isCollect: this.issues.isDeleted });
                //  this.router.go(-1);
                  let content = "微议题物理删除";
                  this.sendMessage(3, content);
                },
                (err: BaseRsp) => {
                  Utils.presentToastWarning("删除失败");
                  this.settingMessageEnable(this.issues.isDeleted);
                  //  modalController.dismiss({ isCollect: this.issues.isDeleted });
                }
              );
            },
          },
        ],
      });
      await alert.present();
    },
    // 发送消息
    sendMessage(msgType: number, content?: string) {
      let busTag={
        projectId: this.issues.projectId,
        projectName: this.issues.projectName,
        taskId: this.issues.taskId
      };
      this.messageService
        .sendMessageExpose(
          this.issues.groupId + "",
          content + "",
          msgType,
          "tt_topic",
          this.issues.id + "",
          this.issues.title + "",
         busTag,
          ""
        )
        .then(
          (rsp: BaseRsp) => {
            //  modalController.dismiss({ isCollect: this.issues.isDeleted });
            this.settingMessageEnable(this.issues.isDeleted);
            this.router.go(-1);
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
          }
        );
    },
    getIssuesInfo(issuesId: string) {
      this.service.getIssuesInfo(issuesId).then(
        (rsp: any) => {
          this.issues = rsp;
          if (rsp.topicParticipantList)
            for (let member of rsp.topicParticipantList) {
              if (member.userId == SystemUtils.loginUser.id) {
                this.isMember = true;
                break;
              }
            }
          this.settingMessageEnable(this.issues.isDeleted);
          this.initSelectList();
        },
        (err: BaseRsp) => {
          Utils.presentToastWarning(err.message);
        }
      );
    },
  },
});
