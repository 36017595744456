/**
 * scheduleFeedback 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class ScheduleRelationApi extends TaskBaseService {
    constructor() {
        super("scheduleRelation");
    }

     //任务关联日程
    public taskLinkSchedule(params: any): Promise<any> {
        const path = "insertScheduleRelationList";
        return this.request(path,this.rest.METHOD_POST, params);       
    }
    //刪除關聯日程 deleteScheduleRelation
    public deleteLinkSchedule(params:String):Promise<any>{
        const path ='deleteScheduleRelation';
        return this.request(path,this.rest.METHOD_POST,params)
    }
}