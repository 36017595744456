
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { modalController, IonPage, IonContent, IonText } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { ProjectStatusChangesService } from "@/services/project/ProjectStatusChangesService";
import Utils from "@/utils/Utils";

export default defineComponent({
  name: "HistoryUpdatedModal",
  components: {
    IonPage,
    IonContent,
    HeaderModal,
    IonText,
  },
  props: {
    projectId: String,
  },
  setup(props) {
    const { projectId } = toRefs(props);
    const projectStatusChangesService = new ProjectStatusChangesService();
    const dataList = reactive({ data: new Array<any>() });

    //加载列表数据
    let loadDataList = (tId: string) => {
      projectStatusChangesService.getByProjectIdForLsit(tId).then(
        (r: any) => {
          if (r && r.data) {
            dataList.data = r.data;
          }
        },
        (e: any) => {
          console.log("加载列表异常：" + e);
        }
      );
    };

    let subStringUserName = (name: string) => {
      return Utils.subStringUserName(name, 2);
    };

    //关闭模态框
    let closeModel = () => {
      modalController.dismiss();
    };

    //加载页面后绑定事件
    onMounted(() => {
      if (projectId != undefined && projectId.value != undefined) {
        loadDataList(projectId.value);
      }
    });

    return {
      dataList,
      closeModel,
      subStringUserName,
    };
  },
});
