
import { menuController } from "@ionic/vue";
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import SearchPanel from "./component/SearchPanel.vue";
import DashboardObj from "./component/DashboardObj.vue";
import { useRouter } from "vue-router";
import { DashboardService } from "@/services/dashboardService";
import CardLoading from "@/components/ListCard/CardLoading.vue";

export default defineComponent({
  name: "Dashboard",
  props: {
    isHome: String,
  },
  data() {
    const router = useRouter();
    const btnIconRight = ["iconsousuo"];
    const btnSizeRight = ["34px"];
    return {
      isBack: true,
      api: new DashboardService(),
      router: router,
      projectLists: new Array(0),
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      parameters: {
        pageNum: 1,
        pageSize: 20,
        orderBy: "is_collection desc, hits desc",
      },
      showNoData: false, //展示暂无数据
      islist: true, //是否显示加载中的标识
    };
  },
  components: {
    Header,
    SearchPanel,
    DashboardObj,
    CardLoading
  },
  watch: {
    $route() {
      this.resetPage();
    },
  },
  created() {
    this.initTaskData();
  },
  methods: {
    initTaskData() {
      this.resetPage();
    },
    //滚动到顶部
    scrollToTop() {
      const ionContent: any = document.querySelector("#Dashboard");
      if (ionContent) {
        ionContent.scrollToTop();
      }
    },
    //重新从第一页获取
    resetPage() {
      this.parameters.pageNum = 1;
      this.getListRequest((res: any) => {
        this.projectLists = res;
        this.showNoData = res == null || res.length <= 0;
        this.scrollToTop();
      });
    },
    nextPage(event: any) {
      this.parameters.pageNum++;
      this.getListRequest((result: any) => {
        this.projectLists = this.projectLists.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.api.getDashboardList(this.parameters).then((res: any) => {
        this.islist = false;
        if (res.data.data && res.data.data.rows) {
          callback(res.data.data.rows);
        }
      });
    },
    onFiltClick() {
      menuController.open();
    },
    itemClick(project: any) {
      this.router.push({
        path: "/dashboardDetails",
        query: {
          projectId: project.id,
          projectName: project.projectName,
          isCollection: project.isCollection,
          projectPeriod: project.projectPeriod,
          gray: project.color.gray,
          green: project.color.green,
          red: project.color.red,
          redYellow: project.color.redYellow,
          yellow: project.color.yellow,
          sopTime: project.sopTime,
          carBodyPlattform: project.carBodyPlattform,
          projectType: project.projectTypeDictText,
          factoryName: project.factoryNameText,
          planningVolume: project.planningVolume,
        },
      });
    },
    onSearch(obj: any) {
      this.parameters = Object.assign(this.parameters, obj);
      this.resetPage();
    },
  },
});
