
import { defineComponent } from "vue";
import { alertController,modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import Utils from "@/utils/Utils";
import DocService from "@/services/doc/docService";
import SystemUtils from "@/utils/SystemUtils";
import FolderTreeVue from "./FolderTree.vue";

export default defineComponent({
  name: "folderList",
  components: {modalController},
  props: {
    projectId: String,
    folderId:String,
    isHasPermission:  {
      type: Object,
      required: false,
      default: {
        isHasDel: false, //删除权限
        isHasPutArchive: false, //移到回收站权限/恢复回收站权限
      },
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    return {
      docService: new DocService(),
      folderList: new Array(0),
      router: router,
      useRoute: useRoute(),
      searchParams: _param,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.resetSearchParam();
      this.resetPage();
    },
    resetSearchParam() {
      this.searchParams = {
        limit: 20,
        offset: 1,
        rootId: this.folderId,
      };
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        if (res == null || res.length <= 0) {
         this.folderList = [];
        } else {
          this.folderList = res;
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.folderList = this.folderList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
          //  event.target.disabled = true;
          }
        }
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    getListRequest(callback: any) {
        this.docService
          .getPagedRootDeletedFolders(this.searchParams)
          .then((res: any) => {
            callback(res.data);
          });
    },
    //删除
    async clearById(id: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要把该文件夹删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
                this.docService.clearFolder(id).then((res: any) => {
                  if(res){
                   this.resetPage();
                  }
                });
            },
          },
        ],
      });
      await alert.present();
    },
    
    async projectRecycle(id: string) {
      const modal = await modalController.create({
        component: FolderTreeVue,
        backdropDismiss: false,
        cssClass: "notice-modal-class",
        componentProps: {
          projectId: this.projectId
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.folderId) {
        this.restoreById(id, data.folderId);
      }
    },
    //还原
     restoreById(folderId: any,targetFolderId: any) {
              //保存数据库
                this.docService.restoreFolder(folderId,targetFolderId).then((res: any) => {
                  this.resetPage();
                });
    },
    formatTime(modifyTime: any) {
      return SystemUtils.fromatTime(modifyTime);
    },
  },
});
