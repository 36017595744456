/**
 * 任务分组
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 首页
 */
export class TaskGroupApi extends TaskBaseService {

    constructor() {
        super("taskGroups");
    }

    /**
     * 获取任务分组（仅项目内任务才有）
     * @param param projectId
     */
    public list(param: any): Promise < any > {
        const path = 'getByProjectId';
        return this.request(path, this.rest.METHOD_POST, param);
    }


    /**
     * 项目任务-任务分组
     * @param param projectId
     */
    public getByProjectIdForMennu(param: any): Promise < any > {
        const path = 'getByProjectIdForMennu';
        return this.request(path, this.rest.METHOD_GET, param);
    }
    //新增任务分组
    public createTaskGroup(param: any): Promise < any > {
        const path = 'createTaskGroup';
        return this.request(path, this.rest.METHOD_POST, param);
    }
    public recycleTaskGroup(param: any): Promise < any > {
        const path = 'recycleTaskGroup?taskGroupId='+param.taskGroupId;
        return this.request(path, this.rest.METHOD_POST, null);
    }
     //更新
     public refreshStatistics(params:any){
        return this.request("refreshStatistics?id="+params.id+"&isRefresh="+params.isRefresh,this.rest.METHOD_POST,null);
    }

    /**
     * 项目-回收站-任务分组列表
     * */
    public getByProjectForDelete(param: any) {
        return this.request("getByProjectForDelete", this.rest.METHOD_GET, param);
    }

    /**
     * 项目-回收站-任务分组列表-删除
     * */
    public clearById(param: any) {
        return this.request("clearById?id="+param.id, this.rest.METHOD_DELETE, null);
    }

    /**
     * 项目-回收站-任务分组列表-还原
     * */
    public restoreTaskGroup(param: any) {
        return this.request("restoreTaskGroup?taskGroupId="+param.id, this.rest.METHOD_POST, null);
    }

}