/**
 * ProjectPage 项目详情-概览 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 项目
 */
export class ProjectTeamRolesApi extends TaskBaseService {

    constructor() {
        super("projectTeamRoles");
    }
    //查询项目团队是否有该角色
    public getProjectExistsTeamRole(params: any): Promise<any> {
        const path = 'existsTeamRole';

        return this.request(path, this.rest.METHOD_GET, params);
    }

    //添加该团队角色
    public addProjectTeamRole(params: any): Promise<any> {
        const path = '';

        return this.request(path, this.rest.METHOD_POST, params);
    }
    //编辑该团队角色
    public editProjectTeamRole(params: any): Promise<any> {
        const path = '';

        return this.request(path, this.rest.METHOD_PUT, params);
    }
    //删除该团队角色
    public deleteProjectTeamRole(params: any): Promise<any> {
        const path = 'deleteTeamRoleById?teamRoleId=' + params.teamRoleId + '&projectId=' + params.projectId;

        return this.request(path, this.rest.METHOD_DELETE, {});
    }
}