/**
 * 单文件上传实体
 */
export interface SingleFile {
    file?: Blob,//文件流媒体
    folderId: string,//文件存放文件夹标识
    systemId: string,//文件存储系统标识
    busId?: string,
    busTable?: string,
    busTag?: string,
    name?: string,
    extName?: string,
    size?: number,
    documentId?: string //用户修改文件标识
}

/**
 * 缩略图请求实体
 */
export interface ThumbnailImage {
    id: string,
    objectKey: string
}

export interface ChunkFileParam {
    chunkNumber: number,
    uploadId: string
}

export interface CheckFileParam {
    folderId: string,
    name: string,
    systemId: string,
    documentId?: string
}

export interface UploadFileParam {
    id: string,
    name: string,
    type: string,
    lastModifiedDate: Date,
    size: number,
    chunkSize: number,
    chunk: number,
    uploadId: string,
    bucketName: string,
    objectKey: string,
    file: Blob
}

export interface MergeFile {
    bucketName: string,
    busId: string,
    busTable: string,
    className: string,
    clickCount: number,
    createTime: string,
    creator: string,
    creatorId: string,
    dataState: number,
    dctmAddr: string,
    downloadCount: number,
    downloadUrl: string,
    extName: string,
    folderId: string,
    id: string,
    isDctm: string,
    isDeleted: boolean,
    isMain: boolean,
    isParentDeleted: string,
    modifier: string,
    modifierId: string,
    modifyTime: string,
    name: string,
    objectKey: string,
    originalUrl: string,
    previewUrl: string,
    remark: string,
    rootId: string,
    size: number,
    sizeTitle: string,
    systemId: string,
    uploadId: string,
    versionName: string,
    documentId: string
}

export const MergeFileParam: MergeFile = {
    bucketName: "",
    busId: "",
    busTable: "",
    extName: "",
    folderId: "",
    id: "",
    name: "",
    objectKey: "",
    rootId: "",
    size: 0,
    systemId: "",
    uploadId: "",
    className: "",
    documentId: "",

    isDctm: "",
    isDeleted: false,
    isMain: true,
    isParentDeleted: "",
    modifier: "",
    modifierId: "",
    modifyTime: "",
    originalUrl: "",
    previewUrl: "",
    remark: "",
    sizeTitle: "",
    versionName: "",
    clickCount: 0,
    createTime: "",
    creator: "",
    creatorId: "",
    dataState: -1,
    dctmAddr: "",
    downloadCount: 0,
    downloadUrl: "",
}

