
  import { defineComponent } from "vue";
  import Header from "@/components/Header.vue";
  import { KeyResultService } from "@/services/okr/KeyResultService";
  import KeyResult from "@/models/okr/KeyResult";
  import Utils from "@/utils/Utils";
  import moment from "moment";
  import { ObjectiveService } from "@/services/okr/ObjectiveService";
  import AvatarList from "@/components/AvatarList.vue";
  import TaskCards from "@/components/ListCard/TaskCards.vue";

  export default defineComponent({
    name: "KeyResultInfo",
    components: {
      Header,
      AvatarList,
      TaskCards,
    },
    data() {
      const params = this.$route.query;
      const service = new KeyResultService();
      const kr = new KeyResult();
      const objectiveService = new ObjectiveService();
      return {
        showTask:true,//展示任务列表
        isShow: false, //控制互转效果
        service: service,
        objectiveService: objectiveService,
        kId: params.id,
        kr: kr,
        statusList: Array(0),
      };
    },

    created() {
      this.service
        .getDetailById(this.kId as string)
        .then(
          (res: any) => {
            this.kr = res;
            this.getTaskListData(this.kr.id as string);
            this.getStatusList();
          },
          (err: any) => {
            Utils.presentToastWarning(err.message);
          }
        )
        .catch((err: any) => {
          Utils.presentToastWarning(err.message);
        });
    },

    methods: {
      showOrHideProgressList() {
        this.isShow = !this.isShow;
      },
      showOrHideTaskList() {
        this.showTask = !this.showTask;
      },

      // 获取任务列表
      getTaskListData(krId: string) {
        this.objectiveService
          .objectivelinkTaskList({
            limit: 1000,
            offset: 1,
            sourceId: krId,
            sourceType: 2,
          })
          .then((res: any) => {
            this.kr.linkTasks = res;
          })
          .catch((err: any) => {
            Utils.presentToastWarning(err.message);
          });
      },

      avatarData(getData: any) {
        let users: any = [];
        if (getData) {
          let userArr = getData.taskUser
            .filter((item: any) => {
              return item.workType == 1;
            })
            .map(function (e: any, i: number) {
              return e.name;
            });
          let usersName = userArr;
          usersName.map((item: any, index: any) => {
            //  把原数组循环一下
            let obj = {
              name: item,
              img: ""
            };
            users.push(obj); //  img.jpg 数组的每一行添加一个 disabled:'true'  属性
          });
        }
        return users;
      },

      getStatusList() {
        for (let log of this.kr.progressLogs) {
          this.statusList.push(this.service.getStatus(this.kr, log));
        }
        console.log(JSON.stringify(this.statusList));
      },

      openTaskInfo(task: any) {
        this.$router.push({
          path: "/task-page",
          query: {
            id: task.id,
            taskName: task.name,
          },
        });
      },
    },
  });
