
import { defineComponent } from "vue";
import { getInstance } from "@/services/project/projectService";
import {
  IonPage,
  IonContent,
  IonText,
  IonInput,
  IonTextarea,
  IonIcon,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";

import Utils from "@/utils/Utils";
import Settings from "@/rest/Setting";
import ChooseOrganization from "@/views/project/modal/ChooseOrganization.vue";
import ProjectOpennessModal from "@/views/project/modal/ProjectOpennessModal.vue";
import ProjectTypeModal from "@/views/project/modal/ProjectTypeModal.vue";
import ProjectGroupingModal from "@/views/project/modal/ProjectGroupingModal.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ProjectAdd",
  components: {
    Header,
    IonContent,
    IonText,
    IonInput,
    IonTextarea,
    IonIcon,
  },
  data() {
    const btnTextRight = ["iconduihao"];
    const btnSizeRight = ["26px"];
    const projectService = getInstance();
    const router = useRouter();
    return {
      btnTextRight: btnTextRight,
      btnSizeRight: btnSizeRight,
      groupingOption: { id: "", name: "" },
      opennessOption: { key: 1, value: "非公开" },
      typeOption: {
        id: "task-projecttype-1cbd5646-3bfd-4ad0-8567-89b58ca37385",
        name: "任务中台",
      },
      organization: { id: "", name: "", description: "" },
      staged: { id: "" },
      projectService: projectService,
      router: router,
      fromData: {
        pubickCheck: 0,
        no: "",
        name: "", //项目名称
        organizationId: "",
        organizationName: "",
        organizationNameCn: "",
        organizationNameDe: "",
        organizationNameEn: "",
        beginTime: "",
        endTime: "",
        projectGroupId: "", //项目分组Id
        projectTypeId: "", //项目类型id
        isPublic: false,
        synopsis: "", //简介
        taskNoPrefix: "", //任务前缀
      },
      isSaveFinish: true, //是否保存成功,防止连续点击

      isShowMore: true,
      projectTypeList: new Array(), //项目类型
    };
  },
  mounted() {
    this.getProjectType();
  },
  methods: {
    // 新建（标题栏 “完成” 按钮）
    onRightClick() {
      const parms = this.fromData;
      if (this.groupingOption.id) {
        parms.projectGroupId = this.groupingOption.id;
      }
      if (this.organization.id) {
        parms.organizationId = this.organization.id;
        parms.organizationName = this.organization.name;
        parms.organizationNameCn = this.organization.description;
        parms.organizationNameDe = this.organization.name;
        parms.organizationNameEn = this.organization.name;
      }
      //选择公开性现在key为 0 1
      if (this.opennessOption.key == 0) {
        parms.isPublic = true;
      }
      parms.projectTypeId = this.typeOption.id;
      this.fromData.name = this.fromData.name.replace(/\s*/g, "");
      if (!this.fromData.name) {
        Utils.presentToastWarning("请输入项目名称");
        return;
      } else {
        if (this.fromData.name.length > 200) {
          Utils.presentToastWarning("项目名称不能超过200个字符");
          return;
        }
      }
      if (this.fromData.no.length > 50) {
        Utils.presentToastWarning("项目编号不能超过50个字符");
        return;
      }

      if (
        this.fromData.beginTime &&
        this.fromData.beginTime != "" &&
        this.fromData.endTime &&
        this.fromData.endTime != ""
      ) {
        let startDate = new Date(this.fromData.beginTime.replace(/\-/g, "/"));
        if (this.fromData.beginTime.indexOf("T") > -1) {
          startDate = new Date(
            this.fromData.beginTime.substring(0, 10) +
              " " +
              this.fromData.beginTime.substring(11, 16)
          );
        }
        let endDate = new Date(this.fromData.endTime.replace(/\-/g, "/"));
        if (this.fromData.endTime.indexOf("T") > -1) {
          endDate = new Date(
            this.fromData.endTime.substring(0, 10) +
              " " +
              this.fromData.endTime.substring(11, 16)
          );
        }

        if (startDate.getTime() > endDate.getTime()) {
          Utils.presentToastWarning("开始日期不能晚于结束日期");
          return;
        }

      }

      this.fromData.beginTime = this.fromData.beginTime.substring(0, 10);
      this.fromData.endTime = this.fromData.endTime.substring(0, 10);

      
      

      if (this.fromData.taskNoPrefix.length > 50) {
        Utils.presentToastWarning("任务前缀不能超过50个字符");
        return;
      }

      if (this.fromData.synopsis.length > 200) {
        Utils.presentToastWarning("项目简介不能超过200个字符");
        return;
      }
      if (this.isSaveFinish) {
        this.isSaveFinish = false;
        this.projectService.createProjectForData(parms).then(
          (r: any) => {
            if (r) {
              //跳转项目列表页面
              this.router.go(-1);
            } else {
              console.log("未选择信息");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      } else {
        Utils.presentToastWarning("操作过于频繁，请稍后再试");
      }
    },
    // 分组Modal
    async openProjectGroupingModal() {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.groupingOption.id,
          isSelPhases: false,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.group) {
        this.groupingOption.id = data.group.id;
        this.groupingOption.name = data.group.name;
      }
    },
    // 所属机构Modal
    async openChooseOrganizationModal() {
      const modal = await modalController.create({
        component: ChooseOrganization,
        cssClass: "notice-modal-class",
        componentProps: {
          finalSelId: this.organization.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.refresh) {
        this.organization.id = data.selectedObj.orgId;
        this.organization.name = data.selectedObj.shortName;
        this.organization.description = data.selectedObj.description;
      }
    },
    // 公开性Modal
    async openProjectOpennessModal() {
      const modal = await modalController.create({
        component: ProjectOpennessModal,
        cssClass: "bottom-openness-class",
        componentProps: {
          selIndex: this.opennessOption.key,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.opennessOption = data.value;
      }
    },
    // 类型Modal
    async openProjectTypeModal() {
      const modal = await modalController.create({
        component: ProjectTypeModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: this.typeOption.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.typeOption.id = data.typeObj.id;
        this.typeOption.name = data.typeObj.name;
      }
    },
    openProjectType() {
      let projectType: any = document.getElementById("select-project-type");
      if (projectType) {
        let el: HTMLElement = projectType;
        el.click();
      }
    },
    getProjectType() {
      this.projectService.getBySystemId({}).then(
        (r: any) => {
          if (r) {
            this.projectTypeList = r.data;
            this.typeOption.id = this.projectTypeList[0].id;
            this.typeOption.name = this.projectTypeList[0].name;
          } else {
            console.log("无类型");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    bindProjectType(ev: any) {
      let that = this;
      that.typeOption.id = ev.detail.value;
      let list = this.projectTypeList.filter((ele: any) => {
        return ele.id == that.typeOption.id;
      });
      if(list.length>0){
        that.typeOption.name=list[0].name;
      }
    },
    showMore() {
      this.isShowMore = false;
    },
  },
});
