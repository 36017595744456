
import { modalController, alertController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { getInstance } from "@/services/project/projectService";
import BaseService from "@/services/base/BaseService";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
// 权限 类
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import { defineComponent } from "vue";
// import { utils } from "gantt";

export default defineComponent({
  name: "ProjectMenu",
  props: {
    project: Object,
    projectId: String,
    isStart: String,
    projectName: String,
    projectPermission: Object,
    isFinished: Boolean,
    isDeleted: Boolean,
  },
  data() {
    const router = useRouter();
    const projectService = getInstance();
    return {
      isCollect: this.isStart,
      router: router,
      projectService: projectService,
    };
  },
  mounted() {
    let count = 3;
    if (this.project != undefined && this.projectPermission != undefined) {
      if (!this.project.isDeleted && this.projectPermission.isHasTransfer) {
        count += 1;
      }
      if (
        !this.project.isDeleted &&
        this.projectPermission.isHasSuspend &&
        !this.isFinished
      ) {
        count += 1;
      }
      if (
        !this.project.isDeleted &&
        this.projectPermission.isHasSuspend &&
        this.isFinished
      ) {
        count += 1;
      }
      if (this.projectPermission.isHasArchive && !this.isDeleted) {
        count += 1;
      }
      if (this.isDeleted && this.projectPermission.isHasArchive) {
        count += 1;
      }
      if (this.isDeleted && this.projectPermission.isHasDel) {
        count += 1;
      }
    }
    Utils.resetModalHei(count);
  },
  methods: {
    closeModel() {
      modalController.dismiss({ isCollect: this.isCollect });
    },
    //成员管理
    memberManage() {
      modalController.dismiss();
      this.router.push({
        path: "/project/member-manage",
        query: {
          projectId: this.projectId,
          title: this.projectName,
          tabIndex: 1,
        },
      });
    },

    //项目转移
    projectTransfer() {
      modalController.dismiss({ openTransfer: true });
    },
    //项目设置
    projectSetting() {
      modalController.dismiss({ openSetting: true });
    },
    //标签
    tagManage() {
      modalController.dismiss({ openTag: true });
    },
    //项目收藏
    collect() {
      //  this.isCollect = !this.isCollect;
      const params = {
        userId: SystemUtils.loginUser.id,
        projectId: this.projectId,
        isStart: this.isCollect == "true" ? 1 : 0,
      };
      this.projectService.updateForProjectList(params).then(
        (r: any) => {
          if (r) {
            this.isCollect = this.isCollect == "true" ? "false" : "true";
          }
        },
        (e: any) => {
          console.log(e);
          Utils.presentToastCenter("收藏失败", "warning");
        }
      );
    },
    //归档
    async pigeonhole(type: any) {
      let info = type == 1 ? "归档" : "取消归档";
      const alert = await alertController.create({
        header: "项目归档",
        message: "您确定要把此项目" + info + "吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                projectId: this.projectId,
                isFinsh: type,
              };
              this.projectService.setFinishById(params).then(
                (r: any) => {
                  if (r) {
                    modalController.dismiss({ isReload: "true" });
                  }
                },
                (e: any) => {
                  Utils.presentToastCenter(info + "失败", "warning");
                }
              );
            },
          },
        ],
      });

      await alert.present();
    },
    //移到回收站
    async moveToRecycle(isDeleted: boolean) {
      const alert = await alertController.create({
        header: isDeleted ? "还原" : "移至回收站",
        message:
          "您确定要把此项目" + (isDeleted ? "还原" : "移至回收站") + "吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: () => {
              console.log("Confirm Ok");
              //保存数据库
              if (isDeleted) {
                this.projectService.recycleProject(this.projectId).then(
                  (r: any) => {
                    if (r) {
                      modalController.dismiss({ isReload: "true" });
                    }
                  },
                  (e: any) => {
                    Utils.presentToastWarning("移至回收站失败");
                  }
                );
              } else {
                this.projectService.deleteProjectDataById(this.projectId).then(
                  (r: any) => {
                    if (r) {
                      modalController.dismiss({ isReload: "true" });
                    }
                  },
                  (e: any) => {
                    Utils.presentToastWarning("还原失败");
                  }
                );
              }
            },
          },
        ],
      });

      await alert.present();
    },
    //回收站
    recycleInfo() {
      modalController.dismiss({ openRecycle: true });
    },
    //还原
    async restore() {
      const alert = await alertController.create({
        header: "还原项目",
        message: "您确定要还原此项目",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.projectService.recycleProject(this.projectId).then(
                (r: any) => {
                  if (r) {
                    Utils.presentToast("项目还原成功");
                    modalController.dismiss({ isReload: "true" });
                  }
                },
                (e: any) => {
                  Utils.presentToastWarning("项目还原失败");
                }
              );
            },
          },
        ],
      });
      await alert.present();
    },
    //彻底删除
    async thoroughDelete() {
      const alert = await alertController.create({
        header: "删除项目",
        message: "您确定要彻底删除此项目",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.projectService.clearProject(this.projectId).then(
                (r: any) => {
                  if (r) {
                    Utils.presentToast("项目删除成功");
                    modalController.dismiss({ isReload: "true" });
                  }
                },
                (e: any) => {
                  Utils.presentToastWarning("项目删除失败");
                }
              );
            },
          },
        ],
      });

      await alert.present();
    },
  },
});
