
import { menuController, modalController, popoverController } from "@ionic/vue";
import { defineComponent } from "vue";
//时间格式化
import moment from "moment";
import Utils from "@/utils/Utils";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SearchConditionManageModal from "./modal/SearchConditionManageModal.vue";
import AvatarList from "@/components/AvatarList.vue";
//数据来源接口
import { TaskService } from "@/services/task/TaskService";
import { SearchPanelService } from "@/services/searchPanel/SearchPanelService";

import BaseService from "@/services/base/BaseService";

export default defineComponent({
  name: "CallendarSearchPanel",
  props: {
    sourceData: Array,
    selectConditionId: String, //列表中选中的Id
  },
  components: {
    AvatarList,
  },
  emits: ["on-close"],
  data() {
    const searchPanelService = new SearchPanelService();
    return {
      searchPanelService: searchPanelService,
      newSName: "",
      isCondition: false,
      isPull: false,
      searchNameArr: new Array(0),
      priorityArr: [
        { name: "全部", value: "" },
        { name: "较低", value: "0" },
        { name: "普通", value: "1" },
        { name: "紧急", value: "2" },
        { name: "非常紧急", value: "3" },
      ],
      statusArr: [
        { name: "全部", value: "" },
        { name: "已逾期", value: "2" },
        { name: "逾期完成", value: "3" },
      ],
      endDateArr: [
        { name: "全部", value: "" },
        { name: "当天", value: "1" },
        { name: "明天", value: "2" },
        { name: "三日内", value: "3" },
        { name: "五日内", value: "5" },
        { name: "近期", value: "7" },
      ],
      isFinishArr: [
        { name: "全部", value: "" },
        { name: "已完成", value: "1" },
        { name: "未完成", value: "0" },
      ],
      feedbackArr:[
        {name:"全部",value:""},
        {name:"待反馈",value:"0"},
        {name:"参加",value:"1"},
        {name:"已拒绝",value:"3"},
      ],
      systemIdArr: new Array(0),
      searchName: "",
      //----筛选条件字段--------------
      searchPanelForm: {
        id: "",
        searchName: "", //搜索名称
        isDefault: false, //是否默认
        isPushHome: false, //是否推送到首页
        keyName: "", //任务名称
        startTimeStart: "", //开始时间
        startTimeEnd: "",
        endTimeStart: "", //截止时间
        endTimeEnd: "",
        priority: "", //优先级（多选）不传默认全部
        endDate: "", //完成时间 不传默认全部，1当天 2次日 3三日内 5五日内 7近期
        systemId: "", //来源（多选）不传默认全部
        joinArr: [], //参与人
        creatArr: [], //创建人
        queryType: "schedule",
        feedbackValue:"",
        isSelected:"false"
      },
      //----查询条件下拉--------------
      searchListParams: {
        queryName: "",
        qIsIndexCustomize: "",
        queryType: "schedule",
      },
      baseService: new BaseService(),
    };
  },
  methods: {
    subStringUserName(name: string) {
      return Utils.subStringUserName(name, 2);
    },
    //关闭
    onClose() {
      menuController.close();
    },
    //优先级
    getPriority(value: string) {
      this.searchPanelForm.priority = value;
    },
    //结束时间
    getEndDate(value: any) {
      this.searchPanelForm.endDate = value;
    },
    //反馈状态
    getFeedbackValue(value:any){
      this.searchPanelForm.feedbackValue=value;
    },
    //参与人
    async selectJoiner() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.searchPanelForm.joinArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.searchPanelForm.joinArr = data.data;
      }
    },
    keyNameHandler(ev: any) {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        this.confirm();
      }
    },
    //创建人
    async selectCreator() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: this.searchPanelForm.creatArr,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.searchPanelForm.creatArr = data.data;
      }
    },
    //删除所选的人
    deleteUser(type: any, item: any, index: number) {
      if (type == "Joiner") {
        //参与人
        this.searchPanelForm.joinArr.splice(index, 1);
      } else if (type == "Creator") {
        //创建人
        this.searchPanelForm.creatArr.splice(index, 1);
      }
    },
    //重置时间
    resetTime(type: any) {
      if (type == "startTime") {
        this.searchPanelForm.startTimeStart = "";
        this.searchPanelForm.startTimeEnd = "";
      }
      if (type == "endTime") {
        this.searchPanelForm.endTimeStart = "";
        this.searchPanelForm.endTimeEnd = "";
      }
    },
    //时间处理-创建人处理
    timeFromat() {
      if (this.searchPanelForm.startTimeStart) {
        this.searchPanelForm.startTimeStart = moment(
          this.searchPanelForm.startTimeStart
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.startTimeEnd != "") {
        this.searchPanelForm.startTimeEnd = moment(
          this.searchPanelForm.startTimeEnd
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.endTimeStart != "") {
        this.searchPanelForm.endTimeStart = moment(
          this.searchPanelForm.endTimeStart
        ).format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.searchPanelForm.endTimeEnd != "") {
        this.searchPanelForm.endTimeEnd = moment(
          this.searchPanelForm.endTimeEnd
        ).format("yyyy-MM-DD HH:mm:ss");
      }
    },
    //确定检索
    confirm() {
      if (Utils.isInCludeEmoji(this.searchPanelForm.keyName)) {
        Utils.presentToastWarning("日程名称不可以包含表情");
        return;
      }
      this.searchPanelForm.keyName = this.baseService.trim(
        this.searchPanelForm.keyName
      );
      this.timeFromat();
      this.searchPanelForm.isSelected="true";
      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },
    //重置检索条件
    reset() {
      this.searchPanelForm = {
        id: "",
        searchName: "", //搜索名称
        isDefault: false, //是否默认
        isPushHome: false, //是否推送到首页
        keyName: "", //任务名称
        startTimeStart: "", //创建时间
        startTimeEnd: "",
        endTimeStart: "", //截止时间
        endTimeEnd: "",
        priority: "", //优先级（多选）不传默认全部
        endDate: "", //完成时间 不传默认全部，1当天 2次日 3三日内 5五日内 7近期
        feedbackValue:"",//反馈状态
        systemId: "", //来源（多选）不传默认全部
        joinArr: [], //参与人
        creatArr: [], //创建人
        queryType: "schedule",
        isSelected:"false"
      };
      this.$emit("on-close", this.searchPanelForm);
      this.onClose();
    },

    //打开条件名称框
    openCondition() {
      this.isCondition = true;
    },
    //关闭条件名称框
    CloseDialog() {
      this.isCondition = false;
    },
    //请求查询条件列表
    getQueryList(params: any) {
      this.searchPanelService.getAppQueryByUserId(params).then(
        (res: any) => {
          if (res) {
            this.searchNameArr = res;
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //渲染查询条件数据
    initSeachFormData(params: any) {
      console.log(params);
    },

    //保存查询条件
    saveCondition(type: number) {
      if (type == 1) {
        if (this.searchPanelForm.searchName) {
          this.timeFromat();
          this.searchPanelForm.id = "";
          this.searchPanelService.saveSearch(this.searchPanelForm).then(
            (res: any) => {
              if (res) {
                //
                this.searchName = this.searchPanelForm.searchName;
                this.getQueryList(this.searchListParams);
              }
            },
            (e: any) => {
              Utils.presentToastTopWarning(e.message);
            }
          );
        } else {
          Utils.presentToastTopWarning("请填写查询条件名称");
        }
        return;
      } else {
        this.isCondition = false;
      }
    },
    //条件管理
    async openSearchConditionManageModal() {
      const modal = await modalController.create({
        component: SearchConditionManageModal,
        cssClass: "notice-modal-class",
        componentProps: {},
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
    },
    //设为默认
    changeDefault(e: any) {
      this.searchPanelForm.isDefault = e.detail.checked;
    },
    //推送到首页
    changePushHome(e: any) {
      this.searchPanelForm.isPushHome = e.detail.checked;
    },
    avatarData(item: any) {
      var users: any = [];
      let obj = { name: item.name, img: item.photo, id: item.userId };
      users.push(obj);
      return users;
    },
  },
});
