
import { alertController, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";

import { ProjectMemberService } from "@/services/project/ProjectMemberService.ts";
import { TaskService } from "@/services/task/TaskService.ts";

export default defineComponent({
  name: "TaskTransfer",
  components: {
    HeaderModal,
  },
  props: {
    taskId: String,
    projectId: String,
    executorList: Array,
  },
  data() {
    return {
      projectMemberService: new ProjectMemberService(),
      taskService: new TaskService(),
      isRollback: true,
      partnerList: new Array(),
      fromUserId: "",
      toUserId: "",
    };
  },
  mounted() {
    //加载页面数据
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      let params = { projectId: this.projectId };
      that.projectMemberService.getDistinctMember(params).then((res: any) => {
        if (res && res.data) {
          that.partnerList = new Array();
          res.data.forEach((ele: any) => {
            let isAdd = false;
            if (that.executorList) {
              let cList = that.executorList.filter((executor: any) => {
                return executor.userId == ele.userId;
              });
              if (cList.length == 0) {
                isAdd = true;
              }
            } else {
              isAdd = true;
            }
            if (isAdd) {
              that.partnerList.push(ele);
            }
          });
        }
      });
    },
    //执行者
    bindFrom(e: any) {
      this.fromUserId = e.detail.value;
    },
    //转派给
    bindTo(e: any) {
      this.toUserId = e.detail.value;
    },
    closeModel(type: any) {
      if (type == 4) {
        if (this.fromUserId != "" && this.toUserId != "") {
          let that = this;
          let params = {
            taskId: this.taskId,
            oldUserId: this.fromUserId,
            newUserId: this.toUserId,
          };
          that.taskService.taskTransfer(params).then((res: any) => {
            if (res && res.data) {
              //关闭页面
              modalController.dismiss({
                refresh: true,
              });
            } else {
              modalController.dismiss({
                refresh: false,
              });
            }
          });
        } else {
          if (this.fromUserId == "") {
            Utils.presentToastWarning("请选择执行者");
          } else if (this.toUserId == "") {
            Utils.presentToastWarning("请选择转派人员");
          }
        }
      } else {
        modalController.dismiss({});
      }
    },
  },
});
