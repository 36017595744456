
import { isPlatform } from "@ionic/vue";
import { defineComponent, reactive, ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { modalController } from "@ionic/vue";
//组件
import Utils from "@/utils/Utils";
import Setting from "@/rest/Setting";

import "@/views/aiui/WavAudioEncoder.min";
import { nextTick } from "process";
import { getInstance } from "@/services/selectexecutor/UserInfoByUserNameService";
import moment from "moment";

import { AiuiService } from "@/services/aiuiservice";
import { ScheduleService } from "@/services/schedule/ScheduleService";

import { AnyARecord } from "dns";
import { getMicrophonePermission, isMobile } from "@/views/aiui/AudioUtils";
import { TaskUserService } from "@/services/task/TaskUserService";
import SystemUtils from "@/utils/SystemUtils";
import { TaskService } from "@/services/task/TaskService";
import { TextToSpeech } from "@ionic-native/text-to-speech";
import { EUserWorkType } from "@/models/task/Enum";
import { File } from "@ionic-native/file";
import { FileHelper } from "@/utils/FileHelper";
import Bus from "@/utils/bus";

export default defineComponent({
  name: "Audio",
  setup(props, context) {
    const route = useRoute(); // 返回当前路由位置。相当于在内部使用“$route” 获取路由传参
    const router = useRouter(); //返回路由器实例。相当于在内部使用“$router” 进行路由跳转
    const taskApi = new TaskService();
    const taskUserApi = new TaskUserService();
    const scheduleService = new ScheduleService();
    let fileHelper = new FileHelper();
    //iconyuyinbobaoguanbi
    let btnIconRight: any = reactive({ data: ["iconyuyinbobao"] });
    // const btnSizeRight: any = reactive({ data: ["34px"] });
    let selectUserId = ref("");
    let flagCount = ref(0);
    let maxTime = 10;
    let isSVW = Setting.isSVW; //是否上汽环境，隐藏部分功能
    let voiceText = ref("按住说话");
    let currentUserName = ref("");
    let islist = ref(true);
    if (SystemUtils.loginUser.name)
      currentUserName.value = SystemUtils.loginUser.name;

    const taskList = reactive({ data: new Array<any>() });
    let isclick = true;
    let startY: any = null;
    let startX: any = 0;
    let moveY: any = ref(0);
    let voiceStatus: any = ref(0);
    let animationIndex: any = ref(0);
    // let task: any = ref(0);
    // let successTimer: any = ref(0); // 显示创建成功的窗口

    let captureCfg: any = null; // Capture configuration object
    let audioDataBuffer: any = new Array(); // Audio Buffer
    let objectURL: any = null; // For file URL; // Info/Debug
    // URL shim
    window.URL = window.URL || window.webkitURL;

    let repContent = ref(new Array());
    let fileUrl = "";
    let fileName = "";

    let chatArr = reactive({ data: new Array<any>() });

    const aiuiService = new AiuiService();
    onMounted(() => {
      // successTimer.value = 0;
      islist.value = false;
      if ("taskList" in route.params) {
        let taskParams = route.params.taskList + "";
        taskList.data = JSON.parse(taskParams);
      }

      // Make it possible to run the demo on desktop.
      if (!(<any>window).cordova) {
        console.log("Running on desktop!");
        onDeviceReady();
      } else {
        // For Cordova apps
        console.log("Running on device!");
        document.addEventListener("deviceready", onDeviceReady, false);
      }

      Bus.$on("start_or_stop_audio_listener", (isOpen: boolean) => {
        if (isOpen) {
          (<any>window).addEventListener(
            "audioinput",
            onAudioInputCapture,
            false
          );
          (<any>window).addEventListener(
            "audioinputerror",
            onAudioInputError,
            false
          );
        } else {
          (<any>window).removeEventListener(
            "audioinput",
            onAudioInputCapture,
            false
          );
          (<any>window).removeEventListener(
            "audioinputerror",
            onAudioInputError,
            false
          );
        }
      });
    });
    //开始录制
    const touchstart = (e: any) => {
      startCapture(e);
    };
    const touchstart2 = (e: any) => {
      moveY = 1;
      voiceStatus.value = 1;
      e.preventDefault();
      getInterval();
      voiceText.value = "松开识别";
      startY = e.changedTouches[0].pageY;
      startX = e.changedTouches[0].pageX;
      isclick = true;
    };
    //音阶动画
    const animation = () => {
      let num = Math.ceil(Math.random() * 5);
      if (animationIndex.value > 1) {
        animationIndex.value = 1;
      }
      if (animationIndex.value % 2 == 0 && animationIndex.value > 0) {
        animationIndex.value -= num;
      } else {
        animationIndex.value += num;
      }
    };
    let animationInterval: any = null;

    const getInterval = () => {
      if (animationInterval == null) {
        animationInterval = setInterval(animation, 300);
      }
    };
    const textToSpeech = (answer: any) => {
      if (btnIconRight.data[0] == "iconyuyinbobao") {
        Utils.textTospeech(answer);
      }
    };
    const changeIon = () => {
      if (btnIconRight.data[0] == "iconyuyinbobao") {
        btnIconRight.data = ["iconyuyinbobaoguanbi"];
      } else {
        btnIconRight.data = ["iconyuyinbobao"];
      }
    };
    //结束说话 停止录制
    const touchend = () => {
      if (flagCount.value > maxTime) {
        return;
      }
      if (animationInterval != null) {
        clearInterval(animationInterval);
        animationInterval = null;
      }
      voiceStatus.value = 0;
      voiceText.value = "按住说话";
      if (moveY < -30) {
        isclick = true;
      } else {
        isclick = false;
      }
      stopCapture(isclick);
    };
    const touchmove = (e: any) => {
      e.preventDefault();
      var moveEndY = e.changedTouches[0].pageY;
      moveY = moveEndY - startY;
      if (flagCount.value <= maxTime) {
        if (moveY < -30) {
          voiceStatus.value = 2;
          voiceText.value = "松开取消";
          isclick = true;
        } else {
          voiceStatus.value = 1;
          voiceText.value = "松开识别";
          isclick = false;
        }
      }
    };

    /**
     * Called continuously while AudioInput capture is running.
     */
    const onAudioInputCapture = (evt: any) => {
      try {
        if (evt && evt.data) {
          audioDataBuffer.push(...evt.data);
          if (flagCount.value >= maxTime) {
            //超过10s自动停止
            touchend();
          }
          flagCount.value++;
        }
      } catch (ex) {
        alert("onAudioInputCapture ex: " + ex);
      }
    };

    /**
     * Called when a plugin error happens.
     */
    const onAudioInputError = (error: any) => {
      alert("onAudioInputError event recieved: " + JSON.stringify(error));
    };

    /**
     * When cordova fires the deviceready event, we initialize everything needed for audio input.
     */
    const onDeviceReady = () => {
      if (
        (<any>window).cordova &&
        (<any>window).cordova.file &&
        (<any>window).audioinput
      ) {
        console.log("Use 'Start Capture' to begin...");
        // Subscribe to audioinput events
        (<any>window).addEventListener(
          "audioinput",
          onAudioInputCapture,
          false
        );
        (<any>window).addEventListener(
          "audioinputerror",
          onAudioInputError,
          false
        );

        // See utils.js
        getMicrophonePermission(
          () => {
            console.log("Microphone input starting...");
            console.log("Microphone input started!");
          },
          (deniedMsg: AnyARecord) => {
            console.log(deniedMsg);
          },
          (errorMsg: any) => {
            console.log(errorMsg);
          }
        );
      } else {
        console.log(
          "Missing: cordova-plugin-file or cordova-plugin-audioinput!"
        );
      }
    };

    /**
     * Start capturing audio.
     */
    const startCapture = (e: any) => {
      audioDataBuffer = new Array();
      flagCount.value = 0;
      objectURL = null;
      try {
        // let audioinput = (<any>window).audioinput;
        TextToSpeech.speak("");
        console.log("Audio.vue-----startCapture");
        if (
          (<any>window).audioinput &&
          !(<any>window).audioinput.isCapturing()
        ) {
          captureCfg = {
            audioSourceType: 0,
            sampleRate: 16000,
            channels: 1,
            format: (<any>window).audioinput.FORMAT.PCM_16BIT,
          };

          // See utils.js
          getMicrophonePermission(
            () => {
              console.log("Microphone input starting...");
              (<any>window).audioinput.start(captureCfg);
              touchstart2(e);
              console.log("Microphone input started!");
              // Throw previously created audio
              if (objectURL) {
                URL.revokeObjectURL(objectURL);
              }
            },
            (deniedMsg: AnyARecord) => {
              console.log(deniedMsg);
            },
            (errorMsg: any) => {
              console.log(errorMsg);
            }
          );
        }
      } catch (e) {
        alert("startCapture exception: " + e);
      }
    };

    const fileSystemPath = isPlatform("ios")
      ? File.dataDirectory
      : File.externalRootDirectory;

    /**
     * Stop the capture, encode the captured audio to WAV, save it to a file and show file URL in UI.
     */
    const stopCapture = (isBlock?: boolean) => {
      try {
        if (isBlock == undefined) isBlock = false;
        if (
          (<any>window).audioinput &&
          (<any>window).audioinput.isCapturing()
        ) {
          if (isMobile.any() && (<any>window).audioinput) {
            // setTimeout(() => {
            //   //延时结束录音
            //   (<any>window).audioinput.stop();
            //   // (<any>window).audioinput.clearInterval();
            // }, 500);
            console.log("(<any>window).audioinput.stop();");
            (<any>window).audioinput.stop();
          }
          //取消搜索，终止执行
          if (isBlock) {
            return;
          }
          console.log("Encoding WAV...");
          let encoder = new (<any>window).WavAudioEncoder(
            (<any>window).audioinput.getCfg().sampleRate,
            (<any>window).audioinput.getCfg().channels
          );
          encoder.encode([audioDataBuffer]);

          console.log("Encoding WAV finished");

          let blob = encoder.finish("audio/wav");
          console.log("BLOB created");
          (<any>window).resolveLocalFileSystemURL(
            fileSystemPath,
            function (dir: any) {
              fileName = new Date().getTime() + "aaaa.wav";
              dir.getFile(fileName, { create: true }, function (file: any) {
                file.createWriter(
                  function (fileWriter: any) {
                    fileWriter.write(blob);
                    fileUrl = file.toURL();

                    setTimeout(() => {
                      console.log("AIUI send audio file: " + fileUrl);
                      aiuiService
                        .aiuiSend({
                          filePath: fileUrl,
                          fileName: fileName,
                        })
                        .then((rep: any) => {
                          console.log(
                            "AIUI result json: " + JSON.stringify(rep.data)
                          );
                          let repContent = JSON.parse(rep.data).data;
                          let nlp = repContent.find(
                            (item: any) => item.sub == "nlp"
                          );
                          if (
                            !nlp ||
                            !nlp.intent ||
                            JSON.stringify(nlp.intent) == "{}"
                          ) {
                            textToSpeech("未听清您说的话，请重试");
                            return;
                          }
                          if (
                            nlp.intent.answer["answerType"] != undefined &&
                            nlp.intent.answer.answerType == "LastGuard"
                          ) {
                            // 不能识别，语音未击中技能
                            textToSpeech(nlp.intent.answer.text);
                            return;
                          }
                          // // 数据返回，语义解析结束
                          let result = nlp.intent.data.result[0];
                          if (
                            !result ||
                            result.speak == "ask" ||
                            (result.UserName &&
                              result.UserName.toLowerCase() != "my")
                          ) {
                            // 无数据返回，或者必填槽位未满足，语义解析到下一页进行
                            modalController.dismiss();
                            router.push({
                              name: "AiuiSearchCondition_copy",
                              params: { aiuiJSON: rep.data },
                            });
                            return;
                          }
                          // console.log("result: " + JSON.stringify(result));
                          if (
                            result.speak == "end" &&
                            result.intentName == "OpenCMD" &&
                            result.intentType == "OpenPage"
                          ) {
                            modalController.dismiss();
                            switch (result.DomainClassinfo) {
                              case "Visitor": // 访客
                                router.push("/visitor");
                                break;
                              case "Meeting": // 会议
                                router.push("/meeting");
                                break;
                              case "Attendance": // 考勤
                                router.push("/attendance");
                                break;
                              case "Project": // 项目
                                router.push("/project");
                                break;
                              case "Schedule": // 日程
                                router.push("/calendar");
                                break;
                              case "OKR": // OKR
                                router.push("/OKR");
                                break;
                              case "Task": // 任务
                                router.push("/task-list");
                                break;
                              default:
                                break;
                            }
                            textToSpeech(nlp.intent.answer.text);
                            return;
                          }
                          modalController.dismiss();
                          router.push({
                            name: "AiuiSearchCondition_copy",
                            params: { aiuiJSON: rep.data },
                          });
                        })
                        .catch((err) => {
                          Utils.presentToastWarning("上传失败");
                          console.log("上传失败");
                          console.log(err);
                        })
                        .finally(() => {
                          fileHelper.deleteNativeFile(
                            fileUrl.replace(fileName, ""),
                            fileName
                          );
                        });
                    }, 1000);
                  },
                  function () {
                    alert("FileWriter error!");
                  }
                );
              });
            }
          );
        } else {
          console.log("web");
        }
      } catch (e) {
        alert("stopCapture exception: " + e);
      }
    };
    onUnmounted(() => {
      if (animationInterval != null) {
        clearInterval(animationInterval);
      }
      Bus.$off("start_or_stop_audio_listener");
    });

    const playAudo = (filePath: any) => {
      console.log("预览文件" + filePath);
    };
    return {
      playAudo,
      currentUserName,
      taskList,
      chatArr,
      voiceText,
      touchstart,
      touchend,
      touchmove,
      repContent,
      startCapture,
      stopCapture,
      voiceStatus,
      animationIndex,
      // task,
      islist,
      selectUserId,
      flagCount,
      btnIconRight,
      changeIon,
      // successTimer,
    };
  },
});
