
import { defineComponent } from "vue";
import { popoverController,modalController } from "@ionic/vue";
import CalendarMorePopover from "./CalendarMorePopover.vue";
import DetailCalendar from "../detail/DetailCalendar.vue";

export default defineComponent({
  name: "CalendarCard",
  components: { CalendarMorePopover },
  props: {
    CalendarCardObj: Object,
    SelectModel: Boolean,
    ShowMenu: Boolean,
    ShowShare: Boolean,
    isRecycle:{
      type:Boolean,
      default:false
    },
    fatherInitData:{
      type: Function,
        default: null
    }
  },
  emits: ["on-share", "on-edit", "on-delete", "on-create", "on-check"],
  data() {
    return {
      selectedId: "",
      userId: "",
    };
  },
  methods: {
    //分享
    shareItem(item: any) {
      this.$emit("on-share", {
        item: item,
      });
    },
    //编辑
    editItem(item: any) {
      this.$emit("on-edit", {
        item: item,
      });
    },
    //删除
    deleteItem(item: any) {
      this.$emit("on-delete", {
        item: item,
      });
    },
    //创建
    create() {
      this.$emit("on-create");
    },
    checkHandler(e: any, item: any) {
      item.checked = e.detail.checked;
      this.$emit("on-check", {
        item: item,
      });
     },
    // clickCard(item: any) {
    //   if (this.SelectModel) {
    //     item.checked = item.checked ? false : true;
    //   }
    // },
    async openPopover(
      ev: Event,
      CalendarCardObj: any,
      ShowMenu: boolean,
      ShowShare: boolean
    ) {
      const popover = await popoverController.create({
        component: CalendarMorePopover,
        cssClass: "my-custom-class",
        event: ev,
        mode: "ios",
        translucent: true,
        componentProps: {
          CalendarCardObj: CalendarCardObj,
          ShowMenu: ShowMenu,
          ShowShare: ShowShare,
        },
      });
      popover.present();
      const { data } = await popover.onDidDismiss();
      if (data) {
        const result = data.data;
        if ("edit" == result.type) {
          this.editItem(result.obj);
        }
        if ("delete" == result.type) {
          this.deleteItem(result.obj);
        }
        if ("share" == result.type) {
          this.shareItem(result.obj);
        }
      }
    },
    async detailHandler(id: any) {
      const modal = await modalController.create({
        component: DetailCalendar,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          id: id,
          isRecycle:this.isRecycle
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      
      if(data&&data.isDelete){ 
        if (this.fatherInitData) {
          this.fatherInitData();
        }
      }
      
    },
  },
});
