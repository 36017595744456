
import { defineComponent, reactive, ref, onMounted, watch } from "vue";
import SearchBar from "@/components/SearchBar.vue";
import { useRouter } from "vue-router";
import Utils from "../../../utils/Utils";
import { alertController } from "@ionic/vue";
import { Plugins } from "@capacitor/core";
import { AnyARecord } from "dns";
import "../../aiui/WavAudioEncoder.min";
import { AiuiService } from "@/services/aiuiservice";
import { getMicrophonePermission, isMobile, pad2 } from "../../aiui/AudioUtils";
import { nextTick } from "process";
export default defineComponent({
  name: "HomeSearchCondition",
  components: { SearchBar },
  ionViewDidEnter() {
    const historyStorage = localStorage.getItem("searchHistory");
    let history = [];
    if (
      historyStorage != undefined &&
      historyStorage != "" &&
      historyStorage != null
    ) {
      history = JSON.parse(historyStorage);
    }
    this.searchHistory.data = history;
  },
  setup(props, context) {
    let searchKey = ref("");
    let voiceText = ref("按住说话");

    const searchHistory = reactive({
      data: new Array<any>(),
    });

    const searchQuestion = reactive({
      data: [
        {
          id: "1",
          name: "进行中的任务",
        },
        {
          id: "2",
          name: "延期的任务",
        },
        {
          id: "3",
          name: "今日的任务",
        },
        {
          id: "4",
          name: "我的任务",
        },
      ],
    });

    const bindRemoveChip = async () => {
      let alertRemove = await alertController.create({
        header: "删除搜索历史",
        message: "确定要搜索历史吗?",
        mode: "ios",
        buttons: [
          { text: "取消" },
          {
            text: "确定",
            handler: () => {
              searchHistory.data = [];
              localStorage.setItem("searchHistory", "[]");
            },
          },
        ],
      });

      alertRemove.present();
    };

    const bindTagClick = (name: string) => {
      searchKey.value = name;
      searchHandler({ searchKey: name });
    };

    const router = useRouter();

    const searchHandler = (v: any) => {
      if (v != null) {
        if (v.searchKey != "") {
          if (Utils.isInCludeEmoji(v.searchKey)) {
            Utils.presentToastWarning("搜索内容不可以包含表情");
            return;
          }
          if (searchHistory.data.length > 0) {
            searchHistory.data.some((item: any, index: number) => {
              if (v.searchKey == item.name) {
                searchHistory.data.splice(index, 1);
                return true;
              }
            });
          }
          if (searchHistory.data.length >= 10) {
            searchHistory.data.pop();
          }
          searchHistory.data.unshift({
            id: new Date().getTime().toString(),
            name: v.searchKey.trim(),
          });
          localStorage.setItem(
            "searchHistory",
            JSON.stringify(searchHistory.data)
          );
          router.push({
            name: "HomeSearchList",
            params: { searchValue: v.searchKey },
          });
        }
      }
    };

    //返回
    const goBack = () => {
      router.go(-1);
    };
    const { Keyboard } = Plugins;
    onMounted(() => {
      // Make it possible to run the demo on desktop.
      if (!(<any>window).cordova) {
        console.log("Running on desktop!");
        onDeviceReady();
      } else {
        // For Cordova apps
        console.log("Running on device!");
        document.addEventListener("deviceready", onDeviceReady, false);
      }
    });
    let isclick = true;
    let startY: any = null;
    let startX: any = 0;
    let Y: any = 0;
    //开始录制
    const touchstart = (e: any) => {
      console.log("我在按住说话");
      Y = 1;
      e.preventDefault();
      voiceText.value = "松开识别";
      startY = e.changedTouches[0].pageY;
      startX = e.changedTouches[0].pageX;
      isclick = true;
      startCapture();
    };
    //结束说话 停止录制
    const touchend = (e: any) => {
      console.log("结束说话");
      voiceText.value = "按住说话";
      if (Y < -30) {
        isclick = true;
      } else {
        isclick = false;
      }
      stopCapture(isclick);
      if (isclick == true) {
        return;
      } else {
        //TODO 请求讯飞接口
      }
    };
    const touchmove = (e: any) => {
      e.preventDefault();
      var moveEndY = e.changedTouches[0].pageY;
      Y = moveEndY - startY;
      if (Y < -30) {
        voiceText.value = "松开取消";
      } else {
        voiceText.value = "松开识别";
      }
    };

    let captureCfg: any = null; // Capture configuration object
    let audioDataBuffer: any = new Array(); // Audio Buffer
    let objectURL: any = null; // For file URL; // Info/Debug

    // URL shim
    window.URL = window.URL || window.webkitURL;

    /**
     * Called continuously while AudioInput capture is running.
     */
    const onAudioInputCapture = (evt: any) => {
      try {
        if (evt && evt.data) {
          audioDataBuffer.push(...evt.data);
        }
      } catch (ex) {
        alert("onAudioInputCapture ex: " + ex);
      }
    };

    /**
     * Called when a plugin error happens.
     */
    const onAudioInputError = (error: any) => {
      alert("onAudioInputError event recieved: " + JSON.stringify(error));
    };

    /**
     * When cordova fires the deviceready event, we initialize everything needed for audio input.
     */
    const onDeviceReady = () => {
      if (
        (<any>window).cordova &&
        (<any>window).cordova.file &&
        (<any>window).audioinput
      ) {
        console.log("Use 'Start Capture' to begin...");
        // Subscribe to audioinput events
        (<any>window).addEventListener(
          "audioinput",
          onAudioInputCapture,
          false
        );
        (<any>window).addEventListener(
          "audioinputerror",
          onAudioInputError,
          false
        );
      } else {
        console.log(
          "Missing: cordova-plugin-file or cordova-plugin-audioinput!"
        );
      }
    };

    /**
     * Start capturing audio.
     */
    const startCapture = () => {
      audioDataBuffer = new Array();
      objectURL = null;
      try {
        // let audioinput = (<any>window).audioinput;
        if (
          (<any>window).audioinput &&
          !(<any>window).audioinput.isCapturing()
        ) {
          captureCfg = {
            audioSourceType: 0,
            sampleRate: 16000,
            channels: 1,
            format: (<any>window).audioinput.FORMAT.PCM_16BIT,
          };

          // See utils.js
          getMicrophonePermission(
            () => {
              console.log("Microphone input starting...");
              (<any>window).audioinput.start(captureCfg);
              console.log("Microphone input started!");
              // Throw previously created audio
              if (objectURL) {
                URL.revokeObjectURL(objectURL);
              }
            },
            (deniedMsg: AnyARecord) => {
              console.log(deniedMsg);
            },
            (errorMsg: any) => {
              console.log(errorMsg);
            }
          );
        }
      } catch (e) {
        alert("startCapture exception: " + e);
      }
    };

    let repContent = ref(new Array());
    let fileUrl ="";
    let fileName = "";
    const aiuiService = new AiuiService();
    /**
     * Stop the capture, encode the captured audio to WAV, save it to a file and show file URL in UI.
     */
    const stopCapture = (isBlock?: boolean) => {
      try {
        if (
          (<any>window).audioinput &&
          (<any>window).audioinput.isCapturing()
        ) {
          if (isMobile.any() && (<any>window).audioinput) {
            (<any>window).audioinput.stop();
          }
          //取消搜索，终止执行
          if (isBlock) {
            return;
          }
          console.log("Encoding WAV...");
          let encoder = new (<any>window).WavAudioEncoder(
            (<any>window).audioinput.getCfg().sampleRate,
            (<any>window).audioinput.getCfg().channels
          );
          encoder.encode([audioDataBuffer]);

          console.log("Encoding WAV finished");

          let blob = encoder.finish("audio/wav");
          console.log("BLOB created");
          (<any>window).resolveLocalFileSystemURL(
            (<any>window).cordova.file.externalDataDirectory,
            function (dir: any) {
              fileName = new Date().getTime() + ".wav";
              dir.getFile(
                fileName,
                { create: true },
                function (file: any) {
                  file.createWriter(
                    function (fileWriter: any) {
                      fileWriter.write(blob);
                      console.log("fileUrl.value:*****" + fileUrl);
                      fileUrl = file.toURL();
                      console.log("File created!");
                    },
                    function () {
                      alert("FileWriter error!");
                    }
                  );
                }
              );
            }
          );
          setTimeout(() => {
            aiuiService
              .aiuiSend({
                filePath: fileUrl,
                fileName: fileName,
              })
              .then((rep: any) => {
                // console.log(JSON.parse(rep.data).data[0]);
                // console.log("rep:******" + rep.data[0].intent.voice_answer.content);
                repContent.value=JSON.parse(rep.data).data;
                // let aiuiResult=JSON.parse(rep.data).data[0].text;
                if(repContent.value){
                  let nlp=repContent.value.find((item)=>item.sub=="nlp");
                  if(nlp&&nlp.intent&&JSON.stringify(nlp.intent)!="{}"&&nlp.intent.data){
                     let queryParms=nlp.intent.data.result;
                     if(queryParms){
                       let  DomainClassinfo="Task";
                       if(queryParms[0].DomainClassinfo){
                         DomainClassinfo=queryParms[0].DomainClassinfo;
                       }
                       switch (DomainClassinfo) {
                         case "Task":
                            //请求任务接口：
                            // Utils.presentToastSuccess("跳转任务详情");
                           break;
                         case "Project":
                            //跳转路由
                            Utils.presentToastSuccess("跳转项目详情");
                           break;
                         case "Schedule":
                            //跳转路由
                            Utils.presentToastSuccess("跳转日程详情");
                           break;
                       
                         default:
                           break;
                       }
                     }
                  }
                }

                //TODO 判断是否查询任务，如果是查询任务，跳转任务列表页面 跳转路由，新开发一个页面
                 

              })
              .catch((err) => {
                console.log("上传失败");
                console.log(err);
              });
          },100);
        } else {
          console.log("web");
        }
      } catch (e) {
        alert("stopCapture exception: " + e);
      }
    };

    return {
      bindRemoveChip,
      searchKey,
      searchHistory,
      searchQuestion,
      searchHandler,
      goBack,
      bindTagClick,
      voiceText,
      touchstart,
      touchend,
      touchmove,
      repContent,
      startCapture,
      stopCapture,
    };
  },
});
