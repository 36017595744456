
import { defineComponent } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  menuController,
  IonMenu,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { getInstance } from "@/services/project/projectService";
import SystemUtils from "@/utils/SystemUtils";
import { TaskStageService } from "@/services/task/TaskStageService";
export default defineComponent({
  name: "ProjectGroupingModal",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    HeaderModal,
    IonMenu,
  },
  props: {
    selIndex: String, //分组Id
    isSelPhases: Boolean, //是否选择阶段
    selPhaseId: String, //阶段Id
    projectId: String, //项目Id
  },
  data() {
    const projectService = getInstance();
    return {
      projectService: projectService,
      stageService: new TaskStageService(),
      selValue: "",
      groupList: Array(0), //分组
      staged: {},
      stageList: Array(0), //阶段
      selOptions: Array(0), // 选择树形分组时,每选择一次分组,将分组加入该数组
      mSelPhaseId: "",
      selectedObj: {},
      tempRootArr: Array(0),
      page: 0,
      isShowPhases: false,
      taskGroupId: "",
    };
  },
  created() {
    this.groupList = []; //分组
    if (this.isSelPhases) {
      if (this.selPhaseId) this.mSelPhaseId = this.selPhaseId.valueOf();
      //请求分组
      this.projectService
        .getByProjectIdForMennu({ projectId: this.projectId })
        .then((res: any) => {
          this.groupList = res.data;
          this.taskGroupId = res.data[0].id;
          if (this.selIndex) this.selValue = this.selIndex;
          this.clickGroup(null);
        });
    } else {
      // 获取树形分组相关
      //获取项目分组
      this.projectService
        .getAllNodeByUserId({ userId: SystemUtils.loginUser.id })
        .then(
          (r: any) => {
            if (r) {
              this.groupList = r.data;
              this.tempRootArr.push(r.data);
              if (this.selIndex) this.selValue = this.selIndex;
              this.clickGroup(null);
            } else {
              console.log("无分组新");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
    }
  },
  methods: {
    closeModel(type: any) {
      if (type == 4) {
        modalController.dismiss({
          group: this.selectedObj,
          staged: this.staged,
        });
      } else {
        modalController.dismiss();
      }
    },
    //返回上一级
    backUp() {
      if (this.isSelPhases) {
        this.page = 0;
        this.isShowPhases = false;
      } else {
        this.selValue = "";
        this.tempRootArr.splice(this.page, 1);
      }
      if (this.page <= 0) return;
      this.page--;
      this.groupList = this.tempRootArr[this.page];
    },
    changed(event: any) {
      this.selValue = event.detail.value;
    },
    clickGroup(groupObj: any) {
      if (this.isSelPhases) {
        this.selectedObj = groupObj;
        this.isShowPhases = true;
        this.page = 1;
        this.stageList = Array(0);
        //根据分组获取阶段
        // this.stageService
        //   .getStageListByProjecctAndTaskGroupId({
        //     projectId: this.projectId,
        //     taskGroupId: "task@TaskGroup(019e9b6ff69c447e8b8b936f0f6bc485)",
        //   })
        //   .then((res: any) => {
        //     this.stageList = res.data;
        //   });
        let params = {
          projectId: this.projectId,
          taskGroupId:
            this.selIndex == null || this.selIndex == ""
              ? this.taskGroupId
              : this.selIndex,
        };
        this.stageService.getStageListByProjecctAndTaskGroupId(params).then(
          (r: any) => {
            if (r) {
              const result = r.data;
              if (result != null && result.length > 0) {
                // result.push(this.stageModel);
                this.stageList = result;
              }
            } else {
              console.log("暂无数据");
            }
          },
          (e: any) => {
            console.log(e);
          }
        );
      } else {
        this.selectedObj = Object.assign(this.selectedObj, groupObj);
      }
    },
    checkPhases(event: any) {
      this.staged = event;
      modalController.dismiss({
        group: event.taskStage,
        staged: this.staged,
      });
    },
    onCloseMenu() {
      menuController.close();
    },
    enterGroup(option: any) {
      this.page++;
      this.tempRootArr.push(option.childNodes);
      this.groupList = option.childNodes;
    },
    getChildNodeByParentId(parentId: any) {
      this.projectService.getChildNodeByParentId(parentId).then(
        (r: any) => {
          if (r) {
            this.groupList = r.data;
          } else {
            console.log("无分组新");
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
  },
});
