
import { defineComponent } from "vue";
import { alertController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import { TaskService } from "@/services/task/TaskService";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import Permission from "@/models/permission/Permission";
import { EUserWorkType } from "@/models/task/Enum";

export default defineComponent({
  name: "TaskList",
  components: {
    TaskCards,
  },
  props: {
    projectId: String,
    isHasPermission: {
      type: Object,
      required: false,
      default: {
        isHasDel: false, //删除权限
        isHasPutArchive: false, //移到回收站权限/恢复回收站权限
        taskPermissionArr: new Array<Permission>(),
      },
    },
    refresh: Boolean,
    isRecycle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.onInit();
      }
    },
  },
  data() {
    const router = useRouter();
    const _param: any = {};
    return {
      taskList: new Array(0),
      taskService: new TaskService(),
      router: router,
      useRoute: useRoute(),
      searchParams: _param,
    };
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      this.resetSearchParam();
      this.resetPage();
    },
    getTaskRolePermission(
      creatorId: string,
      taskUserList: any,
      projectPermission: boolean,
      code: string
    ) {
      const taskRoles = this.getTaskRoleIdList(creatorId, taskUserList);
      const isHasPermission: boolean = this.exsitTaskPer(
        this.isHasPermission.taskPermissionArr,
        projectPermission,
        code,
        taskRoles
      );
      return isHasPermission;
    },
    getTaskRoleIdList(creatorId: string, taskUserList: any) {
      let userId: string = SystemUtils.loginUser.id;
      let roleIdList: Array<string> = [];
      if (creatorId == userId) {
        roleIdList.push("task.creator");
      }
      if (taskUserList != null && taskUserList.length > 0) {
        for (let taskUser of taskUserList) {
          if (
            taskUser.workType == EUserWorkType.Executor &&
            taskUser.userId == userId
          ) {
            roleIdList.push("task.executor");
          } else if (
            taskUser.workType == EUserWorkType.Party &&
            taskUser.userId == userId
          ) {
            roleIdList.push("task.actor");
          }
        }
      }
      return roleIdList;
    },
    exsitTaskPer(
      permissionList: Array<Permission>,
      isHasProjectPermission: boolean,
      taskPermissionName: string,
      taskRoles?: Array<string>
    ) {
      let roleIdList: Array<string> = taskRoles ? taskRoles : []; //当前用户、当前任务拥有的角色列表
      for (let permission of permissionList) {
        if (isHasProjectPermission) {
          return true;
        }
        if (permission.id == taskPermissionName) {
          if (roleIdList.length == 0) {
            continue;
          }
          const index = roleIdList.findIndex((roleId) => {
            return permission.taskRoleId == roleId;
          });
          if (index >= 0) {
            return true;
          }
        }
      }
      return false;
    },
    resetSearchParam() {
      this.searchParams = {
        limit: 20,
        offset: 1,
        projectId: this.projectId,
      };
    },
    //重新从第一页获取
    resetPage() {
      this.searchParams.offset = 1;
      this.getListRequest((res: any) => {
        if (res == null || res.length <= 0) {
          this.taskList = [];
        } else {
          this.taskList = res;
        }
      });
    },
    nextPage(event: any) {
      this.searchParams.offset++;
      this.getListRequest((result: any) => {
        this.taskList = this.taskList.concat(result);
        event.target.complete();
        if (result) {
          if (result == null || result.length <= 0) {
            //  event.target.disabled = true;
          }
        }
      });
    },
    doRefresh(event: any) {
      this.resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    },
    getListRequest(callback: any) {
      this.taskService
        .getByProjectForDelete(this.searchParams)
        .then((res: any) => {
          callback(res);
        });
    },
    openTaskInfo(task: any) {
      this.router.push({
        path: "/task-page",
        query: {
          id: task.id,
          taskName: task.name,
          isRecycle: this.isRecycle+'',
        },
      });
    },
    //删除
    async clearById(id: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "彻底删除任务后，该任务及其子任务将会被永久删除",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                id: id,
              };
              this.taskService.clearById(params).then((res: any) => {
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    //还原
    async restoreById(id: any) {
      const alert = await alertController.create({
        header: "还原",
        message: "您确定要把该任务还原吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              //保存数据库
              const params = {
                id: id,
              };
              this.taskService.restoreById(params).then((res: any) => {
                this.resetPage();
              });
            },
          },
        ],
      });
      await alert.present();
    },
    formatTime(modifyTime: any) {
      return SystemUtils.fromatTime(modifyTime);
    },
  },
});
