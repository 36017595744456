
import { defineComponent, Ref, ref } from "vue";
import { menuController, popoverController } from "@ionic/vue";
import ProjectCss from "@/components/ListCard/ProjectCss.vue";
import { getInstance } from "@/services/project/projectService";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import ProjectSearchPanel from "./ProjectSearchPanel.vue";
import { useRouter } from "vue-router";
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";
import ConditionPop from "@/components/popover/ConditionPop.vue";
import TaskSearchPanelPullDown from "../task/TaskSearchPanelPullDown.vue";
import Utils from "@/utils/Utils";
import CardLoading from "@/components/ListCard/CardLoading.vue";
export default defineComponent({
  name: "ProjectList",
  components: {
    ProjectCss,
    Header,
    SearchBar,
    ProjectSearchPanel,
    ConditionPop,
    CardLoading,
  },
  props: {
    isHome: String,
    searchKey: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    const projectService = getInstance();
    const permissionService = new PermissionService();
    const btnIconRight = [/* "iconxinjian",  */ "iconshaixuanxuanzhong"];
    const btnSizeRight = [/* "34px",  */ "34px"];
    const projectMenu: Ref<any> = ref(null);
    const router = useRouter();
    return {
      projectLists: new Array(0),
      projectService: projectService,
      permissionService: permissionService,
      btnIconRight: btnIconRight,
      btnSizeRight: btnSizeRight,
      projectMenu: projectMenu,
      router: router,
      isMore: true,
      showShade: false,
      isShowSearchPanel: false,
      conditionId: "", //选中条件的Id
      // isEmpty: false,
      searchParams: {
        limit: 10,
        offset: 1,
        projectName: "",
        createTimeCB: "",
        createTimeCE: "",
        endTimeCB: "",
        endTimeEE: "",
        projectLight: "",
        systemId: "",
        partakeType: "",
        createIds: "",
        searchKey: "",
      },
      $refs: {
        searchRef: SearchBar,
      },
      isLoad: false,
      isAddProject: false,
      searchNameArr: new Array(0),
      searchName: "", //查询条件下拉，默认选择名称
      searchId: "", //查询条件下拉，默认选择id
      islist: true, //是否显示加载中的标识
    };
  },
  watch: {
    searchKey(newVal) {
      if (newVal == undefined) {
        newVal = "";
      }
      this.searchParams.searchKey = newVal;
      this.initPageData();
    },
    $route(to, from) {
      // to表示的是你要去的那个组件，from 表示的是你从哪个组件过来的
      //新增项目页面回来刷新列表
      if (from.path == "/project/add") {
        // this.resetQueryParms();
        // this.initPageData();
      } else if (from.path == "/ProjectPage") {
        //项目详情 返回刷新-TODO
        // this.initPageData();
      }
    },
  },
  ionViewDidEnter() {},
  mounted() {
    //项目创建权限
    this.getUserPermission();
  },
  methods: {
    //子组件初始化完成后调用并绑定列表
    onInit(data: Array<any>) {
      this.searchNameArr = JSON.parse(JSON.stringify(data));
      const all = { id: "queryAll", queryName: "--查询全部--" };
      this.searchNameArr.splice(0, 0, all);
      //是否有default qisDefault
      const defaultCond = this.searchNameArr.find((o: any) => {
        return o.qisDefault;
      });
      if (defaultCond && this.isHome != "yes") {
        this.searchName = defaultCond.queryName;
        this.searchId = defaultCond.id;
        this.getQueryParms(defaultCond);
      } else {
        this.initPageData();
      }
    },
    //初始化
    initPageData() {
      this.searchParams.offset = 1;
      this.projectLists = [];
      this.searchParams.searchKey = this.searchKey;
      this.requsetData(this.searchParams, null);
    },
    onRightClick(right_: any) {
      // menuController.open();
      menuController.enable(true, "ProjectMenuPanel");
      menuController.open("ProjectMenuPanel");
    },
    addProject() {
      this.router.push("/project/add");
    },
    //跳转项目详情
    itemClick(projectInfo: any) {
      this.router.push({
        path: "/ProjectPage",
        query: {
          projectId: projectInfo.id,
          projectName: projectInfo.name,
          isStart: projectInfo.isStart,
          chatGroupId: projectInfo.chatGroupId,
          folderId: projectInfo.folderId,
          isDeleted: projectInfo.isDeleted,
        },
      });
    },
    //执行搜索
    searchHandler(val: any) {
      if (Utils.isInCludeEmoji(val.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      if ((val.searchKey + "").trim()) {
        this.searchId = "";
      } else {
        this.searchId = "queryAll";
      }
      this.isMore = true;
      //重置查询条件参数
      this.resetQueryParms(false);
      this.searchParams.projectName = val.searchKey;
      this.requsetData(this.searchParams, null);
    },
    //下拉刷新
    doRefresh(event: any) {
      this.isMore = true;
      // this.searchParams.projectName = "";
      this.searchParams.offset = 1;
      this.requsetData(this.searchParams, null);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //加载下一页数据
    nextPage(event: any) {
      if (this.isMore) {
        this.searchParams.offset++;
        this.requsetData(this.searchParams, event);
      }
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //请求接口
    requsetData(pamas: any, event: any) {
      this.projectService.getProjectList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r.data;
            if (result == null || result.length <= 0) {
              if (event) {
                this.isMore = false;
              }
            }
            if (event != null) {
              event.target.complete();
              this.projectLists = this.projectLists.concat(result);
            } else {
              this.projectLists = result;
              this.scrollToTop();
            }
            this.islist = false;
          } else {
            if (event != null) {
              event.target.complete();
              // this.isEmpty = true;
              this.projectLists = [];
            }
            this.islist = false;
          }
        },
        (e: any) => {
          this.islist = false;
        }
      );
    },
    //绑定得到查询条件
    getQueryParms(queryParms: any) {
      if (queryParms.isSelected == "true") {
        //选中图标
        this.btnIconRight = ["iconshaixuanxuanzhong_iconSelected"];
      } else {
        //未选中图标
        this.btnIconRight = ["iconshaixuanxuanzhong"];
      }
      this.isMore = true;
      if (queryParms.searchId != "queryAll") {
        this.searchParams.projectName = queryParms.qname;
        this.searchParams.createTimeCB = queryParms.qcreateTimeb;
        this.searchParams.createTimeCE = queryParms.qcreateTimee;
        this.searchParams.endTimeCB = queryParms.qendTimeb;
        this.searchParams.endTimeEE = queryParms.qendTimee;
        this.searchParams.projectLight = queryParms.qstatusLight;
        this.searchParams.partakeType = queryParms.qpartType;
        this.searchParams.systemId = queryParms.qsource;
        this.searchParams.createIds = queryParms.qcreatorIds;
        this.searchParams.offset = 1;
        this.searchId = queryParms.searchId;
        this.requsetData(this.searchParams, null);
      } else {
        this.resetQueryParms();
        this.initPageData();
      }
      this.$refs.searchRef.bindSearchModal(true);
    },
    //重置查询条件参数
    resetQueryParms(isReSetSearchRef?: boolean) {
      if (isReSetSearchRef) {
        //清空查询条件
        this.$refs.searchRef.bindSearchModal(true);
      }
      this.searchName = "--查询全部--";
      this.searchId = "queryAll";
      this.searchParams = {
        limit: 10,
        offset: 1,
        projectName: "",
        createTimeCB: "",
        createTimeCE: "",
        endTimeCB: "",
        endTimeEE: "",
        projectLight: "",
        systemId: "",
        partakeType: "",
        createIds: "",
        searchKey: "",
      };
    },
    visibleShadeDialog(val: boolean) {
      this.showShade = val;
    },
    closeShadeDialog() {
      this.showShade = false;
      this.$refs.searchRef.closeShade();
    },
    //根据企业Id获取用户的权限
    getUserPermission() {
      this.permissionService.getByEnterpriseIdAndUser().then(
        (resp: AllResponse<Permission>) => {
          if (resp) {
            if (resp) {
              let permissionData = resp.data;
              //判断是否拥有新增项目权限
              let putInfo = permissionData.filter(
                (item) => item.id == "enterpriseproject.post.project"
              );
              if (putInfo.length < 1) {
                this.isAddProject = false;
              } else {
                this.isAddProject = true;
              }
            }
          }
        },
        (e: any) => {
          console.log(e);
        }
      );
    },
    //下拉框
    async openPostPopover(ev: any) {
      if (this.searchNameArr.length > 0) {
        const popover = await popoverController.create({
          component: TaskSearchPanelPullDown,
          cssClass: "myPullDown",
          event: ev,
          componentProps: {
            searchNameArr: this.searchNameArr,
            searchName: this.searchName,
            searchId: this.searchId,
          },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data) {
          if (data.searchName != "") {
            //清空查询条件
            this.$refs.searchRef.bindSearchModal(true);
            this.searchName = data.searchName;
            this.searchId = data.searchID;
            this.getQueryParms(data.selectObj);
          }
        }
      } else {
        //todo 未设置查询条件
        Utils.presentToastWarning("请求异常或未设置查询条件");
      }
    },
    //滚动到顶部
    scrollToTop() {
      let ionContent: any = document.querySelector("#ProjectMain");
      if (ionContent) {
        ionContent.scrollToTop();
      }
    },
  },
});
