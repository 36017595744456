<template>
  <div class="card-content">
    <div>
      <div>
        <!-- <ion-checkbox color="light" @ionChange="change"></ion-checkbox> -->
        <ion-text class="taskTitle">创 建 人：{{ task.creator }}</ion-text>
      </div>
      <div>
        <ion-img
          class="imgIcon"
          :src="require('@/assets/images/identifier.png')"
        ></ion-img>
        <ion-text class="identifier">待 办 人：{{ task.executor }}</ion-text>
      </div>
    </div>
    <div @click="$emit('nav', task)">
      <ion-text class="task">任务描述：{{ task.taskDesc }}</ion-text>
      <i class="iconfont icon-jinru"></i>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  methods: {
    change() {
      console.log(111);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-content {
  width: calc(100% - 24px);
  padding: 0 15px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  .unRead {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
  }
  > div {
    padding: 15px 0 15px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      justify-content: start;
      align-items: center;
      .taskTitle {
        font-size: 13px;
        font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
        font-weight: 700;
        color: #333333;
      }
      .identifier {
        font-size: 13px;
        font-family: DIN, DIN-Medium;
        font-weight: bold;
        text-align: left;
        color: #333333;
      }
      .imgIcon {
        width: 12px;
        height: 12px;
        margin-right: 7px;
      }
      ion-checkbox {
        margin-right: 11px;
      }
    }

    .task {
      font-size: 11px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: bold;
      text-align: left;
      color: #333333;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > div:nth-of-type(1) {
    border-bottom: 0.5px solid #efefef;
  }
}
</style>