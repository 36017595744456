
import { defineComponent, reactive } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import PTaskStatusModal from "./PTaskStatusModal.vue";
import PTaskPRIModal from "./PTaskPRIModal.vue";
import { chevronDownOutline, ellipsisHorizontalOutline } from "ionicons/icons";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import SelectLable from "@/views/task/info/selectlable/SelectLable.vue";
import ProjectGroupingModal from "@/views/project/modal/ProjectGroupingModal.vue";

export default defineComponent({
  name: "PTaskInfo",
  components: { Header },
  data() {
    return { chevronDownOutline, ellipsisHorizontalOutline };
  },
  setup() {
    //声明响应式对象数据
    const taskInfo = reactive({
      id: "1",
      name: "任务中台需求评审",
      finish: "54",
      state: "0", //任务状态 0未完成 1完成
      executor: [
        {
          id: "1",
          name: "刘云",
        },
      ],
      joiner: [
        {
          id: "1",
          name: "刘云",
        },
      ],
      planStartTime: "2020-04-01",
      planEndTime: "2020-04-30",
      planTime: "528小时",
      practicalStartTime: "2020-04-01",
      practicalEndTime: "2020-04-30",
      alreadyTime: "123小时",
      priority: "2", //0较低 1紧急 2普通
      lables: [],
      subordinateProjectId: "1",
      subordinateProjectName: "任务中台APP及智能终端研发项目",
      groupStageId: "",
      groupStageName: "阶段",
    });

    //任务状态模态
    async function openPTaskStatusModal() {
      const modal = await modalController.create({
        component: PTaskStatusModal,
        cssClass: "project-task-status-class",
        componentProps: {
          checked: taskInfo.state,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if ("checked" in data) {
          if (data.checked != "") {
            taskInfo.state = data.checked;
          }
        }
      }
    }

    //选择任务状态事件
    function bindCheckPTaskStatus() {
      openPTaskStatusModal();
    }

    //优先级模态
    async function openPTaskPRIModal() {
      const modal = await modalController.create({
        component: PTaskPRIModal,
        cssClass: "project-task-pri-class",
        componentProps: {
          checked: taskInfo.priority,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if ("checked" in data) {
          if (data.checked != "") {
            taskInfo.priority = data.checked;
          }
        }
      }
    }

    //选择优先级
    function bindCheckPTaskPRI() {
      openPTaskPRIModal();
    }

    //执行人或参与人模态
    async function openPTaskUserModal(type: string) {
      const params = { type: type, ids: ["1", "2", "3"] };
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: params,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        const checkedUsers = data.data;
        const users: Array<any> = [];
        if (checkedUsers) {
          checkedUsers.forEach((el: any) => {
            if (el) {
              users.push({ id: el.id, name: el.staffName });
            }
          });
        }
        if (users.length > 0) {
          //执行人
          if (type == "executor") {
            taskInfo.executor = users;
          }
          //参与人
          if (type == "joiner") {
            taskInfo.joiner = users;
          }
        }
      }
    }

    //选择执行人
    function bindCheckExecuteUser() {
      openPTaskUserModal("executor");
    }

    //选择参与人
    function bindCheckPartUser() {
      openPTaskUserModal("joiner");
    }

    //标签模态框
    async function openPTaskLableModal() {
      const modal = await modalController.create({
        component: SelectLable,
        cssClass: "notice-modal-class",
        componentProps: {
          ids: ["1", "2"],
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        const lbs = data.checkedLabelList;
        if (lbs) {
          taskInfo.lables = lbs;
        }
      }
    }

    //选择标签
    function bindCheckLable() {
      openPTaskLableModal();
    }

    //所属项目模态框
    async function openPTaskSubordinateProjectModal() {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: {
          id: taskInfo.subordinateProjectId,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        taskInfo.subordinateProjectId = data.id;
        taskInfo.subordinateProjectName = data.name;
      }
    }

    //选择所属项目
    function bindCheckSubordinateProject() {
      openPTaskSubordinateProjectModal();
    }

    //分组阶段模态框
    async function openPTaskGroupStageModal() {
      const modal = await modalController.create({
        component: ProjectGroupingModal,
        cssClass: "notice-modal-class",
        componentProps: {
          selIndex: taskInfo.groupStageId,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        taskInfo.groupStageId = data.id;
        taskInfo.groupStageName = data.name;
      }
    }

    function bindSettingGroupStage() {
      openPTaskGroupStageModal();
    }

    return {
      taskInfo,
      bindCheckPTaskStatus,
      bindCheckPTaskPRI,
      bindCheckExecuteUser,
      bindCheckPartUser,
      bindCheckLable,
      bindCheckSubordinateProject,
      bindSettingGroupStage,
    };
  },
});
