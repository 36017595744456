
import { defineComponent } from "vue";
import { modalController, IonPage } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import moment from "moment";

export default defineComponent({
  name: "SelectRepeat",
  props: {
    paramItem: Object,
  },
  components: {
    HeaderModal,
    SearchBar,
  },
  data() {
    const router = useRouter();
    const listDefine = [
      {
        id: "diy",
        text: "自定义",
      },
    ];
    return {
      router: router,
      useRoute: useRoute(),
      selectedItem: {},
      selectedId: "",
      list: new Array(),
      listDefine: listDefine,
      title: "",
      endTime: "",
      endTimeStr: "",
      isRollback: true,
    };
  },
  mounted() {
    if (this.paramItem) {
      this.selectedItem = this.paramItem;
      this.selectedId = this.paramItem.id;
      this.endTime = this.paramItem.endTime;
    }
    this.initList();
  },
  watch: {
    endTime(newVal) {
      this.endTimeStr = moment(newVal).format("YYYY-MM-DD");
    },
  },
  methods: {
    removeSelectItem() {
      this.selectedItem = {};
      this.selectedId = "";
    },
    initList() {
      this.list = [
        {
          id: "never",
          text: "永不",
        },
        {
          id: "everyday",
          text: "每天",
        },
        {
          id: "everyweek",
          text: "每周",
        },
        {
          id: "everytwoweek",
          text: "每两周",
        },
        {
          id: "everymonth",
          text: "每月",
        },
        {
          id: "everyyear",
          text: "每年",
        },
      ];
      this.title = "选择";
    },
    checkHandler(e: any, item: any) {
      if (e.detail.checked) {
        this.selectedItem = item;
        this.selectedId = item.id;
        //默认重复截止时间：月
        let month = 3;
        switch (item.id) {
          case "everyday":
            month = 3;
            break;
          case "everyweek":
            month = 3;
            break;
          case "everytwoweek":
            month = 3;
            break;
          case "everymonth":
            month = 6;
            break;
          case "everyyear":
            month = 12;
            break;
        }
        let date = new Date();
        date.setMonth(date.getMonth() + month);
        this.endTime = moment(date).format("YYYY-MM-DD");
      }
    },
    clickItemHandler(item: any) {
      if (this.selectedId == item.id) {
        this.selectedId = "";
      } else {
        this.selectedId = item.id;
      }
    },
    //自定义
    selfCheckHandler(itemP: any) {
      let item = {
        id: -1,
      };
      //关闭页面
      modalController.dismiss({
        selectedItem: item,
      });
    },
    //选择截止日期
    selectEndTime() {
      let selectOperate: any = document.getElementById(
        "select-repeat-endtime-schedule"
      );
      let el: HTMLElement = selectOperate;
      el.click();
    },
    closeModel(type: any) {
      if (type == 4) {
        //关闭页面
        let selectItem: any = this.selectedItem;
        selectItem.endTime = this.endTime;
        modalController.dismiss({
          selectedItem: selectItem,
        });
      }
      modalController.dismiss({});
    },
  },
});
