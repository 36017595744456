/**
 * calendar 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class CalendarApi extends TaskBaseService {
    constructor() {
        super("calCalendar");
    }

    // 我的日历列表（创建人或者参与人是我）
    public getPageMyCal(params: any): Promise<any> {
        const path = 'getPageMyCal';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    // 我的回收站日历列表
    public getRecycleList(params: any): Promise<any> {
        const path = 'recycle/list';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    // 回收站还原
    public recycleCal(params: any): Promise<any> {
        const path = 'recycle/'+params.id;
        return this.request(path, this.rest.METHOD_POST,{});
    }
    //日历删除 clearById
    public clearById(id: any): Promise<any> {
        const path = 'clearById?id='+id.id;
        return this.request(path, this.rest.METHOD_DELETE);
    }
    // saveCalendarAndMem 保存日历对象和日历成员
    public saveCalendarAndMem(params: any): Promise<any> {
        const path = 'saveCalendarAndMem';
        return this.request(path, this.rest.METHOD_POST, params);
    }

    // 按Id逻辑删除实体
    public deleteById(params: any): Promise<any> {
        const path ='recycle/'+ params.id;
        return this.request(path, this.rest.METHOD_DELETE, {});
    }

     // 获取日历详情，含参与人信息/v1/calCalendar/getCalDetail/{id}
     public getCalDetail(params: any): Promise<any> {
        const path = 'getCalDetail/'+params.id;
        return this.request(path, this.rest.METHOD_GET, {});
    }

    //根据人员选择订阅日历列表 { offset = 1,limit=10,calName="",workUserId=[] }
     public getCalListByUserAndWorkUser(params: any): Promise<any> {
        const path ="getCalListByUserAndWorkUser?offset="+params.offset+"&limit="+params.limit+"&&calName="+params.calName;
        return this.request(path, this.rest.METHOD_POST, params.workUserId);
    }

}