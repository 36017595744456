/**
 * 上下级关系
 */
import TaskBaseService from '../base/TaskBaseService';

/**
 * 我的上下级
 */
export class UserRelationApi extends TaskBaseService{
    constructor() {
        super("userSubordinate");
    }
    /**
     * 获取我的上下级关系列表
     */
    public getRelationList(param:any):Promise<any>{
        const path='getList';
        return this.request(path,this.rest.METHOD_GET,param)
    }
    /**
     * 添加上级
     */
    public insertSuperior(data:any){
        const path="insertSuperior";
        return this.request(path,this.rest.METHOD_POST,data)
    }
    /**
     * 添加下级
     */
    public insertSubordinate(data:any){
        const path='insertSubordinate';
        return this.request(path,this.rest.METHOD_POST,data)
    }
    /**
     * 删除关系人员
     */
    public updateFieldsById(data:any){
        const path='updateFieldsById';
        return this.request(path,this.rest.METHOD_PUT,data)
    }
     /**
     * 获取我的领导
     */
    public getLeaderIds(data:any){
        const path='getLeaderIds?userId='+data.userId;
        return this.request(path,this.rest.METHOD_GET,null)
    }
}