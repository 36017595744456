/**
 * 登录页面，获取基础信息
 */
import AccountBaseService from '../base/AccountBaseService';
/**
 * 首页
 */
export class AccountApi extends AccountBaseService {
    
    constructor() {
        super("oauth","");
    }

    public login(email: string): Promise < any > {
        const path = 'login/email?email=' + email;
        return this.request(path, this.rest.METHOD_POST);
    }

    public getLoginUser() {
        const path = 'loginUser';
        return this.request(path, this.rest.METHOD_GET);
    }

    loginWithPass(login: string, pass: string) {
        let url = "token?grant_type=password&client_id=app_client&client_secret=6c8dee3e9bdc11e9861d5254008baa09";
        url+="&username="+login+"&password="+pass;
        return this.request(url, this.rest.METHOD_POST);
    }

    loginWithSMS(mobile: string, code: string) {
        let url = "token?grant_type=sms_code&terminal=0&client_id=app_client&client_secret=6c8dee3e9bdc11e9861d5254008baa09";
        url+="&mobile="+mobile+"&smscode="+code;
        return this.request(url, this.rest.METHOD_POST);
    }
}