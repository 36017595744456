
import "mqtt"
import { MqttClient, connect as asConnect, IClientOptions, OnMessageCallback, OnPacketCallback, OnErrorCallback, PacketCallback, Packet, IClientPublishOptions, ISubscriptionMap, IClientSubscribeOptions, ClientSubscribeCallback, ISubscriptionGrant, connect } from "mqtt";
import SystemUtils from "@/utils/SystemUtils";
import { getInstance, MessageService } from "@/services/message/MessageService";
export declare type MessageFunction = OnMessageCallback;

export default class MessageClient {

    private messageService: MessageService = getInstance();
    private static clientInstance: MessageClient;

    private client?: MqttClient;
    private _chatTopic: string;
    private _remindTopic: string;
    private _dataChangeTopic: string;
    private _events: MessageEvents;
    private userId: string;
    private systemId: string;
    private mqttAddress?: [];
    private options: IClientOptions = {
        username: 'admin',
        password: "public", //#MQTT-密码
        servers: [],
        clientId: "",
        protocol: "ws",
        path: "/mqtt"
    }

    private constructor(userId?: string, systemId?: string) {
        this.userId = userId || SystemUtils.loginUser.id;
        this.systemId = systemId || SystemUtils.systemId;
        this.options.clientId = this.userId + Math.random().toString(16).substr(2, 8);
        this._chatTopic = `chat/${this.userId}/${this.systemId}`;
        this._remindTopic = `remind/${this.userId}/${this.systemId}`;
        this._dataChangeTopic = `datachange/${this.userId}/${this.systemId}`;
        this._events = new MessageEvents();
        this.messageService.getMqttAddress().then((resp: any) => {
            console.log("ip and port: " + JSON.stringify(resp.data));

            this.mqttAddress = resp.data;
            this.options.servers = this.mqttAddress;
            MessageClient.clientInstance.connect();
        }).catch((error: any) => { });
    }

    public static mqttInit(userId?: string, systemId?: string): void {
        if (!MessageClient.clientInstance || userId != MessageClient.clientInstance.userId) {
            this.mqttClose();
            MessageClient.clientInstance = new MessageClient(userId, systemId);
            // MessageClient.clientInstance.connect();
        }
    }

    public static mqttClose(): void {
        if (MessageClient.clientInstance) {
            MessageClient.clientInstance.close();
            if (MessageClient.clientInstance.userId)
                MessageClient.clientInstance.userId = "";
        }
    }

    public static mqttClient(userId?: string, systemId?: string): MessageClient {
        if (userId) {
            MessageClient.mqttInit(userId, systemId);
        }
        return MessageClient.clientInstance;
    }

    private connect(options?: IClientOptions) {
        Object.assign(this.options, options);
        let self = this;
        this.client = asConnect(null, this.options);
        this.client.on("error", function (err: Error) {
            self.onError(err)
        });
        this.client.on("message", function (topic: string, payload: Buffer, packet: Packet) {
            self.onMessage(topic, payload, packet);
        });
        this.client.subscribe([this._chatTopic, this._remindTopic, this._dataChangeTopic]);
    }

    private close() {
        if (this.client) {
            this.client.end();
        }
    }

    public get chatTopic(): string {
        return this._chatTopic;
    }

    public get remindTopic(): string {
        return this._remindTopic;
    }

    public get dataChangeTopic(): string {
        return this._dataChangeTopic;
    }

    public get events(): MessageEvents {
        return this._events;
    }

    public subscribe(topic: string | string[] | ISubscriptionMap, callback?: ClientSubscribeCallback) {
        this.check();
        if (this.client) this.client.subscribe(topic, callback);
    }

    public unsubscribe(topic: string | string[], opts?: Object, callback?: PacketCallback) {
        this.check();
        if (this.client) this.client.unsubscribe(topic, opts, callback);
    }

    public reconnect() {
        this.check();
        if (this.client) this.client.reconnect();
    }

    private onMessage(topic: string, payload: Buffer, packet: Packet) {
        // let text = this.utf8ArrayToStr(payload);
        let text = payload.toString();
        let data = JSON.parse(text);
        if (this._events) {
            this._events.runWith(topic, data);
        }
    }

    private utf8ArrayToStr(array: Buffer) {
        var out, i, len, c;
        var char2, char3;
        out = "";
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12: case 13:
                    // 110x xxxx 10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx 10xx xxxx 10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }

        return out;
    }

    private onError(error: Error) {
        console.log(error.message);
        //alert("errorCallback:" + error.message);
    }

    public isConnected(): boolean {
        // this.check();
        if (this.client)
            return this.client.connected;
        else return false;
    }

    public publish(topic: string, data: any, callback?: PacketCallback) {
        this.check();
        let message = JSON.stringify(data);
        if (this.client)
            this.client.publish(topic, message, callback);
        return this;
    }

    private check() {
        if (!this.client) {
            throw new Error("client is null");
        }
    }


}

export class MessageEvents {
    protected handlers: Array<MessageEventHandler | ChatMessageEventHandler>;
    constructor() {
        this.handlers = [];
    }
    public runWith(topic: string, data: any) {
        for (let i = 0; i < this.handlers.length; i++) {
            let handler = this.handlers[i];
            if (topic == handler.topic) {
                handler.runWith(topic, data);
            }
        }
    }
    public remove(hander: MessageEventHandler | ChatMessageEventHandler) {

        // let index = this.handlers.indexOf(hander);
        // if (index > -1) {
        //     this.handlers.slice(index, 1);
        // }

        this.handlers.splice(
            this.handlers.findIndex(item1 => (item1 as any)._id == (hander as any)._id),
            1
        );
    }
    public add(hander: MessageEventHandler | ChatMessageEventHandler) {
        let index = this.handlers.indexOf(hander);
        if (index == -1) {
            this.handlers.push(hander);
        }
    }

    public clear() {
        this.handlers.length = 0;

    }
}


class Handler {
    static _pool: Array<Handler> = [];
    static _gid = 1;
    protected once?: boolean;
    protected _id: Number;
    protected caller?: any;
    protected method?: Function | null;
    protected args?: Array<any> | null;
    constructor(caller?: any, method?: Function, args?: Array<any>, once?: boolean) {
        this.once = false;
        this._id = 0;
        this.setTo(caller, method, args, once);
    }
    setTo(caller?: any, method?: Function, args?: Array<any>, once?: boolean) {
        this._id = Handler._gid++;
        this.caller = caller;
        this.method = method;
        this.args = args;
        this.once = once;
        return this;
    }
    run() {
        if (this.method == null)
            return null;
        var id = this._id;
        var result = this.method.apply(this.caller, this.args);
        this._id === id && this.once && this.recover();
        return result;
    }
    runWith(data?: any) {
        if (this.method == null)
            return null;
        var id = this._id;
        if (data == null)
            var result = this.method.apply(this.caller, this.args);
        else if (!this.args && !data.unshift)
            result = this.method.call(this.caller, data);
        else if (this.args)
            result = this.method.apply(this.caller, this.args.concat(data));
        else
            result = this.method.apply(this.caller, data);
        this._id === id && this.once && this.recover();
        return result;
    }
    clear(): Handler {
        this.caller = null;
        this.method = null;
        this.args = null;
        return this;
    }
    recover() {
        if (this._id > 0) {
            this._id = 0;
            Handler._pool.push(this.clear());
        }
    }
    static create(caller: any, method: Function, args: any = null, once: boolean = true): Handler {
        if (Handler._pool.length) {
            let pop = Handler._pool.pop();
            if (pop) {
                return pop.setTo(caller, method, args, once);
            }
        }
        return new Handler(caller, method, args, once);
    }
}



export class EventHandler extends Handler {
    static _pool: Array<Handler> = [];
    constructor(caller: any, method: Function, args?: Array<any>, once?: boolean) {
        super(caller, method, args, once);
    }
    recover() {
        if (this._id > 0) {
            this._id = 0;
            EventHandler._pool.push(this.clear());
        }
    }
    static create(caller: any, method: Function, args?: Array<any>, once?: boolean) {
        if (EventHandler._pool.length) {
            let pop = EventHandler._pool.pop();
            if (pop) {
                return pop.setTo(caller, method, args, once);
            }
        }

        return new EventHandler(caller, method, args, once);
    }
}

export class MessageEventHandler extends EventHandler {
    public topic: String;
    constructor(caller: any, method: MessageFunction, topic: string, args?: Array<any>) {
        super(caller, method, args);
        this.topic = topic;
    }
    public runWith(topic: string, data?: any) {
        this.args = [topic, data];
        this.run();
    }
}

export class ChatMessageEventHandler extends MessageEventHandler {
    chatGroupId: string;
    constructor(caller: any, method: MessageFunction, topic: string, chatGroupId: string, args?: Array<any>) {
        super(caller, method, topic, args);
        this.chatGroupId = chatGroupId;
    }
    public runWith(topic: string, data?: any) {
        if (data && data.chatGroupId == this.chatGroupId) {
            this.args = [topic, data];
            this.run();
        }
    }
}
export class DataChangeMessageEventHandler extends MessageEventHandler {
    private messageIds?: Array<string>;
    constructor(caller: any, method: MessageFunction, topic: string, messageIds?: Array<string>, args?: Array<any>) {
        super(caller, method, topic, args);
        if (messageIds)
            this.messageIds = messageIds;
    }
    public runWith(topic: string, data?: any) {
        if (data && this.existsTable(data.topic)) {
            this.args = [topic, data];
            this.run();
        }
    }
    private existsTable(messageId: string): boolean {
        if (!this.messageIds) {
            return true;
        }
        return this.messageIds.includes(messageId);
    }
}