
import { defineComponent } from "vue";
import Setting from '@/rest/Setting';
export default defineComponent({
  name: "AvatarList",
  props: {
    users: Object,
    group: {
      //true时显示组，false时排列显示
      type: Boolean,
      default: false,
    },
    text: {  //是否显示圆圈下的全名
      type: Boolean,
      default: true,
    },
    nameNum: {  //圆圈内显示名字的个数
      type: Number,
      default: 2,
    },
    close:{  //是否显示关闭图标，默认不显示
      type: Boolean,
      default: false,
    },
    lwidth: {
      type: Number,
      default: 4.25,
    },
    lheight: {
      type: Number,
      default: 4.25,
    },
    isOnly: { //如果组件外面有循环，那么isOnly必须为true
      type: Boolean,
      default: false,
    },
  },
  data() {
    const users:any=this.users
    return {
      getUser: new Array<any>(),
      userListLength: 0,
      userArray:users,
      direc:''
      // className: this.getClassName,
    };
  },
  created() {
  },
  mounted() {
      this.getData()
  },
  watch:{
    users(newName,oldName){
      this.getData();
    }
  },
  methods: {
    getData(){
       let that = this;
       let shor = "";
      that.userArray=that.users;
      that.getUser=new Array<any>();
      if (that.group==true) {
        if (that.userArray.length > 4) {
          that.userArray.splice(4);
        }
      }
      if(Array.isArray(that.userArray)){
        that.userArray.forEach(function (item: any, index: number) {
          let n = that.nameNum;
          if(that.group==true) {
            n = 1;
            if(that.userArray.length == 1){
                n =2;
            }
          }
          const baseURL:any=Setting.apiBaseServiceName.fileservice;
          /*if (item.img && item.img.length) {
            let img = "url(" + require("../assets/images/" + item.img) + ")";
            item.img = img;*/
          if(item.img){
            if (item.img.length) {
              //let img = baseURL+item.img;
              //data.url = Setting.baseURL + "/api/v1/docservice" + data.url;
              //item.img = img;
            }
          }

          //判断是否是中文字符，中文字符显示最后一 or 二位， 其它显示第一位字符
          let pattern = new RegExp("[\u4E00-\u9FA5]+");
          if(pattern.test(item.name)){ //中文字符
            //alert('该字符串是中文');
            if(item.name == undefined && item.name == null){
              shor = that.subLastString(item[0].name, n);
            } else {
              shor = that.subLastString(item.name, n);
            }

          } else { //其它字符
            //if(that.isOnly){
              //shor = that.subFirstString(item.name);
            //} else {
              shor = that.subFirstString(item.name);
            //}
          }
          item.shor = shor;
          that.getUser.push(item);
        });
        that.userListLength = that.getUser.length
      } else {
        let pattern = new RegExp("[\u4E00-\u9FA5]+");
        if(pattern.test(that.userArray.name)){ //中文字符
          shor = that.subLastString(that.userArray.name, that.nameNum);
        } else { //其它字符
          shor = that.subFirstString(that.userArray.name);
        }
        that.userArray.shor = shor;
        that.getUser = that.userArray;
      }
    },
    subFirstString(str: any) {
      //return (str.split("").join("").substring(0,n)).split("").reverse().join("");
      let fisrtStr = str.slice(0,1);
      let uCase = fisrtStr.toUpperCase()
      return uCase;
    },
    subLastString(str: any, n: any) {
      //return (str.split("").join("").substring(0,n)).split("").reverse().join("");
      return str.slice(-n);
    },
    getClassName() {
      let cName = "";
      let num = this.userArray.length;
      if (num > 0) {
        switch (num) {
          case 4:
            cName = "four";
            break;
          case 3:
            cName = "three";
            break;
          case 2:
            cName = "two";
            break;
          default:
            cName = "one";
            break;
        }
        // console.log("cName--------"+cName);
        return cName;
      }
    },
    getImgUrl(url: any) {
      return "../assets/images/" + url;
    },
    removePerson(index:any){
      this.$emit("removeSelect", index);
    }
  },
});
