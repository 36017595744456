
import { modalController, alertController } from "@ionic/vue";
import { useRouter } from "vue-router";
import BaseService from "@/services/base/BaseService";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import { defineComponent } from "vue";
//组件
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
// 权限 类
import { PermissionService } from "@/services/permission/PermissionService";
import { AllResponse } from "@/services/base/BaseService";
import Permission from "@/models/permission/Permission";

export default defineComponent({
  name: "MemberMenu",
  props: {
    projectId: String,
    projectName: String,
    hasAddMember: Boolean, //是否拥有新增权限
    // hasDelMember: Boolean, //是否拥有删除权限
    // hasEditMemberRole: Boolean,// 是否拥有编辑权限
  },
  data() {
    return {};
  },
  mounted() {
    Utils.resetModalHei(2);
  },
  methods: {
    closeModel() {
      modalController.dismiss();
    },
    //创建人
    async selectUser() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "other",
            executorList: [],
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.data) {
        console.log("选择项目成员");
        modalController.dismiss({ data: data.data, action: "addMember" });
        // this.creatArr = data.data;
        //初始化
      }
    },
    projectTransfer() {
      modalController.dismiss({ data: true, action: "settingRoles" });
    },
  },
});
