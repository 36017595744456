import { createAnimation, Animation } from '@ionic/core';


export class AnimationSelf {


    private static enterAnimation: any;//进入动画
    private static leaveAnimation: any;//离开动画
    private static screenWidth: number = screen.width; //屏幕的宽度
    private static duration =500;

    /**
     * modal:中间展开动画
     * {enterAnimation: value,leaveAnimation: value}
     */
    public get modalCenter(): AnimationResult {
        this.initAnimationCenter();
        const result: AnimationResult = {
            enterAnimation: AnimationSelf.enterAnimation,
            leaveAnimation: AnimationSelf.leaveAnimation
        };
        return result;
    }

    /**
     * modal:左侧滑动动画
     * {enterAnimation: value,leaveAnimation: value}
     */
    public get modalLeft(): AnimationResult {
        this.initAnimationLeft();
        const result: AnimationResult = {
            enterAnimation: AnimationSelf.enterAnimation,
            leaveAnimation: AnimationSelf.leaveAnimation
        };
        return result;
    }

    /**
     * modal:右侧滑动动画
     * {enterAnimation: value,leaveAnimation: value}
     */
    public get modalRight(): AnimationResult {
        this.initAnimationRight();
        const result: AnimationResult = {
            enterAnimation: AnimationSelf.enterAnimation,
            leaveAnimation: AnimationSelf.leaveAnimation
        };
        return result;
    }

    //设置动画对象
    private initAnimationCenter(): void {
        AnimationSelf.enterAnimation = (baseEl: any) => {
            const backdropAnimation = createAnimation()
                .addElement(baseEl.querySelector('ion-backdrop')!)
                .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
            const wrapperAnimation = createAnimation()
                .addElement(baseEl.querySelector('.modal-wrapper')!)
                .keyframes([
                    { offset: 0, opacity: '0', transform: 'scale(0)' },
                    { offset: 1, opacity: '0.99', transform: 'scale(1)' }
                ]);
            return createAnimation()
                .addElement(baseEl)
                .easing('ease-out')
                .duration(AnimationSelf.duration)
                .addAnimation([backdropAnimation, wrapperAnimation]);
        }
        AnimationSelf.leaveAnimation = (baseEl: any) => {
            return AnimationSelf.enterAnimation(baseEl).direction('reverse');
        }
    }

    private initAnimationLeft(): void {
        AnimationSelf.enterAnimation = (baseEl: any) => {
            const xpx = 'translateX(' + (AnimationSelf.screenWidth* -1) + 'px)';
            const backdropAnimation = createAnimation()
                .addElement(baseEl.querySelector('ion-backdrop')!)
                .fromTo('transform', xpx, 'translateX(0)');
            const wrapperAnimation = createAnimation()
                .addElement(baseEl.querySelector('.modal-wrapper')!)
                .fromTo('transform', xpx, 'translateX(0)');
            return createAnimation()
                .addElement(baseEl)
                .easing('ease-out')
                .duration(AnimationSelf.duration)
                .addAnimation([backdropAnimation, wrapperAnimation]);
        }
        AnimationSelf.leaveAnimation = (baseEl: any) => {
            return AnimationSelf.enterAnimation(baseEl).direction('reverse');
        }
    }

    private initAnimationRight(): void {
        AnimationSelf.enterAnimation = (baseEl: any) => {
            const xpx = 'translateX(' +AnimationSelf.screenWidth + 'px)';
            const backdropAnimation = createAnimation()
                .addElement(baseEl.querySelector('ion-backdrop')!)
                .fromTo('transform', xpx, 'translateX(0)');
            const wrapperAnimation = createAnimation()
                .addElement(baseEl.querySelector('.modal-wrapper')!)
                .fromTo('transform', xpx, 'translateX(0)');
            return createAnimation()
                .addElement(baseEl)
                .easing('ease-out')
                .duration(AnimationSelf.duration)
                .addAnimation([backdropAnimation, wrapperAnimation]);
        }
        AnimationSelf.leaveAnimation = (baseEl: any) => {
            return AnimationSelf.enterAnimation(baseEl).direction('reverse');
        }
    }
}

export interface AnimationResult {
    /**
     * 进入动画
     */
    enterAnimation: any;
    /**
     * 离开动画
     */
    leaveAnimation: any;
}