/**
 * 日历订阅-订阅申请 控制器 api
 */
import TaskBaseService from '../base/TaskBaseService';

export class ScheduleApi extends TaskBaseService {
    constructor() {
        super("schedule");
    }
    // 获取他人日历-人员列表
    public getSubscribeUserList(): Promise<any> {
        const path = 'getSubscribeUserList';
        return this.request(path, this.rest.METHOD_GET, null);
    }
    // 获取他人日历-人员列表
    public getOtherScheduleList(params: any): Promise<any> {
        const path = 'getOtherScheduleList';
        return this.request(path, this.rest.METHOD_GET, params);
    }
    /**
    * 他人日程每日待办数量：按月
    */
    public getOtherCountByMonth(params: any): Promise<any> {
        const path = "getOtherCountByMonth";
        return this.request(path, this.rest.METHOD_GET, params);
    }
}