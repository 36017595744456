/**
 * 任务阶段
 */
import TaskBaseService from '../base/TaskBaseService';
/**
 * 任务阶段
 */
export class TaskStagesApi extends TaskBaseService {

    constructor() {
        super("taskStages");
    }
    //根据项目和任务分组获取阶段列表
    public getStageListByProjecctAndTaskGroupId(params: any): Promise<any> {
       const path = 'getStageListByProjecctAndTaskGroupId';
       return this.request(path,this.rest.METHOD_GET, params);       
    }

    //新增任务阶段
    public createTaskStages(param: any): Promise < any > {
        const path = 'createTaskStages';
        return this.request(path, this.rest.METHOD_POST, param);
    }
    // //编辑任务阶段
    // public updateFieldsById(param: any): Promise < any > {
    //     const path = 'updateFieldsById';
    //     return this.request(path, this.rest.METHOD_PUT, param);
    // }
    //在后添加任务阶段
    public insertAfter(param: any): Promise < any > {
        const path = 'insertAfter';
        return this.request(path, this.rest.METHOD_POST, param);
    }
    //删除任务阶段
    public clearById(param: any): Promise < any > {
        const path ="clearById?id="+ param;
        return this.request(path, this.rest.METHOD_DELETE, null);
    }
    
    //在后添加任务阶段
    public addNewStage(param: any): Promise < any > {
        const path = '';
        return this.request(path, this.rest.METHOD_POST, param);
    }
}