
    import { TaskLinkContentService } from "@/services/task/TaskLinkContentService";
    import SystemUtils from "@/utils/SystemUtils";
    import { defineComponent } from "vue";

    export default defineComponent({
        name: "TaskLinkProjectList",
        props: {
            taskId: String,
            searchKey: String,
        },
        data() {
            return {
                showNoDate: false,
                linkApi: new TaskLinkContentService(),
                parameters: {
                    userId: "",
                    isDeleted: false,
                    keyword: "",
                    offset: 1,
                    limit: 15,
                    projectName: "",
                    createTimeCB: "",
                    createTimeCE: "",
                    endTimeCB: "",
                    endTimeEE: "",
                    projectLight: "",
                    createTimeOrder: "DESC",
                    statusLightOrder: "DESC?offset=0&limit=0",
                },
                list: [],
                idList: new Array < any > (), //选中的集合
            };
        },
        watch: {
            searchKey() {
                this.init(this.searchKey + "");
            },
        },
        mounted() {
            this.parameters.userId = SystemUtils.loginUser.id;
            this.init(this.searchKey + "");
        },
        methods: {
            init(keyword: string) {
                this.parameters.projectName = keyword;
                this.parameters.offset = 1;
                this.getListRequest((res: any) => {
                    this.list = res;
                    this.showNoDate = res.length == 0;
                });
            },
            //获取选择的Id集合
            getSelectedItems() {
                return this.idList;
            },
            checkHandler(e: any, item: any) {
                if (e.detail.checked) {
                    const newItem = {
                        targetId: item.id,
                        targetProjectId: item.id,
                        targetType: 1,
                        name: item.name,
                        type: "linked",
                    };
                    this.idList.push(newItem);
                } else {
                    this.idList = this.idList.filter((e) => {
                        return e.targetId != item.id;
                    });
                }
            },
            nextPage(event: any) {
                this.parameters.offset++;
                this.getListRequest((result: any) => {
                    this.list = this.list.concat(result);
                    event.target.complete();
                    if (result) {
                        if (result == null || result.length <= 0) {
                            event.target.disabled = true;
                        }
                    }
                });
            },
            doRefresh(event: any) {
                this.parameters.offset = 1;
                this.getListRequest((res: any) => {
                    this.list = res;
                    this.showNoDate = res.length == 0;
                });
                setTimeout(() => {
                    event.target.complete();
                }, 2000);
            },
            getListRequest(callback: any) {
                this.linkApi.getToLinkProjectList(this.parameters).then((res) => {
                    callback(res);
                });
            },
        },
    });
