
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
import AvatarList from "@/components/AvatarList.vue";
import { WarningService } from "@/services/warning/WarningService";
import RemindCard from "@/components/ListCard/RemindCard.vue";
import Bus from "@/utils/bus";
export default defineComponent({
  name: "RemindUnreadList",
  components: {
    CardLoading,
    AvatarList,
    RemindCard,
  },
  props: {
    refresh: Boolean, //是否刷新页面
    isShowHeader: String,
  },
  setup(props) {
    const { refresh, isShowHeader } = toRefs(props);
    const router = useRouter();

    let islist = ref(true); //是否显示加载中的标识
    const remindList = reactive({ data: new Array() });
    const selectTypeId = ref(""); //当前显示模块id
    const selectType = ref({
      icon: "",
      name: "",
      id: "",
      unReadCount: 0,
      code: "",
    }); //当前显示模块的信息
    const selectIndex = ref(-1); //当前显示模块的index
    const showFixed = ref(false); //是否需要固定模块的名称在顶部
    const scrollEnable = ref(false); //滚动条是否滚动
    const warningService = new WarningService();
    const searchParams = reactive({
      limit: 10,
      offset: 1,
      isReaded: 0,
      moduleId: selectTypeId.value,
      keyWord: "",
    });
    const targetIndex = ref(0);
    const selSeg = ref(0);
    let isEmpty=ref(-1);

    //滚动到顶部 type:part 提醒列表 window:屏幕移到顶部
    const scrollToTop = (type: string) => {
      let that = this;

      nextTick(() => {
        if (selectIndex.value > 0 && type == "remind") {
          let id = remindList.data[selectIndex.value - 1].id;
          let ionContent: any = document.getElementById("#id" + id);
          if (ionContent != null) {
            ionContent.scrollIntoView();
          }
        } else {
          let ionContent: any = document.querySelector(
            "#RemindUnReadListContent"
          );
          if (ionContent != null) {
            ionContent.scrollToTop();
          }
        }
        scrollEnable.value = false;
      });
    };
    const initData = () => {
      //  resetPage();
      getModules(null);
    };
    //收到消息后重新加载信息
    const receiveResetPage = (data: any) => {
      let message: any = data;
      let remindType = message.remindType;
      switch (remindType) {
        case "task":
          break;
      }
      //重新赋值remindList
      let list = remindList.data.filter((ele: any) => {
        return ele.code == remindType;
      });
      let msg = {
        content: JSON.parse(message.content).msg,
        createdBy: "",
        createdDate: message.sendTime,
        defId: "",
        id: message.id,
        itemId: message.busId,
        lastUpdatedBy: "",
        lastUpdatedDate: message.sendTime,
        properties: message.busTag,
        propertiesJson: JSON.parse(message.busTag),
        senderId: message.sendUserId,
        senderName: message.sendUserName,
        status: "",
        title: "",
      };
      //如果点开了某个模块,在index=0位置加本条消息
      if (selectType.value.code == remindType) {
        // if(remindList.data[selectIndex.value].list)
        // tempList.unshift(msg);
        let msgList = new Array();
        msgList.push(msg);
        if (remindList.data[selectIndex.value].list) {
          let tempList = JSON.parse(
            JSON.stringify(remindList.data[selectIndex.value].list)
          );
          msgList = msgList.concat(tempList);
        }
        remindList.data[selectIndex.value].list = [];
        nextTick(() => {
          setTimeout(function () {
            remindList.data[selectIndex.value].list = msgList;
            nextTick(() => {
              scrollToTop("window");
            });
          }, 500);
        });
        // scrollToTop("window");
      }
      if (list.length > 0) {
        list[0].lastedMessage = msg;
        list[0].unReadCount = list[0].unReadCount + 1;
        selectType.value.unReadCount = list[0].unReadCount;
      }
    };
    //重新从第一页获取
    const resetPage = () => {
      nextPageHasData.value = true;
      searchParams.offset = 1;
      getListRequest((res: any) => {
        //remindList.data = res;
        if (res == null || res.length > 0) {
          remindList.data[selectIndex.value].list = res;
          scrollToTop("remind");
        } else {
          scrollToTop("window");
          // resetSelect();
        }
      isEmpty.value= remindList.data.findIndex((item:any)=>item.unReadCount>0);
      });
    };
    const nextPage = (event: any) => {
      if (selectTypeId.value != "") {
        searchParams.offset++;
        getListRequest(
          (result: any) => {
            // remindList.data = remindList.data.concat(result);
            remindList.data[selectIndex.value].list = remindList.data[
              selectIndex.value
            ].list.concat(result);
            setTimeout(() => {
              event.target.complete();
            }, 1000);
            if (result) {
              if (result == null || result.length <= 0) {
                //  event.target.disabled = true;
              }
            }
          },
          () => {
            event.target.complete();
          }
        );
      }
    };
    const canGetNextPage = ref(false);
    const getNextPageDoing = ref(false); //正在获取下一页的数据
    const nextPageHasData = ref(true); //下一页没有数据了
    const nextPage2 = () => {
      canGetNextPage.value = false;
      getNextPageDoing.value = true;
      if (selectTypeId.value != "") {
        searchParams.offset++;
        getListRequest(
          (result: any) => {
            // remindList.data = remindList.data.concat(result);
            remindList.data[selectIndex.value].list = remindList.data[
              selectIndex.value
            ].list.concat(result);
            nextTick(() => {
              getNextPageDoing.value = false;
            });
            if (result.length == 0) {
              nextPageHasData.value = false;
            }
            if (result) {
              if (result == null || result.length <= 0) {
                //  event.target.disabled = true;
              }
            }
          },
          () => {}
        );
      }
    };
    const doRefresh = (event: any) => {
      //resetPage();
      initData();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };
    const getListRequest = (callback: any, errCalback?: any) => {
      warningService
        .getListByModules(searchParams)
        .then((res: any) => {
          islist.value = false;
          res.data.forEach((ele: any) => {
            ele.propertiesJson = JSON.parse(ele.properties);
          });
          callback(res.data);
        })
        .catch(() => {
          islist.value = false;
          if (errCalback) {
            errCalback();
          }
        });
    };
    const getModules = (callback: any) => {
      warningService
        .getModules()
        .then((res: any) => {
          islist.value = false;
          remindList.data = res.data;
          resetSelect();
          showFixed.value = true;
          if (callback && typeof callback == "function") {
            callback();
          }
        })
        .catch(() => {
          islist.value = false;
        });
    };
    const avatarDataAllList = (item: any) => {
      let obj = { name: item.userName, img: "", id: item.userId };
      return obj;
    };
    const selectTypeHandlerCard = (result: any) => {
      let item: any = result.item;
      let index: number = result.index;
      if (selectTypeId.value == item.id) {
        resetSelect();
        searchParams.moduleId = "";
      } else {
        selectTypeId.value = item.id;
        selectType.value.icon = item.icon;
        selectType.value.id = item.id;
        selectType.value.name = item.name;
        selectType.value.unReadCount = item.unReadCount;
        selectType.value.code = item.code;
        selectIndex.value = index;
        showFixed.value = true;
        searchParams.moduleId = item.id;
        resetPage();
      }
    };
    const resetSelect = () => {
      selectTypeId.value = "";
      selectType.value = {
        icon: "",
        name: "",
        id: "",
        unReadCount: 0,
        code: "",
      };
      selectIndex.value = -1;
      scrollToTop("window");
      isEmpty.value= remindList.data.findIndex((item:any)=>item.unReadCount>0);
    };
    //详情
    const detailHandler = (params: any) => {
      readstatusChange(params.item.id, params.item.index);
      switch (selectType.value.code) {
        case "task":
          router.push({
            path: "/task-page",
            query: {
              id: params.item.propertiesJson._sys_params.taskId,
              taskName: params.item.propertiesJson._sys_params.taskName,
            },
          });
          break;
        case "OKR":
          router.push({
            path: "/OKR/details",
            query: {
              id: params.item.itemId,
            },
          });
          break;
        case "schedule":
          router.push({
            path: "/schedule/detail/feedback",
            query: {
              id: params.item.propertiesJson._sys_params.scheduleId,
              scheduleTimeId: params.item.propertiesJson._sys_params.timeId,
              showCalendar: "true",
            },
          });
          break;
        case "project":
          router.push({
            path: "/ProjectPage",
            query: {
              projectId: params.item.propertiesJson._sys_params.projectId,
              projectName: params.item.propertiesJson._sys_params.projectName,
              isStart: params.item.propertiesJson._sys_params.isStart,
              chatGroupId: params.item.propertiesJson._sys_params.chatGroupId,
              folderId: params.item.propertiesJson._sys_params.folderId,
              isDeleted: params.item.propertiesJson._sys_params.isDeleted,
            },
          });
          break;
      }
      setTimeout(()=>{
        Bus.$emit("updateWarningCount"); 
      },1500)
    };
    const readstatusChange = (id: any, index: number) => {
      let param = { id: id };
      let thisSelectTypeId = selectTypeId.value + "";
      warningService
        .readstausChange(param)
        .then((res: any) => {
          getModules(() => {
            nextTick(() => {
              let item = {};
              let itemIndex = -1;
              remindList.data.forEach((ele: any, index: number) => {
                if (ele.id == thisSelectTypeId) {
                  item = ele;
                  itemIndex = index;
                }
              });
              if (item != {}) {
                let p = { item: item, index: itemIndex };
                selectTypeHandlerCard(p);
              }
            });
          });
        })
        .catch(() => {
          islist.value = false;
        });
    };

    const handleScroll = (ev: any) => {
      scrollEnable.value = true;
      if (selectIndex.value >= 0) {
        let selectId = remindList.data[selectIndex.value].id;
        let el: any = document.getElementById("id" + selectId);
        // console.log(JSON.stringify(el.getBoundingClientRect()));
        //40
        if (
          el.getBoundingClientRect().top < 79 ||
          (selectIndex.value == remindList.data.length - 1 &&
            el.getBoundingClientRect().bottom < 600)
        ) {
          showFixed.value = true;
        } else {
          showFixed.value = false;
        }
        //滚动条滚动到下方时加载下一页数据
        if (
          el.getBoundingClientRect().bottom < 900 &&
          el.getBoundingClientRect().bottom > 550
        ) {
          //canGetNextPage 滚动条是否在能请求下一页的范围内
          //getNextPageDoing 是否正在执行请求下一页的接口
          //nextPageHasData：上一次请求是否有数据，没数据了就不请求下一页了
          if (!canGetNextPage.value && !getNextPageDoing.value) {
            canGetNextPage.value = true;
          }
          if (nextPageHasData.value && canGetNextPage.value) {
            nextPage2();
          }
        }
      }
    };

    const showHeader = ref(true);
    onMounted(() => {
      Bus.$on("receive_remind_message", (data: any) => {
        receiveResetPage(data);
      });
      if (isShowHeader != null && isShowHeader.value != null) {
        if (isShowHeader.value == "false") {
          showHeader.value = false;
        }
      }
      initData();
    });
    onUnmounted(() => {
      Bus.$off("receive_remind_message");
    });

    watch(refresh, (newValue, oldVlaue) => {
      if (newValue) {
        initData();
      }
    });

    return {
      islist,
      router,
      remindList,
      searchParams,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      detailHandler,
      avatarDataAllList,
      selectTypeId,
      selectTypeHandlerCard,
      selectType,
      selectIndex,
      handleScroll,
      showFixed,
      scrollEnable,
      resetSelect,
      showHeader,
      isEmpty
    };
  },
});
