
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import TaskList from "./child/TaskList.vue";
import FileList from "./child/FileList.vue";
import TagList from "./child/TagList.vue";
import FolderList from "./child/FolderList.vue";
import TaskGroupList from "./child/TaskGroupList.vue";
import Permission from "@/models/permission/Permission";

export default defineComponent({
  name: "prject-recycle",
  components: {
    Header,
    TaskList,
    FileList,
    TagList,
    FolderList,
    TaskGroupList,
  },
  props: {},
  data() {
    return {
      // indicators: ["任务", "文件","标签","文件夹", "任务分组"],
      indicators: ["任务", "文件", "文件夹"],
      selSeg: 0,
      projectInfo: null,
      title: "项目回收站",
      projectId: "",
      folderId: "",
      archivePermission: {
        taskPermission: {
          isHasPutArchive: false,
          isHasDel: false,
          taskPermissionArr: new Array<Permission>(),
        },
        filePermission: {
          isHasPutArchive: false,
          isHasDel: false,
        },
        tagPermission: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
        },
        documentPermission: {
          isHasDel: false, //删除权限
          isHasPutArchive: false, //移到回收站权限/恢复回收站权限
        },
        taskGroupPermission: {
          isHasPutArchive: false,
          isHasDel: false,
        },
      },
      refreshTask: false,
    };
  },
  ionViewWillEnter() {
    let that = this;
    if (that.selSeg == 0) {
      that.refreshTask = true;
      setTimeout(function() {
        that.refreshTask = false;
      }, 500);
    }
  },
  created() {
    let archivePermission: any = this.$route.query.archivePermission;
    if (archivePermission) {
      this.archivePermission = JSON.parse(archivePermission);
    }
    let projectId = this.$route.query.projectId;
    let folderId = this.$route.query.folderId;

    if (projectId) {
      this.projectId = projectId.toString();
    }
    if (folderId) {
      this.folderId = folderId.toString();
    }
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.selSeg = ev.detail.value;
    },
  },
});
