
import Header from "@/components/Header.vue";
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
export default defineComponent({
  name: "PrivacyPolicy",
  components: {
    Header,
    HeaderModal,
  },
  props: {
    isLogin: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    disAgreeHandler() {},
    agreeHandler() {},
    closeModel() {
      modalController.dismiss();
    },
  },
});
