
import { defineComponent } from "vue";
import {
  modalController,
  IonPage,
  IonContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { getInstance } from "@/services/project/projectService";
export default defineComponent({
  name: "ProjectTypeModal",
  components: {
    IonPage,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    HeaderModal,
  },
  props: {
    selIndex: String,
  },
  data() {
    const projectService = getInstance();
    return {
      projectService: projectService,
      selValue: "",
      options: Array(0),
      selectedObj: null,
    };
  },
  created() {
    this.options = [];
    //获取项目分组
    this.projectService.getBySystemId({}).then(
      (r: any) => {
        if (r) {
          this.options = r.data;
        } else {
          console.log("无类型");
        }
      },
      (e: any) => {
        console.log(e);
      }
    );
    if (this.selIndex) this.selValue = this.selIndex;
  },
  methods: {
    closeModel(type: any) {
      if (type == 4) {
        if (this.selectedObj)
          modalController.dismiss({ typeObj: this.selectedObj });
        else modalController.dismiss();
      } else {
        modalController.dismiss();
      }
    },
    changed(event: any) {
      this.selValue = event.detail.value;
    },
    clickType(option: any) {
      this.selectedObj = option;
    },
  },
});
