
    import { defineComponent } from "vue";
    import {DashboardService} from "@/services/dashboardService";
    import CardLoading from "@/components/ListCard/CardLoading.vue";
    export default defineComponent({
        name: "projectTeam",
        data() {
            return {
                loading:{},
                api: new DashboardService(),
                data:[],
                projectId:"",
                parameters: {
                    pageNum: 1,
                    pageSize: 20,
                    allTeamUser: 1, //过滤没有分配的人员
                    allTeamUserRepeat: 1, //团队人员去重
                    projectId:""
                },
                showNoData: false, //展示暂无数据
                islist: true, //是否显示加载中的标识
            }
        },
        components: {
          CardLoading
        },
        methods: {
          doRefresh(event: any) {
            this.resetPage();
            setTimeout(() => {
              event.target.complete();
            }, 2000);
          },
            nextPage(event: any) {
                this.parameters.pageNum++;
                this.getListRequest((result: any) => {
                    this.data = this.data.concat(result);
                    event.target.complete();
                    if (result) {
                        if (result == null || result.length <= 0) {
                            event.target.disabled = true;
                        }
                    }
                });
            },
            getListRequest(callback: any) {
                this.parameters.projectId= this.projectId;
                this.api.getTeamUser(this.parameters).then((res: any) => {
                  this.islist = false;
                  if (res.data.data && res.data.data.rows) {
                    callback(res.data.data.rows);
                  }
                })
            },
            resetPage() {
                this.parameters.pageNum = 1;
                this.getListRequest((res: any) => {
                    this.data = res;
                    this.showNoData = res == null || res.length <= 0;
                });
            },
        },
        created() {
            this.projectId=this.$route.query.projectId as any;
            this.resetPage();
        },
    });
