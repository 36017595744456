import { httpPostAIUI,aiuiPostfileTransfer } from '@/rest/IonicHttp';
import SystemUtils from '@/utils/SystemUtils';
import crypto from "crypto";
export class AiuiService {

  /**
   * 讯飞发送语音
   * @param dataType 数据类型，可选值：text（文本），audio（音频）
   * @param scene 情景模式（目前不支持 wpgs-识别动态修正 场景）,例如：main
   * @param sampleRate 音频采样率，可选值：16000（16k采样率）、8000（8k采样率），默认为16000
   * @param authId 用户唯一ID（32位字符串，包括英文小写字母与数字，开发者需保证该值与终端用户一一对应）
   * @param aue 音频编码，可选值：raw（未压缩的pcm或wav格式）、speex（speex格式，即sample_rate=8000的speex音频）、speex-wb（宽频speex格式，即sample_rate=16000的speex音频），默认为 raw
   * @param filePath 文件路径（语音）相对路径，用于保存到数据库
   */
  public aiuiSend(paramBody: any): Promise<any> {
    let SCENE = "main_box";
    let AUE = "raw";
    let userId = SystemUtils.loginUser.id;
    // let AUTH_ID ="98912e0f71c1c605cb33ec8790ae157f";    // 1f813a38-6ccc-4f0c-9f1f-3cc79b6ea14c
    console.log("SystemUtils.loginUser.id:::" + userId);
    let AUTH_ID = userId ? userId.replace(/-/g, '') : "98912e0f71c1c605cb33ec8790ae157f";
    console.log("AUTH_ID: " + AUTH_ID);
    let DATA_TYPE = "audio";
    let SAMPLE_RATE = "16000";
    var LAT = "39.938838";
    var LNG = "116.368624";
    let RESULT_LEVEL = "plain";
    // let PERS_PARAM = "{\\\"auth_id\\\":\\\"98912e0f71c1c605cb33ec8790ae157f\\\"}";
    let interact_mode = "oneshot"; //是否开启云端vad，开启后可能返回多个识别和语义结果,continuous（开启），oneshot（关闭）
    let param = "{\"result_level\":\"" + RESULT_LEVEL + "\",\"aue\":\"" + AUE + "\",\"scene\":\"" + SCENE + "\",\"auth_id\":\"" + AUTH_ID + "\",\"data_type\":\"" + DATA_TYPE + "\",\"sample_rate\":\"" + SAMPLE_RATE + "\",\"lat\":\"" + LAT + "\",\"lng\":\"" + LNG + "\",\"interact_mode\":\"" + interact_mode + "\"}";
    /* 智能管理助手*/
    const APPID = "5f8e3914";
    const API_KEY = "cd7235be40872cb1488916a18a74f64f";
    /*智能管理应用1 技能*/
    // const APPID = "9900356c";
    // const API_KEY = "546c89525dc1642e4ae228d3f256e73a";
    /*智能管理应用2*/
    // const APPID = "30ea5e0e";
    // const API_KEY = "c4cf894b46f1c5ece78169bce93f13ad";
    
    const X_CurTime: number = Math.floor(new Date().getTime() / 1000);
    const XParam: any = Buffer.from(param).toString('base64');
    var X_CheckSum = this.md5(API_KEY + X_CurTime + XParam);
    let headers: any = {
      "X-Appid": APPID,
      "X-CurTime": X_CurTime + "",
      "X-Param": XParam,
      "X-CheckSum": X_CheckSum,
      "Content-Type": "audio/x-wav"
    };
    // console.log("heards:************"+JSON.stringify(headers));
    if (!AUTH_ID || AUTH_ID.length != 32) {
      return new Promise((resolve, reject) => {
        reject(AUTH_ID ? "用户id转authId错误" : "请登录");
      });
    }
    return aiuiPostfileTransfer("http://openapi.xfyun.cn/v2/aiui", headers, paramBody.filePath, paramBody.fileName);


  }


  
  public md5(text: any): any {
    return crypto.createHash('md5').update(text).digest('hex');
  }
}
