
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonCard,
  IonItem,
  IonCardContent,
  IonText,
  IonIcon,
  IonInput,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { getInstance } from "@/services/MicroIssuesService";
import Utils from "@/utils/Utils";
import BaseRsp from "@/models/base/BaseRsp";
import SelectVisibleProject from "@/views/project/selectVisibleProject/selectVisibleProjectModel.vue";
import ConvertToChildTask from "@/views/task/menu/ConvertToChildTask.vue";
import Issues from "@/models/microIssues/Issues";
import SystemUtils from "@/utils/SystemUtils";
import { log } from "util";
import UserInfo from "@/services/selectexecutor/UserInfo";
export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonItem,
    IonCardContent,
    IonText,
    IonIcon,
    IonInput,
    Header,
  },
  data() {
    const params = this.$route.params;
    const service = getInstance();
    const issues = new Issues();
    const btnTextRight = ["完成"];
    const btnSizeRight = ["45px"];
    return {
      btnTextRight: btnTextRight,
      btnSizeRight: btnSizeRight,
      takeUsers: Array(0),
      title: "",
      remark: "",
      type: params.type,
      projectId: params.projectId,
      projectName: params.projectName,
      taskId: params.taskId,
      taskName: params.taskName,
      isEdit: params.isEdit,
      issuesjson: params.issues,
      issues: issues,
      service: service,
      lines: 1,
      permitSelTask: true,
      permitSelProject: true,
      mIsEdit: false,
      info: { projectName: "", taskName: "", remark: "" },
      isSaveFinish: true, //是否保存成功
      currentUser: {
        id: Number.parseInt(SystemUtils.loginUser.id),
        name: SystemUtils.loginUser.name,
        pernr: SystemUtils.loginUser.pernr,
        photo: SystemUtils.loginUser.photo,
        userId: SystemUtils.loginUser.id,
        department: SystemUtils.loginUser.department,
        // color: SystemUtils.loginUser.userColor,
        isChecked: true,
      },
      showProject: true,
      showTask: true,
      
    };
  },
  created() {
    this.mIsEdit = this.isEdit == "true" ? true : false;
    if (this.mIsEdit) {
      this.bindViewValue(JSON.parse(this.issuesjson + ""));
      this.getIssuesInfo(this.issues.id + "");
    } else {
      this.takeUsers.push(this.currentUser);
    }
    this.checkSelect();
    this.showOrHide();
  },
  methods: {
    showOrHide() {
      if (
        (!this.projectName || this.projectName == "") &&
        !this.permitSelProject
      ) {
        this.showProject = false;
      }

      if ((!this.taskName || this.taskName == "") && !this.permitSelTask) {
        this.showTask = false;
      }
    },
    /**
     * 绑定微议题信息至view
     */
    bindViewValue(issues: Issues) {
      if (issues) {
        this.issues = issues;
        if (issues.title) this.title = issues.title;
        if (issues.projectId) this.projectId = issues.projectId;
        if (issues.taskId) this.taskId = issues.taskId;
        if (issues.projectName) this.projectName = issues.projectName;
        if (issues.taskName) this.taskName = issues.taskName;
        if (issues.remark) this.remark = issues.remark;
        if (issues.users) this.takeUsers = issues.users;
      }
    },

    // 判断项目、任务是否可选
    checkSelect() {
      if (this.mIsEdit || this.type == "task") {
        this.permitSelTask = false;
        this.permitSelProject = false;
      } else if (this.type == "project") {
        this.permitSelProject = false;
      }
    },

    titleTextareaChange(event: any) {
      this.title = event.detail.value;
    },

    textareaChange(event: any) {
      this.remark = event.detail.value;
    },

    // 选择参与人
    async selectPeople() {
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "joiner",
            executorList: this.takeUsers,
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data) {
        let isExist = false;
        for (let user of data.data) {
          if (user.userId == this.currentUser.userId) {
            isExist = true;
            break;
          }
        }
        this.takeUsers = data.data;
        if (!isExist) {
          this.takeUsers.unshift(this.currentUser);
        }
      }
    },

    // 选择任务
    async selectTask() {
      if (!this.permitSelTask) {
        return;
      }
      // 选择任务modal
      const modal = await modalController.create({
        component: ConvertToChildTask,
        cssClass: "notice-modal-class",
        componentProps: {
          source: 1,
          taskId: this.taskId,
          projectId: this.projectId,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectTask) {
        this.taskId = data.selectTask.id;
        this.taskName = data.selectTask.name;
        if (data.selectTask.projectId && data.selectTask.projectId != "") {
          this.projectId = data.selectTask.projectId;
          this.projectName = data.selectTask.projectName;
        }
      }
    },

    // 选择项目
    async selectProject() {
      if (!this.permitSelProject) {
        return;
      }
      // 选择项目modal， if（项目重新被选择，清空选择的任务）
      const modal = await modalController.create({
        component: SelectVisibleProject,
        cssClass: "notice-modal-class",
        componentProps: {
          source: 1,
          paramProject: { id: this.projectId, name: this.projectName },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.selectedProject) {
        if (this.projectId != data.selectedProject.id) {
          this.taskId = "";
          this.taskName = "";
          this.projectId = data.selectedProject.id;
          this.projectName = data.selectedProject.name;
        }
      }
    },

    enterSuccess() {
      if (this.mIsEdit) {
        this.updateIssues(this.issues);
      } else {
        if (this.isSaveFinish) {
          this.isSaveFinish = false;
          this.createIssues();
        } else {
          Utils.presentToastWarning("操作过于频繁，请稍后再试");
        }
      }
    },

    // 新增微议题
    createIssues() {
      this.service
        .addMicroIssuesExpose(
          this.title,
          this.projectId + "",
          this.projectName + "",
          this.taskId + "",
          this.taskName + "",
          this.remark,
          this.takeUsers
        )
        .then(
          (rsp: BaseRsp) => {
            if (rsp) {
              Utils.presentToast("新建成功");
              this.$router.go(-1);
            }
          },
          (err: BaseRsp) => {
            this.isSaveFinish = true;
            Utils.presentToastWarning(err.message);
          }
        );
    },

    // 获取微议题信息
    getIssuesInfo(issuesId: string) {
      this.service.getIssuesInfo(issuesId).then(
        (rsp: any) => {
          this.bindViewValue(rsp);
        },
        (err: BaseRsp) => {
          Utils.presentToastWarning(err.message);
        }
      );
    },

    // 修改微议题信息
    updateIssues(issues: Issues) {
      this.service
        .updateIssuesExpose(
          issues,
          this.title,
          this.projectId + "",
          this.projectName + "",
          this.taskId + "",
          this.taskName + "",
          this.remark,
          this.takeUsers
        )
        .then(
          (rsp: BaseRsp) => {
            if (rsp) {
              Utils.presentToast("修改成功");
              this.$router.go(-1);
            }
          },
          (err: BaseRsp) => {
            Utils.presentToastWarning(err.message);
          }
        );
    },
  },
});
