
import { popoverController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TargetNamePopover",
  components: {  },
  props: {
    targetNameArr: Array,
    targetObj:{
        type:Object,
        default:{
            id:"",
            name:""
        }
    }, 
  },
  data() {
    return {
      searchSelectId:this.targetObj.id,
      screenHeight:document.body.clientHeight * 0.9,
    };
  },
  methods: {
    getHeight(){
      let point=0.8; 
      return document.body.clientHeight * (document.body.clientHeight/812*point) +'px';
    },
    clickItem(obj: any) {
      if (obj) {
         this.searchSelectId=obj.id
        popoverController.dismiss({
          targetName: obj.name,
          targetObj: obj, 
        });
      } else popoverController.dismiss();
    },
  },
});
