import Setting from '@/rest/Setting';
import CoreService from './CoreService';

export default class MsgBaseService extends CoreService {
    constructor(resource: string, version?: string) {
        super({
            name: Setting.apiBaseServiceName.msgservice,
            resource: resource
        });
    }
}