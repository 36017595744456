import { TaskPermissionApi } from '@/api/task/TaskPermissionApi';
import { EUserWorkType } from '@/models/task/Enum';
import Setting from '@/rest/Setting';
import SystemUtils from '@/utils/SystemUtils';
import Utils from '@/utils/Utils';

export class TaskPermissionService {
    protected api = new TaskPermissionApi();

    public getTaskRoleIdList(task: any): Array < String > {
        const userId: string = SystemUtils.loginUser.id;
        let roleIdList: Array < String > = [];
        if (task.creatorId == userId) {
            roleIdList.push("task.creator");
        }
        if (task.taskUser != null && task.taskUser.length > 0) {
            for (let taskUser of task.taskUser) {
                if (taskUser.workType == EUserWorkType.Executor && taskUser.userId == userId) {
                    roleIdList.push("task.executor");
                } else if (taskUser.workType == EUserWorkType.Party && taskUser.userId == userId) {
                    roleIdList.push("task.actor");
                }
            }
        }
        return roleIdList;
    }

    public getTaskPermissionSettings(id: String, isProject: Boolean): Promise < any > {
        if (isProject) {
            return this.getTaskPermissionByProjectId(id);
        } else {
            return this.getTaskPermissionByTaskId(id);
        }
    }

    /**
     * 根据项目获取任务设置
     * @param id 
     */
    private getTaskPermissionByProjectId(id: String): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.getTaskPermissionByProject({
                "projectId": id
            }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    /**
     * 根据项目获取任务设置
     * @param id 
     */
    private getTaskPermissionByTaskId(id: String): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.getTaskPermissionByTask({
                "taskId": id
            }).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    public saveTaskPermissionSetting(id: String,permissionId: string,isCheck: boolean,roleId: string,isProject: Boolean): Promise < any > {
        const saveParam: any = {
            "permissionId": permissionId,
            "taskRoleId": roleId,
            "isCheck": isCheck,
            "systemId": "task"
        };
        saveParam.enterpriseId=Setting.currentEnterprise.id;
        if (isProject) {
            saveParam.projectId=id;
            return this.saveTaskPermissionForProject(saveParam);
        } else {
            saveParam.taskId=id;
            return this.savePermissionForTask(saveParam);
        }
    }

    private saveTaskPermissionForProject(data: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.savePermissionForProject(data).then(async (res: any) => {
                resolve(res.data);
                Utils.presentToast("操作成功");
            }).catch((err: any) => {
                Utils.presentToast("保存失败");
                reject(err);
            });
        });
    }

    private savePermissionForTask(data: any): Promise < any > {
        return new Promise((resolve, reject) => {
            this.api.savePermissionForTask(data).then(async (res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                Utils.presentToast("保存失败");
                reject(err);
            });
        });
    }

}